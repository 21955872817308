<oui-checkbox
  *ngIf="withCheckBox && fileId"
  class="file-checkbox"
  [checked]="isIncluded(fileId)"
  (checkedChange)="toggleFileSelection(fileId)"
  (click)="$event.stopPropagation()"></oui-checkbox>

<oui-icon class="file-icon">{{ icon }}</oui-icon>

<div class="file-info-container">
  <div class="file-label subtitle-2">{{ label }}</div>
  <div class="file-name body-2">{{ fileName }}</div>
  <oui-tag *ngIf="displayStatus" class="file-status" size="big" type="status" [status]="tagStatus"
    >{{ statusLabel }}
  </oui-tag>
</div>

<div oui-dialog-title>
  Historique de l'opération
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<div class="filter">
  <oui-select-filter
    type="list"
    label="Filtrer par évènement"
    [listOptions]="options"
    [control]="control"></oui-select-filter>

  <oui-chip-list type="selection" [noWrap]="false">
    <oui-chip *ngFor="let chip of control.value" (remove)="onRemoveChip(chip)">{{ chip }}</oui-chip>
  </oui-chip-list>
</div>

<oui-dialog-content [scroll]="true">
  <div class="loading-container" *ngIf="isloading">
    <oui-loading size="small"></oui-loading>
  </div>

  <lib-history
    *ngIf="historyDisplayedEntries"
    [records]="filteredEntries"
    (documentOpen)="this.documentOpen.emit($event)"
    (bordereauLink)="onBordereauLink($event)"></lib-history>
</oui-dialog-content>

import { Component } from '@angular/core';

@Component({
  selector: 'app-confirm-note-delete',
  templateUrl: './confirm-note-delete.component.html',
  styleUrls: ['./confirm-note-delete.component.scss'],
})
export class ConfirmNoteDeleteComponent {
  constructor() {}
}

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';

// Omnium UI
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiButtonToggle } from 'omnium-ui/button-toggle';
import { OuiChipModule } from 'omnium-ui/chip';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiDocumentViewer } from 'omnium-ui/document-viewer';
import { OuiErrorPageComponent } from 'omnium-ui/error-page';
import { OuiEtatVide } from 'omnium-ui/etat-vide';
import {
  OuiAutocomplete,
  OuiCheckbox,
  OuiRadioButton,
  OuiRadioButtonGroup,
  OuiSelect,
  OuiTextarea,
  OuiTextfield,
} from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLayoutModule, OuiNavButtonModule, OuiNavigationPanelModule } from 'omnium-ui/layout';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiMenuModule } from 'omnium-ui/menu';
import { OuiNumber } from 'omnium-ui/number';
import { OuiPaginatorModule } from 'omnium-ui/paginator';
import { OuiPairInfo } from 'omnium-ui/pair-info';
import { OuiSearchbar } from 'omnium-ui/searchbar';
import { OuiSelectFilter } from 'omnium-ui/select-filter';
import { OuiSlideToggle } from 'omnium-ui/slide-toggle';
import { OuiSnackbarModule } from 'omnium-ui/snackbar';
import { OuiStepper } from 'omnium-ui/stepper';
import { OuiTableModule } from 'omnium-ui/table';
import { OuiTabsModule } from 'omnium-ui/tabs';
import { OuiTagModule } from 'omnium-ui/tag';
import { OuiTooltipModule } from 'omnium-ui/tooltip';

import { OuiBanner } from 'omnium-ui/banner';
import { OuiInfosBulle } from 'omnium-ui/infos-bulle';
import { ControlFileDocumentViewerComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/control-file-document-viewer.component';
import { CopyNonConformiteComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/copy-non-conformite/copy-non-conformite.component';
import { DeclareMissingFileComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/declare-missing-file/declare-missing-file.component';
import { OperationFileNonConformFormComponent } from 'projects/box-lib/src/lib/components/control-file-pdf-viewer/operation-file-non-conform-form/operation-file-non-conform-form.component';
import { CreateBoxDocTypeDialogComponent } from 'projects/box-lib/src/lib/components/create-box-doc-type-dialog/create-box-doc-type-dialog.component';
import { ExcelExportComponent } from 'projects/box-lib/src/lib/components/excel-export/excel-export.component';
import { FileConformStatusStampComponent } from 'projects/box-lib/src/lib/components/file-conform-status-stamp/file-conform-status-stamp.component';
import { FileStatusAccordionComponent } from 'projects/box-lib/src/lib/components/file-status-accordion/file-status-accordion.component';
import { FileStatutTagComponent } from 'projects/box-lib/src/lib/components/file-statut-tag/file-statut-tag.component';
import { FilesListStatusComponent } from 'projects/box-lib/src/lib/components/files-list-status/files-list-status.component';
import { LandingPageComponent } from 'projects/box-lib/src/lib/components/landing-page/landing-page.component';
import { NavPanelComponent } from 'projects/box-lib/src/lib/components/nav-panel/nav-panel.component';
import { NonConformiteAccordionComponent } from 'projects/box-lib/src/lib/components/non-conformite-accordion/non-conformite-accordion.component';
import { NonConformiteFichierCardComponent } from 'projects/box-lib/src/lib/components/non-conformite-fichier-card/non-conformite-fichier-card.component';
import { OperationStatutTagComponent } from 'projects/box-lib/src/lib/components/operation-statut-tag/operation-statut-tag.component';
import { OperationsDashboardComponent } from 'projects/box-lib/src/lib/components/operations-dashboard/operations-dashboard.component';
import { OperationsTableComponent } from 'projects/box-lib/src/lib/components/operations-dashboard/operations-table/operations-table.component';
import { CommandeAramisDialogComponent } from 'projects/box-lib/src/lib/components/operations-panel-info/commande-aramis-dialog/commande-aramis-dialog.component';
import { OperationUpdateDialogComponent } from 'projects/box-lib/src/lib/components/operations-panel-info/operation-update-dialog/operation-update-dialog.component';
import { OperationsPanelInfoComponent } from 'projects/box-lib/src/lib/components/operations-panel-info/operations-panel-info.component';
import { AddArianeDocumentViewerComponent } from 'projects/box-lib/src/lib/components/pdf-viewer/add-ariane/add-ariane-document-viewer.component';
import { AddUploadDocumentViewerComponent } from 'projects/box-lib/src/lib/components/pdf-viewer/add-upload/add-upload-document-viewer.component';
import { FileItemComponent } from 'projects/box-lib/src/lib/components/pdf-viewer/file-item/file-item.component';
import { RootDocumentViewerComponent } from 'projects/box-lib/src/lib/components/pdf-viewer/root-pdf-viewer/root-document-viewer.component';
import { SearchEnveloppeProduitAutocompleteComponent } from 'projects/box-lib/src/lib/components/search-enveloppe-produit-autocomplete/search-enveloppe-produit-autocomplete.component';
import { SearchInvestisseurAutocompleteComponent } from 'projects/box-lib/src/lib/components/search-investisseur-autocomplete/search-investisseur-autocomplete.component';
import { SearchNatureAutocompleteComponent } from 'projects/box-lib/src/lib/components/search-nature-autocomplete/search-nature-autocomplete.component';
import { StepperFooterComponent } from 'projects/box-lib/src/lib/components/stepper-footer/stepper-footer.component';
import { TransactionPersonnelleComponent } from 'projects/box-lib/src/lib/components/transaction-personnelle/transaction-personnelle.component';
import { UnauthorizedComponent } from 'projects/box-lib/src/lib/components/unauthorized/unauthorized.component';
import { ValidateAllOperationDocDialogComponent } from 'projects/box-lib/src/lib/components/validate-all-operation-doc-dialog/validate-all-operation-doc-dialog.component';
import { OptionLabelPipe } from 'projects/box-lib/src/lib/pipes/optionLabel';
import { SafeResourceUrlPipe } from 'projects/box-lib/src/lib/safe-resource-url.pipe';
import { ConfirmationCheckboxComponent } from './components/confirmation-checkbox/confirmation-checkbox.component';
import { ConfirmationSimpleComponent } from './components/confirmation-simple/confirmation-simple.component';
import { TransmissionPartenaireDialogComponent } from './components/control-file-pdf-viewer/transmission-partenaire-dialog/transmission-partenaire-dialog.component';
import { UpdateConsultantDialogComponent } from './components/control-file-pdf-viewer/update-consultant-dialog/update-consultant-dialog.component';
import { UpdateDocumentTypeComponent } from './components/control-file-pdf-viewer/update-denomination-document/update-doc-type.component';
import { CreationPersonneMoraleDashboardComponent } from './components/creation-personne-morale-dashboard/creation-personne-morale-dashboard.component';
import { CreationPersonneMoraleStatutCellComponent } from './components/creation-personne-morale-dashboard/creation-personne-morale-table/creation-personne-morale-statut-cell/creation-personne-morale-statut-cell.component';
import { CreationPersonneMoraleTableComponent } from './components/creation-personne-morale-dashboard/creation-personne-morale-table/creation-personne-morale-table.component';
import { CreationPersonneMoraleValidationComponent } from './components/creation-personne-morale-dashboard/creation-personne-morale-table/creation-personne-morale-validation/creation-personne-morale-validation.component';
import { DocumentViewerHeaderComponent } from './components/document-viewer/_common/document-viewer-header/document-viewer-header.component';
import { DocumentViewerPanelComponent } from './components/document-viewer/document-viewer-panel/document-viewer-panel.component';
import { OperationDocumentViewerComponent } from './components/document-viewer/operation-document-viewer/operation-document-viewer.component';
import { NotifyMailPartenaireComponent } from './components/notify-mail-partenaire/notify-mail-partenaire.component';
import { OperationFilesListComponent } from './components/operation-files-list/operation-files-list.component';
import { DeleteDraftConfirmComponent } from './components/operations-dashboard/delete-draft-confirm/delete-draft-confirm.component';
import { OperationDashboardFiltersComponent } from './components/operations-dashboard/operation-dashboard-filters/operation-dashboard-filters.component';
import { GestionnaireActivityTableComponent } from './components/operations-dashboard/operations-dispatcher/gestionnaire-activity-table/gestionnaire-activity-table.component';
import { OperationsDispatcherComponent } from './components/operations-dashboard/operations-dispatcher/operations-dispatcher.component';
import { CommentaireCellComponent } from './components/operations-dashboard/operations-table/commentaire-cell/commentaire-cell.component';
import { ConsultantCellComponent } from './components/operations-dashboard/operations-table/consultant-cell/consultant-cell.component';
import { InvestisseurCellComponent } from './components/operations-dashboard/operations-table/investisseur-cell/investisseur-cell.component';
import { LinkedOperationsCellComponent } from './components/operations-dashboard/operations-table/linked-operations-cell/linked-operations-cell.component';
import { NatureCellComponent } from './components/operations-dashboard/operations-table/nature-cell/nature-cell.component';
import { SearchPartenaireAutocompleteComponent } from './components/search-partenaire-autocomplete/search-partenaire-autocomplete.component';

/**
 * Les deux projets consultant et backoffice qui utilisent les assets de la lib ne passent pas par ce module mais importent directement  les composants dans leur module
 *  pour éviter de passer par un build intermediaire. Ce Module ne sert donc que pour avoir une compilation au niveau de la lib (utile pour les erreurs de codes)
 */
@NgModule({
  declarations: [
    UnauthorizedComponent,
    NavPanelComponent,
    AddArianeDocumentViewerComponent,
    AddUploadDocumentViewerComponent,
    FileItemComponent,
    SafeResourceUrlPipe,
    RootDocumentViewerComponent,
    OperationsPanelInfoComponent,
    OperationsDashboardComponent,
    FileStatusAccordionComponent,
    FilesListStatusComponent,
    StepperFooterComponent,
    SearchInvestisseurAutocompleteComponent,
    SearchEnveloppeProduitAutocompleteComponent,
    SearchNatureAutocompleteComponent,
    SearchEnveloppeProduitAutocompleteComponent,
    SearchPartenaireAutocompleteComponent,
    OperationsDashboardComponent,
    OperationsTableComponent,
    ControlFileDocumentViewerComponent,
    OperationFileNonConformFormComponent,
    DeclareMissingFileComponent,
    OperationsDispatcherComponent,
    GestionnaireActivityTableComponent,
    OperationStatutTagComponent,
    ConsultantCellComponent,
    ConfirmationSimpleComponent,
    ConfirmationCheckboxComponent,
    DeleteDraftConfirmComponent,
    NotifyMailPartenaireComponent,
    ConfirmationCheckboxComponent,
    OperationFilesListComponent,
    CommandeAramisDialogComponent,
    LandingPageComponent,
    UpdateDocumentTypeComponent,
    InvestisseurCellComponent,
    LinkedOperationsCellComponent,
    NatureCellComponent,
    TransmissionPartenaireDialogComponent,
    ExcelExportComponent,
    OperationDashboardFiltersComponent,
    CommentaireCellComponent,
    CopyNonConformiteComponent,
    CreationPersonneMoraleDashboardComponent,
    CreationPersonneMoraleTableComponent,
    CreationPersonneMoraleStatutCellComponent,
    CreationPersonneMoraleValidationComponent,
    CreateBoxDocTypeDialogComponent,
    TransactionPersonnelleComponent,
    OperationUpdateDialogComponent,
    UpdateConsultantDialogComponent,
    ValidateAllOperationDocDialogComponent,
  ],
  imports: [
    OuiLayoutModule,
    OuiIconModule,
    OuiButtonModule,
    OuiTabsModule,
    OuiTagModule,
    OuiTableModule,
    OuiPaginatorModule,
    OuiNavButtonModule,
    OuiNavigationPanelModule,
    OuiStepper,
    OuiSlideToggle,
    OuiSelect,
    OuiLoadingModule,
    OuiSelectFilter,
    OuiAccordionModule,
    OuiTextfield,
    OuiTextarea,
    OuiCheckbox,
    OuiAutocomplete,
    OuiButtonToggle,
    OuiMenuModule,
    OuiSearchbar,
    OuiChipModule,
    OuiNumber,
    OuiBanner,
    OuiTooltipModule,
    OuiDialogModule,
    OuiSnackbarModule,
    OuiRadioButton,
    OuiRadioButtonGroup,
    OuiPairInfo,
    OuiEtatVide,
    OuiInfosBulle,
    OuiIconModule,
    MatDialogModule,
    MatSortModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    OuiDialogModule,
    OuiErrorPageComponent,
    OuiDocumentViewer,
    DocumentViewerPanelComponent,
    OperationDocumentViewerComponent,
    FileConformStatusStampComponent,
    NonConformiteFichierCardComponent,
    NonConformiteAccordionComponent,
    OptionLabelPipe,
    FileStatutTagComponent,
    DocumentViewerHeaderComponent,
  ],
  exports: [
    OperationsDashboardComponent,
    OperationsTableComponent,
    OperationsPanelInfoComponent,
    FilesListStatusComponent,
    NavPanelComponent,
    RootDocumentViewerComponent,
    FileConformStatusStampComponent,
    NonConformiteAccordionComponent,
    SearchNatureAutocompleteComponent,
    SearchEnveloppeProduitAutocompleteComponent,
    SearchInvestisseurAutocompleteComponent,
    StepperFooterComponent,
    OperationFilesListComponent,
    DocumentViewerPanelComponent,
    ControlFileDocumentViewerComponent,
    CreationPersonneMoraleDashboardComponent,
    TransactionPersonnelleComponent,
  ],
  providers: [],
})
export class BoxLibModule {}

registerLocaleData(localeFr, 'fr-FR');

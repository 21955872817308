<div oui-dialog-title>
  Modifier le nom du document
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  Veuillez sélectionner un type pour ce document:
  <oui-autocomplete
    *ngIf="!isLoading"
    label="Type du document"
    [control]="boxDocumentTypeControl"
    [options]="boxDocumentTypeListOptions"
    class="width-100pc top-margin-8"></oui-autocomplete>
  <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="onValidate()" [disabled]="!boxDocumentTypeControl.valid || isLoading"
    >Valider</oui-button
  >
</oui-dialog-actions>

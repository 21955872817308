import { ConsultantHabilitation } from '../models/generated/graphql';

export interface HabilitationCategories {
  valid: ConsultantHabilitation[];
  expired: ConsultantHabilitation[];
}

const isNoCodeHab = (hab: ConsultantHabilitation | null) => !hab?.codeHabilitation;
const isHabValid = (hab: ConsultantHabilitation | null) => hab?.statut === 1;
const isHabExpired = (hab: ConsultantHabilitation | null) => hab?.statut === 0;
const isHabMoreRecent = (hab: ConsultantHabilitation, currentHab: ConsultantHabilitation) =>
  hab.dateFin && currentHab.dateFin && hab.dateFin > currentHab.dateFin;

export const filterHabilitations = (habs: (ConsultantHabilitation | null)[]): HabilitationCategories => {
  const expiredHabilitationsByCode = new Map<string, ConsultantHabilitation>(); // Map<codeHabilitation, hab>
  const validHabilitationsByCode = new Map<string, ConsultantHabilitation>();

  const expiredToProcess = habs.filter(hab => isHabExpired(hab) && !isNoCodeHab(hab)) as ConsultantHabilitation[];
  const validToProcess = habs.filter(hab => isHabValid(hab) && !isNoCodeHab(hab)) as ConsultantHabilitation[];

  for (const hab of validToProcess) {
    // keep only the most recent valid habilitation
    const currentValidHab = validHabilitationsByCode.get(hab.codeHabilitation!);
    if (!currentValidHab || isHabMoreRecent(hab!, currentValidHab)) {
      validHabilitationsByCode.set(hab.codeHabilitation!, hab!);
    }
  }

  for (const hab of expiredToProcess) {
    // keep only the most recent expired habilitation that does not have the same code as a valid habilitation
    const validHabWithSameCode = validHabilitationsByCode.get(hab.codeHabilitation!);
    if (!validHabWithSameCode) {
      const currentExpiredHab = expiredHabilitationsByCode.get(hab.codeHabilitation!);
      if (!currentExpiredHab || isHabMoreRecent(hab, currentExpiredHab)) {
        expiredHabilitationsByCode.set(hab.codeHabilitation!, hab!);
      }
    }
  }
  const expiredHabilitations = Array.from(expiredHabilitationsByCode.values());
  const validHabilitations = Array.from(validHabilitationsByCode.values());

  return { valid: validHabilitations, expired: expiredHabilitations };
};
export const checkConsultantHabilitation = (habilitationCode: any, consultantHabilitations: string[]): boolean => {
  return consultantHabilitations.includes(habilitationCode);
};

import { Component, EventEmitter, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { OptionsService } from 'projects/box-lib/src/lib/services/options.service';
import { AuthService } from '../../services/auth-service.service';

import {
  Consultant,
  EnvoiEmail,
  EnvoiEmailStatut,
  FileMetadata,
  Operation,
  OperationHistoryRecord,
  OperationStateTransitionTrigger,
  OperationType,
  TransactionPersonnelleEnum,
} from '../../models/generated/graphql';
import { OptionLabelPipe } from '../../pipes/optionLabel';
import { HabilitationCategories, checkConsultantHabilitation, filterHabilitations } from '../../utils/habilitation';

import { OperationHistoriqueDialogComponent } from './operation-historique-dialog/operation-historique-dialog.component';

import { OperationsDispatcherComponent } from '../operations-dashboard/operations-dispatcher/operations-dispatcher.component';
import { FicheLiaisonDialogComponent } from './fiche-liaison-dialog/fiche-liaison-dialog.component';
import {
  OperationChamp,
  OperationUpdateDialogComponent,
  OperationUpdateDialogData,
} from './operation-update-dialog/operation-update-dialog.component';

import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { MenuNode } from 'omnium-ui/shared';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { OuiTagStatus } from 'omnium-ui/tag';
import { CommandeAramisDialogComponent } from 'projects/box-lib/src/lib/components/operations-panel-info/commande-aramis-dialog/commande-aramis-dialog.component';
import { distinctUntilChanged, firstValueFrom } from 'rxjs';
import { OperationSpecificData } from '../../models/donees-specifiques.model';
import { ApplicationInsightsService } from '../../services/ApplicationInsightService';
import { OperationsService } from '../../services/operations.service';

import { TemplatePortal } from '@angular/cdk/portal';
import { NotifyMailPartenaireComponent } from 'projects/box-lib/src/lib/components/notify-mail-partenaire/notify-mail-partenaire.component';
import { BoxDocumentViewerService } from '../document-viewer/document-viewer-panel/document-viewer-panel.service';

interface ConsultantInfo {
  displayName: string;
  email: string | null;
  mailToLink: string | null;
  phoneNumber: string | null;
  commisionnement: string;
  titre: string;
}

@Component({
  selector: 'app-operations-panel-info',
  templateUrl: './operations-panel-info.component.html',
  styleUrls: ['./operations-panel-info.component.scss'],
})
export class OperationsPanelInfoComponent {
  readonly OPERATION_MAIL_RECEPTION_TRIGGER = OperationStateTransitionTrigger.BackOfficeReceivesMailDocuments;

  @Input() isConsultant: boolean = false;
  @Input() operation: Operation;

  @Output() onRejectClick = new EventEmitter();
  @Output() onCancelClick = new EventEmitter();
  @Output() onEditGestionnaireCommentClick = new EventEmitter();
  @Output() onSetInstancePartenaireClick = new EventEmitter();
  @Output() operationChange = new EventEmitter();
  @Output() onMailReceptionClick = new EventEmitter();
  @Output() onGestionnaireAssigned = new EventEmitter<Operation>();
  @Output() onBackArrowClicked = new EventEmitter();
  specificData: OperationSpecificData;
  allFilesChecked: boolean;

  isAdmin: boolean;

  get isGestionnaire(): boolean {
    return !this.isConsultant;
  }

  demembrementOptions: OuiButtonToggleOption<string>[];
  modePaiementOptions: OuiSelectOption<string>[];
  transmissionOptions: OuiSelectOption<string>[];
  typeTauxFraisOptions: OuiButtonToggleOption<string>[];
  impactTauxFraisOptions: OuiButtonToggleOption<string>[];
  typeSignaturePartenaireOptions: OuiSelectOption<string>[];
  objetModificationOptions: OuiSelectOption<string>[];

  transactionPersonnelleOptions: OuiSelectOption<string>[];

  tagOptions: OuiSelectOption<number>[] = [];
  tagControl = new FormControl<number[]>([], { nonNullable: true });

  isCancelEnabled = false;
  isRejectEnabled = false;
  isSetInstancePartenaireEnabled = false;
  menuGestionnaire: MenuNode[] = [];

  mailToConsultant: string;
  mailToAssistant: string;
  isConsultantHabilitationWarning: boolean = false;
  consultantHabilitations: {
    icon: string;
    class: string;
    libelle: string;
    startDate?: Date;
    endDate?: Date;
    id: number;
    isOperationHabilitation: boolean;
  }[] = [];
  consultant: ConsultantInfo;
  assistants: ConsultantInfo[];
  emailInError: EnvoiEmail[] = [];

  operationDeclaration: OperationHistoryRecord | null;

  get operationStatus(): OuiTagStatus {
    return this.operationService.getOperationStatusColor(this.operation, this.isConsultant);
  }

  // Valeurs affichées dans les pair infos
  isInformationsEditable: boolean = false;
  private optionLabelPipe = new OptionLabelPipe();

  get fondsEvenementiels(): string {
    return this.operation.fondEvenementiels.map(fe => fe.libelle).join(', ');
  }

  wrapTransactionPersonnelleStatut(tp: TransactionPersonnelleEnum | undefined) {
    switch (tp) {
      case TransactionPersonnelleEnum.AutoSouscription:
        return 'Auto-souscription';
      case TransactionPersonnelleEnum.PourProches:
        return 'Pour un proche';
      case TransactionPersonnelleEnum.PourSalaries:
        return 'Pour un salarié';
      case TransactionPersonnelleEnum.Non:
      default:
        return 'Non';
    }
  }

  get modesPaiement(): string | undefined {
    return this.specificData.modePaiement
      ?.map((mode: string) => this.optionLabelPipe.transform(mode, this.modePaiementOptions))
      .join(', ');
  }

  get typeSignature(): string | undefined {
    return this.operation.typeSignaturePartenaire
      ? this.optionLabelPipe.transform(this.operation.typeSignaturePartenaire, this.typeSignaturePartenaireOptions)
      : undefined;
  }

  get objetsModification(): string | undefined {
    return this.specificData.objetModification
      ? this.optionLabelPipe.transform(this.specificData.objetModification, this.objetModificationOptions)
      : undefined;
  }

  get demembrement(): string | undefined {
    return this.specificData.demembrement
      ? this.optionLabelPipe.transform(this.specificData.demembrement, this.demembrementOptions)
      : undefined;
  }

  get isContrepartie(): string {
    if (!this.specificData.contrepartieIsKnown) {
      return 'Non';
    }
    return 'Oui';
  }

  get contrepartieName(): string | undefined {
    return this.specificData.contrepartieName;
  }
  get typeTauxFrais(): string | undefined {
    return this.specificData.typeTauxFrais
      ? this.optionLabelPipe.transform(this.specificData.typeTauxFrais, this.typeTauxFraisOptions)
      : undefined;
  }

  get impactDiminutionFrais(): string | undefined {
    return this.specificData.impactDiminutionFrais
      ? this.optionLabelPipe.transform(this.specificData.impactDiminutionFrais, this.impactTauxFraisOptions)
      : undefined;
  }
  get transmissionOriginaux(): string | undefined {
    return this.specificData.transmissionOriginaux
      ? this.optionLabelPipe.transform(this.specificData.transmissionOriginaux, this.transmissionOptions)
      : undefined;
  }
  get transactionPersonnelle(): string | undefined {
    return this.specificData.transactionPersonnelle
      ? this.optionLabelPipe.transform(this.specificData.transactionPersonnelle, this.transactionPersonnelleOptions)
      : undefined;
  }

  protected reopenHistory: boolean = false;

  protected documentViewerPortal: TemplatePortal;
  @ViewChild('documentViewerPortalContent') documentViewerPortalContent: TemplateRef<unknown>;

  protected viewerFile?: FileMetadata;
  tagChips: OuiSelectOption[] = [];
  showClearTagsButton: boolean = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private optionService: OptionsService,
    private authService: AuthService,
    private ouiDialogService: OuiDialogService,
    private snackbarService: OuiSnackbarService,
    private operationService: OperationsService,
    private loggerService: ApplicationInsightsService,
    private datepipe: DatePipe,
    private documentViewerPanelService: BoxDocumentViewerService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authService.isAdminRole();
    this.demembrementOptions = this.optionService.getDemembrementOptions();
    this.modePaiementOptions = this.optionService.getModePaiementOptions();
    this.transmissionOptions = this.optionService.getTransmissionOptions();
    this.typeTauxFraisOptions = this.optionService.getTypeTauxFraisOptions();
    this.impactTauxFraisOptions = this.optionService.getImpactTauxFraisOptions();
    this.typeSignaturePartenaireOptions = this.optionService.getTypeSignaturePartenaireOptions();
    this.objetModificationOptions = this.optionService.getObjetModificationOptions();
    this.transactionPersonnelleOptions = this.optionService.getTransactionPersonnelleOptions();

    if (this.isGestionnaire) {
      this.optionService.getOperationTagOptionsWithData().then(options => {
        this.tagOptions = options;
        setTimeout(() => {
          //FIXME setTimeout est nécessaire pour afficher les tags cochés dans le oui-select-filter
          this.tagControl.setValue(this.operation.tags.map(s => s.id));
          this.tagChips = this.tagOptions.filter(o => o.value && this.tagControl.value.includes(o.value));
          this.tagControl.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
            this.tagChips = this.tagOptions.filter(o => o.value && this.tagControl.value.includes(o.value));
            this.updateTag(value);
          });
        });
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['operation']) {
      this.onOperationChanges();
    }
  }

  private onOperationChanges() {
    this.isCancelEnabled = this.isTriggerIncluded(OperationStateTransitionTrigger.GestionnaireCancelOperation);
    this.isRejectEnabled = this.isTriggerIncluded(OperationStateTransitionTrigger.GestionnaireRefuseOperation);
    this.isSetInstancePartenaireEnabled = this.isTriggerIncluded(
      OperationStateTransitionTrigger.GestionnaireDeclareInstancePartenairePresence
    );
    this.getEmailErrors();
    this.menuGestionnaire = [
      {
        name: "Indiquer la présence d'instances partenaires",
        disabled: !this.isSetInstancePartenaireEnabled,
        action: () => this.onSetInstancePartenaire(),
      },
      { name: "Annuler l'opération", disabled: !this.isCancelEnabled, action: () => this.onCancelOperation() },
      { name: "Refuser l'opération", disabled: !this.isCancelEnabled, action: () => this.onRejectOperation() },
      { name: 'Editer commentaire gestionnaire', action: () => this.onEditGestionnaireCommentClick.emit() },
    ];

    if (this.operation) {
      this.parseSpecificData();
      this.setConsultantInfo(this.operation);
      if (this.tagOptions?.length > 0) {
        this.tagControl.setValue(this.operation.tags.map(t => t.id) ?? [], { emitEvent: false });
        this.tagChips = this.tagOptions.filter(o => o.value && this.tagControl.value.includes(o.value));
      }

      // Etats où l'édition des informations est autorisée
      this.isInformationsEditable =
        (this.operation.statutId >= 200 && this.operation.statutId < 250) ||
        (this.operation.statutId >= 310 && this.operation.statutId < 315);
    }
  }

  private parseSpecificData() {
    try {
      this.specificData = JSON.parse(this.operation?.donneesSpecifiques);
    } catch (error: any) {
      this.specificData = {};
      const newError = new Error('Données Spécifiques : JSON Parser Error', error);
      this.loggerService.logException(newError);
    }
  }

  onBackArrowClick() {
    this.onBackArrowClicked.emit();
  }

  getEmailErrors() {
    if (this.operation && this.authService.isBackOffice()) {
      const errors = this.operation.emails.filter(
        email => email.statutEnvoi === EnvoiEmailStatut.Error && !email.isErrorAcknowledged
      );
      this.emailInError = errors ?? [];
    }
  }

  onHistoriqueClick() {
    const dialogRef = this.ouiDialogService.openDialog(
      OperationHistoriqueDialogComponent,
      { operationId: this.operation.id },
      '720px',
      '700px'
    );

    const dialogSub = dialogRef.componentInstance.documentOpen.subscribe((file: FileMetadata) => {
      this.reopenHistory = true;
      this.openDocumentViewer(file);

      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(() => {
      dialogSub.unsubscribe();
    });
  }

  isTriggerIncluded(trigger: OperationStateTransitionTrigger): boolean {
    return this.operation?.activeOperationStateTransitionTriggers.includes(trigger);
  }

  onRejectOperation() {
    this.onRejectClick.emit();
  }
  onCancelOperation() {
    this.onCancelClick.emit();
  }

  onSetInstancePartenaire() {
    this.onSetInstancePartenaireClick.emit();
  }

  onDeclareMailReception() {
    this.onMailReceptionClick.emit();
  }

  onGestionnaireClick() {
    const modalRef = this.ouiDialogService.openDialog(
      OperationsDispatcherComponent,
      {
        operations: [this.operation],
      },
      'auto',
      '1280px'
    );

    modalRef.afterClosed().subscribe((afteclosedData: Operation[]) => {
      if (!afteclosedData || !afteclosedData[0]) return;
      const updatedOne = afteclosedData[0];
      if (updatedOne) {
        this.operation.statutId = updatedOne.statutId;
        this.operation.statut = updatedOne.statut;
        this.operation.gestionnaireId = updatedOne.gestionnaireId;
        this.operation.gestionnaire = updatedOne.gestionnaire;
        this.operation.activeOperationStateTransitionTriggers = updatedOne.activeOperationStateTransitionTriggers;
        this.operation.operationActionRights = updatedOne.operationActionRights;
        this.onGestionnaireAssigned.emit(this.operation);
        this.onOperationChanges();
      }
    });
  }

  setConsultantInfo(operation: Operation) {
    // A consultant can be several individuals and ConsulantPersonnePhysique, ConsultantEmail, ConsultantTelephone are arrays of objects
    // because the Finzzle model is what it is, we presume that index 0 is the main one, and the others are called assistant(e).
    const operationConsultant = operation.consultant;
    this.assistants = [];
    operationConsultant?.personnePhysique?.forEach((pp, index) => {
      const personneInfo: ConsultantInfo = {
        displayName: `${pp?.nom ?? ''} ${pp?.prenom ?? ''}`,
        email: this.tryGetConsultantEmail(operationConsultant, index),
        mailToLink: this.tryGetConsultantEmail(operationConsultant, index)
          ? `mailto:${this.tryGetConsultantEmail(operationConsultant, index)}`
          : null,
        phoneNumber: this.tryGetConsultantPhoneNumber(operationConsultant),
        commisionnement:
          operationConsultant.tauxRemuneration && !Number.isNaN(operationConsultant.tauxRemuneration)
            ? `${operationConsultant.tauxRemuneration * 100}%`
            : `non renseigné`,
        titre: operationConsultant.titreCodeNavigation?.libelle ?? 'non renseigné',
      };
      index === 0 ? (this.consultant = personneInfo) : this.assistants.push(personneInfo);
    });

    this.listAndCheckHabilitations(operation);
  }

  tryGetConsultantPhoneNumber(consultant: Consultant): string | null {
    //first check if there is a mobile phone, otherwise take the first phone
    const MOBILE_PHONE_CODE = 'MOB';
    if (consultant?.telephone) {
      const mobile = consultant.telephone.find(t => t.typeTelephoneCode === MOBILE_PHONE_CODE);
      if (mobile) {
        return `${mobile.numero}`;
      }
      const otherPhone = consultant.telephone[0];
      if (otherPhone) {
        return `${otherPhone.numero}`;
      }
    }
    return null;
  }

  tryGetConsultantEmail(consultant: Consultant, index: number): string | null {
    // because the Finzzle model is what it is, we have no garanty that length is the same for ConsulantPersonnePhysique, ConsultantEmail, ConsultantTelephone.
    try {
      if (consultant?.email && consultant?.email[index]) {
        return `${consultant?.email[index]?.adresse}`;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  listAndCheckHabilitations(operation: Operation) {
    const consultHabs: HabilitationCategories = filterHabilitations(
      operation.consultant?.consultantHabilitations ?? []
    );
    this.isConsultantHabilitationWarning = !checkConsultantHabilitation(
      operation?.produit?.habilitationNavigation?.code,
      consultHabs.valid.map(c => c.codeHabilitation!)
    );

    // compute habilitations
    this.consultantHabilitations = [];
    // push valid habilitations
    consultHabs.valid.forEach(element =>
      this.consultantHabilitations.push({
        icon: 'verified_user',
        class: 'color-valid',
        libelle: element.habilitation?.libelle ?? element.codeHabilitation ?? 'habilitation inconnue',
        startDate: element.dateDebut,
        endDate: element.dateFin,
        id: element.habilitationId,
        isOperationHabilitation: operation.produit?.habilitation == element.habilitationId,
      })
    );
    //push expired habilitations
    consultHabs.expired.forEach(element =>
      this.consultantHabilitations.push({
        icon: 'gpp_bad',
        class: 'color-error',
        libelle: element.habilitation?.libelle ?? element.codeHabilitation ?? 'habilitation inconnue',
        startDate: element.dateDebut,
        endDate: element.dateFin,
        id: element.habilitationId,
        isOperationHabilitation: operation.produit?.habilitation == element.habilitationId,
      })
    );
    // check if we have already push the operation habilitation, if not we add it
    if (this.consultantHabilitations.findIndex(h => h.isOperationHabilitation) === -1) {
      this.consultantHabilitations.push({
        icon: 'gpp_bad',
        class: 'color-error',
        libelle:
          operation.produit?.habilitationNavigation?.libelle ??
          operation.produit?.habilitationNavigation?.code ??
          'habilitation inconnue',
        startDate: undefined,
        endDate: undefined,
        id: operation.produit?.habilitation,
        isOperationHabilitation: true,
      });
    }
    // Sort the habilitations to put the operation habilitation at the top (first place)
    this.consultantHabilitations.sort((a, b) =>
      a.isOperationHabilitation === b.isOperationHabilitation ? 0 : a.isOperationHabilitation ? -1 : 1
    );
  }

  openFicheLiaisonDialog() {
    this.ouiDialogService.openDialog(FicheLiaisonDialogComponent, { operation: this.operation }, 'auto', '560px');
  }

  onSendEmailClick() {
    const modalRef = this.ouiDialogService.openDialog(NotifyMailPartenaireComponent, {
      operationId: this.operation.id,
    });
    modalRef.afterClosed().subscribe((mailId: number) => {
      if (mailId) {
        this.emailInError = [];
      }
    });
  }

  editNotImplemented() {
    this.snackbarService.open("La modification du champ n'est pas implémentée.", 'error');
  }

  isCommandEditable(): boolean {
    return this.isGestionnaire && this.operation.operationType === OperationType.Souscription;
  }

  editAramisCommande() {
    const dialogRef = this.ouiDialogService.openDialog(
      CommandeAramisDialogComponent,
      {
        operation: this.operation,
      },
      'auto',
      '630px'
    );

    dialogRef.afterClosed().subscribe((value: Operation | undefined) => {
      this.operationChange.emit();
    });
  }

  openEditDialog(champ: OperationChamp, currentValue: any) {
    const dialogRef = this.ouiDialogService.openDialog(
      OperationUpdateDialogComponent,
      <OperationUpdateDialogData>{
        operation: this.operation,
        champ: champ,
        currentValue: currentValue,
      },
      'auto',
      '580px'
    );

    dialogRef.afterClosed().subscribe((value: Operation | undefined) => {
      if (!value) return;
      this.operationChange.emit();
    });
  }

  private async updateTag(tagIds: number[]) {
    try {
      var result = await this.operationService.updateOperationTag(this.operation.id, tagIds);
      if (result) {
        this.operation.tags = result.tags;
      }
    } catch (error) {
      this.snackbarService.open('Une erreur est survenue durant la mise à jour du tag', 'error', 5000);

      this.tagControl.setValue(this.operation.tags.map(t => t.id) ?? [], { emitEvent: false });
    }
  }

  protected formatDate(date?: Date): string {
    if (!date) {
      return '--/--/--';
    }
    return this.datepipe.transform(date, 'dd/MM/YYYY') ?? '--/--/--';
  }

  openDocumentViewer(fileMetadata?: FileMetadata) {
    this.documentViewerPanelService.openSimpleDocumentViewer({ fileMetadata: fileMetadata });

    if (this.reopenHistory) {
      firstValueFrom(this.documentViewerPanelService.viewerClosed$).then(() => {
        this.onHistoriqueClick();
        this.reopenHistory = false;
      });
    }
  }

  closeFileViewer() {
    this.documentViewerPanelService.closeDocumentViewer();
  }

  onClearTags() {
    this.tagControl.setValue([]);
  }
  onRemoveTag(tagId: number) {
    const tagIdsList = this.tagControl.value.filter(t => t !== tagId);
    this.tagControl.setValue(tagIdsList);
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { OuiStep } from 'omnium-ui/stepper';

interface StepWithPath {
  step: OuiStep;
  path: string;
}

@Component({
  selector: 'parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
})
export class ParametersComponent {
  constructor(private router: Router) {}

  items: StepWithPath[] = [
    { step: { state: 'doing', title: 'Produits' }, path: 'produits' },
    { step: { state: 'doing', title: 'Types documents' }, path: 'types-doc' },
    { step: { state: 'doing', title: 'Fonds événementiels' }, path: 'fonds-evnt' },
    { step: { state: 'doing', title: 'Utilisateurs' }, path: 'utilisateurs' },
    { step: { state: 'doing', title: "Transfert d'opérations" }, path: 'transfer-operations' },
  ];

  steps = this.items.map(item => item.step);

  onStepChange(step: OuiStep) {
    // sur selection d'un step on met à jour la route
    const item = this.items.find(item => item.step.title === step.title);
    if (item && !this.router.url.includes(item.path)) {
      this.router.navigate(['parametres', item.path]);
    }
  }
}

<div class="page-layout flex-vertical gap-32 align-self-stretch">
  <div class="flex-horizontal width-100pc">
    <h4 class="flex-none">Opérations</h4>
    <div class="flex-1"></div>
    <oui-button-toggle
      *ngIf="userBackOfficeInfo?.withGroupeAccessExtended"
      [control]="serviceToggleControl"
      [options]="serviceToggleOptions"></oui-button-toggle>
  </div>

  <oui-tab-group class="tabs" [selectedIndex]="tabIndex">
    <oui-tab [label]="'Toutes ' + allItemNumber">
      <app-operation-dashboard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        [defaultQueryFilter]="allDefaultQueryFilter"
        [noFetch]="noFetch"
        (onTotalCount)="updateAllItemNumber($event)"
        (onSelectedOperation)="onOperationClick($event)"
        [withCSVExport]="true"
        [dashboardKey]="'all-operations'">
      </app-operation-dashboard>
    </oui-tab>
    <oui-tab [label]="'Attente originaux ' + waitingDocumentsItemNumber">
      <app-operation-dashboard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        [defaultQueryFilter]="waitingDocumentsDefaultQueryFilter"
        [noFetch]="noFetch"
        (onTotalCount)="updateWaitingDocumentsItemNumber($event)"
        (onSelectedOperation)="onOperationClick($event)"
        [withCSVExport]="true"
        [dashboardKey]="'attente-originaux'">
      </app-operation-dashboard>
    </oui-tab>
    <oui-tab [label]="'Demandes PM ' + demandesPmItemNumber">
      <lib-creation-personne-morale-dashboard
        [columnSelection]="creationPMColumnSelection"
        config="backoffice"
        (onTotalCount)="updateDemandesPmItemNumber($event)">
      </lib-creation-personne-morale-dashboard>
    </oui-tab>
    <oui-tab [label]="'A affecter ' + toDispatchItemNumber">
      <app-operation-dashboard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        [defaultQueryFilter]="toDispatchDefaultQueryFilter"
        [withSelects]="authService.isAdminRole()"
        (onSelectedOperation)="onOperationClick($event)"
        (onDispatchedOperationsEvent)="refreshToControlOperationsBoard()"
        [noFetch]="noFetch"
        (onTotalCount)="updateToDispatchItemNumber($event)"
        [withCSVExport]="true"
        [dashboardKey]="'a-affecter'"></app-operation-dashboard>
    </oui-tab>
    <oui-tab [label]="'Contrôles à effectuer ' + toControlItemNumber">
      <app-operation-dashboard
        #toControlBoard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        [defaultQueryFilter]="toControlDefaultQueryFilter"
        (onSelectedOperation)="onOperationClick($event)"
        [noFetch]="noFetch"
        [withSelects]="authService.isAdminRole()"
        (onTotalCount)="updateToControlItemNumber($event)"
        [preSelectedGestionnaireId]="authService.userInfo.id"
        [withCSVExport]="true"
        [dashboardKey]="'control-a-effectuer'"></app-operation-dashboard>
    </oui-tab>
    <oui-tab [label]="'En attente de retour ' + waitingReturnItemNumber">
      <app-operation-dashboard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        (onSelectedOperation)="onOperationClick($event)"
        [defaultQueryFilter]="waitingReturnDefaultQueryFilter"
        [noFetch]="noFetch"
        [withSelects]="authService.isAdminRole()"
        (onTotalCount)="updateWaitingReturnItemNumber($event)"
        [preSelectedGestionnaireId]="authService.userInfo.id"
        [withCSVExport]="true"
        [dashboardKey]="'en-attente-de-retour'"></app-operation-dashboard>
    </oui-tab>
    <oui-tab [label]="'Historique ' + historyItemNumber">
      <app-operation-dashboard
        [columnSelection]="columnSelection"
        [filterSelection]="filterSelection"
        (onSelectedOperation)="onOperationClick($event)"
        [noFetch]="noFetch"
        (onTotalCount)="updateHistoryItemNumber($event)"
        [defaultQueryFilter]="historiqueDefaultQueryFilter"
        [withCSVExport]="true"
        [dashboardKey]="'historique'"></app-operation-dashboard>
    </oui-tab>
  </oui-tab-group>
</div>

<div oui-dialog-title>
  {{ title }}
  <oui-button type="icon" [oui-dialog-close]>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <oui-textarea label="Commentaire (facultatif)" [(value)]="result.commentaire"></oui-textarea>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" [oui-dialog-close]>Annuler</oui-button>
  <oui-button type="contained" [oui-dialog-close]="result">Envoyer</oui-button>
</oui-dialog-actions>

<div class="flex-vertical gap-16 width-100pc">
  <div>
    {{ totalCount }} <span *ngIf="totalCount > 1">fonds événementiels</span
    ><span *ngIf="totalCount < 2">fond événementiel</span>
  </div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="fondEvenementiel">
          <th mat-header-cell *matHeaderCellDef>Libelle</th>
          <td mat-cell *matCellDef="let element">{{ element?.libelle }}</td>
        </ng-container>
        <ng-container matColumnDef="produitFondEvenementiel">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            {{ element?.produitFondEvenementiels?.length ?? '0' }}
            <span *ngIf="element?.produitFondEvenementiels?.length > 1">produits associés</span
            ><span *ngIf="element?.produitFondEvenementiels?.length < 2">produit associé</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="actionButton">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="flex-horizontal gap-16">
              <div class="flex-1"></div>
              <oui-button type="text" (click)="onDeactivateFondEvenementiel(element)">Supprimer</oui-button>
              <oui-button type="text" (click)="onUpdateFond(element)">Modifier</oui-button>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr mat-row [class.interactive]="true" *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OuiDialogService } from 'omnium-ui/dialog';
import { ValidateAllOperationDocDialogComponent } from 'projects/box-lib/src/lib/components/validate-all-operation-doc-dialog/validate-all-operation-doc-dialog.component';
import { FichierOperationStatutEnum } from '../../models/FichierOperationStatutEnum';
import { FichierOperation } from '../../models/generated/graphql';

@Component({
  selector: 'app-files-list-status',
  templateUrl: './files-list-status.component.html',
  styleUrls: ['./files-list-status.component.scss'],
})
export class FilesListStatusComponent {
  @Input() files: FichierOperation[];
  @Input() checkbox: boolean = true;
  @Input() mode: 'consultant' | 'gestionnaire' = 'consultant';
  fichiersAControler: FichierOperation[];
  fichiersNonConformes: FichierOperation[];
  fichiersNonConformesTraitees: FichierOperation[];
  fichiersConformes: FichierOperation[];
  isInstanceMode: boolean = false;
  fichiersNonConformesTitle: string = 'Non conformes';
  fichiersUpdatedTitle: string = 'Non conformités traitées';
  @Output() fileClicked = new EventEmitter<FichierOperation>();

  @Output()
  onValidateAllFiles = new EventEmitter();

  constructor(protected dialogService: OuiDialogService) {}

  ngOnChanges() {
    this.initDisplay();
  }
  ngOnInit() {
    this.initDisplay();
  }

  initDisplay() {
    this.setFilesCategoryByStatus(this.files);
    const hasInstance = this.files.some(file => {
      return (
        file.nonConformiteFichiers &&
        file.nonConformiteFichiers.some(nonConformite => {
          return nonConformite.isInstancePartenaire === true;
        })
      );
    });

    if (hasInstance) {
      this.isInstanceMode = true;
      this.fichiersNonConformesTitle = 'Instances partenaires';
      this.fichiersUpdatedTitle = 'Instances partenaires traitées';
    }
  }

  onValidateAllToControlClick() {
    let dialogRef = this.dialogService.openDialog(ValidateAllOperationDocDialogComponent, {}, 'auto', '400 px');
    dialogRef.afterClosed().subscribe(isValidated => {
      if (isValidated) {
        this.onValidateAllFiles.emit();
      }
    });
  }

  setFilesCategoryByStatus(files: FichierOperation[]) {
    if (files) {
      // sort by natureDocumentRequisId
      this.files.sort((a, b) => (a.natureDocumentRequisId ?? Infinity) - (b.natureDocumentRequisId ?? Infinity));
      this.fichiersAControler =
        this.mode === 'gestionnaire'
          ? files.filter(
              f =>
                f.fichierOperationStatut?.statut == FichierOperationStatutEnum.created ||
                f.fichierOperationStatut?.statut == FichierOperationStatutEnum.updatedByConsulant
            )
          : [];
      this.fichiersNonConformes = files.filter(
        f => f.fichierOperationStatut?.statut == FichierOperationStatutEnum.nonCompliant
      );
      this.fichiersNonConformesTraitees =
        this.mode === 'gestionnaire'
          ? []
          : files.filter(f => f.fichierOperationStatut?.statut == FichierOperationStatutEnum.updatedByConsulant);
      this.fichiersConformes = files.filter(
        f => f.fichierOperationStatut?.statut == FichierOperationStatutEnum.compliant
      );
    }
  }
  onfileClick(file: FichierOperation) {
    this.fileClicked.emit(file);
  }
}

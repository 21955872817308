<oui-accordion [expanded]="NCs && NCs.length > 0" [disabled]="!!NCs && NCs.length < 1" class="padding-vertical-24">
  <oui-accordion-header class="padding-vertical-8 padding-lateral-24">
    <oui-accordion-title [ngClass]="titleClass">{{ title }}&nbsp;({{ NCs.length }})</oui-accordion-title>
  </oui-accordion-header>
  <div class="flex-vertical gap-8">
    <ng-container *ngFor="let nc of NCs; let i = index">
      <app-non-conformite-fichier-card
        [nonConformite]="nc"
        [position]="i + 1"
        [isShowMenu]="isShowMenu"
        (closeNC)="closeNC($event)"
        (editNC)="editNC($event)"
        (reOpenNC)="reOpenNC($event)"></app-non-conformite-fichier-card>
    </ng-container>
  </div>
</oui-accordion>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { CreationPersonneMorale, InvestisseurFilterInput, InvestisseurType } from 'projects/box-lib/src/public-api';
import { CreationPMItem } from '../../creation-personne-morale-dashboard.component';
const SET_INVESTISSEUR_CREATION = gql`
  mutation setInvestisseurInCreationPersonneMorale($creationId: Int!, $investisseurId: Long!) {
    setInvestisseurInCreationPersonneMorale(creationPersonneMoraleId: $creationId, investisseurId: $investisseurId) {
      id
    }
  }
`;
@Component({
  selector: 'lib-creation-personne-morale-validation',
  templateUrl: './creation-personne-morale-validation.component.html',
  styleUrls: ['./creation-personne-morale-validation.component.scss'],
})
export class CreationPersonneMoraleValidationComponent {
  creation: CreationPMItem;
  investisseurFilter: InvestisseurFilterInput;
  atlasInvestisseurId: number | null | undefined;

  investisseurType = InvestisseurType;
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: { creation: CreationPMItem },
    private dialogRef: MatDialogRef<CreationPersonneMoraleValidationComponent>,
    private queryManager: QueryManagerService
  ) {}

  ngOnInit() {
    this.creation = this.data.creation;
    this.investisseurFilter = this.buildDefaultQueryFilter();
  }
  onValidCreation() {
    if (this.creation.id && this.atlasInvestisseurId) {
      this.queryManager
        .mutate<{
          setInvestisseurInCreationPersonneMorale: CreationPersonneMorale;
        }>({
          mutation: SET_INVESTISSEUR_CREATION,
          variables: { creationId: this.creation.id, investisseurId: this.atlasInvestisseurId },
        })
        .subscribe(result => {
          if (result.data?.setInvestisseurInCreationPersonneMorale.id) {
            this.dialogRef.close(result.data?.setInvestisseurInCreationPersonneMorale.id);
          }
        });
    }
  }
  buildDefaultQueryFilter(): InvestisseurFilterInput {
    const filter: InvestisseurFilterInput = {
      investisseurConsultant: {
        some: { and: [{ actif: { eq: 1 } }, { consultantId: { eq: this.creation.consultant?.id } }] },
      },
    };
    return filter;
  }

  onInvestisseurIdSelected(id: number | null | undefined) {
    this.atlasInvestisseurId = id;
  }
}

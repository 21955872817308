import { gql } from 'apollo-angular';

export const fondEvenementielFragment = gql`
  fragment fondEvenementielSimple on FondEvenementiel {
    id
    libelle
    produitFondEvenementiels {
      id
      fondEvenementielId
      produitId
    }
  }
`;

export const emailCheckFragment = gql`
  fragment emailCheck on EnvoiEmail {
    id
    messageId
    statutEnvoi
    statutMessage
    sendingDate
    isErrorAcknowledged
  }
`;

export const createSouscriptionFragment = gql`
  fragment createSouscriptionDetails on Operation {
    id
    operationGroupId
    operationType
    investisseur {
      id
    }
    coInvestisseur {
      id
    }
  }
`;

export const createActDeGestionFragment = gql`
  fragment createActeDeGestionDetails on Operation {
    id
    operationGroupId
    operationType
    investisseur {
      id
    }
    coInvestisseur {
      id
    }
    produitId
    produit {
      id
      nomTechniqueProduitBox
      nom
      enveloppeNavigation {
        code
        libelle
      }
      habilitationNavigation {
        id
      }
    }
    contratId
    contrat {
      id
    }
  }
`;

export const subscribeOperationDetailsFragment = gql`
  fragment subscribeOperationDetails on Operation {
    id
    operationType
    operationGroupId
    statutId
    statut {
      consultantLibelle
      backOfficeLibelle
    }
    activeOperationStateTransitionTriggers
    dateCreation
    produit {
      id
      nom
      nomTechniqueProduitBox
      enveloppeNavigation {
        code
        libelle
      }
      produitExtension {
        libelle
        souscriptionPossible
      }
      habilitationNavigation {
        id
        code
        libelle
      }
    }
    dateModification
    montant
    transactionPersonnelleStatut
    investisseurId
    coInvestisseurId
    investisseur {
      id
      investisseurEntite {
        type
        displayName
      }
    }
    coInvestisseur {
      id
      investisseurEntite {
        displayName
      }
    }
    consultantId
    contratId
    contrat {
      id
    }
    operationConfigId
    donneesSpecifiques
    operationConfig {
      allowMultiple
      enveloppeProduit {
        code
        libelle
      }
      natureOperation {
        libelle
        id
      }
      filteredNatureDocumentRequis {
        id
        natureDocument {
          id
          categorieId
          nom
          boxDocumentType {
            id
            isParentGedTypeHaveMultipleBoxDocumentTypes
            key
            gedParentTypeCode
          }
          categorie {
            libelle
          }
        }
        withAutoImport
        withAutoImportCoInv
      }
    }
    fichierOperations {
      id
      estHorsConfiguration
      estCoInvestisseur
      natureDocumentRequisId
      commentaire
      operationId
      fileId
      natureDocumentRequis {
        id
        natureDocument {
          id
          nom
          categorieId
          boxDocumentType {
            id
            isParentGedTypeHaveMultipleBoxDocumentTypes
            key
            gedParentTypeCode
          }
          categorie {
            libelle
          }
        }
      }
      metadata {
        investisseurId
        fileId
        denomination
        extension
        typeDocumentCode
        etatSignature
        dateDeDerniereModification
        fileName
        typeLibelle
      }
    }
    typeSignaturePartenaire
  }
`;

export const nonConformiteInstanceFragment = gql`
  fragment nonConformiteInstance on NonConformiteFichier {
    id
    categorie
    commentaire
    motif
    fichierOperationId
    isActive
    isInstancePartenaire
    isInternal
  }
`;

export const operationDetailsFragment = gql`
  fragment operationDetails on Operation {
    id
    operationType
    statut {
      id
      consultantLibelle
      backOfficeLibelle
    }
    produit {
      nom
      nomTechniqueProduitBox
      habilitation
      idPartenaireNavigation {
        nom
      }
      enveloppeNavigation {
        code
        libelle
      }
    }
    activeOperationStateTransitionTriggers
    dateCreation
    dateModification
    dateDeclaration
    consultantId
    commentaireGestionnaire
    montant
    operationActionRights
    donneesSpecifiques
    typeSignaturePartenaire
    operationConfig {
      id
      natureOperation {
        libelle
      }
      enveloppeProduit {
        code
        libelle
      }
    }
    investisseur {
      id
      code
      investisseurEntite {
        displayName
      }
      filesMetadata {
        fileId
        denomination
        extension
        typeDocumentCode
        etatSignature
        dateDeDerniereModification
        fileName
        typeLibelle
        actif
      }
    }
    coInvestisseur {
      id
      code
      investisseurEntite {
        displayName
      }
      filesMetadata {
        fileId
        denomination
        extension
        typeDocumentCode
        etatSignature
        dateDeDerniereModification
        fileName
        typeLibelle
      }
    }
    fichierOperations {
      id
      natureDocumentRequisId
      estHorsConfiguration
      operationId
      fileId
      natureDocumentRequis {
        id
        natureDocument {
          id
          nom
          boxDocumentType {
            id
            isParentGedTypeHaveMultipleBoxDocumentTypes
            key
            gedParentTypeCode
          }
          categorieId
          categorie {
            libelle
          }
        }
      }
      commentaire
      historyEntry {
        type
        timestamp
        initiatorBackOffice {
          displayName
        }
      }

      reponseConsultant
      fichierOperationStatut {
        id
        statut
      }
      nonConformiteFichiers {
        ...nonConformiteInstance
      }
      metadata {
        fileId
        fileName
        denomination
        typeLibelle
        creePar
        dateDeCreation
        derniereModificationPar
        dateDeDerniereModification
        extension
        typeDocumentCode
      }
    }
    transactionPersonnelleStatut
    fondEvenementiels {
      id
      libelle
    }
  }
  ${nonConformiteInstanceFragment}
`;

export const operationNoteFragment = gql`
  fragment operationNote on NoteOperation {
    id
    gestionnaireId
    operationId
    contenu
    gestionnaire {
      displayName
      tokenId
    }
    dateCreation
    dateDerniereModification
  }
`;

export const operationDispatchedFragment = gql`
  fragment operationDispatched on Operation {
    id
    gestionnaire {
      id
      displayName
    }
    statutId
    statut {
      id
      consultantLibelle
      backOfficeLibelle
    }
    activeOperationStateTransitionTriggers
    operationActionRights
  }
`;

export const operationTableBordereauInfoFragment = gql`
  fragment operationTableInfo on Operation {
    id
    statutId
    operationType
    montant
    statut {
      id
      consultantLibelle
      backOfficeLibelle
    }
    produitPreset {
      id
      nom
      habilitationCode
      habilitationLibelle
      partenaireLibelle
      partenaireId
      enveloppeCode
      enveloppeLibelle
    }
    investisseurPreset {
      id
      displayName
      type
    }
    coInvestisseurPreset {
      id
      displayName
    }
    operationConfig {
      id
      natureOperation {
        id
        libelle
      }
    }
  }
`;

export const consultantHabilitationFragment = gql`
  fragment consultantHabilitation on ConsultantHabilitation {
    habilitationId
    dateDebut
    statut
    dateFin
    consultantId
    codeHabilitation
    habilitation {
      id
      code
      libelle
    }
  }
`;

export const operationNotifyFragment = gql`
  fragment operationNotify on Operation {
    id
    statutId
    operationType
    produit {
      id
      nom
    }
    operationConfig {
      natureOperation {
        libelle
      }
    }
    investisseur {
      id
      investisseurEntite {
        displayName
      }
    }
    coInvestisseur {
      id
      investisseurEntite {
        displayName
      }
    }
    fichierOperations {
      id
      estHorsConfiguration
      estCoInvestisseur
      natureDocumentRequisId
      natureDocumentRequis {
        id
        natureDocument {
          id
          nom
        }
      }
      metadata {
        fileId
        extension
        fileName
        typeDocumentCode
        typeLibelle
        denomination
        creePar
        dateDeCreation
        derniereModificationPar
        dateDeDerniereModification
        url
      }
    }
    contrat {
      id
      numeroContrat
    }
    attachedFiles {
      id
      fileId
      fileNameWithExtension
      fileExtension
      denomination
      createdDate
      lastModificationDate
      fileConnectionInfo {
        sasUrl
        expiration
      }
    }
  }
`;

export const operationDetailsPageBackOfficeFragment = gql`
  fragment operationDetailsPage on Operation {
    id
    statutId
    operationType
    operationActionRights
    activeOperationStateTransitionTriggers
    commentaireGestionnaire
    messageTechnique
    montant
    transactionPersonnelleStatut
    statut {
      id
      consultantLibelle
      backOfficeLibelle
    }
    gestionnaire {
      displayName
      id
    }
    produit {
      id
      nom
      nomTechniqueProduitBox
      idPartenaireNavigation {
        nom
      }
      enveloppe
      enveloppeNavigation {
        libelle
      }
      habilitation
      habilitationNavigation {
        id
        libelle
        code
      }
    }
    dateCreation
    dateModification
    dateDeclaration
    consultantId
    consultant {
      id
      consultantHabilitations {
        ...consultantHabilitation
      }
      personnePhysique {
        nom
        prenom
      }
      titreCodeNavigation {
        libelle
      }
      tauxRemuneration
      email {
        adresse
      }
      telephone {
        numero
        typeTelephoneCode
      }
    }
    tags {
      id
      libelle
    }
    donneesSpecifiques
    operationConfig {
      natureOperation {
        libelle
      }
    }
    investisseur {
      id
      code
      investisseurConsultant(where: { actif: { eq: 1 } }) {
        consultantId
      }
      investisseurEntite {
        displayName
        type
      }
    }
    coInvestisseur {
      id
      code
      investisseurEntite {
        displayName
      }
    }
    fichierOperations {
      id
      historyEntry {
        type
        timestamp
        initiatorBackOffice {
          displayName
        }
      }
      natureDocumentRequisId
      estHorsConfiguration
      estCoInvestisseur
      operationId
      fileId
      natureDocumentRequis {
        id
        natureDocument {
          id
          nom
          categorieId
          boxDocumentType {
            id
            isParentGedTypeHaveMultipleBoxDocumentTypes
            key
            gedParentTypeCode
          }
          categorie {
            libelle
          }
        }
      }
      commentaire
      reponseConsultant
      fichierOperationStatut {
        id
        statut
      }
      nonConformiteFichiers {
        ...nonConformiteInstance
      }
      metadata {
        investisseurId
        fileId
        extension
        fileName
        typeLibelle
        typeDocumentCode
        denomination
        creePar
        dateDeCreation
        derniereModificationPar
        dateDeDerniereModification
      }
    }
    contrat {
      id
      numeroContrat
      numCommande
    }
    notes {
      ...operationNote
    }
    attachedFiles {
      id
      fileId
      fileNameWithExtension
      fileExtension
      denomination
      createdDate
      lastModificationDate
      fileConnectionInfo {
        sasUrl
        expiration
      }
    }
    fondEvenementiels {
      id
      libelle
    }
    emails {
      ...emailCheck
    }
    typeSignaturePartenaire
  }
  ${operationNoteFragment}
  ${nonConformiteInstanceFragment}
  ${consultantHabilitationFragment}
  ${emailCheckFragment}
`;

export const fichierOperationControlFragment = gql`
  fragment fichierOperationControl on FichierOperation {
    id
    natureDocumentRequisId
    operationId
    fileId
    historyEntry {
      type
      timestamp
      initiatorBackOffice {
        displayName
      }
    }
    natureDocumentRequis {
      id
      natureDocument {
        id
        nom
        boxDocumentType {
          id
          key
          gedParentTypeCode
        }
        categorieId
        categorie {
          libelle
        }
      }
    }
    commentaire
    estHorsConfiguration
    estCoInvestisseur
    reponseConsultant
    fichierOperationStatut {
      id
      statut
    }
    nonConformiteFichiers {
      ...nonConformiteInstance
    }
    metadata {
      investisseurId
      fileName
      typeLibelle
      typeDocumentCode
      creePar
      dateDeCreation
      fileId
      denomination
      url
      extension
      typeDocumentCode
      dateDeDerniereModification
    }
    operation {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      commentaireGestionnaire
    }
  }
  ${nonConformiteInstanceFragment}
`;

export const createFichierOperationFragment = gql`
  fragment createFichierOperation on FichierOperation {
    id
    commentaire
    natureDocumentRequisId
    natureDocumentRequis {
      id
      natureDocument {
        id
        categorieId
        nom
        boxDocumentType {
          id
          key
          gedParentTypeCode
        }
        categorie {
          libelle
        }
      }
    }
    metadata {
      fileName
      denomination
      typeLibelle
      fileId
      investisseurId
    }
    operationId
    fileId
    estHorsConfiguration
    estCoInvestisseur
  }
`;

<div class="flex-vertical">
  <div class="flex-horizontal flex-center padding-right-24">
    <h6 mat-dialog-title>Supprimer un utilisateur</h6>
    <div class="flex-1"></div>
    <oui-icon mat-dialog-close>close</oui-icon>
  </div>
  <mat-dialog-content>
    <div class="flex-vertical flex-start gap-16">
      <div class="body-1 color-on-surface-emphasis">
        Vous êtes sur le point de supprimer un utilisateur. Il sera supprimé jusqu'à sa prochaine connexion
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="padding-right-24 padding-bottom-24">
    <div class="flex-1"></div>
    <oui-button type="text" class="flex-none" (click)="cancel()"> Annuler </oui-button>
    <oui-button type="contained" class="flex-none padding-left-8" (click)="validate()"> Supprimer </oui-button>
  </mat-dialog-actions>
</div>

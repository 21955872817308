import { Injectable } from '@angular/core';
import { FileMetadata } from '../models/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class FilesSelectorService {
  private selection: FileMetadata[] = [];
  private files: FileMetadata[] = [];
  constructor() {}
  setFiles(files: FileMetadata[]) {
    this.files = files.filter(f => f.fileId);
    this.selection = [];
  }
  getFilesCount() {
    return this.files.length;
  }
  getSelectionCount() {
    return this.selection.length;
  }
  toggleFileSelection(selectId: string) {
    if (this.selection.find(metadata => metadata.fileId === selectId) !== undefined) {
      this.selection = this.selection.filter(metadata => metadata.fileId !== selectId);
    } else {
      let fileToAdd = this.files.find(metadata => metadata.fileId === selectId);
      if (fileToAdd) {
        this.selection.push(fileToAdd);
      }
    }
  }
  toggleAllRows() {
    if (this.files) {
      if (this.isAllSelected()) {
        this.selection = [];
      } else {
        this.selection = this.files.filter(metadata => !!metadata.fileId);
      }
    }
  }

  isAllSelected(): boolean {
    return this.selection.length == this.files?.length;
  }
  hasSelected(): boolean {
    return this.selection.length > 0;
  }

  isIncluded(fileId: string): boolean {
    return this.selection.find(metadata => metadata.fileId === fileId) !== undefined;
  }
  getSelection(): FileMetadata[] {
    return this.selection;
  }
  getSelectionFileIdList(): string[] {
    return this.selection.map(metadata => metadata.fileId!);
  }
}

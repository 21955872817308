<div class="param-layout">
  <div class="flex-horizontal">
    <h4>Types document Box</h4>
    <div class="flex-1"></div>
    <!-- <oui-button class="contained" (click)="addFondEvenementiel()">Ajouter un fond événementiel</oui-button> -->
  </div>

  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
    <div class="flex-horizontal gap-24">
      <div class="flex-1">
        <oui-searchbar placeholder="Rechercher un type de document" [control]="searchControl"></oui-searchbar>
      </div>
      <div class="flex-1"></div>
      <div class="flex-none">
        <oui-button type="outlined" (click)="onCreateNewBoxDocumentType()" class="width-100pc top-margin-8"
          >Créer un nouveau type</oui-button
        >
      </div>
    </div>

    <app-doc-types-table
      [receivedData]="requestResult"
      (requestNewPage)="getAllDocTypes()"
      [(paginationParams)]="paginationParams">
    </app-doc-types-table>
  </div>
</div>

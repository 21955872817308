import { Component, Input } from '@angular/core';
import { OuiTagModule, OuiTagStatus } from 'omnium-ui/tag';
import { FichierOperationStatutEnum } from '../../models/FichierOperationStatutEnum';
import { FichierOperation } from '../../models/generated/graphql';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-file-statut-tag',
  standalone: true,
  imports: [CommonModule, OuiTagModule],
  templateUrl: './file-statut-tag.component.html',
  styleUrls: ['./file-statut-tag.component.scss'],
})
export class FileStatutTagComponent {
  statusText: string = '';
  statusColor: OuiTagStatus = 'in-progress';

  private _file: FichierOperation;
  public get file(): FichierOperation {
    return this._file;
  }

  @Input()
  public set file(value: FichierOperation) {
    this._file = value;
    this.statusText = this.getStatusText(value);
    this.statusColor = this.getStatusColor(value);
  }

  getStatusText(file: FichierOperation): string {
    switch (file.fichierOperationStatut.statut) {
      case FichierOperationStatutEnum.compliant:
        return 'Conforme';
      case FichierOperationStatutEnum.updatedByConsulant:
        return 'Mis à jour';
      case FichierOperationStatutEnum.created:
        return 'À contrôler';
      case FichierOperationStatutEnum.nonCompliant:
        if (!file.fileId) {
          return 'Manquant';
        }
        return 'À corriger';
      default:
        return 'statut';
    }
  }
  getStatusColor(file: FichierOperation): OuiTagStatus {
    switch (file.fichierOperationStatut.statut) {
      case FichierOperationStatutEnum.compliant:
        return 'completed';
      case FichierOperationStatutEnum.updatedByConsulant:
        return 'transition';
      case FichierOperationStatutEnum.created:
        return 'in-progress';
      case FichierOperationStatutEnum.nonCompliant:
        return 'error';
      default:
        return 'in-progress';
    }
  }
}

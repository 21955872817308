<oui-document-viewer [documentUrl]="documentUrl" [showControls]="true">
  <ng-container oui-document-controls>
    <lib-document-viewer-header
      [documentName]="headerDocumentName"
      [fileName]="fileMetadata?.fileName ?? ''"
      [dateAjout]="fileMetadata?.dateDeDerniereModification"
      [menu]="fileMenu"
      (close)="close.emit()">
    </lib-document-viewer-header>

    <div class="flex-vertical" *ngIf="commentaire">
      <div class="body-2 color-on-surface-medium">Commentaire</div>
      <div class="body-2 color-on-surface-emphasis" [innerText]="commentaire"></div>
    </div>

    <ng-container *ngIf="fichierOperationStatut?.statut == compliant">
      <app-file-conform-status-stamp [historyEntries]="historyEntries"></app-file-conform-status-stamp>
    </ng-container>
    <ng-container *ngIf="!!nCtoDisplayList && nCtoDisplayList.length">
      <app-non-conformite-accordion
        [NCs]="nCtoDisplayList"
        [isShowMenu]="false"
        [title]="ncTitle"></app-non-conformite-accordion>
    </ng-container>
  </ng-container>
</oui-document-viewer>

<div class="flex-vertical gap-16 width-100pc">
  <ng-container *ngIf="selection.length; then dispatcher; else counter"></ng-container>
  <ng-template #counter>
    <div class="flex-horizontal flex-center gap-16">
      <div *ngIf="withTotalNumberDisplayed">
        {{ totalCount }} <span *ngIf="totalCount > 1">opérations</span><span *ngIf="totalCount < 2">opération</span>
      </div>
      <oui-button *ngIf="withRefresh" (click)="fetchData(true)" type="text" iconPrefix="cached" size="small"
        >Actualiser</oui-button
      >
      <oui-button (click)="exportData()" type="text" iconPrefix="file_upload" size="small" *ngIf="withCSVExport"
        >Exporter</oui-button
      >
    </div>
  </ng-template>
  <ng-template #dispatcher>
    <div
      class="flex-horizontal flex-center background-background fakeHeader with-border gap-16"
      *ngIf="withSelects && selection.length">
      <div class="subtitle-2">
        {{ selection.length }}
        <span *ngIf="selection.length > 1">opérations sélectionnées</span
        ><span *ngIf="selection.length < 2">opération sélectionnée</span>
      </div>
      <div>
        <oui-button class="body-2" (click)="unselectAll()" type="text"><u>Déselectionner</u></oui-button>
      </div>
      <div>
        <oui-button (click)="onSelectionActionClick()" type="text" iconPrefix="contact_page">{{
          selectionActionLabel
        }}</oui-button>
      </div>
    </div>
  </ng-template>
  <div class="width-100pc">
    <oui-table *ngIf="!isLoading && dataSource.filteredData.length > 0">
      <table mat-table [dataSource]="dataSource" matSort #operationsSort="matSort">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <oui-checkbox
              (checkedChange)="toggleAllRows()"
              [checked]="selection.length > 0 && isAllSelected()"
              [indeterminate]="selection.length > 0 && !isAllSelected()">
            </oui-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <oui-checkbox
              (click)="$event.stopPropagation()"
              (checkedChange)="selectOperation(row.id, $event)"
              [checked]="isOperationSelected(row.id)">
            </oui-checkbox>
          </td>
        </ng-container>

        <ng-container *ngFor="let columnconfig of columnSelection" matColumnDef="{{ columnconfig.header }}">
          <ng-container *ngIf="columnconfig?.withSort">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disableClear]="true">
              <ng-container
                *ngIf="
                  columnconfig.header === 'Warning' ||
                    columnconfig.header === 'DeleteDraft' ||
                    columnconfig.header === 'Picto';
                  else textHeader
                ">
              </ng-container>
              <ng-template #textHeader> {{ columnconfig.header }}</ng-template>
            </th>
          </ng-container>
          <ng-container *ngIf="!columnconfig?.withSort">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container
                *ngIf="
                  columnconfig.header === 'Warning' ||
                    columnconfig.header === 'DeleteDraft' ||
                    columnconfig.header === 'Picto';
                  else textHeader
                ">
              </ng-container>
              <ng-template #textHeader> {{ columnconfig.header }}</ng-template>
            </th>
          </ng-container>
          <td mat-cell *matCellDef="let operation">
            <ng-container [ngSwitch]="columnconfig.header">
              <ng-container *ngSwitchCase="'Warning'">
                <oui-icon *ngIf="displayColumnInfo(columnconfig, operation) === 'warning'; else comment" color="error"
                  >report_problem</oui-icon
                ><ng-template #comment>
                  <lib-commentaire-cell [operation]="operation"></lib-commentaire-cell>
                </ng-template>
              </ng-container>
              <ng-container *ngSwitchCase="'DeleteDraft'">
                <oui-button
                  type="icon"
                  *ngIf="displayColumnInfo(columnconfig, operation) === 'delete'"
                  (click)="onDeleteDraftClick(operation); $event.stopPropagation()"
                  color="secondary"
                  ><oui-icon>delete</oui-icon>
                </oui-button>
              </ng-container>

              <ng-container *ngSwitchCase="'Statut'">
                <app-operation-statut-tag [statut]="operation?.statut"></app-operation-statut-tag>
                <ng-container *ngIf="isBackOffice">
                  <div class="flex-horizontal"></div>
                  <oui-tag size="big" type="status" status="pending" *ngFor="let chip of operation.tags">{{
                    chip.libelle
                  }}</oui-tag>
                </ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'Investisseur'">
                <lib-investisseur-cell
                  [operation]="operation"
                  [displayName]="displayColumnInfo(columnconfig, operation)"></lib-investisseur-cell>
              </ng-container>

              <ng-container *ngSwitchCase="'Consultant'">
                <lib-consultant-cell
                  [operation]="operation"
                  [displayName]="displayColumnInfo(columnconfig, operation)"></lib-consultant-cell>
              </ng-container>
              <ng-container *ngSwitchCase="'Nature'">
                <lib-nature-cell
                  [operation]="operation"
                  [displayName]="displayColumnInfo(columnconfig, operation)"></lib-nature-cell>
              </ng-container>
              <ng-container *ngSwitchCase="'Picto'">
                <oui-icon *ngIf="displayColumnInfo(columnconfig, operation)" class="alert-icon">error_outline</oui-icon>
              </ng-container>
              <div *ngSwitchDefault style="white-space: pre-line">
                {{ displayColumnInfo(columnconfig, operation) ?? '-' }}
              </div>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container>
          <tr
            [class.interactive]="withClickableRow"
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"></tr>
        </ng-container>
      </table>
      <oui-paginator
        *ngIf="withPaginator"
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
    <div class="etat-vide-container" *ngIf="isLoading || dataSource.filteredData.length === 0">
      <oui-etat-vide *ngIf="!isLoading" title="Aucun résultat" icon="live_help"></oui-etat-vide>
      <oui-loading *ngIf="isLoading"></oui-loading>
    </div>
  </div>
</div>

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@lib/services/auth-service.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  // Recuperer le token generer et le stocker dans le header  en complement du bearer
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.backEndUrl)) {
      const idToken = sessionStorage.getItem(AuthService.token_sessionstorage_key);

      //FIXME : mettre en place un systeme générique
      if (idToken) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + idToken),
        });

        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    }
    return next.handle(req);
  }
}

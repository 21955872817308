<div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-16">
  <div class="flex-vertical gap-16 width-100pc">
    <div class="flex-horizontal flex-center gap-16" *ngIf="partenaireOptions.length > 0">
      <oui-select
        class="thirdWidth"
        [options]="partenaireOptions"
        [control]="partenaireControl"
        label="Sélectionner un partenaire">
      </oui-select>
      <oui-button
        *ngIf="selectedOperationsPerPartenaireList.length != operationsPerPatenaireList.length"
        (click)="resetPartenaireSelection()"
        type="text"
        >Afficher tous les partenaires</oui-button
      >
    </div>
    <oui-accordion [expanded]="true" *ngFor="let entry of selectedOperationsPerPartenaireList">
      <oui-accordion-header class="padding-vertical-8">
        <oui-accordion-title class="subtitle-1">{{
          getPartenaireTitleById(entry.key, entry.value.operations.length)
        }}</oui-accordion-title>
      </oui-accordion-header>
      <div class="flex-vertical">
        <app-operations-table
          [receivedData]="{
            loading: entry.value.loading,
            data: { items: entry.value.operations, totalCount: entry.value.operations.length },
          }"
          (requestNewPage)="getAllOperations()"
          [(paginationParams)]="paginationParams"
          [columnSelection]="columnSelection"
          (onOperationRowClick)="onRowClick($event)"
          [withSelects]="!entry.value.loading"
          (onSelectionActionClicked)="onCreateBordereau($event, entry.key, entry.value)"
          [withPaginator]="false"
          [withTotalNumberDisplayed]="false"
          [fetchOnInit]="false"
          [selectionActionLabel]="'Générer un bordereau'"
          [withClickableRow]="false"
          [withRefresh]="false">
        </app-operations-table>
      </div>
    </oui-accordion>
    <div class="etat-vide-container" *ngIf="partenaireOptions.length == 0 || operationsPerPatenaireList.length == 0">
      <oui-etat-vide
        *ngIf="!loading"
        title="Aucune opération disponible pour générer un bordereau"
        icon="folder_open"></oui-etat-vide>
      <oui-loading *ngIf="loading"></oui-loading>
    </div>
  </div>
</div>

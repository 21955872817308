<div oui-dialog-title>
  {{ title ?? 'Confirmation' }}
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content>
  <div class="flex-vertical flex-center">
    <oui-loading size="small" *ngIf="isMutating"></oui-loading>
  </div>

  <ng-container *ngIf="!isMutating">
    <div class="flex-vertical gap-16">
      <div class="body-1">{{ message }}</div>
      <oui-select
        *ngIf="data?.isReject"
        [multiple]="false"
        [options]="motifRejetOptions"
        [control]="motifRejet"
        placeholder="Motif"
        label="Motif">
      </oui-select>

      <oui-select
        *ngIf="!data?.isReject"
        [multiple]="false"
        [options]="motifAnnulationOptions"
        [control]="motifAnnulation"
        placeholder="Motif"
        label="Motif">
      </oui-select>
      <oui-textarea label="Commentaire" placeholder="Commentaire" [control]="comment"></oui-textarea>
    </div>
  </ng-container>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" (click)="onCancel()"> Annuler </oui-button>
  <oui-button type="contained" (click)="onValidate()" [disabled]="!canValidate || isMutating">
    {{ 'Valider' }}
  </oui-button>
</oui-dialog-actions>

import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { MatSort, SortDirection } from '@angular/material/sort';
import { AbstractPaginatedTableComponent } from '../../../components/paginated-table/paginated-table.component';
import { Operation, OperationSortInput } from '../../../models/generated/graphql';
import { MAJHeader, OperationDashboardColumn } from '../operations-dashboard-columns';

import { AuthService } from '../../../services/auth-service.service';

@Component({
  selector: 'app-operations-table',
  templateUrl: './operations-table.component.html',
  styleUrls: ['./operations-table.component.scss'],
})
export class OperationsTableComponent extends AbstractPaginatedTableComponent<Operation> {
  @ViewChildren('operationsSort') operationsSort!: QueryList<MatSort>;

  isOperationSelected(operationId: number): boolean {
    // return this.selection.isSelected(operationId)
    return this.selection.includes(operationId);
  }
  selectOperation(operationId: number, state: boolean) {
    // this.selection.toggle(operationId);
    if (this.selection.includes(operationId)) {
      this.selection.splice(this.selection.indexOf(operationId), 1);
    } else {
      this.selection.push(operationId);
    }
  }

  @Input()
  columnSelection: OperationDashboardColumn[] = [];

  // display paginator
  @Input() withPaginator: boolean = true;

  // display CSV export
  @Input() withCSVExport: boolean = false;

  // display CSV export
  @Input() withRefresh: boolean = true;

  // display number of operations
  @Input() withTotalNumberDisplayed: boolean = true;

  // display if true will fetch data on init
  @Input() fetchOnInit: boolean = true;

  // display checkboxes
  @Input() withSelects: boolean = false;
  // display dispatcher options
  @Input() selectionActionLabel: String = '';

  // are rows clickable
  @Input() withClickableRow: boolean = true;

  @Output()
  onOperationRowClick = new EventEmitter<Operation>();

  @Output()
  onSelectionActionClicked = new EventEmitter<Operation[]>();

  @Output()
  onDeleteDraftClicked = new EventEmitter<Operation>();

  @Output()
  onSortCriteriaUpdate = new EventEmitter<OperationSortInput | undefined>();

  @Output()
  onExportClick = new EventEmitter();

  selection: number[] = [];

  displayedColumns: string[];

  isBackOffice: boolean;

  sortActive?: string;
  sortDirection?: SortDirection;

  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    authService: AuthService,
    private cdref: ChangeDetectorRef
  ) {
    super();
    this.isBackOffice = authService.isBackOffice();
  }

  ngOnInit() {
    this.displayedColumns = this.withSelects ? ['select'] : [];
    this.displayedColumns.push(...this.columnSelection.map(column => column.header));
    if (this.fetchOnInit) {
      this.fetchData(true);
    }

    if (this.displayedColumns.includes(MAJHeader)) {
      this.sortActive = MAJHeader;
      this.sortDirection = 'desc';
    }
  }

  private updateSortCriteria(sort: MatSort) {
    let querySortCriteria = this.wrapSortCriteria(sort);
    this.onSortCriteriaUpdate.emit(querySortCriteria);
  }
  private wrapSortCriteria(sort: MatSort): OperationSortInput | undefined {
    let column = this.columnSelection.find(column => column.header === sort.active);
    if (!column || sort.direction == '') {
      return undefined;
    }
    let sortCriteria = column?.getSortCriteria(sort.direction === 'asc');
    return sortCriteria;
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    this.operationsSort.changes.subscribe(_ => {
      if (this.operationsSort.length > 0) {
        let sort = this.operationsSort.first;
        if (sort) {
          if (this.sortActive && this.sortDirection) {
            sort.sort({ id: this.sortActive, start: this.sortDirection, disableClear: true });
            this.cdref.detectChanges();
          }
          sort.sortChange.subscribe(() => {
            this.sortActive = sort.active;
            this.sortDirection = sort.direction;
            this.updateSortCriteria(sort);
          });
        }
      }
    });
  }

  onRowClick(operation: Operation) {
    if (this.withClickableRow) {
      this.onOperationRowClick.emit(operation);
    }
  }

  displayColumnInfo(column: OperationDashboardColumn, operation: Operation) {
    return column.getDataFromOperation(operation, this.currencyPipe, this.datePipe);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.unselectAll();
      return;
    } else {
      this.selection = this.dataSource.data.map(operation => operation.id);
    }
  }

  getSelected(): Operation[] {
    const selectedIds = this.selection;
    return this.dataSource.data.filter(operation => selectedIds.includes(operation.id));
  }

  unselectAll() {
    this.selection = [];
  }

  onSelectionActionClick() {
    this.onSelectionActionClicked.emit(this.getSelected());
  }

  onDeleteDraftClick(operation: Operation) {
    this.onDeleteDraftClicked.emit(operation);
  }

  exportData() {
    this.onExportClick.emit();
  }
}

import { Component, Inject } from '@angular/core';
import { OperationStateTransitionTrigger } from '../../../models/generated/graphql';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum TransferType {
  courrier = 'courrier',
  email = 'email',
  extranet = 'extranet',
  direct = 'direct',
}

@Component({
  selector: 'lib-transmission-partenaire-dialog',
  templateUrl: './transmission-partenaire-dialog.component.html',
  styleUrls: ['./transmission-partenaire-dialog.component.scss'],
})
export class TransmissionPartenaireDialogComponent {
  protected transfertTypeEnum = TransferType;

  transfertType?: TransferType;
  operationId?: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { operationId?: number },
    private dialogRef: MatDialogRef<TransmissionPartenaireDialogComponent>
  ) {
    this.operationId = data.operationId;
  }

  onTransmissionModeSelected(transfer: TransferType) {
    if (transfer === TransferType.courrier) {
      this.dialogRef.close(OperationStateTransitionTrigger.GestionnaireTransfersOperationToPartenaireByCourrier);
    }

    this.transfertType = transfer;
  }

  onValidation(isSucces: boolean) {
    if (isSucces) {
      this.dialogRef.close(OperationStateTransitionTrigger.GestionnaireTransfersOperationToPartenaire);
    }
  }
}

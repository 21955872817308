import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { OperationSpecificData } from 'projects/box-lib/src/lib/models/donees-specifiques.model';
import {
  FondEvenementiel,
  Operation,
  TransactionPersonnelleEnum,
  TypeSignaturePartenaire,
  UpdateOperationInput,
} from '../../../models/generated/graphql';
import { FondsEvenementielsService } from '../../../services/fonds-evenementiels.service';
import { OperationsService } from '../../../services/operations.service';
import { OptionsService } from '../../../services/options.service';

export type OperationChamp =
  | 'typeSignaturePartenaire'
  | 'fondsEvenementiels'
  | 'montant'
  | 'modePaiement'
  | 'transaction-personnelle'
  | 'objetModification';

export type OperationUpdateDialogData = { operation: Operation; champ: OperationChamp; currentValue: any };

@Component({
  selector: 'lib-operation-update-dialog',
  templateUrl: './operation-update-dialog.component.html',
  styleUrls: ['./operation-update-dialog.component.scss'],
})
export class OperationUpdateDialogComponent implements OnInit {
  signatureOptions: OuiSelectOption<TypeSignaturePartenaire>[];
  fondsEvenementielsOptions: OuiSelectOption[];
  modePaiementOptions: OuiSelectOption<string>[];
  transactionPersonnelleOptions: OuiSelectOption<TransactionPersonnelleEnum>[];
  objetModificationOptions: OuiSelectOption<string>[];

  control = new FormControl<any>(null, Validators.required);
  toggleControl = new FormControl<boolean>(false);

  habiliationSet: Set<number>;
  currentValue: any;

  canSave: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OperationUpdateDialogData,
    private dialogRef: MatDialogRef<OperationUpdateDialogComponent>,
    private optionsService: OptionsService,
    private operationsService: OperationsService,
    private fondsEvenementielsService: FondsEvenementielsService,
    private snackbarService: OuiSnackbarService
  ) {
    this.habiliationSet = new Set([data.operation.produit?.habilitation] ?? []);
    this.currentValue = data.currentValue;
  }

  ngOnInit(): void {
    switch (this.data.champ) {
      case 'montant':
        this.initMontant();
        break;
      case 'typeSignaturePartenaire':
        this.initTypeSignature();
        break;
      case 'fondsEvenementiels':
        this.initFondsEvenementiels();
        break;

      case 'modePaiement':
        this.initModePaiement();
        break;
      case 'objetModification':
        this.initObjetModification();
        break;
    }
  }

  initTypeSignature() {
    this.signatureOptions = this.optionsService.getTypeSignaturePartenaireOptions();
    this.control.setValue(this.data.currentValue);
  }
  initAutoSouscription() {
    this.toggleControl.setValue(this.data.currentValue);
  }
  initMontant() {
    this.control = new FormControl<number>(this.data.currentValue, Validators.required);
    this.control.valueChanges.subscribe(value => {
      this.canSave = value && value >= 0;
    });
  }
  initModePaiement() {
    this.modePaiementOptions = this.optionsService.getModePaiementOptions();
    this.control.setValue(this.data.currentValue);
  }
  initObjetModification() {
    this.objetModificationOptions = this.optionsService.getObjetModificationOptions();
    this.control.setValue(this.data.currentValue);
  }

  async initFondsEvenementiels() {
    const fondsEvenementiels = await this.fondsEvenementielsService.getfondEvenementielsByProductId(
      this.data.operation.produit!.id
    );

    this.fondsEvenementielsOptions = fondsEvenementiels.map(
      fe =>
        <OuiSelectOption>{
          label: fe.libelle,
          value: fe.id,
        }
    );

    this.control.setValue(this.data.currentValue.map((fe: FondEvenementiel) => fe.id));
  }

  updateTransactionPersonelleStatut(transaction: TransactionPersonnelleEnum) {
    this.control.setValue(transaction);
  }

  async saveOperation() {
    const op = this.data.operation;

    const updateModel: UpdateOperationInput = {
      operationId: op.id,
      operationGroupId: op.operationGroupId ?? 0,
    };

    let donneesSpecifiques: OperationSpecificData = this.operationsService.parseSpecificData(
      this.data.operation.donneesSpecifiques
    );

    // Mise à jour du champ
    switch (this.data.champ) {
      case 'typeSignaturePartenaire':
        updateModel.typeSignaturePartenaire = this.control.value;
        updateModel.donneesSpecifiques = JSON.stringify(donneesSpecifiques);
        break;
      case 'fondsEvenementiels':
        donneesSpecifiques.fondEvenementielIds = this.control.value;
        updateModel.donneesSpecifiques = JSON.stringify(donneesSpecifiques);
        break;
      case 'transaction-personnelle':
        updateModel.transactionPersonnelleStatut = this.control.value;
        break;
      case 'montant':
        donneesSpecifiques.montant = this.control.value;
        updateModel.donneesSpecifiques = JSON.stringify(donneesSpecifiques);
        break;
      case 'modePaiement':
        donneesSpecifiques.modePaiement = this.control.value;
        updateModel.donneesSpecifiques = JSON.stringify(donneesSpecifiques);
        break;
      case 'objetModification':
        donneesSpecifiques.objetModification = this.control.value;
        updateModel.donneesSpecifiques = JSON.stringify(donneesSpecifiques);
        break;
    }

    try {
      const result = await this.operationsService.updateOperation(updateModel);
      this.snackbarService.open("L'opération a bien été mise à jour", 'success');
      this.dialogRef.close(result);
    } catch (error) {
      this.snackbarService.open("Une erreur s'est produite durant la mise à jour de l'opération", 'error');
    }
  }
}

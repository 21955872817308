<div oui-dialog-title>
  {{ title ?? 'Confirmation' }}
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <oui-checkbox [(checked)]="checked" [label]="message ?? 'Voulez-vous confimer cette action ?'"></oui-checkbox>
  </div>
</oui-dialog-content>

<oui-dialog-actions class="padding-right-24 padding-bottom-24">
  <div class="flex-1"></div>
  <oui-button type="text" class="flex-none" (click)="cancel()">
    {{ cancelButtonLabel ?? 'Annuler' }}
  </oui-button>
  <oui-button type="contained" class="flex-none padding-left-8" (click)="validate()" [disabled]="!checked">
    {{ validateButtonLabel ?? 'Confirmer' }}
  </oui-button>
</oui-dialog-actions>

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDocumentViewer } from 'omnium-ui/document-viewer';
import { OuiTextarea } from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { FichierOperation } from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';
import { DocumentViewerHeaderComponent } from '../_common/document-viewer-header/document-viewer-header.component';

export type AppendedDocumentViewerResult = {
  fichierOperation: FichierOperation;
  comment: string | null;
};

export type AppendedDocumentViewerRemoveEvent = {
  fichierOperationToRemove: FichierOperation;
};

@Component({
  selector: 'app-appended-document-viewer',
  templateUrl: './appended-document-viewer.component.html',
  styleUrls: ['./appended-document-viewer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OuiDocumentViewer,
    OuiIconModule,
    OuiTextarea,
    OuiButtonModule,
    DocumentViewerHeaderComponent,
  ],
})
export class AppendedDocumentViewerComponent {
  private _fichierOperation: FichierOperation;
  public get fichierOperation(): FichierOperation {
    return this._fichierOperation;
  }

  @Input({ required: true })
  public set fichierOperation(value: FichierOperation) {
    this._fichierOperation = value;
    this.initDocument();
  }

  @Output()
  close = new EventEmitter<AppendedDocumentViewerResult | undefined>();

  @Output()
  remove = new EventEmitter<AppendedDocumentViewerRemoveEvent>();

  commentControl: FormControl<string | null> = new FormControl<string>('');
  documentUrl: string;
  headerDocumentName: string;

  constructor(protected documentService: DocumentsService) {}

  private async initDocument() {
    let fileCacheUrl;

    if (this.fichierOperation?.metadata) {
      fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(this.fichierOperation.metadata);
    }

    if (fileCacheUrl) {
      this.documentUrl = fileCacheUrl;
    }

    this.commentControl.setValue(this.fichierOperation.commentaire ?? '');

    this.headerDocumentName =
      this.fichierOperation.natureDocumentRequis?.natureDocument?.nom ??
      this.fichierOperation.metadata?.denomination ??
      DEFAULT_BOX_DOCUMENT_TYPE.key;
  }

  closeDocumentViewer(): void {
    if (this.fichierOperation) {
      this.close.emit({ comment: this.commentControl.value, fichierOperation: this.fichierOperation });
    } else {
      this.close.emit();
    }
  }

  removeFicherOperation() {
    if (this.fichierOperation) {
      this.remove.emit({ fichierOperationToRemove: this.fichierOperation });
    }
  }
}

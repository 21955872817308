import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import {
  EnvoiEmail,
  FichierOperationHistoryRecord,
  FichierOperationHistoryRecordTypes,
  FileMetadata,
  Operation,
  OperationHistoryRecord,
  OperationHistoryRecordTypes,
  OperationsPaginatedCollectionSegment,
} from '../models/generated/graphql';
import { OptionsService } from './options.service';

const fetchOperationHistory = gql`
  query fetchOperationHistory($operationId: Int) {
    allOperationsPaginated(skip: 0, take: 1, where: { id: { eq: $operationId } }, order: [{}]) {
      items {
        id
        motifAnnulation
        motifRejet
        motifRejetCancelCommentaire
        statutId
        historyEntry {
          type
          timestamp
          initiatorConsultantId
          initiatorBackOffice {
            displayName
          }
          gestionnaireComment
          tags {
            code
            libelle
          }
          previousStatut {
            id
            consultantLibelle
            backOfficeLibelle
          }
          newStatut {
            id
            consultantLibelle
            backOfficeLibelle
          }
          dispatchTo {
            displayName
          }
          mailContent
          consultant {
            personnePhysique {
              nom
              prenom
            }
          }
          envoiPartenaireId
        }
        fichierHistoryEntry {
          id
          fichierOperation {
            id
            natureDocumentRequis {
              natureDocument {
                id
                nom
              }
            }
          }
          type
          timestamp
          initiatorConsultantId
          initiatorBackOffice {
            displayName
          }
          fileId
          fileMetadata {
            fileName
            fileId
            denomination
            dateDeDerniereModification
            extension
          }
          motif
          categorie
          comment
          isInternal
          consultant {
            personnePhysique {
              nom
              prenom
            }
          }
        }
        emails {
          subject
          sendingDate
          messageId
          senderBackOffice {
            displayName
          }
        }
      }
    }
  }
`;

export interface HistoryDisplayedRecord {
  icon: string;
  actionText: string;
  header: string;
  details: string[];
  timestamp: Date;
  fileMetadata?: FileMetadata;
  emailSubject?: string;
  emailSendingDate?: Date;
  emailMessageId?: string;
  bordereauId?: number;
  visibleConsultant?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  datepipe: DatePipe = new DatePipe('fr-FR');

  constructor(private queryManager: QueryManagerService, private optionsService: OptionsService) {}

  public async fetchHistory(
    operationId: number,
    fichierOperationId?: number,
    isConsultant = false
  ): Promise<HistoryDisplayedRecord[]> {
    const result = await firstValueFrom(
      this.queryManager.query<{
        allOperationsPaginated: OperationsPaginatedCollectionSegment;
      }>({
        query: fetchOperationHistory,
        variables: {
          operationId: operationId,
        },
        fetchPolicy: 'network-only',
      })
    );

    if (!result.data.allOperationsPaginated.items || result.data.allOperationsPaginated.items.length <= 0) {
      return [];
    }
    let operation = result.data.allOperationsPaginated.items[0];
    // init history record list with operation records
    let operationHistoryRecords =
      operation?.historyEntry?.map(rec => this.formatOperationHistoryRecord(operation, rec, isConsultant)) ?? [];

    let fichierOperationHistoryRecords: HistoryDisplayedRecord[] = [];
    if (operation.fichierHistoryEntry) {
      fichierOperationHistoryRecords = operation.fichierHistoryEntry
        .filter(rec => !fichierOperationId || rec.fichierOperation.id == fichierOperationId) // if fichierOperationId is specified should only display records of this fichier Operation
        .map(rec => this.formatFichierOperationHistoryRecord(rec, isConsultant));
    }
    let emailsHistoryRecords: HistoryDisplayedRecord[] = [];
    if (!isConsultant && operation.emails) {
      emailsHistoryRecords = operation.emails.map(mail => this.formatEmailHistory(mail, isConsultant));
    }

    let historyRecord = [];
    if (fichierOperationId) {
      // if fichierOperationId is specified should only display records of this fichier Operation
      historyRecord = fichierOperationHistoryRecords;
    } else {
      historyRecord = [...operationHistoryRecords, ...fichierOperationHistoryRecords, ...emailsHistoryRecords];
    }

    // then sort all history records by decreasing timestamp
    historyRecord.sort((a, b) => {
      const timeA = a?.timestamp?.getTime() ?? 0;
      const timeB = b?.timestamp?.getTime() ?? 0;
      return b.timestamp.getTime() - a.timestamp.getTime();
    });

    if (isConsultant) {
      //for consultant filter diplayed records
      return historyRecord.filter(rec => rec.visibleConsultant);
    }

    return historyRecord;
  }

  private formatCategory(category: string): string {
    switch (category) {
      case 'CONTRAT':
        return 'Contrat';
      case 'FICHIER':
        return 'Fichier';
      default:
        return category;
    }
  }
  private formatMotif(motif: string): string {
    switch (motif) {
      case 'CONTRAT':
        return 'Contrat';
      case 'FICHIER':
        return 'Fichier';
      default:
        return motif;
    }
  }

  private formatInitiator(entry: OperationHistoryRecord | FichierOperationHistoryRecord): String {
    if (entry.initiatorBackOffice?.displayName) {
      return entry.initiatorBackOffice.displayName;
    } else if (entry.initiatorConsultantId) {
      let firstName = entry.consultant?.personnePhysique ? entry.consultant?.personnePhysique[0]?.prenom : '';
      let lastName = entry.consultant?.personnePhysique ? entry.consultant?.personnePhysique[0]?.nom : '';
      if (firstName === '' && lastName === '') {
        return 'inconnu';
      }
      return firstName + ' ' + lastName;
    } else {
      return 'inconnu';
    }
  }

  private formatDate(entry: OperationHistoryRecord | FichierOperationHistoryRecord): string {
    if (entry.timestamp) {
      let date = new Date(entry.timestamp);
      let formatedDate = this.datepipe.transform(date, 'dd/MM/YYYY à HH:mm');
      return formatedDate ?? '[date inconnue]';
    } else {
      return ' [date inconnue]';
    }
  }

  private formatEmailHistory(entry: EnvoiEmail, isConsultant: boolean): HistoryDisplayedRecord {
    let date = '[date inconnue]';
    if (entry?.sendingDate) {
      date = this.datepipe.transform(entry?.sendingDate, 'dd/MM/YYYY à HH:mm') ?? '[date inconnue]';
    }
    let header = ' par ' + (entry.senderBackOffice?.displayName ?? 'inconnu') + ' le ' + date;
    let detail;
    const details: string[] = [];
    if (entry?.subject) {
      details.push('Sujet: ' + entry?.subject);
    }
    if (entry?.messageId) {
      details.push('Identifiant Brevo: ' + entry?.messageId);
    }
    return {
      icon: 'email',
      actionText: 'Envoi opération au partenaire par email',
      header: header,
      details: details,
      timestamp: new Date(entry.sendingDate ?? new Date()),
    };
  }

  private formatOperationHistoryRecord(
    operation: Operation,
    entry: OperationHistoryRecord,
    isConsultant: boolean
  ): HistoryDisplayedRecord {
    let header = ' par ' + this.formatInitiator(entry) + ' le ' + this.formatDate(entry);
    let detail;
    const details: string[] = [];
    switch (entry.type) {
      case OperationHistoryRecordTypes.OperationStatutChange:
        if (entry?.previousStatut?.id == 5 && (entry?.newStatut?.id == 100 || entry?.newStatut?.id == 105)) {
          // for operation declare action we have a special case. should be displayed to consultant and so use specific action text
          return {
            icon: 'update',
            actionText: "Déclaration de l'opération",
            header: header,
            details: details,
            timestamp: new Date(entry.timestamp),
            visibleConsultant: true,
          };
        }
        if (entry?.newStatut?.id == 405 || entry?.newStatut?.id == 410) {
          // for cancelled or rejected operations we have a special case: should be displayed to consultant with motives and specific action text
          const specific: { actionText: string; details: string[] } = this.getAnnulationRejetTextDetails(operation);
          return {
            icon: 'close',
            actionText: specific.actionText,
            header: header,
            details: specific.details,
            timestamp: new Date(entry.timestamp),
            visibleConsultant: true,
          };
        } else {
          const previousStatutLabel = isConsultant
            ? entry.previousStatut?.consultantLibelle
            : entry.previousStatut?.backOfficeLibelle ?? 'indéfini';
          const newStatutLabel = isConsultant
            ? entry.newStatut?.consultantLibelle
            : entry.newStatut?.backOfficeLibelle ?? 'indéfini';
          detail = previousStatutLabel + ' -> ' + newStatutLabel;
          if (detail) {
            details.push(detail);
          }
          return {
            icon: 'update',
            actionText: 'Changement de statut',
            header: header,
            details: details,
            timestamp: new Date(entry.timestamp),
          };
        }

      case OperationHistoryRecordTypes.OperationTagEdit: {
        let visibleConsultant = true;
        const showtags = isConsultant
          ? entry.tags.filter(
              t =>
                t.code === 'ATTENTE_CONTREPARTIE' ||
                t.code === 'ATTENTE_DECAISSEMENT' ||
                t.code === 'STANDBY_CONSULTANT' ||
                t.code === 'NC_NON_BLOQUANTE'
            )
          : entry.tags;
        let tagstring = showtags.map(x => x.libelle).join(', ');
        if (showtags.length === 0) {
          visibleConsultant = false;
          tagstring = 'aucun';
        }
        details.push('tags associés: ' + tagstring);
        return {
          icon: 'new_label',
          actionText: 'Edition tag',
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant,
        };
      }

      case OperationHistoryRecordTypes.OperationGestionnaireComment:
        details.push('commentaire : ' + entry.gestionnaireComment);
        return {
          icon: 'feedback',
          actionText: 'Envoi commentaire gestionnaire',
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case OperationHistoryRecordTypes.ConsultantChanged:
        return {
          icon: 'update',
          actionText: "Transfert de l'opération à un nouveau consultant",
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case OperationHistoryRecordTypes.OperationCreate:
        return {
          icon: 'add_circle_outline',
          actionText: "Création de l'opération",
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case OperationHistoryRecordTypes.OperationAffectation:
        details.push('assigné au gestionnaire : ' + entry.dispatchTo?.displayName);
        return {
          icon: 'person_add_alt',
          actionText: 'Opération affectée',
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case OperationHistoryRecordTypes.EnvoiPartenaireCreate:
        return {
          icon: 'attach_email',
          actionText: "Bordereau associé à l'opération créé",
          header: header,
          details: details,
          timestamp: new Date(entry.timestamp),
          bordereauId: entry.envoiPartenaireId ?? undefined,
        };
      default:
        return {
          icon: 'update',
          actionText: 'Evènement indéfini',
          header: header,
          details: [],
          timestamp: new Date(entry.timestamp),
        };
    }
  }

  private formatFichierOperationHistoryRecord(
    entry: FichierOperationHistoryRecord,
    isConsultant: boolean
  ): HistoryDisplayedRecord {
    let header = ' par ' + this.formatInitiator(entry) + ' le ' + this.formatDate(entry);
    const details: string[] = [];
    let fileMetadata = entry.fileMetadata ?? undefined;
    switch (entry.type) {
      case FichierOperationHistoryRecordTypes.FichierOperationCreate:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'upload_file',
          header,
          fileMetadata,
          actionText: 'Ajout du fichier',
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case FichierOperationHistoryRecordTypes.FichierOperationRaiseNc:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.motif) {
          details.push('Motif: ' + entry.motif);
        }
        if (entry.categorie) {
          details.push('Catégorie: ' + entry.categorie);
        }
        if (entry.comment) {
          details.push('Commentaire: ' + entry.comment);
        }
        return {
          icon: 'report_problem',
          header,
          fileMetadata,
          actionText: "Ajout d'une non conformité",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationNcDataUpdate:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.motif) {
          details.push('Motif: ' + entry.motif);
        }
        if (entry.categorie) {
          details.push('Catégorie: ' + entry.categorie);
        }
        if (entry.comment) {
          details.push('Commentaire: ' + entry.comment);
        }
        return {
          icon: 'report_problem',
          header,
          fileMetadata,
          actionText: "Mise à jour des information d'une non conformité",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationRaiseInstance:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.motif) {
          details.push('Motif: ' + entry.motif);
        }
        if (entry.categorie) {
          details.push('Catégorie: ' + entry.categorie);
        }
        if (entry.comment) {
          details.push('Commentaire: ' + entry.comment);
        }
        if (entry.isInternal) {
          details.push('Interne Stellium');
        }
        return {
          icon: 'report_problem',
          header,
          fileMetadata,
          actionText: "Ajout d'une instance partenaire",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: !entry.isInternal,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationInstanceDataUpdate:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.motif) {
          details.push('Motif: ' + entry.motif);
        }
        if (entry.categorie) {
          details.push('Catégorie: ' + entry.categorie);
        }
        if (entry.comment) {
          details.push('Commentaire: ' + entry.comment);
        }
        if (entry.isInternal) {
          details.push('Interne Stellium');
        }
        return {
          icon: 'report_problem',
          header,
          fileMetadata,
          actionText: "Mise à jour des information d'une instance partenaire",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: !entry.isInternal,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationCancelInstance:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.isInternal) {
          details.push('Interne Stellium');
        }
        return {
          icon: 'update',
          header,
          fileMetadata,
          actionText: "Fermeture d'une instance partenaire",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: !entry.isInternal,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationCancelNc:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'update',
          header,
          fileMetadata,
          actionText: "Fermeture d'une non conformité",
          details: [],
          timestamp: new Date(entry.timestamp),
        };

      case FichierOperationHistoryRecordTypes.FichierOperationSetToConforme:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'check_circle',
          header,
          fileMetadata,
          actionText: 'Validation du fichier',
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case FichierOperationHistoryRecordTypes.FichierOperationSetToNotConforme:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'forward_to_inbox',
          header,
          fileMetadata,
          actionText: "Déclaration non conforme d'un fichier",
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationUpdate:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'upload_file',
          header,
          fileMetadata,
          actionText: 'Mise à jour fichier',
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      case FichierOperationHistoryRecordTypes.FichierOperationResponseNcComment:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        details.push('Commentaire: ' + entry.comment);
        return {
          icon: 'reviews',
          header,
          fileMetadata,
          actionText: 'Réponse à une non conformité par commentaire',
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationResponseNcFileUpload:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        if (entry.comment) {
          details.push('Commentaire: ' + entry.comment);
        }
        if (entry.newfileMetadata?.fileName) {
          details.push('nom nouveau fichier: ' + entry.newfileMetadata?.fileName);
        }

        return {
          icon: 'reviews',
          header,
          fileMetadata,
          actionText: 'Réponse à une non conformité par mise à jour de fichier',
          details: details,
          timestamp: new Date(entry.timestamp),
          visibleConsultant: true,
        };

      case FichierOperationHistoryRecordTypes.FichierOperationDelete:
        if (entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom) {
          details.push('Document: ' + entry.fichierOperation?.natureDocumentRequis?.natureDocument?.nom);
        }
        return {
          icon: 'delete',
          header,
          fileMetadata,
          actionText: "Suppression d'un fichier",
          details: details,
          timestamp: new Date(entry.timestamp),
        };

      default:
        return {
          icon: 'update',
          header,
          fileMetadata,
          actionText: 'Evènement indéfini',
          details: [],
          timestamp: new Date(entry.timestamp),
        };
    }
  }

  /**
   * Retrieves the details for an annulation or rejection operation.
   * For now, we only support one and only one annulation and rejet per operation.
   * If multiple annulations or rejets are implemented in the future, this method will need to be updated (info should be in the historyRecord and not on Operation).
   *
   * @param {Operation} operation - The operation object containing the necessary information.
   * @return {{actionText: string, details: string[]}} An object with the action text and details for the operation.
   */
  private getAnnulationRejetTextDetails(operation: Operation): { actionText: string; details: string[] } {
    const comment = 'Commentaire: ' + operation.motifRejetCancelCommentaire;
    if (operation.statutId === 410) {
      const rejetMotif =
        'Motif: ' +
        this.optionsService.getLabelfromOptionValue(operation.motifRejet, this.optionsService.getMotifRejetOptions());
      return {
        actionText: 'Operation refusée',
        details: [rejetMotif, comment],
      };
    }
    if (operation.statutId === 405) {
      const annulationMotif =
        'Motif: ' +
        this.optionsService.getLabelfromOptionValue(
          operation.motifAnnulation,
          this.optionsService.getMotifAnnulationOptions()
        );
      return {
        actionText: 'Operation annulée',
        details: [annulationMotif, comment],
      };
    }
    return {
      actionText: '',
      details: [],
    };
  }
}

<div class="page-layout flex-vertical gap-32 align-self-stretch">
  <div class="flex-horizontal gap-16 width-100pc">
    <oui-button type="icon" (click)="onBackArrowClick()"><oui-icon>arrow_back</oui-icon></oui-button>

    <div class="flex-vertical">
      <div class="flex-horizontal">
        <h6 class="flex-none right-margin-8">Suivi envoi bordereau {{ bordereauId }}</h6>
        <app-bordereau-statut-tag [size]="'small'" [statut]="envoiPartenaire?.statutEnvoi"></app-bordereau-statut-tag>
      </div>
      <div class="flex-horizontal">
        <div class="body-2 color-on-surface-medium right-margin-8">Partenaire:</div>
        <div class="body-2">
          {{ envoiPartenaire?.partenaire?.nom }}
        </div>
      </div>
    </div>

    <div class="flex-1"></div>

    <oui-button iconPrefix="delete" type="outlined" (click)="onDeleteBordereauClick()" [disabled]="isDeleteDisabled">
      Supprimer
    </oui-button>
    <oui-button iconPrefix="download" (click)="onDownloadBordereauClick()"> Télécharger le bordereau</oui-button>
    <div class="flex-vertical gap-8">
      <oui-button iconPrefix="email" (click)="onNotifyPartenaireClick()"> Notifier le partenaire</oui-button>
      <div *ngIf="emailLastSendDate" class="body-2 color-on-surface-medium">
        Mail envoyé le {{ emailLastSendDate | date: 'dd/MM/yyyy' : 'fr-FR' }} à
        {{ emailLastSendDate | date: 'HH:mm' : 'fr-FR' }}
      </div>
    </div>
  </div>

  <oui-banner
    *ngIf="emailInErrors && emailInErrors.length > 0"
    class="width-100pc"
    [title]="emailBannerTitle"
    declination="error"
    [action]="bannerEmailAction"
    [showCloseButton]="false">
  </oui-banner>

  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-40">
    <div class="flex-horizontal gap-16 width-100pc">
      <oui-date-picker [control]="createDateControl" [label]="'Création'"></oui-date-picker>
      <oui-date-picker [control]="sendingDateControl" [label]="'Envoyé au partenaire'"></oui-date-picker>
      <oui-date-picker [control]="receivingDateControl" [label]="'Reçu partenaire'"></oui-date-picker>
      <div class="flex-1"></div>

      <ng-container *ngIf="!isStatutPerdu()">
        <oui-button
          *ngIf="!isStatutRecherche()"
          type="outlined"
          [size]="'large'"
          (click)="onDeclareSearch()"
          [disabled]="isSearchButtonDisabled">
          Déclarer en recherche</oui-button
        >
        <div class="flex-vertical" *ngIf="isStatutRecherche()">
          <span class="body-2 color-on-surface-medium">Déclaré en recherche le {{ getSearchDate() }}</span>
          <oui-button type="text" [size]="'small'" (click)="onCancelSearch()" [disabled]="isCancelSearchButtonDisabled">
            <oui-icon>undo</oui-icon> Annuler</oui-button
          >
        </div>
      </ng-container>

      <oui-button
        *ngIf="!isStatutPerdu()"
        type="outlined"
        [size]="'large'"
        (click)="onDeclareLost()"
        [disabled]="isLostButtonDisabled">
        Déclarer perdu</oui-button
      >
      <div class="flex-vertical" *ngIf="isStatutPerdu()">
        <span class="body-2 color-on-surface-medium">Déclaré en perdu le {{ getLostDate() }}</span>
        <oui-button type="text" [size]="'small'" (click)="onCancelStatut()">
          <oui-icon>undo</oui-icon> Annuler</oui-button
        >
      </div>
    </div>
    <oui-textarea class="width-100pc flex-none" label="Note" [control]="noteControl"></oui-textarea>
  </div>

  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-40">
    <app-box-file-list
      [envoiPartenaireId]="envoiPartenaire?.id"
      [files]="envoiPartenaire?.attachedFiles ?? []"
      (onAddedFile)="onAddAttachedFile()"></app-box-file-list>
  </div>

  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-40">
    <oui-accordion [expanded]="true" buttonPosition="title-end">
      <oui-accordion-header class="padding-vertical-8">
        <oui-accordion-title class="subtitle-1">{{ getOperationTableTitle() }}</oui-accordion-title>
      </oui-accordion-header>

      <app-operations-table
        [receivedData]="operationCollectionSegment"
        (requestNewPage)="fetchOperations()"
        [(paginationParams)]="operationPaginationParams"
        [columnSelection]="columnSelection"
        [withSelects]="false"
        [withPaginator]="false"
        [withClickableRow]="false"
        [withRefresh]="false">
      </app-operations-table>
    </oui-accordion>
  </div>
</div>

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';
import { BoxFile } from '@lib/models/generated/graphql';
import { DocumentsService, NewBoxFileInput } from '@lib/services/documents.service';
import { openFilePicker } from '@lib/utils/filesUtils';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
type DenominationChange = { newDenomination: string; fileId: number };

@Component({
  template: '',
})
export abstract class AbstractBoxFileListComponent {
  async onDownloadSelectedFiles() {
    this.isFileDownloading = true;
    let zipname = 'documents';
    if (this.envoiPartenaireId) {
      zipname = `pj_bordereau_${this.envoiPartenaireId}`;
    }
    if (this.operationId) {
      zipname = `pi_operation_${this.operationId}`;
    }
    const dateFormat = this.datepipe.transform(new Date(), 'YYYY-MM-dd-HH-mm');
    zipname = `${zipname}_${dateFormat}`;

    await this.documentsService.downloadMultipleFilesInZipFile(
      this.files.filter(f => this.selection.includes(f.id)),
      zipname
    );
    this.isFileDownloading = false;
  }

  // ids of selected box files
  selection: number[] = [];

  @Input()
  files: BoxFile[] = [];

  @Input()
  envoiPartenaireId?: number | null = null;

  @Input()
  operationId?: number | null = null;

  @Output()
  onAddedFile: EventEmitter<number[]> = new EventEmitter<number[]>();

  private readonly changeDenominationSubject = new Subject<DenominationChange>();
  private changeDenominationSubscription: Subscription;

  isFileDownloading: boolean = false;
  constructor(
    protected documentsService: DocumentsService,
    private snackbarService: OuiSnackbarService,
    private documentViewerPanelService: BoxDocumentViewerService,
    private datepipe: DatePipe
  ) {}

  onAddAttachedFile(event: any) {
    event.stopPropagation();

    openFilePicker(
      'target_div',
      async (files: File[]) => {
        if (!files.every(file => this.documentsService.isAuthorizedFileSize(file))) {
          this.snackbarService.open('Poids de fichier non autorisé.', 'error', 5000, {
            horizontal: 'left',
            vertical: 'bottom',
          });
          return;
        }
        if (files.length > 0) {
          const inputs: NewBoxFileInput[] = files.map(file => {
            return {
              file,
              envoiPartenaireId: this.envoiPartenaireId,
              operationId: this.operationId,
            };
          });
          const boxfilesIds = await this.documentsService.createNewBoxFileInBatch(inputs);
          if (boxfilesIds) {
            this.onAddedFile.emit(boxfilesIds);
          }
        }
      },
      true
    );
  }

  ngOnInit() {
    this.changeDenominationSubscription = this.changeDenominationSubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(async changeDenomination => {
        var boxfile = await this.documentsService.updateBoxFileMetadata(
          changeDenomination.fileId,
          undefined,
          changeDenomination.newDenomination
        );
        var file = this.files.find(f => f.id == changeDenomination.fileId);
        if (file) {
          file.denomination = boxfile?.denomination;
        }
      });
  }

  ngOnDestroy() {
    if (this.changeDenominationSubscription) {
      this.changeDenominationSubscription.unsubscribe();
    }
  }

  async onDeleteFile(arg0: number) {
    var isDeleted = await this.documentsService.deleteBoxFile(arg0);
    if (isDeleted) {
      this.files = this.files.filter(f => f.id != arg0);
    }
  }

  onFileDenominationChange(fileId: number, $event: Event) {
    const denominationChange: DenominationChange = {
      newDenomination: ($event.target as HTMLInputElement).value,
      fileId: fileId,
    };

    this.changeDenominationSubject.next(denominationChange);
  }

  toggleFileSelection(arg0: number) {
    if (this.selection.includes(arg0)) {
      this.selection = this.selection.filter(id => id !== arg0);
    } else {
      this.selection.push(arg0);
    }
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection = [];
    } else {
      var ids = this.files?.map(f => f.id);
      if (ids) {
        this.selection = [...ids];
      }
    }
  }
  isAllSelected(): boolean {
    var ids = this.files?.map(f => f.id);
    if (JSON.stringify(ids) == JSON.stringify(this.selection)) {
      return true;
    }
    return false;
  }

  openFileViewer(boxFile: BoxFile) {
    this.documentViewerPanelService.openSimpleDocumentViewer({ boxFile: boxFile });
  }

  isFileViewerCompatible(boxFile: BoxFile) {
    return this.documentsService.isViewerCompatible(boxFile.fileExtension);
  }
}

<div oui-dialog-title>
  {{ title ?? 'Confirmation' }}
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <div style="white-space: pre-line" class="body-1 color-on-surface-emphasis">
      {{ message ?? 'Voulez-vous confimer cette action ?' }}
    </div>
  </div>
</oui-dialog-content>

<oui-dialog-actions class="padding-right-24 padding-bottom-24">
  <div class="flex-1"></div>
  <oui-button type="text" class="flex-none" (click)="cancel()">
    {{ cancelButtonLabel ?? 'Annuler' }}
  </oui-button>
  <oui-button type="contained" *ngIf="!hideValidateButton" class="flex-none padding-left-8" (click)="validate()">
    {{ validateButtonLabel ?? 'Confirmer' }}
  </oui-button>
</oui-dialog-actions>

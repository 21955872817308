<div oui-dialog-title>
  Valider le transfert
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <div class="with-border rounded width-100pc padding-24 gap-24">
      <div class="color-on-surface-medium">Investisseur source :</div>
      <div>
        <b>{{ formatName(data.sourceInvestisseurData) }}</b>
      </div>
      <div>
        {{ formatIds(data.sourceInvestisseurData) }}
      </div>
    </div>
    <div class="align-self-center">
      <oui-icon>arrow_downward</oui-icon>
    </div>
    <div class="align-self-center">
      <b>{{ data.operationNumber }} opération{{ data.operationNumber > 1 ? 's' : '' }}</b>
    </div>
    <div class="align-self-center">
      <oui-icon>arrow_downward</oui-icon>
    </div>
    <div class="with-border rounded width-100pc padding-24 gap-24">
      <div class="color-on-surface-medium">Investisseur source :</div>
      <div>
        <b>{{ formatName(data.targetInvestisseurData) }}</b>
      </div>
      <div>
        {{ formatIds(data.targetInvestisseurData) }}
      </div>
    </div>
  </div>
</oui-dialog-content>

<oui-dialog-actions class="padding-right-16 padding-bottom-24">
  <div class="flex-1"></div>
  <oui-button type="text" class="flex-none" (click)="cancel()"> Annuler </oui-button>
  <oui-button type="contained" class="flex-none padding-left-8" (click)="validate()"> Valider </oui-button>
</oui-dialog-actions>

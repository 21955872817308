import { BoxEnvelope } from './box-enveloppe.model';

export const NATURE_OPERATION_SOUSCRIPTION_ID = 1;
export const CONNAISSANCE_CLIENT_CATEGORIE_ID = 1;
export const CONSEIL_CATEGORIE_ID = 2;
export const OPERATION_CATEGORIE_ID = 3;
export const PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE = 'FEMALE';

export const FICHIER_STATUT_CREATED_ID = 1;
export const FICHIER_STATUT_UPDATEDBYCONSULTANT_ID = 5;
export const FICHIER_STATUT_NONCOMPLIANT_ID = 11;
export const FICHIER_STATUT_COMPLIANT_ID = 21;

export enum OperationState {
  DraftInvestisseur = 1,
  DraftProduit = 5,
  NCToFixByConsultant = 50,
  InstanceToFixByConsultant = 51,
  WaitingMailDocuments = 100,
  RecievedInBackOffice = 105,
  Dispatched = 200,
  NCFixedByConsultant = 205,
  InstanceFixedByConsultant = 206,
  controlledWithNC = 215,
  controlledNoNC = 220,
}

// https://www.iana.org/assignments/media-types/media-types.xhtml
export const JPEG_MIME_TYPE = 'image/jpeg';
export const PNG_MIME_TYPE = 'image/png';
export const PDF_MIME_TYPE = 'application/pdf';

export const JPEG_EXTENSIONS = ['jpg', 'jpeg', 'jpe', 'pjpeg', 'jfif', 'pjp']; // https://developer.mozilla.org/fr/docs/Web/Media/Formats/Image_types
export const PNG_EXTENSIONS = ['png'];
export const PDF_EXTENSIONS = ['pdf'];

export const ENVELOPPE_TO_MERGE_LIST: BoxEnvelope[] = [
  { code: 16, libelle: 'FIP' },
  { code: 18, libelle: 'FCPI' },
];

export const FAKE_ENVELOPPE_MERGED: BoxEnvelope = { code: -1, libelle: 'FIP/FCPI' };

import { Injectable, isDevMode } from '@angular/core';
import { ApolloQueryResult, QueryOptions } from '@apollo/client/core';
import { GraphQLErrors } from '@apollo/client/errors';
import { Apollo } from 'apollo-angular';
import { EmptyObject, MutationOptions, MutationResult } from 'apollo-angular/types';

import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';
import { Observable, catchError, tap, throwError, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueryManagerService {
  constructor(private authService: AuthService, private snackBarService: OuiSnackbarService, private apollo: Apollo) {}

  /**
   * This method add a side effect that display a snackbar with information on error to user and reset authent if error
   * is induced by authent ( code 401 or graphql error with code AUTH_NOT_AUTHORIZED)
   * error are not removed and will be transfered to caller
   *
   * @param {Observable<ApolloQueryResult<T>>} params - The observable that emits the Apollo query result.
   * @param {boolean} noSnackBar - (optional) If true, no snackbar will be displayed for graphql errors. (network errors will still be displayed)
   * @return {Observable<ApolloQueryResult<T>>} The observable that emits the Apollo query result with error handling.
   */
  handleErrors(params: Observable<any>, noSnackBar = false): Observable<any> {
    // in graph we can have error and 200 status. in that case error will be in response.errors array. So even if request succeed we check if there is an error in graphql
    // response.
    // we also test if request fail (network error). We use catch in pipe but we apply a catch an rethrow policy to allow sender to detect error and handle it at his level
    return params.pipe(
      //automatically display errors from response
      tap((response: any) => {
        if ((response?.errors?.length ?? 0) > 0) {
          if (response.errors?.filter((error: any) => error?.extensions?.['code'] === 'AUTH_NOT_AUTHORIZED')?.length) {
            // if at least one error is of type "AUTH_NOT_AUTHORIZED" this means that the user token has expired, we need to reset authent
            this.onAuthentError();
            return;
          }

          if (!noSnackBar) {
            this.snackBarGraphQlErrors(response.errors ?? []);
          }

          if (isDevMode()) {
            console.log('Received error from the server : ', response);
          }
        }
      }),
      catchError(error => {
        if (error?.networkError) {
          if (error.networkError.status === 401) {
            this.onAuthentError();
          } else if (error.networkError.status === 500) {
            this.snackBarDefaultError();
          }

          if (error.networkError.error?.errors?.length > 0) {
            error.networkError.error.errors.forEach((error: any) => {
              this.snackBarGraphQlError(error);
            });
          }
        }

        return throwError(() => error);
      })
    );
  }

  onAuthentError() {
    // display error message to inform user and reset authent (this will redirect user to login page)
    const message =
      "Votre session de connexion n'est plus valide. Vous allez être redirigé vers la page d'authentification.";
    this.snackBarService.open(message, 'error', 5000);
    timer(5000).subscribe(() => {
      this.authService.resetAuthent();
    });
  }

  public snackBarGraphQlError(error: any) {
    if (error.extensions && error.extensions.userMessage && typeof error.extensions.userMessage === 'string') {
      var message = error.extensions.userMessage as string;
      this.snackBarService.open(message, 'error', 5000);
    } else if (
      error.message &&
      typeof error.message === 'string' &&
      error.message.includes('The GraphQL request document contains more than 2048 fields')
    ) {
      this.snackBarService.open(
        "Vous avez initié une action qui consomment trop de données simultanément, ce qui engendre une instabilité de l'application. Merci de réduire le nombre d'opérations et de données associées pour poursuivre votre déclaration.",
        'error',
        10000
      );
    } else {
      this.snackBarDefaultError();
    }
  }

  public snackBarDefaultError() {
    const message =
      "Une erreur est survenue lors du traitement de votre requête. Veuillez rafraichir la page et contacter l'administrateur si l'erreur persiste.";
    this.snackBarService.open(message, 'error', 5000);
  }

  public snackBarGraphQlErrors(graphQLErrors: GraphQLErrors) {
    if (graphQLErrors.length > 0) {
      graphQLErrors.forEach(error => this.snackBarGraphQlError(error));
    }
  }

  /**
   * method to use request query to the server. wrap apollo query with default front behaviors
   *
   * @param options  : graphQL query options
   * @param noSnackBar  : (optionnal boolean) if true, no snackbar will be displayed for errors
   * @returns
   */
  public query<T, V = EmptyObject>(
    options: QueryOptions<V, T>,
    noSnackBar = false,
    overGet = false
  ): Observable<ApolloQueryResult<T>> {
    options.errorPolicy = 'all';
    if (overGet) {
      if (options.context) {
        options.context['method'] = 'GET';
      } else {
        options.context = { method: 'GET' };
      }
    }
    var obs = this.apollo.query<T, V>(options);
    return this.handleErrors(obs, noSnackBar);
  }

  /**
   * method to use to request mutate to the server. wrap apollo query with default front behaviors
   *
   * @param options  : graphQL query options
   * @param noSnackBar  : (optionnal boolean) if true, no snackbar will be displayed for errors
   * @returns
   */
  public mutate<T, V = EmptyObject>(options: MutationOptions<T, V>, noSnackBar = false): Observable<MutationResult<T>> {
    options.errorPolicy = 'all';
    var obs = this.apollo.mutate<T, V>(options);
    return this.handleErrors(obs, noSnackBar);
  }
}

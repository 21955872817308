import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilesListStatusComponent } from '@lib/components/files-list-status/files-list-status.component';
import {
  FichierOperation,
  Operation,
  OperationActionRight,
  OperationStateTransitionTrigger,
  OperationsPaginatedCollectionSegment,
} from '@lib/models/generated/graphql';

import { ActivatedRoute, Router } from '@angular/router';
import { isArray } from '@apollo/client/utilities';
import { ConfirmationSimpleComponent } from '@lib/components/confirmation-simple/confirmation-simple.component';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';
import { operationDetailsPageBackOfficeFragment } from '@lib/models/graphqlFragments';
import { BoxLayoutService } from '@lib/services/layout.service';
import { OperationsService } from '@lib/services/operations.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import {
  EditGestionnaireCommentModalComponent,
  GestionnaireCommentChange,
} from 'src/app/components/operation-details-gestionnaire/edit-gestionnaire-comment-modal/edit-gestionnaire-comment-modal';
import { FichierOperationInvestisseurService } from 'src/services/fichier-operation-investisseur.service';
import { FichierOperationsDownloadService } from 'src/services/fichier-operations-download.service';
import { getOperationDeskTabIndex } from 'src/utils/operation-desk-tab-config';
import { RefuseCancelOperationModalComponent } from './refuse-cancel-operation-modal/refuse-cancel-operation-modal.component';

const OPERATIONDETAILS = gql`
  query allOperations($where: OperationFilterInput) {
    allOperationsPaginated(skip: 0, take: 1, where: $where) {
      items {
        ...operationDetailsPage
      }
    }
  }
  ${operationDetailsPageBackOfficeFragment}
`;

const DELETEMESSAGETECHNIQUE = gql`
  mutation deleteMessageTechnique($operationId: Int!) {
    deleteMessageTechnique(operationId: $operationId) {
      id
      messageTechnique
    }
  }
`;

const fireOperationStateTransitionTrigger = gql`
  mutation fireOperationStateTransitionTrigger($operationId: Int!, $trigger: OperationStateTransitionTrigger!) {
    fireOperationStateTransitionTrigger(operationId: $operationId, trigger: $trigger) {
      id
      statutId
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      operationActionRights
    }
  }
`;

const setOperationGestionnaireComment = gql`
  mutation ($operationId: Int!, $comment: String) {
    setOperationGestionnaireComment(operationId: $operationId, commentaire: $comment) {
      id
      commentaireGestionnaire
    }
  }
`;

const DECLARE_ALL_FILES_COMPLIANT = gql`
  mutation declareAllFilesCompliantByOperationId($operationId: ID!) {
    declareAllFilesCompliantByOperationId(operationId: $operationId) {
      id
    }
  }
`;

const FetchOperationBoxFiles = gql`
  query allOperations($where: OperationFilterInput) {
    allOperationsPaginated(skip: 0, take: 1, where: $where) {
      items {
        id
        attachedFiles {
          id
          fileId
          fileNameWithExtension
          fileExtension
          denomination
          createdDate
          lastModificationDate
          fileConnectionInfo {
            sasUrl
            expiration
          }
        }
      }
    }
  }
`;

@Component({
  selector: 'app-operation-details-gestionnaire',
  templateUrl: './operation-details-gestionnaire.component.html',
  styleUrls: ['./operation-details-gestionnaire.component.scss'],
})
export class OperationDetailsGestionnaireComponent implements OnInit, OnDestroy {
  @ViewChild(FilesListStatusComponent)
  private filesListDisplay: FilesListStatusComponent;

  @Output()
  operationChange = new EventEmitter();

  displayDocumentControl: boolean = false;

  operationId?: number;
  operation: Operation;

  isEditableFichierMode: boolean;

  isInstanceMode: boolean = false;

  isLoadingOperation: boolean = true;
  isEmailError: boolean = false;

  isConsultantOutDated: boolean = false;

  bannerConformeAcion: OuiBannerAction = {
    label: 'Voir',
    action: () => {
      this.onfileClick(this.filesListDisplay.fichiersConformes[0]);
    },
  };

  bannerTechniqueAcion: OuiBannerAction = {
    label: 'Fermer',
    action: () => {
      this.deleteMessageTechnique();
    },
  };

  bannerNCAcion: OuiBannerAction = {
    label: 'Voir',
    action: () => {
      this.onfileClick(this.filesListDisplay.fichiersNonConformes[0]);
    },
  };

  selectedFile?: FichierOperation;

  bannerGestionnaireCommentActions: OuiBannerAction[] = [
    {
      label: 'Modifier',
      action: () => {
        this.openGestionnaireCommentEditor();
      },
    },
    {
      label: 'Supprimer',
      action: () => {
        this.updateGestionnaireComment();
      },
    },
  ];

  get showBannerConforme(): boolean {
    return (
      this.operationService.canSendToPartenaire(this.operation.activeOperationStateTransitionTriggers) &&
      !this.isInstanceMode
    );
  }

  backButtonTabIndex: number;
  constructor(
    private dialog: MatDialog,
    private ouiDialog: OuiDialogService,
    protected fileService: FichierOperationsDownloadService,
    protected investisseurService: FichierOperationInvestisseurService,
    private queryManager: QueryManagerService,
    private route: ActivatedRoute,
    private router: Router,
    private layoutService: BoxLayoutService,
    private operationService: OperationsService,
    private documentViewerPanelService: BoxDocumentViewerService
  ) {}

  ngOnInit(): void {
    this.layoutService.hideHeader();

    const operationIdParam = this.route.snapshot.paramMap.get('operationId');

    if (operationIdParam) this.operationId = Number.parseInt(operationIdParam);

    if (!this.operationId) this.router.navigate(['backoffice-tab']);

    this.getOperation();
  }

  ngOnDestroy(): void {
    this.layoutService.showHeader();
  }

  onOperationChange() {
    this.getOperation();
    this.operationChange.emit();
  }

  updateGestionnaireComment(newComment?: string) {
    if (this.operation.id) {
      this.queryManager
        .mutate<{ setOperationGestionnaireComment: Operation }>({
          mutation: setOperationGestionnaireComment,
          variables: {
            operationId: this.operation.id,
            comment: newComment,
          },
        })
        .subscribe(result => {
          if (result.data?.setOperationGestionnaireComment) {
            this.operation.commentaireGestionnaire =
              result.data.setOperationGestionnaireComment.commentaireGestionnaire;
          }
        });
    }
  }

  onValidateAllToControlFiles() {
    if (this.operationId) {
      this.queryManager
        .mutate<{ declareAllFilesCompliantByOperationId: [FichierOperation] }>({
          mutation: DECLARE_ALL_FILES_COMPLIANT,
          variables: {
            operationId: this.operationId,
          },
        })
        .subscribe(result => {
          if (
            result.data?.declareAllFilesCompliantByOperationId?.length &&
            result.data?.declareAllFilesCompliantByOperationId?.length > 0
          ) {
            this.onOperationChange();
          }
        });
    }
  }

  openGestionnaireCommentEditor() {
    const modalRef = this.ouiDialog.openDialog(
      EditGestionnaireCommentModalComponent,
      {
        currentComment: this.operation?.commentaireGestionnaire,
      },
      'auto',
      '473px'
    );
    modalRef.afterClosed().subscribe((commentChange: GestionnaireCommentChange) => {
      if (commentChange && commentChange.shouldUpdate) {
        this.updateGestionnaireComment(commentChange.newComment);
      }
    });
  }

  deleteMessageTechnique() {
    if (this.operation.id) {
      this.queryManager
        .mutate<{ deleteMessageTechnique: Operation }>({
          mutation: DELETEMESSAGETECHNIQUE,
          variables: {
            operationId: this.operation.id,
          },
        })
        .subscribe(result => {
          if (result.data?.deleteMessageTechnique) {
            this.operation.messageTechnique = result.data.deleteMessageTechnique.messageTechnique;
          }
        });
    }
  }

  getOperation(): void {
    this.isLoadingOperation = true;
    this.queryManager
      .query<{
        allOperationsPaginated: OperationsPaginatedCollectionSegment;
      }>({
        query: OPERATIONDETAILS,
        variables: {
          where: {
            id: {
              eq: this.operationId,
            },
          },
        },
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        if (!loading && isArray(data?.allOperationsPaginated?.items) && data.allOperationsPaginated.items[0]) {
          this.operation = deepCopy(data.allOperationsPaginated.items[0]);

          this.sortFichiersOperation();
          let opeLabel = 'operation-' + this.operation.id;
          if (this?.operation?.investisseurPreset?.nom) {
            opeLabel = opeLabel + '_' + this.operation.investisseurPreset?.nom;
          }
          this.fileService.setFiles(this.operation.fichierOperations, opeLabel);

          this.isEditableFichierMode = this.isFichierOperationEditable(this.operation);
          this.isInstanceMode =
            this.operation.statutId == 310 ||
            this.operation.fichierOperations.some(fo =>
              fo.nonConformiteFichiers.some(ncf => ncf.isInstancePartenaire == true)
            );
          if (this.operation.investisseur) {
            this.investisseurService.setInvestisseur(this.operation.investisseur);
            this.investisseurService.updateInvestisseurFileData(this.operation.investisseur.id);
          }
          if (this.operation.coInvestisseur) {
            this.investisseurService.setCoInvestisseur(this.operation.coInvestisseur);
            this.investisseurService.updateInvestisseurFileData(this.operation.coInvestisseur.id);
          }

          this.setIsConsultantOutDated();
          if (this.backButtonTabIndex === undefined) {
            this.backButtonTabIndex = this.setBackButtonTabIndex(this.operation.statutId);
          }

          this.isLoadingOperation = false;
        }
      });
  }

  private setIsConsultantOutDated() {
    // here we check if the current consultant is listed as an active consultant for the current operation investisseur
    if (!this.operation.consultant || !this.operation.investisseur?.investisseurConsultant) return;
    const investisseurActiveConsultantIds: number[] = this.operation.investisseur?.investisseurConsultant.map(
      ic => ic.consultantId
    );
    this.isConsultantOutDated = !investisseurActiveConsultantIds.includes(this.operation.consultant.id);
  }
  private sortFichiersOperation() {
    this.operation.fichierOperations = this.operation.fichierOperations.sort((a, b) => {
      return (a.natureDocumentRequis?.natureDocument?.categorie?.libelle ?? '').localeCompare(
        b.natureDocumentRequis?.natureDocument?.categorie?.libelle ?? ''
      );
    });
  }

  isFichierOperationEditable(operation: Operation) {
    if (!operation || !operation.operationActionRights) {
      return false;
    }
    return operation.operationActionRights.includes(OperationActionRight.FichierOperationEdition);
  }

  onfileClick(selectedFile: FichierOperation) {
    this.isFichierOperationEditable(this.operation)
      ? this.openControlFileViewer(selectedFile)
      : this.openFileViewer(selectedFile);
  }

  needToSendNC() {
    if (this.isInstanceMode) {
      return this.operation.activeOperationStateTransitionTriggers.includes(
        OperationStateTransitionTrigger.GestionnaireTransfersOperationWithInstance
      );
    }
    return this.operation.activeOperationStateTransitionTriggers.includes(
      OperationStateTransitionTrigger.GestionnaireTransfersOperationWithNc
    );
  }

  openControlFileViewer(selectedFile: FichierOperation) {
    this.selectedFile = selectedFile;
    this.displayDocumentControl = true;
  }

  closeControlFileViewer() {
    this.selectedFile = undefined;
    this.displayDocumentControl = false;
    this.onOperationChange();
  }

  openFileViewer(selectedFile: FichierOperation) {
    this.documentViewerPanelService.openOperationDocumentViewer(selectedFile);
  }

  onRejectOperation() {
    const modalRef = this.ouiDialog.openDialog(
      RefuseCancelOperationModalComponent,
      {
        operationId: this.operationId,
        isReject: true,
      },
      'auto',
      '473px'
    );
    modalRef.afterClosed().subscribe((rejectedOpe: Operation) => {
      if (rejectedOpe) {
        this.operation.statut = rejectedOpe.statut;
        this.operation.statutId = rejectedOpe.statut.id;
        this.operation.activeOperationStateTransitionTriggers = rejectedOpe.activeOperationStateTransitionTriggers;
        this.operation.operationActionRights = rejectedOpe.operationActionRights;
      }
    });
  }

  onSetInstancePartenaire() {
    this.fireOperationStateTransitionTrigger(
      OperationStateTransitionTrigger.GestionnaireDeclareInstancePartenairePresence
    );
  }

  onGestionnaireAssigned(operation: Operation) {
    this.operation = operation;
  }
  onCancelOperation() {
    const modalRef = this.ouiDialog.openDialog(
      RefuseCancelOperationModalComponent,
      {
        operationId: this.operationId,
        isReject: false,
      },
      'auto',
      '473px'
    );
    modalRef.afterClosed().subscribe((cancelledOpe: Operation) => {
      if (cancelledOpe) {
        this.operation.statut = cancelledOpe.statut;
        this.operation.statutId = cancelledOpe.statut.id;
        this.operation.activeOperationStateTransitionTriggers = cancelledOpe.activeOperationStateTransitionTriggers;
        this.operation.operationActionRights = cancelledOpe.operationActionRights;
      }
    });
  }

  fireOperationStateTransitionTrigger(trigger: OperationStateTransitionTrigger) {
    if (this.operation.activeOperationStateTransitionTriggers.includes(trigger)) {
      this.queryManager
        .mutate<{ fireOperationStateTransitionTrigger: Operation }>({
          mutation: fireOperationStateTransitionTrigger,
          variables: {
            operationId: this.operation.id,
            trigger: trigger,
          },
        })
        .subscribe(result => {
          if (result.data?.fireOperationStateTransitionTrigger?.statut) {
            this.operation.statut = result.data?.fireOperationStateTransitionTrigger.statut;
            this.operation.statutId = result.data?.fireOperationStateTransitionTrigger.statut.id;
            this.operation.activeOperationStateTransitionTriggers =
              result?.data?.fireOperationStateTransitionTrigger.activeOperationStateTransitionTriggers;
            this.operation.operationActionRights =
              result?.data?.fireOperationStateTransitionTrigger.operationActionRights;
          }
        });
    }
  }

  onAddAttachedFile() {
    this.queryManager
      .query<{
        allOperationsPaginated: OperationsPaginatedCollectionSegment;
      }>({
        query: FetchOperationBoxFiles,
        variables: { where: { id: { eq: this.operation.id } } },
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        if (!loading && isArray(data?.allOperationsPaginated?.items) && data.allOperationsPaginated.items[0]) {
          this.operation.attachedFiles = deepCopy(data.allOperationsPaginated.items[0].attachedFiles);
        }
      });
  }

  onMailReception() {
    const modalRef = this.ouiDialog.openDialog(
      ConfirmationSimpleComponent,
      {
        title: 'Déclarer la réception des originaux ?',
        message: 'Êtes-vous sûr de vouloir déclarer la réception des originaux ?',
        validateButtonLabel: 'Déclarer',
        cancelButtonLabel: 'Annuler',
      },
      'auto',
      '492px'
    );
    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        this.fireOperationStateTransitionTrigger(OperationStateTransitionTrigger.BackOfficeReceivesMailDocuments);
      }
    });
  }

  toggleAllRows() {
    this.fileService.toggleAllRows();
  }

  isAllSelected() {
    return this.fileService.isAllSelected();
  }

  hasSelected() {
    return this.fileService.hasSelected();
  }

  onDownloadSelectedFiles() {
    this.fileService.onDownloadSelectedFiles();
  }

  getControlFilesLabel(): string {
    const fileCount = this.fileService.getFilesCount();
    const selectCount = this.fileService.getSelectionCount();
    if (selectCount > 0) {
      return selectCount > 1 ? `${selectCount} documents sélectionnés` : `${selectCount} document sélectionné`;
    }
    return fileCount > 1 ? `${fileCount} documents` : `${fileCount} document`;
  }

  setBackButtonTabIndex(statusId: number): number {
    return getOperationDeskTabIndex(statusId);
  }

  onBackArrowClicked() {
    if (this.backButtonTabIndex >= 0) {
      this.router.navigate(['backoffice-tab', this.backButtonTabIndex]);
      return;
    }
    this.router.navigate(['backoffice-tab']);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BoxLayoutService {
  constructor() {}

  private _displayHeaderSubject = new BehaviorSubject<boolean>(true);
  public readonly displayHeader$: Observable<boolean> = this._displayHeaderSubject.asObservable();

  public showHeader(): void {
    this.setHeaderVisibility(true);
  }

  public hideHeader(): void {
    this.setHeaderVisibility(false);
  }

  public setHeaderVisibility(visible: boolean) {
    this._displayHeaderSubject.next(visible);
  }
}

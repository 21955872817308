import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { operationNoteFragment } from '@lib/models/graphqlFragments';
import { NoteOperation, Operation } from '@lib/models/generated/graphql';
import { AuthService } from '@lib/services/auth-service.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import { ConfirmNoteDeleteComponent } from './confirm-note-delete/confirm-note-delete.component';
import { OuiDialogService } from 'omnium-ui/dialog';

const CREATENOTE = gql`
  mutation createNoteOperation($operationId: Int!, $contenu: String!) {
    createNoteOperation(operationId: $operationId, contenu: $contenu) {
      ...operationNote
    }
  }
  ${operationNoteFragment}
`;

const UPDATENOTE = gql`
  mutation updateNoteOperation($noteId: Int!, $contenu: String!) {
    updateNoteOperation(noteId: $noteId, contenu: $contenu) {
      ...operationNote
    }
  }
  ${operationNoteFragment}
`;

const DELETENOTE = gql`
  mutation deleteNoteOperation($noteId: Int!) {
    deleteNoteOperation(noteId: $noteId)
  }
`;

type EditableNote = NoteOperation & { editableContenu: string; isEditableMode: boolean };

@Component({
  selector: 'app-operation-notes',
  templateUrl: './operation-notes.component.html',
  styleUrls: ['./operation-notes.component.scss'],
})
export class OperationNotesComponent {
  @Input() operation: Operation;
  notes: EditableNote[] = [];
  userTokenId: string | null;
  noteControl = new FormControl<string>('');
  constructor(
    private queryManager: QueryManagerService,
    private authService: AuthService,
    private dialog: MatDialog,
    private ouiDialog: OuiDialogService
  ) {}

  ngOnInit() {
    this.userTokenId = this.authService.getUserInfo()?.tokenId;
    if (this.operation?.notes) this.notes = this.operation.notes.map(note => this.convertNoteToEditableNote(note));
    this.sortNotesByDateDesc();
  }
  async addNote() {
    if (this.isEmptyString(this.noteControl.value)) {
      return;
    }
    const result = await firstValueFrom(
      this.queryManager.mutate<{ createNoteOperation: NoteOperation }>({
        mutation: CREATENOTE,
        variables: {
          operationId: this.operation.id,
          contenu: this.noteControl.value,
        },
      })
    );
    if (result.data?.createNoteOperation) {
      this.noteControl.reset();
      this.notes.push(this.convertNoteToEditableNote(result.data?.createNoteOperation));
      this.sortNotesByDateDesc();
    }
  }

  async updateNote(note: EditableNote) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ updateNoteOperation: NoteOperation }>({
        mutation: UPDATENOTE,
        variables: {
          noteId: note.id,
          contenu: note.editableContenu,
        },
      })
    );
    if (result.data?.updateNoteOperation) {
      const index = this.notes.findIndex(n => n.id === note.id);
      this.notes[index] = this.convertNoteToEditableNote(result.data?.updateNoteOperation);
    }
  }

  async deleteNote(note: EditableNote) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ deleteNoteOperation: boolean }>({
        mutation: DELETENOTE,
        variables: {
          noteId: note.id,
        },
      })
    );
    if (result.data?.deleteNoteOperation) {
      const index = this.notes.findIndex(n => n.id === note.id);
      this.notes.splice(index, 1);
    }
  }

  isEmptyString(str: string | null) {
    return !str || str.trim().length === 0;
  }

  convertNoteToEditableNote(note: NoteOperation): EditableNote {
    return {
      ...note,
      editableContenu: deepCopy(note.contenu),
      isEditableMode: false,
    };
  }
  toogleMode(note: EditableNote): EditableNote {
    note.editableContenu = deepCopy(note.contenu);
    note.isEditableMode = !note.isEditableMode;
    return note;
  }

  onDeleteNote(note: EditableNote) {
    const modalRef = this.ouiDialog.openDialog(ConfirmNoteDeleteComponent, undefined, 'auto', '448px');

    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        this.deleteNote(note);
      }
    });
  }

  sortNotesByDateDesc() {
    this.notes.sort((a, b) => new Date(b.dateCreation).getTime() - new Date(a.dateCreation).getTime());
  }
}

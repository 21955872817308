<div oui-dialog-title>
  Affecter un consultant
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content class="flex-vertical gap-16">
  <div>
    Aucun consultant n’est affecté à cette opération. Sélectionnez le consultant qui va reprendre la gestion de cette
    opération
  </div>
  <div>
    <ng-container *ngFor="let consultant of displayableConsultants">
      <div
        class="flex-horizontal flex-center selectable with-border cursor-pointer padding-16"
        (click)="onSelectConsultant(consultant.id)">
        <div class="figmaText">{{ consultant.displayName }}</div>
        <div class="flex-1"></div>
        <oui-icon>chevron_right</oui-icon>
      </div>
    </ng-container>
  </div>
</oui-dialog-content>

import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { AuthService } from '@lib/services/auth-service.service';
import { BoxLayoutService } from '@lib/services/layout.service';
import { MenuNode } from 'omnium-ui/shared';
import { delay } from 'rxjs/internal/operators/delay';
import { TemplatePortal } from '@angular/cdk/portal';
import { BoxDocumentViewerService } from '@lib/components/document-viewer/document-viewer-panel/document-viewer-panel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  logoUrl: string = 'assets/Logo_Box-Placement.svg';

  displayHeader: boolean = true;

  menu: MenuNode[] = [
    {
      id: 'operations',
      name: 'Opérations',
      routeLink: '/backoffice',
    },
    {
      id: 'bordereaux',
      name: 'Bordereaux',
      routeLink: '/bordereaux',
    },
  ];

  userName: string = '';
  userMenu: MenuNode[] = [
    {
      name: 'Se déconnecter',
      icon: 'power_settings_new',
      action: () => {
        this.authService.logout();
      },
    },
  ];

  @ViewChild('documentViewerPortalContent')
  documentViewerPortalContent: TemplateRef<unknown>;

  constructor(
    protected authService: AuthService,
    private layoutService: BoxLayoutService,
    private documentViewerPanelService: BoxDocumentViewerService,
    private _viewContainerRef: ViewContainerRef
  ) {
    // .pipe( delay(0)) to avoid NG0100: ExpressionChangedAfterItHasBeenCheckedError (see https://blog.angular-university.io/angular-debugging/)
    this.layoutService.displayHeader$.pipe(delay(0)).subscribe(value => (this.displayHeader = value));
  }

  onHelpClick() {
    window.open('https://prodemial.zendesk.com/hc/fr', '_blank');
  }

  ngOnInit(): void {
    this.authService.login().then(() => {
      const userInfo = this.authService.getUserInfo();
      this.userName = userInfo.fullName;
    });

    this.authService.isAdminSubject.subscribe(isAdmin => {
      if (isAdmin) {
        this.menu.push({
          id: 'parametres',
          name: 'Paramètres',
          routeLink: '/parametres',
        });
      }
    });
  }

  ngAfterViewInit(): void {
    const documentViewerPortal = new TemplatePortal(this.documentViewerPortalContent, this._viewContainerRef);
    this.documentViewerPanelService.setDocumentViewerPortal(documentViewerPortal);
  }
}

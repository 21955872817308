<div class="flex-vertical gap-16 width-100pc">
    <div class="flex-horizontal flex-center gap-16">
        <div>
          {{ totalCount }} <span *ngIf="totalCount > 1">demandes</span><span *ngIf="totalCount < 2">demande</span>
        </div>
        <oui-button *ngIf="withRefresh" (click)="onRefresh.emit()" type="text" iconPrefix="cached"
          size="small">Actualiser</oui-button>
    </div>
    <div class="width-100pc">
        <oui-table *ngIf="!isLoading && dataSource.data.length > 0">
            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="columnSelection[0].header" matSortDirection="asc">
                <ng-container *ngFor="let columnconfig of columnSelection" matColumnDef="{{ columnconfig.header }}">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="{{ columnconfig.header }}">
                        <ng-container *ngIf="columnconfig.header === 'ActionConsultant' || columnconfig.header === 'ActionBackOffice'; else textHeader">
                        </ng-container>
                        <ng-template #textHeader> {{ columnconfig.header }}</ng-template>
                    </th>
                    <td mat-cell *matCellDef="let creation">
                        <ng-container [ngSwitch]="columnconfig.header">
                            <ng-container *ngSwitchCase="'ActionConsultant'">
                                <oui-button *ngIf="displaycellValue(columnconfig, creation) === 'true'" style="width: 100%" type="text" (click)="declareSouscription(creation)">Déclarer une souscription</oui-button>
                            </ng-container>
                            <ng-container *ngSwitchCase="'Statut'">
                                <lib-creation-personne-morale-statut-cell [creation]="creation"></lib-creation-personne-morale-statut-cell>
                            </ng-container>
                            <ng-container *ngSwitchCase="'ActionBackOffice'">
                                <oui-button style="width: 100%" type="text" iconPrefix="check_small" (click)="onValidCreation(creation)">Valider</oui-button>
                            </ng-container>

                            <div *ngSwitchDefault style="white-space: pre-line">
                                {{ displaycellValue(columnconfig, creation) }}
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </ng-container>
            </table>
        </oui-table>
        <div class="etat-vide-container" *ngIf="isLoading || dataSource.data.length === 0">
            <oui-etat-vide *ngIf="!isLoading" title="Aucun résultat" icon="live_help"></oui-etat-vide>
            <oui-loading *ngIf="isLoading"></oui-loading>
        </div>
    </div>
</div>
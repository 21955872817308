import { Injectable } from '@angular/core';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import { OuiAutocompleteOption, OuiSelectOption } from 'omnium-ui/form-field';
import { BoxEnvelope } from '../models/box-enveloppe.model';
import {
  EnvoiPartenaireStatut,
  MotifAnnulation,
  MotifRejet,
  TransactionPersonnelleEnum,
  TypeSignaturePartenaire,
} from '../models/generated/graphql';
import { OperationsService } from './operations.service';

enum NCCategories {
  date = 'Date',
  document = 'Document',
  informations = 'Informations',
  operation = 'Opération',
  preAccord = 'Pré accord',
  profilRisque = 'Profil de risque',
  signature = 'Signature',
  projet = 'Projet',
  autre = 'Autre',
}

enum NonConformiteMotifs {
  absent = 'Absent',
  incomplete = 'Incomplète',
  autre = 'Autre',
  manquanteIllisible = 'Manquante/illisible',
  manquanteIncoherente = 'Manquante/incohérente',
  incoherente = 'Incohérente',
  nonAccepte = 'Non accepté',
  enAttenteReceptionOriginal = 'En attente réception original',
  erronees = 'Erronées',
  incoherenceFichier = 'Incohérentes avec un autre document',
  impossible = "Impossible en l'état",
  nonConforme = 'Non conforme',
  formatNonAccepte = 'Format non accepté',
  mauvaisSignataire = 'Mauvais signataire',
  nonAdequat = 'Non adéquat/Non approprié',
}

export type TypeTransmission = 'envoiPostal' | 'pasEnvoiPostal';

@Injectable({
  providedIn: 'root',
})
export class OptionsService {
  // Non conformite are front side defined. backend only handle string so we save directly user readable
  // string in db
  private nonConformiteCategories: OuiSelectOption[] = Object.values(NCCategories).map(value => {
    return { value, label: value };
  });

  private nonConformiteMotifs: OuiSelectOption[] = [
    {
      label: NonConformiteMotifs.manquanteIllisible,
      value: NonConformiteMotifs.manquanteIllisible,
      data: NCCategories.date,
    },
    { label: NonConformiteMotifs.incoherente, value: NonConformiteMotifs.incoherente, data: NCCategories.date },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.date },

    { label: NonConformiteMotifs.nonAccepte, value: NonConformiteMotifs.nonAccepte, data: NCCategories.document },
    {
      label: NonConformiteMotifs.enAttenteReceptionOriginal,
      value: NonConformiteMotifs.enAttenteReceptionOriginal,
      data: NCCategories.document,
    },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.document },

    {
      label: NonConformiteMotifs.manquanteIllisible,
      value: NonConformiteMotifs.manquanteIllisible,
      data: NCCategories.informations,
    },
    { label: NonConformiteMotifs.erronees, value: NonConformiteMotifs.erronees, data: NCCategories.informations },
    {
      label: NonConformiteMotifs.incoherenceFichier,
      value: NonConformiteMotifs.incoherenceFichier,
      data: NCCategories.informations,
    },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.informations },

    { label: NonConformiteMotifs.nonAdequat, value: NonConformiteMotifs.nonAdequat, data: NCCategories.projet },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.projet },

    { label: NonConformiteMotifs.impossible, value: NonConformiteMotifs.impossible, data: NCCategories.operation },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.operation },

    { label: NonConformiteMotifs.absent, value: NonConformiteMotifs.absent, data: NCCategories.preAccord },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.preAccord },

    { label: NonConformiteMotifs.nonAdequat, value: NonConformiteMotifs.nonAdequat, data: NCCategories.profilRisque },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.profilRisque },

    {
      label: NonConformiteMotifs.formatNonAccepte,
      value: NonConformiteMotifs.formatNonAccepte,
      data: NCCategories.signature,
    },
    {
      label: NonConformiteMotifs.mauvaisSignataire,
      value: NonConformiteMotifs.mauvaisSignataire,
      data: NCCategories.signature,
    },
    {
      label: NonConformiteMotifs.manquanteIncoherente,
      value: NonConformiteMotifs.manquanteIncoherente,
      data: NCCategories.signature,
    },
    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.signature },

    { label: NonConformiteMotifs.autre, value: NonConformiteMotifs.autre, data: NCCategories.autre },
  ];

  private demembrementOptions: OuiButtonToggleOption<string>[] = [
    { label: 'Viager', value: 'Viager' },
    { label: 'Temporaire', value: 'Temporaire' },
  ];

  private modePaiementOptions: OuiSelectOption<string>[] = [
    { label: 'Chèque', value: 'Cheque' },
    { label: 'Prélèvement SEPA', value: 'PrelevementSEPA' },
    { label: 'Financement Crédit', value: 'FinancementCredit' },
    { label: 'Virement', value: 'Virement' },
  ];

  private transmissionOptions: OuiSelectOption<TypeTransmission>[] = [
    { label: 'Je dois envoyer les originaux par voie postale', value: 'envoiPostal' },
    { label: "Je n'ai pas besoin d'envoyer les originaux par voie postale", value: 'pasEnvoiPostal' },
  ];

  private typeSignaturePartenaireOptions: OuiSelectOption<TypeSignaturePartenaire>[] = [
    {
      label: 'Signature électronique (extranet partenaire)',
      value: TypeSignaturePartenaire.ElectroniqueExtranetPartenaire,
    },
    {
      label: 'Signature électronique (Ariane ou Universign)',
      value: TypeSignaturePartenaire.ElectroniqueArianeUniversign,
    },
    {
      label: 'Signature manuscrite',
      value: TypeSignaturePartenaire.Manuscrite,
    },
  ];

  private typeErrorEmailOptions: OuiSelectOption<boolean>[] = [
    {
      label: 'Avec e-mail en erreur',
      value: true,
    },
    {
      label: 'Sans e-mail en erreur',
      value: false,
    },
  ];
  private commandeAramisOptions: OuiSelectOption<boolean>[] = [
    {
      label: 'Rattachée ',
      value: true,
    },
    {
      label: 'Non rattachée',
      value: false,
    },
  ];
  private typeTauxFraisOptions: OuiButtonToggleOption<string>[] = [
    { label: 'Standard', value: 'standard' },
    { label: 'Dérogés', value: 'derogation' },
  ];

  private impactTauxFraisOptions: OuiButtonToggleOption<string>[] = [
    { label: 'Sur le Point Valeur', value: 'lignee' },
    { label: 'Sur ma rémunération personnelle', value: 'remuneration' },
  ];

  private objetModificationOptions: OuiSelectOption<string>[] = [
    { label: 'Frais', value: 'frais' },
    { label: 'Allocation', value: 'allocation' },
    { label: 'Montant', value: 'montant' },
    { label: 'Périodicité', value: 'periodicite' },
    { label: 'Coordonées bancaires', value: 'coordoneesBancaires' },
  ];

  private transactionPersonnelleRestictedOptions: OuiSelectOption<TransactionPersonnelleEnum>[] = [
    { label: 'Non', value: TransactionPersonnelleEnum.Non },
    { label: 'Auto-Souscription', value: TransactionPersonnelleEnum.AutoSouscription },
    { label: 'Pour un de mes proches', value: TransactionPersonnelleEnum.PourProches },
    { label: 'Pour un de mes salariés', value: TransactionPersonnelleEnum.PourSalaries },
  ];

  private motifAnnulationOptions: OuiSelectOption<MotifAnnulation>[] = [
    { value: MotifAnnulation.NoMoreWant, label: "L'investisseur ne souhaite plus souscrire" },
    { value: MotifAnnulation.CgpError, label: "Le CGP s'est trompé sur le bulletin" },
    { value: MotifAnnulation.NoFound, label: 'Financement non obtenu' },
    { value: MotifAnnulation.DuplicatedNoObject, label: 'Doublon / sans objet' },
  ];

  private motifRejetOptions: OuiSelectOption<MotifRejet>[] = [
    { value: MotifRejet.NoNcReturn, label: 'Pas de retour sur la non-conformité' },
    { value: MotifRejet.ChronoError, label: 'Problème de chronologie' },
    { value: MotifRejet.HabilitationError, label: 'Problème habilitation' },
    { value: MotifRejet.RiskProfilNotCompliant, label: 'Profil de risque opposé au placement' },
    { value: MotifRejet.RiskyClient, label: 'Client classé à risque' },
    { value: MotifRejet.FundSourceNotJustified, label: 'Origine des fonds non justifiée' },
    { value: MotifRejet.MoreThan85YearsOld, label: 'Refus + 85 ans' },
  ];

  private bordereauStatutOptions: OuiSelectOption<EnvoiPartenaireStatut>[] = [
    { value: EnvoiPartenaireStatut.CourrierBordereauCreated, label: 'Créé' },
    { value: EnvoiPartenaireStatut.CourrierSent, label: 'Envoyé' },
    { value: EnvoiPartenaireStatut.CourrierSearching, label: 'En recheche' },
    { value: EnvoiPartenaireStatut.CourrierLost, label: 'Perdu' },
    { value: EnvoiPartenaireStatut.CourrierReceived, label: 'Réceptionné' },
  ];

  private isInvestisseurPersonneMoraleOptions: OuiSelectOption<boolean>[] = [
    { value: true, label: 'Personne Morale' },
    { value: false, label: 'Personne Physique' },
  ];
  constructor(private operationsService: OperationsService) {}

  getLabelfromOptionValue(value: any, options: OuiSelectOption[]): string {
    const found = options.find(o => o.value === value);
    if (found) {
      return found.label;
    }
    return '';
  }

  // getters
  getNonConformiteCategories(): OuiSelectOption[] {
    return this.nonConformiteCategories;
  }
  getNonConformiteMotifs(): OuiSelectOption[] {
    return this.nonConformiteMotifs;
  }

  getDemembrementOptions(): OuiButtonToggleOption<string>[] {
    return this.demembrementOptions;
  }

  getModePaiementOptions(): OuiSelectOption<string>[] {
    return this.modePaiementOptions;
  }

  getTransmissionOptions(): OuiSelectOption<string>[] {
    return this.transmissionOptions;
  }

  getTypeSignaturePartenaireOptions(): OuiSelectOption<TypeSignaturePartenaire>[] {
    return this.typeSignaturePartenaireOptions;
  }

  getTypeErrorEmailFilterOptions(): OuiSelectOption<boolean>[] {
    return this.typeErrorEmailOptions;
  }

  getCommandeAramisOptions(): OuiSelectOption<boolean>[] {
    return this.commandeAramisOptions;
  }

  getTypeTauxFraisOptions(): OuiButtonToggleOption<string>[] {
    return this.typeTauxFraisOptions;
  }

  getImpactTauxFraisOptions(): OuiButtonToggleOption<string>[] {
    return this.impactTauxFraisOptions;
  }

  getObjetModificationOptions(): OuiSelectOption<string>[] {
    return this.objetModificationOptions;
  }

  getTransactionPersonnelleOptions(): OuiSelectOption<TransactionPersonnelleEnum>[] {
    return this.transactionPersonnelleRestictedOptions;
  }

  async getOperationTagOptionsWithData(): Promise<OuiSelectOption<number>[]> {
    const tags = await this.operationsService.getOperationTags();
    const options = tags
      .map(tag => <OuiSelectOption<number>>{ label: tag.libelle, value: tag.id, data: tag })
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }
  async getOperationTagOptions(): Promise<OuiSelectOption<number>[]> {
    const tags = await this.operationsService.getOperationTags();
    const options = tags
      .map(tag => <OuiSelectOption<number>>{ label: tag.libelle, value: tag.id })
      .sort((a, b) => a.label.localeCompare(b.label));

    return options;
  }
  getEnveloppeAutocompleteOptions(enveloppes: BoxEnvelope[]): OuiAutocompleteOption<number>[] {
    return enveloppes
      .map(env => <OuiAutocompleteOption<number>>{ label: env.libelle, value: env.code })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  getMotifAnnulationOptions(): OuiSelectOption<MotifAnnulation>[] {
    return this.motifAnnulationOptions;
  }
  getMotifRejetOptions(): OuiSelectOption<MotifRejet>[] {
    return this.motifRejetOptions;
  }

  getEnvoiPartenaireStatutOptions(): OuiSelectOption<EnvoiPartenaireStatut>[] {
    return this.bordereauStatutOptions;
  }

  getIsInvestisseurPersonneMoraleOptions(): OuiSelectOption<boolean>[] {
    return this.isInvestisseurPersonneMoraleOptions;
  }
}

<div oui-dialog-title>
  Editer le commentaire gestionnaire
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content>
  <div class="flex-vertical gap-16">
    <div class="body-1">Saisissez un commentaire qui sera visible par le consultant dans le détail de l’opération.</div>
    <oui-textarea label="Commentaire" placeholder="Commentaire" [control]="comment"></oui-textarea>
  </div>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" (click)="onCancel()"> Annuler </oui-button>
  <oui-button type="contained" (click)="onValidate()" [disabled]="!canValidate"> {{'Valider'}} </oui-button>
</oui-dialog-actions>

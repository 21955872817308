<div oui-dialog-title>
  Supprimer la note
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content> Votre note sera définitivement supprimée </oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" [oui-dialog-close]="true">Supprimer</oui-button>
</oui-dialog-actions>

import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { OuiDialogService } from 'omnium-ui/dialog';
import { CreationPersonneMorale } from 'projects/box-lib/src/public-api';
import { CreationPersonneMoraleDashboardColumn } from '../creation-personne-morale-dashboard-column';
import { CreationPMItem } from '../creation-personne-morale-dashboard.component';
import { CreationPersonneMoraleValidationComponent } from './creation-personne-morale-validation/creation-personne-morale-validation.component';

@Component({
  selector: 'lib-creation-personne-morale-table',
  templateUrl: './creation-personne-morale-table.component.html',
  styleUrls: ['./creation-personne-morale-table.component.scss'],
})
export class CreationPersonneMoraleTableComponent {
  @Input()
  columnSelection: CreationPersonneMoraleDashboardColumn[] = [];

  @Input()
  withRefresh = true;
  @Input()
  receivedData: { data: CreationPMItem[]; loading: boolean } | undefined;

  @Output()
  onDeclareSouscription = new EventEmitter<CreationPMItem>();

  @Output()
  onRefresh = new EventEmitter<void>();

  displayedColumns: string[];
  isLoading: boolean;
  dataSource: MatTableDataSource<CreationPMItem> = new MatTableDataSource<CreationPMItem>([]);
  sort: Sort;
  totalCount: number =0;
  constructor(private datePipe: DatePipe, private dialogService: OuiDialogService) {}
  ngOnInit(): void {
    this.displayedColumns = [...this.columnSelection.map(column => column.header)];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedData']) {
      this.receivedData = changes['receivedData'].currentValue;
      this.updateTableFromReceivedData();
    }
  }

  updateTableFromReceivedData() {
    if (this.receivedData) {
      this.isLoading = this.receivedData.loading;
      if (this.receivedData.data) {
        this.dataSource.data = this.receivedData.data;
        this.totalCount = this.receivedData.data.length;
        if(this.sort) {
          this.sortData(this.sort);
        }
      }
    }
  }
  displaycellValue(column: CreationPersonneMoraleDashboardColumn, creation: CreationPMItem): string {
    return column.getDisplayValue(creation, this.datePipe);
  }

  declareSouscription(creation: CreationPMItem) {
    this.onDeclareSouscription.emit(creation);
  }
  onValidCreation(creation: CreationPMItem) {
    const dialog = this.dialogService.openDialog(
      CreationPersonneMoraleValidationComponent,
      { creation: creation },
      'auto',
      '510px'
    );
    dialog.afterClosed().subscribe(result => {
      if (result) {
        this.onRefresh.emit();
      }
    });
  }

  sortData(sort: Sort) {
    this.sort = sort;
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    const config = this.columnSelection.find(c => c.header === sort.active);
    if (!config) {
      return;
    }
    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return config.sort(a, b, isAsc);
    });
    this.dataSource.data = sortedData;
  }
}

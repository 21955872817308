<div oui-dialog-title>
  Valider la demande
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content>
  <div class="flex-vertical gap-24">
    <div class="body-1">Pour valider la création sélectionner la personne morale créée.</div>
    <app-search-investisseur-autocomplete
      [defaultQueryFilter]="investisseurFilter"
      [fullDisplay]="false"
      (onInvestisseurIdSelected)="onInvestisseurIdSelected($event)"
      [typePersonnes]="[investisseurType.PersonneMorale]"></app-search-investisseur-autocomplete>
  </div>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close> Annuler </oui-button>
  <oui-button type="contained" (click)="onValidCreation()" [disabled]="!atlasInvestisseurId"> Valider </oui-button>
</oui-dialog-actions>

<oui-tag
  *ngIf="selectedConfig.tagType === 'status'"
  type="status"
  [status]="statusValue"
  [size]="size"
  [declination]="selectedConfig.declination">
  {{ statutText }}
</oui-tag>
<oui-tag
  *ngIf="selectedConfig.tagType === 'brand'"
  type="brand"
  [brand]="brandValue"
  [size]="size"
  [declination]="selectedConfig.declination">
  {{ statutText }}
</oui-tag>

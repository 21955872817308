<div oui-dialog-title>
  Historique du fichier
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content [scroll]="true">
  <div class="loading-container" *ngIf="isloading">
    <oui-loading size="small"></oui-loading>
  </div>

  <lib-history
    *ngIf="historyDisplayedEntries"
    [records]="historyDisplayedEntries"
    (documentOpen)="documentOpen.emit($event)"></lib-history>
</oui-dialog-content>

<div oui-dialog-title>
  Supprimer l'opération
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content class="flex-vertical gap-8">
  <div class="padding-bottom-8">
    Vous êtes sur le point de supprimer une opération. Toute suppression est définitive
  </div>
  <div class="subtitle-2">Operation concernée:</div>
  <div class="padding-16 rounded with-border">
    {{ investisseurFullName }}
    <ul class="margin-none">
      <li>{{ operationInfo }}</li>
    </ul>
  </div>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="onDelete()">Supprimer</oui-button>
</oui-dialog-actions>

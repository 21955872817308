<ng-container *ngIf="!transfertType">
  <div oui-dialog-title>
    Transmettre au partenaire
    <oui-button type="icon" oui-dialog-close>
      <oui-icon>close</oui-icon>
    </oui-button>
  </div>

  <oui-dialog-content class="choice">
    Sélectionnez le mode de transmission des documents au partenaire

    <div class="mode-buttons">
      <oui-button type="outlined" size="large" (click)="onTransmissionModeSelected(transfertTypeEnum.courrier)"
        >Courrier</oui-button
      >
      <oui-button type="outlined" size="large" (click)="onTransmissionModeSelected(transfertTypeEnum.email)"
        >E-mail</oui-button
      >
      <oui-button type="outlined" size="large" (click)="onTransmissionModeSelected(transfertTypeEnum.extranet)"
        >Extranet Partenaire</oui-button
      >
      <oui-button type="text" size="large" (click)="onTransmissionModeSelected(transfertTypeEnum.direct)"
        >Réponse en direct avec le partenaire</oui-button
      >
    </div>
  </oui-dialog-content>
</ng-container>

<ng-container [ngSwitch]="transfertType">
  <lib-notify-mail-partenaire
    *ngSwitchCase="transfertTypeEnum.email"
    dialogTitle="Transmettre par e-mail au partenaire"
    [operationId]="operationId"
    [isChild]="true"
    (onMailSent)="onValidation($event)"></lib-notify-mail-partenaire>

  <lib-confirmation-checkbox
    class="extranet-dialog"
    *ngSwitchCase="transfertTypeEnum.extranet"
    [selfClosing]="false"
    inputTitle="Transmettre par l'extranet partenaire"
    inputMessage="Je confirme avoir validé l'opération sur l'extranet du partenaire"
    inputValidateButtonLabel="Valider"
    (validateEvent)="onValidation($event)"></lib-confirmation-checkbox>

  <lib-confirmation-simple
    *ngSwitchCase="transfertTypeEnum.direct"
    class="direct-dialog"
    [selfClosing]="false"
    inputTitle="Confirmer la réponse en direct ?"
    inputMessage="Attention : ne pas utiliser ce mode de transmission pour l'envoi initial au partenaire."
    inputValidateButtonLabel="Confirmer"
    (validateEvent)="onValidation($event)"></lib-confirmation-simple>
</ng-container>

<ng-container *ngIf="fileInfo">
  <app-simple-document-viewer
    *ngIf="fileInfo.viewerType === 'simple'"
    [documentUrl]="fileInfo.documentUrl"
    [boxFile]="fileInfo.boxFile"
    [fileMetadata]="fileInfo.fileMetadata"
    [commentaire]="fileInfo.commentaire"
    (close)="closeDocumentViewer()"></app-simple-document-viewer>

  <app-appended-document-viewer
    *ngIf="fileInfo.viewerType === 'appended-file'"
    [fichierOperation]="fileInfo.fichierOperation!"
    (close)="closeAppendedDocumentViewer($event)"
    (remove)="removeFichierOperation($event)"></app-appended-document-viewer>

  <lib-operation-document-viewer
    *ngIf="fileInfo.viewerType === 'operation'"
    [fichierOperation]="fileInfo.fichierOperation!"
    (close)="closeDocumentViewer()"></lib-operation-document-viewer>

  <ng-container *ngIf="fileInfo.viewerType === 'custom'">
    <ng-template [cdkPortalOutlet]="fileInfo.customPortal!"></ng-template>
  </ng-container>
</ng-container>

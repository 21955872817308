import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@lib/services/auth-service.service';

export const authBackOfficeGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isInitLogin()) {
    inject(Router).navigate(['/init']);
  } else if (!inject(AuthService).isBackOffice()) {
    inject(Router).navigate(['/unauthorized']);
  }
  return true;
};

export const authAdminGuard: CanActivateFn = (route, state) => {
  if (inject(AuthService).isInitLogin()) {
    inject(Router).navigate(['/init']);
  } else if (!inject(AuthService).isAdminRole()) {
    inject(Router).navigate(['/unauthorized']);
  }
  return true;
};

// should not display init page if already logged in
export const InitGuard: CanActivateFn = (route, state) => {
  if (!inject(AuthService).isInitLogin()) {
    inject(Router).navigate(['']);
  }
  return true;
};

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NonConformiteFichier } from 'projects/box-lib/src/public-api';

@Component({
  selector: 'lib-copy-non-conformite',
  templateUrl: './copy-non-conformite.component.html',
  styleUrls: ['./copy-non-conformite.component.scss'],
})
export class CopyNonConformiteComponent {
  dataSource: MatTableDataSource<NonConformiteFichier> = new MatTableDataSource<NonConformiteFichier>([]);
  selectedIds: number[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: { similarNC: NonConformiteFichier[] },
    private dialogRef: MatDialogRef<CopyNonConformiteComponent>
  ) {
    this.dataSource.data = data.similarNC;
  }

  selectNC(arg0: any, $event: boolean) {
    if ($event) {
      this.selectedIds.push(arg0);
    } else {
      this.selectedIds = this.selectedIds.filter(id => id !== arg0);
    }
  }

  canValidate() {
    return this.selectedIds.length > 0;
  }
  validate() {
    let selectedNC = this.data.similarNC.filter(nc => this.selectedIds.includes(nc.id));
    this.dialogRef.close(selectedNC);
  }
  cancel() {
    this.dialogRef.close([]);
  }

  isAllSelected(): boolean {
    const ids = this.dataSource.data.map(f => f.id);
    if (JSON.stringify(ids) == JSON.stringify(this.selectedIds)) {
      return true;
    }
    return false;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selectedIds = [];
    } else {
      this.selectedIds = this.dataSource.data?.map(f => f.id);
    }
  }
}

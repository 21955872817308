<div class="full-screen-viewer-content">
  <div class="left-panel flex-none overflow-y-auto">
    <ng-content select="[left-panel]"> </ng-content>
  </div>

  <oui-document-viewer [documentUrl]="documentUrl" [showControls]="true">
    <oui-loading *ngIf="!isMissingFile && !documentUrl"></oui-loading>

    <oui-etat-vide
      *ngIf="isMissingFile"
      icon="unknown_document"
      title="Document en attente de dépôt par le consultant"></oui-etat-vide>

    <ng-container oui-document-controls>
      <ng-content select="[right-panel]"></ng-content>
    </ng-container>
  </oui-document-viewer>
</div>
<div *ngIf="withFooter" class="full-screen-viewer-footer">
  <oui-button type="text" class="flex-none" (click)="cancel()">
    {{ cancelButtonLabel }}
  </oui-button>
  <oui-button type="contained" class="flex-none" (click)="validate()" [disabled]="isValidateDisabled">
    {{ validateButtonLabel }}
  </oui-button>
  <oui-button
    *ngIf="optionnalButton1Label"
    type="contained"
    [color]="optionnalButtonColor"
    class="flex-none"
    (click)="optionnalButton1Click()"
    [disabled]="isOptionnalButton1Disabled">
    {{ optionnalButton1Label }}
  </oui-button>
</div>

import { Component, EventEmitter, Output } from '@angular/core';
import { GestionnaireWorkLoad } from 'projects/box-lib/src/public-api';
import { AbstractPaginatedTableComponent } from '../../../paginated-table/paginated-table.component';

@Component({
  selector: 'lib-gestionnaire-activity-table',
  templateUrl: './gestionnaire-activity-table.component.html',
  styleUrls: ['./gestionnaire-activity-table.component.scss'],
})
export class GestionnaireActivityTableComponent extends AbstractPaginatedTableComponent<GestionnaireWorkLoad> {
  displayedColumns = ['gestionnaire', 'souscriptions', 'actes', 'retourNCs', 'RetourInstances'];

  @Output() onSelectedGestionnaire: EventEmitter<number> = new EventEmitter<number>();
  constructor() {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  onRowClick(row: GestionnaireWorkLoad) {
    if (row) {
      this.onSelectedGestionnaire.emit(row.id);
    }
  }
}

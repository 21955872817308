<div class="flex-vertical gap-24 height-100pc">
  <div class="flex-horizontal">
    <h6 *ngIf="!editNC; else editHeader">Ajouter une non-conformité</h6>
    <ng-template #editHeader>
      <h6>Modifier une non-conformité</h6>
    </ng-template>
  </div>
  <div class="flex-vertical gap-16 flex-1">
    <oui-select label="Catégorie" [options]="categoriesList" [control]="categoryControl"></oui-select>
    <oui-select label="Motif" [options]="motifsList" [control]="motifControl"></oui-select>
    <div class="flex-vertical flex-1">
      <oui-textarea
        class="height-100pc"
        label="Commentaire"
        [control]="commentaireControl"
        placeholder="Commentaire"></oui-textarea>
    </div>
  </div>
  <ng-container *ngIf="isInstanceMode">
    <oui-slide-toggle
      label="Instance à traiter en interne"
      (checkedChange)="onSetInternalChange($event)"
      [checked]="!!editNC?.isInternal">
    </oui-slide-toggle>
  </ng-container>
  <div class="flex-horizontal gap-16">
    <div><oui-button type="text" (click)="onCancel()">Annuler</oui-button></div>
    <div *ngIf="!editNC; else editButton" class="flex-1">
      <oui-button [disabled]="!isValid()" (click)="onAdd()" class="width-100pc">Ajouter</oui-button>
    </div>
    <ng-template #editButton>
      <div class="flex-1">
        <oui-button [disabled]="!isValid()" (click)="onEdit()" class="width-100pc">Valider</oui-button>
      </div>
    </ng-template>
  </div>
</div>

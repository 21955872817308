import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { Produit, ProduitSortInput, SortEnumType } from '@lib/models/generated/graphql';
import { ChangeProduitSouscriptionPossible } from '@lib/services/changeProduitSouscriptionPossible';

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent extends AbstractPaginatedTableComponent<Produit> {
  @ViewChild('produitsSort') produitsSort!: MatSort;
  displayedColumns = ['activate-toggle', 'produit', 'partenaire', 'enveloppe', 'statut'];
  @Output() onSortClick = new EventEmitter<ProduitSortInput | undefined>();

  constructor(
    private changeSubsHandler: ChangeProduitSouscriptionPossible,
    private router: Router,
    private cdref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.produitsSort.sortChange.subscribe(() => {
      this.onSortClick.emit(this.getSortInputBySort(this.produitsSort));
    });
  }

  getSortInputBySort(sort: MatSort): ProduitSortInput | undefined {
    {
      if (sort.direction == '') {
        return undefined;
      }
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'produit':
          return { nom: isAsc ? SortEnumType.Asc : SortEnumType.Desc }; //here we choose nom over nomTechniqueProduitBox because nomTechniqueProduitBox can be null and actually it is null in a significant number of produits
        case 'partenaire':
          return { idPartenaireNavigation: { nom: isAsc ? SortEnumType.Asc : SortEnumType.Desc } };
        case 'enveloppe':
          return { enveloppeNavigation: { libelle: isAsc ? SortEnumType.Asc : SortEnumType.Desc } };
        default:
          return undefined;
      }
    }
  }
  onProduitActiveToggle(produit: Produit) {
    let newValue = !(produit?.produitExtension?.souscriptionPossible ?? true);

    //On fait une requete de mutation pour changer l'état
    this.changeSubsHandler.mutate({ produitId: produit.id, newValue }).subscribe(result => {
      // on recupère le produit extension post mutation
      const produitExtension = result?.data?.updateProduitExtensionSouscriptionPossible;
      if (produitExtension) {
        // on retouve le produit associé dans le datasource
        const dataSourceProd = this.dataSource.data.find(prod => prod.produitExtension?.id === produitExtension?.id);
        if (dataSourceProd) {
          // on update le produit extension dans le datasource
          dataSourceProd.produitExtension = produitExtension;
        }
        // on force le rendering du tableau car on a changer un sous-element du tableau qui n'est pas detecté
        this.table.renderRows();
      }
    });
  }

  onRowClick(produit: Produit) {
    this.router.navigate(['parametres', 'produits', produit.id]);
  }
}

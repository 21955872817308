import { FichierOperation } from '../models/generated/graphql';

/**
 * Returns a label for a FichierOperation according business rules.
 *
 * @param {FichierOperation} file - The FichierOperation object to get the label for.
 * @return {string} The label for the FichierOperation object. If the file's is out of operationConfiguration,
 * it returns the denomination from the metadata or 'Autre' if it is undefined. Otherwise, it returns the nom from
 * the natureDocumentRequis or the denomination from the metadata or 'Autre' if both are undefined.
 */
export const fileLabel = (file: FichierOperation): string => {
  if (file.estHorsConfiguration) {
    return file.metadata?.denomination ?? file.natureDocumentRequis?.natureDocument?.nom ?? 'Autre';
  }
  return file.natureDocumentRequis?.natureDocument?.nom ?? file.metadata?.denomination ?? 'Autre Document';
};

import { Component, Input } from '@angular/core';
import { FichierOperationHistoryRecord, FichierOperationHistoryRecordTypes } from '../../models/generated/graphql';
import { CommonModule } from '@angular/common';
import { OuiIconModule } from 'omnium-ui/icon';
@Component({
  selector: 'app-file-conform-status-stamp',
  standalone: true,
  imports: [CommonModule, OuiIconModule],
  templateUrl: './file-conform-status-stamp.component.html',
  styleUrls: ['./file-conform-status-stamp.component.scss'],
})
export class FileConformStatusStampComponent {
  @Input() historyEntries: FichierOperationHistoryRecord[];
  latestConformity: FichierOperationHistoryRecord | null = null;

  ngOnInit(): void {
    const conformityEntries = this.historyEntries.filter(
      entry => entry.type == FichierOperationHistoryRecordTypes.FichierOperationSetToConforme
    );
    if (conformityEntries.length > 0) {
      this.latestConformity = conformityEntries.reduce((a, b) => (a.timestamp > b.timestamp ? a : b));
    }
  }
}

<ng-container *ngIf="!isLoading || !useSpinner">
  <oui-autocomplete
    label="Enveloppe"
    [control]="enveloppeControl"
    [options]="enveloppeAutocompleteOptions"
    class="width-100pc"></oui-autocomplete>

  <oui-autocomplete
    #searchProduit
    label="Produit"
    [control]="produitControl"
    [options]="produitAutocompleteOptions"
    [allowCustomValue]="false"
    class="width-100pc"></oui-autocomplete>
</ng-container>
<div class="flex-horizontal align-self-center">
  <oui-loading *ngIf="isLoading && useSpinner"></oui-loading>
</div>

import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { OuiTagStatus } from 'omnium-ui/tag';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { OperationSpecificData } from '../models/donees-specifiques.model';
import { operationDetailsPageBackOfficeFragment, subscribeOperationDetailsFragment } from '../models/graphqlFragments';
import { Operation, OperationStateTransitionTrigger, OperationTag, UpdateOperationInput } from '../models/generated/graphql';
import { ApplicationInsightsService } from './ApplicationInsightService';
import { AuthService } from './auth-service.service';
import { QueryManagerService } from './queryManagerService';

const updateAllOperationsBackOffice = gql`
  mutation updateOperations($operationDatas: [UpdateOperationInput!]!) {
    updateOperations(operationDatas: $operationDatas) {
      ...operationDetailsPage
    }
  }
  ${operationDetailsPageBackOfficeFragment}
`;

const updateAllOperationsConsultant = gql`
  mutation updateOperations($operationDatas: [UpdateOperationInput!]!) {
    updateOperations(operationDatas: $operationDatas) {
      ...subscribeOperationDetails
    }
  }
  ${subscribeOperationDetailsFragment}
`;

const updateOperationTag = gql`
  mutation updateOperationTag($operationId: Int!, $tagIds: [Int!]!) { 
    setOperationTags(operationId: $operationId, tagIds: $tagIds) {
      id
      tags {
        id
        libelle
      }
    }
  }
`;

const getAllOperationTagsQuery = gql`
  query allOperationTags {
    allOperationTags {
      id
      code
      libelle
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class OperationsService {
  constructor(
    private queryManager: QueryManagerService,
    private loggerService: ApplicationInsightsService,
    private authService: AuthService
  ) {}

  public async updateOperations(model: UpdateOperationInput[]): Promise<Operation[]> {
    const mutation = this.authService.isBackOffice() ? updateAllOperationsBackOffice : updateAllOperationsConsultant;
    const result = await firstValueFrom(
      this.queryManager.mutate<{ updateOperations: Operation[] }>({
        mutation: mutation,
        variables: {
          operationDatas: model,
        },
      })
    );

    // TODO gestion d'erreurs
    return result.data!.updateOperations;
  }

  public async updateOperation(model: UpdateOperationInput): Promise<Operation> {
    const result = await this.updateOperations([model]);

    return result[0];
  }

  public async updateOperationTag(operationId: number, tagIds: number[]): Promise<Operation | undefined> {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ setOperationTag: Operation }>({
        mutation: updateOperationTag,
        variables: {
          operationId,
          tagIds,
        },
      })
    );

    return result.data?.setOperationTag;
  }

  public getOperationStatusColor(operation: Operation, isConsultant: boolean): OuiTagStatus {
    const statut = operation.statut.id;

    if (statut == 400) {
      return 'completed';
    } else if (statut >= 405) {
      return 'pending';
    }

    if (isConsultant) {
      if (statut <= 5) {
        return 'in-progress';
      } else if (statut >= 50 && statut < 100) {
        return 'error';
      } else if (statut >= 100 && statut <= 310) {
        return 'transition';
      }
    } else {
      if (statut >= 50 && statut <= 100) {
        return 'transition';
      } else if (statut >= 105 && statut <= 206) {
        return 'in-progress';
      } else if (statut >= 250 && statut <= 300) {
        return 'transition';
      } else if (statut == 310) {
        return 'in-progress';
      }
    }

    return 'in-progress';
  }

  public async getOperationTags(): Promise<OperationTag[]> {
    const result = await lastValueFrom(
      this.queryManager.query<{ allOperationTags: OperationTag[] }>(
        {
          query: getAllOperationTagsQuery,
        },
        false,
        true
      )
    );

    return result.data.allOperationTags;
  }

  parseSpecificData(donneesSpecifiques: string): OperationSpecificData {
    try {
      return JSON.parse(donneesSpecifiques);
    } catch (error: any) {
      const newError = new Error('Données Spécifiques : JSON Parser Error', error);
      this.loggerService.logException(newError);
      return {} as OperationSpecificData;
    }
  }

  public canSendToPartenaire(operationTriggers: OperationStateTransitionTrigger[]) {
    return operationTriggers.includes(OperationStateTransitionTrigger.GestionnaireTransfersOperationToPartenaire);
  }
}

export enum DashboardFilterEnum {
  GoToById,
  Statut,
  Enveloppe,
  Nature,
  Produit,
  Montant,
  Gestionnaire,
  FondEvnt,
  Investisseur,
  Tag,
  Signature,
  Partenaire,
  BordereauDateEnvoi,
  BordereauStatut,
  ErrorPartenaireEmail,
  CommandeAramis,
  IsInvestisseurPersonneMorale,
}

<div class="flex-horizontal gap-16 padding-vertical-32">
  <div class="flex-horizontal gap-8 align-self-center" *ngIf="files && files.length > 0">
    <oui-checkbox
      (checkedChange)="toggleAllRows()"
      [checked]="selection.length > 0 && isAllSelected()"
      [indeterminate]="selection.length > 0 && !isAllSelected()">
    </oui-checkbox>
    <div class="body-2 color-on-surface-medium align-self-center">
      {{ files.length }} <span *ngIf="files.length > 1">documents</span> <span *ngIf="files.length < 2">document</span>
    </div>
  </div>
  <oui-button
    *ngIf="!isFileDownloading"
    class="align-self-center"
    type="text"
    iconPrefix="download"
    (click)="onDownloadSelectedFiles()"
    [disabled]="!selection || selection.length === 0"
    >Télécharger</oui-button
  ><oui-loading *ngIf="isFileDownloading" size="small"></oui-loading>
  <div class="flex-1"></div>
  <oui-button type="contained" (click)="onAddAttachedFile($event)" class="align-self-center"
    >Ajouter des documents</oui-button
  >
</div>
<div class="flex-vertical rounded with-border background-white">
  <div
    *ngFor="let file of files; let eltIndex = index"
    class="flex-horizontal height-56"
    [ngClass]="eltIndex > 0 ? 'with-border-top' : ''">
    <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
      <oui-checkbox
        [checked]="selection.includes(file.id)"
        (checkedChange)="toggleFileSelection(file.id)"></oui-checkbox>

      <div *ngIf="isFileViewerCompatible(file)" class="cursor-pointer flex-none" (click)="openFileViewer(file)">
        <u class="color-primary-500">{{ file.fileNameWithExtension }}</u>
      </div>
      <span *ngIf="!isFileViewerCompatible(file)">{{ file.fileNameWithExtension }}</span>
    </div>
    <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
      <oui-textfield
        class="flex-1 padding-right-16"
        [placeholder]="'Nom du document'"
        [value]="file.denomination"
        (change)="onFileDenominationChange(file.id, $event)"></oui-textfield>
      <oui-icon class="flex-none align-self-center cursor-pointer" (click)="onDeleteFile(file.id)">delete</oui-icon>
    </div>
  </div>
</div>
<div id="target_div" class="display-none"></div>

import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { BoxDocumentType } from '@lib/models/generated/graphql';
import { fondEvenementielFragment } from '@lib/models/graphqlFragments';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';

const UpdateFondEvenementiel = gql`
  mutation updateFondEvenementiel($fondEvenementielId: Int!, $libelle: String, $produitIds: [Int!]) {
    updateFondEvenementiel(fondEvenementielId: $fondEvenementielId, libelle: $libelle, produitIds: $produitIds) {
      ...fondEvenementielSimple
    }
  }
  ${fondEvenementielFragment}
`;
@Component({
  selector: 'app-doc-types-table',
  templateUrl: './doc-types-table.component.html',
  styleUrls: ['./doc-types-table.component.scss'],
})
export class TypesDocsTableComponent extends AbstractPaginatedTableComponent<BoxDocumentType> {
  displayedColumns = ['typesDocument', 'parentGedLibelle'];

  constructor(
    private dialog: MatDialog,
    private queryManager: QueryManagerService,
    private dialogService: OuiDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  // onUpdateFond(fond: FondEvenementiel) {
  //   const modalRef = this.dialog.open(FondEvenementielModalComponent, {
  //     width: '660px',
  //     height: 'auto',
  //     data: {
  //       fondEvenementiel: fond,
  //       title: 'Modifier un fond événementiel',
  //       validateButtonLabel: 'Valider',
  //     },
  //   });
  //   modalRef.afterClosed().subscribe(result => {
  //     if (result && result.id) {
  //       this.fetchData(false);
  //     }
  //   });
  // }
}

import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';
import { Produit } from '../models/generated/graphql';

type ProduitDetailsResponse = {
  produitById: Produit;
};

@Injectable({
  providedIn: 'root',
})
export class ProduitDetails extends Query<ProduitDetailsResponse> {
  override document = gql`
    query produitById($produitID: Long!) {
      produitById(id: $produitID) {
        id
        nom
        nomTechniqueProduitBox
        habilitationNavigation {
          libelle
          code
        }
        enveloppeNavigation {
          code
          libelle
          operationConfigs {
            natureOperation {
              libelle
            }
            natureDocumentRequis {
              id
              natureDocument {
                id
                nom
                boxDocumentType {
                  key
                  gedParentTypeCode
                }
                categorieId
                categorie {
                  libelle
                }
              }
              applicableHabilitationCode
            }
          }
        }
        idPartenaireNavigation {
          nom
        }
        produitExtension {
          libelle
          souscriptionPossible
        }
      }
    }
  `;
}

<div class="flex-vertical gap-16 width-100pc">
  <div>{{ totalCount }} <span *ngIf="totalCount > 1">produits</span><span *ngIf="totalCount < 2">produit</span></div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource" matSort #produitsSort="matSort">
        <ng-container matColumnDef="activate-toggle">
          <th mat-header-cell *matHeaderCellDef>Affiché à la souscription</th>
          <td mat-cell *matCellDef="let element">
            <oui-slide-toggle
              label=""
              (checkedChange)="onProduitActiveToggle(element)"
              [checked]="element?.produitExtension?.souscriptionPossible ?? true"
              (click)="$event.stopPropagation()">
            </oui-slide-toggle>
          </td>
        </ng-container>

        <ng-container matColumnDef="produit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Produit</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.produitExtension?.libelle ?? element?.nomTechniqueProduitBox ?? element?.nom }}
          </td>
        </ng-container>

        <ng-container matColumnDef="partenaire">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Partenaire</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.idPartenaireNavigation?.nom }}
          </td>
        </ng-container>

        <ng-container matColumnDef="enveloppe">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Enveloppe</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.enveloppeNavigation?.libelle }}
          </td>
        </ng-container>

        <ng-container matColumnDef="statut">
          <th mat-header-cell *matHeaderCellDef>Statut</th>
          <td mat-cell *matCellDef="let element">
            <oui-tag
              type="status"
              status="completed"
              size="big"
              *ngIf="element?.produitExtension?.souscriptionPossible; else noSubs"
              >Affiché à la souscription</oui-tag
            >
            <ng-template #noSubs>
              <oui-tag type="status" status="pending" size="big">Masqué à la souscription</oui-tag>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr
            mat-row
            [class.interactive]="true"
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>

import { Injectable } from '@angular/core';

const keyListKey = 'keyList';

// Class handling user parameters in local storage
@Injectable({
  providedIn: 'root',
})
export class UserParametersService {
  constructor() {}

  saveUserParam(callerKey: string, paramKey: string, value: any) {
    var key = callerKey + '_' + paramKey;

    if (value && key) {
      this.saveKey(key);
      localStorage.setItem(callerKey + '_' + paramKey, JSON.stringify(value));
    }
  }

  removeUserParam(callerKey: string, paramKey: string) {
    var key = callerKey + '_' + paramKey;

    if (key) {
      localStorage.removeItem(callerKey + '_' + paramKey);
      const keys = this.getkeyList();
      keys.splice(keys.indexOf(key), 1);
      localStorage.setItem(keyListKey, JSON.stringify(keys));
    }
  }

  getkeyList(): string[] {
    const keyList = localStorage.getItem(keyListKey);
    if (keyList) {
      return JSON.parse(keyList);
    } else {
      return [];
    }
  }

  saveKey(key: string) {
    var keyList = this.getkeyList();
    if (!keyList.includes(key)) {
      keyList.push(key);
      localStorage.setItem(keyListKey, JSON.stringify(keyList));
    }
  }

  getUserParam(callerKey: string, paramKey: string) {
    var value = localStorage.getItem(callerKey + '_' + paramKey);
    if (!value) {
      return undefined;
    }
    if (value === '[null]') {
      return [undefined];
    }
    return JSON.parse(value);
  }

  // We want to clear local storage each time user authenticate so we check the authentication date. if ti has changed we clear local storage
  // to reset user parameters
  checkLocalStorageClear(authenticationDate: string) {
    const lastSavedAuthDate = localStorage.getItem('authDate');
    if (lastSavedAuthDate != authenticationDate) {
      this.getkeyList().forEach(key => localStorage.removeItem(key));
      localStorage.setItem(keyListKey, JSON.stringify([]));
      localStorage.setItem('authDate', authenticationDate);
    }
  }
}

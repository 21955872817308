import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fondEvenementielFragment } from '@lib/models/graphqlFragments';
import { FondEvenementiel, Produit, ProduitFilterInput } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { firstValueFrom } from 'rxjs';

//

const allProduits = gql`
  query allProduits($where: ProduitFilterInput) {
    allProduits(where: $where) {
      id
      nom
      nomTechniqueProduitBox
      produitExtension {
        libelle
        souscriptionPossible
      }
    }
  }
`;

const CreateFondEvenementiel = gql`
  mutation createFondEvenementiel($libelle: String!, $produitIds: [Int!]!) {
    createFondEvenementiel(libelle: $libelle, produitIds: $produitIds) {
      ...fondEvenementielSimple
    }
  }
  ${fondEvenementielFragment}
`;

const UpdateFondEvenementiel = gql`
  mutation updateFondEvenementiel($fondEvenementielId: Int!, $libelle: String, $produitIds: [Int!]) {
    updateFondEvenementiel(fondEvenementielId: $fondEvenementielId, libelle: $libelle, produitIds: $produitIds) {
      ...fondEvenementielSimple
    }
  }
  ${fondEvenementielFragment}
`;
@Component({
  selector: 'app-fond-evenementiel-modal',
  templateUrl: './fond-evenementiel-modal.component.html',
  styleUrls: ['./fond-evenementiel-modal.component.scss'],
})
export class FondEvenementielModalComponent {
  title: string | undefined;
  validateButtonLabel: string | undefined;
  fondEvenementiel: FondEvenementiel | undefined;
  productOptions: OuiSelectOption<any>[] = [];
  libelle: string;
  isLoadingProducts = false;
  isMutating = false;
  produitIds: number[];

  libelleControl: FormControl<string | null> = new FormControl<string>('', Validators.required);
  produitIdsControl: FormControl<number[] | null> = new FormControl<number[]>([], Validators.required);
  isCreateOrUpdate: boolean;

  constructor(
    private dialogRef: MatDialogRef<FondEvenementielModalComponent>,
    private queryManager: QueryManagerService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      title: string;
      validateButtonLabel: string;
      fondEvenementiel: FondEvenementiel;
    }
  ) {}

  ngOnInit() {
    this.getProduitOptions();
    this.title = this.data.title;
    this.validateButtonLabel = this.data.validateButtonLabel;
    this.fondEvenementiel = deepCopy(this.data.fondEvenementiel);
    if (this.fondEvenementiel) {
      this.libelleControl.setValue(this.fondEvenementiel?.libelle);
      this.produitIdsControl.setValue(this.fondEvenementiel?.produitFondEvenementiels?.map(p => p?.produitId) ?? []);
    }
  }

  async getProduitOptions() {
    this.isLoadingProducts = true;
    const where: ProduitFilterInput = {};
    const produitsResult = await firstValueFrom(
      this.queryManager.query<{ allProduits: Produit[] }>({
        query: allProduits,
        variables: {
          where: where,
        },
      })
    );
    const produits =
      produitsResult.data.allProduits.filter(prod => prod?.produitExtension?.souscriptionPossible === true) ?? [];
    this.productOptions = produitsResult.data.allProduits
      ?.map(produit => ({
        label: produit.produitExtension?.libelle ?? produit.nomTechniqueProduitBox ?? produit.nom ?? 'Produit sans nom',
        value: produit.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    this.isLoadingProducts = false;
  }

  onCancel() {
    this.dialogRef.close();
  }
  async onValidate() {
    if (this.libelleControl.valid && this.produitIdsControl.valid) {
      let uptoDate: FondEvenementiel | undefined;
      if (this.fondEvenementiel) {
        uptoDate = await this.updateFondEvenementiel();
      } else {
        uptoDate = await this.createFondEvenementiel();
      }
      this.dialogRef.close(uptoDate);
    }
  }

  async createFondEvenementiel(): Promise<FondEvenementiel | undefined> {
    this.isMutating = true;
    const result = await firstValueFrom(
      this.queryManager.mutate<{ createFondEvenementiel: FondEvenementiel }>({
        mutation: CreateFondEvenementiel,
        variables: {
          libelle: this.libelleControl.value,
          produitIds: this.produitIdsControl.value,
        },
      })
    );
    this.isMutating = result.loading;
    if (result.data) {
      if (result.data.createFondEvenementiel) {
        return result.data.createFondEvenementiel;
      }
    }
    return undefined;
  }

  async updateFondEvenementiel(): Promise<FondEvenementiel | undefined> {
    this.isMutating = true;
    const result = await firstValueFrom(
      this.queryManager.mutate<{ updateFondEvenementiel: FondEvenementiel }>({
        mutation: UpdateFondEvenementiel,
        variables: {
          fondEvenementielId: this.fondEvenementiel?.id,
          libelle: this.libelleControl.value,
          produitIds: this.produitIdsControl.value,
        },
      })
    );
    this.isMutating = result.loading;
    if (result.data) {
      if (result.data.updateFondEvenementiel) {
        return result.data.updateFondEvenementiel;
      }
    }
    return undefined;
  }
}

import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MotifAnnulation, MotifRejet, Operation } from '@lib/models/generated/graphql';
import { OptionsService } from '@lib/services/options.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiSelectOption } from 'omnium-ui/form-field/select/select-option.model';
import { firstValueFrom } from 'rxjs';

const cancelOperation = gql`
  mutation cancelOperation($operationId: Int!, $commentaire: String!, $motifAnnulation: MotifAnnulation!) {
    cancelOperation(operationId: $operationId, comment: $commentaire, motifAnnulation: $motifAnnulation) {
      id
      statutId
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      operationActionRights
    }
  }
`;

const refuseOperation = gql`
  mutation refuseOperation($operationId: Int!, $commentaire: String!, $motifRejet: MotifRejet!) {
    refuseOperation(operationId: $operationId, comment: $commentaire, motifRejet: $motifRejet) {
      id
      statutId
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
      operationActionRights
    }
  }
`;

@Component({
  selector: 'app-refuse-cancel-operation-modal',
  templateUrl: './refuse-cancel-operation-modal.component.html',
  styleUrls: ['./refuse-cancel-operation-modal.component.scss'],
})
export class RefuseCancelOperationModalComponent {
  isMutating = false;
  canValidate = false;
  title: string | undefined;
  message: string | undefined;

  comment: FormControl<string | null> = new FormControl<string>('', Validators.required);
  motifAnnulation: FormControl<MotifAnnulation | null> = new FormControl<MotifAnnulation | null>(
    null,
    Validators.required
  );
  motifRejet: FormControl<MotifRejet | null> = new FormControl<MotifRejet | null>(null, Validators.required);
  motifAnnulationOptions: OuiSelectOption<MotifAnnulation>[];
  motifRejetOptions: OuiSelectOption<MotifRejet>[];

  constructor(
    private dialogRef: MatDialogRef<RefuseCancelOperationModalComponent>,
    private optionsService: OptionsService,
    private queryManager: QueryManagerService,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      operationId: number;
      isReject: boolean;
    }
  ) {
    this.motifAnnulationOptions = this.optionsService.getMotifAnnulationOptions();

    this.motifRejetOptions = this.optionsService.getMotifRejetOptions();
  }

  ngOnInit() {
    if (this.data.isReject) {
      this.message = 'Précisez le motif du refus';
      this.title = "Refuser l'opération";
    } else {
      this.message = "Précisez le motif de l'annulation";
      this.title = "Annuler l'opération";
    }
    this.updateCanValidate();
    this.comment.valueChanges.subscribe(() => this.updateCanValidate());
    this.motifAnnulation.valueChanges.subscribe(() => this.updateCanValidate());
    this.motifRejet.valueChanges.subscribe(() => this.updateCanValidate());
  }

  updateCanValidate() {
    if (this.data.isReject) {
      this.canValidate = this.motifRejet.valid && this.comment.valid;
    } else {
      this.canValidate = this.motifAnnulation.valid && this.comment.valid;
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  async onValidate() {
    if (this.data.isReject) {
      if (this.motifRejet.valid && this.comment.valid) {
        var ope = await this.refuseOperation();
        this.dialogRef.close(ope);
      }
    } else {
      if (this.motifAnnulation.valid && this.comment.valid) {
        var ope = await this.cancelOperation();
        this.dialogRef.close(ope);
      }
    }
  }

  async cancelOperation(): Promise<Operation | undefined> {
    if (this.data.operationId) {
      this.isMutating = true;
      const result = await firstValueFrom(
        this.queryManager.mutate<{ cancelOperation: Operation }>({
          mutation: cancelOperation,
          variables: {
            operationId: this.data.operationId,
            commentaire: this.comment.value,
            motifAnnulation: this.motifAnnulation.value,
          },
        })
      );
      this.isMutating = result.loading;
      if (result.data) {
        if (result.data.cancelOperation) {
          return result.data.cancelOperation;
        }
      }
    }
    return undefined;
  }

  async refuseOperation(): Promise<Operation | undefined> {
    if (this.data.operationId) {
      this.isMutating = true;
      const result = await firstValueFrom(
        this.queryManager.mutate<{ refuseOperation: Operation }>({
          mutation: refuseOperation,
          variables: {
            operationId: this.data.operationId,
            commentaire: this.comment.value,
            motifRejet: this.motifRejet.value,
          },
        })
      );
      this.isMutating = result.loading;
      if (result.data) {
        if (result.data.refuseOperation) {
          return result.data.refuseOperation;
        }
      }
    }
    return undefined;
  }
}

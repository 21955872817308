import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { OuiAutocomplete, OuiAutocompleteOption } from 'omnium-ui/form-field';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  Commande,
  CommandeFilterInput,
  CommandesFinancieresPaginatedCollectionSegment,
  Operation,
} from '../../../models/generated/graphql';

const GET_COMMANDES_FINANCIERE = gql`
  query allCommandesFinancieresPaginated($where: CommandeFilterInput) {
    allCommandesFinancieresPaginated(skip: 0, take: 100, where: $where) {
      items {
        id
        nom
        numCommande
      }
      totalCount
    }
  }
`;

const ATTACH_COMMANDE = gql`
  mutation attachCommandeToOperation($operationId: Int!, $commandeId: Long!) {
    attachCommandeToOperation(operationId: $operationId, commandeId: $commandeId) {
      contratId
      contrat {
        numeroContrat
        nom
        numCommande
      }
    }
  }
`;

@Component({
  selector: 'lib-commande-aramis-dialog',
  templateUrl: './commande-aramis-dialog.component.html',
  styleUrls: ['./commande-aramis-dialog.component.scss'],
})
export class CommandeAramisDialogComponent {
  operation: Operation;
  commandes: Commande[] = [];

  commandesAutocompleteOptions: OuiAutocompleteOption[] = [];
  commandesControl = new FormControl<number | undefined>(undefined);

  commandesReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectedCommandeId?: number;

  @ViewChild('commandeAutocomplete') investisseurAutocomplete: OuiAutocomplete<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operation: Operation },
    private dialogRef: MatDialogRef<CommandeAramisDialogComponent>,
    private queryManager: QueryManagerService,
    private cdRef: ChangeDetectorRef
  ) {
    this.operation = this.data.operation;
  }

  ngOnInit() {
    this.fetchAvailableCommandes().then(() => {
      this.setInitialValue();
    });
  }
  ngAfterViewInit() {
    this.commandesControl.valueChanges.subscribe(commandeId => {
      this.selectedCommandeId = Number(commandeId) || undefined;
    });
    this.commandesReady.subscribe(ready => {
      if (ready) {
        if (this.operation.contrat?.id) {
          this.setInitialValue();
        }
      }
    });
  }
  async fetchAvailableCommandes() {
    if (this.operation.investisseur?.id) {
      let result = await firstValueFrom(
        this.queryManager.query<{ allCommandesFinancieresPaginated: CommandesFinancieresPaginatedCollectionSegment }>({
          query: GET_COMMANDES_FINANCIERE,
          variables: {
            where: this.buildQueryFilter(),
          },
        })
      );
      this.commandes = result.data.allCommandesFinancieresPaginated.items ?? [];
      this.commandesAutocompleteOptions = this.commandes.map(c => {
        return { label: `${c.nom}`, value: c.id };
      });

      this.commandesReady.next(true);
    }
  }
  removeLink() {
    this.commandesControl.setValue(undefined);
  }
  setInitialValue() {
    if (this.operation.contrat?.id) {
      const commandeId = Number(this.operation.contrat?.id) || undefined;
      if (commandeId) {
        this.commandesControl.setValue(commandeId);
        this.commandesControl.patchValue(commandeId);
        this.commandesControl.updateValueAndValidity();
        this.cdRef.detectChanges();
      }
    }
  }
  async validate() {
    this.queryManager
      .mutate<{ attachCommandeToOperation: Operation }>({
        mutation: ATTACH_COMMANDE,
        variables: {
          operationId: this.data.operation.id,
          commandeId: this.selectedCommandeId ?? -1,
        },
      })
      .subscribe(({ data }) => {
        this.dialogRef.close(data?.attachCommandeToOperation);
      });
  }

  buildQueryFilter() {
    const where = {
      and: [],
    } as CommandeFilterInput;
    const etatCommandeFilter = { etatCommande: { in: [0, 1] } };
    where.and!.push(etatCommandeFilter);

    const investisseurId = this.operation.investisseur?.id ?? this.operation.investisseurId;
    const coInvestisseurId = this.operation.coInvestisseur?.id ?? this.operation.coInvestisseurId;
    if (investisseurId || coInvestisseurId) {
      const InvestisseurFilter = { or: [] } as CommandeFilterInput;
      if (investisseurId) {
        const investisseurFilter = { investisseurCommande: { some: { investisseurId: { eq: investisseurId } } } };
        InvestisseurFilter.or!.push(investisseurFilter);
      }
      if (coInvestisseurId) {
        const coInvestisseurFilter = { investisseurCommande: { some: { investisseurId: { eq: coInvestisseurId } } } };
        InvestisseurFilter.or!.push(coInvestisseurFilter);
      }
      where.and!.push(InvestisseurFilter);
    }
    return where;
  }
}

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-confirmation-simple',
  templateUrl: './confirmation-simple.component.html',
  styleUrls: ['./confirmation-simple.component.scss'],
})
export class ConfirmationSimpleComponent {
  @Input() inputTitle: string | undefined;
  @Input() inputMessage: string | undefined;
  @Input() inputValidateButtonLabel: string | undefined;
  @Input() inputCancelButtonLabel: string | undefined;
  @Input() inputHideValidateButton: boolean | undefined;

  @Input() selfClosing = true;
  @Output() validateEvent = new EventEmitter<boolean>();

  title: string | undefined;
  message: string | undefined;
  validateButtonLabel: string | undefined;
  cancelButtonLabel: string | undefined;
  hideValidateButton: boolean | undefined;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationSimpleComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      title: string;
      message: string;
      validateButtonLabel: string;
      hideValideButton: boolean;
      cancelButtonLabel: string;
    }
  ) {}
  ngOnInit(): void {
    this.title = this.inputTitle ?? this.data.title;
    this.message = this.inputMessage ?? this.data.message;
    this.validateButtonLabel = this.inputValidateButtonLabel ?? this.data.validateButtonLabel;
    this.cancelButtonLabel = this.inputCancelButtonLabel ?? this.data.cancelButtonLabel;
    this.hideValidateButton = this.inputHideValidateButton ?? this.data.hideValideButton;
  }
  cancel() {
    this.dialogRef.close();
  }

  validate() {
    this.validateEvent.emit(true);
    if (this.selfClosing) {
      this.dialogRef.close(true);
    }
  }
}

import { Component, Input } from '@angular/core';
import { checkConsultantHabilitation } from 'projects/box-lib/src/lib/utils/habilitation';
import { Operation, PresettedProduit } from 'projects/box-lib/src/public-api';

@Component({
  selector: 'lib-consultant-cell',
  templateUrl: './consultant-cell.component.html',
  styleUrls: ['./consultant-cell.component.scss'],
})
export class ConsultantCellComponent {
  @Input() operation: Operation;
  @Input() displayName: string | undefined | null;
  constructor() {}
  // displayColumnInfo(column: OperationDashboardColumn, operation: Operation) {
  //   return column.getDataFromOperation(operation, this.currencyPipe, this.datePipe);
  // }

  isOperationConsultantHabilited(operation: Operation): boolean {
    return checkConsultantHabilitation(
      operation.produitPreset?.habilitationCode,
      operation.consultantPreset?.habilitationCodes ?? []
    );
  }

  habilitationToolTipText(produitPreset: PresettedProduit | null): string {
    if (produitPreset?.habilitationLibelle) {
      return `Habilitation ${produitPreset.habilitationLibelle} inactive`;
    }
    return 'Habilitation inactive';
  }
}

<div class="param-layout">
  <h4>Produits</h4>

  <ng-container *ngIf="displayProduitDetails; else displayAllProducts">
    <div class="content flex-vertical gap-32">
      <div class="flex-auto">
        <oui-button type="icon" (click)="onBackArrowClick()"><oui-icon>arrow_back</oui-icon></oui-button>
        <div *ngIf="!selectedProduit">
          <oui-loading class="flex-vertical flex-center padding-vertical-16"></oui-loading>
        </div>
        <ng-container *ngIf="selectedProduit">
          <div class="flex-horizontal height-32">
            <h5 class="right-margin-32">
              {{
                selectedProduit.produitExtension?.libelle ??
                  selectedProduit.nomTechniqueProduitBox ??
                  selectedProduit.nom
              }}
            </h5>
            <oui-tag
              type="status"
              status="completed"
              size="big"
              *ngIf="selectedProduit?.produitExtension?.souscriptionPossible; else noSubs"
              >Affiché à la souscription</oui-tag
            >
            <ng-template #noSubs>
              <oui-tag type="status" status="pending" size="big">Masqué à la souscription</oui-tag>
            </ng-template>
          </div>
          <p class="no-vertical-margin">Nom Aramis : {{ selectedProduit.nom }}</p>
          <div class="flex-horizontal">
            <p class="right-margin-24">
              <span class="color-on-surface-medium">Partenaire : </span
              >{{ selectedProduit.idPartenaireNavigation?.nom }}
            </p>
            <p class="right-margin-24">
              <span class="color-on-surface-medium">Enveloppe : </span
              >{{ selectedProduit.enveloppeNavigation?.libelle }}
            </p>
          </div>
        </ng-container>
      </div>

      <div class="padding-24 oui-elevation-04 flex-auto flex-vertical gap-24 rounded">
        <div class="flex-horizontal">
          <span class="subtitle-1 flex-none">Documents associés requis (Connaissance client et Conseil)</span>
          <div class="flex-auto"></div>
        </div>

        <div class="min-height-200 with-border rounded">
          <div
            *ngIf="
              (selectedProduit?.enveloppeNavigation?.operationConfigs?.length ?? 0) <= 0;
              else operationConfigsNotEmpty
            "></div>
          <ng-template #operationConfigsNotEmpty>
            <div>
              <div
                *ngFor="let operationConfig of selectedProduit?.enveloppeNavigation?.operationConfigs"
                class="padding-24">
                <div class="flex-horizontal gap-24">
                  <div class="flex-vertical gap-8 flex-1">
                    <p class="subtitle-1 no-vertical-margin">
                      {{ operationConfig.natureOperation.libelle }}
                    </p>
                    <p class="color-on-surface-medium no-vertical-margin">
                      {{ selectedProduit?.habilitationNavigation?.code }}
                    </p>
                  </div>
                  <div class="flex-vertical gap-8 flex-1">
                    <p class="color-on-surface-medium no-vertical-margin">Connaissance Client</p>
                    <div *ngFor="let ccDoc of filterConnaissanceClientDoc(operationConfig)">
                      <oui-icon>description</oui-icon> {{ ccDoc.natureDocument.nom }}
                      <span *ngIf="ccDoc.applicableHabilitationCode" class="color-on-surface-medium">
                        ({{ ccDoc.applicableHabilitationCode }})</span
                      >
                    </div>
                  </div>
                  <div class="flex-vertical gap-8 flex-1">
                    <p class="color-on-surface-medium no-vertical-margin">Conseil</p>
                    <div *ngFor="let ccDoc of filterConseilDoc(operationConfig)">
                      <oui-icon>description</oui-icon> {{ ccDoc.natureDocument.nom }}
                      <span *ngIf="ccDoc.applicableHabilitationCode" class="color-on-surface-medium">
                        ({{ ccDoc.applicableHabilitationCode }})</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <!-- </ng-template> -->
  </ng-container>

  <ng-template #displayAllProducts>
    <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
      <!-- <div *ngIf="loadingAllProduits; else dataLoaded">
                <oui-loading class="flex-vertical flex-center" ></oui-loading>
                <div  class="flex-vertical flex-center">
                    Chargement des données
                </div>
            </div> -->
      <!-- <ng-template #dataLoaded> -->
      <oui-searchbar class="width-100pc" placeholder="Rechercher un produit" [control]="searchControl"></oui-searchbar>
      <app-products-table
        [receivedData]="requestResult"
        (requestNewPage)="getAllProduits()"
        [(paginationParams)]="paginationParams"
        (onSortClick)="onSortUpdate($event)">
      </app-products-table>
      <!-- </ng-template> -->
    </div>
  </ng-template>
</div>

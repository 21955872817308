import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export type GestionnaireCommentChange = {
  shouldUpdate: boolean;
  newComment?: string;
};

@Component({
  selector: 'app-edit-gestionnaire-comment-modal',
  templateUrl: './edit-gestionnaire-comment-modal.html',
  styleUrls: ['./edit-gestionnaire-comment-modal.scss'],
})
export class EditGestionnaireCommentModalComponent {
  comment: FormControl<string | null> = new FormControl<string>('', Validators.required);
  canValidate = false;

  constructor(
    private dialogRef: MatDialogRef<EditGestionnaireCommentModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      currentComment: string;
    }
  ) {
    this.comment.setValue(this.data.currentComment);
    this.comment.registerOnChange(() => (this.canValidate = true));
  }

  onCancel() {
    this.dialogRef.close({ shouldUpdate: false });
  }

  async onValidate() {
    this.dialogRef.close({ shouldUpdate: true, newComment: this.comment.value });
  }
}

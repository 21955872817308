import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OperationDetailsGestionnaireComponent } from 'src/app/components/operation-details-gestionnaire/operation-details-gestionnaire.component';
import { OperationsDeskComponent } from 'src/app/components/operations-desk/operations-desk.component';

import { ParamProductsComponent } from './components/parameters/param-products/param-products.component';
import { ProductsTableComponent } from './components/parameters/param-products/products-table/products-table.component';
import { ParametersComponent } from './components/parameters/parameters.component';

import { GraphQLModule } from 'src/app/graphql.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatDialogModule } from '@angular/material/dialog';

import { environment } from '../environments/environment';

// Omnium UI
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiButtonToggle } from 'omnium-ui/button-toggle';
import { OuiChipModule } from 'omnium-ui/chip';
import { OuiDialogModule } from 'omnium-ui/dialog';
import {
  OuiAutocomplete,
  OuiCheckbox,
  OuiDatePickerModule,
  OuiSelect,
  OuiTextarea,
  OuiTextfield,
} from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLayoutModule, OuiNavButtonModule, OuiNavigationPanelModule } from 'omnium-ui/layout';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiMenuModule } from 'omnium-ui/menu';
import { OuiNumber } from 'omnium-ui/number';
import { OuiPaginatorModule } from 'omnium-ui/paginator';
import { OuiSearchbar } from 'omnium-ui/searchbar';
import { OuiSelectFilter } from 'omnium-ui/select-filter';
import { OuiSlideToggle } from 'omnium-ui/slide-toggle';
import { OuiSnackbarModule } from 'omnium-ui/snackbar';
import { OuiStepper } from 'omnium-ui/stepper';
import { OuiTableModule } from 'omnium-ui/table';
import { OuiTabsModule } from 'omnium-ui/tabs';
import { OuiTagModule } from 'omnium-ui/tag';

import { OuiEtatVide } from 'omnium-ui/etat-vide';
import { OuiTooltipModule } from 'omnium-ui/tooltip';

import { BoxLibModule } from '@lib/box-lib.module';
import { BordereauStatutTagComponent } from 'src/app/components/bordereau-statut-tag/bordereau-statut-tag.component';
import { BordereauxDeskComponent } from 'src/app/components/bordereaux-desk/bordereaux-desk.component';
import { BordereauxOperationsDashboardComponent } from 'src/app/components/bordereaux-desk/bordereaux-operations-dashboard/bordereaux-operations-dashboard.component';
import { BordereauxSuiviComponent } from 'src/app/components/bordereaux-desk/bordereaux-suivi/bordereaux-suivi.component';
import { BordereauxTableComponent } from 'src/app/components/bordereaux-desk/bordereaux-table/bordereaux-table.component';
import { BordereauxDetailsComponent } from 'src/app/components/bordereaux-details/bordereaux-details.component';
import { BoxFileListComponent } from 'src/app/components/bordereaux-details/box-file-list/box-file-list.component';
import { ConfirmGestionnaireDeleteComponent } from 'src/app/components/parameters/param-utilisateurs/confirm-gestionnaire-delete/confirm-gestionnaire-delete.component';
import { ParamUtilisateursComponent } from 'src/app/components/parameters/param-utilisateurs/param-utilisateurs.component';
import { UtilisateursTableComponent } from 'src/app/components/parameters/param-utilisateurs/products-utilisateurs/utilisateurs-table.component';
import { AuthInterceptorInterceptor } from 'src/interceptors/auth-interceptor.interceptor';
import { ConfirmNoteDeleteComponent } from './components/operation-details-gestionnaire/operation-notes/confirm-note-delete/confirm-note-delete.component';
import { OperationNotesComponent } from './components/operation-details-gestionnaire/operation-notes/operation-notes.component';

import { OuiBanner } from 'omnium-ui/banner';
import { BordereauWarningIconComponent } from './components/bordereaux-desk/bordereaux-table/bordereau-warning-icon/bordereau-warning-icon.component';
import { OperationBoxFilesListComponent } from './components/operation-details-gestionnaire/operation-box-files-list/operation-box-files-list.component';
import { FondEvenementielModalComponent } from './components/parameters/param-fond-evenementiels/fond-evenementiel-modal/fond-evenementiel-modal.component';
import { FondEvenementielsTableComponent } from './components/parameters/param-fond-evenementiels/fond-evenementiels-table/fond-evenementiels-table.component';
import { ParamFondEvenementielsComponent } from './components/parameters/param-fond-evenementiels/param-fond-evenementiels.component';

import { ApplicationInsightsErrorHandler } from '@lib/utils/ApplicationInsightsErrorHandler';
import { EditGestionnaireCommentModalComponent } from 'src/app/components/operation-details-gestionnaire/edit-gestionnaire-comment-modal/edit-gestionnaire-comment-modal';
import { RefuseCancelOperationModalComponent } from 'src/app/components/operation-details-gestionnaire/refuse-cancel-operation-modal/refuse-cancel-operation-modal.component';
import { TypesDocsTableComponent } from 'src/app/components/parameters/param-doc-types/doc-types-table/doc-types-table.component';
import { ParamTypesDocComponent } from 'src/app/components/parameters/param-doc-types/param-doc-types.component';
import { ParamTransferOperationsComponent } from 'src/app/components/parameters/param-transfer-operations/param-transfer-operations.component';
import { SearchInvestisseurTransferAutocompleteComponent } from 'src/app/components/parameters/param-transfer-operations/search-investisseur-transfer-autocomplete/search-investisseur-transfer-autocomplete.component';
import { TransferOperationModalComponent } from 'src/app/components/parameters/param-transfer-operations/transfer-operation-modal/transfer-operation-modal.component';
import { BordereauxDashboardFiltersComponent } from './components/bordereaux-desk/bordereaux-suivi/bordereaux-dashboard-filters/bordereaux-dashboard-filters.component';

@NgModule({
  declarations: [
    AppComponent,
    OperationDetailsGestionnaireComponent,
    OperationsDeskComponent,
    ParametersComponent,
    ParamProductsComponent,
    ProductsTableComponent,
    UtilisateursTableComponent,
    ParamUtilisateursComponent,
    ConfirmGestionnaireDeleteComponent,
    OperationNotesComponent,
    ConfirmNoteDeleteComponent,
    BordereauxOperationsDashboardComponent,
    BordereauxDeskComponent,
    BordereauxTableComponent,
    BordereauxSuiviComponent,
    BordereauxDetailsComponent,
    BordereauStatutTagComponent,
    BoxFileListComponent,
    OperationBoxFilesListComponent,
    ParamFondEvenementielsComponent,
    FondEvenementielsTableComponent,
    FondEvenementielModalComponent,
    BordereauWarningIconComponent,
    RefuseCancelOperationModalComponent,
    BordereauxDashboardFiltersComponent,
    ParamTransferOperationsComponent,
    SearchInvestisseurTransferAutocompleteComponent,
    TransferOperationModalComponent,
    EditGestionnaireCommentModalComponent,
    ParamTypesDocComponent,
    TypesDocsTableComponent,
  ],
  imports: [
    OuiLayoutModule,
    OuiDatePickerModule,
    OuiIconModule,
    OuiButtonModule,
    OuiTabsModule,
    OuiTagModule,
    OuiTableModule,
    OuiPaginatorModule,
    OuiNavButtonModule,
    OuiNavigationPanelModule,
    OuiStepper,
    OuiSlideToggle,
    OuiSelect,
    OuiLoadingModule,
    OuiSelectFilter,
    OuiAccordionModule,
    OuiTextfield,
    OuiTextarea,
    OuiCheckbox,
    OuiAutocomplete,
    OuiButtonToggle,
    OuiMenuModule,
    OuiSearchbar,
    OuiChipModule,
    OuiNumber,
    OuiBanner,
    OuiEtatVide,
    OuiDialogModule,
    OuiSnackbarModule,
    OuiTooltipModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GraphQLModule,
    BoxLibModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthInterceptorInterceptor },
    { provide: 'environment', useValue: environment },
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler },
    CurrencyPipe,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

registerLocaleData(localeFr, 'fr-FR');

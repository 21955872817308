import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OuiStep } from 'omnium-ui/stepper';

export interface StepWithPath {
  step: OuiStep;
  path: string;
}

@Component({
  selector: 'app-nav-panel',
  templateUrl: './nav-panel.component.html',
  styleUrls: ['./nav-panel.component.scss'],
})
export class NavPanelComponent {
  // @ViewChildren('stepper') stepper?: OuiStepper;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  @Input()
  navItems: StepWithPath[];

  steps: OuiStep[] = [];

  ngOnInit() {
    this.steps = this.navItems.map(item => item.step);
  }

  onStepChange(step: OuiStep) {
    // sur selection d'un step on met à jour la route
    const item = this.navItems.find(item => item.step.title === step.title);
    if (item && !this.router.url.includes(item.path)) {
      this.router.navigate([item.path], { relativeTo: this.activatedRoute });
    }
  }
}

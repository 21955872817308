import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { gql } from '@apollo/client/core';

import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { Partenaire, PartenaireFilterInput } from '../../models/generated/graphql';
import { QueryManagerService } from '../../services/queryManagerService';
const allPartenairesQuery = gql`
  query allPartenaires($filter: PartenaireFilterInput) {
    allPartenaires(where: $filter) {
      id
      nom
    }
  }
`;

@Component({
  selector: 'app-search-partenaire-autocomplete',
  templateUrl: './search-partenaire-autocomplete.component.html',
  styleUrls: ['./search-partenaire-autocomplete.component.scss'],
})
export class SearchPartenaireAutocompleteComponent {
  @Input() currentPartenaireId: number | undefined;
  @Input() set inputPartenaireList(value: Partenaire[] | undefined) {
    if (value) {
      this.allPartenaires = value;
      this.setPartenairesOptions(this.allPartenaires);
    }
  }
  @Input() isRequired: boolean = false;
  @Input() isDisabled: boolean = true;

  @Output() onPartenaireSelected = new EventEmitter<number | null>();

  @Input() allPartenaires: Partenaire[];

  partenaireAutocompleteOptions: OuiAutocompleteOption[] = [];
  partenaireControl: FormControl;
  initPartenaireLibelle: string | undefined;

  isLoading: boolean = false;
  constructor(private queryManager: QueryManagerService) {}

  ngOnInit() {
    // init the control
    this.partenaireControl = new FormControl<number | undefined>(this.currentPartenaireId);

    // set control value
    this.partenaireControl.setValue(this.currentPartenaireId);
    this.partenaireControl.updateValueAndValidity();
    // set control validators
    if (this.isRequired) {
      this.partenaireControl.setValidators(Validators.required);
    }

    this.fetchAllPartenaires();

    //subscribe to control
    this.partenaireControl.valueChanges.pipe(debounceTime(50), distinctUntilChanged()).subscribe(newPartenaireId => {
      if (newPartenaireId && Number.isInteger(newPartenaireId)) {
        if (this.allPartenaires) {
          const newPartenaire = this.allPartenaires.find(partenaire => partenaire.id === newPartenaireId);
          if (newPartenaire) {
            this.onPartenaireSelected.emit(newPartenaireId);
          }
        }
      } else {
        this.onPartenaireSelected.emit(null);
        if (this.allPartenaires && newPartenaireId) {
          const possiblePartenaires = this.allPartenaires.filter(partenaire =>
            partenaire.nom?.toUpperCase().includes(newPartenaireId.toString().toUpperCase())
          );
          this.setPartenairesOptions(possiblePartenaires);
        }
      }
    });
  }

  async fetchAllPartenaires() {
    if (this.inputPartenaireList) {
      this.allPartenaires = this.inputPartenaireList;
    } else {
      this.isLoading = true;
      const activiteFilter = { activiteRattachement: { eq: 0 } }; //  activite investissement only
      let searchPartenaireFilter = this.partenaireControl.value ? { id: { eq: this.partenaireControl.value } } : {};
      let filter: PartenaireFilterInput = { and: [activiteFilter, searchPartenaireFilter] };
      let result = await firstValueFrom(
        this.queryManager.query<{ allPartenaires: Partenaire[] }>({
          query: allPartenairesQuery,
          variables: {
            filter: filter,
          },
        })
      );
      this.isLoading = false;
      this.allPartenaires = result.data.allPartenaires;
    }

    this.setPartenairesOptions(this.allPartenaires);
  }

  setPartenairesOptions(partenaires: Partenaire[]) {
    this.partenaireAutocompleteOptions = partenaires
      .map(partenaire => {
        return { label: partenaire.nom ?? '', value: partenaire.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}

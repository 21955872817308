import { Component, Input } from '@angular/core';
import { OuiTagStatus } from 'omnium-ui/tag';
import { CreationPersonneMorale as CreationPMItem, CreationPersonneMoraleStatut } from 'projects/box-lib/src/public-api';

@Component({
  selector: 'lib-creation-personne-morale-statut-cell',
  templateUrl: './creation-personne-morale-statut-cell.component.html',
  styleUrls: ['./creation-personne-morale-statut-cell.component.scss'],
})
export class CreationPersonneMoraleStatutCellComponent {
  statusText: string = '';
  statusColor: OuiTagStatus = 'in-progress';

  private _creation: CreationPMItem;
  public get creation(): CreationPMItem {
    return this._creation;
  }

  @Input({
    required: true,
  })
  public set creation(value: CreationPMItem) {
    this._creation = value;
    this.statusText = this.getStatusText(value);
    this.statusColor = this.getStatusColor(value);
  }

  getStatusText(creation: CreationPMItem): string {
    if (creation.statut === CreationPersonneMoraleStatut.Nouveaute) {
      return 'Crée';
    }
    if (creation.statut === CreationPersonneMoraleStatut.DemandeEnCours) {
      return 'Creation en cours';
    }
    return '-';
  }

  getStatusColor(creation: CreationPMItem): OuiTagStatus {
    if (creation.statut === CreationPersonneMoraleStatut.Nouveaute) {
      return 'completed';
    }
    if (creation.statut === CreationPersonneMoraleStatut.DemandeEnCours) {
      return 'transition';
    }
    return 'error';
  }
}

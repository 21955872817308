<div class="history-record" *ngFor="let record of records">
  <div class="record-header">
    <oui-icon class="record-icon">{{ record.icon }}</oui-icon>
    <div class="record-title body-2">
      <span class="subtitle-2">{{ record.actionText }}</span>
      {{ record.header }}
    </div>
  </div>
  <div class="record-body">
    <div class="timeline-container">
      <div class="timeline"></div>
    </div>
    <div class="record-content">
      <a class="record-file" *ngIf="record.fileMetadata?.fileId" (click)="onFileLinkClick(record.fileMetadata)"
        >{{ record.fileMetadata?.fileName }}</a
      >
      <a class="record-file" *ngIf="record.bordereauId" (click)="onBordereauLink(record.bordereauId)"
        >voir le bordereau n°{{ record.bordereauId }}</a
      >
      <div class="body-2" *ngFor="let detail of record.details">{{ detail }}</div>
    </div>
  </div>
</div>

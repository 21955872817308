import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AbstractDashboardFilterComponent,
  FilterChips,
} from '@lib/components/dashboard-filters/abstract-dashboard-filter.component';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import { EnvoiPartenaireFilterInput, EnvoiPartenaireStatut, Partenaire } from '@lib/models/generated/graphql';
import { DashboardFiltersService } from '@lib/services/dashboard-filters.service';
import { OptionsService } from '@lib/services/options.service';
import { UserParametersService } from '@lib/services/user-parameters.service';
import { OuiSelectOption } from 'omnium-ui/form-field';

@Component({
  selector: 'app-bordereaux-dashboard-filters',
  templateUrl: './bordereaux-dashboard-filters.component.html',
  styleUrls: ['./bordereaux-dashboard-filters.component.scss'],
})
export class BordereauxDashboardFiltersComponent extends AbstractDashboardFilterComponent<EnvoiPartenaireFilterInput> {
  bordereauStatutControl = new FormControl<EnvoiPartenaireStatut[]>([]);
  bordereauStatutOptions: OuiSelectOption<EnvoiPartenaireStatut>[] = [];

  dateEnvoiControl = new FormControl<Date | null>(null);

  partenaireControl = new FormControl<number[]>([]);
  partenaireOptions: OuiSelectOption<number>[] = [];

  datePipe = new DatePipe('fr-FR');

  constructor(
    optionsService: OptionsService,
    userParametersService: UserParametersService,
    protected dashboardFiltersService: DashboardFiltersService
  ) {
    super(optionsService, userParametersService);
  }

  override buildNewFilter(): EnvoiPartenaireFilterInput {
    const requestFilter = { and: [{}] };
    this.filterStateMap.forEach(filter => {
      if (filter.chips.length > 0) {
        requestFilter.and?.push({ or: filter.chips.map(chip => chip.value.filter) });
      }
    });
    return requestFilter;
  }
  override async getAllDataForFilters(): Promise<void> {
    if (this.isFilterActive(DashboardFilterEnum.Partenaire)) {
      await this.getAllPartenaires();
    }
    if (this.isFilterActive(DashboardFilterEnum.BordereauStatut)) {
      this.getAllBordereauStatutOptions();
    }
  }
  override subscribeToFilterChanges(): void {
    this.filterStateMap.set(DashboardFilterEnum.Investisseur, {
      chips: [],
      filterType: DashboardFilterEnum.Investisseur,
    });

    this.initSelectFilter(
      this.bordereauStatutControl,
      this.bordereauStatutOptions,
      DashboardFilterEnum.BordereauStatut,
      value => ({ statutEnvoi: { eq: value } })
    );
    this.initSelectFilter(this.partenaireControl, this.partenaireOptions, DashboardFilterEnum.Partenaire, value => ({
      partenaireId: { eq: value },
    }));
    this.initFilter(
      this.dateEnvoiControl,
      DashboardFilterEnum.BordereauDateEnvoi,
      value => this.createDateChips(value),
      chips => {
        if (!chips || chips.length === 0) {
          return undefined;
        }
        return chips[0].value.dataValue;
      }
    );
  }
  override resetControlValues(): void {
    this.bordereauStatutControl.setValue([]);
    this.partenaireControl.setValue([]);
    this.dateEnvoiControl.setValue(null);
  }

  override onRemove(chip: FilterChips<EnvoiPartenaireFilterInput>): void {
    this.removeChipFromFilter(chip);
    switch (chip.value.type) {
      case DashboardFilterEnum.Partenaire:
        this.updateControlValue(this.partenaireControl, chip.value.filter.partenaireId?.eq);
        break;
      case DashboardFilterEnum.BordereauStatut:
        this.updateControlValue(this.bordereauStatutControl, chip.value.filter.statutEnvoi?.eq);
        break;
      case DashboardFilterEnum.BordereauDateEnvoi:
        this.dateEnvoiControl.setValue(null);
        this.dateEnvoiControl.updateValueAndValidity();
        break;
    }
    this.emitNewFilter();
  }

  private createDateChips(date: Date | null): FilterChips<{ sendingDate: { eq: Date } }>[] {
    if (!date) {
      return [];
    }
    const dateValue = this.datePipe.transform(date, 'dd-MM-YYYY')!;
    const chip: FilterChips<{ sendingDate: { eq: Date } }> = {
      value: {
        type: DashboardFilterEnum.BordereauDateEnvoi,
        filter: { sendingDate: { eq: date } },
        dataValue: date,
      },
      label: `Envoyé le ${dateValue}`,
    };
    return [chip];
  }

  getAllBordereauStatutOptions() {
    this.bordereauStatutOptions = this.optionsService.getEnvoiPartenaireStatutOptions();
  }
  async getAllPartenaires() {
    const allPartenaires = await this.dashboardFiltersService.getAllPartenaires();
    this.setPartenairesOptions(allPartenaires);
  }

  setPartenairesOptions(partenaires: Partenaire[]) {
    this.partenaireOptions = this.createOptions(
      partenaires,
      partenaire => partenaire.nom ?? '',
      partenaire => partenaire.id
    );
  }
}

<div class="param-layout">
  <h4>Transfert d'opérations</h4>

  <ng-container>
    <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
      <div class="flex-vertical gap-24">
        <div>Sélectionnez l'investisseur source</div>
        <app-search-investisseur-transfer-autocomplete
          (onInvestisseurIdSelected)="onSourceInvestisseurIdSelected($event)"
          (investisseurData)="
            onSourceInvestisseurData($event ?? undefined)
          "></app-search-investisseur-transfer-autocomplete>
      </div>
      <div *ngIf="selectedSourceInvestisseurId" class="flex-vertical gap-24">
        <div>Sélectionnez l'investisseur cible</div>
        <app-search-investisseur-transfer-autocomplete
          (onInvestisseurIdSelected)="onTargetInvestisseurIdSelected($event)"
          (investisseurData)="onTargetInvestisseurData($event ?? undefined)"
          [isTarget]="true"
          [consultantIdFilter]="sourceConsultantIds"
          [excludedInvestisseurIds]="[selectedSourceInvestisseurId]"></app-search-investisseur-transfer-autocomplete>
      </div>
      <oui-button
        *ngIf="selectedSourceInvestisseurId"
        [disabled]="operationNumber === 0 || !selectedTargetInvestisseurId"
        type="contained"
        (click)="onSubmitTransfer()"
        >Transférer les {{ operationNumber }} opération{{ operationNumber > 1 ? 's' : '' }}</oui-button
      >
    </div>
  </ng-container>
</div>

<div class="tabContainer">
  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-16">
    <lib-operation-dashboard-filters
      (onFilterChanged)="onFilterChanged($event)"
      [dashboardKey]="dashboardKey"
      [filterSelection]="filterSelection"
      [defaultQueryFilter]="defaultQueryFilter"
      [preSelectedGestionnaireId]="preSelectedGestionnaireId"></lib-operation-dashboard-filters>
    <div class="flex-vertical gap-16 width-100pc">
      <app-operations-table
        [fetchOnInit]="false"
        [receivedData]="requestResult"
        (requestNewPage)="getAllOperations()"
        [(paginationParams)]="paginationParams"
        [columnSelection]="columnSelection"
        (onOperationRowClick)="onRowClick($event)"
        [withSelects]="withSelects"
        [withCSVExport]="withCSVExport"
        (onSelectionActionClicked)="onDispatchedOperations($event)"
        (onDeleteDraftClicked)="onDeleteDraftClick($event)"
        [selectionActionLabel]="'Affecter un gestionnaire'"
        (onSortCriteriaUpdate)="onTableSortCriteriaChange($event)"
        (onExportClick)="onExportClick()">
      </app-operations-table>
    </div>
  </div>
</div>

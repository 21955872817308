import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { CreateNonConformiteFichierInput, NonConformiteFichier } from '../../../models/generated/graphql';
import { OptionsService } from '../../../services/options.service';

@Component({
  selector: 'app-operation-file-non-conform-form',
  templateUrl: './operation-file-non-conform-form.component.html',
  styleUrls: ['./operation-file-non-conform-form.component.scss'],
})
export class OperationFileNonConformFormComponent {
  @Output()
  onNonConformiteAdd = new EventEmitter<CreateNonConformiteFichierInput>();

  @Output()
  onNonConformiteEdit = new EventEmitter<NonConformiteFichier>();

  @Output()
  onInstanceSetIsInternal = new EventEmitter<boolean>();

  @Input()
  editNC: NonConformiteFichier | undefined;

  @Input()
  isInstanceMode: boolean | undefined | null;

  categoriesList: OuiSelectOption[];

  motifsFullList: OuiSelectOption[];

  motifsList: OuiSelectOption[] = [];

  categoryControl = new FormControl<string>('', [Validators.required]);
  motifControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  commentaireControl = new FormControl<string | undefined>(undefined, [Validators.required]);

  constructor(private optionService: OptionsService) {}
  ngOnInit() {
    this.categoriesList = this.optionService.getNonConformiteCategories();
    this.motifsFullList = this.optionService.getNonConformiteMotifs();

    if (this.editNC) {
      this.isInstanceMode = this.isInstanceMode || this.editNC.isInstancePartenaire;
      this.motifsList = this.motifsFullList.filter(op => op.data === this.editNC?.categorie);
      this.categoryControl.setValue(this.editNC.categorie);
      this.motifControl.setValue(this.editNC.motif);
      this.commentaireControl.setValue(this.editNC.commentaire);
    }
    this.categoryControl.valueChanges.subscribe(value => this.onCategoryChange(value));
  }

  onCategoryChange(categorie: string | null) {
    if (categorie) {
      this.motifsList = this.motifsFullList.filter(op => op.data === categorie);
      this.motifControl.setValue(this.motifsList[0].value);
    }
  }

  isValid(): boolean {
    return this.categoryControl.valid && this.motifControl.valid && this.commentaireControl.valid;
  }

  formReset() {
    this.categoryControl.reset();
    this.commentaireControl.reset();
    this.motifControl.reset();
  }

  onAdd() {
    let nonCompliance: CreateNonConformiteFichierInput = {
      categorie: this.categoryControl.value!,
      motif: this.motifControl.value!,
      commentaire: this.commentaireControl.value!,
      fichierOperationId: -1,
    };
    this.onNonConformiteAdd.emit(nonCompliance);
    this.formReset();
  }

  onCancel() {
    this.onNonConformiteAdd.emit();
    this.formReset();
  }

  onEdit() {
    if (this.editNC) {
      this.editNC.categorie = this.categoryControl.value!;
      this.editNC.motif = this.motifControl.value!;
      this.editNC.commentaire = this.commentaireControl.value!;
    }
    this.onNonConformiteEdit.emit(this.editNC);
  }

  onSetInternalChange(isInternal: boolean) {
    if (this.editNC) {
      this.editNC.isInternal = isInternal;
    }
    this.onInstanceSetIsInternal.emit(isInternal);
  }
}

import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import { fondEvenementielFragment } from '@lib/models/graphqlFragments';
import { FondEvenementielsPaginatedCollectionSegment } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { FondEvenementielModalComponent } from './fond-evenementiel-modal/fond-evenementiel-modal.component';

const PAGEALLACTIVEFONDS = gql`
  query allFondEvenementielsPaginated($skip: Int, $take: Int, $filter: String) {
    allFondEvenementielsPaginated(
      skip: $skip
      take: $take
      where: { and: [{ isActive: { eq: true } }, { libelle: { contains: $filter } }] }
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...fondEvenementielSimple
      }
      totalCount
    }
  }
  ${fondEvenementielFragment}
`;

@Component({
  selector: 'app-param-fond-evenementiels',
  templateUrl: './param-fond-evenementiels.component.html',
  styleUrls: ['./param-fond-evenementiels.component.scss'],
})
export class ParamFondEvenementielsComponent {
  requestResult: { data: FondEvenementielsPaginatedCollectionSegment; loading: boolean } | undefined;
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  searchControl = new FormControl<any>('');

  searchSub: Subscription | undefined;

  constructor(
    private queryManager: QueryManagerService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.searchSub = this.searchControl.valueChanges
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(search => {
        this.paginationParams = DEFAULT_PAGINATION_PARAMS;
        this.getAllFondEvenementiels();
      });
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }
  getAllFondEvenementiels() {
    const requestVariables = { ...this.paginationParams, filter: this.searchControl.value };

    this.queryManager
      .query<{ allFondEvenementielsPaginated: FondEvenementielsPaginatedCollectionSegment }>({
        query: PAGEALLACTIVEFONDS,
        variables: requestVariables,
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allFondEvenementielsPaginated };
      });
  }
  addFondEvenementiel() {
    const modalRef = this.dialog.open(FondEvenementielModalComponent, {
      width: '660px',
      height: 'auto',
      data: {
        fondEvenementiel: undefined,
        title: 'Ajouter un fond evenementiel',
        validateButtonLabel: 'Ajouter',
      },
    });
    modalRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.getAllFondEvenementiels();
      }
    });
  }
}

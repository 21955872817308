import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { UserParametersService } from 'projects/box-lib/src/lib/services/user-parameters.service';
import { distinctUntilChanged } from 'rxjs';
import { DashboardFilterEnum } from '../../models/dashboardFilterEnum';
import { OptionsService } from '../../services/options.service';

export type FilterChipsValue<T> = {
  type: DashboardFilterEnum;
  filter: T;
  dataValue: any;
};

export type FilterChips<T> = {
  label: string;
  value: FilterChipsValue<T>;
};

export type SelectFilterState<T> = FilterState<T> & {
  options: OuiSelectOption<any>[];
  createFilter: (value: any) => T;
};
export type FilterState<T> = {
  chips: FilterChips<T>[];
  control?: FormControl;
  filterType: DashboardFilterEnum;
  key?: string;
};
@Component({
  template: '',
})
export abstract class AbstractDashboardFilterComponent<T> {
  @Output() onFilterChanged = new EventEmitter<T>();

  @Input({ required: true }) filterSelection: DashboardFilterEnum[] = [];

  filterStateMap = new Map<DashboardFilterEnum, FilterState<T>>();
  operationDashboardFilterEnum = DashboardFilterEnum;
  get showClearFiltersButton(): boolean {
    this.filterStateMap.entries();
    for (const filter of this.filterStateMap.values()) {
      if (filter.chips.length > 0) {
        return true;
      }
    }
    return false;
  }

  constructor(protected optionsService: OptionsService, protected userParametersService: UserParametersService) {}

  ngOnInit(): void {
    this.asyncInit();
  }

  async asyncInit(): Promise<void> {
    await this.getAllDataForFilters();
    this.subscribeToFilterChanges();
    this.onFilterChanged.emit(this.buildNewFilter());
  }

  isFilterActive(filter: DashboardFilterEnum) {
    return this.filterSelection.includes(filter);
  }

  removeChipFromFilter(chip: FilterChips<T>) {
    const filterState = this.filterStateMap.get(chip.value.type);
    if (filterState) {
      const index = filterState.chips.findIndex(
        (c: FilterChips<T>) => c.label === chip.label && c.value.type === chip.value.type
      );
      if (index >= 0) {
        filterState.chips.splice(index, 1);
      }
      this.filterStateMap.set(chip.value.type, filterState);
    }
  }
  createSelectChips(
    values: any,
    options: OuiSelectOption<any>[],
    createFilter: (value: any) => T,
    filterType: DashboardFilterEnum
  ) {
    if (values) {
      const chips: FilterChips<T>[] = values.map((value: T) => ({
        label: this.optionsService.getLabelfromOptionValue(value, options),
        value: { type: filterType, filter: createFilter(value), dataValue: value },
      }));
      return chips;
    }
    return [];
  }

  initSelectFilter(
    control: FormControl,
    options: OuiSelectOption<any>[],
    filterType: DashboardFilterEnum,
    createFilter: (value: any) => T,
    key?: string,
    defaultOptionIds?: number[]
  ) {
    // set default value for chips
    let initialValues: any = undefined;
    if (defaultOptionIds) {
      const selectedOptions: OuiSelectOption<any>[] = options.filter(option => defaultOptionIds.includes(option.value));
      if (selectedOptions?.length) {
        initialValues = selectedOptions.map(option => option.value);
      }
    }

    const creatChips: (values: any) => FilterChips<T>[] = values => {
      return this.createSelectChips(values, options, createFilter, filterType);
    };
    const getControlValueFromChips: (chips: FilterChips<T>[]) => any = chips => {
      return chips.map(chip => chip.value.dataValue);
    };

    this.initFilter(control, filterType, creatChips, getControlValueFromChips, key, initialValues);
  }

  initFilter(
    control: FormControl,
    filterType: DashboardFilterEnum,
    createChips: (value: any) => FilterChips<T>[],
    getControlValueFromChips: (chips: FilterChips<T>[]) => any,
    key?: string,
    defaultValue?: any
  ) {
    const filterState: FilterState<T> = {
      chips: [],
      control,
      filterType,
    };

    // set default value for chips
    let initialChips: FilterChips<T>[] = [];
    if (defaultValue) {
      initialChips = createChips(defaultValue);
    }

    if (key) {
      const savedValues = this.userParametersService.getUserParam(key, filterType.toString());
      if (savedValues) {
        // if there is saved values, we load them and overwrite the default values
        initialChips = createChips(savedValues);
      }
    }
    if (initialChips.length) {
      filterState.chips = initialChips;
      const value = getControlValueFromChips(initialChips);
      filterState.control?.setValue(value);
    }

    filterState.chips = initialChips;

    control.valueChanges.pipe(distinctUntilChanged()).subscribe(values => {
      if (values) {
        const chips: FilterChips<T>[] = createChips(values);
        filterState.chips = chips;
        if (key) {
          // if there is a key, we save the values in local storage to have persistent config
          this.userParametersService.saveUserParam(key, filterType.toString(), values);
        }
        this.emitNewFilter();
      }
    });

    this.filterStateMap.set(filterType, filterState);
  }

  onclearAllFilter() {
    this.filterStateMap = new Map<DashboardFilterEnum, SelectFilterState<T>>();
    this.resetControlValues();
  }

  toggleControlState(control: FormControl, options: OuiSelectOption<any>[]) {
    options.length > 0 ? control.enable() : control.disable();
  }

  createOptions<T>(items: T[], getLabel: (item: T) => string, getValue: (item: T) => any): OuiSelectOption<any>[] {
    return items
      .map(item => ({ label: getLabel(item), value: getValue(item) }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  emitNewFilter(): void {
    this.onFilterChanged.emit(this.buildNewFilter());
  }

  updateControlValue(control: FormControl, value: any) {
    control.setValue(control.value.filter((v: any) => v !== value));
  }

  abstract buildNewFilter(): T;

  abstract getAllDataForFilters(): void;

  abstract subscribeToFilterChanges(): void;

  abstract resetControlValues(): void;

  abstract onRemove(chip: FilterChips<T>): void;
}

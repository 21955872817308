export const environment = {
  authConfig: {
    loginUrl: 'https://auth-int.prodemial.fr/cas/oauth2.0/authorize',
    tokenUrl: 'https://auth-int.prodemial.fr/cas/oauth2.0/token',
    logoutUrl: 'https://auth-int.prodemial.fr/cas/logout?service=' + encodeURI(window.location.origin),
    clientId: 'dj4knr49up.stellium',
    authGroup: 'APP_MYAPP',
    consultantGroups: ['CN=GOF_CONSULTANT'],
    backOfficeGroups: [
      'CN=APP_PLACEMENT_BACKOFFICE_COURTAGE_RECETTE',
      'CN=APP_PLACEMENT_BACKOFFICE_INVEST_RECETTE',
      'CN=APP_PLACEMENT_BACKOFFICE_RECETTE',
    ],
    backOfficeAdminGroups: ['CN=APP_PLACEMENT_BACKOFFICE_ADMIN_RECETTE'],
  },
  backEndUrl: 'https://api-box-hp-int.azurewebsites.net/graphql',
  appInsightsKey: 'fac7b58d-db08-4e0b-ae45-49a5a9d242e4',
};

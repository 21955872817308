import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiTextarea } from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';

export type SendNonConfirmiteDialogResult = {
  commentaire?: string;
};

@Component({
  selector: 'lib-send-non-conformite-dialog',
  standalone: true,
  imports: [CommonModule, OuiDialogModule, OuiIconModule, OuiButtonModule, OuiTextarea],
  templateUrl: './send-non-conformite-dialog.component.html',
  styleUrls: ['./send-non-conformite-dialog.component.scss'],
})
export class SendNonConformiteDialogComponent {
  title: string;

  result: SendNonConfirmiteDialogResult = {};

  constructor(@Inject(MAT_DIALOG_DATA) data: { isInstanceMode: boolean; currentComment?: string }) {
    this.result.commentaire = data.currentComment;
    this.title = data.isInstanceMode ? 'Envoyer les instances' : 'Envoyer les non-conformités';
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiMenuModule } from 'omnium-ui/menu';
import { MenuNode } from 'omnium-ui/shared';

@Component({
  selector: 'lib-document-viewer-header',
  standalone: true,
  imports: [CommonModule, OuiButtonModule, OuiIconModule, OuiMenuModule],
  templateUrl: './document-viewer-header.component.html',
  styleUrls: ['./document-viewer-header.component.scss'],
})
export class DocumentViewerHeaderComponent {
  @Input({ required: true })
  documentName: string;

  @Input()
  fileName?: string;

  @Input()
  dateAjout?: Date;

  @Input()
  menu?: MenuNode[];

  @Input()
  showCloseButton: boolean = true;

  @Output()
  close = new EventEmitter();
}

<ng-container *ngIf="isLoadingOperation; else operationDetails">
  <div class="spinner">
    <oui-loading class="align-self-center"></oui-loading>
  </div>
</ng-container>
<ng-template #operationDetails>
  <div *ngIf="!displayDocumentControl" class="page-body flex-horizontal gap-12">
    <app-operations-panel-info
      [operation]="operation"
      (onCancelClick)="onCancelOperation()"
      (onRejectClick)="onRejectOperation()"
      (onSetInstancePartenaireClick)="onSetInstancePartenaire()"
      (onMailReceptionClick)="onMailReception()"
      (operationChange)="onOperationChange()"
      (onGestionnaireAssigned)="onGestionnaireAssigned($event)"
      (onEditGestionnaireCommentClick)="openGestionnaireCommentEditor()"
      (onBackArrowClicked)="onBackArrowClicked()">
    </app-operations-panel-info>

    <div class="page-content flex-vertical flex-center gap-32 padding-72 width-100pc content">
      <ng-container *ngIf="operation">
        <oui-banner
          *ngIf="operation.messageTechnique"
          declination="alert"
          [title]="operation.messageTechnique"
          [showCloseButton]="false"
          [action]="bannerTechniqueAcion"></oui-banner>

        <oui-banner
          *ngIf="showBannerConforme"
          title="Tous les documents sont conformes, vous pouvez transmettre l'opération au partenaire"
          declination="alert"
          [action]="bannerConformeAcion"
          [showCloseButton]="false">
        </oui-banner>

        <oui-banner
          *ngIf="needToSendNC()"
          title="Les non-conformités n'ont pas été envoyées au consultant"
          declination="error"
          [action]="bannerNCAcion"></oui-banner>
        <oui-banner
          *ngIf="operation?.commentaireGestionnaire"
          title="Commentaire gestionnaire"
          declination="alert"
          [action]="bannerGestionnaireCommentActions"
          type="complex"
          [description]="operation.commentaireGestionnaire ?? ''"
          class="no-shrink"></oui-banner>
        <oui-tab-group class="tabs width-100pc">
          <oui-tab label="Contrôle des documents">
            <div class="flex-vertical">
              <div class="flex-vertical padding-vertical-32 padding-lateral-16">
                <div class="flex-horizontal gap-8">
                  <oui-checkbox
                    [label]="getControlFilesLabel()"
                    class="align-self-center"
                    (checkedChange)="toggleAllRows()"
                    [checked]="hasSelected() && isAllSelected()"
                    [indeterminate]="hasSelected() && !isAllSelected()">
                  </oui-checkbox>
                  <oui-button
                    type="text"
                    iconPrefix="download"
                    (click)="onDownloadSelectedFiles()"
                    [disabled]="!hasSelected()">
                    Télécharger</oui-button
                  >
                  <div class="flex-1"></div>
                </div>
              </div>
              <app-files-list-status
                [files]="operation.fichierOperations"
                (fileClicked)="onfileClick($event)"
                mode="gestionnaire"
                (onValidateAllFiles)="onValidateAllToControlFiles()"></app-files-list-status>
            </div>
          </oui-tab>
          <oui-tab label="Notes">
            <app-operation-notes [operation]="operation"></app-operation-notes>
          </oui-tab>
          <oui-tab label="Pièces internes"
            ><app-operation-box-files-list
              [files]="operation.attachedFiles"
              [operationId]="operation.id"
              (onAddedFile)="onAddAttachedFile()"></app-operation-box-files-list>
          </oui-tab>
        </oui-tab-group>
      </ng-container>
    </div>
  </div>
  <app-control-file-document-viewer
    *ngIf="displayDocumentControl && selectedFile"
    [files]="operation.fichierOperations"
    [selectedFile]="selectedFile"
    [operationStatut]="operation.statut"
    [operationTriggers]="operation.activeOperationStateTransitionTriggers"
    [operation]="operation"
    [currentOperationGestionnaireComment]="operation.commentaireGestionnaire ?? undefined"
    [isConsultantOutDated]="isConsultantOutDated"
    (close)="closeControlFileViewer()">
  </app-control-file-document-viewer>
</ng-template>

import { Component, Input } from '@angular/core';
import { Operation } from 'projects/box-lib/src/lib/models/generated/graphql';

@Component({
  selector: 'lib-linked-operations-cell',
  templateUrl: './linked-operations-cell.component.html',
  styleUrls: ['./linked-operations-cell.component.scss'],
})
export class LinkedOperationsCellComponent {
  @Input() operation: Operation;
  isLinked: boolean;
  ngOnInit() {
    this.isLinked = this.operation?.operationGroup?.operations.length > 1;
  }
}

import { OperationFilterInput } from '@lib/models/generated/graphql';

// desk tabs
const ALL_OPERATIONS_TAB_INDEX = 0;
const WAITING_DOCUMENTS_TAB_INDEX = 1;
const CREATION_PM_TAB_INDEX = 2;
const TO_DISPATCH_TAB_INDEX = 3;
const TO_CONTROL_TAB_INDEX = 4;
const WAITING_FOR_REPLY_TAB_INDEX = 5;
const HISTORY_TAB_INDEX = 6;

// The OperationFilterInput and the boolean Check for status share the same logic
// If you change one, change the other too

// Filters
export const ALL_BACKOFFICE_FILTER: OperationFilterInput = {
  statutId: { gt: 5 },
};
export const WAITING_DOCUMENTS_FILTER: OperationFilterInput = {
  statutId: { eq: 100 },
};
export const TO_DISPATCH_FILTER: OperationFilterInput = {
  statutId: { eq: 105 },
};
export const TO_CONTROL_FILTER: OperationFilterInput = {
  or: [
    {
      and: [{ statutId: { gte: 200 } }, { statutId: { lte: 250 } }],
    },
    {
      and: [{ statutId: { gte: 310 } }, { statutId: { lt: 400 } }],
    },
  ],
};
export const WAITING_FOR_REPLY_FILTER: OperationFilterInput = {
  or: [
    {
      and: [{ statutId: { gte: 50 } }, { statutId: { lt: 60 } }],
    },
    {
      and: [{ statutId: { gt: 250 } }, { statutId: { lt: 310 } }],
    },
  ],
};
export const HISTORY_FILTER: OperationFilterInput = { statutId: { gte: 400 } };

//bool checks
const isWaitingDocumentsStatus = (statusId: number): boolean => {
  return statusId === 100;
};
const isToDispatchStatus = (statusId: number): boolean => {
  return statusId === 105;
};
const isToControlStatus = (statusId: number): boolean => {
  return (statusId >= 200 && statusId <= 250) || (statusId >= 310 && statusId <= 400);
};
const isWaitingForReplyStatus = (statusId: number): boolean => {
  return (statusId >= 50 && statusId < 60) || (statusId > 250 && statusId < 310);
};
const isHistoryStatus = (statusId: number): boolean => {
  return statusId >= 400;
};

export const getOperationDeskTabIndex = (statusId: number): number => {
  if (isWaitingDocumentsStatus(statusId)) {
    return WAITING_DOCUMENTS_TAB_INDEX;
  }
  if (isToDispatchStatus(statusId)) {
    return TO_DISPATCH_TAB_INDEX;
  }
  if (isToControlStatus(statusId)) {
    return TO_CONTROL_TAB_INDEX;
  }
  if (isWaitingForReplyStatus(statusId)) {
    return WAITING_FOR_REPLY_TAB_INDEX;
  }
  if (isHistoryStatus(statusId)) {
    return HISTORY_TAB_INDEX;
  }
  return TO_CONTROL_TAB_INDEX;
};

<div oui-dialog-title>
  Modifier une opération
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content class="dialog-content">
  <div class="form-container" [ngSwitch]="data.champ">
    <oui-textfield
      *ngSwitchCase="'montant'"
      label="Montant"
      type="number"
      [control]="control"
      iconSuffix="euro"></oui-textfield>

    <oui-select
      *ngSwitchCase="'typeSignaturePartenaire'"
      label="Comment ont été signés les documents du partenaire ? (hors documents Stellium)"
      [control]="control"
      [options]="signatureOptions"></oui-select>

    <oui-select
      *ngSwitchCase="'fondsEvenementiels'"
      label="Fonds événementiel"
      [control]="control"
      [options]="fondsEvenementielsOptions"
      [multiple]="true"></oui-select>

    <div *ngSwitchCase="'transaction-personnelle'">
      <app-transaction-personnelle
        [habilitationIds]="habiliationSet"
        [initValue]="currentValue"
        (onTransactionPersonnelleChange)="updateTransactionPersonelleStatut($event)"></app-transaction-personnelle>
    </div>

    <oui-select
      *ngSwitchCase="'modePaiement'"
      label="Mode de paiement"
      [control]="control"
      [options]="modePaiementOptions"
      [multiple]="true"></oui-select>

    <oui-select
      *ngSwitchCase="'objetModification'"
      label="Objet de la modification"
      [control]="control"
      [options]="objetModificationOptions"
      [multiple]="false"></oui-select>
  </div>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="saveOperation()" [disabled]="!control.valid">Enregistrer</oui-button>
</oui-dialog-actions>

<div oui-dialog-title>
  Créer un nouveau type de document
  <oui-button type="icon" [oui-dialog-close]>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <oui-textfield label="Libellé du type" [(value)]="key" class="width-100pc"></oui-textfield>
    <oui-autocomplete
      label="Type GED associé"
      [control]="gedDocumentTypeControl"
      [options]="gedDocumentTypeListOptions"
      class="width-100pc"></oui-autocomplete>
  </div>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" [oui-dialog-close]>Annuler</oui-button>
  <oui-button type="contained" (click)="onValidate()">Valider</oui-button>
</oui-dialog-actions>

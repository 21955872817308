import { DatePipe } from '@angular/common';
import { CreationPersonneMorale, CreationPersonneMoraleStatut } from '../../models/generated/graphql';
import { CreationPMItem } from './creation-personne-morale-dashboard.component';

export abstract class CreationPersonneMoraleDashboardColumn {
  public header: string;

  constructor(header: string) {
    this.header = header;
  }
  public abstract getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string;

  public abstract sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number;
  protected static compare(a: number | string | Date, b: number | string | Date, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}

export class RaisonSocialeCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Raison sociale');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.raisonSociale ?? 'Raison sociale inconnue';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.raisonSociale, b.raisonSociale, isAsc);
  }
}

export class FormeSocialeCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Forme sociale');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.formeSociale ?? 'Inconnue';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.formeSociale, b.formeSociale, isAsc);
  }
}

export class DateDemandeCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Date de la demande');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    if (datePipe == null) {
      return new Date(creation.demandeDate).getTime().toString();
    }
    return datePipe?.transform(creation.demandeDate, 'dd/MM/yyyy') ?? 'Date inconnue';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.demandeDate, b.demandeDate, isAsc);
  }
}

export class InvestisseurPhysiqueCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Personne physique');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.personnePhysique ?? '-';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.personnePhysique, b.personnePhysique, isAsc);
  }
}

export class StatutCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Statut');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.statut ?? '-';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.statut, b.statut, isAsc);
  }
}

export class ActionConsultantCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('ActionConsultant');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.statut === CreationPersonneMoraleStatut.Nouveaute ? 'true' : '';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.statut, b.statut, isAsc);
  }
}
export class ActionBackOfficeCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('ActionBackOffice');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return creation.statut === CreationPersonneMoraleStatut.DemandeEnCours ? 'true' : '';
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    return CreationPersonneMoraleDashboardColumn.compare(a.statut, b.statut, isAsc);
  }
}

export class ConsultantCreationPMColumn extends CreationPersonneMoraleDashboardColumn {
  constructor() {
    super('Consultant');
  }
  public getDisplayValue(creation: CreationPMItem, datePipe?: DatePipe): string {
    return `${creation.consultant?.nom} ${creation.consultant?.prenom}`;
  }

  public sort(a: CreationPMItem, b: CreationPMItem, isAsc: boolean): number {
    if (!a.consultant?.nom || !b.consultant?.nom) {
      return 0;
    }
    return CreationPersonneMoraleDashboardColumn.compare(a.consultant?.nom, b.consultant?.nom, isAsc);
  }
}

<div class="padding-16 gap-16 flex-horizontal background-valid-pastel rounded">
  <oui-icon class="color-valid">task</oui-icon>
  <div class="flex-vertical gap-4">
    <div class="subtitle-1 color-valid">Déclaré conforme</div>
    <div class="caption color-on-surface-medium" *ngIf="latestConformity?.initiatorBackOffice">
      par {{ latestConformity?.initiatorBackOffice?.displayName }}
    </div>
    <div class="caption color-on-surface-medium" *ngIf="latestConformity?.timestamp">
      le {{ latestConformity?.timestamp | date: 'dd/MM/yyyy' }} à {{ latestConformity?.timestamp | date: 'HH:mm' }}
    </div>
  </div>
</div>

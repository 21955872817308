import { Component, ViewChildren } from '@angular/core';

import { Investisseur, Operation } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { SearchInvestisseurTransferAutocompleteComponent } from 'src/app/components/parameters/param-transfer-operations/search-investisseur-transfer-autocomplete/search-investisseur-transfer-autocomplete.component';
import { TransferOperationModalComponent } from 'src/app/components/parameters/param-transfer-operations/transfer-operation-modal/transfer-operation-modal.component';

const consultantIdsByInvestisseurId = gql`
  query consultantIdsByInvestisseurId($investisseurId: Long!) {
    investisseurById(id: $investisseurId) {
      id
      investisseurConsultant(where: { actif: { eq: 1 } }) {
        consultantId
      }
    }
  }
`;

const operationNumberByInvestisseurId = gql`
  query operationNumberByInvestisseurId($investisseurId: Long!) {
    allOperationsPaginated(
      skip: 0
      take: 0
      where: { or: [{ investisseurId: { eq: $investisseurId } }, { coInvestisseurId: { eq: $investisseurId } }] }
    ) {
      totalCount
    }
  }
`;

const transferAllOperationsBetweenInvestisseurs = gql`
  mutation transferAllOperationsBetweenInvestisseurs($sourceInvestisseurId: Long!, $targetInvestisseurId: Long!) {
    transferAllOperationsBetweenInvestisseurs(
      sourceInvestisseurId: $sourceInvestisseurId
      targetInvestisseurId: $targetInvestisseurId
    ) {
      id
    }
  }
`;

@Component({
  selector: 'app-param-transfer-operations',
  templateUrl: './param-transfer-operations.component.html',
  styleUrls: ['./param-transfer-operations.component.scss'],
})
export class ParamTransferOperationsComponent {
  selectedSourceInvestisseurId?: number;
  selectedSourceInvestisseurData?: Investisseur;
  selectedTargetInvestisseurId?: number;
  selectedTargetInvestisseurData?: Investisseur;
  sourceConsultantIds: number[] = [];
  operationNumber: number = 0;

  @ViewChildren(SearchInvestisseurTransferAutocompleteComponent)
  transferOperationSearchs: SearchInvestisseurTransferAutocompleteComponent[] = [];

  constructor(
    private queryManager: QueryManagerService,
    private snackbarService: OuiSnackbarService,
    private dialogService: OuiDialogService
  ) {}

  onSubmitTransfer() {
    if (this.selectedSourceInvestisseurId && this.selectedTargetInvestisseurId && this.operationNumber > 0) {
      const modalRef = this.dialogService.openDialog(
        TransferOperationModalComponent,
        {
          sourceInvestisseurData: this.selectedSourceInvestisseurData,
          targetInvestisseurData: this.selectedTargetInvestisseurData,
          operationNumber: this.operationNumber,
        },
        'auto',
        '448px'
      );
      modalRef.afterClosed().subscribe(async (isValidated: boolean) => {
        if (isValidated) {
          this.queryManager
            .mutate<{
              transferOperationsBetweenInvestisseurs: { transferAllOperationsBetweenInvestisseurs: Operation[] };
            }>({
              mutation: transferAllOperationsBetweenInvestisseurs,
              variables: {
                sourceInvestisseurId: this.selectedSourceInvestisseurId,
                targetInvestisseurId: this.selectedTargetInvestisseurId,
              },
            })
            .subscribe(result => {
              if (!result.errors?.length) {
                this.snackbarService.open('Transfert terminé', 'success', 7000, {
                  horizontal: 'left',
                  vertical: 'bottom',
                });
                this.resetPage();
              }
            });
        }
      });
    }
  }

  onSourceInvestisseurIdSelected(id: number | null | undefined) {
    this.selectedSourceInvestisseurId = id ?? undefined;

    if (this.selectedSourceInvestisseurId) {
      this.queryManager
        .query<{ investisseurById: Investisseur }>({
          query: consultantIdsByInvestisseurId,
          variables: {
            investisseurId: this.selectedSourceInvestisseurId,
          },
          fetchPolicy: 'network-only',
        })
        .subscribe(investisseurResult => {
          this.sourceConsultantIds = investisseurResult.data.investisseurById.investisseurConsultant.map(
            invCons => invCons.consultantId
          );
          this.transferOperationSearchs.find(search => search.isTarget)?.clear();
        });

      this.queryManager
        .query<{ allOperationsPaginated: { totalCount: number } }>({
          query: operationNumberByInvestisseurId,
          variables: {
            investisseurId: this.selectedSourceInvestisseurId,
          },
          fetchPolicy: 'network-only',
        })
        .subscribe(investisseurResult => {
          this.operationNumber = investisseurResult.data.allOperationsPaginated.totalCount ?? 0;
        });
    } else {
      this.sourceConsultantIds = [];
      this.selectedSourceInvestisseurId = undefined;
      this.operationNumber = 0;
    }
  }

  onSourceInvestisseurData(investisseur?: Investisseur) {
    this.selectedSourceInvestisseurData = investisseur;
  }

  onTargetInvestisseurIdSelected(id: number | null | undefined) {
    this.selectedTargetInvestisseurId = id ?? undefined;
  }

  onTargetInvestisseurData(investisseur?: Investisseur) {
    this.selectedTargetInvestisseurData = investisseur;
  }

  resetPage() {
    this.selectedSourceInvestisseurId = undefined;
    this.selectedSourceInvestisseurData = undefined;
    this.selectedTargetInvestisseurId = undefined;
    this.selectedTargetInvestisseurData = undefined;
    this.sourceConsultantIds = [];
    this.operationNumber = 0;
    this.transferOperationSearchs.forEach(search => search.clear());
  }
}

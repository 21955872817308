<div class="flex-vertical width-99pc padding-bottom-32">
  <div class="flex-vertical padding-vertical-32 gap-8">
    <div class="subtitle-1 color-on-surface-emphasis">Ajouter une note</div>
    <div class="width-100pc">
      <oui-textarea
        label="Vous pouvez saisir un texte libre ici"
        class="width-100pc"
        [control]="noteControl"></oui-textarea>
    </div>
    <div>
      <oui-button type="contained" [disabled]="!noteControl.valid" (click)="addNote()">Ajouter la note</oui-button>
    </div>
  </div>
  <div class="flex-vertical">
    <div class="flex-vertical gap-8">
      <ng-container *ngFor="let note of notes">
        <div
          class="background-white padding-16 oui-elevation-04 rounded flex-vertical gap-8"
          [ngClass]="{
            'background-white padding-16 oui-elevation-04 rounded flex-vertical gap-8': true,
            'padding-16': note.gestionnaire.tokenId !== userTokenId,
            'padding-lateral-16 padding-bottom-16 padding-top-8': note.gestionnaire.tokenId === userTokenId,
          }">
          <div class="flex-horizontal gap-8 height-36 flex-baseline">
            <div class="body-2 color-on-surface-medium">
              Par {{ note.gestionnaire.displayName }} le {{ note.dateCreation | date: 'dd/MM/yyyy' }} à
              {{ note.dateCreation | date: 'HH:mm' }}
            </div>
            <div class="body-2 color-on-surface-medium" *ngIf="note.dateDerniereModification > note.dateCreation">
              modifié
            </div>
            <div class="flex-1"></div>
            <div *ngIf="note.gestionnaire.tokenId === userTokenId" class="flex-horizontal gap-8">
              <oui-button type="text" (click)="toogleMode(note)">Modifier</oui-button>
              <oui-button type="text" (click)="onDeleteNote(note)">Supprimer</oui-button>
            </div>
          </div>
          <div *ngIf="!note.isEditableMode">{{ note.contenu }}</div>
          <div *ngIf="note.isEditableMode" class="flex-vertical gap-8">
            <oui-textarea [(value)]="note.editableContenu" class="with-border width-100pc rounded"></oui-textarea>
            <div class="flex-horizontal gap-8">
              <div class="flex-1"></div>
              <oui-button type="text" (click)="toogleMode(note)">Annuler</oui-button>
              <oui-button (click)="updateNote(note)">Valider la modification </oui-button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

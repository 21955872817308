<div class="flex-vertical">
  <div class="flex-horizontal flex-center padding-right-24">
    <h6 mat-dialog-title>{{ title ?? 'Confirmation' }}</h6>
    <div class="flex-1"></div>
    <oui-icon class="cursor-pointer" mat-dialog-close>close</oui-icon>
  </div>
  <mat-dialog-content>
    <div class="flex-vertical gap-16">
      <div class="flex-horizontal align-self-center">
        <oui-loading size="small" *ngIf="isLoadingProducts"></oui-loading>
      </div>
      <ng-container *ngIf="!isLoadingProducts">
        <oui-textfield type="text" label="Libellé" placeholder="Libellé" [control]="libelleControl"></oui-textfield>
        <oui-select
          [multiple]="true"
          [options]="productOptions"
          [control]="produitIdsControl"
          placeholder="Produits associés"></oui-select
      ></ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="padding-right-24 padding-bottom-24">
    <div class="flex-1"></div>
    <oui-button type="text" class="flex-none" (click)="onCancel()"> Annuler </oui-button>
    <oui-button
      type="contained"
      class="flex-none padding-left-8"
      (click)="onValidate()"
      [disabled]="!libelleControl.valid || !produitIdsControl.valid || isMutating">
      {{ validateButtonLabel ?? 'Ajouter' }}
    </oui-button>
  </mat-dialog-actions>
</div>

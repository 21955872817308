import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { EnvoiPartenaire, EnvoiPartenaireSortInput, SortEnumType } from '@lib/models/generated/graphql';

@Component({
  selector: 'app-bordereaux-table',
  templateUrl: './bordereaux-table.component.html',
  styleUrls: ['./bordereaux-table.component.scss'],
})
export class BordereauxTableComponent extends AbstractPaginatedTableComponent<EnvoiPartenaire> {
  @ViewChild('envoiPartenaireSort') envoiPartenaireSort!: MatSort;
  displayedColumns = ['identifiant', 'Partenaire', 'sendDate', 'receiveDate', 'updateDate', 'statut', 'warning'];

  @Output() onSortClick = new EventEmitter<EnvoiPartenaireSortInput | undefined>();

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    this.envoiPartenaireSort.active = 'updateDate';
    this.envoiPartenaireSort.start = 'desc';

    this.envoiPartenaireSort.sortChange.subscribe(() => {
      this.onSortClick.emit(this.getSortInputBySort(this.envoiPartenaireSort));
    });
  }

  getSortInputBySort(sort: MatSort): EnvoiPartenaireSortInput | undefined {
    {
      if (sort.direction == '') {
        return undefined;
      }
      const sortType = sort.direction === 'asc' ? SortEnumType.Asc : SortEnumType.Desc;
      switch (sort.active) {
        case 'identifiant':
          return { id: sortType };
        case 'sendDate':
          return { sendingDate: sortType };
        case 'receiveDate':
          return { receiveDate: sortType };
        case 'updateDate':
          return { updateDate: sortType };
        case 'statut':
          return { statutEnvoi: sortType };
        default:
          return undefined;
      }
    }
  }

  onRowClick(envoi: EnvoiPartenaire) {
    this.router.navigate(['bordereau-details', envoi.id]);
  }
}

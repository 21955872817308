import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { Operation } from '../../../models/generated/graphql';

const deleteDraftOperation = gql`
  mutation deleteDraftOperation($operationId: Int!) {
    deleteDraftOperation(operationId: $operationId)
  }
`;

@Component({
  selector: 'lib-delete-draft-confirm',
  templateUrl: './delete-draft-confirm.component.html',
  styleUrls: ['./delete-draft-confirm.component.scss'],
})
export class DeleteDraftConfirmComponent {
  operation: Operation;
  investisseurFullName: string;
  operationInfo: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { operation: Operation },
    private dialogRef: MatDialogRef<DeleteDraftConfirmComponent>,
    private queryManager: QueryManagerService
  ) {
    this.operation = data.operation;
    this.investisseurFullName =
      this.operation.investisseurPreset?.nom + ' ' + this.operation.investisseurPreset?.prenom;
    const coInvestisseurPreset = this.operation.coInvestisseurPreset;
    if (coInvestisseurPreset) {
      this.investisseurFullName =
        this.investisseurFullName + ' & ' + coInvestisseurPreset?.nom + ' ' + coInvestisseurPreset?.prenom;
    }
    const operationNature = this.operation.operationConfig?.natureOperation?.libelle;
    const operationEnveloppe = this.operation?.produitPreset?.enveloppeLibelle;
    const operationMontant = this.operation?.montant?.toFixed(2).replace('.', ',');
    this.operationInfo = `${operationNature ?? 'Nature non renseignée'} / ${operationEnveloppe ?? 'Enveloppe non renseignée'} / ${operationMontant ?? '0'} €`;
  }

  async onDelete() {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ deleteDraftOperation: Number }>({
        mutation: deleteDraftOperation,
        variables: {
          operationId: this.operation.id,
        },
      })
    );
    if (result.data?.deleteDraftOperation === this.operation.id) {
      this.dialogRef.close(this.operation.id);
    }
  }
}

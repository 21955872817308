import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationParams, DEFAULT_PAGINATION_PARAMS } from '../paginated-table/paginated-table.component';
import { gql } from 'apollo-angular';
import { QueryManagerService } from '../../services/queryManagerService';
import {
  CreationPersonneMorale,
  CreationPersonneMoraleFilterInput,
  CreationPersonneMoraleSortInput,
  CreationPersonneMoralesPaginatedCollectionSegment,
  CreationPersonneMoraleStatut,
  PresettedConsultant,
  SortEnumType,
} from '../../models/generated/graphql';
import { CreationPersonneMoraleDashboardColumn } from './creation-personne-morale-dashboard-column';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

// creationpersonnemorale request for consultant (no consultant information are requested)
const CREATIONPERSONNEMORALE_CONSULTANT = gql`
  query allCreationPersonneMorales($filter: CreationPersonneMoraleFilterInput) {
    allCreationPersonneMorales(where: $filter) {
      id
      statut
      raisonSociale
      demandeDate
      atlasInvestisseurId
      investisseurPhysiqueId
      consultantId
      investisseurPhysiquePreset {
        id
        displayName
      }
      formeSocialeId
      formeSociale {
        libelle
      }
    }
  }
`;

const CREATIONPERSONNEMORALE_BACKOFFICE = gql`
  query allCreationPersonneMorales($filter: CreationPersonneMoraleFilterInput) {
    allCreationPersonneMorales(where: $filter) {
      id
      statut
      raisonSociale
      atlasInvestisseurId
      demandeDate
      investisseurPhysiqueId
      consultantId
      investisseurPhysiquePreset {
        id
        displayName
      }
      formeSocialeId
      formeSociale {
        libelle
      }
      consultantPreset {
        id
        nom
        prenom
      }
    }
  }
`;

export interface CreationPMItem {
  id: number;
  consultant?: PresettedConsultant;
  statut: CreationPersonneMoraleStatut;
  raisonSociale: string;
  demandeDate: Date;
  formeSociale: string;
  personnePhysique: string;
}

@Component({
  selector: 'lib-creation-personne-morale-dashboard',
  templateUrl: './creation-personne-morale-dashboard.component.html',
  styleUrls: ['./creation-personne-morale-dashboard.component.scss'],
})
export class CreationPersonneMoraleDashboardComponent {
  @Output()
  onTotalCount = new EventEmitter<number>();
  @Output()
  onDeclareSouscription = new EventEmitter<CreationPersonneMorale>();
  @Input({ required: true })
  columnSelection: CreationPersonneMoraleDashboardColumn[] = [];

  @Input({ required: true })
  config: 'backoffice' | 'consultant';

  creationPMs: CreationPersonneMorale[] = [];
  creationPMItems: CreationPMItem[] = [];
  filteredCreationPMItems: CreationPMItem[] = [];
  totalCount: number = 0;
  requestResult: { data: CreationPMItem[]; loading: boolean } | undefined;

  statusOptions: OuiSelectOption<CreationPersonneMoraleStatut>[] = [
    { label: 'Demande en cours', value: CreationPersonneMoraleStatut.DemandeEnCours },
    { label: 'Crée', value: CreationPersonneMoraleStatut.Nouveaute },
  ];
  statusControl: FormControl = new FormControl();
  searchControl: FormControl = new FormControl();
  constructor(protected queryManager: QueryManagerService) {}

  ngOnInit() {
    this.getAllCreationPersonneMorales();
    this.statusControl.valueChanges.subscribe(value => {
      if (value) {
        this.filterCreationPMs();
      }
    });
    this.searchControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(value => {
      this.filterCreationPMs();
    });
  }

  getAllCreationPersonneMorales() {
    this.requestResult = {
      loading: true,
      data: [],
    };
    const order: CreationPersonneMoraleSortInput[] = [{ demandeDate: SortEnumType.Desc }];
    const filter = this.buildRequestFilter();
    const query = this.config === 'backoffice' ? CREATIONPERSONNEMORALE_BACKOFFICE : CREATIONPERSONNEMORALE_CONSULTANT;
    const requestVariables = {
      filter,
    };
    this.queryManager
      .query<{ allCreationPersonneMorales: CreationPersonneMorale[] }>({
        query: query,
        variables: requestVariables,
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        this.creationPMs = data.allCreationPersonneMorales;
        this.totalCount = data.allCreationPersonneMorales.length;
        this.creationPMItems = this.creationPMs.map(this.CreationPMItemFromCreationPersonneMorale);
        this.filterCreationPMs();
        this.requestResult = { loading, data: this.filteredCreationPMItems };
        this.onTotalCount.emit(this.totalCount);
      });
  }

  buildRequestFilter() {
    if (this.config === 'backoffice') {
      return { statut: { eq: CreationPersonneMoraleStatut.DemandeEnCours } };
    }

    const filter: CreationPersonneMoraleFilterInput = {
      and: [],
    };
    const selectedStatus = this.statusControl.value;
    if (selectedStatus) {
      filter.and!.push({ statut: { eq: selectedStatus } });
    } else {
      filter.and!.push({
        or: [
          { statut: { eq: CreationPersonneMoraleStatut.DemandeEnCours } },
          { statut: { eq: CreationPersonneMoraleStatut.Nouveaute } },
        ],
      });
    }
    return filter;
  }

  declareSouscription(item: CreationPMItem) {
    const index = this.creationPMs.findIndex(creationPM => creationPM.id === item.id);
    if(index === -1) {
      return;
    }
    this.onDeclareSouscription.emit(this.creationPMs[index]);
  }

  CreationPMItemFromCreationPersonneMorale(data: CreationPersonneMorale): CreationPMItem {
    const consultant = data.consultantPreset ? data.consultantPreset : undefined;
    return {
      id: data.id,
      statut: data.statut,
      raisonSociale: data.raisonSociale,
      demandeDate: data.demandeDate,
      formeSociale: data.formeSociale?.libelle ?? '-',
      consultant: consultant,
      personnePhysique: data?.investisseurPhysiquePreset?.displayName ?? '-',
    };
  }

  filterCreationPMs() {
    let filteredItems = this.creationPMItems;

    // Filter by status if a value is selected
    if (this.statusControl.value) {
      filteredItems = filteredItems.filter(item => item.statut === this.statusControl.value);
    }

    // Filter by search term if a value is entered
    if (this.searchControl.value && this.searchControl.value.trim() !== '') {
      filteredItems = filteredItems.filter(item => {
        return (
          this.isInRaisonSociale(item, this.searchControl.value) ||
          this.isInPersonnePhysique(item, this.searchControl.value)
        );
      });
    }

    // Update the filteredCreationPMItems array and requestResult object
    this.filteredCreationPMItems = filteredItems;
    if (this.requestResult?.data) {
      this.requestResult = { loading: false, data: this.filteredCreationPMItems };
    }
  }

  isInRaisonSociale(item: CreationPMItem, filter: string) {
    return item.raisonSociale.toLocaleUpperCase().includes(filter?.toLocaleUpperCase());
  }

  isInPersonnePhysique(item: CreationPMItem, filter: string) {
    return item.personnePhysique.toLocaleUpperCase().includes(filter?.toLocaleUpperCase());
  }
}

<oui-autocomplete
  #investisseurAutocomplete
  [label]="label"
  [control]="investisseurControl"
  [options]="investisseurAutocompleteOptions"
  [allowCustomValue]="true"
  [displayOptionsOnEmptySearch]="false"
  [displayResetButton]="true"
  [isLoading]="isInvestisseurLoading"
  class="width-100pc"></oui-autocomplete>

<ng-container *ngIf="operation">
  <div class="flex-vertical width-100pc">
    <div class="headerContent padding-24 flex-vertical width-100pc gap-16">
      <div class="flex-horizontal">
        <oui-button type="icon" color="secondary" (click)="onBackArrowClick()">
          <oui-icon>arrow_back</oui-icon>
        </oui-button>
        <div class="flex-1 width-100pc"></div>
        <oui-menu
          [menuItems]="menuGestionnaire"
          horizontalPosition="right"
          verticalPosition="bottom"
          *ngIf="!isConsultant">
          <oui-button type="icon" color="secondary">
            <oui-icon>more_vert</oui-icon>
          </oui-button>
        </oui-menu>
      </div>
      <div class="flex-vertical gap-4">
        <h5>
          Opération N°<span>{{ operation.id }}</span>
        </h5>
        <div class="body-2 color-on-surface-medium max-width-177">
          Déclarée le {{ (operation.dateDeclaration | date : 'dd/MM/yyyy') ?? ' --/--/--' }}
        </div>
        <div class="flex-horizontal">
          <a class="cursor-pointer body-2" (click)="onHistoriqueClick()">Historique</a>
          <div class="flex-1"></div>
        </div>
      </div>
      <div class="flex-horizontal flex-center gap-12">
        <div class="flex-vertical justify-center">
          <app-operation-statut-tag [statut]="operation.statut"></app-operation-statut-tag>
        </div>

        <div class="flex-1"></div>
        <a *ngIf="isGestionnaire" class="cursor-pointer body-2" (click)="openFicheLiaisonDialog()">Fiche de liaison</a>
      </div>

      <oui-select-filter
        *ngIf="tagOptions"
        [control]="tagControl"
        [listOptions]="tagOptions"
        type="list"
        label="Sélectionner des tags"
        listPlaceholder="Sélectionnez des tags"></oui-select-filter>
      <div class="chips-overflow-container">
        <oui-chip-list type="selection" [noWrap]="false">
          <oui-chip *ngFor="let tagChip of tagChips" (remove)="onRemoveTag(tagChip.value)">{{
            tagChip.label
          }}</oui-chip>
        </oui-chip-list>
        <oui-button *ngIf="tagControl.value.length > 0" type="text" color="primary" (click)="onClearTags()"
          >Effacer les tags
        </oui-button>
      </div>
      <oui-button
        iconPrefix="check"
        *ngIf="!isConsultant && isTriggerIncluded(OPERATION_MAIL_RECEPTION_TRIGGER)"
        (click)="onDeclareMailReception()"
        >Déclarer la réception des originaux</oui-button
      >

      <div *ngIf="emailInError && emailInError.length > 0 && !isConsultant">
        <oui-banner title="L’e-mail partenaire n’a pas été reçu" declination="error" type="expandable">
          <div>
            <div>
              <div class="body-2">Code erreur : {{ emailInError[0].statutMessage }}</div>
              <div class="body-2 color-on-surface-medium">Vous pouvez cliquer ci-dessous pour l’envoyer à nouveau</div>
            </div>
            <div class="top-margin-16">
              <oui-button type="contained" (click)="onSendEmailClick()">E-mail Partenaire</oui-button>
            </div>
          </div>
        </oui-banner>
      </div>
    </div>

    <div class="operation-panel-section" *ngIf="isGestionnaire">
      <oui-pair-info
        label="Gestionnaire"
        [value]="operation.gestionnaire?.displayName"
        [editable]="isAdmin"
        emptyButtonText="A renseigner"
        (edit)="onGestionnaireClick()"></oui-pair-info>
    </div>

    <div class="operation-panel-section" *ngIf="isGestionnaire">
      <oui-accordion [expanded]="true">
        <oui-accordion-header>
          <oui-accordion-title>Commande</oui-accordion-title>
        </oui-accordion-header>
        <div class="panel-accordion-content">
          <oui-pair-info
            label="Commande Aramis"
            level="secondary"
            [value]="operation.contrat?.numCommande ?? undefined"
            [editable]="isCommandEditable()"
            (edit)="editAramisCommande()"></oui-pair-info>
        </div>
      </oui-accordion>
    </div>

    <div class="operation-panel-section" *ngIf="isGestionnaire">
      <oui-accordion>
        <oui-accordion-header>
          <oui-accordion-title>Consultant</oui-accordion-title>
          <oui-accordion-description
            class="color-error body-2"
            *ngIf="isConsultantHabilitationWarning; else noConsultantWarning">
            <oui-icon class="color-error">gpp_bad</oui-icon>
            <span>{{ consultant.displayName }}</span>
          </oui-accordion-description>
          <ng-template #noConsultantWarning>
            <oui-accordion-description class="body-2">
              {{ consultant.displayName }}
            </oui-accordion-description>
          </ng-template>
        </oui-accordion-header>
        <div class="flex-vertical gap-24 padding-top-18">
          <div class="flex-horizontal gap-16">
            <div class="photoframe">
              <oui-icon>person_black_24dp</oui-icon>
            </div>
            <div class="flex-vertical body-2 gap-6">
              <div class="body-2 color-on-surface-medium">Consultant(e)</div>
              <div>{{ consultant.displayName }}</div>
              <div>{{ consultant.phoneNumber }}</div>
              <div *ngIf="consultant.mailToLink">
                <a [href]="consultant.mailToLink" class="body-2">{{ consultant.email }}</a>
              </div>
              <div>
                <span class="color-on-surface-medium">Taux de commissionnement : </span>{{ consultant.commisionnement }}
              </div>
              <div><span class="color-on-surface-medium">Titre : </span>{{ consultant.titre }}</div>
            </div>
          </div>
          <div class="flex-horizontal gap-12" *ngFor="let assistant of assistants">
            <div class="photoframe"><oui-icon>frame_person</oui-icon></div>
            <div class="flex-vertical body-2">
              <div class="body-2 color-on-surface-medium">Assistant(e)</div>
              <div>{{ assistant.displayName }}</div>
              <div>{{ assistant.phoneNumber ?? '-' }}</div>
              <div *ngIf="assistant.mailToLink">
                <a [href]="assistant.mailToLink" class="body-2">{{ assistant.email }}</a>
              </div>
            </div>
          </div>
          <div class="flex-vertical gap-8">
            <div class="body-2 color-on-surface-medium">Habilitations</div>
            <div class="flex-vertical gap-4">
              <div class="flex-horizontal flex-center gap-8 body-2" *ngFor="let hab of consultantHabilitations">
                <oui-icon [class]="hab.class">{{ hab.icon }}</oui-icon>
                <div
                  [ngClass]="{
                    'width-124': true,
                    bold: hab.isOperationHabilitation,
                  }">
                  {{ hab.libelle }}
                </div>
                <div class="body-2 color-on-surface-medium">
                  du
                  {{ formatDate(hab.startDate) }} au
                  {{ formatDate(hab.endDate) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </oui-accordion>
    </div>

    <div class="operation-panel-section">
      <oui-accordion [expanded]="true">
        <oui-accordion-header>
          <oui-accordion-title>Investisseurs</oui-accordion-title>
        </oui-accordion-header>

        <div class="panel-accordion-content">
          <oui-pair-info
            label="Investisseur"
            level="secondary"
            value="{{ operation.investisseur?.investisseurEntite?.displayName }}"
            [editable]="false"></oui-pair-info>

          <oui-pair-info
            *ngIf="operation.coInvestisseur?.investisseurEntite"
            label="Investisseur"
            level="secondary"
            value="{{ operation.coInvestisseur?.investisseurEntite?.displayName }}"
            [editable]="false"></oui-pair-info>
        </div>
      </oui-accordion>
    </div>

    <div class="operation-panel-section">
      <oui-accordion [expanded]="true">
        <oui-accordion-header>
          <oui-accordion-title>Informations</oui-accordion-title>
        </oui-accordion-header>

        <div class="panel-accordion-content">
          <oui-pair-info
            label="Nature"
            level="secondary"
            [value]="operation.operationConfig?.natureOperation?.libelle"
            [editable]="false"></oui-pair-info>

          <oui-pair-info
            label="Enveloppe"
            level="secondary"
            [value]="operation.produit?.enveloppeNavigation?.libelle ?? undefined"
            [editable]="false"></oui-pair-info>

          <oui-pair-info
            label="Produit"
            level="secondary"
            [value]="operation.produit?.nomTechniqueProduitBox ?? operation.produit?.nom ?? undefined"
            [editable]="false"></oui-pair-info>

          <oui-pair-info
            *ngIf="operation.operationType != 'SOUSCRIPTION' && operation.contrat"
            label="Contrat N°"
            level="secondary"
            [value]="operation.contrat.numeroContrat ?? undefined"
            [editable]="false"></oui-pair-info>

          <oui-pair-info
            *ngIf="operation.montant != null && operation.montant != undefined"
            label="Montant de l'opération"
            level="secondary"
            value="{{ operation.montant | currency : 'EUR' }}"
            [editable]="isInformationsEditable"
            (edit)="openEditDialog('montant', operation.montant)"></oui-pair-info>

          <oui-pair-info
            *ngIf="operation.transactionPersonnelleStatut != null"
            label="Transaction personnelle"
            level="secondary"
            value="{{ wrapTransactionPersonnelleStatut(operation.transactionPersonnelleStatut) }}"
            [editable]="isInformationsEditable"
            (edit)="openEditDialog('transaction-personnelle', operation.transactionPersonnelleStatut)"></oui-pair-info>

          <ng-container *ngIf="specificData">
            <oui-pair-info
              *ngIf="specificData.versementsProgrammes"
              label="Mise en place de versements programmés"
              level="secondary"
              value="{{ specificData.versementsProgrammes }}"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="operation.fondEvenementiels && operation.fondEvenementiels.length > 0"
              label="Fonds Evènementiels"
              level="secondary"
              [value]="fondsEvenementiels"
              [editable]="isInformationsEditable"
              (edit)="openEditDialog('fondsEvenementiels', operation.fondEvenementiels)"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.modePaiement && specificData.modePaiement.length"
              label="Mode de paiement"
              level="secondary"
              [value]="modesPaiement"
              [editable]="isInformationsEditable"
              (edit)="openEditDialog('modePaiement', specificData.modePaiement)"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.objetModification && specificData.objetModification.length"
              label="Objet de la modification"
              level="secondary"
              [value]="objetsModification"
              [editable]="isInformationsEditable"
              (edit)="openEditDialog('objetModification', specificData.objetModification)"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.demembrement"
              label="Démembrement"
              level="secondary"
              [value]="demembrement"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.demembrement && specificData.demembrement != 'Viager'"
              label="Contrepartie connue ?"
              level="secondary"
              [value]="isContrepartie"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.demembrement && contrepartieName"
              label="Contrepartie"
              level="secondary"
              [value]="contrepartieName"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="typeTauxFrais"
              label="Type de taux de frais"
              level="secondary"
              [value]="typeTauxFrais"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="impactDiminutionFrais"
              label="Impact diminution des frais"
              level="secondary"
              [value]="impactDiminutionFrais"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="transmissionOriginaux"
              label="Transmission des originaux"
              level="secondary"
              [value]="transmissionOriginaux"
              [editable]="false"></oui-pair-info>

            <oui-pair-info
              *ngIf="operation.typeSignaturePartenaire"
              label="Comment ont été signés les documents du partenaire ? (hors documents Stellium)"
              level="secondary"
              [value]="typeSignature"
              [editable]="isInformationsEditable"
              (edit)="openEditDialog('typeSignaturePartenaire', operation.typeSignaturePartenaire)"></oui-pair-info>

            <oui-pair-info
              *ngIf="specificData.commentaire"
              label="Commentaire"
              level="secondary"
              [value]="specificData.commentaire"
              [editable]="false"
              orientation="vertical"></oui-pair-info>
          </ng-container>
        </div>
      </oui-accordion>
    </div>
    <div class="background-white flex-1 filler"></div>
  </div>
</ng-container>

import { Component, Input } from '@angular/core';
import { EnvoiPartenaireStatut } from '@lib/models/generated/graphql';
import { OuiTagStatus } from 'omnium-ui/tag';

@Component({
  selector: 'app-bordereau-statut-tag',
  templateUrl: './bordereau-statut-tag.component.html',
  styleUrls: ['./bordereau-statut-tag.component.scss'],
})
export class BordereauStatutTagComponent {
  @Input() statut?: EnvoiPartenaireStatut;
  @Input() size: string = 'big';

  getStatusText(): string {
    switch (this.statut) {
      case EnvoiPartenaireStatut.CourrierBordereauCreated:
        return 'Créé';
      case EnvoiPartenaireStatut.CourrierSent:
        return 'Envoyé';
      case EnvoiPartenaireStatut.CourrierReceived:
        return 'Réceptionné';
      case EnvoiPartenaireStatut.CourrierSearching:
        return 'En recherche';
      case EnvoiPartenaireStatut.CourrierLost:
        return 'Perdu';
      default:
        return 'Autre';
    }
  }

  getStatusColor(): OuiTagStatus | undefined {
    switch (this.statut) {
      case EnvoiPartenaireStatut.CourrierBordereauCreated:
        return 'in-progress';
      case EnvoiPartenaireStatut.CourrierSent:
      case EnvoiPartenaireStatut.CourrierSearching:
        return 'transition';
      case EnvoiPartenaireStatut.CourrierReceived:
        return 'completed';
      case EnvoiPartenaireStatut.CourrierLost:
        return 'pending';
      default:
        return 'in-progress';
    }
  }
}

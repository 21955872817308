<div oui-dialog-title>
  Dupliquer des non-conformités
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="padding-bottom-16">
    <div>Ce document est présent dans d’autres opérations avec les non conformités suivantes.</div>
    <div>Veuillez sélectionner les non-conformités que vous souhaitez appliquer à cette opération.</div>
  </div>
  <div class="flex-vertical gap-8">
    <oui-table>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="selector">
          <th mat-header-cell *matHeaderCellDef>
            <oui-checkbox
              (checkedChange)="toggleAllRows()"
              [checked]="selectedIds.length > 0 && isAllSelected()"
              [indeterminate]="selectedIds.length > 0 && !isAllSelected()">
            </oui-checkbox>
          </th>
          <td mat-cell *matCellDef="let element">
            <oui-checkbox
              (click)="$event.stopPropagation()"
              (checkedChange)="selectNC(element.id, $event)"
              [checked]="selectedIds.includes(element.id)"></oui-checkbox>
          </td> </ng-container
        ><ng-container matColumnDef="Categorie">
          <th mat-header-cell *matHeaderCellDef>Categorie</th>
          <td mat-cell *matCellDef="let element">{{ element?.categorie }}</td>
        </ng-container>
        <ng-container matColumnDef="Motif">
          <th mat-header-cell *matHeaderCellDef>Motif</th>
          <td mat-cell *matCellDef="let element">{{ element?.motif }}</td>
        </ng-container>
        <ng-container matColumnDef="Commentaire">
          <th mat-header-cell *matHeaderCellDef>Commentaire</th>
          <td mat-cell *matCellDef="let element">{{ element?.commentaire }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['selector', 'Categorie', 'Motif', 'Commentaire']"></tr>
        <ng-container>
          <tr
            mat-row
            [class.interactive]="true"
            *matRowDef="let row; columns: ['selector', 'Categorie', 'Motif', 'Commentaire']"></tr>
        </ng-container>
      </table>
    </oui-table>
  </div>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" class="flex-none" (click)="cancel()"> Annuler </oui-button>
  <oui-button type="contained" class="flex-none" (click)="validate()" [disabled]="!canValidate()"> Valider </oui-button>
</oui-dialog-actions>

import { ChangeDetectorRef, Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';
import { DashboardFiltersService } from 'projects/box-lib/src/lib/services/dashboard-filters.service';
import { OptionsService } from 'projects/box-lib/src/lib/services/options.service';
import { UserParametersService } from 'projects/box-lib/src/lib/services/user-parameters.service';
import { DashboardFilterEnum } from '../../../models/dashboardFilterEnum';
import {
  BackOfficeMember,
  EnveloppeProduit,
  EnvoiEmailStatut,
  FondEvenementiel,
  Investisseur,
  NatureOperation,
  OperationFilterInput,
  Produit,
  Statut,
  StatutFilterInput,
  TypeSignaturePartenaire,
} from '../../../models/generated/graphql';
import {
  AbstractDashboardFilterComponent,
  FilterChips,
} from '../../dashboard-filters/abstract-dashboard-filter.component';
import { SearchInvestisseurAutocompleteComponent } from '../../search-investisseur-autocomplete/search-investisseur-autocomplete.component';

@Component({
  selector: 'lib-operation-dashboard-filters',
  templateUrl: './operation-dashboard-filters.component.html',
  styleUrls: ['./operation-dashboard-filters.component.scss'],
})
export class OperationDashboardFiltersComponent extends AbstractDashboardFilterComponent<OperationFilterInput> {
  @ViewChild(SearchInvestisseurAutocompleteComponent)
  private investisseurSelector: SearchInvestisseurAutocompleteComponent;
  @Input({ required: true }) defaultQueryFilter: OperationFilterInput | undefined;
  @Input() preSelectedGestionnaireId: string | undefined;
  @Input() dashboardKey: string;

  fondsEvntOptions: OuiSelectOption<any>[] = [];
  statutOptions: OuiSelectOption<any>[] = [];
  produitOptions: OuiSelectOption<any>[] = [];
  enveloppeOptions: OuiSelectOption<any>[] = [];
  gestionnaireOptions: OuiSelectOption<any>[] = [];
  natureOperationOptions: OuiSelectOption<any>[] = [];
  tagOptions: OuiSelectOption<number>[] = [];
  signatureOptions: OuiSelectOption<TypeSignaturePartenaire>[] = [];
  errorEmailOptions: OuiSelectOption<boolean | undefined>[] = [];
  commandeAramisOptions: OuiSelectOption<boolean | undefined>[] = [];
  isInvestisseurPersonneMoraleOptions: OuiSelectOption<boolean>[] = [];

  statutControl = new FormControl<number[]>([]);
  enveloppeControl = new FormControl<number[]>([]);
  natureOperationControl = new FormControl<number[]>([]);
  produitControl = new FormControl<number[]>([]);
  montantControl = new FormControl();
  gestionnaireControl = new FormControl<number[]>([]);
  fondsEvntControl = new FormControl<number[]>([]);
  tagControl = new FormControl<(number | undefined)[]>([]);
  signatureControl = new FormControl<TypeSignaturePartenaire[]>([]);
  errorEmailControl = new FormControl<(boolean | undefined)[]>([]);
  commandeAramisControl = new FormControl<(boolean | undefined)[]>([]);
  isInvestisseurPersonneMoraleControl = new FormControl<boolean[]>([]);

  selectedInvestisseurId: number | null | undefined;
  allGestionnaires: BackOfficeMember[];

  isInitialized = false;

  isBackOffice = false;
  goToOperationId: number;
  goToOperationIdControl = new FormControl<number | null>(null);

  constructor(
    optionsService: OptionsService,
    userParametersService: UserParametersService,
    protected dashboardFiltersService: DashboardFiltersService,
    protected authService: AuthService,
    protected cdRef: ChangeDetectorRef,
    private authServiceService: AuthService,
    private router: Router
  ) {
    super(optionsService, userParametersService);

    this.goToOperationIdControl.valueChanges.subscribe(id => {
      this.goToOperationId = Number(id);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['defaultQueryFilter']) {
      this.getAllStatuts();
      this.emitNewFilter();
    }
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.authService.userBackOfficeSubject.subscribe(user => {
      this.isBackOffice = this.authService.isBackOffice();
    });
  }
  override emitNewFilter(): void {
    if (this.defaultQueryFilter && this.isInitialized) {
      this.onFilterChanged.emit(this.buildNewFilter());
    }
  }
  goToOperation() {
    if (!Number.isNaN(this.goToOperationId)) {
      this.router.navigate(['backoffice', this.goToOperationId]);
    }
  }

  override buildNewFilter(): OperationFilterInput {
    if (!this.defaultQueryFilter) return {};
    const requestFilter: OperationFilterInput = { and: [this.defaultQueryFilter] };
    for (const filter of this.filterStateMap.values()) {
      if (filter.chips.length > 0) {
        requestFilter.and?.push({ or: filter.chips.map(chip => chip.value.filter) });
      }
    }
    return requestFilter;
  }
  override async getAllDataForFilters(): Promise<void> {
    if (this.isFilterActive(DashboardFilterEnum.FondEvnt)) {
      await this.getAllFondsEvenementiels();
    }

    if (this.isFilterActive(DashboardFilterEnum.Enveloppe) || this.isFilterActive(DashboardFilterEnum.Produit)) {
      await this.getAllProduits();
    }
    if (this.isFilterActive(DashboardFilterEnum.Gestionnaire)) {
      await this.getAllGestionnaires();
    }
    if (this.isFilterActive(DashboardFilterEnum.Nature)) {
      await this.getAllNatures();
    }
    if (this.isFilterActive(DashboardFilterEnum.Statut)) {
      await this.getAllStatuts();
    }
    if (this.isFilterActive(DashboardFilterEnum.Tag)) {
      await this.getOperationTags();
    }

    if (this.isFilterActive(DashboardFilterEnum.Signature)) {
      this.getAllSignatureOptions();
    }
    if (this.isFilterActive(DashboardFilterEnum.ErrorPartenaireEmail)) {
      this.getErrorEmailOptions();
    }
    if (this.isFilterActive(DashboardFilterEnum.CommandeAramis)) {
      this.getCommandeAramisOptions();
    }

    if (this.isFilterActive(DashboardFilterEnum.IsInvestisseurPersonneMorale)) {
      this.getIsInvestisseurPersonneMoraleOptions();
    }
  }

  override subscribeToFilterChanges(): void {
    this.initSelectFilter(
      this.enveloppeControl,
      this.enveloppeOptions,
      DashboardFilterEnum.Enveloppe,
      value => ({ operationConfig: { enveloppeId: { eq: value } } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.produitControl,
      this.produitOptions,
      DashboardFilterEnum.Produit,
      value => ({ produitId: { eq: value } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.natureOperationControl,
      this.natureOperationOptions,
      DashboardFilterEnum.Nature,
      value => ({ operationConfig: { natureOperationId: { eq: value } } }),
      this.dashboardKey
    );
    let gestionnaireId = undefined;
    if (this.preSelectedGestionnaireId) {
      gestionnaireId = this.getPreselectedGestionnaireId();
    }
    this.initSelectFilter(
      this.gestionnaireControl,
      this.gestionnaireOptions,
      DashboardFilterEnum.Gestionnaire,
      value => ({ gestionnaireId: { eq: value } }),
      this.dashboardKey,
      gestionnaireId
    );
    this.initSelectFilter(
      this.statutControl,
      this.statutOptions,
      DashboardFilterEnum.Statut,
      value => ({ statutId: { in: value } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.tagControl,
      this.tagOptions,
      DashboardFilterEnum.Tag,
      value => ({ tags: { some: { id: { eq: value } } } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.signatureControl,
      this.signatureOptions,
      DashboardFilterEnum.Signature,
      value => ({ typeSignaturePartenaire: { eq: value ?? null } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.fondsEvntControl,
      this.fondsEvntOptions,
      DashboardFilterEnum.FondEvnt,
      value => ({ fondEvenementiels: { some: { id: { in: value } } } }),
      this.dashboardKey
    );
    this.initSelectFilter(
      this.errorEmailControl,
      this.errorEmailOptions,
      DashboardFilterEnum.ErrorPartenaireEmail,
      value => {
        if (value) {
          return { emails: { some: { statutEnvoi: { eq: EnvoiEmailStatut.Error } } } };
        } else {
          return { emails: { all: { statutEnvoi: { neq: EnvoiEmailStatut.Error } } } };
        }
      },
      this.dashboardKey
    );

    this.initSelectFilter(
      this.commandeAramisControl,
      this.commandeAramisOptions,
      DashboardFilterEnum.CommandeAramis,
      value => {
        if (value) {
          return { contratId: { neq: null } };
        } else {
          return { contratId: { eq: null } };
        }
      },
      this.dashboardKey
    );
    this.initSelectFilter(
      this.isInvestisseurPersonneMoraleControl,
      this.isInvestisseurPersonneMoraleOptions,
      DashboardFilterEnum.IsInvestisseurPersonneMorale,
      value => {
        if (value) {
          return { isInvestisseurPersonneMorale: { eq: true } };
        } else {
          return { isInvestisseurPersonneMorale: { eq: false } };
        }
      },
      this.dashboardKey
    );
    // specific init for montant as it is not a select filter
    this.initFilter(
      this.montantControl,
      DashboardFilterEnum.Montant,
      value => this.createMontantChips(value?.minimum, value?.maximum),
      chips => {
        if (!chips || chips.length === 0) {
          return undefined;
        }
        return chips[0].value.dataValue;
      },
      this.dashboardKey
    );

    // specific init for investisseur selector
    this.filterStateMap.set(DashboardFilterEnum.Investisseur, {
      chips: [],
      filterType: DashboardFilterEnum.Investisseur,
      key: this.dashboardKey,
    });

    this.isInitialized = true;
  }

  override resetControlValues(): void {
    this.produitControl.setValue([]);
    this.enveloppeControl.setValue([]);
    this.natureOperationControl.setValue([]);
    this.gestionnaireControl.setValue([]);
    this.montantControl.setValue({ minimum: undefined, maximum: undefined });
    this.statutControl.setValue([]);
    this.investisseurSelector.setControlValue(null);
    this.tagControl.setValue([]);
    this.signatureControl.setValue([]);
    this.errorEmailControl.setValue([]);
  }

  checkPreselectedGestionnaire(gestionnaires: BackOfficeMember[]) {
    if (this.preSelectedGestionnaireId) {
      const gestionnaire = gestionnaires.find(g => g.tokenId == this.preSelectedGestionnaireId);
      if (gestionnaire) {
        this.gestionnaireControl.setValue([gestionnaire.id]);
      }
    }
  }

  getPreselectedGestionnaireId() {
    if (this.preSelectedGestionnaireId) {
      const gestionnaire = this.allGestionnaires.find(g => g.tokenId == this.preSelectedGestionnaireId);
      if (gestionnaire) {
        return [gestionnaire.id];
      }
    }
    return [];
  }

  async getAllProduits() {
    const allProduits = await this.dashboardFiltersService.getAllProduits();
    this.setProduitAndEnveloppeOptions(allProduits);
  }

  async getAllNatures() {
    const allNatureOperations = await this.dashboardFiltersService.getAllNatures();
    this.setNatureOperationOptions(allNatureOperations);
  }

  async getAllGestionnaires() {
    this.allGestionnaires = await this.dashboardFiltersService.getAllGestionnaires();
    this.setGestionnaireOptions(this.allGestionnaires);
  }
  async getAllStatuts() {
    if (!this.defaultQueryFilter) return;
    const where: StatutFilterInput = this.transformToStatutFilter(this.defaultQueryFilter);
    const allStatuts = (await this.dashboardFiltersService.getAllStatuts(where)) ?? [];
    this.authService.isBackOffice()
      ? this.setStatutOptions(allStatuts, 'backOfficeLibelle')
      : this.setStatutOptions(allStatuts, 'consultantLibelle');
  }

  // FIXME : this call use paginated query in the service: this is temporary solution and not a future-proof solution. Need input from UIUX team
  async getAllFondsEvenementiels() {
    const allFondsEvenementiels = await this.dashboardFiltersService.getAllFondsEvenementiels();
    this.setFondsEvenementielOptions(allFondsEvenementiels);
  }

  async getOperationTags() {
    this.tagOptions = await this.optionsService.getOperationTagOptions();
  }

  getAllSignatureOptions() {
    this.signatureOptions = this.optionsService.getTypeSignaturePartenaireOptions();
  }

  getErrorEmailOptions() {
    this.errorEmailOptions = this.optionsService.getTypeErrorEmailFilterOptions();
  }

  getCommandeAramisOptions() {
    this.commandeAramisOptions = this.optionsService.getCommandeAramisOptions();
  }

  getIsInvestisseurPersonneMoraleOptions() {
    this.isInvestisseurPersonneMoraleOptions = this.optionsService.getIsInvestisseurPersonneMoraleOptions();
  }
  private transformToStatutFilter(operationFilter: OperationFilterInput): StatutFilterInput {
    const statutFilter: StatutFilterInput = {};
    if (!operationFilter) return statutFilter;

    if (operationFilter.statutId) {
      statutFilter.id = { ...(operationFilter.id || operationFilter.statutId) };
    }

    if (operationFilter.and) {
      statutFilter.and = operationFilter.and.map(filter => this.transformToStatutFilter(filter));
    }

    if (operationFilter.or) {
      statutFilter.or = operationFilter.or.map(filter => this.transformToStatutFilter(filter));
    }

    return statutFilter;
  }

  private setStatutOptions(allStatuts: Statut[], propertyName: 'consultantLibelle' | 'backOfficeLibelle') {
    const groupedStatus: { [libelle: string]: number[] } = allStatuts.reduce(
      (accumulate: { [libelle: string]: number[] }, statut) => {
        const { id } = statut;
        const libelle = statut[propertyName];
        if (libelle) {
          if (!accumulate[libelle]) {
            accumulate[libelle] = [];
          }
          accumulate[libelle].push(id);
        }
        return accumulate;
      },
      {}
    );

    const options = Object.entries(groupedStatus).map(([libelle, value]) => ({ label: libelle, value }));
    this.statutOptions = options.sort((a, b) => a.label.localeCompare(b.label));
  }

  setProduitAndEnveloppeOptions(produits: Produit[]) {
    this.produitOptions = this.createOptions(
      produits,
      produit => produit.nomTechniqueProduitBox ?? produit.nom ?? 'Produit sans nom',
      produit => produit.id
    );
    this.enveloppeOptions = this.createEnveloppeOptions(produits);
    this.toggleControlState(this.produitControl, this.produitOptions);
    this.toggleControlState(this.enveloppeControl, this.enveloppeOptions);
  }

  setNatureOperationOptions(natures: NatureOperation[]) {
    this.natureOperationOptions = this.createOptions(
      natures,
      nature => nature.libelle ?? 'Nature sans nom',
      nature => nature.id
    );
    this.toggleControlState(this.natureOperationControl, this.natureOperationOptions);
  }

  setGestionnaireOptions(gestionnaires: BackOfficeMember[]) {
    this.gestionnaireOptions = this.createOptions(
      gestionnaires,
      gestionnaire => gestionnaire.displayName ?? 'Gestionnaire sans nom',
      gestionnaire => gestionnaire.id
    );
    this.toggleControlState(this.gestionnaireControl, this.gestionnaireOptions);
  }

  setFondsEvenementielOptions(fondsEvenementiels: FondEvenementiel[]) {
    this.fondsEvntOptions = this.createOptions(
      fondsEvenementiels,
      fondsEvenementiel => fondsEvenementiel.libelle ?? 'Fonds sans nom',
      fondsEvenementiel => fondsEvenementiel.id
    );
    this.toggleControlState(this.fondsEvntControl, this.fondsEvntOptions);
  }
  createEnveloppeOptions(produits: Produit[]): OuiSelectOption<any>[] {
    const enveloppes: EnveloppeProduit[] = [];
    produits.forEach(prod => {
      if (prod.enveloppeNavigation) {
        enveloppes.find(env => env?.code === prod.enveloppeNavigation?.code) ??
          enveloppes.push(prod.enveloppeNavigation);
      }
    });
    return enveloppes
      .map(env => ({ label: env?.libelle ?? 'Enveloppe sans nom', value: env?.code }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  createMontantChips(minimum: number | null, maximum: number | null): FilterChips<OperationFilterInput>[] {
    if (minimum || maximum) {
      const filter = this.getMontantFilter(minimum, maximum);
      if (filter) {
        return [
          {
            label: this.getMontantChipsLabel(minimum, maximum),
            value: { type: DashboardFilterEnum.Montant, filter, dataValue: { minimum, maximum } },
          },
        ];
      }
    }
    return [];
  }

  getMontantFilter(minimum: number | null, maximum: number | null): OperationFilterInput | null {
    const filter: OperationFilterInput = { and: [] };
    if (minimum) filter.and?.push({ montant: { gte: minimum } });
    if (maximum) filter.and?.push({ montant: { lte: maximum } });
    return minimum === null && maximum === null ? null : filter;
  }

  private getMontantChipsLabel(minimum: number | null, maximum: number | null): string {
    if (minimum && maximum) return `${minimum}€ - ${maximum}€`;
    if (minimum) return `>= ${minimum}€`;
    if (maximum) return `<= ${maximum}€`;
    return '';
  }

  onInvestisseurSelected(investisseur: Investisseur | undefined) {
    if (this.selectedInvestisseurId !== investisseur?.id) {
      let chips: FilterChips<OperationFilterInput>[] = [];
      if (investisseur?.id) {
        chips = [
          {
            label: investisseur?.investisseurEntite?.displayName ?? '-',
            value: {
              type: DashboardFilterEnum.Investisseur,
              filter: {
                or: [{ investisseurId: { eq: investisseur?.id } }, { coInvestisseurId: { eq: investisseur?.id } }],
              },
              dataValue: investisseur?.id,
            },
          },
        ];
      }
      const filter = this.filterStateMap.get(DashboardFilterEnum.Investisseur);
      if (filter) {
        filter.chips = chips;
      }
      this.emitNewFilter();
    }
  }

  override onRemove(chip: FilterChips<OperationFilterInput>) {
    this.removeChipFromFilter(chip);
    switch (chip.value.type) {
      case DashboardFilterEnum.Produit:
        this.updateControlValue(this.produitControl, chip.value.filter.produitId?.eq);
        break;
      case DashboardFilterEnum.Enveloppe:
        this.updateControlValue(this.enveloppeControl, chip.value.filter.operationConfig?.enveloppeId?.eq);
        break;
      case DashboardFilterEnum.Nature:
        this.updateControlValue(this.natureOperationControl, chip.value.filter.operationConfig?.natureOperationId?.eq);
        break;
      case DashboardFilterEnum.Gestionnaire:
        this.updateControlValue(this.gestionnaireControl, chip.value.filter.gestionnaireId?.eq);
        break;
      case DashboardFilterEnum.Investisseur:
        this.investisseurSelector.setControlValue(null);
        const filter = this.filterStateMap.get(DashboardFilterEnum.Investisseur);
        if (filter) {
          filter.chips = [];
        }
        break;
      case DashboardFilterEnum.Montant:
        this.montantControl.setValue({ minimum: undefined, maximum: undefined });
        break;
      case DashboardFilterEnum.Statut:
        this.updateControlValue(this.statutControl, chip.value.filter.statutId?.in);
        break;
      case DashboardFilterEnum.Tag:
        this.updateControlValue(this.tagControl, chip.value.filter.tags?.some?.id?.eq);
        break;
      case DashboardFilterEnum.Signature:
        this.updateControlValue(this.signatureControl, chip.value.filter.typeSignaturePartenaire?.eq);
        break;
      case DashboardFilterEnum.FondEvnt:
        this.updateControlValue(this.fondsEvntControl, chip.value.filter.fondEvenementiels?.some?.id?.in);
        break;
      case DashboardFilterEnum.ErrorPartenaireEmail:
        this.updateControlValue(this.errorEmailControl, chip.value.dataValue);
        break;
      case DashboardFilterEnum.CommandeAramis:
        this.updateControlValue(this.commandeAramisControl, chip.value.dataValue);
        break;
      case DashboardFilterEnum.IsInvestisseurPersonneMorale:
        this.updateControlValue(this.isInvestisseurPersonneMoraleControl, chip.value.dataValue);
        break;
      default:
        break;
    }

    this.emitNewFilter();
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { gql } from 'apollo-angular';
import { OuiAutocomplete, OuiAutocompleteOption } from 'omnium-ui/form-field';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { Subscription, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { EnveloppeProduit, Maybe, Produit, ProduitFilterInput } from '../../models/generated/graphql';
import { AuthService } from '../../services/auth-service.service';

const allProduits = gql`
  query allProduits($where: ProduitFilterInput) {
    allProduits(where: $where) {
      id
      nom
      nomTechniqueProduitBox
      enveloppeNavigation {
        code
        libelle
      }
      habilitationNavigation {
        id
        code
        libelle
      }
      produitExtension {
        souscriptionPossible
      }
    }
  }
`;

@Component({
  selector: 'app-search-enveloppe-produit-autocomplete',
  templateUrl: './search-enveloppe-produit-autocomplete.component.html',
  styleUrls: ['./search-enveloppe-produit-autocomplete.component.scss'],
})
export class SearchEnveloppeProduitAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchProduit') searchProduit: OuiAutocomplete<any>;

  @Input() queryFilter: ProduitFilterInput;
  @Input() isRequired: boolean = false;
  @Input() isProductOpenOnly: boolean = false;
  @Input() useSpinner: boolean = true;
  @Input() currentProduit: Produit | null | undefined;
  @Input() isProductSet: boolean = false;

  @Output() onEnveloppeSelect = new EventEmitter<number | undefined>();
  @Output() onProduitSelect = new EventEmitter<Produit | undefined>();

  enveloppeAutocompleteOptions: OuiAutocompleteOption[] = [];
  //enveloppeControl = new FormControl<number | undefined>(undefined, [ ]);

  produitAutocompleteOptions: OuiAutocompleteOption[] = [];
  // produitControl = new FormControl<number | undefined>(undefined, [ ]);

  produitControl = new FormControl<number | null | undefined>(undefined);
  enveloppeControl = new FormControl<number | null | undefined>(undefined);
  selectedProduit: Produit | undefined;
  allProduits: Produit[];
  allEnveloppes: (Maybe<EnveloppeProduit> | undefined)[] = [];
  isLoading: boolean;

  authSubscription: Subscription;

  constructor(private queryManager: QueryManagerService, private userService: AuthService) {}

  ngOnInit() {
    if (this.currentProduit) {
      this.allProduits = [this.currentProduit];
      this.allEnveloppes = [this.currentProduit.enveloppeNavigation];

      this.setProduitOptions(this.allProduits);
      this.setEnveloppeOptions(this.allEnveloppes);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.currentProduit) {
        this.produitControl.setValue(this.currentProduit.id);
        this.enveloppeControl.setValue(this.currentProduit?.enveloppeNavigation?.code);
        this.onEnveloppeSelect.emit(this.currentProduit?.enveloppeNavigation?.code);
        this.onProduitSelect.emit(this.currentProduit);
      }

      if (this.isRequired) {
        this.produitControl.setValidators(Validators.required);
        this.enveloppeControl.setValidators(Validators.required);
      }

      if (this.isProductSet) {
        this.produitControl.disable();
        this.enveloppeControl.disable();
      }

      this.userService.userHabilitationCheck.subscribe(value => {
        if (value) {
          this.initDataAndValues();
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription?.unsubscribe();
    }
  }

  initDataAndValues() {
    this.buildQueryFilter(this.userService.isBackOffice());

    this.selectedProduit = this.currentProduit ?? undefined;

    if (this.currentProduit) {
      if (!this.isProductSet) {
        this.fetchDataAndListenChanges();
      }
    } else {
      this.fetchDataAndListenChanges();
    }
  }

  async fetchAndInitProduits(where: ProduitFilterInput) {
    let produitsResult = await firstValueFrom(
      this.queryManager.query<{ allProduits: Produit[] }>({
        query: allProduits,
        variables: {
          where: where,
        },
      })
    );
    if (this.isProductOpenOnly) {
      this.allProduits = produitsResult.data.allProduits.filter(
        prod => prod?.produitExtension?.souscriptionPossible !== false
      );
    } else {
      this.allProduits = produitsResult.data.allProduits;
    }
    this.allEnveloppes = [];
    this.allProduits.forEach(prod => {
      this.allEnveloppes.find(env => env?.code === prod.enveloppeNavigation?.code) ??
        this.allEnveloppes.push(prod.enveloppeNavigation);
    });
    this.setProduitOptions(this.allProduits);
    this.setEnveloppeOptions(this.allEnveloppes);
  }

  buildQueryFilter(isBackOffice: boolean) {
    const inputFiltersList: ProduitFilterInput[] = [];
    if (this.isProductOpenOnly) {
      inputFiltersList.push({ etat: { eq: 2 } }); //produit ouvert à la souscription
    }
    if (!isBackOffice) {
      inputFiltersList.push({ habilitation: { in: this.userService.getExtendedUserValidHabilitationIds() } }); // consultant Habilitations
    }
    if (!this.queryFilter) {
      this.queryFilter = inputFiltersList.length > 0 ? { and: inputFiltersList } : this.queryFilter;
      return;
    }

    // if we have an alreday queryFilter in input, we copy the 'and' list to avoid circular structure
    const updatedFilter: ProduitFilterInput = {};
    if (this.queryFilter.and) {
      updatedFilter.and = this.queryFilter.and;
    }
    if (Array.isArray(updatedFilter.and)) {
      updatedFilter.and = [...updatedFilter.and, ...inputFiltersList];
    } else {
      updatedFilter.and = [this.queryFilter, ...inputFiltersList];
    }
    this.queryFilter = updatedFilter;
  }

  setProduitOptions(produits: Produit[]) {
    this.produitAutocompleteOptions = produits
      .map(produit => {
        return { label: produit.nomTechniqueProduitBox ?? produit.nom ?? 'Produit sans nom', value: produit.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
  setEnveloppeOptions(enveloppes: (EnveloppeProduit | undefined | null)[]) {
    this.enveloppeAutocompleteOptions = enveloppes
      .map(env => {
        return { label: env?.libelle ?? 'Enveloppe sans nom', value: env?.code };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  async fetchDataAndListenChanges() {
    this.isLoading = true;
    await this.fetchAndInitProduits(this.queryFilter);
    this.isLoading = false;
    this.produitControl.valueChanges.pipe(distinctUntilChanged()).subscribe(newProduitId => {
      if (!newProduitId) {
        this.selectedProduit = undefined;
        this.onProduitSelect.emit(undefined);
      } else {
        this.selectedProduit = this.allProduits.find(prod => prod?.id === newProduitId);
        this.onProduitSelect.emit(this.selectedProduit);
      }
    });

    this.enveloppeControl.valueChanges.pipe(distinctUntilChanged()).subscribe(newEnveloppeCode => {
      let possibleProducts = this.allProduits;
      if (!newEnveloppeCode) {
        this.onEnveloppeSelect.emit(undefined);
      } else {
        possibleProducts = this.allProduits.filter(prod => prod.enveloppeNavigation?.code === newEnveloppeCode);
        this.onEnveloppeSelect.emit(newEnveloppeCode);
        if (newEnveloppeCode !== this.selectedProduit?.enveloppeNavigation?.code) {
          this.selectedProduit = undefined;
          this.produitControl.reset();
          this.searchProduit.empty();
        }
      }
      if (possibleProducts) {
        this.setProduitOptions(possibleProducts);
      }
    });
  }
}

/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: { input: any; output: any; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any; }
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any; }
  /** The multiplier path scalar represents a valid GraphQL multiplier path string. */
  MultiplierPath: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type AbonnementConsultant = {
  __typename?: 'AbonnementConsultant';
  acceptationCGV?: Maybe<Scalars['Boolean']['output']>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dernierPaiement?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  expirationAcces?: Maybe<Scalars['DateTime']['output']>;
  finAbonnement?: Maybe<Scalars['DateTime']['output']>;
  finPeriodeGratuite?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  referenceProduit: Scalars['String']['output'];
};

export type AbonnementConsultantFilterInput = {
  acceptationCGV?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<AbonnementConsultantFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dernierPaiement?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  expirationAcces?: InputMaybe<DateTimeOperationFilterInput>;
  finAbonnement?: InputMaybe<DateTimeOperationFilterInput>;
  finPeriodeGratuite?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<AbonnementConsultantFilterInput>>;
  referenceProduit?: InputMaybe<StringOperationFilterInput>;
};

export type ActionDeFormation = {
  __typename?: 'ActionDeFormation';
  actionDeFormationSession: Array<ActionDeFormationSession>;
  codeFamille?: Maybe<Scalars['Byte']['output']>;
  codeFamilleNavigation?: Maybe<FormationType>;
  codeSaison?: Maybe<Scalars['Byte']['output']>;
  codeSaisonNavigation?: Maybe<FormationSaison>;
  creationDate: Scalars['DateTime']['output'];
  cursusFormation: Array<CursusFormation>;
  dateDebutValidite?: Maybe<Scalars['DateTime']['output']>;
  dateFinValidite?: Maybe<Scalars['DateTime']['output']>;
  dureeHeure?: Maybe<Scalars['Decimal']['output']>;
  dureeJour?: Maybe<Scalars['Decimal']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  nom: Scalars['String']['output'];
  obligatoire?: Maybe<Scalars['Byte']['output']>;
  origineData?: Maybe<Scalars['Byte']['output']>;
  origineDataNavigation?: Maybe<OrigineData>;
  reference: Scalars['String']['output'];
};


export type ActionDeFormationActionDeFormationSessionArgs = {
  where?: InputMaybe<ActionDeFormationSessionFilterInput>;
};


export type ActionDeFormationCursusFormationArgs = {
  where?: InputMaybe<CursusFormationFilterInput>;
};

export type ActionDeFormationFilterInput = {
  actionDeFormationSession?: InputMaybe<ListFilterInputTypeOfActionDeFormationSessionFilterInput>;
  and?: InputMaybe<Array<ActionDeFormationFilterInput>>;
  codeFamille?: InputMaybe<ByteOperationFilterInput>;
  codeFamilleNavigation?: InputMaybe<FormationTypeFilterInput>;
  codeSaison?: InputMaybe<ByteOperationFilterInput>;
  codeSaisonNavigation?: InputMaybe<FormationSaisonFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursusFormation?: InputMaybe<ListFilterInputTypeOfCursusFormationFilterInput>;
  dateDebutValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dureeHeure?: InputMaybe<DecimalOperationFilterInput>;
  dureeJour?: InputMaybe<DecimalOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  obligatoire?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<ActionDeFormationFilterInput>>;
  origineData?: InputMaybe<ByteOperationFilterInput>;
  origineDataNavigation?: InputMaybe<OrigineDataFilterInput>;
  reference?: InputMaybe<StringOperationFilterInput>;
};

export type ActionDeFormationSession = {
  __typename?: 'ActionDeFormationSession';
  actionDeFormation: ActionDeFormation;
  actionDeFormationId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  session: Session;
  sessionId: Scalars['Long']['output'];
};

export type ActionDeFormationSessionFilterInput = {
  actionDeFormation?: InputMaybe<ActionDeFormationFilterInput>;
  actionDeFormationId?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<ActionDeFormationSessionFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ActionDeFormationSessionFilterInput>>;
  session?: InputMaybe<SessionFilterInput>;
  sessionId?: InputMaybe<LongOperationFilterInput>;
};

export type ActiviteRattachPartenaire = {
  __typename?: 'ActiviteRattachPartenaire';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate: Scalars['DateTime']['output'];
  libelle: Scalars['String']['output'];
  partenaire: Array<Partenaire>;
};


export type ActiviteRattachPartenairePartenaireArgs = {
  where?: InputMaybe<PartenaireFilterInput>;
};

export type ActiviteRattachPartenaireFilterInput = {
  and?: InputMaybe<Array<ActiviteRattachPartenaireFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ActiviteRattachPartenaireFilterInput>>;
  partenaire?: InputMaybe<ListFilterInputTypeOfPartenaireFilterInput>;
};

export type ActiviteRattachPartenaireSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type AdhesionConsultant = {
  __typename?: 'AdhesionConsultant';
  adhesionFin?: Maybe<Scalars['DateTime']['output']>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  exerciceCommercial: ExerciceCommercial;
  exerciceCommercialId: Scalars['Int']['output'];
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  modeReponse: Scalars['Byte']['output'];
  modeReponseNavigation: ModeReponseReadhesion;
  reponse: Scalars['Byte']['output'];
  reponseNavigation: ReponseReadhesion;
};

export type AdhesionConsultantFilterInput = {
  adhesionFin?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<AdhesionConsultantFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  exerciceCommercial?: InputMaybe<ExerciceCommercialFilterInput>;
  exerciceCommercialId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  modeReponse?: InputMaybe<ByteOperationFilterInput>;
  modeReponseNavigation?: InputMaybe<ModeReponseReadhesionFilterInput>;
  or?: InputMaybe<Array<AdhesionConsultantFilterInput>>;
  reponse?: InputMaybe<ByteOperationFilterInput>;
  reponseNavigation?: InputMaybe<ReponseReadhesionFilterInput>;
};

export type Adresse = {
  __typename?: 'Adresse';
  adresse1?: Maybe<Scalars['String']['output']>;
  adresse2?: Maybe<Scalars['String']['output']>;
  adresse3?: Maybe<Scalars['String']['output']>;
  arianeId?: Maybe<Scalars['Long']['output']>;
  cedex?: Maybe<Scalars['String']['output']>;
  codePostalCode?: Maybe<Scalars['String']['output']>;
  complement?: Maybe<Scalars['String']['output']>;
  consultant: Array<Consultant>;
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  contact: Array<Contact>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  modeLogement?: Maybe<Scalars['String']['output']>;
  modeLogementNavigation?: Maybe<ModeLogement>;
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Long']['output']>;
  paysCode?: Maybe<Scalars['String']['output']>;
  paysCodeNavigation?: Maybe<Pays>;
  personneMoraleAdresse: Array<PersonneMoraleAdresse>;
  personnePhysique: Array<PersonnePhysique>;
  typeAdresseCode: Scalars['String']['output'];
  typeAdresseCodeNavigation: TypeAdresse;
  villeNom?: Maybe<Scalars['String']['output']>;
};


export type AdresseConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type AdresseConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};


export type AdresseContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type AdressePersonneMoraleAdresseArgs = {
  where?: InputMaybe<PersonneMoraleAdresseFilterInput>;
};


export type AdressePersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type AdresseFilterInput = {
  adresse1?: InputMaybe<StringOperationFilterInput>;
  adresse2?: InputMaybe<StringOperationFilterInput>;
  adresse3?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<AdresseFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  cedex?: InputMaybe<StringOperationFilterInput>;
  codePostalCode?: InputMaybe<StringOperationFilterInput>;
  complement?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  modeLogement?: InputMaybe<StringOperationFilterInput>;
  modeLogementNavigation?: InputMaybe<ModeLogementFilterInput>;
  note?: InputMaybe<NoteFilterInput>;
  noteId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<AdresseFilterInput>>;
  paysCode?: InputMaybe<StringOperationFilterInput>;
  paysCodeNavigation?: InputMaybe<PaysFilterInput>;
  personneMoraleAdresse?: InputMaybe<ListFilterInputTypeOfPersonneMoraleAdresseFilterInput>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  typeAdresseCode?: InputMaybe<StringOperationFilterInput>;
  typeAdresseCodeNavigation?: InputMaybe<TypeAdresseFilterInput>;
  villeNom?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type AllActiveInvestissorsIdsConnection = {
  __typename?: 'AllActiveInvestissorsIdsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllActiveInvestissorsIdsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Scalars['Long']['output']>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type AllActiveInvestissorsIdsEdge = {
  __typename?: 'AllActiveInvestissorsIdsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Scalars['Long']['output'];
};

/** A segment of a collection. */
export type AllFichierOperationsPaginatedCollectionSegment = {
  __typename?: 'AllFichierOperationsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<FichierOperation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type AppartenanceDocument = {
  __typename?: 'AppartenanceDocument';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  gedTypeDocument: Array<GedTypeDocument>;
  libelle: Scalars['String']['output'];
};


export type AppartenanceDocumentGedTypeDocumentArgs = {
  where?: InputMaybe<GedTypeDocumentFilterInput>;
};

export type AppartenanceDocumentFilterInput = {
  and?: InputMaybe<Array<AppartenanceDocumentFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  gedTypeDocument?: InputMaybe<ListFilterInputTypeOfGedTypeDocumentFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AppartenanceDocumentFilterInput>>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AssujettiTva = {
  __typename?: 'AssujettiTVA';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type AssujettiTvaConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};

export type AssujettiTvaFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<AssujettiTvaFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<AssujettiTvaFilterInput>>;
};

export type BackOfficeMember = {
  __typename?: 'BackOfficeMember';
  displayName: Scalars['String']['output'];
  groupeGestionnaire: GroupeGestionnaire;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  notifications: Array<Notification>;
  serviceAllowedEnvelopeIds?: Maybe<Array<Scalars['Int']['output']>>;
  tokenId: Scalars['String']['output'];
  withGroupeAccessExtended: Scalars['Boolean']['output'];
};


export type BackOfficeMemberNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};

export type BackOfficeMemberFilterInput = {
  and?: InputMaybe<Array<BackOfficeMemberFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  groupeGestionnaire?: InputMaybe<GroupeGestionnaireOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isAdmin?: InputMaybe<BooleanOperationFilterInput>;
  notifications?: InputMaybe<ListFilterInputTypeOfNotificationFilterInput>;
  or?: InputMaybe<Array<BackOfficeMemberFilterInput>>;
  tokenId?: InputMaybe<StringOperationFilterInput>;
  withGroupeAccessExtended?: InputMaybe<BooleanOperationFilterInput>;
};

export type BackOfficeMemberSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  groupeGestionnaire?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isAdmin?: InputMaybe<SortEnumType>;
  tokenId?: InputMaybe<SortEnumType>;
  withGroupeAccessExtended?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type BackOfficeMembersPaginatedCollectionSegment = {
  __typename?: 'BackOfficeMembersPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<BackOfficeMember>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum BackOfficeService {
  Psi = 'PSI',
  Svp = 'SVP'
}

export type BackOfficeServiceOperationFilterInput = {
  eq?: InputMaybe<BackOfficeService>;
  in?: InputMaybe<Array<BackOfficeService>>;
  neq?: InputMaybe<BackOfficeService>;
  nin?: InputMaybe<Array<BackOfficeService>>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BoxDocumentType = {
  __typename?: 'BoxDocumentType';
  gedParentTypeCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isParentGedTypeHaveMultipleBoxDocumentTypes: Scalars['Boolean']['output'];
  key: Scalars['String']['output'];
  natureDocuments: Array<NatureDocument>;
  parentGEDType?: Maybe<GedTypeDocument>;
};


export type BoxDocumentTypeNatureDocumentsArgs = {
  where?: InputMaybe<NatureDocumentFilterInput>;
};

export type BoxDocumentTypeFilterInput = {
  and?: InputMaybe<Array<BoxDocumentTypeFilterInput>>;
  gedParentTypeCode?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  key?: InputMaybe<StringOperationFilterInput>;
  natureDocuments?: InputMaybe<ListFilterInputTypeOfNatureDocumentFilterInput>;
  or?: InputMaybe<Array<BoxDocumentTypeFilterInput>>;
};

export type BoxDocumentTypeSortInput = {
  gedParentTypeCode?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  key?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type BoxDocumentTypesPaginatedCollectionSegment = {
  __typename?: 'BoxDocumentTypesPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<BoxDocumentType>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type BoxFile = {
  __typename?: 'BoxFile';
  createdDate?: Maybe<Scalars['DateTime']['output']>;
  denomination?: Maybe<Scalars['String']['output']>;
  envoiPartenaire?: Maybe<EnvoiPartenaire>;
  envoiPartenaireId?: Maybe<Scalars['Int']['output']>;
  fileConnectionInfo?: Maybe<BoxFileConnectionInfo>;
  fileExtension: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  fileNameWithExtension?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastModificationDate?: Maybe<Scalars['DateTime']['output']>;
  operation?: Maybe<Operation>;
  operationId?: Maybe<Scalars['Int']['output']>;
};

export type BoxFileConnectionInfo = {
  __typename?: 'BoxFileConnectionInfo';
  boxFile?: Maybe<BoxFile>;
  boxFileId: Scalars['Int']['output'];
  expiration: Scalars['DateTime']['output'];
  permissions: Array<BoxFilesPermission>;
  sasUrl: Scalars['String']['output'];
};


export type BoxFileConnectionInfoPermissionsArgs = {
  where?: InputMaybe<BoxFilesPermissionOperationFilterInput>;
};

export type BoxFileFilterInput = {
  and?: InputMaybe<Array<BoxFileFilterInput>>;
  createdDate?: InputMaybe<DateTimeOperationFilterInput>;
  denomination?: InputMaybe<StringOperationFilterInput>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireFilterInput>;
  envoiPartenaireId?: InputMaybe<IntOperationFilterInput>;
  fileExtension?: InputMaybe<StringOperationFilterInput>;
  fileId?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  lastModificationDate?: InputMaybe<DateTimeOperationFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<BoxFileFilterInput>>;
};

export type BoxFileSortInput = {
  createdDate?: InputMaybe<SortEnumType>;
  denomination?: InputMaybe<SortEnumType>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireSortInput>;
  envoiPartenaireId?: InputMaybe<SortEnumType>;
  fileExtension?: InputMaybe<SortEnumType>;
  fileId?: InputMaybe<SortEnumType>;
  fileName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  lastModificationDate?: InputMaybe<SortEnumType>;
  operation?: InputMaybe<OperationSortInput>;
  operationId?: InputMaybe<SortEnumType>;
};

export enum BoxFilesPermission {
  Read = 'READ',
  Write = 'WRITE'
}

export type BoxFilesPermissionOperationFilterInput = {
  eq?: InputMaybe<BoxFilesPermission>;
  in?: InputMaybe<Array<BoxFilesPermission>>;
  neq?: InputMaybe<BoxFilesPermission>;
  nin?: InputMaybe<Array<BoxFilesPermission>>;
};

export type ByteOperationFilterInput = {
  eq?: InputMaybe<Scalars['Byte']['input']>;
  gt?: InputMaybe<Scalars['Byte']['input']>;
  gte?: InputMaybe<Scalars['Byte']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  lt?: InputMaybe<Scalars['Byte']['input']>;
  lte?: InputMaybe<Scalars['Byte']['input']>;
  neq?: InputMaybe<Scalars['Byte']['input']>;
  ngt?: InputMaybe<Scalars['Byte']['input']>;
  ngte?: InputMaybe<Scalars['Byte']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Byte']['input']>>>;
  nlt?: InputMaybe<Scalars['Byte']['input']>;
  nlte?: InputMaybe<Scalars['Byte']['input']>;
};

export type CessionHistoCommande = {
  __typename?: 'CessionHistoCommande';
  cessionnaire: Consultant;
  cessionnaireId: Scalars['Long']['output'];
  commande: Commande;
  commandeId: Scalars['Long']['output'];
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  motifCession: MotifCession;
  motifCessionId: Scalars['Byte']['output'];
  statutJuridiqueConsultant?: Maybe<ConsultantStatutJuridique>;
  statutJuridiqueConsultantId?: Maybe<Scalars['Long']['output']>;
};

export type CessionHistoCommandeFilterInput = {
  and?: InputMaybe<Array<CessionHistoCommandeFilterInput>>;
  cessionnaire?: InputMaybe<ConsultantFilterInput>;
  cessionnaireId?: InputMaybe<LongOperationFilterInput>;
  commande?: InputMaybe<CommandeFilterInput>;
  commandeId?: InputMaybe<LongOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  motifCession?: InputMaybe<MotifCessionFilterInput>;
  motifCessionId?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<CessionHistoCommandeFilterInput>>;
  statutJuridiqueConsultant?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  statutJuridiqueConsultantId?: InputMaybe<LongOperationFilterInput>;
};

export type CessionHistoInvestisseur = {
  __typename?: 'CessionHistoInvestisseur';
  cessionnaire: Consultant;
  cessionnaireId: Scalars['Long']['output'];
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  date: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  motifCession: MotifCession;
  motifCessionId: Scalars['Byte']['output'];
};

export type CessionHistoInvestisseurFilterInput = {
  and?: InputMaybe<Array<CessionHistoInvestisseurFilterInput>>;
  cessionnaire?: InputMaybe<ConsultantFilterInput>;
  cessionnaireId?: InputMaybe<LongOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  motifCession?: InputMaybe<MotifCessionFilterInput>;
  motifCessionId?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<CessionHistoInvestisseurFilterInput>>;
};

/** A segment of a collection. */
export type CessionHistoInvestisseurPaginatedCollectionSegment = {
  __typename?: 'CessionHistoInvestisseurPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CessionHistoInvestisseur>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type CessionHistoInvestisseurSortInput = {
  cessionnaire?: InputMaybe<ConsultantSortInput>;
  cessionnaireId?: InputMaybe<SortEnumType>;
  consultant?: InputMaybe<ConsultantSortInput>;
  consultantId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  date?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  motifCession?: InputMaybe<MotifCessionSortInput>;
  motifCessionId?: InputMaybe<SortEnumType>;
};

export type Civilite = {
  __typename?: 'Civilite';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  personnePhysique: Array<PersonnePhysique>;
};


export type CivilitePersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type CiviliteFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<CiviliteFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CiviliteFilterInput>>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
};

export type CiviliteSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type Commande = {
  __typename?: 'Commande';
  achatPersonnel: Scalars['Byte']['output'];
  baseCommissionnement?: Maybe<Scalars['Decimal']['output']>;
  cessionHistoCommande: Array<CessionHistoCommande>;
  conseillerCommande: Array<ConseillerCommande>;
  creationDate: Scalars['DateTime']['output'];
  dateActe?: Maybe<Scalars['DateTime']['output']>;
  dateAnnulation?: Maybe<Scalars['DateTime']['output']>;
  dateSortie?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  etatCommande: Scalars['Byte']['output'];
  etatCommandeNavigation: EtatCommande;
  etatPMR?: Maybe<Scalars['Byte']['output']>;
  etatPMRNavigation?: Maybe<EtatPmrCommande>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  investisseurCommande: Array<InvestisseurCommande>;
  modeSortie?: Maybe<ModeSortieCommande>;
  modeSortieId?: Maybe<Scalars['Byte']['output']>;
  motifAnnulation?: Maybe<MotifAnnulationCommande>;
  motifAnnulationId?: Maybe<Scalars['Byte']['output']>;
  mouvement: Array<Mouvement>;
  nom: Scalars['String']['output'];
  numCommande: Scalars['Long']['output'];
  numeroContrat?: Maybe<Scalars['String']['output']>;
  prixVenteTotalTTC?: Maybe<Scalars['Decimal']['output']>;
  produit: Produit;
  produitId: Scalars['Long']['output'];
  typeAnnulationId?: Maybe<Scalars['Byte']['output']>;
  typeCommande: Scalars['Byte']['output'];
  typeCommandeNavigation: TypeCommande;
};


export type CommandeCessionHistoCommandeArgs = {
  where?: InputMaybe<CessionHistoCommandeFilterInput>;
};


export type CommandeConseillerCommandeArgs = {
  where?: InputMaybe<ConseillerCommandeFilterInput>;
};


export type CommandeInvestisseurCommandeArgs = {
  where?: InputMaybe<InvestisseurCommandeFilterInput>;
};


export type CommandeMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};

export type CommandeFilterInput = {
  achatPersonnel?: InputMaybe<ByteOperationFilterInput>;
  and?: InputMaybe<Array<CommandeFilterInput>>;
  baseCommissionnement?: InputMaybe<DecimalOperationFilterInput>;
  cessionHistoCommande?: InputMaybe<ListFilterInputTypeOfCessionHistoCommandeFilterInput>;
  conseillerCommande?: InputMaybe<ListFilterInputTypeOfConseillerCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateActe?: InputMaybe<DateTimeOperationFilterInput>;
  dateAnnulation?: InputMaybe<DateTimeOperationFilterInput>;
  dateSortie?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  etatCommande?: InputMaybe<ByteOperationFilterInput>;
  etatCommandeNavigation?: InputMaybe<EtatCommandeFilterInput>;
  etatPMR?: InputMaybe<ByteOperationFilterInput>;
  etatPMRNavigation?: InputMaybe<EtatPmrCommandeFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  investisseurCommande?: InputMaybe<ListFilterInputTypeOfInvestisseurCommandeFilterInput>;
  modeSortie?: InputMaybe<ModeSortieCommandeFilterInput>;
  modeSortieId?: InputMaybe<ByteOperationFilterInput>;
  motifAnnulation?: InputMaybe<MotifAnnulationCommandeFilterInput>;
  motifAnnulationId?: InputMaybe<ByteOperationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  numCommande?: InputMaybe<LongOperationFilterInput>;
  numeroContrat?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CommandeFilterInput>>;
  prixVenteTotalTTC?: InputMaybe<DecimalOperationFilterInput>;
  produit?: InputMaybe<ProduitFilterInput>;
  produitId?: InputMaybe<LongOperationFilterInput>;
  typeAnnulationId?: InputMaybe<ByteOperationFilterInput>;
  typeCommande?: InputMaybe<ByteOperationFilterInput>;
  typeCommandeNavigation?: InputMaybe<TypeCommandeFilterInput>;
};

export type CommandeSortInput = {
  achatPersonnel?: InputMaybe<SortEnumType>;
  baseCommissionnement?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateActe?: InputMaybe<SortEnumType>;
  dateAnnulation?: InputMaybe<SortEnumType>;
  dateSortie?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  etatCommande?: InputMaybe<SortEnumType>;
  etatCommandeNavigation?: InputMaybe<EtatCommandeSortInput>;
  etatPMR?: InputMaybe<SortEnumType>;
  etatPMRNavigation?: InputMaybe<EtatPmrCommandeSortInput>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  modeSortie?: InputMaybe<ModeSortieCommandeSortInput>;
  modeSortieId?: InputMaybe<SortEnumType>;
  motifAnnulation?: InputMaybe<MotifAnnulationCommandeSortInput>;
  motifAnnulationId?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
  numCommande?: InputMaybe<SortEnumType>;
  numeroContrat?: InputMaybe<SortEnumType>;
  prixVenteTotalTTC?: InputMaybe<SortEnumType>;
  produit?: InputMaybe<ProduitSortInput>;
  produitId?: InputMaybe<SortEnumType>;
  typeAnnulationId?: InputMaybe<SortEnumType>;
  typeCommande?: InputMaybe<SortEnumType>;
  typeCommandeNavigation?: InputMaybe<TypeCommandeSortInput>;
};

/** A segment of a collection. */
export type CommandesFinancieresPaginatedCollectionSegment = {
  __typename?: 'CommandesFinancieresPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Commande>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type CommandesPaginatedCollectionSegment = {
  __typename?: 'CommandesPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Commande>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConseillerCommande = {
  __typename?: 'ConseillerCommande';
  commande: Commande;
  commandeId: Scalars['Long']['output'];
  conseiller: Consultant;
  conseillerId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  typeConseillerCommande: Scalars['Byte']['output'];
  typeConseillerCommandeNavigation: TypeConseillerCommande;
};

export type ConseillerCommandeFilterInput = {
  and?: InputMaybe<Array<ConseillerCommandeFilterInput>>;
  commande?: InputMaybe<CommandeFilterInput>;
  commandeId?: InputMaybe<LongOperationFilterInput>;
  conseiller?: InputMaybe<ConsultantFilterInput>;
  conseillerId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ConseillerCommandeFilterInput>>;
  typeConseillerCommande?: InputMaybe<ByteOperationFilterInput>;
  typeConseillerCommandeNavigation?: InputMaybe<TypeConseillerCommandeFilterInput>;
};

export type Consultant = {
  __typename?: 'Consultant';
  abonnementConsultant: Array<AbonnementConsultant>;
  adhesionConsultant: Array<AdhesionConsultant>;
  adresse: Array<Adresse>;
  affiliationDate?: Maybe<Scalars['DateTime']['output']>;
  arianeId?: Maybe<Scalars['Long']['output']>;
  cessionHistoCommandeCessionnaire: Array<CessionHistoCommande>;
  cessionHistoCommandeConsultant: Array<CessionHistoCommande>;
  cessionHistoInvestisseurCessionnaire: Array<CessionHistoInvestisseur>;
  cessionHistoInvestisseurConsultant: Array<CessionHistoInvestisseur>;
  code: Scalars['String']['output'];
  codeTier?: Maybe<Scalars['String']['output']>;
  commentairePeriodeJunior?: Maybe<Scalars['String']['output']>;
  conseillerCommande: Array<ConseillerCommande>;
  consultantDistinction: Array<ConsultantDistinction>;
  consultantHabilitations: Array<Maybe<ConsultantHabilitation>>;
  consultantHabilitationsFictives: Array<ConsultantHabilitationsFictives>;
  consultantMouvement: Array<ConsultantMouvement>;
  consultantOrganisationConstellation: Array<ConsultantOrganisation>;
  consultantOrganisationConsultant?: Maybe<ConsultantOrganisation>;
  consultantOrganisationEtoile: Array<ConsultantOrganisation>;
  consultantOrganisationGalaxie: Array<ConsultantOrganisation>;
  consultantOrganisationManager: Array<ConsultantOrganisation>;
  consultantOrganisationPlanete: Array<ConsultantOrganisation>;
  consultantRcp: Array<ConsultantRcp>;
  consultantStatutCommercial: Array<ConsultantStatutCommercial>;
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  date1AcquisitionDroit?: Maybe<Scalars['DateTime']['output']>;
  date1Manager?: Maybe<Scalars['DateTime']['output']>;
  date1VenteActee?: Maybe<Scalars['DateTime']['output']>;
  date1VenteFinancement?: Maybe<Scalars['DateTime']['output']>;
  date1VenteImmo?: Maybe<Scalars['DateTime']['output']>;
  date1VentePlacement?: Maybe<Scalars['DateTime']['output']>;
  dateAcquisitionDroit?: Maybe<Scalars['DateTime']['output']>;
  dateAffiliationProdemial?: Maybe<Scalars['DateTime']['output']>;
  dateContact?: Maybe<Scalars['DateTime']['output']>;
  dateDemission?: Maybe<Scalars['DateTime']['output']>;
  dateEntree?: Maybe<Scalars['DateTime']['output']>;
  dateFinPeriodeJunior?: Maybe<Scalars['DateTime']['output']>;
  dateSeminairePro?: Maybe<Scalars['DateTime']['output']>;
  derniereDateExclusif?: Maybe<Scalars['DateTime']['output']>;
  derniereDateNonExclusif?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  edition: Array<Edition>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  eligilibleRenouvellement: Scalars['Boolean']['output'];
  email: Array<Email>;
  equivalenceDeFormationConsultant: Array<EquivalenceDeFormation>;
  equivalenceDeFormationConsultantFormateur: Array<EquivalenceDeFormation>;
  exclusif?: Maybe<Scalars['Boolean']['output']>;
  facture: Array<Facture>;
  galaxy?: Maybe<Consultant>;
  galaxyId?: Maybe<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  inscriptionSession: Array<InscriptionSession>;
  inverseGalaxy: Array<Consultant>;
  inverseManager: Array<Consultant>;
  inverseParrain: Array<Consultant>;
  inverseTeteReseau: Array<Consultant>;
  investisseurConsultant: Array<InvestisseurConsultant>;
  manager?: Maybe<Consultant>;
  managerId?: Maybe<Scalars['Long']['output']>;
  mandatAutofacFinancement?: Maybe<Scalars['Byte']['output']>;
  mandatAutofacPlacement?: Maybe<Scalars['Byte']['output']>;
  mandatAutofacTotal?: Maybe<Scalars['Byte']['output']>;
  motifResiliationCode?: Maybe<Scalars['String']['output']>;
  motifResiliationCodeNavigation?: Maybe<MotifResiliationConsultant>;
  nbAmbassadeurs?: Maybe<Scalars['Byte']['output']>;
  nbRecommandations?: Maybe<Scalars['Byte']['output']>;
  parrain?: Maybe<Consultant>;
  parrainId?: Maybe<Scalars['Long']['output']>;
  personnePhysique: Array<PersonnePhysique>;
  peutParainner?: Maybe<Scalars['Boolean']['output']>;
  qualificationCode?: Maybe<Scalars['String']['output']>;
  qualificationCodeNavigation?: Maybe<QualificationConsultant>;
  raisonSociale?: Maybe<Scalars['String']['output']>;
  resiliationDate?: Maybe<Scalars['DateTime']['output']>;
  resiliationPaiementB2S: Array<ResiliationPaiementB2S>;
  statutCode: Scalars['String']['output'];
  statutCodeNavigation: StatutConsultant;
  tauxRemuneration?: Maybe<Scalars['Decimal']['output']>;
  telephone: Array<Telephone>;
  teteReseau?: Maybe<Consultant>;
  teteReseauId?: Maybe<Scalars['Long']['output']>;
  titreCode?: Maybe<Scalars['String']['output']>;
  titreCodeNavigation?: Maybe<TitreConsultant>;
  totalDebours?: Maybe<Scalars['Decimal']['output']>;
};


export type ConsultantAbonnementConsultantArgs = {
  where?: InputMaybe<AbonnementConsultantFilterInput>;
};


export type ConsultantAdhesionConsultantArgs = {
  where?: InputMaybe<AdhesionConsultantFilterInput>;
};


export type ConsultantAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};


export type ConsultantCessionHistoCommandeCessionnaireArgs = {
  where?: InputMaybe<CessionHistoCommandeFilterInput>;
};


export type ConsultantCessionHistoCommandeConsultantArgs = {
  where?: InputMaybe<CessionHistoCommandeFilterInput>;
};


export type ConsultantCessionHistoInvestisseurCessionnaireArgs = {
  where?: InputMaybe<CessionHistoInvestisseurFilterInput>;
};


export type ConsultantCessionHistoInvestisseurConsultantArgs = {
  where?: InputMaybe<CessionHistoInvestisseurFilterInput>;
};


export type ConsultantConseillerCommandeArgs = {
  where?: InputMaybe<ConseillerCommandeFilterInput>;
};


export type ConsultantConsultantDistinctionArgs = {
  where?: InputMaybe<ConsultantDistinctionFilterInput>;
};


export type ConsultantConsultantHabilitationsFictivesArgs = {
  where?: InputMaybe<ConsultantHabilitationsFictivesFilterInput>;
};


export type ConsultantConsultantMouvementArgs = {
  where?: InputMaybe<ConsultantMouvementFilterInput>;
};


export type ConsultantConsultantOrganisationConstellationArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};


export type ConsultantConsultantOrganisationEtoileArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};


export type ConsultantConsultantOrganisationGalaxieArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};


export type ConsultantConsultantOrganisationManagerArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};


export type ConsultantConsultantOrganisationPlaneteArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};


export type ConsultantConsultantRcpArgs = {
  where?: InputMaybe<ConsultantRcpFilterInput>;
};


export type ConsultantConsultantStatutCommercialArgs = {
  where?: InputMaybe<ConsultantStatutCommercialFilterInput>;
};


export type ConsultantConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};


export type ConsultantEditionArgs = {
  where?: InputMaybe<EditionFilterInput>;
};


export type ConsultantEmailArgs = {
  where?: InputMaybe<EmailFilterInput>;
};


export type ConsultantEquivalenceDeFormationConsultantArgs = {
  where?: InputMaybe<EquivalenceDeFormationFilterInput>;
};


export type ConsultantEquivalenceDeFormationConsultantFormateurArgs = {
  where?: InputMaybe<EquivalenceDeFormationFilterInput>;
};


export type ConsultantFactureArgs = {
  where?: InputMaybe<FactureFilterInput>;
};


export type ConsultantInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};


export type ConsultantInscriptionSessionArgs = {
  where?: InputMaybe<InscriptionSessionFilterInput>;
};


export type ConsultantInverseGalaxyArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type ConsultantInverseManagerArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type ConsultantInverseParrainArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type ConsultantInverseTeteReseauArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type ConsultantInvestisseurConsultantArgs = {
  where?: InputMaybe<InvestisseurConsultantFilterInput>;
};


export type ConsultantPersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};


export type ConsultantResiliationPaiementB2SArgs = {
  where?: InputMaybe<ResiliationPaiementB2SFilterInput>;
};


export type ConsultantTelephoneArgs = {
  where?: InputMaybe<TelephoneFilterInput>;
};

export type ConsultantContrat = {
  __typename?: 'ConsultantContrat';
  consultantStatutJuridique: ConsultantStatutJuridique;
  consultantStatutJuridiqueId: Scalars['Long']['output'];
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateContrat?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  pieceJointe: Array<PieceJointeConsultant>;
  typeContratCode: Scalars['String']['output'];
  typeContratCodeNavigation: TypeContrat;
};


export type ConsultantContratPieceJointeArgs = {
  where?: InputMaybe<PieceJointeConsultantFilterInput>;
};

export type ConsultantContratFilterInput = {
  and?: InputMaybe<Array<ConsultantContratFilterInput>>;
  consultantStatutJuridique?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  consultantStatutJuridiqueId?: InputMaybe<LongOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateContrat?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantContratFilterInput>>;
  pieceJointe?: InputMaybe<ListFilterInputTypeOfPieceJointeConsultantFilterInput>;
  typeContratCode?: InputMaybe<StringOperationFilterInput>;
  typeContratCodeNavigation?: InputMaybe<TypeContratFilterInput>;
};

export type ConsultantDistinction = {
  __typename?: 'ConsultantDistinction';
  categorie: DistinctionCategorie;
  categorieId: Scalars['Byte']['output'];
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateApparitionDistinction: Scalars['DateTime']['output'];
  dateCalculDistinction: Scalars['DateTime']['output'];
  dateDistinction: Scalars['DateTime']['output'];
  distinction: Scalars['Byte']['output'];
  distinctionNavigation: Distinction;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  exercicePMR: Scalars['Int']['output'];
  exercicePMRNavigation: ExerciceCommercial;
  id: Scalars['Long']['output'];
  nombreEtoile: Scalars['Byte']['output'];
  statutDistinction: StatutDistinction;
  statutDistinctionId: Scalars['Byte']['output'];
};

export type ConsultantDistinctionFilterInput = {
  and?: InputMaybe<Array<ConsultantDistinctionFilterInput>>;
  categorie?: InputMaybe<DistinctionCategorieFilterInput>;
  categorieId?: InputMaybe<ByteOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateApparitionDistinction?: InputMaybe<DateTimeOperationFilterInput>;
  dateCalculDistinction?: InputMaybe<DateTimeOperationFilterInput>;
  dateDistinction?: InputMaybe<DateTimeOperationFilterInput>;
  distinction?: InputMaybe<ByteOperationFilterInput>;
  distinctionNavigation?: InputMaybe<DistinctionFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  exercicePMR?: InputMaybe<IntOperationFilterInput>;
  exercicePMRNavigation?: InputMaybe<ExerciceCommercialFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  nombreEtoile?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantDistinctionFilterInput>>;
  statutDistinction?: InputMaybe<StatutDistinctionFilterInput>;
  statutDistinctionId?: InputMaybe<ByteOperationFilterInput>;
};

export type ConsultantFilterInput = {
  abonnementConsultant?: InputMaybe<ListFilterInputTypeOfAbonnementConsultantFilterInput>;
  adhesionConsultant?: InputMaybe<ListFilterInputTypeOfAdhesionConsultantFilterInput>;
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  affiliationDate?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<ConsultantFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  cessionHistoCommandeCessionnaire?: InputMaybe<ListFilterInputTypeOfCessionHistoCommandeFilterInput>;
  cessionHistoCommandeConsultant?: InputMaybe<ListFilterInputTypeOfCessionHistoCommandeFilterInput>;
  cessionHistoInvestisseurCessionnaire?: InputMaybe<ListFilterInputTypeOfCessionHistoInvestisseurFilterInput>;
  cessionHistoInvestisseurConsultant?: InputMaybe<ListFilterInputTypeOfCessionHistoInvestisseurFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  codeTier?: InputMaybe<StringOperationFilterInput>;
  commentairePeriodeJunior?: InputMaybe<StringOperationFilterInput>;
  conseillerCommande?: InputMaybe<ListFilterInputTypeOfConseillerCommandeFilterInput>;
  consultantDistinction?: InputMaybe<ListFilterInputTypeOfConsultantDistinctionFilterInput>;
  consultantHabilitationsFictives?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationsFictivesFilterInput>;
  consultantMouvement?: InputMaybe<ListFilterInputTypeOfConsultantMouvementFilterInput>;
  consultantOrganisationConstellation?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  consultantOrganisationConsultant?: InputMaybe<ConsultantOrganisationFilterInput>;
  consultantOrganisationEtoile?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  consultantOrganisationGalaxie?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  consultantOrganisationManager?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  consultantOrganisationPlanete?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  consultantRcp?: InputMaybe<ListFilterInputTypeOfConsultantRcpFilterInput>;
  consultantStatutCommercial?: InputMaybe<ListFilterInputTypeOfConsultantStatutCommercialFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  date1AcquisitionDroit?: InputMaybe<DateTimeOperationFilterInput>;
  date1Manager?: InputMaybe<DateTimeOperationFilterInput>;
  date1VenteActee?: InputMaybe<DateTimeOperationFilterInput>;
  date1VenteFinancement?: InputMaybe<DateTimeOperationFilterInput>;
  date1VenteImmo?: InputMaybe<DateTimeOperationFilterInput>;
  date1VentePlacement?: InputMaybe<DateTimeOperationFilterInput>;
  dateAcquisitionDroit?: InputMaybe<DateTimeOperationFilterInput>;
  dateAffiliationProdemial?: InputMaybe<DateTimeOperationFilterInput>;
  dateContact?: InputMaybe<DateTimeOperationFilterInput>;
  dateDemission?: InputMaybe<DateTimeOperationFilterInput>;
  dateEntree?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinPeriodeJunior?: InputMaybe<DateTimeOperationFilterInput>;
  dateSeminairePro?: InputMaybe<DateTimeOperationFilterInput>;
  derniereDateExclusif?: InputMaybe<DateTimeOperationFilterInput>;
  derniereDateNonExclusif?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  edition?: InputMaybe<ListFilterInputTypeOfEditionFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  eligilibleRenouvellement?: InputMaybe<BooleanOperationFilterInput>;
  email?: InputMaybe<ListFilterInputTypeOfEmailFilterInput>;
  equivalenceDeFormationConsultant?: InputMaybe<ListFilterInputTypeOfEquivalenceDeFormationFilterInput>;
  equivalenceDeFormationConsultantFormateur?: InputMaybe<ListFilterInputTypeOfEquivalenceDeFormationFilterInput>;
  exclusif?: InputMaybe<BooleanOperationFilterInput>;
  facture?: InputMaybe<ListFilterInputTypeOfFactureFilterInput>;
  galaxy?: InputMaybe<ConsultantFilterInput>;
  galaxyId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  inscriptionSession?: InputMaybe<ListFilterInputTypeOfInscriptionSessionFilterInput>;
  inverseGalaxy?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  inverseManager?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  inverseParrain?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  inverseTeteReseau?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  investisseurConsultant?: InputMaybe<ListFilterInputTypeOfInvestisseurConsultantFilterInput>;
  manager?: InputMaybe<ConsultantFilterInput>;
  managerId?: InputMaybe<LongOperationFilterInput>;
  mandatAutofacFinancement?: InputMaybe<ByteOperationFilterInput>;
  mandatAutofacPlacement?: InputMaybe<ByteOperationFilterInput>;
  mandatAutofacTotal?: InputMaybe<ByteOperationFilterInput>;
  motifResiliationCode?: InputMaybe<StringOperationFilterInput>;
  motifResiliationCodeNavigation?: InputMaybe<MotifResiliationConsultantFilterInput>;
  nbAmbassadeurs?: InputMaybe<ByteOperationFilterInput>;
  nbRecommandations?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantFilterInput>>;
  parrain?: InputMaybe<ConsultantFilterInput>;
  parrainId?: InputMaybe<LongOperationFilterInput>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  peutParainner?: InputMaybe<BooleanOperationFilterInput>;
  qualificationCode?: InputMaybe<StringOperationFilterInput>;
  qualificationCodeNavigation?: InputMaybe<QualificationConsultantFilterInput>;
  raisonSociale?: InputMaybe<StringOperationFilterInput>;
  resiliationDate?: InputMaybe<DateTimeOperationFilterInput>;
  resiliationPaiementB2S?: InputMaybe<ListFilterInputTypeOfResiliationPaiementB2SFilterInput>;
  statutCode?: InputMaybe<StringOperationFilterInput>;
  statutCodeNavigation?: InputMaybe<StatutConsultantFilterInput>;
  tauxRemuneration?: InputMaybe<DecimalOperationFilterInput>;
  telephone?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
  teteReseau?: InputMaybe<ConsultantFilterInput>;
  teteReseauId?: InputMaybe<LongOperationFilterInput>;
  titreCode?: InputMaybe<StringOperationFilterInput>;
  titreCodeNavigation?: InputMaybe<TitreConsultantFilterInput>;
  totalDebours?: InputMaybe<DecimalOperationFilterInput>;
};

export type ConsultantHabilitation = {
  __typename?: 'ConsultantHabilitation';
  associationProNom?: Maybe<Scalars['String']['output']>;
  cifPartenaireCode?: Maybe<Scalars['String']['output']>;
  codeHabilitation?: Maybe<Scalars['String']['output']>;
  consultantHabilitationInscription: Array<ConsultantHabilitationInscription>;
  consultantId?: Maybe<Scalars['Long']['output']>;
  consultantStatutJuridique?: Maybe<ConsultantStatutJuridique>;
  consultantStatutJuridiqueId?: Maybe<Scalars['Long']['output']>;
  creationDate: Scalars['DateTime']['output'];
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  datePremiereHabilitation?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  garantAdresse?: Maybe<Scalars['String']['output']>;
  garantNom?: Maybe<Scalars['String']['output']>;
  habilitation?: Maybe<Habilitation>;
  habilitationId?: Maybe<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  mediateur?: Maybe<Scalars['String']['output']>;
  mediateurAssu?: Maybe<Scalars['String']['output']>;
  mediateurCif?: Maybe<Scalars['String']['output']>;
  mediateurIOB?: Maybe<Scalars['String']['output']>;
  mediateurImmo?: Maybe<Scalars['String']['output']>;
  partenaireCourtierFinancement1?: Maybe<Scalars['String']['output']>;
  partenaireCourtierFinancement2?: Maybe<Scalars['String']['output']>;
  statut?: Maybe<Scalars['Int']['output']>;
};


export type ConsultantHabilitationConsultantHabilitationInscriptionArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
};

export type ConsultantHabilitationFilterInput = {
  and?: InputMaybe<Array<ConsultantHabilitationFilterInput>>;
  associationProNom?: InputMaybe<StringOperationFilterInput>;
  cifPartenaireCode?: InputMaybe<StringOperationFilterInput>;
  codeHabilitation?: InputMaybe<StringOperationFilterInput>;
  consultantHabilitationInscription?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  consultantStatutJuridiqueId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  datePremiereHabilitation?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  garantAdresse?: InputMaybe<StringOperationFilterInput>;
  garantNom?: InputMaybe<StringOperationFilterInput>;
  habilitation?: InputMaybe<HabilitationFilterInput>;
  habilitationId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  mediateur?: InputMaybe<StringOperationFilterInput>;
  mediateurAssu?: InputMaybe<StringOperationFilterInput>;
  mediateurCif?: InputMaybe<StringOperationFilterInput>;
  mediateurIOB?: InputMaybe<StringOperationFilterInput>;
  mediateurImmo?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantHabilitationFilterInput>>;
  partenaireCourtierFinancement1?: InputMaybe<StringOperationFilterInput>;
  partenaireCourtierFinancement2?: InputMaybe<StringOperationFilterInput>;
  statut?: InputMaybe<IntOperationFilterInput>;
};

export type ConsultantHabilitationInscription = {
  __typename?: 'ConsultantHabilitationInscription';
  carteTStellium?: Maybe<Scalars['Boolean']['output']>;
  consultantHabilitation?: Maybe<ConsultantHabilitation>;
  consultantHabilitationId?: Maybe<Scalars['Long']['output']>;
  consultantHabilitationInscriptionCategorie: Array<ConsultantHabilitationInscriptionCategorie>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateDepot?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  descriptionInscription?: Maybe<Scalars['String']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idHabConsInsc?: Maybe<Scalars['Long']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  libelleInscription?: Maybe<Scalars['String']['output']>;
  numeroCarteTConsultant?: Maybe<Scalars['String']['output']>;
  numeroInscription?: Maybe<Scalars['String']['output']>;
  orgCIF?: Maybe<Scalars['String']['output']>;
  organismeCode?: Maybe<Scalars['String']['output']>;
  organismeCodeNavigation?: Maybe<Organisme>;
  pieceJointeConsultant: Array<PieceJointeConsultant>;
};


export type ConsultantHabilitationInscriptionConsultantHabilitationInscriptionCategorieArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
};


export type ConsultantHabilitationInscriptionPieceJointeConsultantArgs = {
  where?: InputMaybe<PieceJointeConsultantFilterInput>;
};

export type ConsultantHabilitationInscriptionCategorie = {
  __typename?: 'ConsultantHabilitationInscriptionCategorie';
  consultantHabilitationInscription: ConsultantHabilitationInscription;
  consultantHabilitationInscriptionCategorieMandant: Array<ConsultantHabilitationInscriptionCategorieMandant>;
  consultantHabilitationInscriptionId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  habilitationInscriptionCategorie: HabilitationInscriptionCategorie;
  habilitationInscriptionCategorieId: Scalars['Int']['output'];
  id: Scalars['Long']['output'];
  rn_Create_User?: Maybe<Scalars['Long']['output']>;
  rn_Edit_User?: Maybe<Scalars['Long']['output']>;
};


export type ConsultantHabilitationInscriptionCategorieConsultantHabilitationInscriptionCategorieMandantArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionCategorieMandantFilterInput>;
};

export type ConsultantHabilitationInscriptionCategorieFilterInput = {
  and?: InputMaybe<Array<ConsultantHabilitationInscriptionCategorieFilterInput>>;
  consultantHabilitationInscription?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
  consultantHabilitationInscriptionCategorieMandant?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionCategorieMandantFilterInput>;
  consultantHabilitationInscriptionId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  habilitationInscriptionCategorie?: InputMaybe<HabilitationInscriptionCategorieFilterInput>;
  habilitationInscriptionCategorieId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantHabilitationInscriptionCategorieFilterInput>>;
  rn_Create_User?: InputMaybe<LongOperationFilterInput>;
  rn_Edit_User?: InputMaybe<LongOperationFilterInput>;
};

export type ConsultantHabilitationInscriptionCategorieMandant = {
  __typename?: 'ConsultantHabilitationInscriptionCategorieMandant';
  consultantHabilitationInscriptionCategorie?: Maybe<ConsultantHabilitationInscriptionCategorie>;
  consultantHabilitationInscriptionCategorieId?: Maybe<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  mandant?: Maybe<Scalars['String']['output']>;
};

export type ConsultantHabilitationInscriptionCategorieMandantFilterInput = {
  and?: InputMaybe<Array<ConsultantHabilitationInscriptionCategorieMandantFilterInput>>;
  consultantHabilitationInscriptionCategorie?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
  consultantHabilitationInscriptionCategorieId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  mandant?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantHabilitationInscriptionCategorieMandantFilterInput>>;
};

export type ConsultantHabilitationInscriptionFilterInput = {
  and?: InputMaybe<Array<ConsultantHabilitationInscriptionFilterInput>>;
  carteTStellium?: InputMaybe<BooleanOperationFilterInput>;
  consultantHabilitation?: InputMaybe<ConsultantHabilitationFilterInput>;
  consultantHabilitationId?: InputMaybe<LongOperationFilterInput>;
  consultantHabilitationInscriptionCategorie?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionCategorieFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateDepot?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  descriptionInscription?: InputMaybe<StringOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idHabConsInsc?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  libelleInscription?: InputMaybe<StringOperationFilterInput>;
  numeroCarteTConsultant?: InputMaybe<StringOperationFilterInput>;
  numeroInscription?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantHabilitationInscriptionFilterInput>>;
  orgCIF?: InputMaybe<StringOperationFilterInput>;
  organismeCode?: InputMaybe<StringOperationFilterInput>;
  organismeCodeNavigation?: InputMaybe<OrganismeFilterInput>;
  pieceJointeConsultant?: InputMaybe<ListFilterInputTypeOfPieceJointeConsultantFilterInput>;
};

export type ConsultantHabilitationsFictives = {
  __typename?: 'ConsultantHabilitationsFictives';
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  habilitation: Scalars['String']['output'];
  id: Scalars['Long']['output'];
};

export type ConsultantHabilitationsFictivesFilterInput = {
  and?: InputMaybe<Array<ConsultantHabilitationsFictivesFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  habilitation?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantHabilitationsFictivesFilterInput>>;
};

export type ConsultantMouvement = {
  __typename?: 'ConsultantMouvement';
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  mouvement: Mouvement;
  mouvementId: Scalars['Long']['output'];
  roleConsultantMouvement: Scalars['Byte']['output'];
  roleConsultantMouvementNavigation: TypeConseillerCommande;
};

export type ConsultantMouvementFilterInput = {
  and?: InputMaybe<Array<ConsultantMouvementFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  mouvement?: InputMaybe<MouvementFilterInput>;
  mouvementId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantMouvementFilterInput>>;
  roleConsultantMouvement?: InputMaybe<ByteOperationFilterInput>;
  roleConsultantMouvementNavigation?: InputMaybe<TypeConseillerCommandeFilterInput>;
};

export type ConsultantOrganisation = {
  __typename?: 'ConsultantOrganisation';
  constellation?: Maybe<Consultant>;
  constellationId?: Maybe<Scalars['Long']['output']>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  etoile?: Maybe<Consultant>;
  etoileId?: Maybe<Scalars['Long']['output']>;
  galaxie?: Maybe<Consultant>;
  galaxieId?: Maybe<Scalars['Long']['output']>;
  manager?: Maybe<Consultant>;
  managerId?: Maybe<Scalars['Long']['output']>;
  nbMembresOrgan4Nvx?: Maybe<Scalars['Int']['output']>;
  nbMembresOrgan8Nvx?: Maybe<Scalars['Int']['output']>;
  planete?: Maybe<Consultant>;
  planeteId?: Maybe<Scalars['Long']['output']>;
  qualification?: Maybe<QualificationConsultant>;
  qualificationId?: Maybe<Scalars['String']['output']>;
  tauxRemuneration?: Maybe<TauxRemunerationConsultant>;
  tauxRemunerationId?: Maybe<Scalars['Int']['output']>;
  titre?: Maybe<TitreConsultant>;
  titreId?: Maybe<Scalars['String']['output']>;
};

export type ConsultantOrganisationFilterInput = {
  and?: InputMaybe<Array<ConsultantOrganisationFilterInput>>;
  constellation?: InputMaybe<ConsultantFilterInput>;
  constellationId?: InputMaybe<LongOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  etoile?: InputMaybe<ConsultantFilterInput>;
  etoileId?: InputMaybe<LongOperationFilterInput>;
  galaxie?: InputMaybe<ConsultantFilterInput>;
  galaxieId?: InputMaybe<LongOperationFilterInput>;
  manager?: InputMaybe<ConsultantFilterInput>;
  managerId?: InputMaybe<LongOperationFilterInput>;
  nbMembresOrgan4Nvx?: InputMaybe<IntOperationFilterInput>;
  nbMembresOrgan8Nvx?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantOrganisationFilterInput>>;
  planete?: InputMaybe<ConsultantFilterInput>;
  planeteId?: InputMaybe<LongOperationFilterInput>;
  qualification?: InputMaybe<QualificationConsultantFilterInput>;
  qualificationId?: InputMaybe<StringOperationFilterInput>;
  tauxRemuneration?: InputMaybe<TauxRemunerationConsultantFilterInput>;
  tauxRemunerationId?: InputMaybe<IntOperationFilterInput>;
  titre?: InputMaybe<TitreConsultantFilterInput>;
  titreId?: InputMaybe<StringOperationFilterInput>;
};

export type ConsultantOrganisationSortInput = {
  constellation?: InputMaybe<ConsultantSortInput>;
  constellationId?: InputMaybe<SortEnumType>;
  consultant?: InputMaybe<ConsultantSortInput>;
  consultantId?: InputMaybe<SortEnumType>;
  etoile?: InputMaybe<ConsultantSortInput>;
  etoileId?: InputMaybe<SortEnumType>;
  galaxie?: InputMaybe<ConsultantSortInput>;
  galaxieId?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<ConsultantSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  nbMembresOrgan4Nvx?: InputMaybe<SortEnumType>;
  nbMembresOrgan8Nvx?: InputMaybe<SortEnumType>;
  planete?: InputMaybe<ConsultantSortInput>;
  planeteId?: InputMaybe<SortEnumType>;
  qualification?: InputMaybe<QualificationConsultantSortInput>;
  qualificationId?: InputMaybe<SortEnumType>;
  tauxRemuneration?: InputMaybe<TauxRemunerationConsultantSortInput>;
  tauxRemunerationId?: InputMaybe<SortEnumType>;
  titre?: InputMaybe<TitreConsultantSortInput>;
  titreId?: InputMaybe<SortEnumType>;
};

export type ConsultantParametersConfig = {
  __typename?: 'ConsultantParametersConfig';
  code: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  notifyInstance: Scalars['Boolean']['output'];
  notifyNC: Scalars['Boolean']['output'];
  notifyOriginauxReceived: Scalars['Boolean']['output'];
  notifyTransferPartenaire: Scalars['Boolean']['output'];
};

export type ConsultantParametersConfigInput = {
  notifyInstance: Scalars['Boolean']['input'];
  notifyNC: Scalars['Boolean']['input'];
  notifyOriginauxReceived: Scalars['Boolean']['input'];
  notifyTransferPartenaire: Scalars['Boolean']['input'];
};

export type ConsultantRcp = {
  __typename?: 'ConsultantRcp';
  assureurRcpAdresse?: Maybe<Scalars['String']['output']>;
  assureurRcpNom?: Maybe<Scalars['String']['output']>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  consultantStatutJuridique?: Maybe<ConsultantStatutJuridique>;
  consultantStatutJuridiqueId?: Maybe<Scalars['Long']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateAnnulation?: Maybe<Scalars['DateTime']['output']>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  garantieRcpMontantParSinistre: Scalars['Decimal']['output'];
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  motifAnnulationId?: Maybe<Scalars['String']['output']>;
  organismeAssureur?: Maybe<OrganismeAssureurRcp>;
  organismeAssureurId?: Maybe<Scalars['Long']['output']>;
  typeRcpCode?: Maybe<Scalars['String']['output']>;
  typeRcpCodeNavigation?: Maybe<TypeRcp>;
};

export type ConsultantRcpFilterInput = {
  and?: InputMaybe<Array<ConsultantRcpFilterInput>>;
  assureurRcpAdresse?: InputMaybe<StringOperationFilterInput>;
  assureurRcpNom?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  consultantStatutJuridiqueId?: InputMaybe<LongOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateAnnulation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  garantieRcpMontantParSinistre?: InputMaybe<DecimalOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  motifAnnulationId?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantRcpFilterInput>>;
  organismeAssureur?: InputMaybe<OrganismeAssureurRcpFilterInput>;
  organismeAssureurId?: InputMaybe<LongOperationFilterInput>;
  typeRcpCode?: InputMaybe<StringOperationFilterInput>;
  typeRcpCodeNavigation?: InputMaybe<TypeRcpFilterInput>;
};

export type ConsultantSortInput = {
  affiliationDate?: InputMaybe<SortEnumType>;
  arianeId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  codeTier?: InputMaybe<SortEnumType>;
  commentairePeriodeJunior?: InputMaybe<SortEnumType>;
  consultantOrganisationConsultant?: InputMaybe<ConsultantOrganisationSortInput>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  date1AcquisitionDroit?: InputMaybe<SortEnumType>;
  date1Manager?: InputMaybe<SortEnumType>;
  date1VenteActee?: InputMaybe<SortEnumType>;
  date1VenteFinancement?: InputMaybe<SortEnumType>;
  date1VenteImmo?: InputMaybe<SortEnumType>;
  date1VentePlacement?: InputMaybe<SortEnumType>;
  dateAcquisitionDroit?: InputMaybe<SortEnumType>;
  dateAffiliationProdemial?: InputMaybe<SortEnumType>;
  dateContact?: InputMaybe<SortEnumType>;
  dateDemission?: InputMaybe<SortEnumType>;
  dateEntree?: InputMaybe<SortEnumType>;
  dateFinPeriodeJunior?: InputMaybe<SortEnumType>;
  dateSeminairePro?: InputMaybe<SortEnumType>;
  derniereDateExclusif?: InputMaybe<SortEnumType>;
  derniereDateNonExclusif?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  eligilibleRenouvellement?: InputMaybe<SortEnumType>;
  exclusif?: InputMaybe<SortEnumType>;
  galaxy?: InputMaybe<ConsultantSortInput>;
  galaxyId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  manager?: InputMaybe<ConsultantSortInput>;
  managerId?: InputMaybe<SortEnumType>;
  mandatAutofacFinancement?: InputMaybe<SortEnumType>;
  mandatAutofacPlacement?: InputMaybe<SortEnumType>;
  mandatAutofacTotal?: InputMaybe<SortEnumType>;
  motifResiliationCode?: InputMaybe<SortEnumType>;
  motifResiliationCodeNavigation?: InputMaybe<MotifResiliationConsultantSortInput>;
  nbAmbassadeurs?: InputMaybe<SortEnumType>;
  nbRecommandations?: InputMaybe<SortEnumType>;
  parrain?: InputMaybe<ConsultantSortInput>;
  parrainId?: InputMaybe<SortEnumType>;
  peutParainner?: InputMaybe<SortEnumType>;
  qualificationCode?: InputMaybe<SortEnumType>;
  qualificationCodeNavigation?: InputMaybe<QualificationConsultantSortInput>;
  raisonSociale?: InputMaybe<SortEnumType>;
  resiliationDate?: InputMaybe<SortEnumType>;
  statutCode?: InputMaybe<SortEnumType>;
  statutCodeNavigation?: InputMaybe<StatutConsultantSortInput>;
  tauxRemuneration?: InputMaybe<SortEnumType>;
  teteReseau?: InputMaybe<ConsultantSortInput>;
  teteReseauId?: InputMaybe<SortEnumType>;
  titreCode?: InputMaybe<SortEnumType>;
  titreCodeNavigation?: InputMaybe<TitreConsultantSortInput>;
  totalDebours?: InputMaybe<SortEnumType>;
};

export type ConsultantStatutCommercial = {
  __typename?: 'ConsultantStatutCommercial';
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  stacActif: Scalars['Int']['output'];
  stacDateDebut: Scalars['DateTime']['output'];
  stacDateFin?: Maybe<Scalars['DateTime']['output']>;
  stacMotifAcquisitionStatut: Scalars['String']['output'];
  statutCommercialCode: Scalars['String']['output'];
  statutCommercialCodeNavigation: StatutCommercial;
};

export type ConsultantStatutCommercialFilterInput = {
  and?: InputMaybe<Array<ConsultantStatutCommercialFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantStatutCommercialFilterInput>>;
  stacActif?: InputMaybe<IntOperationFilterInput>;
  stacDateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  stacDateFin?: InputMaybe<DateTimeOperationFilterInput>;
  stacMotifAcquisitionStatut?: InputMaybe<StringOperationFilterInput>;
  statutCommercialCode?: InputMaybe<StringOperationFilterInput>;
  statutCommercialCodeNavigation?: InputMaybe<StatutCommercialFilterInput>;
};

export type ConsultantStatutJuridique = {
  __typename?: 'ConsultantStatutJuridique';
  actif: Scalars['Boolean']['output'];
  adressePro: Adresse;
  adresseProId: Scalars['Long']['output'];
  assujettiTVACode?: Maybe<Scalars['String']['output']>;
  assujettiTVACodeNavigation?: Maybe<AssujettiTva>;
  cessionHistoCommande: Array<CessionHistoCommande>;
  codeTier?: Maybe<Scalars['String']['output']>;
  commissionDifferenceImmo: Scalars['Boolean']['output'];
  commissionDifferencePlacement: Scalars['Boolean']['output'];
  consultant: Consultant;
  consultantContrat: Array<ConsultantContrat>;
  consultantHabilitation: Array<ConsultantHabilitation>;
  consultantId: Scalars['Long']['output'];
  consultantRcp: Array<ConsultantRcp>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateAttest3558?: Maybe<Scalars['DateTime']['output']>;
  dateAttestHonneur?: Maybe<Scalars['DateTime']['output']>;
  dateAttestKbisPerso?: Maybe<Scalars['DateTime']['output']>;
  dateAttestUrssafSociete?: Maybe<Scalars['DateTime']['output']>;
  dateKBISOriginal?: Maybe<Scalars['DateTime']['output']>;
  dateUrssafPer?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  etatStatutCode: Scalars['String']['output'];
  etatStatutCodeNavigation: EtatStatut;
  facture: Array<Facture>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  nomCommercial?: Maybe<Scalars['String']['output']>;
  numeroTva?: Maybe<Scalars['String']['output']>;
  raisonSociale?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  statutJuridiqueCode: Scalars['String']['output'];
  statutJuridiqueCodeNavigation: StatutJuridique;
  typeSocieteCode?: Maybe<Scalars['String']['output']>;
  typeSocieteCodeNavigation?: Maybe<TypeSociete>;
  villeSiret?: Maybe<Scalars['String']['output']>;
};


export type ConsultantStatutJuridiqueCessionHistoCommandeArgs = {
  where?: InputMaybe<CessionHistoCommandeFilterInput>;
};


export type ConsultantStatutJuridiqueConsultantContratArgs = {
  where?: InputMaybe<ConsultantContratFilterInput>;
};


export type ConsultantStatutJuridiqueConsultantHabilitationArgs = {
  where?: InputMaybe<ConsultantHabilitationFilterInput>;
};


export type ConsultantStatutJuridiqueConsultantRcpArgs = {
  where?: InputMaybe<ConsultantRcpFilterInput>;
};


export type ConsultantStatutJuridiqueFactureArgs = {
  where?: InputMaybe<FactureFilterInput>;
};

export type ConsultantStatutJuridiqueFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  adressePro?: InputMaybe<AdresseFilterInput>;
  adresseProId?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<ConsultantStatutJuridiqueFilterInput>>;
  assujettiTVACode?: InputMaybe<StringOperationFilterInput>;
  assujettiTVACodeNavigation?: InputMaybe<AssujettiTvaFilterInput>;
  cessionHistoCommande?: InputMaybe<ListFilterInputTypeOfCessionHistoCommandeFilterInput>;
  codeTier?: InputMaybe<StringOperationFilterInput>;
  commissionDifferenceImmo?: InputMaybe<BooleanOperationFilterInput>;
  commissionDifferencePlacement?: InputMaybe<BooleanOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantContrat?: InputMaybe<ListFilterInputTypeOfConsultantContratFilterInput>;
  consultantHabilitation?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  consultantRcp?: InputMaybe<ListFilterInputTypeOfConsultantRcpFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateAttest3558?: InputMaybe<DateTimeOperationFilterInput>;
  dateAttestHonneur?: InputMaybe<DateTimeOperationFilterInput>;
  dateAttestKbisPerso?: InputMaybe<DateTimeOperationFilterInput>;
  dateAttestUrssafSociete?: InputMaybe<DateTimeOperationFilterInput>;
  dateKBISOriginal?: InputMaybe<DateTimeOperationFilterInput>;
  dateUrssafPer?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  etatStatutCode?: InputMaybe<StringOperationFilterInput>;
  etatStatutCodeNavigation?: InputMaybe<EtatStatutFilterInput>;
  facture?: InputMaybe<ListFilterInputTypeOfFactureFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  nomCommercial?: InputMaybe<StringOperationFilterInput>;
  numeroTva?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ConsultantStatutJuridiqueFilterInput>>;
  raisonSociale?: InputMaybe<StringOperationFilterInput>;
  siret?: InputMaybe<StringOperationFilterInput>;
  statutJuridiqueCode?: InputMaybe<StringOperationFilterInput>;
  statutJuridiqueCodeNavigation?: InputMaybe<StatutJuridiqueFilterInput>;
  typeSocieteCode?: InputMaybe<StringOperationFilterInput>;
  typeSocieteCodeNavigation?: InputMaybe<TypeSocieteFilterInput>;
  villeSiret?: InputMaybe<StringOperationFilterInput>;
};

/** A segment of a collection. */
export type ConsultantsPaginatedCollectionSegment = {
  __typename?: 'ConsultantsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Consultant>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Contact = {
  __typename?: 'Contact';
  adresse: Array<Adresse>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  email: Array<Email>;
  estBenefEffectif: Scalars['Boolean']['output'];
  estInterlocuteurPrincipal: Scalars['Boolean']['output'];
  fonction?: Maybe<FonctionContact>;
  fonctionId?: Maybe<Scalars['Byte']['output']>;
  id: Scalars['Long']['output'];
  idSourceARInvestisseurEntreprise?: Maybe<Scalars['Long']['output']>;
  idSourceContact?: Maybe<Scalars['Long']['output']>;
  idSourceOfInvPP?: Maybe<Scalars['Long']['output']>;
  partenaire?: Maybe<Partenaire>;
  partenaireId?: Maybe<Scalars['Long']['output']>;
  personneMorale?: Maybe<PersonneMorale>;
  personneMoraleId?: Maybe<Scalars['Long']['output']>;
  personnePhysique?: Maybe<PersonnePhysique>;
  personnePhysiqueId?: Maybe<Scalars['Long']['output']>;
  telephone: Array<Telephone>;
};


export type ContactAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};


export type ContactEmailArgs = {
  where?: InputMaybe<EmailFilterInput>;
};


export type ContactTelephoneArgs = {
  where?: InputMaybe<TelephoneFilterInput>;
};

export type ContactFilterInput = {
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<ContactFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<ListFilterInputTypeOfEmailFilterInput>;
  estBenefEffectif?: InputMaybe<BooleanOperationFilterInput>;
  estInterlocuteurPrincipal?: InputMaybe<BooleanOperationFilterInput>;
  fonction?: InputMaybe<FonctionContactFilterInput>;
  fonctionId?: InputMaybe<ByteOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSourceARInvestisseurEntreprise?: InputMaybe<LongOperationFilterInput>;
  idSourceContact?: InputMaybe<LongOperationFilterInput>;
  idSourceOfInvPP?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<ContactFilterInput>>;
  partenaire?: InputMaybe<PartenaireFilterInput>;
  partenaireId?: InputMaybe<LongOperationFilterInput>;
  personneMorale?: InputMaybe<PersonneMoraleFilterInput>;
  personneMoraleId?: InputMaybe<LongOperationFilterInput>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
  telephone?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
};

export type CreateBatchFichierOperationForGroupInput = {
  batchConfig: Array<CreateFichierOperationInput>;
  groupId: Scalars['Int']['input'];
};

export type CreateBatchFichierOperationInput = {
  batchConfig: Array<CreateFichierOperationInput>;
};

export type CreateBoxDocumentTypeInput = {
  gedParentTypeCode: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CreateCreationPersonneMoraleInput = {
  formeSocialeId: Scalars['Long']['input'];
  investisseurPhysiqueId: Scalars['Long']['input'];
  raisonSociale: Scalars['String']['input'];
};

export type CreateEmptyBoxFileInput = {
  denomination?: InputMaybe<Scalars['String']['input']>;
  envoiPartenaireId?: InputMaybe<Scalars['Int']['input']>;
  fileExtension: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  operationId?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateFichierOperationInput = {
  commentaire?: InputMaybe<Scalars['String']['input']>;
  estCoInvestisseur: Scalars['Boolean']['input'];
  estHorsConfig: Scalars['Boolean']['input'];
  fileId: Scalars['String']['input'];
  natureDocumentRequisId?: InputMaybe<Scalars['Int']['input']>;
  operationId: Scalars['Int']['input'];
};

export type CreateInstancePartenaireInput = {
  categorie: Scalars['String']['input'];
  commentaire: Scalars['String']['input'];
  fichierOperationId: Scalars['Int']['input'];
  isInternal: Scalars['Boolean']['input'];
  motif: Scalars['String']['input'];
};

export type CreateNonConformiteFichierInput = {
  categorie: Scalars['String']['input'];
  commentaire: Scalars['String']['input'];
  fichierOperationId: Scalars['Int']['input'];
  motif: Scalars['String']['input'];
};

export type CreateNonConformiteFichierManquantInput = {
  boxDocumentTypeId: Scalars['Int']['input'];
  commentaire: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
};

export type CreationPersonneMorale = {
  __typename?: 'CreationPersonneMorale';
  atlasInvestisseur?: Maybe<Investisseur>;
  atlasInvestisseurId?: Maybe<Scalars['Long']['output']>;
  consultant?: Maybe<Consultant>;
  consultantId: Scalars['Long']['output'];
  consultantPreset?: Maybe<PresettedConsultant>;
  creationInvestisseurDate?: Maybe<Scalars['DateTime']['output']>;
  demandeDate: Scalars['DateTime']['output'];
  formeSociale?: Maybe<FormeJuridique>;
  formeSocialeId: Scalars['Long']['output'];
  id: Scalars['Int']['output'];
  investisseurPhysique?: Maybe<Investisseur>;
  investisseurPhysiqueId: Scalars['Long']['output'];
  investisseurPhysiquePreset?: Maybe<PresettedInvestisseur>;
  raisonSociale: Scalars['String']['output'];
  statut: CreationPersonneMoraleStatut;
};

export type CreationPersonneMoraleFilterInput = {
  and?: InputMaybe<Array<CreationPersonneMoraleFilterInput>>;
  atlasInvestisseurId?: InputMaybe<LongOperationFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationInvestisseurDate?: InputMaybe<DateTimeOperationFilterInput>;
  demandeDate?: InputMaybe<DateTimeOperationFilterInput>;
  formeSocialeId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  investisseurPhysiqueId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<CreationPersonneMoraleFilterInput>>;
  raisonSociale?: InputMaybe<StringOperationFilterInput>;
  statut?: InputMaybe<CreationPersonneMoraleStatutOperationFilterInput>;
};

export type CreationPersonneMoraleSortInput = {
  atlasInvestisseurId?: InputMaybe<SortEnumType>;
  consultantId?: InputMaybe<SortEnumType>;
  creationInvestisseurDate?: InputMaybe<SortEnumType>;
  demandeDate?: InputMaybe<SortEnumType>;
  formeSocialeId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  investisseurPhysiqueId?: InputMaybe<SortEnumType>;
  raisonSociale?: InputMaybe<SortEnumType>;
  statut?: InputMaybe<SortEnumType>;
};

export enum CreationPersonneMoraleStatut {
  DemandeEnCours = 'DEMANDE_EN_COURS',
  Nouveaute = 'NOUVEAUTE',
  Utilise = 'UTILISE'
}

export type CreationPersonneMoraleStatutOperationFilterInput = {
  eq?: InputMaybe<CreationPersonneMoraleStatut>;
  in?: InputMaybe<Array<CreationPersonneMoraleStatut>>;
  neq?: InputMaybe<CreationPersonneMoraleStatut>;
  nin?: InputMaybe<Array<CreationPersonneMoraleStatut>>;
};

/** A segment of a collection. */
export type CreationPersonneMoralesPaginatedCollectionSegment = {
  __typename?: 'CreationPersonneMoralesPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<CreationPersonneMorale>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Cursus = {
  __typename?: 'Cursus';
  codeFamille?: Maybe<Scalars['Byte']['output']>;
  codeFamilleNavigation?: Maybe<FormationType>;
  codeSaison?: Maybe<Scalars['Byte']['output']>;
  codeSaisonNavigation?: Maybe<FormationSaison>;
  creationDate: Scalars['DateTime']['output'];
  cursusCycleCourt: Array<CursusCycleCourt>;
  cursusFormation: Array<CursusFormation>;
  dateDebutValidite?: Maybe<Scalars['DateTime']['output']>;
  dateFinValidite?: Maybe<Scalars['DateTime']['output']>;
  dureeHeure?: Maybe<Scalars['Decimal']['output']>;
  dureeJour?: Maybe<Scalars['Decimal']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  nom: Scalars['String']['output'];
  obligatoire?: Maybe<Scalars['Byte']['output']>;
  reference: Scalars['String']['output'];
};


export type CursusCursusCycleCourtArgs = {
  where?: InputMaybe<CursusCycleCourtFilterInput>;
};


export type CursusCursusFormationArgs = {
  where?: InputMaybe<CursusFormationFilterInput>;
};

export type CursusCycleCourt = {
  __typename?: 'CursusCycleCourt';
  creationDate: Scalars['DateTime']['output'];
  cursus: Cursus;
  cursusId: Scalars['Long']['output'];
  cycleCourt: CycleCourt;
  cycleCourtId: Scalars['Long']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
};

export type CursusCycleCourtFilterInput = {
  and?: InputMaybe<Array<CursusCycleCourtFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursus?: InputMaybe<CursusFilterInput>;
  cursusId?: InputMaybe<LongOperationFilterInput>;
  cycleCourt?: InputMaybe<CycleCourtFilterInput>;
  cycleCourtId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<CursusCycleCourtFilterInput>>;
};

export type CursusFilterInput = {
  and?: InputMaybe<Array<CursusFilterInput>>;
  codeFamille?: InputMaybe<ByteOperationFilterInput>;
  codeFamilleNavigation?: InputMaybe<FormationTypeFilterInput>;
  codeSaison?: InputMaybe<ByteOperationFilterInput>;
  codeSaisonNavigation?: InputMaybe<FormationSaisonFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursusCycleCourt?: InputMaybe<ListFilterInputTypeOfCursusCycleCourtFilterInput>;
  cursusFormation?: InputMaybe<ListFilterInputTypeOfCursusFormationFilterInput>;
  dateDebutValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dureeHeure?: InputMaybe<DecimalOperationFilterInput>;
  dureeJour?: InputMaybe<DecimalOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  obligatoire?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<CursusFilterInput>>;
  reference?: InputMaybe<StringOperationFilterInput>;
};

export type CursusFormation = {
  __typename?: 'CursusFormation';
  creationDate: Scalars['DateTime']['output'];
  cursus: Cursus;
  cursusId: Scalars['Long']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formation: ActionDeFormation;
  formationId: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
};

export type CursusFormationFilterInput = {
  and?: InputMaybe<Array<CursusFormationFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursus?: InputMaybe<CursusFilterInput>;
  cursusId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formation?: InputMaybe<ActionDeFormationFilterInput>;
  formationId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<CursusFormationFilterInput>>;
};

export type CycleCourt = {
  __typename?: 'CycleCourt';
  codeEtat?: Maybe<Scalars['Byte']['output']>;
  codeEtatNavigation?: Maybe<SessionEtat>;
  codeGenre?: Maybe<Scalars['Byte']['output']>;
  codeGenreNavigation?: Maybe<SessionGenre>;
  creationDate: Scalars['DateTime']['output'];
  cursusCycleCourt: Array<CursusCycleCourt>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  dureeHeure?: Maybe<Scalars['Decimal']['output']>;
  dureeJour?: Maybe<Scalars['Decimal']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  nom: Scalars['String']['output'];
};


export type CycleCourtCursusCycleCourtArgs = {
  where?: InputMaybe<CursusCycleCourtFilterInput>;
};


export type CycleCourtInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};

export type CycleCourtFilterInput = {
  and?: InputMaybe<Array<CycleCourtFilterInput>>;
  codeEtat?: InputMaybe<ByteOperationFilterInput>;
  codeEtatNavigation?: InputMaybe<SessionEtatFilterInput>;
  codeGenre?: InputMaybe<ByteOperationFilterInput>;
  codeGenreNavigation?: InputMaybe<SessionGenreFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursusCycleCourt?: InputMaybe<ListFilterInputTypeOfCursusCycleCourtFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  dureeHeure?: InputMaybe<DecimalOperationFilterInput>;
  dureeJour?: InputMaybe<DecimalOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CycleCourtFilterInput>>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Departement = {
  __typename?: 'Departement';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  nom?: Maybe<Scalars['String']['output']>;
  organisme: Array<Organisme>;
  personnePhysique: Array<PersonnePhysique>;
};


export type DepartementOrganismeArgs = {
  where?: InputMaybe<OrganismeFilterInput>;
};


export type DepartementPersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type DepartementFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<DepartementFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DepartementFilterInput>>;
  organisme?: InputMaybe<ListFilterInputTypeOfOrganismeFilterInput>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
};

export type DepartementSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
};

export type DetenteurPatrimoineActif = {
  __typename?: 'DetenteurPatrimoineActif';
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  detentionNuPropriete?: Maybe<Scalars['Decimal']['output']>;
  detentionPleinePropriete?: Maybe<Scalars['Decimal']['output']>;
  detentionUsufruit?: Maybe<Scalars['Decimal']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur?: Maybe<Investisseur>;
  investisseurId?: Maybe<Scalars['Long']['output']>;
  patrimoineActif?: Maybe<PatrimoineActif>;
  patrimoineActifId?: Maybe<Scalars['Long']['output']>;
  personnePhysique?: Maybe<PersonnePhysique>;
  personnePhysiqueId?: Maybe<Scalars['Long']['output']>;
};

export type DetenteurPatrimoineActifFilterInput = {
  and?: InputMaybe<Array<DetenteurPatrimoineActifFilterInput>>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  detentionNuPropriete?: InputMaybe<DecimalOperationFilterInput>;
  detentionPleinePropriete?: InputMaybe<DecimalOperationFilterInput>;
  detentionUsufruit?: InputMaybe<DecimalOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<DetenteurPatrimoineActifFilterInput>>;
  patrimoineActif?: InputMaybe<PatrimoineActifFilterInput>;
  patrimoineActifId?: InputMaybe<LongOperationFilterInput>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
};

export type DetenteurPatrimoineChargeRevenu = {
  __typename?: 'DetenteurPatrimoineChargeRevenu';
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  patrimoineChargeRevenu: PatrimoineChargeRevenu;
  patrimoineChargeRevenuId: Scalars['Long']['output'];
  tauxDetention?: Maybe<Scalars['Decimal']['output']>;
};

export type DetenteurPatrimoineChargeRevenuFilterInput = {
  and?: InputMaybe<Array<DetenteurPatrimoineChargeRevenuFilterInput>>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<DetenteurPatrimoineChargeRevenuFilterInput>>;
  patrimoineChargeRevenu?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
  patrimoineChargeRevenuId?: InputMaybe<LongOperationFilterInput>;
  tauxDetention?: InputMaybe<DecimalOperationFilterInput>;
};

export type DetenteurPatrimoinePassif = {
  __typename?: 'DetenteurPatrimoinePassif';
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  patrimoinePassif: PatrimoinePassif;
  patrimoinePassifId: Scalars['Long']['output'];
  quotePartAdi?: Maybe<Scalars['Decimal']['output']>;
  tauxDetention: Scalars['Decimal']['output'];
};

export type DetenteurPatrimoinePassifFilterInput = {
  and?: InputMaybe<Array<DetenteurPatrimoinePassifFilterInput>>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<DetenteurPatrimoinePassifFilterInput>>;
  patrimoinePassif?: InputMaybe<PatrimoinePassifFilterInput>;
  patrimoinePassifId?: InputMaybe<LongOperationFilterInput>;
  quotePartAdi?: InputMaybe<DecimalOperationFilterInput>;
  tauxDetention?: InputMaybe<DecimalOperationFilterInput>;
};

export type Detention = {
  __typename?: 'Detention';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  patrimoineActif: Array<PatrimoineActif>;
  patrimoineChargeRevenu: Array<PatrimoineChargeRevenu>;
  patrimoinePassif: Array<PatrimoinePassif>;
};


export type DetentionPatrimoineActifArgs = {
  where?: InputMaybe<PatrimoineActifFilterInput>;
};


export type DetentionPatrimoineChargeRevenuArgs = {
  where?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
};


export type DetentionPatrimoinePassifArgs = {
  where?: InputMaybe<PatrimoinePassifFilterInput>;
};

export type DetentionFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<DetentionFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DetentionFilterInput>>;
  patrimoineActif?: InputMaybe<ListFilterInputTypeOfPatrimoineActifFilterInput>;
  patrimoineChargeRevenu?: InputMaybe<ListFilterInputTypeOfPatrimoineChargeRevenuFilterInput>;
  patrimoinePassif?: InputMaybe<ListFilterInputTypeOfPatrimoinePassifFilterInput>;
};

export type Disposition = {
  __typename?: 'Disposition';
  autresDispositions?: Maybe<Scalars['Boolean']['output']>;
  autresDonation?: Maybe<Scalars['Boolean']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  donationConjoint?: Maybe<Scalars['Boolean']['output']>;
  donationDernierVivant?: Maybe<Scalars['Boolean']['output']>;
  donationPartage?: Maybe<Scalars['Boolean']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  legsParticuliers?: Maybe<Scalars['Boolean']['output']>;
  legsUniversels?: Maybe<Scalars['Boolean']['output']>;
  note: Array<Note>;
  personnePhysique: PersonnePhysique;
  personnePhysiqueId: Scalars['Long']['output'];
};


export type DispositionNoteArgs = {
  where?: InputMaybe<NoteFilterInput>;
};

export type DispositionFilterInput = {
  and?: InputMaybe<Array<DispositionFilterInput>>;
  autresDispositions?: InputMaybe<BooleanOperationFilterInput>;
  autresDonation?: InputMaybe<BooleanOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  donationConjoint?: InputMaybe<BooleanOperationFilterInput>;
  donationDernierVivant?: InputMaybe<BooleanOperationFilterInput>;
  donationPartage?: InputMaybe<BooleanOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  legsParticuliers?: InputMaybe<BooleanOperationFilterInput>;
  legsUniversels?: InputMaybe<BooleanOperationFilterInput>;
  note?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  or?: InputMaybe<Array<DispositionFilterInput>>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
};

export type DispositionSortInput = {
  autresDispositions?: InputMaybe<SortEnumType>;
  autresDonation?: InputMaybe<SortEnumType>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  donationConjoint?: InputMaybe<SortEnumType>;
  donationDernierVivant?: InputMaybe<SortEnumType>;
  donationPartage?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  legsParticuliers?: InputMaybe<SortEnumType>;
  legsUniversels?: InputMaybe<SortEnumType>;
  personnePhysique?: InputMaybe<PersonnePhysiqueSortInput>;
  personnePhysiqueId?: InputMaybe<SortEnumType>;
};

export type Distinction = {
  __typename?: 'Distinction';
  code: Scalars['Byte']['output'];
  consultantDistinction: Array<ConsultantDistinction>;
  creationDate: Scalars['DateTime']['output'];
  distinctionCategorie: Scalars['Byte']['output'];
  distinctionCategorieNavigation: DistinctionCategorie;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type DistinctionConsultantDistinctionArgs = {
  where?: InputMaybe<ConsultantDistinctionFilterInput>;
};

export type DistinctionCategorie = {
  __typename?: 'DistinctionCategorie';
  code: Scalars['Byte']['output'];
  consultantDistinction: Array<ConsultantDistinction>;
  creationDate: Scalars['DateTime']['output'];
  distinction: Array<Distinction>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type DistinctionCategorieConsultantDistinctionArgs = {
  where?: InputMaybe<ConsultantDistinctionFilterInput>;
};


export type DistinctionCategorieDistinctionArgs = {
  where?: InputMaybe<DistinctionFilterInput>;
};

export type DistinctionCategorieFilterInput = {
  and?: InputMaybe<Array<DistinctionCategorieFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  consultantDistinction?: InputMaybe<ListFilterInputTypeOfConsultantDistinctionFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  distinction?: InputMaybe<ListFilterInputTypeOfDistinctionFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DistinctionCategorieFilterInput>>;
};

export type DistinctionFilterInput = {
  and?: InputMaybe<Array<DistinctionFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  consultantDistinction?: InputMaybe<ListFilterInputTypeOfConsultantDistinctionFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  distinctionCategorie?: InputMaybe<ByteOperationFilterInput>;
  distinctionCategorieNavigation?: InputMaybe<DistinctionCategorieFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DistinctionFilterInput>>;
};

export type DocumentCategorie = {
  __typename?: 'DocumentCategorie';
  id: Scalars['Int']['output'];
  libelle?: Maybe<Scalars['String']['output']>;
  natureDocument: Array<NatureDocument>;
};


export type DocumentCategorieNatureDocumentArgs = {
  where?: InputMaybe<NatureDocumentFilterInput>;
};

export type DocumentCategorieFilterInput = {
  and?: InputMaybe<Array<DocumentCategorieFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  natureDocument?: InputMaybe<ListFilterInputTypeOfNatureDocumentFilterInput>;
  or?: InputMaybe<Array<DocumentCategorieFilterInput>>;
};

export type DocumentCategorieSortInput = {
  id?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type Edition = {
  __typename?: 'Edition';
  consultant?: Maybe<Consultant>;
  consultantId?: Maybe<Scalars['Long']['output']>;
  dateCreation: Scalars['DateTime']['output'];
  dateEdition: Scalars['DateTime']['output'];
  dateProchaineEdition?: Maybe<Scalars['DateTime']['output']>;
  documentId?: Maybe<Scalars['UUID']['output']>;
  estSupprime: Scalars['Boolean']['output'];
  extension: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  nombreTelechargement: Scalars['Int']['output'];
  typeEditionCode: Scalars['String']['output'];
  typeEditionCodeNavigation: TypeEdition;
};

export type EditionFilterInput = {
  and?: InputMaybe<Array<EditionFilterInput>>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  dateCreation?: InputMaybe<DateTimeOperationFilterInput>;
  dateEdition?: InputMaybe<DateTimeOperationFilterInput>;
  dateProchaineEdition?: InputMaybe<DateTimeOperationFilterInput>;
  documentId?: InputMaybe<UuidOperationFilterInput>;
  estSupprime?: InputMaybe<BooleanOperationFilterInput>;
  extension?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  nombreTelechargement?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<EditionFilterInput>>;
  typeEditionCode?: InputMaybe<StringOperationFilterInput>;
  typeEditionCodeNavigation?: InputMaybe<TypeEditionFilterInput>;
};

export type Email = {
  __typename?: 'Email';
  adresse: Scalars['String']['output'];
  arianeId?: Maybe<Scalars['Long']['output']>;
  consultant: Array<Consultant>;
  contact: Array<Contact>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  estAdresseCorrespondance?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Long']['output']>;
  personnePhysique: Array<PersonnePhysique>;
  typeEmailCode: Scalars['String']['output'];
  typeEmailCodeNavigation: TypeEmail;
};


export type EmailConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type EmailContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type EmailPersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type EmailFilterInput = {
  adresse?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<EmailFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  estAdresseCorrespondance?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<NoteFilterInput>;
  noteId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<EmailFilterInput>>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  typeEmailCode?: InputMaybe<StringOperationFilterInput>;
  typeEmailCodeNavigation?: InputMaybe<TypeEmailFilterInput>;
};

export type EnveloppeProduit = {
  __typename?: 'EnveloppeProduit';
  backOfficeService: BackOfficeService;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  operationConfigs: Array<OperationConfig>;
  produit: Array<Produit>;
  type2: Scalars['Byte']['output'];
  type2Navigation: Type2Produit;
};


export type EnveloppeProduitProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type EnveloppeProduitFilterInput = {
  and?: InputMaybe<Array<EnveloppeProduitFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EnveloppeProduitFilterInput>>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
  type2?: InputMaybe<ByteOperationFilterInput>;
  type2Navigation?: InputMaybe<Type2ProduitFilterInput>;
};

export type EnveloppeProduitSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  type2?: InputMaybe<SortEnumType>;
  type2Navigation?: InputMaybe<Type2ProduitSortInput>;
};

export type EnvoiEmail = {
  __typename?: 'EnvoiEmail';
  envoiPartenaire?: Maybe<EnvoiPartenaire>;
  envoiPartenaireId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isErrorAcknowledged: Scalars['Boolean']['output'];
  messageId: Scalars['String']['output'];
  operation?: Maybe<Operation>;
  operationId?: Maybe<Scalars['Int']['output']>;
  senderBackOffice?: Maybe<BackOfficeMember>;
  senderBackOfficeId?: Maybe<Scalars['Int']['output']>;
  sendingDate?: Maybe<Scalars['DateTime']['output']>;
  statutEnvoi: EnvoiEmailStatut;
  statutMessage?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EnvoiEmailFilterInput = {
  and?: InputMaybe<Array<EnvoiEmailFilterInput>>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireFilterInput>;
  envoiPartenaireId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isErrorAcknowledged?: InputMaybe<BooleanOperationFilterInput>;
  messageId?: InputMaybe<StringOperationFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<EnvoiEmailFilterInput>>;
  senderBackOffice?: InputMaybe<BackOfficeMemberFilterInput>;
  senderBackOfficeId?: InputMaybe<IntOperationFilterInput>;
  sendingDate?: InputMaybe<DateTimeOperationFilterInput>;
  statutEnvoi?: InputMaybe<EnvoiEmailStatutOperationFilterInput>;
  statutMessage?: InputMaybe<StringOperationFilterInput>;
  subject?: InputMaybe<StringOperationFilterInput>;
};

export enum EnvoiEmailStatut {
  Delivered = 'DELIVERED',
  Error = 'ERROR',
  Sent = 'SENT'
}

export type EnvoiEmailStatutOperationFilterInput = {
  eq?: InputMaybe<EnvoiEmailStatut>;
  in?: InputMaybe<Array<EnvoiEmailStatut>>;
  neq?: InputMaybe<EnvoiEmailStatut>;
  nin?: InputMaybe<Array<EnvoiEmailStatut>>;
};

export type EnvoiPartenaire = {
  __typename?: 'EnvoiPartenaire';
  attachedFiles: Array<BoxFile>;
  bordereauFile?: Maybe<BoxFile>;
  bordereauFileId?: Maybe<Scalars['Int']['output']>;
  emails: Array<EnvoiEmail>;
  historyRecords: Array<EnvoiPartenaireHistoryRecord>;
  id: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  operations: Array<Operation>;
  partenaire?: Maybe<Partenaire>;
  partenaireHasBeenNotifed: Scalars['Boolean']['output'];
  partenaireId: Scalars['Long']['output'];
  partenairePreset?: Maybe<PresettedPartenaire>;
  receiveDate?: Maybe<Scalars['DateTime']['output']>;
  sendingDate?: Maybe<Scalars['DateTime']['output']>;
  statutEnvoi: EnvoiPartenaireStatut;
  updateDate?: Maybe<Scalars['DateTime']['output']>;
};


export type EnvoiPartenaireAttachedFilesArgs = {
  where?: InputMaybe<BoxFileFilterInput>;
};


export type EnvoiPartenaireEmailsArgs = {
  where?: InputMaybe<EnvoiEmailFilterInput>;
};


export type EnvoiPartenaireHistoryRecordsArgs = {
  where?: InputMaybe<EnvoiPartenaireHistoryRecordFilterInput>;
};


export type EnvoiPartenaireOperationsArgs = {
  where?: InputMaybe<OperationFilterInput>;
};

export type EnvoiPartenaireFilterInput = {
  and?: InputMaybe<Array<EnvoiPartenaireFilterInput>>;
  attachedFiles?: InputMaybe<ListFilterInputTypeOfBoxFileFilterInput>;
  bordereauFile?: InputMaybe<BoxFileFilterInput>;
  bordereauFileId?: InputMaybe<IntOperationFilterInput>;
  emails?: InputMaybe<ListFilterInputTypeOfEnvoiEmailFilterInput>;
  historyRecords?: InputMaybe<ListFilterInputTypeOfEnvoiPartenaireHistoryRecordFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  operations?: InputMaybe<ListFilterInputTypeOfOperationFilterInput>;
  or?: InputMaybe<Array<EnvoiPartenaireFilterInput>>;
  partenaireHasBeenNotifed?: InputMaybe<BooleanOperationFilterInput>;
  partenaireId?: InputMaybe<LongOperationFilterInput>;
  receiveDate?: InputMaybe<DateTimeOperationFilterInput>;
  sendingDate?: InputMaybe<DateTimeOperationFilterInput>;
  statutEnvoi?: InputMaybe<EnvoiPartenaireStatutOperationFilterInput>;
  updateDate?: InputMaybe<DateTimeOperationFilterInput>;
};

export type EnvoiPartenaireHistoryRecord = {
  __typename?: 'EnvoiPartenaireHistoryRecord';
  boxFile?: Maybe<BoxFile>;
  boxFileId?: Maybe<Scalars['Int']['output']>;
  declaredDate?: Maybe<Scalars['DateTime']['output']>;
  envoiPartenaire: EnvoiPartenaire;
  envoiPartenaireId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  initiatorBackOffice: BackOfficeMember;
  initiatorBackOfficeId: Scalars['Int']['output'];
  newStatut?: Maybe<EnvoiPartenaireStatut>;
  note?: Maybe<Scalars['String']['output']>;
  previousStatut?: Maybe<EnvoiPartenaireStatut>;
  timestamp: Scalars['DateTime']['output'];
  type: EnvoiPartenaireHistoryRecordTypes;
};

export type EnvoiPartenaireHistoryRecordFilterInput = {
  and?: InputMaybe<Array<EnvoiPartenaireHistoryRecordFilterInput>>;
  boxFile?: InputMaybe<BoxFileFilterInput>;
  boxFileId?: InputMaybe<IntOperationFilterInput>;
  declaredDate?: InputMaybe<DateTimeOperationFilterInput>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireFilterInput>;
  envoiPartenaireId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatorBackOffice?: InputMaybe<BackOfficeMemberFilterInput>;
  initiatorBackOfficeId?: InputMaybe<IntOperationFilterInput>;
  newStatut?: InputMaybe<NullableOfEnvoiPartenaireStatutOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EnvoiPartenaireHistoryRecordFilterInput>>;
  previousStatut?: InputMaybe<NullableOfEnvoiPartenaireStatutOperationFilterInput>;
  timestamp?: InputMaybe<DateTimeOperationFilterInput>;
  type?: InputMaybe<EnvoiPartenaireHistoryRecordTypesOperationFilterInput>;
};

export enum EnvoiPartenaireHistoryRecordTypes {
  EnvoiPartenaireAddFile = 'ENVOI_PARTENAIRE_ADD_FILE',
  EnvoiPartenaireCreate = 'ENVOI_PARTENAIRE_CREATE',
  EnvoiPartenaireNoteUpdated = 'ENVOI_PARTENAIRE_NOTE_UPDATED',
  EnvoiPartenaireNotified = 'ENVOI_PARTENAIRE_NOTIFIED',
  EnvoiPartenaireRemoveFile = 'ENVOI_PARTENAIRE_REMOVE_FILE',
  EnvoiPartenaireStatutChanged = 'ENVOI_PARTENAIRE_STATUT_CHANGED'
}

export type EnvoiPartenaireHistoryRecordTypesOperationFilterInput = {
  eq?: InputMaybe<EnvoiPartenaireHistoryRecordTypes>;
  in?: InputMaybe<Array<EnvoiPartenaireHistoryRecordTypes>>;
  neq?: InputMaybe<EnvoiPartenaireHistoryRecordTypes>;
  nin?: InputMaybe<Array<EnvoiPartenaireHistoryRecordTypes>>;
};

/** A segment of a collection. */
export type EnvoiPartenairePaginatedCollectionSegment = {
  __typename?: 'EnvoiPartenairePaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<EnvoiPartenaire>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type EnvoiPartenaireSortInput = {
  bordereauFile?: InputMaybe<BoxFileSortInput>;
  bordereauFileId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  partenaireHasBeenNotifed?: InputMaybe<SortEnumType>;
  partenaireId?: InputMaybe<SortEnumType>;
  receiveDate?: InputMaybe<SortEnumType>;
  sendingDate?: InputMaybe<SortEnumType>;
  statutEnvoi?: InputMaybe<SortEnumType>;
  updateDate?: InputMaybe<SortEnumType>;
};

export enum EnvoiPartenaireStatut {
  CourrierBordereauCreated = 'COURRIER_BORDEREAU_CREATED',
  CourrierLost = 'COURRIER_LOST',
  CourrierReceived = 'COURRIER_RECEIVED',
  CourrierSearching = 'COURRIER_SEARCHING',
  CourrierSent = 'COURRIER_SENT',
  EmailReceived = 'EMAIL_RECEIVED',
  EmailSent = 'EMAIL_SENT',
  ExtranetSent = 'EXTRANET_SENT',
  ReponseDirecteSent = 'REPONSE_DIRECTE_SENT'
}

export type EnvoiPartenaireStatutOperationFilterInput = {
  eq?: InputMaybe<EnvoiPartenaireStatut>;
  in?: InputMaybe<Array<EnvoiPartenaireStatut>>;
  neq?: InputMaybe<EnvoiPartenaireStatut>;
  nin?: InputMaybe<Array<EnvoiPartenaireStatut>>;
};

export type EquivalenceDeFormation = {
  __typename?: 'EquivalenceDeFormation';
  codeFormation: Scalars['Byte']['output'];
  codeFormationNavigation: Formation;
  commentaire?: Maybe<Scalars['String']['output']>;
  consultant: Consultant;
  consultantFormateur?: Maybe<Consultant>;
  consultantFormateurId?: Maybe<Scalars['Long']['output']>;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateFinValidite?: Maybe<Scalars['DateTime']['output']>;
  dateFormation?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  nom?: Maybe<Scalars['String']['output']>;
  organisme?: Maybe<Scalars['String']['output']>;
  typeJustification?: Maybe<Scalars['Byte']['output']>;
  typeJustificationNavigation?: Maybe<TypeJustificationFormation>;
};

export type EquivalenceDeFormationFilterInput = {
  and?: InputMaybe<Array<EquivalenceDeFormationFilterInput>>;
  codeFormation?: InputMaybe<ByteOperationFilterInput>;
  codeFormationNavigation?: InputMaybe<FormationFilterInput>;
  commentaire?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantFormateur?: InputMaybe<ConsultantFilterInput>;
  consultantFormateurId?: InputMaybe<LongOperationFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateFormation?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EquivalenceDeFormationFilterInput>>;
  organisme?: InputMaybe<StringOperationFilterInput>;
  typeJustification?: InputMaybe<ByteOperationFilterInput>;
  typeJustificationNavigation?: InputMaybe<TypeJustificationFormationFilterInput>;
};

export type EtatCivil = {
  __typename?: 'EtatCivil';
  codePaysResidenceStableCouple?: Maybe<Scalars['String']['output']>;
  codePaysResidenceStableCoupleNavigation?: Maybe<Pays>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateChangementSituation?: Maybe<Scalars['DateTime']['output']>;
  dateContratUnion?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  personnePhysique: PersonnePhysique;
  personnePhysiqueId: Scalars['Long']['output'];
  regimePacsIndivision?: Maybe<Scalars['Boolean']['output']>;
  typeAvantageMatrimonialCode?: Maybe<Scalars['String']['output']>;
  typeAvantageMatrimonialCodeNavigation?: Maybe<TypeAvantageMatrimonial>;
  typeRegimeSituationEtatCivilCode?: Maybe<Scalars['String']['output']>;
  typeRegimeSituationEtatCivilCodeNavigation?: Maybe<TypeRegimeSituationEtatCivil>;
  typeSituationEtatCivilCode?: Maybe<Scalars['String']['output']>;
  typeSituationEtatCivilCodeNavigation?: Maybe<TypeSituationEtatCivil>;
};

export type EtatCivilFilterInput = {
  and?: InputMaybe<Array<EtatCivilFilterInput>>;
  codePaysResidenceStableCouple?: InputMaybe<StringOperationFilterInput>;
  codePaysResidenceStableCoupleNavigation?: InputMaybe<PaysFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateChangementSituation?: InputMaybe<DateTimeOperationFilterInput>;
  dateContratUnion?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatCivilFilterInput>>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
  regimePacsIndivision?: InputMaybe<BooleanOperationFilterInput>;
  typeAvantageMatrimonialCode?: InputMaybe<StringOperationFilterInput>;
  typeAvantageMatrimonialCodeNavigation?: InputMaybe<TypeAvantageMatrimonialFilterInput>;
  typeRegimeSituationEtatCivilCode?: InputMaybe<StringOperationFilterInput>;
  typeRegimeSituationEtatCivilCodeNavigation?: InputMaybe<TypeRegimeSituationEtatCivilFilterInput>;
  typeSituationEtatCivilCode?: InputMaybe<StringOperationFilterInput>;
  typeSituationEtatCivilCodeNavigation?: InputMaybe<TypeSituationEtatCivilFilterInput>;
};

export type EtatCivilSortInput = {
  codePaysResidenceStableCouple?: InputMaybe<SortEnumType>;
  codePaysResidenceStableCoupleNavigation?: InputMaybe<PaysSortInput>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateChangementSituation?: InputMaybe<SortEnumType>;
  dateContratUnion?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  personnePhysique?: InputMaybe<PersonnePhysiqueSortInput>;
  personnePhysiqueId?: InputMaybe<SortEnumType>;
  regimePacsIndivision?: InputMaybe<SortEnumType>;
  typeAvantageMatrimonialCode?: InputMaybe<SortEnumType>;
  typeAvantageMatrimonialCodeNavigation?: InputMaybe<TypeAvantageMatrimonialSortInput>;
  typeRegimeSituationEtatCivilCode?: InputMaybe<SortEnumType>;
  typeRegimeSituationEtatCivilCodeNavigation?: InputMaybe<TypeRegimeSituationEtatCivilSortInput>;
  typeSituationEtatCivilCode?: InputMaybe<SortEnumType>;
  typeSituationEtatCivilCodeNavigation?: InputMaybe<TypeSituationEtatCivilSortInput>;
};

export type EtatCommande = {
  __typename?: 'EtatCommande';
  code: Scalars['Byte']['output'];
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type EtatCommandeCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};

export type EtatCommandeFilterInput = {
  and?: InputMaybe<Array<EtatCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatCommandeFilterInput>>;
};

export type EtatCommandeSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type EtatMouvement = {
  __typename?: 'EtatMouvement';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  mouvement: Array<Mouvement>;
};


export type EtatMouvementMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};

export type EtatMouvementFilterInput = {
  and?: InputMaybe<Array<EtatMouvementFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  or?: InputMaybe<Array<EtatMouvementFilterInput>>;
};

export type EtatMouvementSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type EtatPmrCommande = {
  __typename?: 'EtatPmrCommande';
  code: Scalars['Byte']['output'];
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type EtatPmrCommandeCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};

export type EtatPmrCommandeFilterInput = {
  and?: InputMaybe<Array<EtatPmrCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatPmrCommandeFilterInput>>;
};

export type EtatPmrCommandeSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type EtatProduit = {
  __typename?: 'EtatProduit';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  produit: Array<Produit>;
};


export type EtatProduitProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type EtatProduitFilterInput = {
  and?: InputMaybe<Array<EtatProduitFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatProduitFilterInput>>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
};

export type EtatProduitSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type EtatResiliationB2S = {
  __typename?: 'EtatResiliationB2S';
  code: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate: Scalars['DateTime']['output'];
  libelle: Scalars['String']['output'];
  resiliationPaiementB2S: Array<ResiliationPaiementB2S>;
};


export type EtatResiliationB2SResiliationPaiementB2SArgs = {
  where?: InputMaybe<ResiliationPaiementB2SFilterInput>;
};

export type EtatResiliationB2SFilterInput = {
  and?: InputMaybe<Array<EtatResiliationB2SFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatResiliationB2SFilterInput>>;
  resiliationPaiementB2S?: InputMaybe<ListFilterInputTypeOfResiliationPaiementB2SFilterInput>;
};

export type EtatStatut = {
  __typename?: 'EtatStatut';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type EtatStatutConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};

export type EtatStatutFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<EtatStatutFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<EtatStatutFilterInput>>;
};

export type ExerciceCommercial = {
  __typename?: 'ExerciceCommercial';
  actif: Scalars['Boolean']['output'];
  adhesionConsultant: Array<AdhesionConsultant>;
  codeExercice: Scalars['Long']['output'];
  consultantDistinction: Array<ConsultantDistinction>;
  creationDate: Scalars['DateTime']['output'];
  dateAdhesionReport?: Maybe<Scalars['DateTime']['output']>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  libelleExercice: Scalars['String']['output'];
};


export type ExerciceCommercialAdhesionConsultantArgs = {
  where?: InputMaybe<AdhesionConsultantFilterInput>;
};


export type ExerciceCommercialConsultantDistinctionArgs = {
  where?: InputMaybe<ConsultantDistinctionFilterInput>;
};

export type ExerciceCommercialFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  adhesionConsultant?: InputMaybe<ListFilterInputTypeOfAdhesionConsultantFilterInput>;
  and?: InputMaybe<Array<ExerciceCommercialFilterInput>>;
  codeExercice?: InputMaybe<LongOperationFilterInput>;
  consultantDistinction?: InputMaybe<ListFilterInputTypeOfConsultantDistinctionFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateAdhesionReport?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelleExercice?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExerciceCommercialFilterInput>>;
};

export type Expiration = {
  __typename?: 'Expiration';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateAnniversaire: Scalars['DateTime']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  periode: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
  typePieceJointe: Array<TypePieceJointe>;
};


export type ExpirationTypePieceJointeArgs = {
  where?: InputMaybe<TypePieceJointeFilterInput>;
};

export type ExpirationFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ExpirationFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateAnniversaire?: InputMaybe<DateTimeOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExpirationFilterInput>>;
  periode?: InputMaybe<IntOperationFilterInput>;
  type?: InputMaybe<IntOperationFilterInput>;
  typePieceJointe?: InputMaybe<ListFilterInputTypeOfTypePieceJointeFilterInput>;
};

export type Facture = {
  __typename?: 'Facture';
  besoinApprobation: Scalars['Boolean']['output'];
  codeEtablissement: Scalars['String']['output'];
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  dateApprobation?: Maybe<Scalars['DateTime']['output']>;
  dateCreation: Scalars['DateTime']['output'];
  dateFacture: Scalars['DateTime']['output'];
  dateLimiteValidite: Scalars['DateTime']['output'];
  dateModification?: Maybe<Scalars['DateTime']['output']>;
  datePaiement?: Maybe<Scalars['DateTime']['output']>;
  documentId: Scalars['UUID']['output'];
  id: Scalars['Long']['output'];
  montant: Scalars['Decimal']['output'];
  nomFichier?: Maybe<Scalars['String']['output']>;
  nomFiliale: Scalars['String']['output'];
  nombreTelechargement: Scalars['Int']['output'];
  noteApprobation?: Maybe<Note>;
  noteApprobationId?: Maybe<Scalars['Long']['output']>;
  numeroEcritureComptable: Scalars['String']['output'];
  numeroFacture: Scalars['String']['output'];
  numeroPieceComptable: Scalars['String']['output'];
  statutApprobation?: Maybe<Scalars['Boolean']['output']>;
  statutJuridique: ConsultantStatutJuridique;
  statutJuridiqueId: Scalars['Long']['output'];
  yearFacture?: Maybe<Scalars['Int']['output']>;
};

export type FactureFilterInput = {
  and?: InputMaybe<Array<FactureFilterInput>>;
  besoinApprobation?: InputMaybe<BooleanOperationFilterInput>;
  codeEtablissement?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  dateApprobation?: InputMaybe<DateTimeOperationFilterInput>;
  dateCreation?: InputMaybe<DateTimeOperationFilterInput>;
  dateFacture?: InputMaybe<DateTimeOperationFilterInput>;
  dateLimiteValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateModification?: InputMaybe<DateTimeOperationFilterInput>;
  datePaiement?: InputMaybe<DateTimeOperationFilterInput>;
  documentId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  montant?: InputMaybe<DecimalOperationFilterInput>;
  nomFichier?: InputMaybe<StringOperationFilterInput>;
  nomFiliale?: InputMaybe<StringOperationFilterInput>;
  nombreTelechargement?: InputMaybe<IntOperationFilterInput>;
  noteApprobation?: InputMaybe<NoteFilterInput>;
  noteApprobationId?: InputMaybe<LongOperationFilterInput>;
  numeroEcritureComptable?: InputMaybe<StringOperationFilterInput>;
  numeroFacture?: InputMaybe<StringOperationFilterInput>;
  numeroPieceComptable?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FactureFilterInput>>;
  statutApprobation?: InputMaybe<BooleanOperationFilterInput>;
  statutJuridique?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  statutJuridiqueId?: InputMaybe<LongOperationFilterInput>;
  yearFacture?: InputMaybe<IntOperationFilterInput>;
};

export type FichierOperation = {
  __typename?: 'FichierOperation';
  commentaire?: Maybe<Scalars['String']['output']>;
  estCoInvestisseur: Scalars['Boolean']['output'];
  estHorsConfiguration: Scalars['Boolean']['output'];
  fichierOperationStatut: FichierOperationStatut;
  fichierOperationStatutId: Scalars['Int']['output'];
  fileId?: Maybe<Scalars['String']['output']>;
  historyEntry: Array<FichierOperationHistoryRecord>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<FileMetadata>;
  natureDocumentRequis?: Maybe<NatureDocumentRequi>;
  natureDocumentRequisId?: Maybe<Scalars['Int']['output']>;
  nonConformiteFichiers: Array<NonConformiteFichier>;
  operation?: Maybe<Operation>;
  operationId?: Maybe<Scalars['Int']['output']>;
  reponseConsultant?: Maybe<Scalars['String']['output']>;
};


export type FichierOperationHistoryEntryArgs = {
  where?: InputMaybe<FichierOperationHistoryRecordFilterInput>;
};


export type FichierOperationNonConformiteFichiersArgs = {
  where?: InputMaybe<NonConformiteFichierFilterInput>;
};

export type FichierOperationFilterInput = {
  and?: InputMaybe<Array<FichierOperationFilterInput>>;
  commentaire?: InputMaybe<StringOperationFilterInput>;
  estCoInvestisseur?: InputMaybe<BooleanOperationFilterInput>;
  estHorsConfiguration?: InputMaybe<BooleanOperationFilterInput>;
  fichierOperationStatut?: InputMaybe<FichierOperationStatutFilterInput>;
  fichierOperationStatutId?: InputMaybe<IntOperationFilterInput>;
  fileId?: InputMaybe<StringOperationFilterInput>;
  historyEntry?: InputMaybe<ListFilterInputTypeOfFichierOperationHistoryRecordFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  natureDocumentRequis?: InputMaybe<NatureDocumentRequiFilterInput>;
  natureDocumentRequisId?: InputMaybe<IntOperationFilterInput>;
  nonConformiteFichiers?: InputMaybe<ListFilterInputTypeOfNonConformiteFichierFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FichierOperationFilterInput>>;
  reponseConsultant?: InputMaybe<StringOperationFilterInput>;
};

export type FichierOperationHistoryRecord = {
  __typename?: 'FichierOperationHistoryRecord';
  categorie?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  consultant?: Maybe<Consultant>;
  fichierOperation: FichierOperation;
  fichierOperationId: Scalars['Int']['output'];
  fileId?: Maybe<Scalars['String']['output']>;
  fileMetadata?: Maybe<FileMetadata>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  initiatorBackOffice?: Maybe<BackOfficeMember>;
  initiatorBackOfficeId?: Maybe<Scalars['Int']['output']>;
  initiatorConsultantId?: Maybe<Scalars['Long']['output']>;
  isInternal: Scalars['Boolean']['output'];
  motif?: Maybe<Scalars['String']['output']>;
  newFileId?: Maybe<Scalars['String']['output']>;
  newfileMetadata?: Maybe<FileMetadata>;
  operation: Operation;
  operationId: Scalars['Int']['output'];
  previousFileId?: Maybe<Scalars['String']['output']>;
  timestamp: Scalars['DateTime']['output'];
  type: FichierOperationHistoryRecordTypes;
};

export type FichierOperationHistoryRecordFilterInput = {
  and?: InputMaybe<Array<FichierOperationHistoryRecordFilterInput>>;
  categorie?: InputMaybe<StringOperationFilterInput>;
  comment?: InputMaybe<StringOperationFilterInput>;
  fichierOperation?: InputMaybe<FichierOperationFilterInput>;
  fichierOperationId?: InputMaybe<IntOperationFilterInput>;
  fileId?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatorBackOffice?: InputMaybe<BackOfficeMemberFilterInput>;
  initiatorBackOfficeId?: InputMaybe<IntOperationFilterInput>;
  initiatorConsultantId?: InputMaybe<LongOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  motif?: InputMaybe<StringOperationFilterInput>;
  newFileId?: InputMaybe<StringOperationFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FichierOperationHistoryRecordFilterInput>>;
  previousFileId?: InputMaybe<StringOperationFilterInput>;
  timestamp?: InputMaybe<DateTimeOperationFilterInput>;
  type?: InputMaybe<FichierOperationHistoryRecordTypesOperationFilterInput>;
};

export enum FichierOperationHistoryRecordTypes {
  FichierOperationCancelInstance = 'FICHIER_OPERATION_CANCEL_INSTANCE',
  FichierOperationCancelNc = 'FICHIER_OPERATION_CANCEL_NC',
  FichierOperationCreate = 'FICHIER_OPERATION_CREATE',
  FichierOperationDelete = 'FICHIER_OPERATION_DELETE',
  FichierOperationInstanceDataUpdate = 'FICHIER_OPERATION_INSTANCE_DATA_UPDATE',
  FichierOperationNcDataUpdate = 'FICHIER_OPERATION_NC_DATA_UPDATE',
  FichierOperationRaiseInstance = 'FICHIER_OPERATION_RAISE_INSTANCE',
  FichierOperationRaiseNc = 'FICHIER_OPERATION_RAISE_NC',
  FichierOperationResponseNcComment = 'FICHIER_OPERATION_RESPONSE_NC_COMMENT',
  FichierOperationResponseNcFileUpload = 'FICHIER_OPERATION_RESPONSE_NC_FILE_UPLOAD',
  FichierOperationResponseNcPending = 'FICHIER_OPERATION_RESPONSE_NC_PENDING',
  FichierOperationSetToConforme = 'FICHIER_OPERATION_SET_TO_CONFORME',
  FichierOperationSetToNotConforme = 'FICHIER_OPERATION_SET_TO_NOT_CONFORME',
  FichierOperationUpdate = 'FICHIER_OPERATION_UPDATE'
}

export type FichierOperationHistoryRecordTypesOperationFilterInput = {
  eq?: InputMaybe<FichierOperationHistoryRecordTypes>;
  in?: InputMaybe<Array<FichierOperationHistoryRecordTypes>>;
  neq?: InputMaybe<FichierOperationHistoryRecordTypes>;
  nin?: InputMaybe<Array<FichierOperationHistoryRecordTypes>>;
};

export type FichierOperationInGroupInput = {
  commentaire?: InputMaybe<Scalars['String']['input']>;
  estCoInvestisseur: Scalars['Boolean']['input'];
  estHorsConfig: Scalars['Boolean']['input'];
  fileId: Scalars['String']['input'];
  natureDocumentRequisId?: InputMaybe<Scalars['Int']['input']>;
  operationGroupId: Scalars['Int']['input'];
};

export type FichierOperationSortInput = {
  commentaire?: InputMaybe<SortEnumType>;
  estCoInvestisseur?: InputMaybe<SortEnumType>;
  estHorsConfiguration?: InputMaybe<SortEnumType>;
  fichierOperationStatut?: InputMaybe<FichierOperationStatutSortInput>;
  fichierOperationStatutId?: InputMaybe<SortEnumType>;
  fileId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  natureDocumentRequis?: InputMaybe<NatureDocumentRequiSortInput>;
  natureDocumentRequisId?: InputMaybe<SortEnumType>;
  operation?: InputMaybe<OperationSortInput>;
  operationId?: InputMaybe<SortEnumType>;
  reponseConsultant?: InputMaybe<SortEnumType>;
};

export type FichierOperationStatut = {
  __typename?: 'FichierOperationStatut';
  fichierOperations: Array<FichierOperation>;
  id: Scalars['Int']['output'];
  statut: Scalars['String']['output'];
};


export type FichierOperationStatutFichierOperationsArgs = {
  where?: InputMaybe<FichierOperationFilterInput>;
};

export type FichierOperationStatutFilterInput = {
  and?: InputMaybe<Array<FichierOperationStatutFilterInput>>;
  fichierOperations?: InputMaybe<ListFilterInputTypeOfFichierOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FichierOperationStatutFilterInput>>;
  statut?: InputMaybe<StringOperationFilterInput>;
};

export type FichierOperationStatutSortInput = {
  id?: InputMaybe<SortEnumType>;
  statut?: InputMaybe<SortEnumType>;
};

export type FileMetadata = {
  __typename?: 'FileMetadata';
  actif?: Maybe<Scalars['Boolean']['output']>;
  creePar?: Maybe<Scalars['String']['output']>;
  dateDeCreation: Scalars['DateTime']['output'];
  dateDeDerniereModification: Scalars['DateTime']['output'];
  dateSignature?: Maybe<Scalars['DateTime']['output']>;
  denomination?: Maybe<Scalars['String']['output']>;
  derniereModificationPar?: Maybe<Scalars['String']['output']>;
  documentIdentifier?: Maybe<Scalars['String']['output']>;
  etatSignature?: Maybe<SignatureState>;
  extension?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  investisseurId?: Maybe<Scalars['Long']['output']>;
  permission?: Maybe<Permission>;
  typeDocumentCode?: Maybe<Scalars['String']['output']>;
  typeLibelle?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  urlExpireDate?: Maybe<Scalars['DateTime']['output']>;
  versionNumber?: Maybe<Scalars['Int']['output']>;
};

export type FileMetadataFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<FileMetadataFilterInput>>;
  creePar?: InputMaybe<StringOperationFilterInput>;
  dateDeCreation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeDerniereModification?: InputMaybe<DateTimeOperationFilterInput>;
  dateSignature?: InputMaybe<DateTimeOperationFilterInput>;
  denomination?: InputMaybe<StringOperationFilterInput>;
  derniereModificationPar?: InputMaybe<StringOperationFilterInput>;
  etatSignature?: InputMaybe<NullableOfSignatureStateOperationFilterInput>;
  extension?: InputMaybe<StringOperationFilterInput>;
  fileId?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<FileMetadataFilterInput>>;
  permission?: InputMaybe<NullableOfPermissionOperationFilterInput>;
  typeDocumentCode?: InputMaybe<StringOperationFilterInput>;
  url?: InputMaybe<StringOperationFilterInput>;
  urlExpireDate?: InputMaybe<DateTimeOperationFilterInput>;
  versionNumber?: InputMaybe<IntOperationFilterInput>;
};

export type FileMetadataUpdateInput = {
  actif?: InputMaybe<Scalars['Boolean']['input']>;
  denomination?: InputMaybe<Scalars['String']['input']>;
  extension?: InputMaybe<Scalars['String']['input']>;
  typeDocumentCode?: InputMaybe<Scalars['String']['input']>;
};

export type FilialeFacturation = {
  __typename?: 'FilialeFacturation';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate: Scalars['DateTime']['output'];
  gammeProduit: Array<GammeProduit>;
  libelle: Scalars['String']['output'];
  produit: Array<Produit>;
};


export type FilialeFacturationGammeProduitArgs = {
  where?: InputMaybe<GammeProduitFilterInput>;
};


export type FilialeFacturationProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type FilialeFacturationFilterInput = {
  and?: InputMaybe<Array<FilialeFacturationFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  gammeProduit?: InputMaybe<ListFilterInputTypeOfGammeProduitFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FilialeFacturationFilterInput>>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
};

export type FilialeFacturationSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type FloatOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  ngt?: InputMaybe<Scalars['Float']['input']>;
  ngte?: InputMaybe<Scalars['Float']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  nlt?: InputMaybe<Scalars['Float']['input']>;
  nlte?: InputMaybe<Scalars['Float']['input']>;
};

export type FonctionContact = {
  __typename?: 'FonctionContact';
  code: Scalars['Byte']['output'];
  contact: Array<Contact>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type FonctionContactContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};

export type FonctionContactFilterInput = {
  and?: InputMaybe<Array<FonctionContactFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FonctionContactFilterInput>>;
};

export type FondEvenementiel = {
  __typename?: 'FondEvenementiel';
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  libelle: Scalars['String']['output'];
  operations: Array<Operation>;
  produitFondEvenementiels: Array<ProduitFondEvenementiel>;
};


export type FondEvenementielOperationsArgs = {
  where?: InputMaybe<OperationFilterInput>;
};


export type FondEvenementielProduitFondEvenementielsArgs = {
  where?: InputMaybe<ProduitFondEvenementielFilterInput>;
};

export type FondEvenementielFilterInput = {
  and?: InputMaybe<Array<FondEvenementielFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  operations?: InputMaybe<ListFilterInputTypeOfOperationFilterInput>;
  or?: InputMaybe<Array<FondEvenementielFilterInput>>;
  produitFondEvenementiels?: InputMaybe<ListFilterInputTypeOfProduitFondEvenementielFilterInput>;
};

export type FondEvenementielSortInput = {
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type FondEvenementielsPaginatedCollectionSegment = {
  __typename?: 'FondEvenementielsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<FondEvenementiel>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Formation = {
  __typename?: 'Formation';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  equivalenceDeFormation: Array<EquivalenceDeFormation>;
  libelle: Scalars['String']['output'];
  type: Scalars['Byte']['output'];
  typeNavigation: FormationType;
};


export type FormationEquivalenceDeFormationArgs = {
  where?: InputMaybe<EquivalenceDeFormationFilterInput>;
};

export type FormationEtat = {
  __typename?: 'FormationEtat';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  inscriptionSession: Array<InscriptionSession>;
  libelle: Scalars['String']['output'];
};


export type FormationEtatInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};


export type FormationEtatInscriptionSessionArgs = {
  where?: InputMaybe<InscriptionSessionFilterInput>;
};

export type FormationEtatFilterInput = {
  and?: InputMaybe<Array<FormationEtatFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  inscriptionSession?: InputMaybe<ListFilterInputTypeOfInscriptionSessionFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationEtatFilterInput>>;
};

export type FormationFilterInput = {
  and?: InputMaybe<Array<FormationFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  equivalenceDeFormation?: InputMaybe<ListFilterInputTypeOfEquivalenceDeFormationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationFilterInput>>;
  type?: InputMaybe<ByteOperationFilterInput>;
  typeNavigation?: InputMaybe<FormationTypeFilterInput>;
};

export type FormationMotifAbandon = {
  __typename?: 'FormationMotifAbandon';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  libelle: Scalars['String']['output'];
};


export type FormationMotifAbandonInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};

export type FormationMotifAbandonFilterInput = {
  and?: InputMaybe<Array<FormationMotifAbandonFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationMotifAbandonFilterInput>>;
};

export type FormationNature = {
  __typename?: 'FormationNature';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  inscriptionSession: Array<InscriptionSession>;
  libelle: Scalars['String']['output'];
};


export type FormationNatureInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};


export type FormationNatureInscriptionSessionArgs = {
  where?: InputMaybe<InscriptionSessionFilterInput>;
};

export type FormationNatureFilterInput = {
  and?: InputMaybe<Array<FormationNatureFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  inscriptionSession?: InputMaybe<ListFilterInputTypeOfInscriptionSessionFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationNatureFilterInput>>;
};

export type FormationOrigine = {
  __typename?: 'FormationOrigine';
  code: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  inscriptionCycleCourt: Array<InscriptionCycleCourt>;
  libelle: Scalars['String']['output'];
};


export type FormationOrigineInscriptionCycleCourtArgs = {
  where?: InputMaybe<InscriptionCycleCourtFilterInput>;
};

export type FormationOrigineFilterInput = {
  and?: InputMaybe<Array<FormationOrigineFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  inscriptionCycleCourt?: InputMaybe<ListFilterInputTypeOfInscriptionCycleCourtFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationOrigineFilterInput>>;
};

export type FormationSaison = {
  __typename?: 'FormationSaison';
  actionDeFormation: Array<ActionDeFormation>;
  code: Scalars['Byte']['output'];
  codeSource: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  cursus: Array<Cursus>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type FormationSaisonActionDeFormationArgs = {
  where?: InputMaybe<ActionDeFormationFilterInput>;
};


export type FormationSaisonCursusArgs = {
  where?: InputMaybe<CursusFilterInput>;
};

export type FormationSaisonFilterInput = {
  actionDeFormation?: InputMaybe<ListFilterInputTypeOfActionDeFormationFilterInput>;
  and?: InputMaybe<Array<FormationSaisonFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  codeSource?: InputMaybe<StringOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursus?: InputMaybe<ListFilterInputTypeOfCursusFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationSaisonFilterInput>>;
};

export type FormationType = {
  __typename?: 'FormationType';
  actionDeFormation: Array<ActionDeFormation>;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  cursus: Array<Cursus>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formation: Array<Formation>;
  libelle: Scalars['String']['output'];
};


export type FormationTypeActionDeFormationArgs = {
  where?: InputMaybe<ActionDeFormationFilterInput>;
};


export type FormationTypeCursusArgs = {
  where?: InputMaybe<CursusFilterInput>;
};


export type FormationTypeFormationArgs = {
  where?: InputMaybe<FormationFilterInput>;
};

export type FormationTypeFilterInput = {
  actionDeFormation?: InputMaybe<ListFilterInputTypeOfActionDeFormationFilterInput>;
  and?: InputMaybe<Array<FormationTypeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cursus?: InputMaybe<ListFilterInputTypeOfCursusFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formation?: InputMaybe<ListFilterInputTypeOfFormationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormationTypeFilterInput>>;
};

export type FormeJuridique = {
  __typename?: 'FormeJuridique';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  personneMorale: Array<PersonneMorale>;
  type: Scalars['Byte']['output'];
  typeNavigation: TypeFormeJuridique;
};


export type FormeJuridiquePersonneMoraleArgs = {
  where?: InputMaybe<PersonneMoraleFilterInput>;
};

export type FormeJuridiqueFilterInput = {
  and?: InputMaybe<Array<FormeJuridiqueFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormeJuridiqueFilterInput>>;
  personneMorale?: InputMaybe<ListFilterInputTypeOfPersonneMoraleFilterInput>;
  type?: InputMaybe<ByteOperationFilterInput>;
  typeNavigation?: InputMaybe<TypeFormeJuridiqueFilterInput>;
};

export type GammeProduit = {
  __typename?: 'GammeProduit';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  filialeFacturation: Scalars['Byte']['output'];
  filialeFacturationNavigation: FilialeFacturation;
  libelle: Scalars['String']['output'];
  type2Produit: Array<Type2Produit>;
};


export type GammeProduitType2ProduitArgs = {
  where?: InputMaybe<Type2ProduitFilterInput>;
};

export type GammeProduitFilterInput = {
  and?: InputMaybe<Array<GammeProduitFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  filialeFacturation?: InputMaybe<ByteOperationFilterInput>;
  filialeFacturationNavigation?: InputMaybe<FilialeFacturationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GammeProduitFilterInput>>;
  type2Produit?: InputMaybe<ListFilterInputTypeOfType2ProduitFilterInput>;
};

export type GammeProduitSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  filialeFacturation?: InputMaybe<SortEnumType>;
  filialeFacturationNavigation?: InputMaybe<FilialeFacturationSortInput>;
  libelle?: InputMaybe<SortEnumType>;
};

export type GedCategorie = {
  __typename?: 'GedCategorie';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  gedTypeDocument: Array<GedTypeDocument>;
  libelle: Scalars['String']['output'];
};


export type GedCategorieGedTypeDocumentArgs = {
  where?: InputMaybe<GedTypeDocumentFilterInput>;
};

export type GedCategorieFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<GedCategorieFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  gedTypeDocument?: InputMaybe<ListFilterInputTypeOfGedTypeDocumentFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GedCategorieFilterInput>>;
};

export type GedTypeDocument = {
  __typename?: 'GedTypeDocument';
  actif?: Maybe<Scalars['Boolean']['output']>;
  appartenanceDocument: AppartenanceDocument;
  appartenanceDocumentId: Scalars['Byte']['output'];
  code: Scalars['String']['output'];
  codeCategorie: Scalars['String']['output'];
  codeCategorieNavigation: GedCategorie;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  dernierDocumentVisible: Scalars['Boolean']['output'];
  libelle: Scalars['String']['output'];
  suppressionNonDefinitive: Scalars['Boolean']['output'];
};

export type GedTypeDocumentFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<GedTypeDocumentFilterInput>>;
  appartenanceDocument?: InputMaybe<AppartenanceDocumentFilterInput>;
  appartenanceDocumentId?: InputMaybe<ByteOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  codeCategorie?: InputMaybe<StringOperationFilterInput>;
  codeCategorieNavigation?: InputMaybe<GedCategorieFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  dernierDocumentVisible?: InputMaybe<BooleanOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<GedTypeDocumentFilterInput>>;
  personnePhysiqueDocument?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueDocumentFilterInput>;
  suppressionNonDefinitive?: InputMaybe<BooleanOperationFilterInput>;
};

export type GestionnaireWorkLoad = {
  __typename?: 'GestionnaireWorkLoad';
  displayName: Scalars['String']['output'];
  groupeGestionnaire: GroupeGestionnaire;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  isAdmin: Scalars['Boolean']['output'];
  notifications: Array<Notification>;
  nouveauActeDeGestion: Scalars['Int']['output'];
  nouveauSouscription: Scalars['Int']['output'];
  retourInstance: Scalars['Int']['output'];
  retourNC: Scalars['Int']['output'];
  serviceAllowedEnvelopeIds?: Maybe<Array<Scalars['Int']['output']>>;
  tokenId: Scalars['String']['output'];
  withGroupeAccessExtended: Scalars['Boolean']['output'];
};


export type GestionnaireWorkLoadNotificationsArgs = {
  where?: InputMaybe<NotificationFilterInput>;
};

export type GestionnaireWorkLoadFilterInput = {
  and?: InputMaybe<Array<GestionnaireWorkLoadFilterInput>>;
  displayName?: InputMaybe<StringOperationFilterInput>;
  groupeGestionnaire?: InputMaybe<GroupeGestionnaireOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isAdmin?: InputMaybe<BooleanOperationFilterInput>;
  notifications?: InputMaybe<ListFilterInputTypeOfNotificationFilterInput>;
  nouveauActeDeGestion?: InputMaybe<IntOperationFilterInput>;
  nouveauSouscription?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<GestionnaireWorkLoadFilterInput>>;
  retourInstance?: InputMaybe<IntOperationFilterInput>;
  retourNC?: InputMaybe<IntOperationFilterInput>;
  tokenId?: InputMaybe<StringOperationFilterInput>;
  withGroupeAccessExtended?: InputMaybe<BooleanOperationFilterInput>;
};

export type GestionnaireWorkLoadSortInput = {
  displayName?: InputMaybe<SortEnumType>;
  groupeGestionnaire?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isAdmin?: InputMaybe<SortEnumType>;
  nouveauActeDeGestion?: InputMaybe<SortEnumType>;
  nouveauSouscription?: InputMaybe<SortEnumType>;
  retourInstance?: InputMaybe<SortEnumType>;
  retourNC?: InputMaybe<SortEnumType>;
  tokenId?: InputMaybe<SortEnumType>;
  withGroupeAccessExtended?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type GestionnaireWorkLoadsCollectionSegment = {
  __typename?: 'GestionnaireWorkLoadsCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<GestionnaireWorkLoad>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export enum GroupeGestionnaire {
  Psi = 'PSI',
  Svp = 'SVP'
}

export type GroupeGestionnaireOperationFilterInput = {
  eq?: InputMaybe<GroupeGestionnaire>;
  in?: InputMaybe<Array<GroupeGestionnaire>>;
  neq?: InputMaybe<GroupeGestionnaire>;
  nin?: InputMaybe<Array<GroupeGestionnaire>>;
};

export type Habilitation = {
  __typename?: 'Habilitation';
  actif: Scalars['Boolean']['output'];
  associationProNom: Scalars['Boolean']['output'];
  cifPartenaireCode: Scalars['Boolean']['output'];
  code?: Maybe<Scalars['String']['output']>;
  consultantHabilitation: Array<ConsultantHabilitation>;
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  garantAdresse: Scalars['Boolean']['output'];
  garantNom: Scalars['Boolean']['output'];
  habilitationInscription: Array<HabilitationInscription>;
  habilitationRcp: Array<HabilitationRcp>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  libelle?: Maybe<Scalars['String']['output']>;
  mediateur: Scalars['Boolean']['output'];
  partenaireCourtierFinancement1: Scalars['Boolean']['output'];
  partenaireCourtierFinancement2: Scalars['Boolean']['output'];
  produit: Array<Produit>;
};


export type HabilitationConsultantHabilitationArgs = {
  where?: InputMaybe<ConsultantHabilitationFilterInput>;
};


export type HabilitationHabilitationInscriptionArgs = {
  where?: InputMaybe<HabilitationInscriptionFilterInput>;
};


export type HabilitationHabilitationRcpArgs = {
  where?: InputMaybe<HabilitationRcpFilterInput>;
};


export type HabilitationProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type HabilitationFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<HabilitationFilterInput>>;
  associationProNom?: InputMaybe<BooleanOperationFilterInput>;
  cifPartenaireCode?: InputMaybe<BooleanOperationFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantHabilitation?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  garantAdresse?: InputMaybe<BooleanOperationFilterInput>;
  garantNom?: InputMaybe<BooleanOperationFilterInput>;
  habilitationInscription?: InputMaybe<ListFilterInputTypeOfHabilitationInscriptionFilterInput>;
  habilitationRcp?: InputMaybe<ListFilterInputTypeOfHabilitationRcpFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mediateur?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<HabilitationFilterInput>>;
  partenaireCourtierFinancement1?: InputMaybe<BooleanOperationFilterInput>;
  partenaireCourtierFinancement2?: InputMaybe<BooleanOperationFilterInput>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
};

export type HabilitationInscription = {
  __typename?: 'HabilitationInscription';
  actif: Scalars['Boolean']['output'];
  dateAnniversaire?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  dateFinFixe?: Maybe<Scalars['DateTime']['output']>;
  dureeValidite?: Maybe<Scalars['Int']['output']>;
  habilitation?: Maybe<Habilitation>;
  habilitationId?: Maybe<Scalars['Long']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  obligatoire?: Maybe<Scalars['Boolean']['output']>;
  organismeDefautCode?: Maybe<Scalars['String']['output']>;
  organismeDefautCodeNavigation?: Maybe<Organisme>;
};

export type HabilitationInscriptionCategorie = {
  __typename?: 'HabilitationInscriptionCategorie';
  code?: Maybe<Scalars['String']['output']>;
  consultantHabilitationInscriptionCategorie: Array<ConsultantHabilitationInscriptionCategorie>;
  id: Scalars['Int']['output'];
  libelle?: Maybe<Scalars['String']['output']>;
  mandataire?: Maybe<Scalars['String']['output']>;
};


export type HabilitationInscriptionCategorieConsultantHabilitationInscriptionCategorieArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
};

export type HabilitationInscriptionCategorieFilterInput = {
  and?: InputMaybe<Array<HabilitationInscriptionCategorieFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantHabilitationInscriptionCategorie?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionCategorieFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mandataire?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<HabilitationInscriptionCategorieFilterInput>>;
};

export type HabilitationInscriptionFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<HabilitationInscriptionFilterInput>>;
  dateAnniversaire?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinFixe?: InputMaybe<DateTimeOperationFilterInput>;
  dureeValidite?: InputMaybe<IntOperationFilterInput>;
  habilitation?: InputMaybe<HabilitationFilterInput>;
  habilitationId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  obligatoire?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<HabilitationInscriptionFilterInput>>;
  organismeDefautCode?: InputMaybe<StringOperationFilterInput>;
  organismeDefautCodeNavigation?: InputMaybe<OrganismeFilterInput>;
};

export type HabilitationRcp = {
  __typename?: 'HabilitationRcp';
  actif?: Maybe<Scalars['Boolean']['output']>;
  dateAnniversaire?: Maybe<Scalars['DateTime']['output']>;
  habilitation: Habilitation;
  habilitationId: Scalars['Long']['output'];
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  obligatoire?: Maybe<Scalars['Boolean']['output']>;
  typeRcpCode: Scalars['String']['output'];
  typeRcpCodeNavigation: TypeRcp;
};

export type HabilitationRcpFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<HabilitationRcpFilterInput>>;
  dateAnniversaire?: InputMaybe<DateTimeOperationFilterInput>;
  habilitation?: InputMaybe<HabilitationFilterInput>;
  habilitationId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  obligatoire?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<HabilitationRcpFilterInput>>;
  typeRcpCode?: InputMaybe<StringOperationFilterInput>;
  typeRcpCodeNavigation?: InputMaybe<TypeRcpFilterInput>;
};

export type HabilitationSortInput = {
  actif?: InputMaybe<SortEnumType>;
  associationProNom?: InputMaybe<SortEnumType>;
  cifPartenaireCode?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateDebut?: InputMaybe<SortEnumType>;
  dateFin?: InputMaybe<SortEnumType>;
  garantAdresse?: InputMaybe<SortEnumType>;
  garantNom?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  mediateur?: InputMaybe<SortEnumType>;
  partenaireCourtierFinancement1?: InputMaybe<SortEnumType>;
  partenaireCourtierFinancement2?: InputMaybe<SortEnumType>;
};

export type InscriptionCycleCourt = {
  __typename?: 'InscriptionCycleCourt';
  codeEtat?: Maybe<Scalars['Byte']['output']>;
  codeEtatNavigation?: Maybe<FormationEtat>;
  codeMotifAbandon?: Maybe<Scalars['Byte']['output']>;
  codeMotifAbandonNavigation?: Maybe<FormationMotifAbandon>;
  codeNature?: Maybe<Scalars['Byte']['output']>;
  codeNatureNavigation?: Maybe<FormationNature>;
  codeOrigine?: Maybe<Scalars['String']['output']>;
  codeOrigineNavigation?: Maybe<FormationOrigine>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  cycleCourt?: Maybe<CycleCourt>;
  cycleCourtId?: Maybe<Scalars['Long']['output']>;
  dateDebutElearning?: Maybe<Scalars['DateTime']['output']>;
  dateFacture?: Maybe<Scalars['DateTime']['output']>;
  dateInscription?: Maybe<Scalars['DateTime']['output']>;
  dateValidationQCM?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formationValide?: Maybe<Scalars['Byte']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  numeroFacture?: Maybe<Scalars['String']['output']>;
};

export type InscriptionCycleCourtFilterInput = {
  and?: InputMaybe<Array<InscriptionCycleCourtFilterInput>>;
  codeEtat?: InputMaybe<ByteOperationFilterInput>;
  codeEtatNavigation?: InputMaybe<FormationEtatFilterInput>;
  codeMotifAbandon?: InputMaybe<ByteOperationFilterInput>;
  codeMotifAbandonNavigation?: InputMaybe<FormationMotifAbandonFilterInput>;
  codeNature?: InputMaybe<ByteOperationFilterInput>;
  codeNatureNavigation?: InputMaybe<FormationNatureFilterInput>;
  codeOrigine?: InputMaybe<StringOperationFilterInput>;
  codeOrigineNavigation?: InputMaybe<FormationOrigineFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cycleCourt?: InputMaybe<CycleCourtFilterInput>;
  cycleCourtId?: InputMaybe<LongOperationFilterInput>;
  dateDebutElearning?: InputMaybe<DateTimeOperationFilterInput>;
  dateFacture?: InputMaybe<DateTimeOperationFilterInput>;
  dateInscription?: InputMaybe<DateTimeOperationFilterInput>;
  dateValidationQCM?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formationValide?: InputMaybe<ByteOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  numeroFacture?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InscriptionCycleCourtFilterInput>>;
};

export type InscriptionSession = {
  __typename?: 'InscriptionSession';
  codeEtat?: Maybe<Scalars['Byte']['output']>;
  codeEtatNavigation?: Maybe<FormationEtat>;
  codeNature?: Maybe<Scalars['Byte']['output']>;
  codeNatureNavigation?: Maybe<FormationNature>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateValidationFormation?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formationValide?: Maybe<Scalars['Byte']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['Long']['output']>;
};

export type InscriptionSessionFilterInput = {
  and?: InputMaybe<Array<InscriptionSessionFilterInput>>;
  codeEtat?: InputMaybe<ByteOperationFilterInput>;
  codeEtatNavigation?: InputMaybe<FormationEtatFilterInput>;
  codeNature?: InputMaybe<ByteOperationFilterInput>;
  codeNatureNavigation?: InputMaybe<FormationNatureFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateValidationFormation?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formationValide?: InputMaybe<ByteOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<InscriptionSessionFilterInput>>;
  session?: InputMaybe<SessionFilterInput>;
  sessionId?: InputMaybe<LongOperationFilterInput>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type Investisseur = {
  __typename?: 'Investisseur';
  arianeId?: Maybe<Scalars['Long']['output']>;
  cessionHistoInvestisseur: Array<CessionHistoInvestisseur>;
  code: Scalars['String']['output'];
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateConnaissance?: Maybe<Scalars['DateTime']['output']>;
  dateDernierScanAccuity?: Maybe<Scalars['DateTime']['output']>;
  dateEntreeRelation?: Maybe<Scalars['DateTime']['output']>;
  dateMajScanAccuityRequis?: Maybe<Scalars['DateTime']['output']>;
  dateSuppression?: Maybe<Scalars['DateTime']['output']>;
  detenteurPatrimoineActif: Array<DetenteurPatrimoineActif>;
  detenteurPatrimoineChargeRevenu: Array<DetenteurPatrimoineChargeRevenu>;
  detenteurPatrimoinePassif: Array<DetenteurPatrimoinePassif>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  filesMetadata: Array<FileMetadata>;
  id: Scalars['Long']['output'];
  idSourceAramis?: Maybe<Scalars['String']['output']>;
  idSourceDiapason?: Maybe<Scalars['String']['output']>;
  inverseRecommandePar: Array<Investisseur>;
  investisseurCommande: Array<InvestisseurCommande>;
  investisseurConsultant: Array<InvestisseurConsultant>;
  investisseurEntite?: Maybe<InvestisseurEntite>;
  investisseurObjectif: Array<InvestisseurObjectif>;
  investisseurQpi?: Maybe<InvestisseurQpi>;
  logiqueSuppression?: Maybe<Scalars['Boolean']['output']>;
  modeCommunication?: Maybe<Scalars['String']['output']>;
  modeCommunicationNavigation?: Maybe<ModeCommunication>;
  mouvement: Array<Mouvement>;
  note: Array<Note>;
  objectifMontantEpargneMensuel?: Maybe<Scalars['Decimal']['output']>;
  objectifMontantEpargnePrecaution?: Maybe<Scalars['Decimal']['output']>;
  origineContactCode?: Maybe<Scalars['String']['output']>;
  origineContactCodeNavigation?: Maybe<OrigineContact>;
  origineSaisie?: Maybe<Scalars['Byte']['output']>;
  origineSaisieNavigation?: Maybe<OrigineData>;
  patrimoineFiscalite?: Maybe<PatrimoineFiscalite>;
  personneMorale: Array<PersonneMorale>;
  personnePhysique: Array<PersonnePhysique>;
  recommandePar?: Maybe<Investisseur>;
  recommandeParId?: Maybe<Scalars['Long']['output']>;
  scanAccuityRequis?: Maybe<Scalars['Boolean']['output']>;
  statutCode: Scalars['String']['output'];
  statutCodeNavigation: TypeStatutInvestisseur;
};


export type InvestisseurCessionHistoInvestisseurArgs = {
  where?: InputMaybe<CessionHistoInvestisseurFilterInput>;
};


export type InvestisseurDetenteurPatrimoineActifArgs = {
  where?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
};


export type InvestisseurDetenteurPatrimoineChargeRevenuArgs = {
  where?: InputMaybe<DetenteurPatrimoineChargeRevenuFilterInput>;
};


export type InvestisseurDetenteurPatrimoinePassifArgs = {
  where?: InputMaybe<DetenteurPatrimoinePassifFilterInput>;
};


export type InvestisseurInverseRecommandeParArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};


export type InvestisseurInvestisseurCommandeArgs = {
  where?: InputMaybe<InvestisseurCommandeFilterInput>;
};


export type InvestisseurInvestisseurConsultantArgs = {
  where?: InputMaybe<InvestisseurConsultantFilterInput>;
};


export type InvestisseurInvestisseurObjectifArgs = {
  where?: InputMaybe<InvestisseurObjectifFilterInput>;
};


export type InvestisseurMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};


export type InvestisseurNoteArgs = {
  where?: InputMaybe<NoteFilterInput>;
};


export type InvestisseurPersonneMoraleArgs = {
  where?: InputMaybe<PersonneMoraleFilterInput>;
};


export type InvestisseurPersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type InvestisseurCommande = {
  __typename?: 'InvestisseurCommande';
  commande: Commande;
  commandeId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  investisseur?: Maybe<Investisseur>;
  investisseurId?: Maybe<Scalars['Long']['output']>;
  ordre: Scalars['Byte']['output'];
};

export type InvestisseurCommandeFilterInput = {
  and?: InputMaybe<Array<InvestisseurCommandeFilterInput>>;
  commande?: InputMaybe<CommandeFilterInput>;
  commandeId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<InvestisseurCommandeFilterInput>>;
  ordre?: InputMaybe<ByteOperationFilterInput>;
};

export type InvestisseurConsultant = {
  __typename?: 'InvestisseurConsultant';
  actif: Scalars['Int']['output'];
  arianeId?: Maybe<Scalars['Long']['output']>;
  batchDateUpdate?: Maybe<Scalars['DateTime']['output']>;
  consultant: Consultant;
  consultantId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  dateActivation: Scalars['DateTime']['output'];
  dateDesactivation?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  typeRelationInvestisseurConsultantCode: Scalars['String']['output'];
  typeRelationInvestisseurConsultantCodeNavigation: TypeRelationInvestisseurConsultant;
};

export type InvestisseurConsultantFilterInput = {
  actif?: InputMaybe<IntOperationFilterInput>;
  and?: InputMaybe<Array<InvestisseurConsultantFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  batchDateUpdate?: InputMaybe<DateTimeOperationFilterInput>;
  consultant?: InputMaybe<ConsultantFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateActivation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDesactivation?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<InvestisseurConsultantFilterInput>>;
  typeRelationInvestisseurConsultantCode?: InputMaybe<StringOperationFilterInput>;
  typeRelationInvestisseurConsultantCodeNavigation?: InputMaybe<TypeRelationInvestisseurConsultantFilterInput>;
};

/** A segment of a collection. */
export type InvestisseurConsultantPaginatedCollectionSegment = {
  __typename?: 'InvestisseurConsultantPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<InvestisseurConsultant>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type InvestisseurConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  arianeId?: InputMaybe<SortEnumType>;
  batchDateUpdate?: InputMaybe<SortEnumType>;
  consultant?: InputMaybe<ConsultantSortInput>;
  consultantId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateActivation?: InputMaybe<SortEnumType>;
  dateDesactivation?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  typeRelationInvestisseurConsultantCode?: InputMaybe<SortEnumType>;
  typeRelationInvestisseurConsultantCodeNavigation?: InputMaybe<TypeRelationInvestisseurConsultantSortInput>;
};

export type InvestisseurEntite = {
  __typename?: 'InvestisseurEntite';
  code: Scalars['String']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  personneMorale?: Maybe<PersonneMorale>;
  personnePhysique?: Maybe<PersonnePhysique>;
  type: InvestisseurType;
};

export type InvestisseurFilterInput = {
  and?: InputMaybe<Array<InvestisseurFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  cessionHistoInvestisseur?: InputMaybe<ListFilterInputTypeOfCessionHistoInvestisseurFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateConnaissance?: InputMaybe<DateTimeOperationFilterInput>;
  dateDernierScanAccuity?: InputMaybe<DateTimeOperationFilterInput>;
  dateEntreeRelation?: InputMaybe<DateTimeOperationFilterInput>;
  dateMajScanAccuityRequis?: InputMaybe<DateTimeOperationFilterInput>;
  dateSuppression?: InputMaybe<DateTimeOperationFilterInput>;
  detenteurPatrimoineActif?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoineActifFilterInput>;
  detenteurPatrimoineChargeRevenu?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoineChargeRevenuFilterInput>;
  detenteurPatrimoinePassif?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoinePassifFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSourceAramis?: InputMaybe<StringOperationFilterInput>;
  idSourceDiapason?: InputMaybe<StringOperationFilterInput>;
  inverseRecommandePar?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  investisseurCommande?: InputMaybe<ListFilterInputTypeOfInvestisseurCommandeFilterInput>;
  investisseurConsultant?: InputMaybe<ListFilterInputTypeOfInvestisseurConsultantFilterInput>;
  investisseurObjectif?: InputMaybe<ListFilterInputTypeOfInvestisseurObjectifFilterInput>;
  investisseurQpi?: InputMaybe<InvestisseurQpiFilterInput>;
  logiqueSuppression?: InputMaybe<BooleanOperationFilterInput>;
  modeCommunication?: InputMaybe<StringOperationFilterInput>;
  modeCommunicationNavigation?: InputMaybe<ModeCommunicationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  note?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  objectifMontantEpargneMensuel?: InputMaybe<DecimalOperationFilterInput>;
  objectifMontantEpargnePrecaution?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<InvestisseurFilterInput>>;
  origineContactCode?: InputMaybe<StringOperationFilterInput>;
  origineContactCodeNavigation?: InputMaybe<OrigineContactFilterInput>;
  origineSaisie?: InputMaybe<ByteOperationFilterInput>;
  origineSaisieNavigation?: InputMaybe<OrigineDataFilterInput>;
  patrimoineFiscalite?: InputMaybe<PatrimoineFiscaliteFilterInput>;
  personneMorale?: InputMaybe<ListFilterInputTypeOfPersonneMoraleFilterInput>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  recommandePar?: InputMaybe<InvestisseurFilterInput>;
  recommandeParId?: InputMaybe<LongOperationFilterInput>;
  scanAccuityRequis?: InputMaybe<BooleanOperationFilterInput>;
  statutCode?: InputMaybe<StringOperationFilterInput>;
  statutCodeNavigation?: InputMaybe<TypeStatutInvestisseurFilterInput>;
};

export type InvestisseurObjectif = {
  __typename?: 'InvestisseurObjectif';
  arianeId?: Maybe<Scalars['Long']['output']>;
  commun?: Maybe<Scalars['UUID']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  horizonDelais?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Long']['output'];
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  montant?: Maybe<Scalars['Decimal']['output']>;
  precisions?: Maybe<Scalars['String']['output']>;
  priorite: Scalars['Int']['output'];
  typeObjectifInvestisseurCode: Scalars['String']['output'];
  typeObjectifInvestisseurCodeNavigation: TypeObjectifInvestisseur;
};

export type InvestisseurObjectifFilterInput = {
  and?: InputMaybe<Array<InvestisseurObjectifFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  commun?: InputMaybe<UuidOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  horizonDelais?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  montant?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<InvestisseurObjectifFilterInput>>;
  precisions?: InputMaybe<StringOperationFilterInput>;
  priorite?: InputMaybe<IntOperationFilterInput>;
  typeObjectifInvestisseurCode?: InputMaybe<StringOperationFilterInput>;
  typeObjectifInvestisseurCodeNavigation?: InputMaybe<TypeObjectifInvestisseurFilterInput>;
};

export type InvestisseurObjectifSortInput = {
  arianeId?: InputMaybe<SortEnumType>;
  commun?: InputMaybe<SortEnumType>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  horizonDelais?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  montant?: InputMaybe<SortEnumType>;
  precisions?: InputMaybe<SortEnumType>;
  priorite?: InputMaybe<SortEnumType>;
  typeObjectifInvestisseurCode?: InputMaybe<SortEnumType>;
  typeObjectifInvestisseurCodeNavigation?: InputMaybe<TypeObjectifInvestisseurSortInput>;
};

export type InvestisseurQpi = {
  __typename?: 'InvestisseurQpi';
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateCalcul?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  motivationDerogation?: Maybe<Scalars['String']['output']>;
  profilCalculCode?: Maybe<Scalars['String']['output']>;
  profilCalculCodeNavigation?: Maybe<ProfilQpi>;
  profilDerogationCode?: Maybe<Scalars['String']['output']>;
  profilDerogationCodeNavigation?: Maybe<ProfilQpi>;
  srriCalcul?: Maybe<Scalars['Int']['output']>;
  srriDerogation?: Maybe<Scalars['Int']['output']>;
};

export type InvestisseurQpiFilterInput = {
  and?: InputMaybe<Array<InvestisseurQpiFilterInput>>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateCalcul?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  motivationDerogation?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<InvestisseurQpiFilterInput>>;
  profilCalculCode?: InputMaybe<StringOperationFilterInput>;
  profilCalculCodeNavigation?: InputMaybe<ProfilQpiFilterInput>;
  profilDerogationCode?: InputMaybe<StringOperationFilterInput>;
  profilDerogationCodeNavigation?: InputMaybe<ProfilQpiFilterInput>;
  srriCalcul?: InputMaybe<IntOperationFilterInput>;
  srriDerogation?: InputMaybe<IntOperationFilterInput>;
};

export type InvestisseurQpiSortInput = {
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateCalcul?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  motivationDerogation?: InputMaybe<SortEnumType>;
  profilCalculCode?: InputMaybe<SortEnumType>;
  profilCalculCodeNavigation?: InputMaybe<ProfilQpiSortInput>;
  profilDerogationCode?: InputMaybe<SortEnumType>;
  profilDerogationCodeNavigation?: InputMaybe<ProfilQpiSortInput>;
  srriCalcul?: InputMaybe<SortEnumType>;
  srriDerogation?: InputMaybe<SortEnumType>;
};

export type InvestisseurSortInput = {
  arianeId?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateConnaissance?: InputMaybe<SortEnumType>;
  dateDernierScanAccuity?: InputMaybe<SortEnumType>;
  dateEntreeRelation?: InputMaybe<SortEnumType>;
  dateMajScanAccuityRequis?: InputMaybe<SortEnumType>;
  dateSuppression?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSourceAramis?: InputMaybe<SortEnumType>;
  idSourceDiapason?: InputMaybe<SortEnumType>;
  investisseurQpi?: InputMaybe<InvestisseurQpiSortInput>;
  logiqueSuppression?: InputMaybe<SortEnumType>;
  modeCommunication?: InputMaybe<SortEnumType>;
  modeCommunicationNavigation?: InputMaybe<ModeCommunicationSortInput>;
  objectifMontantEpargneMensuel?: InputMaybe<SortEnumType>;
  objectifMontantEpargnePrecaution?: InputMaybe<SortEnumType>;
  origineContactCode?: InputMaybe<SortEnumType>;
  origineContactCodeNavigation?: InputMaybe<OrigineContactSortInput>;
  origineSaisie?: InputMaybe<SortEnumType>;
  origineSaisieNavigation?: InputMaybe<OrigineDataSortInput>;
  patrimoineFiscalite?: InputMaybe<PatrimoineFiscaliteSortInput>;
  recommandePar?: InputMaybe<InvestisseurSortInput>;
  recommandeParId?: InputMaybe<SortEnumType>;
  scanAccuityRequis?: InputMaybe<SortEnumType>;
  statutCode?: InputMaybe<SortEnumType>;
  statutCodeNavigation?: InputMaybe<TypeStatutInvestisseurSortInput>;
};

export enum InvestisseurType {
  PersonneMorale = 'PERSONNE_MORALE',
  PersonnePhysique = 'PERSONNE_PHYSIQUE'
}

/** A segment of a collection. */
export type InvestisseursByNamePaginatedCollectionSegment = {
  __typename?: 'InvestisseursByNamePaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Investisseur>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

/** A segment of a collection. */
export type InvestisseursPaginatedCollectionSegment = {
  __typename?: 'InvestisseursPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Investisseur>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ListFilterInputTypeOfAbonnementConsultantFilterInput = {
  all?: InputMaybe<AbonnementConsultantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AbonnementConsultantFilterInput>;
  some?: InputMaybe<AbonnementConsultantFilterInput>;
};

export type ListFilterInputTypeOfActionDeFormationFilterInput = {
  all?: InputMaybe<ActionDeFormationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ActionDeFormationFilterInput>;
  some?: InputMaybe<ActionDeFormationFilterInput>;
};

export type ListFilterInputTypeOfActionDeFormationSessionFilterInput = {
  all?: InputMaybe<ActionDeFormationSessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ActionDeFormationSessionFilterInput>;
  some?: InputMaybe<ActionDeFormationSessionFilterInput>;
};

export type ListFilterInputTypeOfAdhesionConsultantFilterInput = {
  all?: InputMaybe<AdhesionConsultantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AdhesionConsultantFilterInput>;
  some?: InputMaybe<AdhesionConsultantFilterInput>;
};

export type ListFilterInputTypeOfAdresseFilterInput = {
  all?: InputMaybe<AdresseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<AdresseFilterInput>;
  some?: InputMaybe<AdresseFilterInput>;
};

export type ListFilterInputTypeOfBoxFileFilterInput = {
  all?: InputMaybe<BoxFileFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BoxFileFilterInput>;
  some?: InputMaybe<BoxFileFilterInput>;
};

export type ListFilterInputTypeOfCessionHistoCommandeFilterInput = {
  all?: InputMaybe<CessionHistoCommandeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CessionHistoCommandeFilterInput>;
  some?: InputMaybe<CessionHistoCommandeFilterInput>;
};

export type ListFilterInputTypeOfCessionHistoInvestisseurFilterInput = {
  all?: InputMaybe<CessionHistoInvestisseurFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CessionHistoInvestisseurFilterInput>;
  some?: InputMaybe<CessionHistoInvestisseurFilterInput>;
};

export type ListFilterInputTypeOfCommandeFilterInput = {
  all?: InputMaybe<CommandeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CommandeFilterInput>;
  some?: InputMaybe<CommandeFilterInput>;
};

export type ListFilterInputTypeOfConseillerCommandeFilterInput = {
  all?: InputMaybe<ConseillerCommandeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConseillerCommandeFilterInput>;
  some?: InputMaybe<ConseillerCommandeFilterInput>;
};

export type ListFilterInputTypeOfConsultantContratFilterInput = {
  all?: InputMaybe<ConsultantContratFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantContratFilterInput>;
  some?: InputMaybe<ConsultantContratFilterInput>;
};

export type ListFilterInputTypeOfConsultantDistinctionFilterInput = {
  all?: InputMaybe<ConsultantDistinctionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantDistinctionFilterInput>;
  some?: InputMaybe<ConsultantDistinctionFilterInput>;
};

export type ListFilterInputTypeOfConsultantFilterInput = {
  all?: InputMaybe<ConsultantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantFilterInput>;
  some?: InputMaybe<ConsultantFilterInput>;
};

export type ListFilterInputTypeOfConsultantHabilitationFilterInput = {
  all?: InputMaybe<ConsultantHabilitationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantHabilitationFilterInput>;
  some?: InputMaybe<ConsultantHabilitationFilterInput>;
};

export type ListFilterInputTypeOfConsultantHabilitationInscriptionCategorieFilterInput = {
  all?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
  some?: InputMaybe<ConsultantHabilitationInscriptionCategorieFilterInput>;
};

export type ListFilterInputTypeOfConsultantHabilitationInscriptionCategorieMandantFilterInput = {
  all?: InputMaybe<ConsultantHabilitationInscriptionCategorieMandantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantHabilitationInscriptionCategorieMandantFilterInput>;
  some?: InputMaybe<ConsultantHabilitationInscriptionCategorieMandantFilterInput>;
};

export type ListFilterInputTypeOfConsultantHabilitationInscriptionFilterInput = {
  all?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
  some?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
};

export type ListFilterInputTypeOfConsultantHabilitationsFictivesFilterInput = {
  all?: InputMaybe<ConsultantHabilitationsFictivesFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantHabilitationsFictivesFilterInput>;
  some?: InputMaybe<ConsultantHabilitationsFictivesFilterInput>;
};

export type ListFilterInputTypeOfConsultantMouvementFilterInput = {
  all?: InputMaybe<ConsultantMouvementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantMouvementFilterInput>;
  some?: InputMaybe<ConsultantMouvementFilterInput>;
};

export type ListFilterInputTypeOfConsultantOrganisationFilterInput = {
  all?: InputMaybe<ConsultantOrganisationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantOrganisationFilterInput>;
  some?: InputMaybe<ConsultantOrganisationFilterInput>;
};

export type ListFilterInputTypeOfConsultantRcpFilterInput = {
  all?: InputMaybe<ConsultantRcpFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantRcpFilterInput>;
  some?: InputMaybe<ConsultantRcpFilterInput>;
};

export type ListFilterInputTypeOfConsultantStatutCommercialFilterInput = {
  all?: InputMaybe<ConsultantStatutCommercialFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantStatutCommercialFilterInput>;
  some?: InputMaybe<ConsultantStatutCommercialFilterInput>;
};

export type ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput = {
  all?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
  some?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};

export type ListFilterInputTypeOfContactFilterInput = {
  all?: InputMaybe<ContactFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ContactFilterInput>;
  some?: InputMaybe<ContactFilterInput>;
};

export type ListFilterInputTypeOfCursusCycleCourtFilterInput = {
  all?: InputMaybe<CursusCycleCourtFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CursusCycleCourtFilterInput>;
  some?: InputMaybe<CursusCycleCourtFilterInput>;
};

export type ListFilterInputTypeOfCursusFilterInput = {
  all?: InputMaybe<CursusFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CursusFilterInput>;
  some?: InputMaybe<CursusFilterInput>;
};

export type ListFilterInputTypeOfCursusFormationFilterInput = {
  all?: InputMaybe<CursusFormationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CursusFormationFilterInput>;
  some?: InputMaybe<CursusFormationFilterInput>;
};

export type ListFilterInputTypeOfCycleCourtFilterInput = {
  all?: InputMaybe<CycleCourtFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<CycleCourtFilterInput>;
  some?: InputMaybe<CycleCourtFilterInput>;
};

export type ListFilterInputTypeOfDetenteurPatrimoineActifFilterInput = {
  all?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
  some?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
};

export type ListFilterInputTypeOfDetenteurPatrimoineChargeRevenuFilterInput = {
  all?: InputMaybe<DetenteurPatrimoineChargeRevenuFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DetenteurPatrimoineChargeRevenuFilterInput>;
  some?: InputMaybe<DetenteurPatrimoineChargeRevenuFilterInput>;
};

export type ListFilterInputTypeOfDetenteurPatrimoinePassifFilterInput = {
  all?: InputMaybe<DetenteurPatrimoinePassifFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DetenteurPatrimoinePassifFilterInput>;
  some?: InputMaybe<DetenteurPatrimoinePassifFilterInput>;
};

export type ListFilterInputTypeOfDispositionFilterInput = {
  all?: InputMaybe<DispositionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DispositionFilterInput>;
  some?: InputMaybe<DispositionFilterInput>;
};

export type ListFilterInputTypeOfDistinctionFilterInput = {
  all?: InputMaybe<DistinctionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DistinctionFilterInput>;
  some?: InputMaybe<DistinctionFilterInput>;
};

export type ListFilterInputTypeOfEditionFilterInput = {
  all?: InputMaybe<EditionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EditionFilterInput>;
  some?: InputMaybe<EditionFilterInput>;
};

export type ListFilterInputTypeOfEmailFilterInput = {
  all?: InputMaybe<EmailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EmailFilterInput>;
  some?: InputMaybe<EmailFilterInput>;
};

export type ListFilterInputTypeOfEnveloppeProduitFilterInput = {
  all?: InputMaybe<EnveloppeProduitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EnveloppeProduitFilterInput>;
  some?: InputMaybe<EnveloppeProduitFilterInput>;
};

export type ListFilterInputTypeOfEnvoiEmailFilterInput = {
  all?: InputMaybe<EnvoiEmailFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EnvoiEmailFilterInput>;
  some?: InputMaybe<EnvoiEmailFilterInput>;
};

export type ListFilterInputTypeOfEnvoiPartenaireHistoryRecordFilterInput = {
  all?: InputMaybe<EnvoiPartenaireHistoryRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EnvoiPartenaireHistoryRecordFilterInput>;
  some?: InputMaybe<EnvoiPartenaireHistoryRecordFilterInput>;
};

export type ListFilterInputTypeOfEquivalenceDeFormationFilterInput = {
  all?: InputMaybe<EquivalenceDeFormationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EquivalenceDeFormationFilterInput>;
  some?: InputMaybe<EquivalenceDeFormationFilterInput>;
};

export type ListFilterInputTypeOfEtatCivilFilterInput = {
  all?: InputMaybe<EtatCivilFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<EtatCivilFilterInput>;
  some?: InputMaybe<EtatCivilFilterInput>;
};

export type ListFilterInputTypeOfFactureFilterInput = {
  all?: InputMaybe<FactureFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FactureFilterInput>;
  some?: InputMaybe<FactureFilterInput>;
};

export type ListFilterInputTypeOfFichierOperationFilterInput = {
  all?: InputMaybe<FichierOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FichierOperationFilterInput>;
  some?: InputMaybe<FichierOperationFilterInput>;
};

export type ListFilterInputTypeOfFichierOperationHistoryRecordFilterInput = {
  all?: InputMaybe<FichierOperationHistoryRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FichierOperationHistoryRecordFilterInput>;
  some?: InputMaybe<FichierOperationHistoryRecordFilterInput>;
};

export type ListFilterInputTypeOfFondEvenementielFilterInput = {
  all?: InputMaybe<FondEvenementielFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FondEvenementielFilterInput>;
  some?: InputMaybe<FondEvenementielFilterInput>;
};

export type ListFilterInputTypeOfFormationFilterInput = {
  all?: InputMaybe<FormationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormationFilterInput>;
  some?: InputMaybe<FormationFilterInput>;
};

export type ListFilterInputTypeOfFormeJuridiqueFilterInput = {
  all?: InputMaybe<FormeJuridiqueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormeJuridiqueFilterInput>;
  some?: InputMaybe<FormeJuridiqueFilterInput>;
};

export type ListFilterInputTypeOfGammeProduitFilterInput = {
  all?: InputMaybe<GammeProduitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<GammeProduitFilterInput>;
  some?: InputMaybe<GammeProduitFilterInput>;
};

export type ListFilterInputTypeOfGedTypeDocumentFilterInput = {
  all?: InputMaybe<GedTypeDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<GedTypeDocumentFilterInput>;
  some?: InputMaybe<GedTypeDocumentFilterInput>;
};

export type ListFilterInputTypeOfHabilitationInscriptionFilterInput = {
  all?: InputMaybe<HabilitationInscriptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<HabilitationInscriptionFilterInput>;
  some?: InputMaybe<HabilitationInscriptionFilterInput>;
};

export type ListFilterInputTypeOfHabilitationRcpFilterInput = {
  all?: InputMaybe<HabilitationRcpFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<HabilitationRcpFilterInput>;
  some?: InputMaybe<HabilitationRcpFilterInput>;
};

export type ListFilterInputTypeOfInscriptionCycleCourtFilterInput = {
  all?: InputMaybe<InscriptionCycleCourtFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InscriptionCycleCourtFilterInput>;
  some?: InputMaybe<InscriptionCycleCourtFilterInput>;
};

export type ListFilterInputTypeOfInscriptionSessionFilterInput = {
  all?: InputMaybe<InscriptionSessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InscriptionSessionFilterInput>;
  some?: InputMaybe<InscriptionSessionFilterInput>;
};

export type ListFilterInputTypeOfInvestisseurCommandeFilterInput = {
  all?: InputMaybe<InvestisseurCommandeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvestisseurCommandeFilterInput>;
  some?: InputMaybe<InvestisseurCommandeFilterInput>;
};

export type ListFilterInputTypeOfInvestisseurConsultantFilterInput = {
  all?: InputMaybe<InvestisseurConsultantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvestisseurConsultantFilterInput>;
  some?: InputMaybe<InvestisseurConsultantFilterInput>;
};

export type ListFilterInputTypeOfInvestisseurFilterInput = {
  all?: InputMaybe<InvestisseurFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvestisseurFilterInput>;
  some?: InputMaybe<InvestisseurFilterInput>;
};

export type ListFilterInputTypeOfInvestisseurObjectifFilterInput = {
  all?: InputMaybe<InvestisseurObjectifFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvestisseurObjectifFilterInput>;
  some?: InputMaybe<InvestisseurObjectifFilterInput>;
};

export type ListFilterInputTypeOfInvestisseurQpiFilterInput = {
  all?: InputMaybe<InvestisseurQpiFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<InvestisseurQpiFilterInput>;
  some?: InputMaybe<InvestisseurQpiFilterInput>;
};

export type ListFilterInputTypeOfMotifAnnulationCommandeFilterInput = {
  all?: InputMaybe<MotifAnnulationCommandeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MotifAnnulationCommandeFilterInput>;
  some?: InputMaybe<MotifAnnulationCommandeFilterInput>;
};

export type ListFilterInputTypeOfMotifCessionFilterInput = {
  all?: InputMaybe<MotifCessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MotifCessionFilterInput>;
  some?: InputMaybe<MotifCessionFilterInput>;
};

export type ListFilterInputTypeOfMouvementFilterInput = {
  all?: InputMaybe<MouvementFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MouvementFilterInput>;
  some?: InputMaybe<MouvementFilterInput>;
};

export type ListFilterInputTypeOfNatureDocumentFilterInput = {
  all?: InputMaybe<NatureDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NatureDocumentFilterInput>;
  some?: InputMaybe<NatureDocumentFilterInput>;
};

export type ListFilterInputTypeOfNatureDocumentRequiFilterInput = {
  all?: InputMaybe<NatureDocumentRequiFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NatureDocumentRequiFilterInput>;
  some?: InputMaybe<NatureDocumentRequiFilterInput>;
};

export type ListFilterInputTypeOfNonConformiteFichierFilterInput = {
  all?: InputMaybe<NonConformiteFichierFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NonConformiteFichierFilterInput>;
  some?: InputMaybe<NonConformiteFichierFilterInput>;
};

export type ListFilterInputTypeOfNoteFilterInput = {
  all?: InputMaybe<NoteFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NoteFilterInput>;
  some?: InputMaybe<NoteFilterInput>;
};

export type ListFilterInputTypeOfNoteOperationFilterInput = {
  all?: InputMaybe<NoteOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NoteOperationFilterInput>;
  some?: InputMaybe<NoteOperationFilterInput>;
};

export type ListFilterInputTypeOfNotificationFilterInput = {
  all?: InputMaybe<NotificationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<NotificationFilterInput>;
  some?: InputMaybe<NotificationFilterInput>;
};

export type ListFilterInputTypeOfOperationConfigFilterInput = {
  all?: InputMaybe<OperationConfigFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OperationConfigFilterInput>;
  some?: InputMaybe<OperationConfigFilterInput>;
};

export type ListFilterInputTypeOfOperationFilterInput = {
  all?: InputMaybe<OperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OperationFilterInput>;
  some?: InputMaybe<OperationFilterInput>;
};

export type ListFilterInputTypeOfOperationHistoryRecordFilterInput = {
  all?: InputMaybe<OperationHistoryRecordFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OperationHistoryRecordFilterInput>;
  some?: InputMaybe<OperationHistoryRecordFilterInput>;
};

export type ListFilterInputTypeOfOperationTagFilterInput = {
  all?: InputMaybe<OperationTagFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OperationTagFilterInput>;
  some?: InputMaybe<OperationTagFilterInput>;
};

export type ListFilterInputTypeOfOrganismeFilterInput = {
  all?: InputMaybe<OrganismeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<OrganismeFilterInput>;
  some?: InputMaybe<OrganismeFilterInput>;
};

export type ListFilterInputTypeOfPartenaireFilterInput = {
  all?: InputMaybe<PartenaireFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PartenaireFilterInput>;
  some?: InputMaybe<PartenaireFilterInput>;
};

export type ListFilterInputTypeOfPatrimoineActifFilterInput = {
  all?: InputMaybe<PatrimoineActifFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PatrimoineActifFilterInput>;
  some?: InputMaybe<PatrimoineActifFilterInput>;
};

export type ListFilterInputTypeOfPatrimoineChargeRevenuFilterInput = {
  all?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
  some?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
};

export type ListFilterInputTypeOfPatrimoinePassifFilterInput = {
  all?: InputMaybe<PatrimoinePassifFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PatrimoinePassifFilterInput>;
  some?: InputMaybe<PatrimoinePassifFilterInput>;
};

export type ListFilterInputTypeOfPersonneMoraleAdresseFilterInput = {
  all?: InputMaybe<PersonneMoraleAdresseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PersonneMoraleAdresseFilterInput>;
  some?: InputMaybe<PersonneMoraleAdresseFilterInput>;
};

export type ListFilterInputTypeOfPersonneMoraleFilterInput = {
  all?: InputMaybe<PersonneMoraleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PersonneMoraleFilterInput>;
  some?: InputMaybe<PersonneMoraleFilterInput>;
};

export type ListFilterInputTypeOfPersonneMoraleTelephoneFilterInput = {
  all?: InputMaybe<PersonneMoraleTelephoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PersonneMoraleTelephoneFilterInput>;
  some?: InputMaybe<PersonneMoraleTelephoneFilterInput>;
};

export type ListFilterInputTypeOfPersonnePhysiqueDocumentFilterInput = {
  all?: InputMaybe<PersonnePhysiqueDocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PersonnePhysiqueDocumentFilterInput>;
  some?: InputMaybe<PersonnePhysiqueDocumentFilterInput>;
};

export type ListFilterInputTypeOfPersonnePhysiqueFilterInput = {
  all?: InputMaybe<PersonnePhysiqueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PersonnePhysiqueFilterInput>;
  some?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type ListFilterInputTypeOfPieceJointeConsultantFilterInput = {
  all?: InputMaybe<PieceJointeConsultantFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<PieceJointeConsultantFilterInput>;
  some?: InputMaybe<PieceJointeConsultantFilterInput>;
};

export type ListFilterInputTypeOfProduitFilterInput = {
  all?: InputMaybe<ProduitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProduitFilterInput>;
  some?: InputMaybe<ProduitFilterInput>;
};

export type ListFilterInputTypeOfProduitFondEvenementielFilterInput = {
  all?: InputMaybe<ProduitFondEvenementielFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProduitFondEvenementielFilterInput>;
  some?: InputMaybe<ProduitFondEvenementielFilterInput>;
};

export type ListFilterInputTypeOfReglementaireFilterInput = {
  all?: InputMaybe<ReglementaireFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ReglementaireFilterInput>;
  some?: InputMaybe<ReglementaireFilterInput>;
};

export type ListFilterInputTypeOfRelationAutreFilterInput = {
  all?: InputMaybe<RelationAutreFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RelationAutreFilterInput>;
  some?: InputMaybe<RelationAutreFilterInput>;
};

export type ListFilterInputTypeOfRelationCoupleFilterInput = {
  all?: InputMaybe<RelationCoupleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RelationCoupleFilterInput>;
  some?: InputMaybe<RelationCoupleFilterInput>;
};

export type ListFilterInputTypeOfRelationFamilleFilterInput = {
  all?: InputMaybe<RelationFamilleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RelationFamilleFilterInput>;
  some?: InputMaybe<RelationFamilleFilterInput>;
};

export type ListFilterInputTypeOfResiliationPaiementB2SFilterInput = {
  all?: InputMaybe<ResiliationPaiementB2SFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ResiliationPaiementB2SFilterInput>;
  some?: InputMaybe<ResiliationPaiementB2SFilterInput>;
};

export type ListFilterInputTypeOfSeanceSessionFilterInput = {
  all?: InputMaybe<SeanceSessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SeanceSessionFilterInput>;
  some?: InputMaybe<SeanceSessionFilterInput>;
};

export type ListFilterInputTypeOfSessionFilterInput = {
  all?: InputMaybe<SessionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SessionFilterInput>;
  some?: InputMaybe<SessionFilterInput>;
};

export type ListFilterInputTypeOfSituationProfessionnelleFilterInput = {
  all?: InputMaybe<SituationProfessionnelleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SituationProfessionnelleFilterInput>;
  some?: InputMaybe<SituationProfessionnelleFilterInput>;
};

export type ListFilterInputTypeOfTelephoneFilterInput = {
  all?: InputMaybe<TelephoneFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TelephoneFilterInput>;
  some?: InputMaybe<TelephoneFilterInput>;
};

export type ListFilterInputTypeOfType2ProduitFilterInput = {
  all?: InputMaybe<Type2ProduitFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<Type2ProduitFilterInput>;
  some?: InputMaybe<Type2ProduitFilterInput>;
};

export type ListFilterInputTypeOfTypeEditionFilterInput = {
  all?: InputMaybe<TypeEditionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TypeEditionFilterInput>;
  some?: InputMaybe<TypeEditionFilterInput>;
};

export type ListFilterInputTypeOfTypeObjectifInvestisseurFilterInput = {
  all?: InputMaybe<TypeObjectifInvestisseurFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TypeObjectifInvestisseurFilterInput>;
  some?: InputMaybe<TypeObjectifInvestisseurFilterInput>;
};

export type ListFilterInputTypeOfTypePieceJointeFilterInput = {
  all?: InputMaybe<TypePieceJointeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TypePieceJointeFilterInput>;
  some?: InputMaybe<TypePieceJointeFilterInput>;
};

export type ListFilterInputTypeOfTypeRelationAutreFilterInput = {
  all?: InputMaybe<TypeRelationAutreFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TypeRelationAutreFilterInput>;
  some?: InputMaybe<TypeRelationAutreFilterInput>;
};

export type ListFilterInputTypeOfTypeRelationFamilleFilterInput = {
  all?: InputMaybe<TypeRelationFamilleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<TypeRelationFamilleFilterInput>;
  some?: InputMaybe<TypeRelationFamilleFilterInput>;
};

export type LongOperationFilterInput = {
  eq?: InputMaybe<Scalars['Long']['input']>;
  gt?: InputMaybe<Scalars['Long']['input']>;
  gte?: InputMaybe<Scalars['Long']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  lt?: InputMaybe<Scalars['Long']['input']>;
  lte?: InputMaybe<Scalars['Long']['input']>;
  neq?: InputMaybe<Scalars['Long']['input']>;
  ngt?: InputMaybe<Scalars['Long']['input']>;
  ngte?: InputMaybe<Scalars['Long']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Long']['input']>>>;
  nlt?: InputMaybe<Scalars['Long']['input']>;
  nlte?: InputMaybe<Scalars['Long']['input']>;
};

export type ModeCommunication = {
  __typename?: 'ModeCommunication';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  investisseur: Array<Investisseur>;
  libelle: Scalars['String']['output'];
};


export type ModeCommunicationInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};

export type ModeCommunicationFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ModeCommunicationFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModeCommunicationFilterInput>>;
};

export type ModeCommunicationSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type ModeLogement = {
  __typename?: 'ModeLogement';
  actif?: Maybe<Scalars['Boolean']['output']>;
  adresse: Array<Adresse>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type ModeLogementAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};

export type ModeLogementFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<ModeLogementFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModeLogementFilterInput>>;
};

export type ModeReponseReadhesion = {
  __typename?: 'ModeReponseReadhesion';
  adhesionConsultant: Array<AdhesionConsultant>;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type ModeReponseReadhesionAdhesionConsultantArgs = {
  where?: InputMaybe<AdhesionConsultantFilterInput>;
};

export type ModeReponseReadhesionFilterInput = {
  adhesionConsultant?: InputMaybe<ListFilterInputTypeOfAdhesionConsultantFilterInput>;
  and?: InputMaybe<Array<ModeReponseReadhesionFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModeReponseReadhesionFilterInput>>;
};

export type ModeSortieCommande = {
  __typename?: 'ModeSortieCommande';
  code: Scalars['Byte']['output'];
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type ModeSortieCommandeCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};

export type ModeSortieCommandeFilterInput = {
  and?: InputMaybe<Array<ModeSortieCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ModeSortieCommandeFilterInput>>;
};

export type ModeSortieCommandeSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export enum MotifAnnulation {
  CgpError = 'CGP_ERROR',
  DuplicatedNoObject = 'DUPLICATED_NO_OBJECT',
  NoFound = 'NO_FOUND',
  NoMoreWant = 'NO_MORE_WANT'
}

export type MotifAnnulationCommande = {
  __typename?: 'MotifAnnulationCommande';
  code: Scalars['Byte']['output'];
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  mouvement: Array<Mouvement>;
  typeMotif: Scalars['Byte']['output'];
  typeMotifNavigation: TypeMotifAnnulationMouvement;
};


export type MotifAnnulationCommandeCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};


export type MotifAnnulationCommandeMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};

export type MotifAnnulationCommandeFilterInput = {
  and?: InputMaybe<Array<MotifAnnulationCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  or?: InputMaybe<Array<MotifAnnulationCommandeFilterInput>>;
  typeMotif?: InputMaybe<ByteOperationFilterInput>;
  typeMotifNavigation?: InputMaybe<TypeMotifAnnulationMouvementFilterInput>;
};

export type MotifAnnulationCommandeSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  typeMotif?: InputMaybe<SortEnumType>;
  typeMotifNavigation?: InputMaybe<TypeMotifAnnulationMouvementSortInput>;
};

export type MotifCession = {
  __typename?: 'MotifCession';
  cessionHistoCommande: Array<CessionHistoCommande>;
  cessionHistoInvestisseur: Array<CessionHistoInvestisseur>;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  type: Scalars['Byte']['output'];
  typeNavigation: TypeMotifCession;
};


export type MotifCessionCessionHistoCommandeArgs = {
  where?: InputMaybe<CessionHistoCommandeFilterInput>;
};


export type MotifCessionCessionHistoInvestisseurArgs = {
  where?: InputMaybe<CessionHistoInvestisseurFilterInput>;
};

export type MotifCessionFilterInput = {
  and?: InputMaybe<Array<MotifCessionFilterInput>>;
  cessionHistoCommande?: InputMaybe<ListFilterInputTypeOfCessionHistoCommandeFilterInput>;
  cessionHistoInvestisseur?: InputMaybe<ListFilterInputTypeOfCessionHistoInvestisseurFilterInput>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MotifCessionFilterInput>>;
  type?: InputMaybe<ByteOperationFilterInput>;
  typeNavigation?: InputMaybe<TypeMotifCessionFilterInput>;
};

export type MotifCessionSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
  typeNavigation?: InputMaybe<TypeMotifCessionSortInput>;
};

export enum MotifRejet {
  ChronoError = 'CHRONO_ERROR',
  FundSourceNotJustified = 'FUND_SOURCE_NOT_JUSTIFIED',
  HabilitationError = 'HABILITATION_ERROR',
  MoreThan85YearsOld = 'MORE_THAN85_YEARS_OLD',
  NotCounterpartie = 'NOT_COUNTERPARTIE',
  NoNcReturn = 'NO_NC_RETURN',
  RiskyClient = 'RISKY_CLIENT',
  RiskProfilNotCompliant = 'RISK_PROFIL_NOT_COMPLIANT'
}

export type MotifResiliationConsultant = {
  __typename?: 'MotifResiliationConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultant: Array<Consultant>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type MotifResiliationConsultantConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};

export type MotifResiliationConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<MotifResiliationConsultantFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MotifResiliationConsultantFilterInput>>;
};

export type MotifResiliationConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type Mouvement = {
  __typename?: 'Mouvement';
  avecCorrespondant: Scalars['Boolean']['output'];
  baseCommission?: Maybe<Scalars['Decimal']['output']>;
  commande: Commande;
  commandeId: Scalars['Long']['output'];
  consultantMouvement: Array<ConsultantMouvement>;
  creationDate: Scalars['DateTime']['output'];
  dateActe?: Maybe<Scalars['DateTime']['output']>;
  dateAnnulation?: Maybe<Scalars['DateTime']['output']>;
  dateDebutMouvement?: Maybe<Scalars['DateTime']['output']>;
  dateFinMouvement?: Maybe<Scalars['DateTime']['output']>;
  dateTraitement?: Maybe<Scalars['DateTime']['output']>;
  dateValeur?: Maybe<Scalars['DateTime']['output']>;
  dateValidationComptable?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  etatMouvement: EtatMouvement;
  etatMouvementId: Scalars['Byte']['output'];
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  montantFraisReels?: Maybe<Scalars['Decimal']['output']>;
  montantVersement?: Maybe<Scalars['Decimal']['output']>;
  motifAnnulation?: Maybe<MotifAnnulationCommande>;
  motifAnnulationId?: Maybe<Scalars['Byte']['output']>;
  natureMouvement: NatureMouvement;
  natureMouvementId: Scalars['Byte']['output'];
  nouveauxFrais?: Maybe<Scalars['Decimal']['output']>;
  periodicite?: Maybe<PeriodiciteMouvement>;
  periodiciteId?: Maybe<Scalars['Byte']['output']>;
  pointValeurStrategique?: Maybe<Scalars['Decimal']['output']>;
  reductionLignee: Scalars['Boolean']['output'];
  tauxFraisProduit?: Maybe<Scalars['Decimal']['output']>;
};


export type MouvementConsultantMouvementArgs = {
  where?: InputMaybe<ConsultantMouvementFilterInput>;
};

export type MouvementFilterInput = {
  and?: InputMaybe<Array<MouvementFilterInput>>;
  avecCorrespondant?: InputMaybe<BooleanOperationFilterInput>;
  baseCommission?: InputMaybe<DecimalOperationFilterInput>;
  commande?: InputMaybe<CommandeFilterInput>;
  commandeId?: InputMaybe<LongOperationFilterInput>;
  consultantMouvement?: InputMaybe<ListFilterInputTypeOfConsultantMouvementFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateActe?: InputMaybe<DateTimeOperationFilterInput>;
  dateAnnulation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebutMouvement?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinMouvement?: InputMaybe<DateTimeOperationFilterInput>;
  dateTraitement?: InputMaybe<DateTimeOperationFilterInput>;
  dateValeur?: InputMaybe<DateTimeOperationFilterInput>;
  dateValidationComptable?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  etatMouvement?: InputMaybe<EtatMouvementFilterInput>;
  etatMouvementId?: InputMaybe<ByteOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  montantFraisReels?: InputMaybe<DecimalOperationFilterInput>;
  montantVersement?: InputMaybe<DecimalOperationFilterInput>;
  motifAnnulation?: InputMaybe<MotifAnnulationCommandeFilterInput>;
  motifAnnulationId?: InputMaybe<ByteOperationFilterInput>;
  natureMouvement?: InputMaybe<NatureMouvementFilterInput>;
  natureMouvementId?: InputMaybe<ByteOperationFilterInput>;
  nouveauxFrais?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<MouvementFilterInput>>;
  periodicite?: InputMaybe<PeriodiciteMouvementFilterInput>;
  periodiciteId?: InputMaybe<ByteOperationFilterInput>;
  pointValeurStrategique?: InputMaybe<DecimalOperationFilterInput>;
  reductionLignee?: InputMaybe<BooleanOperationFilterInput>;
  tauxFraisProduit?: InputMaybe<DecimalOperationFilterInput>;
};

export type MouvementSortInput = {
  avecCorrespondant?: InputMaybe<SortEnumType>;
  baseCommission?: InputMaybe<SortEnumType>;
  commande?: InputMaybe<CommandeSortInput>;
  commandeId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateActe?: InputMaybe<SortEnumType>;
  dateAnnulation?: InputMaybe<SortEnumType>;
  dateDebutMouvement?: InputMaybe<SortEnumType>;
  dateFinMouvement?: InputMaybe<SortEnumType>;
  dateTraitement?: InputMaybe<SortEnumType>;
  dateValeur?: InputMaybe<SortEnumType>;
  dateValidationComptable?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  etatMouvement?: InputMaybe<EtatMouvementSortInput>;
  etatMouvementId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  montantFraisReels?: InputMaybe<SortEnumType>;
  montantVersement?: InputMaybe<SortEnumType>;
  motifAnnulation?: InputMaybe<MotifAnnulationCommandeSortInput>;
  motifAnnulationId?: InputMaybe<SortEnumType>;
  natureMouvement?: InputMaybe<NatureMouvementSortInput>;
  natureMouvementId?: InputMaybe<SortEnumType>;
  nouveauxFrais?: InputMaybe<SortEnumType>;
  periodicite?: InputMaybe<PeriodiciteMouvementSortInput>;
  periodiciteId?: InputMaybe<SortEnumType>;
  pointValeurStrategique?: InputMaybe<SortEnumType>;
  reductionLignee?: InputMaybe<SortEnumType>;
  tauxFraisProduit?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type MouvementsPaginatedCollectionSegment = {
  __typename?: 'MouvementsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Mouvement>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  attachCommandeToOperation?: Maybe<Operation>;
  bordereauNoteUpdate?: Maybe<EnvoiPartenaire>;
  cancelBordereauSearch?: Maybe<EnvoiPartenaire>;
  cancelBordereauStatut?: Maybe<EnvoiPartenaire>;
  cancelConsulantNCResponse?: Maybe<FichierOperation>;
  cancelOperation?: Maybe<Operation>;
  closeNonConformiteFichier?: Maybe<NonConformiteFichier>;
  createActeDeGestion: Operation;
  createAndReplaceRequiredFichierOperationInOperationGroup: Array<FichierOperation>;
  createBatchFichierOperation: Array<FichierOperation>;
  createBatchResponseFichierOperation: Array<FichierOperation>;
  createBordereau?: Maybe<EnvoiPartenaire>;
  createBoxDocumentType?: Maybe<BoxDocumentType>;
  createCreationPersonneMorales?: Maybe<CreationPersonneMorale>;
  createEmptyBoxFile: BoxFileConnectionInfo;
  createEmptyBoxFileInBatch: Array<BoxFileConnectionInfo>;
  createEmptyGedFile: FileMetadata;
  createFicheLiaison: Scalars['String']['output'];
  createFichierOperation: FichierOperation;
  createFondEvenementiel?: Maybe<FondEvenementiel>;
  createInstanceFichierManquant?: Maybe<NonConformiteFichier>;
  createInstancePartenaire?: Maybe<NonConformiteFichier>;
  createNonConformiteFichier?: Maybe<NonConformiteFichier>;
  createNonConformiteFichierManquant?: Maybe<NonConformiteFichier>;
  createNoteOperation?: Maybe<NoteOperation>;
  createSouscription: Operation;
  deactivateBackOfficeMember?: Maybe<BackOfficeMember>;
  deactivateFondEvenementiel?: Maybe<FondEvenementiel>;
  declareAllFilesCompliantByOperationId: Array<FichierOperation>;
  declareAllOperationsInGroup?: Maybe<OperationGroup>;
  declareBordereauLost?: Maybe<EnvoiPartenaire>;
  declareBordereauReceiveDate?: Maybe<EnvoiPartenaire>;
  declareBordereauSearch?: Maybe<EnvoiPartenaire>;
  declareBordereauSendDate?: Maybe<EnvoiPartenaire>;
  deleteBordereau: Scalars['Boolean']['output'];
  deleteBoxFile: Scalars['Boolean']['output'];
  deleteDraftOperation: Scalars['Int']['output'];
  deleteMessageTechnique?: Maybe<Operation>;
  deleteNoteOperation?: Maybe<Scalars['Int']['output']>;
  deleteNotifications: Scalars['Boolean']['output'];
  deleteNotificationsByLink: Scalars['Boolean']['output'];
  dispatchOperationsTo?: Maybe<Array<Maybe<Operation>>>;
  fireConsultantCancelDeclarationforOperationGroup?: Maybe<OperationGroup>;
  fireConsultantFixesNCTransition?: Maybe<Operation>;
  fireOperationStateTransitionAndComment?: Maybe<Operation>;
  fireOperationStateTransitionTrigger?: Maybe<Operation>;
  fireOperationStateTransitionTriggerForOperationGroup?: Maybe<OperationGroup>;
  fireOperationStateTransitionTriggerForOperationsList?: Maybe<Array<Maybe<Operation>>>;
  notifyUserLogin: UserInformations;
  reOpenNonConformiteFichier?: Maybe<NonConformiteFichier>;
  refuseOperation?: Maybe<Operation>;
  removeFichierOperationFromOperation?: Maybe<Operation>;
  resetDraftOperationGroup?: Maybe<OperationGroup>;
  saveConsultantParametersConfig?: Maybe<ConsultantParametersConfig>;
  sendOperationTransmissionEmailToPartenaire: Array<EnvoiEmail>;
  sendPartenaireNotificationEmail: Array<EnvoiEmail>;
  setInvestisseurInCreationPersonneMorale?: Maybe<CreationPersonneMorale>;
  setOperationGestionnaireComment?: Maybe<Operation>;
  setOperationTags?: Maybe<Operation>;
  toggleBackOfficeMemberExtendedRIghts?: Maybe<BackOfficeMember>;
  transferAllOperationsBetweenInvestisseurs?: Maybe<Array<Maybe<Operation>>>;
  tryCreateBatchDistinctFilesinOperationForGroup: Array<FichierOperation>;
  updateBoxFileMetadata: BoxFile;
  updateConsultantInOperation?: Maybe<Operation>;
  updateFichierOperationCommentaire?: Maybe<FichierOperation>;
  updateFichierOperationFile?: Maybe<FichierOperation>;
  updateFichierOperationReponse?: Maybe<FichierOperation>;
  updateFichierOperationStatut?: Maybe<FichierOperation>;
  updateFondEvenementiel?: Maybe<FondEvenementiel>;
  updateGedFileMetadata?: Maybe<FileMetadata>;
  updateNonConformiteFichierFields?: Maybe<NonConformiteFichier>;
  updateNoteOperation?: Maybe<NoteOperation>;
  updateOperations: Array<Operation>;
  updateProduitExtensionLibelle?: Maybe<ProduitExtension>;
  updateProduitExtensionSouscriptionPossible?: Maybe<ProduitExtension>;
};


export type MutationAttachCommandeToOperationArgs = {
  commandeId: Scalars['Long']['input'];
  operationId: Scalars['Int']['input'];
};


export type MutationBordereauNoteUpdateArgs = {
  bordereauId: Scalars['Int']['input'];
  newNote: Scalars['String']['input'];
};


export type MutationCancelBordereauSearchArgs = {
  bordereauId: Scalars['Int']['input'];
};


export type MutationCancelBordereauStatutArgs = {
  bordereauId: Scalars['Int']['input'];
};


export type MutationCancelConsulantNcResponseArgs = {
  fichierOperationId: Scalars['ID']['input'];
};


export type MutationCancelOperationArgs = {
  comment: Scalars['String']['input'];
  motifAnnulation: MotifAnnulation;
  operationId: Scalars['Int']['input'];
};


export type MutationCloseNonConformiteFichierArgs = {
  nonConformiteId: Scalars['ID']['input'];
};


export type MutationCreateActeDeGestionArgs = {
  coInvestisseurId?: InputMaybe<Scalars['Long']['input']>;
  contratId: Scalars['Long']['input'];
  investisseurId: Scalars['Int']['input'];
  operationGroupId?: InputMaybe<Scalars['Int']['input']>;
  produitId: Scalars['Int']['input'];
};


export type MutationCreateAndReplaceRequiredFichierOperationInOperationGroupArgs = {
  input: FichierOperationInGroupInput;
};


export type MutationCreateBatchFichierOperationArgs = {
  input: CreateBatchFichierOperationInput;
};


export type MutationCreateBatchResponseFichierOperationArgs = {
  input: CreateBatchFichierOperationInput;
};


export type MutationCreateBordereauArgs = {
  operationsIds: Array<Scalars['Int']['input']>;
  partenaireId: Scalars['Long']['input'];
};


export type MutationCreateBoxDocumentTypeArgs = {
  input: CreateBoxDocumentTypeInput;
};


export type MutationCreateCreationPersonneMoralesArgs = {
  input: CreateCreationPersonneMoraleInput;
};


export type MutationCreateEmptyBoxFileArgs = {
  denomination?: InputMaybe<Scalars['String']['input']>;
  envoiPartenaireId?: InputMaybe<Scalars['Int']['input']>;
  fileExtension: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  operationId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCreateEmptyBoxFileInBatchArgs = {
  inputs: Array<CreateEmptyBoxFileInput>;
};


export type MutationCreateEmptyGedFileArgs = {
  denomination: Scalars['String']['input'];
  investisseurId: Scalars['Long']['input'];
  nomDeFichier: Scalars['String']['input'];
  typeCode: Scalars['String']['input'];
};


export type MutationCreateFicheLiaisonArgs = {
  commentaire?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
};


export type MutationCreateFichierOperationArgs = {
  input: CreateFichierOperationInput;
};


export type MutationCreateFondEvenementielArgs = {
  libelle: Scalars['String']['input'];
  produitIds: Array<Scalars['Int']['input']>;
};


export type MutationCreateInstanceFichierManquantArgs = {
  input: CreateNonConformiteFichierManquantInput;
};


export type MutationCreateInstancePartenaireArgs = {
  input: CreateInstancePartenaireInput;
};


export type MutationCreateNonConformiteFichierArgs = {
  input: CreateNonConformiteFichierInput;
};


export type MutationCreateNonConformiteFichierManquantArgs = {
  input: CreateNonConformiteFichierManquantInput;
};


export type MutationCreateNoteOperationArgs = {
  contenu: Scalars['String']['input'];
  operationId: Scalars['Int']['input'];
};


export type MutationCreateSouscriptionArgs = {
  coInvestisseurId?: InputMaybe<Scalars['Long']['input']>;
  investisseurId: Scalars['Long']['input'];
  operationGroupId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeactivateBackOfficeMemberArgs = {
  backOfficerMemberId: Scalars['Int']['input'];
};


export type MutationDeactivateFondEvenementielArgs = {
  fondEvenementielId: Scalars['Int']['input'];
};


export type MutationDeclareAllFilesCompliantByOperationIdArgs = {
  operationId: Scalars['ID']['input'];
};


export type MutationDeclareAllOperationsInGroupArgs = {
  operationGroupId: Scalars['Int']['input'];
};


export type MutationDeclareBordereauLostArgs = {
  bordereauId: Scalars['Int']['input'];
};


export type MutationDeclareBordereauReceiveDateArgs = {
  bordereauId: Scalars['Int']['input'];
  receiveDate: Scalars['DateTime']['input'];
};


export type MutationDeclareBordereauSearchArgs = {
  bordereauId: Scalars['Int']['input'];
};


export type MutationDeclareBordereauSendDateArgs = {
  bordereauId: Scalars['Int']['input'];
  sendDate: Scalars['DateTime']['input'];
};


export type MutationDeleteBordereauArgs = {
  bordereauId: Scalars['Int']['input'];
};


export type MutationDeleteBoxFileArgs = {
  boxFileId: Scalars['Int']['input'];
};


export type MutationDeleteDraftOperationArgs = {
  operationId: Scalars['Int']['input'];
};


export type MutationDeleteMessageTechniqueArgs = {
  operationId: Scalars['Int']['input'];
};


export type MutationDeleteNoteOperationArgs = {
  noteId: Scalars['Int']['input'];
};


export type MutationDeleteNotificationsArgs = {
  notificationIds: Array<Scalars['Int']['input']>;
};


export type MutationDeleteNotificationsByLinkArgs = {
  link: Scalars['String']['input'];
};


export type MutationDispatchOperationsToArgs = {
  gestionnaireId: Scalars['Int']['input'];
  operationIds: Array<Scalars['Int']['input']>;
};


export type MutationFireConsultantCancelDeclarationforOperationGroupArgs = {
  operationGroupId: Scalars['Int']['input'];
};


export type MutationFireConsultantFixesNcTransitionArgs = {
  operationId: Scalars['Int']['input'];
  trigger: OperationStateTransitionTrigger;
};


export type MutationFireOperationStateTransitionAndCommentArgs = {
  commentaire?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
  trigger: OperationStateTransitionTrigger;
};


export type MutationFireOperationStateTransitionTriggerArgs = {
  operationId: Scalars['Int']['input'];
  trigger: OperationStateTransitionTrigger;
};


export type MutationFireOperationStateTransitionTriggerForOperationGroupArgs = {
  operationGroupId: Scalars['Int']['input'];
  trigger: OperationStateTransitionTrigger;
};


export type MutationFireOperationStateTransitionTriggerForOperationsListArgs = {
  operationIds: Array<Scalars['Int']['input']>;
  trigger: OperationStateTransitionTrigger;
};


export type MutationReOpenNonConformiteFichierArgs = {
  nonConformiteId: Scalars['ID']['input'];
};


export type MutationRefuseOperationArgs = {
  comment: Scalars['String']['input'];
  motifRejet: MotifRejet;
  operationId: Scalars['Int']['input'];
};


export type MutationRemoveFichierOperationFromOperationArgs = {
  fichierOperationId: Scalars['ID']['input'];
};


export type MutationResetDraftOperationGroupArgs = {
  groupId: Scalars['Int']['input'];
};


export type MutationSaveConsultantParametersConfigArgs = {
  config: ConsultantParametersConfigInput;
};


export type MutationSendOperationTransmissionEmailToPartenaireArgs = {
  attachBoxFileIds: Array<Scalars['Int']['input']>;
  ccList: Array<Scalars['String']['input']>;
  fichierOperationIds: Array<Scalars['Int']['input']>;
  operationId: Scalars['Int']['input'];
  subject: Scalars['String']['input'];
  textContent: Scalars['String']['input'];
  toList: Array<Scalars['String']['input']>;
};


export type MutationSendPartenaireNotificationEmailArgs = {
  attachBoxFileIds: Array<Scalars['Int']['input']>;
  bordereauId: Scalars['Int']['input'];
  ccList: Array<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  textContent: Scalars['String']['input'];
  toList: Array<Scalars['String']['input']>;
};


export type MutationSetInvestisseurInCreationPersonneMoraleArgs = {
  creationPersonneMoraleId: Scalars['Int']['input'];
  investisseurId: Scalars['Long']['input'];
};


export type MutationSetOperationGestionnaireCommentArgs = {
  commentaire?: InputMaybe<Scalars['String']['input']>;
  operationId: Scalars['Int']['input'];
};


export type MutationSetOperationTagsArgs = {
  operationId: Scalars['Int']['input'];
  tagIds: Array<Scalars['Int']['input']>;
};


export type MutationToggleBackOfficeMemberExtendedRIghtsArgs = {
  backOfficerMemberId: Scalars['Int']['input'];
};


export type MutationTransferAllOperationsBetweenInvestisseursArgs = {
  sourceInvestisseurId: Scalars['Long']['input'];
  targetInvestisseurId: Scalars['Long']['input'];
};


export type MutationTryCreateBatchDistinctFilesinOperationForGroupArgs = {
  input: CreateBatchFichierOperationForGroupInput;
};


export type MutationUpdateBoxFileMetadataArgs = {
  boxFileId: Scalars['Int']['input'];
  denomination?: InputMaybe<Scalars['String']['input']>;
  fileExtension?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateConsultantInOperationArgs = {
  consultantId: Scalars['Long']['input'];
  operationId: Scalars['Int']['input'];
};


export type MutationUpdateFichierOperationCommentaireArgs = {
  commentaire: Scalars['String']['input'];
  fichierOperationId: Scalars['ID']['input'];
};


export type MutationUpdateFichierOperationFileArgs = {
  fichierOperationId: Scalars['ID']['input'];
  fileId: Scalars['String']['input'];
};


export type MutationUpdateFichierOperationReponseArgs = {
  fichierOperationId: Scalars['ID']['input'];
  fileId?: InputMaybe<Scalars['String']['input']>;
  reponse?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateFichierOperationStatutArgs = {
  fichierOperationId: Scalars['ID']['input'];
  fichierOperationStatutId: Scalars['ID']['input'];
};


export type MutationUpdateFondEvenementielArgs = {
  fondEvenementielId: Scalars['Int']['input'];
  libelle?: InputMaybe<Scalars['String']['input']>;
  produitIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationUpdateGedFileMetadataArgs = {
  fileId: Scalars['String']['input'];
  newValue: FileMetadataUpdateInput;
};


export type MutationUpdateNonConformiteFichierFieldsArgs = {
  categorie?: InputMaybe<Scalars['String']['input']>;
  commentaire?: InputMaybe<Scalars['String']['input']>;
  isInternal?: InputMaybe<Scalars['Boolean']['input']>;
  motif?: InputMaybe<Scalars['String']['input']>;
  nonConformiteId: Scalars['ID']['input'];
};


export type MutationUpdateNoteOperationArgs = {
  contenu: Scalars['String']['input'];
  noteId: Scalars['Int']['input'];
};


export type MutationUpdateOperationsArgs = {
  operationDatas: Array<UpdateOperationInput>;
};


export type MutationUpdateProduitExtensionLibelleArgs = {
  newLibelle: Scalars['String']['input'];
  produitId?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateProduitExtensionSouscriptionPossibleArgs = {
  newSouscriptionPossibleValue: Scalars['Boolean']['input'];
  produitId: Scalars['ID']['input'];
};

export type NatureDocument = {
  __typename?: 'NatureDocument';
  boxDocumentType: BoxDocumentType;
  boxDocumentTypeId: Scalars['Int']['output'];
  categorie: DocumentCategorie;
  categorieId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  natureDocumentRequis: Array<NatureDocumentRequi>;
  nom: Scalars['String']['output'];
};


export type NatureDocumentNatureDocumentRequisArgs = {
  where?: InputMaybe<NatureDocumentRequiFilterInput>;
};

export type NatureDocumentFilterInput = {
  and?: InputMaybe<Array<NatureDocumentFilterInput>>;
  boxDocumentType?: InputMaybe<BoxDocumentTypeFilterInput>;
  boxDocumentTypeId?: InputMaybe<IntOperationFilterInput>;
  categorie?: InputMaybe<DocumentCategorieFilterInput>;
  categorieId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  natureDocumentRequis?: InputMaybe<ListFilterInputTypeOfNatureDocumentRequiFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NatureDocumentFilterInput>>;
};

export type NatureDocumentRequi = {
  __typename?: 'NatureDocumentRequi';
  applicableHabilitationCode?: Maybe<Scalars['String']['output']>;
  fichierOperations: Array<FichierOperation>;
  id: Scalars['Int']['output'];
  natureDocument: NatureDocument;
  natureDocumentId: Scalars['Int']['output'];
  operationConfigs: Array<OperationConfig>;
  withAutoImport: Scalars['Boolean']['output'];
  withAutoImportCoInv: Scalars['Boolean']['output'];
};


export type NatureDocumentRequiFichierOperationsArgs = {
  where?: InputMaybe<FichierOperationFilterInput>;
};


export type NatureDocumentRequiOperationConfigsArgs = {
  where?: InputMaybe<OperationConfigFilterInput>;
};

export type NatureDocumentRequiFilterInput = {
  and?: InputMaybe<Array<NatureDocumentRequiFilterInput>>;
  applicableHabilitationCode?: InputMaybe<StringOperationFilterInput>;
  fichierOperations?: InputMaybe<ListFilterInputTypeOfFichierOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  natureDocument?: InputMaybe<NatureDocumentFilterInput>;
  natureDocumentId?: InputMaybe<IntOperationFilterInput>;
  operationConfigs?: InputMaybe<ListFilterInputTypeOfOperationConfigFilterInput>;
  or?: InputMaybe<Array<NatureDocumentRequiFilterInput>>;
  withAutoImport?: InputMaybe<BooleanOperationFilterInput>;
  withAutoImportCoInv?: InputMaybe<BooleanOperationFilterInput>;
};

export type NatureDocumentRequiSortInput = {
  applicableHabilitationCode?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  natureDocument?: InputMaybe<NatureDocumentSortInput>;
  natureDocumentId?: InputMaybe<SortEnumType>;
  withAutoImport?: InputMaybe<SortEnumType>;
  withAutoImportCoInv?: InputMaybe<SortEnumType>;
};

export type NatureDocumentSortInput = {
  boxDocumentType?: InputMaybe<BoxDocumentTypeSortInput>;
  boxDocumentTypeId?: InputMaybe<SortEnumType>;
  categorie?: InputMaybe<DocumentCategorieSortInput>;
  categorieId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
};

export type NatureMouvement = {
  __typename?: 'NatureMouvement';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  mouvement: Array<Mouvement>;
};


export type NatureMouvementMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};

export type NatureMouvementFilterInput = {
  and?: InputMaybe<Array<NatureMouvementFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  or?: InputMaybe<Array<NatureMouvementFilterInput>>;
};

export type NatureMouvementSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type NatureOperation = {
  __typename?: 'NatureOperation';
  id: Scalars['Int']['output'];
  libelle: Scalars['String']['output'];
  operationConfigs: Array<OperationConfig>;
};


export type NatureOperationOperationConfigsArgs = {
  where?: InputMaybe<OperationConfigFilterInput>;
};

export type NatureOperationFilterInput = {
  and?: InputMaybe<Array<NatureOperationFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  operationConfigs?: InputMaybe<ListFilterInputTypeOfOperationConfigFilterInput>;
  or?: InputMaybe<Array<NatureOperationFilterInput>>;
};

export type NatureOperationSortInput = {
  id?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type NaturePatrimoine = {
  __typename?: 'NaturePatrimoine';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  patrimoineActif: Array<PatrimoineActif>;
  patrimoineChargeRevenu: Array<PatrimoineChargeRevenu>;
  patrimoinePassif: Array<PatrimoinePassif>;
  type: Scalars['String']['output'];
};


export type NaturePatrimoinePatrimoineActifArgs = {
  where?: InputMaybe<PatrimoineActifFilterInput>;
};


export type NaturePatrimoinePatrimoineChargeRevenuArgs = {
  where?: InputMaybe<PatrimoineChargeRevenuFilterInput>;
};


export type NaturePatrimoinePatrimoinePassifArgs = {
  where?: InputMaybe<PatrimoinePassifFilterInput>;
};

export type NaturePatrimoineFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<NaturePatrimoineFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NaturePatrimoineFilterInput>>;
  patrimoineActif?: InputMaybe<ListFilterInputTypeOfPatrimoineActifFilterInput>;
  patrimoineChargeRevenu?: InputMaybe<ListFilterInputTypeOfPatrimoineChargeRevenuFilterInput>;
  patrimoinePassif?: InputMaybe<ListFilterInputTypeOfPatrimoinePassifFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type NatureRelationAutre = {
  __typename?: 'NatureRelationAutre';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  typeRelationAutre: Array<TypeRelationAutre>;
};


export type NatureRelationAutreTypeRelationAutreArgs = {
  where?: InputMaybe<TypeRelationAutreFilterInput>;
};

export type NatureRelationAutreFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<NatureRelationAutreFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NatureRelationAutreFilterInput>>;
  typeRelationAutre?: InputMaybe<ListFilterInputTypeOfTypeRelationAutreFilterInput>;
};

export type NiveauEtude = {
  __typename?: 'NiveauEtude';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  relationFamille: Array<RelationFamille>;
};


export type NiveauEtudeRelationFamilleArgs = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};

export type NiveauEtudeFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<NiveauEtudeFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NiveauEtudeFilterInput>>;
  relationFamille?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
};

export type NonConformiteFichier = {
  __typename?: 'NonConformiteFichier';
  categorie: Scalars['String']['output'];
  commentaire: Scalars['String']['output'];
  fichierOperation: FichierOperation;
  fichierOperationId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isInstancePartenaire?: Maybe<Scalars['Boolean']['output']>;
  isInternal?: Maybe<Scalars['Boolean']['output']>;
  motif: Scalars['String']['output'];
};

export type NonConformiteFichierFilterInput = {
  and?: InputMaybe<Array<NonConformiteFichierFilterInput>>;
  categorie?: InputMaybe<StringOperationFilterInput>;
  commentaire?: InputMaybe<StringOperationFilterInput>;
  fichierOperation?: InputMaybe<FichierOperationFilterInput>;
  fichierOperationId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  isInstancePartenaire?: InputMaybe<BooleanOperationFilterInput>;
  isInternal?: InputMaybe<BooleanOperationFilterInput>;
  motif?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NonConformiteFichierFilterInput>>;
};

export type NonConformiteFichierSortInput = {
  categorie?: InputMaybe<SortEnumType>;
  commentaire?: InputMaybe<SortEnumType>;
  fichierOperation?: InputMaybe<FichierOperationSortInput>;
  fichierOperationId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isActive?: InputMaybe<SortEnumType>;
  isInstancePartenaire?: InputMaybe<SortEnumType>;
  isInternal?: InputMaybe<SortEnumType>;
  motif?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type NonConformiteFichiersCollectionSegment = {
  __typename?: 'NonConformiteFichiersCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<NonConformiteFichier>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Note = {
  __typename?: 'Note';
  adresse: Array<Adresse>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  disposition: Array<Disposition>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  email: Array<Email>;
  facture: Array<Facture>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur: Array<Investisseur>;
  note1: Scalars['String']['output'];
  situationProfessionnelle: Array<SituationProfessionnelle>;
  telephone: Array<Telephone>;
};


export type NoteAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};


export type NoteDispositionArgs = {
  where?: InputMaybe<DispositionFilterInput>;
};


export type NoteEmailArgs = {
  where?: InputMaybe<EmailFilterInput>;
};


export type NoteFactureArgs = {
  where?: InputMaybe<FactureFilterInput>;
};


export type NoteInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};


export type NoteSituationProfessionnelleArgs = {
  where?: InputMaybe<SituationProfessionnelleFilterInput>;
};


export type NoteTelephoneArgs = {
  where?: InputMaybe<TelephoneFilterInput>;
};

export type NoteFilterInput = {
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<NoteFilterInput>>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  disposition?: InputMaybe<ListFilterInputTypeOfDispositionFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<ListFilterInputTypeOfEmailFilterInput>;
  facture?: InputMaybe<ListFilterInputTypeOfFactureFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  note1?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NoteFilterInput>>;
  situationProfessionnelle?: InputMaybe<ListFilterInputTypeOfSituationProfessionnelleFilterInput>;
  telephone?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
};

export type NoteOperation = {
  __typename?: 'NoteOperation';
  contenu: Scalars['String']['output'];
  dateCreation: Scalars['DateTime']['output'];
  dateDerniereModification: Scalars['DateTime']['output'];
  gestionnaire: BackOfficeMember;
  gestionnaireId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  operation: Operation;
  operationId: Scalars['Int']['output'];
};

export type NoteOperationFilterInput = {
  and?: InputMaybe<Array<NoteOperationFilterInput>>;
  contenu?: InputMaybe<StringOperationFilterInput>;
  dateCreation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDerniereModification?: InputMaybe<DateTimeOperationFilterInput>;
  gestionnaire?: InputMaybe<BackOfficeMemberFilterInput>;
  gestionnaireId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<NoteOperationFilterInput>>;
};

export type Notification = {
  __typename?: 'Notification';
  backOfficeMember?: Maybe<BackOfficeMember>;
  backOfficeMemberId?: Maybe<Scalars['Int']['output']>;
  consultantId?: Maybe<Scalars['Long']['output']>;
  id: Scalars['Int']['output'];
  isActive: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  linkLabel?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
};

export type NotificationFilterInput = {
  and?: InputMaybe<Array<NotificationFilterInput>>;
  backOfficeMember?: InputMaybe<BackOfficeMemberFilterInput>;
  backOfficeMemberId?: InputMaybe<IntOperationFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isActive?: InputMaybe<BooleanOperationFilterInput>;
  link?: InputMaybe<StringOperationFilterInput>;
  linkLabel?: InputMaybe<StringOperationFilterInput>;
  message?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<NotificationFilterInput>>;
};

export type NullableOfEnvoiPartenaireStatutOperationFilterInput = {
  eq?: InputMaybe<EnvoiPartenaireStatut>;
  in?: InputMaybe<Array<InputMaybe<EnvoiPartenaireStatut>>>;
  neq?: InputMaybe<EnvoiPartenaireStatut>;
  nin?: InputMaybe<Array<InputMaybe<EnvoiPartenaireStatut>>>;
};

export type NullableOfMotifAnnulationOperationFilterInput = {
  eq?: InputMaybe<MotifAnnulation>;
  in?: InputMaybe<Array<InputMaybe<MotifAnnulation>>>;
  neq?: InputMaybe<MotifAnnulation>;
  nin?: InputMaybe<Array<InputMaybe<MotifAnnulation>>>;
};

export type NullableOfMotifRejetOperationFilterInput = {
  eq?: InputMaybe<MotifRejet>;
  in?: InputMaybe<Array<InputMaybe<MotifRejet>>>;
  neq?: InputMaybe<MotifRejet>;
  nin?: InputMaybe<Array<InputMaybe<MotifRejet>>>;
};

export type NullableOfPermissionOperationFilterInput = {
  eq?: InputMaybe<Permission>;
  in?: InputMaybe<Array<InputMaybe<Permission>>>;
  neq?: InputMaybe<Permission>;
  nin?: InputMaybe<Array<InputMaybe<Permission>>>;
};

export type NullableOfSignatureStateOperationFilterInput = {
  eq?: InputMaybe<SignatureState>;
  in?: InputMaybe<Array<InputMaybe<SignatureState>>>;
  neq?: InputMaybe<SignatureState>;
  nin?: InputMaybe<Array<InputMaybe<SignatureState>>>;
};

export type NullableOfTransactionPersonnelleEnumOperationFilterInput = {
  eq?: InputMaybe<TransactionPersonnelleEnum>;
  in?: InputMaybe<Array<InputMaybe<TransactionPersonnelleEnum>>>;
  neq?: InputMaybe<TransactionPersonnelleEnum>;
  nin?: InputMaybe<Array<InputMaybe<TransactionPersonnelleEnum>>>;
};

export type NullableOfTypeSignaturePartenaireOperationFilterInput = {
  eq?: InputMaybe<TypeSignaturePartenaire>;
  in?: InputMaybe<Array<InputMaybe<TypeSignaturePartenaire>>>;
  neq?: InputMaybe<TypeSignaturePartenaire>;
  nin?: InputMaybe<Array<InputMaybe<TypeSignaturePartenaire>>>;
};

export type Operation = {
  __typename?: 'Operation';
  activeOperationStateTransitionTriggers: Array<OperationStateTransitionTrigger>;
  attachedFiles: Array<BoxFile>;
  coInvestisseur?: Maybe<Investisseur>;
  coInvestisseurId?: Maybe<Scalars['Long']['output']>;
  coInvestisseurPreset?: Maybe<PresettedInvestisseur>;
  commentaireGestionnaire?: Maybe<Scalars['String']['output']>;
  consultant?: Maybe<Consultant>;
  consultantId: Scalars['Long']['output'];
  consultantPreset?: Maybe<PresettedConsultant>;
  contrat?: Maybe<Commande>;
  contratId?: Maybe<Scalars['Long']['output']>;
  dateCreation: Scalars['DateTime']['output'];
  dateDeclaration?: Maybe<Scalars['DateTime']['output']>;
  dateModification: Scalars['DateTime']['output'];
  donneesSpecifiques: Scalars['String']['output'];
  emails: Array<EnvoiEmail>;
  envoiPartenaire?: Maybe<EnvoiPartenaire>;
  envoiPartenaireId?: Maybe<Scalars['Int']['output']>;
  fichierHistoryEntry: Array<FichierOperationHistoryRecord>;
  fichierOperations: Array<FichierOperation>;
  fondEvenementiels: Array<FondEvenementiel>;
  gestionnaire?: Maybe<BackOfficeMember>;
  gestionnaireId?: Maybe<Scalars['Int']['output']>;
  historyEntry: Array<OperationHistoryRecord>;
  id: Scalars['Int']['output'];
  investisseur?: Maybe<Investisseur>;
  investisseurId: Scalars['Long']['output'];
  investisseurPreset?: Maybe<PresettedInvestisseur>;
  isInvestisseurPersonneMorale: Scalars['Boolean']['output'];
  messageTechnique?: Maybe<Scalars['String']['output']>;
  montant?: Maybe<Scalars['Float']['output']>;
  motifAnnulation?: Maybe<MotifAnnulation>;
  motifRejet?: Maybe<MotifRejet>;
  motifRejetCancelCommentaire?: Maybe<Scalars['String']['output']>;
  notes: Array<NoteOperation>;
  operationActionRights: Array<OperationActionRight>;
  operationConfig?: Maybe<OperationConfig>;
  operationConfigId?: Maybe<Scalars['Int']['output']>;
  operationGroup: OperationGroup;
  operationGroupId: Scalars['Int']['output'];
  operationType: OperationType;
  produit?: Maybe<Produit>;
  produitId?: Maybe<Scalars['Long']['output']>;
  produitPreset?: Maybe<PresettedProduit>;
  statut: Statut;
  statutId: Scalars['Int']['output'];
  tags: Array<OperationTag>;
  transactionPersonnelleStatut?: Maybe<TransactionPersonnelleEnum>;
  typeSignaturePartenaire?: Maybe<TypeSignaturePartenaire>;
};


export type OperationAttachedFilesArgs = {
  where?: InputMaybe<BoxFileFilterInput>;
};


export type OperationEmailsArgs = {
  where?: InputMaybe<EnvoiEmailFilterInput>;
};


export type OperationFichierHistoryEntryArgs = {
  where?: InputMaybe<FichierOperationHistoryRecordFilterInput>;
};


export type OperationFichierOperationsArgs = {
  where?: InputMaybe<FichierOperationFilterInput>;
};


export type OperationFondEvenementielsArgs = {
  where?: InputMaybe<FondEvenementielFilterInput>;
};


export type OperationHistoryEntryArgs = {
  where?: InputMaybe<OperationHistoryRecordFilterInput>;
};


export type OperationNotesArgs = {
  where?: InputMaybe<NoteOperationFilterInput>;
};


export type OperationTagsArgs = {
  where?: InputMaybe<OperationTagFilterInput>;
};

export enum OperationActionRight {
  AttachCommandeToOperation = 'ATTACH_COMMANDE_TO_OPERATION',
  FichierOperationEdition = 'FICHIER_OPERATION_EDITION',
  OperationEdition = 'OPERATION_EDITION'
}

export type OperationConfig = {
  __typename?: 'OperationConfig';
  allowMultiple: Scalars['Boolean']['output'];
  enveloppeId: Scalars['Int']['output'];
  enveloppeProduit?: Maybe<EnveloppeProduit>;
  filteredNatureDocumentRequis?: Maybe<Array<NatureDocumentRequi>>;
  id: Scalars['Int']['output'];
  natureDocumentRequis: Array<NatureDocumentRequi>;
  natureOperation: NatureOperation;
  natureOperationId: Scalars['Int']['output'];
  operations: Array<Operation>;
};


export type OperationConfigNatureDocumentRequisArgs = {
  where?: InputMaybe<NatureDocumentRequiFilterInput>;
};


export type OperationConfigOperationsArgs = {
  where?: InputMaybe<OperationFilterInput>;
};

export type OperationConfigFilterInput = {
  allowMultiple?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<OperationConfigFilterInput>>;
  enveloppeId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  natureDocumentRequis?: InputMaybe<ListFilterInputTypeOfNatureDocumentRequiFilterInput>;
  natureOperation?: InputMaybe<NatureOperationFilterInput>;
  natureOperationId?: InputMaybe<IntOperationFilterInput>;
  operations?: InputMaybe<ListFilterInputTypeOfOperationFilterInput>;
  or?: InputMaybe<Array<OperationConfigFilterInput>>;
};

export type OperationConfigSortInput = {
  allowMultiple?: InputMaybe<SortEnumType>;
  enveloppeId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  natureOperation?: InputMaybe<NatureOperationSortInput>;
  natureOperationId?: InputMaybe<SortEnumType>;
};

export type OperationFilterInput = {
  and?: InputMaybe<Array<OperationFilterInput>>;
  attachedFiles?: InputMaybe<ListFilterInputTypeOfBoxFileFilterInput>;
  coInvestisseurId?: InputMaybe<LongOperationFilterInput>;
  commentaireGestionnaire?: InputMaybe<StringOperationFilterInput>;
  consultantId?: InputMaybe<LongOperationFilterInput>;
  contratId?: InputMaybe<LongOperationFilterInput>;
  dateCreation?: InputMaybe<DateTimeOperationFilterInput>;
  dateDeclaration?: InputMaybe<DateTimeOperationFilterInput>;
  dateModification?: InputMaybe<DateTimeOperationFilterInput>;
  donneesSpecifiques?: InputMaybe<StringOperationFilterInput>;
  emails?: InputMaybe<ListFilterInputTypeOfEnvoiEmailFilterInput>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireFilterInput>;
  envoiPartenaireId?: InputMaybe<IntOperationFilterInput>;
  fichierHistoryEntry?: InputMaybe<ListFilterInputTypeOfFichierOperationHistoryRecordFilterInput>;
  fichierOperations?: InputMaybe<ListFilterInputTypeOfFichierOperationFilterInput>;
  fondEvenementiels?: InputMaybe<ListFilterInputTypeOfFondEvenementielFilterInput>;
  gestionnaire?: InputMaybe<BackOfficeMemberFilterInput>;
  gestionnaireId?: InputMaybe<IntOperationFilterInput>;
  historyEntry?: InputMaybe<ListFilterInputTypeOfOperationHistoryRecordFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  isInvestisseurPersonneMorale?: InputMaybe<BooleanOperationFilterInput>;
  messageTechnique?: InputMaybe<StringOperationFilterInput>;
  montant?: InputMaybe<FloatOperationFilterInput>;
  motifAnnulation?: InputMaybe<NullableOfMotifAnnulationOperationFilterInput>;
  motifRejet?: InputMaybe<NullableOfMotifRejetOperationFilterInput>;
  motifRejetCancelCommentaire?: InputMaybe<StringOperationFilterInput>;
  notes?: InputMaybe<ListFilterInputTypeOfNoteOperationFilterInput>;
  operationConfig?: InputMaybe<OperationConfigFilterInput>;
  operationConfigId?: InputMaybe<IntOperationFilterInput>;
  operationGroup?: InputMaybe<OperationGroupFilterInput>;
  operationGroupId?: InputMaybe<IntOperationFilterInput>;
  operationType?: InputMaybe<OperationTypeOperationFilterInput>;
  or?: InputMaybe<Array<OperationFilterInput>>;
  produitId?: InputMaybe<LongOperationFilterInput>;
  statut?: InputMaybe<StatutFilterInput>;
  statutId?: InputMaybe<IntOperationFilterInput>;
  tags?: InputMaybe<ListFilterInputTypeOfOperationTagFilterInput>;
  transactionPersonnelleStatut?: InputMaybe<NullableOfTransactionPersonnelleEnumOperationFilterInput>;
  typeSignaturePartenaire?: InputMaybe<NullableOfTypeSignaturePartenaireOperationFilterInput>;
};

export type OperationGroup = {
  __typename?: 'OperationGroup';
  id: Scalars['Int']['output'];
  operations: Array<Operation>;
};


export type OperationGroupOperationsArgs = {
  where?: InputMaybe<OperationFilterInput>;
};

export type OperationGroupFilterInput = {
  and?: InputMaybe<Array<OperationGroupFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  operations?: InputMaybe<ListFilterInputTypeOfOperationFilterInput>;
  or?: InputMaybe<Array<OperationGroupFilterInput>>;
};

export type OperationGroupSortInput = {
  id?: InputMaybe<SortEnumType>;
};

export type OperationHistoryRecord = {
  __typename?: 'OperationHistoryRecord';
  consultant?: Maybe<Consultant>;
  dispatchTo?: Maybe<BackOfficeMember>;
  dispatchToId?: Maybe<Scalars['Int']['output']>;
  envoiPartenaire?: Maybe<EnvoiPartenaire>;
  envoiPartenaireId?: Maybe<Scalars['Int']['output']>;
  gestionnaireComment?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  initiatorBackOffice?: Maybe<BackOfficeMember>;
  initiatorBackOfficeId?: Maybe<Scalars['Int']['output']>;
  initiatorConsultantId?: Maybe<Scalars['Long']['output']>;
  mailContent?: Maybe<Scalars['String']['output']>;
  newConsultantId?: Maybe<Scalars['Long']['output']>;
  newStatut?: Maybe<Statut>;
  newStatutId?: Maybe<Scalars['Int']['output']>;
  operation: Operation;
  operationId: Scalars['Int']['output'];
  previousConsultantId?: Maybe<Scalars['Long']['output']>;
  previousStatut?: Maybe<Statut>;
  previousStatutId?: Maybe<Scalars['Int']['output']>;
  tags: Array<OperationTag>;
  timestamp: Scalars['DateTime']['output'];
  type: OperationHistoryRecordTypes;
};


export type OperationHistoryRecordTagsArgs = {
  where?: InputMaybe<OperationTagFilterInput>;
};

export type OperationHistoryRecordFilterInput = {
  and?: InputMaybe<Array<OperationHistoryRecordFilterInput>>;
  dispatchTo?: InputMaybe<BackOfficeMemberFilterInput>;
  dispatchToId?: InputMaybe<IntOperationFilterInput>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireFilterInput>;
  envoiPartenaireId?: InputMaybe<IntOperationFilterInput>;
  gestionnaireComment?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  initiatorBackOffice?: InputMaybe<BackOfficeMemberFilterInput>;
  initiatorBackOfficeId?: InputMaybe<IntOperationFilterInput>;
  initiatorConsultantId?: InputMaybe<LongOperationFilterInput>;
  mailContent?: InputMaybe<StringOperationFilterInput>;
  newConsultantId?: InputMaybe<LongOperationFilterInput>;
  newStatut?: InputMaybe<StatutFilterInput>;
  newStatutId?: InputMaybe<IntOperationFilterInput>;
  operation?: InputMaybe<OperationFilterInput>;
  operationId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<OperationHistoryRecordFilterInput>>;
  previousConsultantId?: InputMaybe<LongOperationFilterInput>;
  previousStatut?: InputMaybe<StatutFilterInput>;
  previousStatutId?: InputMaybe<IntOperationFilterInput>;
  tags?: InputMaybe<ListFilterInputTypeOfOperationTagFilterInput>;
  timestamp?: InputMaybe<DateTimeOperationFilterInput>;
  type?: InputMaybe<OperationHistoryRecordTypesOperationFilterInput>;
};

export enum OperationHistoryRecordTypes {
  ConsultantChanged = 'CONSULTANT_CHANGED',
  EnvoiPartenaireCreate = 'ENVOI_PARTENAIRE_CREATE',
  OperationAffectation = 'OPERATION_AFFECTATION',
  OperationCreate = 'OPERATION_CREATE',
  OperationGestionnaireComment = 'OPERATION_GESTIONNAIRE_COMMENT',
  OperationMailPartenaire = 'OPERATION_MAIL_PARTENAIRE',
  OperationStatutChange = 'OPERATION_STATUT_CHANGE',
  OperationTagEdit = 'OPERATION_TAG_EDIT'
}

export type OperationHistoryRecordTypesOperationFilterInput = {
  eq?: InputMaybe<OperationHistoryRecordTypes>;
  in?: InputMaybe<Array<OperationHistoryRecordTypes>>;
  neq?: InputMaybe<OperationHistoryRecordTypes>;
  nin?: InputMaybe<Array<OperationHistoryRecordTypes>>;
};

export type OperationSortInput = {
  coInvestisseurId?: InputMaybe<SortEnumType>;
  commentaireGestionnaire?: InputMaybe<SortEnumType>;
  consultantId?: InputMaybe<SortEnumType>;
  contratId?: InputMaybe<SortEnumType>;
  dateCreation?: InputMaybe<SortEnumType>;
  dateDeclaration?: InputMaybe<SortEnumType>;
  dateModification?: InputMaybe<SortEnumType>;
  donneesSpecifiques?: InputMaybe<SortEnumType>;
  envoiPartenaire?: InputMaybe<EnvoiPartenaireSortInput>;
  envoiPartenaireId?: InputMaybe<SortEnumType>;
  gestionnaire?: InputMaybe<BackOfficeMemberSortInput>;
  gestionnaireId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  investisseurId?: InputMaybe<SortEnumType>;
  isInvestisseurPersonneMorale?: InputMaybe<SortEnumType>;
  messageTechnique?: InputMaybe<SortEnumType>;
  montant?: InputMaybe<SortEnumType>;
  motifAnnulation?: InputMaybe<SortEnumType>;
  motifRejet?: InputMaybe<SortEnumType>;
  motifRejetCancelCommentaire?: InputMaybe<SortEnumType>;
  operationConfig?: InputMaybe<OperationConfigSortInput>;
  operationConfigId?: InputMaybe<SortEnumType>;
  operationGroup?: InputMaybe<OperationGroupSortInput>;
  operationGroupId?: InputMaybe<SortEnumType>;
  operationType?: InputMaybe<SortEnumType>;
  produitId?: InputMaybe<SortEnumType>;
  statut?: InputMaybe<StatutSortInput>;
  statutId?: InputMaybe<SortEnumType>;
  transactionPersonnelleStatut?: InputMaybe<SortEnumType>;
  typeSignaturePartenaire?: InputMaybe<SortEnumType>;
};

export enum OperationStateTransitionTrigger {
  BackOfficeReceivesMailDocuments = 'BACK_OFFICE_RECEIVES_MAIL_DOCUMENTS',
  ConsultantCancelDeclaration = 'CONSULTANT_CANCEL_DECLARATION',
  ConsultantDefinesProduitInSouscriptionDraft = 'CONSULTANT_DEFINES_PRODUIT_IN_SOUSCRIPTION_DRAFT',
  ConsultantFixesNc = 'CONSULTANT_FIXES_NC',
  ConsultantTransfersNewSouscription = 'CONSULTANT_TRANSFERS_NEW_SOUSCRIPTION',
  GestionnaireCancelOperation = 'GESTIONNAIRE_CANCEL_OPERATION',
  GestionnaireCancelSendOperationToPartenaireByCourrier = 'GESTIONNAIRE_CANCEL_SEND_OPERATION_TO_PARTENAIRE_BY_COURRIER',
  GestionnaireDeclareInstancePartenairePresence = 'GESTIONNAIRE_DECLARE_INSTANCE_PARTENAIRE_PRESENCE',
  GestionnaireRefuseOperation = 'GESTIONNAIRE_REFUSE_OPERATION',
  GestionnaireSendsOperationToPartenaireByCourrier = 'GESTIONNAIRE_SENDS_OPERATION_TO_PARTENAIRE_BY_COURRIER',
  GestionnaireTransfersOperationToPartenaire = 'GESTIONNAIRE_TRANSFERS_OPERATION_TO_PARTENAIRE',
  GestionnaireTransfersOperationToPartenaireByCourrier = 'GESTIONNAIRE_TRANSFERS_OPERATION_TO_PARTENAIRE_BY_COURRIER',
  GestionnaireTransfersOperationWithInstance = 'GESTIONNAIRE_TRANSFERS_OPERATION_WITH_INSTANCE',
  GestionnaireTransfersOperationWithNc = 'GESTIONNAIRE_TRANSFERS_OPERATION_WITH_NC',
  ManagerDispatchsOperation = 'MANAGER_DISPATCHS_OPERATION',
  PartenaireValidateOperation = 'PARTENAIRE_VALIDATE_OPERATION'
}

export type OperationTag = {
  __typename?: 'OperationTag';
  code: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  libelle: Scalars['String']['output'];
};

export type OperationTagFilterInput = {
  and?: InputMaybe<Array<OperationTagFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OperationTagFilterInput>>;
};

export enum OperationType {
  ActeDeGestion = 'ACTE_DE_GESTION',
  Souscription = 'SOUSCRIPTION'
}

export type OperationTypeOperationFilterInput = {
  eq?: InputMaybe<OperationType>;
  in?: InputMaybe<Array<OperationType>>;
  neq?: InputMaybe<OperationType>;
  nin?: InputMaybe<Array<OperationType>>;
};

/** A segment of a collection. */
export type OperationsPaginatedCollectionSegment = {
  __typename?: 'OperationsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Operation>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type Organisme = {
  __typename?: 'Organisme';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantHabilitationInscription: Array<ConsultantHabilitationInscription>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  departementCci?: Maybe<Scalars['String']['output']>;
  departementCciNavigation?: Maybe<Departement>;
  habilitationInscription: Array<HabilitationInscription>;
  libelle?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};


export type OrganismeConsultantHabilitationInscriptionArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
};


export type OrganismeHabilitationInscriptionArgs = {
  where?: InputMaybe<HabilitationInscriptionFilterInput>;
};

export type OrganismeAssureurRcp = {
  __typename?: 'OrganismeAssureurRCP';
  adresse?: Maybe<Scalars['String']['output']>;
  consultantRcp: Array<ConsultantRcp>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  nom: Scalars['String']['output'];
};


export type OrganismeAssureurRcpConsultantRcpArgs = {
  where?: InputMaybe<ConsultantRcpFilterInput>;
};

export type OrganismeAssureurRcpFilterInput = {
  adresse?: InputMaybe<StringOperationFilterInput>;
  and?: InputMaybe<Array<OrganismeAssureurRcpFilterInput>>;
  consultantRcp?: InputMaybe<ListFilterInputTypeOfConsultantRcpFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganismeAssureurRcpFilterInput>>;
};

export type OrganismeFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<OrganismeFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantHabilitationInscription?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  departementCci?: InputMaybe<StringOperationFilterInput>;
  departementCciNavigation?: InputMaybe<DepartementFilterInput>;
  habilitationInscription?: InputMaybe<ListFilterInputTypeOfHabilitationInscriptionFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrganismeFilterInput>>;
  type?: InputMaybe<IntOperationFilterInput>;
};

export type OrigineContact = {
  __typename?: 'OrigineContact';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  investisseur: Array<Investisseur>;
  libelle: Scalars['String']['output'];
};


export type OrigineContactInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};

export type OrigineContactFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<OrigineContactFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrigineContactFilterInput>>;
};

export type OrigineContactSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type OrigineData = {
  __typename?: 'OrigineData';
  actionDeFormation: Array<ActionDeFormation>;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  investisseur: Array<Investisseur>;
  libelle: Scalars['String']['output'];
};


export type OrigineDataActionDeFormationArgs = {
  where?: InputMaybe<ActionDeFormationFilterInput>;
};


export type OrigineDataInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};

export type OrigineDataFilterInput = {
  actionDeFormation?: InputMaybe<ListFilterInputTypeOfActionDeFormationFilterInput>;
  and?: InputMaybe<Array<OrigineDataFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<OrigineDataFilterInput>>;
};

export type OrigineDataSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Partenaire = {
  __typename?: 'Partenaire';
  activiteRattachement?: Maybe<Scalars['Byte']['output']>;
  activiteRattachementNavigation?: Maybe<ActiviteRattachPartenaire>;
  contact: Array<Contact>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  nom: Scalars['String']['output'];
  produit: Array<Produit>;
};


export type PartenaireContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type PartenaireProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type PartenaireFilterInput = {
  activiteRattachement?: InputMaybe<ByteOperationFilterInput>;
  activiteRattachementNavigation?: InputMaybe<ActiviteRattachPartenaireFilterInput>;
  and?: InputMaybe<Array<PartenaireFilterInput>>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PartenaireFilterInput>>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
};

export type PartenaireSortInput = {
  activiteRattachement?: InputMaybe<SortEnumType>;
  activiteRattachementNavigation?: InputMaybe<ActiviteRattachPartenaireSortInput>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PartenairesPaginatedCollectionSegment = {
  __typename?: 'PartenairesPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Partenaire>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PatrimoineActif = {
  __typename?: 'PatrimoineActif';
  arianeId?: Maybe<Scalars['Long']['output']>;
  autresChampsJson?: Maybe<Scalars['String']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dateNaissanceUsufruitier?: Maybe<Scalars['DateTime']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  detenteurPatrimoineActif: Array<DetenteurPatrimoineActif>;
  detentionCode?: Maybe<Scalars['String']['output']>;
  detentionCodeNavigation?: Maybe<Detention>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  montant?: Maybe<Scalars['Decimal']['output']>;
  natureCode: Scalars['String']['output'];
  natureCodeNavigation: NaturePatrimoine;
  note?: Maybe<Scalars['String']['output']>;
  patrimoinePassif: Array<PatrimoinePassif>;
};


export type PatrimoineActifDetenteurPatrimoineActifArgs = {
  where?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
};


export type PatrimoineActifPatrimoinePassifArgs = {
  where?: InputMaybe<PatrimoinePassifFilterInput>;
};

export type PatrimoineActifFilterInput = {
  and?: InputMaybe<Array<PatrimoineActifFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  autresChampsJson?: InputMaybe<StringOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  date?: InputMaybe<DateTimeOperationFilterInput>;
  dateNaissanceUsufruitier?: InputMaybe<DateTimeOperationFilterInput>;
  designation?: InputMaybe<StringOperationFilterInput>;
  detenteurPatrimoineActif?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoineActifFilterInput>;
  detentionCode?: InputMaybe<StringOperationFilterInput>;
  detentionCodeNavigation?: InputMaybe<DetentionFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  montant?: InputMaybe<DecimalOperationFilterInput>;
  natureCode?: InputMaybe<StringOperationFilterInput>;
  natureCodeNavigation?: InputMaybe<NaturePatrimoineFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PatrimoineActifFilterInput>>;
  patrimoinePassif?: InputMaybe<ListFilterInputTypeOfPatrimoinePassifFilterInput>;
};

export type PatrimoineChargeRevenu = {
  __typename?: 'PatrimoineChargeRevenu';
  arianeId?: Maybe<Scalars['Long']['output']>;
  autresChampsJson?: Maybe<Scalars['String']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  detenteurPatrimoineChargeRevenu: Array<DetenteurPatrimoineChargeRevenu>;
  detentionCode?: Maybe<Scalars['String']['output']>;
  detentionCodeNavigation?: Maybe<Detention>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseurId?: Maybe<Scalars['Long']['output']>;
  montant?: Maybe<Scalars['Decimal']['output']>;
  natureCode: Scalars['String']['output'];
  natureCodeNavigation: NaturePatrimoine;
  note?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};


export type PatrimoineChargeRevenuDetenteurPatrimoineChargeRevenuArgs = {
  where?: InputMaybe<DetenteurPatrimoineChargeRevenuFilterInput>;
};

export type PatrimoineChargeRevenuFilterInput = {
  and?: InputMaybe<Array<PatrimoineChargeRevenuFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  autresChampsJson?: InputMaybe<StringOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  designation?: InputMaybe<StringOperationFilterInput>;
  detenteurPatrimoineChargeRevenu?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoineChargeRevenuFilterInput>;
  detentionCode?: InputMaybe<StringOperationFilterInput>;
  detentionCodeNavigation?: InputMaybe<DetentionFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  montant?: InputMaybe<DecimalOperationFilterInput>;
  natureCode?: InputMaybe<StringOperationFilterInput>;
  natureCodeNavigation?: InputMaybe<NaturePatrimoineFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PatrimoineChargeRevenuFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type PatrimoineFiscalite = {
  __typename?: 'PatrimoineFiscalite';
  annee?: Maybe<Scalars['Int']['output']>;
  baseImposable?: Maybe<Scalars['Decimal']['output']>;
  chargesDeductibles?: Maybe<Scalars['Decimal']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  csAutresChampsJson?: Maybe<Scalars['String']['output']>;
  csTotalPrelevSociauxNet?: Maybe<Scalars['Decimal']['output']>;
  csgDeductible?: Maybe<Scalars['Decimal']['output']>;
  decote?: Maybe<Scalars['Decimal']['output']>;
  deficitFoncier?: Maybe<Scalars['Decimal']['output']>;
  disponibleFiscal?: Maybe<Scalars['Decimal']['output']>;
  droitsImmo?: Maybe<Scalars['Decimal']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  ifiAutresChampsJson?: Maybe<Scalars['String']['output']>;
  ifiNetAPayer?: Maybe<Scalars['Decimal']['output']>;
  ifiReduction?: Maybe<Scalars['Decimal']['output']>;
  ifiTmi?: Maybe<Scalars['Decimal']['output']>;
  immBatis?: Maybe<Scalars['Decimal']['output']>;
  immNonBatis?: Maybe<Scalars['Decimal']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  irAutresChampsJson?: Maybe<Scalars['String']['output']>;
  irNbParts?: Maybe<Scalars['Decimal']['output']>;
  irNetAvantCorrections?: Maybe<Scalars['Decimal']['output']>;
  irPlafondRetraite?: Maybe<Scalars['Decimal']['output']>;
  irRevenuImposable?: Maybe<Scalars['Decimal']['output']>;
  irSoumis?: Maybe<Scalars['Decimal']['output']>;
  irTauxImposition?: Maybe<Scalars['Decimal']['output']>;
  irTmi?: Maybe<Scalars['Decimal']['output']>;
  irTotalImpotNet?: Maybe<Scalars['Decimal']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  passifDeductible?: Maybe<Scalars['Decimal']['output']>;
  revenuBrutGlobal?: Maybe<Scalars['Decimal']['output']>;
  revenuFiscalDeRef?: Maybe<Scalars['Decimal']['output']>;
  totalCreditImpot?: Maybe<Scalars['Decimal']['output']>;
  totalReduImpots?: Maybe<Scalars['Decimal']['output']>;
  totalSalaireAssimile?: Maybe<Scalars['Decimal']['output']>;
};

export type PatrimoineFiscaliteFilterInput = {
  and?: InputMaybe<Array<PatrimoineFiscaliteFilterInput>>;
  annee?: InputMaybe<IntOperationFilterInput>;
  baseImposable?: InputMaybe<DecimalOperationFilterInput>;
  chargesDeductibles?: InputMaybe<DecimalOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  csAutresChampsJson?: InputMaybe<StringOperationFilterInput>;
  csTotalPrelevSociauxNet?: InputMaybe<DecimalOperationFilterInput>;
  csgDeductible?: InputMaybe<DecimalOperationFilterInput>;
  decote?: InputMaybe<DecimalOperationFilterInput>;
  deficitFoncier?: InputMaybe<DecimalOperationFilterInput>;
  disponibleFiscal?: InputMaybe<DecimalOperationFilterInput>;
  droitsImmo?: InputMaybe<DecimalOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  ifiAutresChampsJson?: InputMaybe<StringOperationFilterInput>;
  ifiNetAPayer?: InputMaybe<DecimalOperationFilterInput>;
  ifiReduction?: InputMaybe<DecimalOperationFilterInput>;
  ifiTmi?: InputMaybe<DecimalOperationFilterInput>;
  immBatis?: InputMaybe<DecimalOperationFilterInput>;
  immNonBatis?: InputMaybe<DecimalOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  irAutresChampsJson?: InputMaybe<StringOperationFilterInput>;
  irNbParts?: InputMaybe<DecimalOperationFilterInput>;
  irNetAvantCorrections?: InputMaybe<DecimalOperationFilterInput>;
  irPlafondRetraite?: InputMaybe<DecimalOperationFilterInput>;
  irRevenuImposable?: InputMaybe<DecimalOperationFilterInput>;
  irSoumis?: InputMaybe<DecimalOperationFilterInput>;
  irTauxImposition?: InputMaybe<DecimalOperationFilterInput>;
  irTmi?: InputMaybe<DecimalOperationFilterInput>;
  irTotalImpotNet?: InputMaybe<DecimalOperationFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PatrimoineFiscaliteFilterInput>>;
  passifDeductible?: InputMaybe<DecimalOperationFilterInput>;
  revenuBrutGlobal?: InputMaybe<DecimalOperationFilterInput>;
  revenuFiscalDeRef?: InputMaybe<DecimalOperationFilterInput>;
  totalCreditImpot?: InputMaybe<DecimalOperationFilterInput>;
  totalReduImpots?: InputMaybe<DecimalOperationFilterInput>;
  totalSalaireAssimile?: InputMaybe<DecimalOperationFilterInput>;
};

export type PatrimoineFiscaliteSortInput = {
  annee?: InputMaybe<SortEnumType>;
  baseImposable?: InputMaybe<SortEnumType>;
  chargesDeductibles?: InputMaybe<SortEnumType>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  csAutresChampsJson?: InputMaybe<SortEnumType>;
  csTotalPrelevSociauxNet?: InputMaybe<SortEnumType>;
  csgDeductible?: InputMaybe<SortEnumType>;
  decote?: InputMaybe<SortEnumType>;
  deficitFoncier?: InputMaybe<SortEnumType>;
  disponibleFiscal?: InputMaybe<SortEnumType>;
  droitsImmo?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  ifiAutresChampsJson?: InputMaybe<SortEnumType>;
  ifiNetAPayer?: InputMaybe<SortEnumType>;
  ifiReduction?: InputMaybe<SortEnumType>;
  ifiTmi?: InputMaybe<SortEnumType>;
  immBatis?: InputMaybe<SortEnumType>;
  immNonBatis?: InputMaybe<SortEnumType>;
  investisseur?: InputMaybe<InvestisseurSortInput>;
  investisseurId?: InputMaybe<SortEnumType>;
  irAutresChampsJson?: InputMaybe<SortEnumType>;
  irNbParts?: InputMaybe<SortEnumType>;
  irNetAvantCorrections?: InputMaybe<SortEnumType>;
  irPlafondRetraite?: InputMaybe<SortEnumType>;
  irRevenuImposable?: InputMaybe<SortEnumType>;
  irSoumis?: InputMaybe<SortEnumType>;
  irTauxImposition?: InputMaybe<SortEnumType>;
  irTmi?: InputMaybe<SortEnumType>;
  irTotalImpotNet?: InputMaybe<SortEnumType>;
  note?: InputMaybe<SortEnumType>;
  passifDeductible?: InputMaybe<SortEnumType>;
  revenuBrutGlobal?: InputMaybe<SortEnumType>;
  revenuFiscalDeRef?: InputMaybe<SortEnumType>;
  totalCreditImpot?: InputMaybe<SortEnumType>;
  totalReduImpots?: InputMaybe<SortEnumType>;
  totalSalaireAssimile?: InputMaybe<SortEnumType>;
};

export type PatrimoinePassif = {
  __typename?: 'PatrimoinePassif';
  actifLie?: Maybe<PatrimoineActif>;
  actifLieId?: Maybe<Scalars['Long']['output']>;
  arianeId?: Maybe<Scalars['Long']['output']>;
  autresChampsJson?: Maybe<Scalars['String']['output']>;
  capitalRestantDu?: Maybe<Scalars['Decimal']['output']>;
  commun?: Maybe<Scalars['UUID']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateSouscription?: Maybe<Scalars['DateTime']['output']>;
  designation?: Maybe<Scalars['String']['output']>;
  detenteurPatrimoinePassif: Array<DetenteurPatrimoinePassif>;
  detentionCode?: Maybe<Scalars['String']['output']>;
  detentionCodeNavigation?: Maybe<Detention>;
  duree?: Maybe<Scalars['Int']['output']>;
  dureeRestante?: Maybe<Scalars['Int']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseurId?: Maybe<Scalars['Long']['output']>;
  mensualiteAdi?: Maybe<Scalars['Decimal']['output']>;
  montant?: Maybe<Scalars['Decimal']['output']>;
  natureCode: Scalars['String']['output'];
  natureCodeNavigation: NaturePatrimoine;
  note?: Maybe<Scalars['String']['output']>;
  quotePartAdi?: Maybe<Scalars['Decimal']['output']>;
  tauxAdi?: Maybe<Scalars['Decimal']['output']>;
  tauxDetention?: Maybe<Scalars['Decimal']['output']>;
  typeCredit?: Maybe<Scalars['String']['output']>;
  typeCreditNavigation?: Maybe<TypeCredit>;
};


export type PatrimoinePassifDetenteurPatrimoinePassifArgs = {
  where?: InputMaybe<DetenteurPatrimoinePassifFilterInput>;
};

export type PatrimoinePassifFilterInput = {
  actifLie?: InputMaybe<PatrimoineActifFilterInput>;
  actifLieId?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<PatrimoinePassifFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  autresChampsJson?: InputMaybe<StringOperationFilterInput>;
  capitalRestantDu?: InputMaybe<DecimalOperationFilterInput>;
  commun?: InputMaybe<UuidOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateSouscription?: InputMaybe<DateTimeOperationFilterInput>;
  designation?: InputMaybe<StringOperationFilterInput>;
  detenteurPatrimoinePassif?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoinePassifFilterInput>;
  detentionCode?: InputMaybe<StringOperationFilterInput>;
  detentionCodeNavigation?: InputMaybe<DetentionFilterInput>;
  duree?: InputMaybe<IntOperationFilterInput>;
  dureeRestante?: InputMaybe<IntOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  mensualiteAdi?: InputMaybe<DecimalOperationFilterInput>;
  montant?: InputMaybe<DecimalOperationFilterInput>;
  natureCode?: InputMaybe<StringOperationFilterInput>;
  natureCodeNavigation?: InputMaybe<NaturePatrimoineFilterInput>;
  note?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PatrimoinePassifFilterInput>>;
  quotePartAdi?: InputMaybe<DecimalOperationFilterInput>;
  tauxAdi?: InputMaybe<DecimalOperationFilterInput>;
  tauxDetention?: InputMaybe<DecimalOperationFilterInput>;
  typeCredit?: InputMaybe<StringOperationFilterInput>;
  typeCreditNavigation?: InputMaybe<TypeCreditFilterInput>;
};

export type Pays = {
  __typename?: 'Pays';
  actif?: Maybe<Scalars['Boolean']['output']>;
  adresse: Array<Adresse>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  etatCivil: Array<EtatCivil>;
  libelle: Scalars['String']['output'];
  personnePhysiqueCodePaysNaissanceNavigation: Array<PersonnePhysique>;
  personnePhysiqueCodePaysNationaliteNavigation: Array<PersonnePhysique>;
  personnePhysiqueCodePaysNationaliteSecondaireNavigation: Array<PersonnePhysique>;
  reglementaireCodePaysFactaResidenceFiscaleNavigation: Array<Reglementaire>;
  reglementaireCodePaysPpeNavigation: Array<Reglementaire>;
};


export type PaysAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};


export type PaysEtatCivilArgs = {
  where?: InputMaybe<EtatCivilFilterInput>;
};


export type PaysPersonnePhysiqueCodePaysNaissanceNavigationArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};


export type PaysPersonnePhysiqueCodePaysNationaliteNavigationArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};


export type PaysPersonnePhysiqueCodePaysNationaliteSecondaireNavigationArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};


export type PaysReglementaireCodePaysFactaResidenceFiscaleNavigationArgs = {
  where?: InputMaybe<ReglementaireFilterInput>;
};


export type PaysReglementaireCodePaysPpeNavigationArgs = {
  where?: InputMaybe<ReglementaireFilterInput>;
};

export type PaysFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<PaysFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  etatCivil?: InputMaybe<ListFilterInputTypeOfEtatCivilFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PaysFilterInput>>;
  personnePhysiqueCodePaysNaissanceNavigation?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  personnePhysiqueCodePaysNationaliteNavigation?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  personnePhysiqueCodePaysNationaliteSecondaireNavigation?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  reglementaireCodePaysFactaResidenceFiscaleNavigation?: InputMaybe<ListFilterInputTypeOfReglementaireFilterInput>;
  reglementaireCodePaysPpeNavigation?: InputMaybe<ListFilterInputTypeOfReglementaireFilterInput>;
};

export type PaysSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type PeriodiciteMouvement = {
  __typename?: 'PeriodiciteMouvement';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  mouvement: Array<Mouvement>;
};


export type PeriodiciteMouvementMouvementArgs = {
  where?: InputMaybe<MouvementFilterInput>;
};

export type PeriodiciteMouvementFilterInput = {
  and?: InputMaybe<Array<PeriodiciteMouvementFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  mouvement?: InputMaybe<ListFilterInputTypeOfMouvementFilterInput>;
  or?: InputMaybe<Array<PeriodiciteMouvementFilterInput>>;
};

export type PeriodiciteMouvementSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export enum Permission {
  Read = 'READ',
  ReadWrite = 'READ_WRITE'
}

export type PersonneMorale = {
  __typename?: 'PersonneMorale';
  codeNafApe?: Maybe<Scalars['String']['output']>;
  contact: Array<Contact>;
  creationDate: Scalars['DateTime']['output'];
  denomination?: Maybe<Scalars['String']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formeJuridique?: Maybe<FormeJuridique>;
  formeJuridiqueId?: Maybe<Scalars['Byte']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  investisseur: Investisseur;
  investisseurId: Scalars['Long']['output'];
  personneMoraleAdresse: Array<PersonneMoraleAdresse>;
  personneMoraleTelephone: Array<PersonneMoraleTelephone>;
  siretSiren?: Maybe<Scalars['String']['output']>;
  siteInternet?: Maybe<Scalars['String']['output']>;
};


export type PersonneMoraleContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type PersonneMoralePersonneMoraleAdresseArgs = {
  where?: InputMaybe<PersonneMoraleAdresseFilterInput>;
};


export type PersonneMoralePersonneMoraleTelephoneArgs = {
  where?: InputMaybe<PersonneMoraleTelephoneFilterInput>;
};

export type PersonneMoraleAdresse = {
  __typename?: 'PersonneMoraleAdresse';
  adresse: Adresse;
  adresseId: Scalars['Long']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  personneMorale: PersonneMorale;
  personneMoraleId: Scalars['Long']['output'];
};

export type PersonneMoraleAdresseFilterInput = {
  adresse?: InputMaybe<AdresseFilterInput>;
  adresseId?: InputMaybe<LongOperationFilterInput>;
  and?: InputMaybe<Array<PersonneMoraleAdresseFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<PersonneMoraleAdresseFilterInput>>;
  personneMorale?: InputMaybe<PersonneMoraleFilterInput>;
  personneMoraleId?: InputMaybe<LongOperationFilterInput>;
};

export type PersonneMoraleFilterInput = {
  and?: InputMaybe<Array<PersonneMoraleFilterInput>>;
  codeNafApe?: InputMaybe<StringOperationFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  denomination?: InputMaybe<StringOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formeJuridique?: InputMaybe<FormeJuridiqueFilterInput>;
  formeJuridiqueId?: InputMaybe<ByteOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  investisseur?: InputMaybe<InvestisseurFilterInput>;
  investisseurId?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<PersonneMoraleFilterInput>>;
  personneMoraleAdresse?: InputMaybe<ListFilterInputTypeOfPersonneMoraleAdresseFilterInput>;
  personneMoraleTelephone?: InputMaybe<ListFilterInputTypeOfPersonneMoraleTelephoneFilterInput>;
  siretSiren?: InputMaybe<StringOperationFilterInput>;
  siteInternet?: InputMaybe<StringOperationFilterInput>;
};

export type PersonneMoraleTelephone = {
  __typename?: 'PersonneMoraleTelephone';
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  personneMorale: PersonneMorale;
  personneMoraleId: Scalars['Long']['output'];
  telephone: Telephone;
  telephoneId: Scalars['Long']['output'];
};

export type PersonneMoraleTelephoneFilterInput = {
  and?: InputMaybe<Array<PersonneMoraleTelephoneFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<PersonneMoraleTelephoneFilterInput>>;
  personneMorale?: InputMaybe<PersonneMoraleFilterInput>;
  personneMoraleId?: InputMaybe<LongOperationFilterInput>;
  telephone?: InputMaybe<TelephoneFilterInput>;
  telephoneId?: InputMaybe<LongOperationFilterInput>;
};

export type PersonnePhysique = {
  __typename?: 'PersonnePhysique';
  adresse: Array<Adresse>;
  arianeId?: Maybe<Scalars['Long']['output']>;
  civiliteCode: Scalars['String']['output'];
  civiliteCodeNavigation: Civilite;
  code?: Maybe<Scalars['String']['output']>;
  codePaysNaissance?: Maybe<Scalars['String']['output']>;
  codePaysNaissanceNavigation?: Maybe<Pays>;
  codePaysNationalite?: Maybe<Scalars['String']['output']>;
  codePaysNationaliteNavigation?: Maybe<Pays>;
  codePaysNationaliteSecondaire?: Maybe<Scalars['String']['output']>;
  codePaysNationaliteSecondaireNavigation?: Maybe<Pays>;
  consultant: Array<Consultant>;
  contact: Array<Contact>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  dateNaissance?: Maybe<Scalars['DateTime']['output']>;
  departementNaissance?: Maybe<Scalars['String']['output']>;
  departementNaissanceNavigation?: Maybe<Departement>;
  detenteurPatrimoineActif: Array<DetenteurPatrimoineActif>;
  deuxiemePrenom?: Maybe<Scalars['String']['output']>;
  disposition?: Maybe<Disposition>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  email: Array<Email>;
  etatCivil?: Maybe<EtatCivil>;
  genre?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  investisseur: Array<Investisseur>;
  login?: Maybe<Scalars['String']['output']>;
  mesureProtectionDate?: Maybe<Scalars['DateTime']['output']>;
  nom: Scalars['String']['output'];
  nomCompletDeuxiemeParent?: Maybe<Scalars['String']['output']>;
  nomCompletPremierParent?: Maybe<Scalars['String']['output']>;
  nomNaissance?: Maybe<Scalars['String']['output']>;
  nomNaissanceUniformise?: Maybe<Scalars['String']['output']>;
  nomUniformise: Scalars['String']['output'];
  personnePhysiqueDocument: Array<PersonnePhysiqueDocument>;
  prenom: Scalars['String']['output'];
  prenomUniformise: Scalars['String']['output'];
  reglementaire?: Maybe<Reglementaire>;
  relationAutrePersonnePhysique1: Array<RelationAutre>;
  relationAutrePersonnePhysique2: Array<RelationAutre>;
  relationCouplePersonnePhysique1: Array<RelationCouple>;
  relationCouplePersonnePhysique2: Array<RelationCouple>;
  relationFamillePersonnePhysique1: Array<RelationFamille>;
  relationFamillePersonnePhysique2: Array<RelationFamille>;
  situationProfessionnelle?: Maybe<SituationProfessionnelle>;
  telephone: Array<Telephone>;
  troisiemePrenom?: Maybe<Scalars['String']['output']>;
  typeMesureProtectionCode?: Maybe<Scalars['String']['output']>;
  typeMesureProtectionCodeNavigation?: Maybe<TypeMesureProtection>;
  villeNaissanceNom?: Maybe<Scalars['String']['output']>;
};


export type PersonnePhysiqueAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};


export type PersonnePhysiqueConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type PersonnePhysiqueContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type PersonnePhysiqueDetenteurPatrimoineActifArgs = {
  where?: InputMaybe<DetenteurPatrimoineActifFilterInput>;
};


export type PersonnePhysiqueEmailArgs = {
  where?: InputMaybe<EmailFilterInput>;
};


export type PersonnePhysiqueInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};


export type PersonnePhysiquePersonnePhysiqueDocumentArgs = {
  where?: InputMaybe<PersonnePhysiqueDocumentFilterInput>;
};


export type PersonnePhysiqueRelationAutrePersonnePhysique1Args = {
  where?: InputMaybe<RelationAutreFilterInput>;
};


export type PersonnePhysiqueRelationAutrePersonnePhysique2Args = {
  where?: InputMaybe<RelationAutreFilterInput>;
};


export type PersonnePhysiqueRelationCouplePersonnePhysique1Args = {
  where?: InputMaybe<RelationCoupleFilterInput>;
};


export type PersonnePhysiqueRelationCouplePersonnePhysique2Args = {
  where?: InputMaybe<RelationCoupleFilterInput>;
};


export type PersonnePhysiqueRelationFamillePersonnePhysique1Args = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};


export type PersonnePhysiqueRelationFamillePersonnePhysique2Args = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};


export type PersonnePhysiqueTelephoneArgs = {
  where?: InputMaybe<TelephoneFilterInput>;
};

export type PersonnePhysiqueDocument = {
  __typename?: 'PersonnePhysiqueDocument';
  codeTypeDocument: Scalars['String']['output'];
  codeTypeDocumentNavigation: GedTypeDocument;
  dateDebutValidite?: Maybe<Scalars['DateTime']['output']>;
  dateFinValidite?: Maybe<Scalars['DateTime']['output']>;
  documentId?: Maybe<Scalars['UUID']['output']>;
  id: Scalars['Long']['output'];
  person: PersonnePhysique;
  personId: Scalars['Long']['output'];
};

export type PersonnePhysiqueDocumentFilterInput = {
  and?: InputMaybe<Array<PersonnePhysiqueDocumentFilterInput>>;
  codeTypeDocument?: InputMaybe<StringOperationFilterInput>;
  codeTypeDocumentNavigation?: InputMaybe<GedTypeDocumentFilterInput>;
  dateDebutValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinValidite?: InputMaybe<DateTimeOperationFilterInput>;
  documentId?: InputMaybe<UuidOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<PersonnePhysiqueDocumentFilterInput>>;
  person?: InputMaybe<PersonnePhysiqueFilterInput>;
  personId?: InputMaybe<LongOperationFilterInput>;
};

export type PersonnePhysiqueFilterInput = {
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<PersonnePhysiqueFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  civiliteCode?: InputMaybe<StringOperationFilterInput>;
  civiliteCodeNavigation?: InputMaybe<CiviliteFilterInput>;
  code?: InputMaybe<StringOperationFilterInput>;
  codePaysNaissance?: InputMaybe<StringOperationFilterInput>;
  codePaysNaissanceNavigation?: InputMaybe<PaysFilterInput>;
  codePaysNationalite?: InputMaybe<StringOperationFilterInput>;
  codePaysNationaliteNavigation?: InputMaybe<PaysFilterInput>;
  codePaysNationaliteSecondaire?: InputMaybe<StringOperationFilterInput>;
  codePaysNationaliteSecondaireNavigation?: InputMaybe<PaysFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateNaissance?: InputMaybe<DateTimeOperationFilterInput>;
  departementNaissance?: InputMaybe<StringOperationFilterInput>;
  departementNaissanceNavigation?: InputMaybe<DepartementFilterInput>;
  detenteurPatrimoineActif?: InputMaybe<ListFilterInputTypeOfDetenteurPatrimoineActifFilterInput>;
  deuxiemePrenom?: InputMaybe<StringOperationFilterInput>;
  disposition?: InputMaybe<DispositionFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<ListFilterInputTypeOfEmailFilterInput>;
  etatCivil?: InputMaybe<EtatCivilFilterInput>;
  genre?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  login?: InputMaybe<StringOperationFilterInput>;
  mesureProtectionDate?: InputMaybe<DateTimeOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  nomCompletDeuxiemeParent?: InputMaybe<StringOperationFilterInput>;
  nomCompletPremierParent?: InputMaybe<StringOperationFilterInput>;
  nomNaissance?: InputMaybe<StringOperationFilterInput>;
  nomNaissanceUniformise?: InputMaybe<StringOperationFilterInput>;
  nomUniformise?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PersonnePhysiqueFilterInput>>;
  personnePhysiqueDocument?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueDocumentFilterInput>;
  prenom?: InputMaybe<StringOperationFilterInput>;
  prenomUniformise?: InputMaybe<StringOperationFilterInput>;
  reglementaire?: InputMaybe<ReglementaireFilterInput>;
  relationAutrePersonnePhysique1?: InputMaybe<ListFilterInputTypeOfRelationAutreFilterInput>;
  relationAutrePersonnePhysique2?: InputMaybe<ListFilterInputTypeOfRelationAutreFilterInput>;
  relationCouplePersonnePhysique1?: InputMaybe<ListFilterInputTypeOfRelationCoupleFilterInput>;
  relationCouplePersonnePhysique2?: InputMaybe<ListFilterInputTypeOfRelationCoupleFilterInput>;
  relationFamillePersonnePhysique1?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
  relationFamillePersonnePhysique2?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
  situationProfessionnelle?: InputMaybe<SituationProfessionnelleFilterInput>;
  telephone?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
  troisiemePrenom?: InputMaybe<StringOperationFilterInput>;
  typeMesureProtectionCode?: InputMaybe<StringOperationFilterInput>;
  typeMesureProtectionCodeNavigation?: InputMaybe<TypeMesureProtectionFilterInput>;
  villeNaissanceNom?: InputMaybe<StringOperationFilterInput>;
};

export type PersonnePhysiqueSortInput = {
  arianeId?: InputMaybe<SortEnumType>;
  civiliteCode?: InputMaybe<SortEnumType>;
  civiliteCodeNavigation?: InputMaybe<CiviliteSortInput>;
  code?: InputMaybe<SortEnumType>;
  codePaysNaissance?: InputMaybe<SortEnumType>;
  codePaysNaissanceNavigation?: InputMaybe<PaysSortInput>;
  codePaysNationalite?: InputMaybe<SortEnumType>;
  codePaysNationaliteNavigation?: InputMaybe<PaysSortInput>;
  codePaysNationaliteSecondaire?: InputMaybe<SortEnumType>;
  codePaysNationaliteSecondaireNavigation?: InputMaybe<PaysSortInput>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  dateNaissance?: InputMaybe<SortEnumType>;
  departementNaissance?: InputMaybe<SortEnumType>;
  departementNaissanceNavigation?: InputMaybe<DepartementSortInput>;
  deuxiemePrenom?: InputMaybe<SortEnumType>;
  disposition?: InputMaybe<DispositionSortInput>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  etatCivil?: InputMaybe<EtatCivilSortInput>;
  genre?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  login?: InputMaybe<SortEnumType>;
  mesureProtectionDate?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
  nomCompletDeuxiemeParent?: InputMaybe<SortEnumType>;
  nomCompletPremierParent?: InputMaybe<SortEnumType>;
  nomNaissance?: InputMaybe<SortEnumType>;
  nomNaissanceUniformise?: InputMaybe<SortEnumType>;
  nomUniformise?: InputMaybe<SortEnumType>;
  prenom?: InputMaybe<SortEnumType>;
  prenomUniformise?: InputMaybe<SortEnumType>;
  reglementaire?: InputMaybe<ReglementaireSortInput>;
  situationProfessionnelle?: InputMaybe<SituationProfessionnelleSortInput>;
  troisiemePrenom?: InputMaybe<SortEnumType>;
  typeMesureProtectionCode?: InputMaybe<SortEnumType>;
  typeMesureProtectionCodeNavigation?: InputMaybe<TypeMesureProtectionSortInput>;
  villeNaissanceNom?: InputMaybe<SortEnumType>;
};

/** A segment of a collection. */
export type PersonnePhysiquesPaginatedCollectionSegment = {
  __typename?: 'PersonnePhysiquesPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<PersonnePhysique>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type PieceJointeConsultant = {
  __typename?: 'PieceJointeConsultant';
  consultantContrat: Array<ConsultantContrat>;
  consultantHabilitationInscription: Array<ConsultantHabilitationInscription>;
  dateDebutValidite: Scalars['DateTime']['output'];
  dateFinValidite: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  lienGed?: Maybe<Scalars['String']['output']>;
  typePieceJointeCode: Scalars['String']['output'];
  typePieceJointeCodeNavigation: TypePieceJointe;
};


export type PieceJointeConsultantConsultantContratArgs = {
  where?: InputMaybe<ConsultantContratFilterInput>;
};


export type PieceJointeConsultantConsultantHabilitationInscriptionArgs = {
  where?: InputMaybe<ConsultantHabilitationInscriptionFilterInput>;
};

export type PieceJointeConsultantFilterInput = {
  and?: InputMaybe<Array<PieceJointeConsultantFilterInput>>;
  consultantContrat?: InputMaybe<ListFilterInputTypeOfConsultantContratFilterInput>;
  consultantHabilitationInscription?: InputMaybe<ListFilterInputTypeOfConsultantHabilitationInscriptionFilterInput>;
  dateDebutValidite?: InputMaybe<DateTimeOperationFilterInput>;
  dateFinValidite?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  lienGed?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<PieceJointeConsultantFilterInput>>;
  typePieceJointeCode?: InputMaybe<StringOperationFilterInput>;
  typePieceJointeCodeNavigation?: InputMaybe<TypePieceJointeFilterInput>;
};

export type PresettedConsultant = {
  __typename?: 'PresettedConsultant';
  civiliteCode?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  dateNaissance?: Maybe<Scalars['DateTime']['output']>;
  habilitationCodes?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['Long']['output'];
  nom?: Maybe<Scalars['String']['output']>;
  prenom?: Maybe<Scalars['String']['output']>;
  qualificationCode?: Maybe<Scalars['String']['output']>;
  qualificationLibelle?: Maybe<Scalars['String']['output']>;
};

export type PresettedInvestisseur = {
  __typename?: 'PresettedInvestisseur';
  civiliteCode?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  dateNaissance?: Maybe<Scalars['DateTime']['output']>;
  denomination?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  nom?: Maybe<Scalars['String']['output']>;
  prenom?: Maybe<Scalars['String']['output']>;
  type: InvestisseurType;
};

export type PresettedPartenaire = {
  __typename?: 'PresettedPartenaire';
  activiteRattachementCode?: Maybe<Scalars['Byte']['output']>;
  activiteRattachementLibelle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  nom: Scalars['String']['output'];
};

export type PresettedProduit = {
  __typename?: 'PresettedProduit';
  enveloppeCode?: Maybe<Scalars['Byte']['output']>;
  enveloppeLibelle?: Maybe<Scalars['String']['output']>;
  etatCode?: Maybe<Scalars['Byte']['output']>;
  etatLibelle?: Maybe<Scalars['String']['output']>;
  habilitationCode?: Maybe<Scalars['String']['output']>;
  habilitationLibelle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  nom: Scalars['String']['output'];
  nomTechniqueProduitBox?: Maybe<Scalars['String']['output']>;
  partenaireId?: Maybe<Scalars['Long']['output']>;
  partenaireLibelle?: Maybe<Scalars['String']['output']>;
};

export type Produit = {
  __typename?: 'Produit';
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  dateCloture?: Maybe<Scalars['DateTime']['output']>;
  dateInitialisation?: Maybe<Scalars['DateTime']['output']>;
  dateLancementInterne?: Maybe<Scalars['DateTime']['output']>;
  dateOuverture?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  enveloppe?: Maybe<Scalars['Byte']['output']>;
  enveloppeNavigation?: Maybe<EnveloppeProduit>;
  etat?: Maybe<Scalars['Byte']['output']>;
  etatNavigation?: Maybe<EtatProduit>;
  filialeFacturation?: Maybe<Scalars['Byte']['output']>;
  filialeFacturationNavigation?: Maybe<FilialeFacturation>;
  habilitation?: Maybe<Scalars['Long']['output']>;
  habilitationNavigation?: Maybe<Habilitation>;
  id: Scalars['Long']['output'];
  idPartenaire?: Maybe<Scalars['Long']['output']>;
  idPartenaireNavigation?: Maybe<Partenaire>;
  idSource?: Maybe<Scalars['Long']['output']>;
  nom: Scalars['String']['output'];
  nomTechniqueProduitBox?: Maybe<Scalars['String']['output']>;
  produitExtension?: Maybe<ProduitExtension>;
  typeProduit: Scalars['Byte']['output'];
  typeProduitNavigation: TypeProduit;
};


export type ProduitCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};

export type ProduitExtension = {
  __typename?: 'ProduitExtension';
  id: Scalars['Int']['output'];
  libelle?: Maybe<Scalars['String']['output']>;
  souscriptionPossible?: Maybe<Scalars['Boolean']['output']>;
};

export type ProduitFilterInput = {
  and?: InputMaybe<Array<ProduitFilterInput>>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateCloture?: InputMaybe<DateTimeOperationFilterInput>;
  dateInitialisation?: InputMaybe<DateTimeOperationFilterInput>;
  dateLancementInterne?: InputMaybe<DateTimeOperationFilterInput>;
  dateOuverture?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  enveloppe?: InputMaybe<ByteOperationFilterInput>;
  enveloppeNavigation?: InputMaybe<EnveloppeProduitFilterInput>;
  etat?: InputMaybe<ByteOperationFilterInput>;
  etatNavigation?: InputMaybe<EtatProduitFilterInput>;
  filialeFacturation?: InputMaybe<ByteOperationFilterInput>;
  filialeFacturationNavigation?: InputMaybe<FilialeFacturationFilterInput>;
  habilitation?: InputMaybe<LongOperationFilterInput>;
  habilitationNavigation?: InputMaybe<HabilitationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idPartenaire?: InputMaybe<LongOperationFilterInput>;
  idPartenaireNavigation?: InputMaybe<PartenaireFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  nomTechniqueProduitBox?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProduitFilterInput>>;
  typeProduit?: InputMaybe<ByteOperationFilterInput>;
  typeProduitNavigation?: InputMaybe<TypeProduitFilterInput>;
};

export type ProduitFondEvenementiel = {
  __typename?: 'ProduitFondEvenementiel';
  fondEvenementiel: FondEvenementiel;
  fondEvenementielId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  produitId: Scalars['Int']['output'];
};

export type ProduitFondEvenementielFilterInput = {
  and?: InputMaybe<Array<ProduitFondEvenementielFilterInput>>;
  fondEvenementiel?: InputMaybe<FondEvenementielFilterInput>;
  fondEvenementielId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProduitFondEvenementielFilterInput>>;
  produitId?: InputMaybe<IntOperationFilterInput>;
};

export type ProduitSortInput = {
  creationDate?: InputMaybe<SortEnumType>;
  dateCloture?: InputMaybe<SortEnumType>;
  dateInitialisation?: InputMaybe<SortEnumType>;
  dateLancementInterne?: InputMaybe<SortEnumType>;
  dateOuverture?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  enveloppe?: InputMaybe<SortEnumType>;
  enveloppeNavigation?: InputMaybe<EnveloppeProduitSortInput>;
  etat?: InputMaybe<SortEnumType>;
  etatNavigation?: InputMaybe<EtatProduitSortInput>;
  filialeFacturation?: InputMaybe<SortEnumType>;
  filialeFacturationNavigation?: InputMaybe<FilialeFacturationSortInput>;
  habilitation?: InputMaybe<SortEnumType>;
  habilitationNavigation?: InputMaybe<HabilitationSortInput>;
  id?: InputMaybe<SortEnumType>;
  idPartenaire?: InputMaybe<SortEnumType>;
  idPartenaireNavigation?: InputMaybe<PartenaireSortInput>;
  idSource?: InputMaybe<SortEnumType>;
  nom?: InputMaybe<SortEnumType>;
  nomTechniqueProduitBox?: InputMaybe<SortEnumType>;
  typeProduit?: InputMaybe<SortEnumType>;
  typeProduitNavigation?: InputMaybe<TypeProduitSortInput>;
};

/** A segment of a collection. */
export type ProduitsPaginatedCollectionSegment = {
  __typename?: 'ProduitsPaginatedCollectionSegment';
  /** A flattened list of the items. */
  items?: Maybe<Array<Produit>>;
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  totalCount: Scalars['Int']['output'];
};

export type ProfilQpi = {
  __typename?: 'ProfilQpi';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  investisseurQpiProfilCalculCodeNavigation: Array<InvestisseurQpi>;
  investisseurQpiProfilDerogationCodeNavigation: Array<InvestisseurQpi>;
  libelle: Scalars['String']['output'];
};


export type ProfilQpiInvestisseurQpiProfilCalculCodeNavigationArgs = {
  where?: InputMaybe<InvestisseurQpiFilterInput>;
};


export type ProfilQpiInvestisseurQpiProfilDerogationCodeNavigationArgs = {
  where?: InputMaybe<InvestisseurQpiFilterInput>;
};

export type ProfilQpiFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<ProfilQpiFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseurQpiProfilCalculCodeNavigation?: InputMaybe<ListFilterInputTypeOfInvestisseurQpiFilterInput>;
  investisseurQpiProfilDerogationCodeNavigation?: InputMaybe<ListFilterInputTypeOfInvestisseurQpiFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProfilQpiFilterInput>>;
};

export type ProfilQpiSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type QualificationConsultant = {
  __typename?: 'QualificationConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultant: Array<Consultant>;
  consultantOrganisation: Array<ConsultantOrganisation>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type QualificationConsultantConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type QualificationConsultantConsultantOrganisationArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};

export type QualificationConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<QualificationConsultantFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  consultantOrganisation?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<QualificationConsultantFilterInput>>;
};

export type QualificationConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type Query = {
  __typename?: 'Query';
  ConsultantParametersConfig: ConsultantParametersConfig;
  allActiveInvestissorsIds?: Maybe<AllActiveInvestissorsIdsConnection>;
  allBackOfficeMembers: Array<BackOfficeMember>;
  allBackOfficeMembersPaginated?: Maybe<BackOfficeMembersPaginatedCollectionSegment>;
  allBoxDocumentTypes: Array<BoxDocumentType>;
  allBoxDocumentTypesPaginated?: Maybe<BoxDocumentTypesPaginatedCollectionSegment>;
  allCommandesFinancieresPaginated?: Maybe<CommandesFinancieresPaginatedCollectionSegment>;
  allCommandesPaginated?: Maybe<CommandesPaginatedCollectionSegment>;
  allConsultantsPaginated?: Maybe<ConsultantsPaginatedCollectionSegment>;
  allCreationPersonneMorales: Array<CreationPersonneMorale>;
  allCreationPersonneMoralesPaginated?: Maybe<CreationPersonneMoralesPaginatedCollectionSegment>;
  allEnveloppeProduits: Array<EnveloppeProduit>;
  allEnvoiPartenairePaginated?: Maybe<EnvoiPartenairePaginatedCollectionSegment>;
  allFichierOperationsPaginated?: Maybe<AllFichierOperationsPaginatedCollectionSegment>;
  allFondEvenementielsPaginated?: Maybe<FondEvenementielsPaginatedCollectionSegment>;
  allGestionnaireWorkLoadPaginated?: Maybe<GestionnaireWorkLoadsCollectionSegment>;
  allInvestisseursPaginated?: Maybe<InvestisseursPaginatedCollectionSegment>;
  allNatureOperations: Array<NatureOperation>;
  allNonConformiteFichiersPaginated?: Maybe<NonConformiteFichiersCollectionSegment>;
  allOperationConfig: Array<OperationConfig>;
  allOperationTags: Array<OperationTag>;
  allOperationsPaginated?: Maybe<OperationsPaginatedCollectionSegment>;
  allPartenaires: Array<Partenaire>;
  allProduits: Array<Produit>;
  allProduitsPaginated?: Maybe<ProduitsPaginatedCollectionSegment>;
  allStatuts: Array<Statut>;
  allTypeGED: Array<GedTypeDocument>;
  consultantByCode?: Maybe<Consultant>;
  envoiPartenaireById?: Maybe<EnvoiPartenaire>;
  fichierOperationStatuts: Array<FichierOperationStatut>;
  fondEvenementielsByProductId: Array<FondEvenementiel>;
  formeJuridiques: Array<FormeJuridique>;
  gedFileMetadataById?: Maybe<FileMetadata>;
  investisseurAndDuplicatesById?: Maybe<Array<Investisseur>>;
  investisseurById?: Maybe<Investisseur>;
  investisseursByNamePaginated?: Maybe<InvestisseursPaginatedCollectionSegment>;
  partenaireById?: Maybe<Partenaire>;
  presettedConsultantById?: Maybe<PresettedConsultant>;
  presettedInvestisseurById?: Maybe<PresettedInvestisseur>;
  presettedProduitById: PresettedProduit;
  produitById: Produit;
  senderEmail?: Maybe<Scalars['String']['output']>;
};


export type QueryAllActiveInvestissorsIdsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  operationAgeThresholdInYears?: Scalars['Int']['input'];
};


export type QueryAllBackOfficeMembersArgs = {
  where?: InputMaybe<BackOfficeMemberFilterInput>;
};


export type QueryAllBackOfficeMembersPaginatedArgs = {
  order?: InputMaybe<Array<BackOfficeMemberSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BackOfficeMemberFilterInput>;
};


export type QueryAllBoxDocumentTypesArgs = {
  where?: InputMaybe<BoxDocumentTypeFilterInput>;
};


export type QueryAllBoxDocumentTypesPaginatedArgs = {
  order?: InputMaybe<Array<BoxDocumentTypeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BoxDocumentTypeFilterInput>;
};


export type QueryAllCommandesFinancieresPaginatedArgs = {
  order?: InputMaybe<Array<CommandeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommandeFilterInput>;
};


export type QueryAllCommandesPaginatedArgs = {
  order?: InputMaybe<Array<CommandeSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CommandeFilterInput>;
};


export type QueryAllConsultantsPaginatedArgs = {
  order?: InputMaybe<Array<ConsultantSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ConsultantFilterInput>;
};


export type QueryAllCreationPersonneMoralesArgs = {
  where?: InputMaybe<CreationPersonneMoraleFilterInput>;
};


export type QueryAllCreationPersonneMoralesPaginatedArgs = {
  order?: InputMaybe<Array<CreationPersonneMoraleSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CreationPersonneMoraleFilterInput>;
};


export type QueryAllEnveloppeProduitsArgs = {
  where?: InputMaybe<EnveloppeProduitFilterInput>;
};


export type QueryAllEnvoiPartenairePaginatedArgs = {
  order?: InputMaybe<Array<EnvoiPartenaireSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EnvoiPartenaireFilterInput>;
};


export type QueryAllFichierOperationsPaginatedArgs = {
  order?: InputMaybe<Array<FichierOperationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FichierOperationFilterInput>;
};


export type QueryAllFondEvenementielsPaginatedArgs = {
  order?: InputMaybe<Array<FondEvenementielSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FondEvenementielFilterInput>;
};


export type QueryAllGestionnaireWorkLoadPaginatedArgs = {
  order?: InputMaybe<Array<GestionnaireWorkLoadSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GestionnaireWorkLoadFilterInput>;
};


export type QueryAllInvestisseursPaginatedArgs = {
  order?: InputMaybe<Array<InvestisseurSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvestisseurFilterInput>;
};


export type QueryAllNatureOperationsArgs = {
  where?: InputMaybe<NatureOperationFilterInput>;
};


export type QueryAllNonConformiteFichiersPaginatedArgs = {
  order?: InputMaybe<Array<NonConformiteFichierSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NonConformiteFichierFilterInput>;
};


export type QueryAllOperationConfigArgs = {
  where?: InputMaybe<OperationConfigFilterInput>;
};


export type QueryAllOperationTagsArgs = {
  where?: InputMaybe<OperationTagFilterInput>;
};


export type QueryAllOperationsPaginatedArgs = {
  order?: InputMaybe<Array<OperationSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<OperationFilterInput>;
};


export type QueryAllPartenairesArgs = {
  order?: InputMaybe<Array<PartenaireSortInput>>;
  where?: InputMaybe<PartenaireFilterInput>;
};


export type QueryAllProduitsArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};


export type QueryAllProduitsPaginatedArgs = {
  order?: InputMaybe<Array<ProduitSortInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ProduitFilterInput>;
};


export type QueryAllStatutsArgs = {
  where?: InputMaybe<StatutFilterInput>;
};


export type QueryAllTypeGedArgs = {
  where?: InputMaybe<GedTypeDocumentFilterInput>;
};


export type QueryConsultantByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryEnvoiPartenaireByIdArgs = {
  id: Scalars['Int']['input'];
};


export type QueryFichierOperationStatutsArgs = {
  where?: InputMaybe<FichierOperationStatutFilterInput>;
};


export type QueryFondEvenementielsByProductIdArgs = {
  productId: Scalars['Int']['input'];
};


export type QueryFormeJuridiquesArgs = {
  where?: InputMaybe<FormeJuridiqueFilterInput>;
};


export type QueryGedFileMetadataByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryInvestisseurAndDuplicatesByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryInvestisseurByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryInvestisseursByNamePaginatedArgs = {
  order?: InputMaybe<Array<InvestisseurSortInput>>;
  searchText: Scalars['String']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<InvestisseurFilterInput>;
};


export type QueryPartenaireByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryPresettedConsultantByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryPresettedInvestisseurByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryPresettedProduitByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QueryProduitByIdArgs = {
  id: Scalars['Long']['input'];
};


export type QuerySenderEmailArgs = {
  envoiPartenaireId?: InputMaybe<Scalars['Int']['input']>;
  operationId?: InputMaybe<Scalars['Int']['input']>;
};

export type Reglementaire = {
  __typename?: 'Reglementaire';
  codePaysFactaResidenceFiscale?: Maybe<Scalars['String']['output']>;
  codePaysFactaResidenceFiscaleNavigation?: Maybe<Pays>;
  codePaysPpe?: Maybe<Scalars['String']['output']>;
  codePaysPpeNavigation?: Maybe<Pays>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  factaStatut?: Maybe<Scalars['Boolean']['output']>;
  factaTinCode?: Maybe<Scalars['String']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  nifCode?: Maybe<Scalars['String']['output']>;
  personnePhysique: PersonnePhysique;
  personnePhysiqueId: Scalars['Long']['output'];
  ppeAnciennete?: Maybe<Scalars['DateTime']['output']>;
  ppeStatut?: Maybe<Scalars['Boolean']['output']>;
  typePpeMotifCode?: Maybe<Scalars['String']['output']>;
  typePpeMotifCodeNavigation?: Maybe<TypePpeMotif>;
  typePpeRelationCode?: Maybe<Scalars['String']['output']>;
  typePpeRelationCodeNavigation?: Maybe<TypePpeRelation>;
};

export type ReglementaireFilterInput = {
  and?: InputMaybe<Array<ReglementaireFilterInput>>;
  codePaysFactaResidenceFiscale?: InputMaybe<StringOperationFilterInput>;
  codePaysFactaResidenceFiscaleNavigation?: InputMaybe<PaysFilterInput>;
  codePaysPpe?: InputMaybe<StringOperationFilterInput>;
  codePaysPpeNavigation?: InputMaybe<PaysFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  factaStatut?: InputMaybe<BooleanOperationFilterInput>;
  factaTinCode?: InputMaybe<StringOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  nifCode?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReglementaireFilterInput>>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
  ppeAnciennete?: InputMaybe<DateTimeOperationFilterInput>;
  ppeStatut?: InputMaybe<BooleanOperationFilterInput>;
  typePpeMotifCode?: InputMaybe<StringOperationFilterInput>;
  typePpeMotifCodeNavigation?: InputMaybe<TypePpeMotifFilterInput>;
  typePpeRelationCode?: InputMaybe<StringOperationFilterInput>;
  typePpeRelationCodeNavigation?: InputMaybe<TypePpeRelationFilterInput>;
};

export type ReglementaireSortInput = {
  codePaysFactaResidenceFiscale?: InputMaybe<SortEnumType>;
  codePaysFactaResidenceFiscaleNavigation?: InputMaybe<PaysSortInput>;
  codePaysPpe?: InputMaybe<SortEnumType>;
  codePaysPpeNavigation?: InputMaybe<PaysSortInput>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  factaStatut?: InputMaybe<SortEnumType>;
  factaTinCode?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  nifCode?: InputMaybe<SortEnumType>;
  personnePhysique?: InputMaybe<PersonnePhysiqueSortInput>;
  personnePhysiqueId?: InputMaybe<SortEnumType>;
  ppeAnciennete?: InputMaybe<SortEnumType>;
  ppeStatut?: InputMaybe<SortEnumType>;
  typePpeMotifCode?: InputMaybe<SortEnumType>;
  typePpeMotifCodeNavigation?: InputMaybe<TypePpeMotifSortInput>;
  typePpeRelationCode?: InputMaybe<SortEnumType>;
  typePpeRelationCodeNavigation?: InputMaybe<TypePpeRelationSortInput>;
};

export type RelationAutre = {
  __typename?: 'RelationAutre';
  arianeId?: Maybe<Scalars['Long']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  personnePhysique1: PersonnePhysique;
  personnePhysique1Id: Scalars['Long']['output'];
  personnePhysique2: PersonnePhysique;
  personnePhysique2Id: Scalars['Long']['output'];
  typeRelationAutreCode: Scalars['String']['output'];
  typeRelationAutreCodeNavigation: TypeRelationAutre;
  typeRelationAutreCodeRev: Scalars['String']['output'];
  typeRelationAutreCodeRevNavigation: TypeRelationAutre;
};

export type RelationAutreFilterInput = {
  and?: InputMaybe<Array<RelationAutreFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<RelationAutreFilterInput>>;
  personnePhysique1?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique1Id?: InputMaybe<LongOperationFilterInput>;
  personnePhysique2?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique2Id?: InputMaybe<LongOperationFilterInput>;
  typeRelationAutreCode?: InputMaybe<StringOperationFilterInput>;
  typeRelationAutreCodeNavigation?: InputMaybe<TypeRelationAutreFilterInput>;
  typeRelationAutreCodeRev?: InputMaybe<StringOperationFilterInput>;
  typeRelationAutreCodeRevNavigation?: InputMaybe<TypeRelationAutreFilterInput>;
};

export type RelationCouple = {
  __typename?: 'RelationCouple';
  arianeId?: Maybe<Scalars['Long']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['Long']['output']>;
  personnePhysique1: PersonnePhysique;
  personnePhysique1Id: Scalars['Long']['output'];
  personnePhysique2: PersonnePhysique;
  personnePhysique2Id: Scalars['Long']['output'];
  typeRelationCoupleCode: Scalars['String']['output'];
  typeRelationCoupleCodeNavigation: TypeRelationCouple;
};

export type RelationCoupleFilterInput = {
  and?: InputMaybe<Array<RelationCoupleFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  or?: InputMaybe<Array<RelationCoupleFilterInput>>;
  personnePhysique1?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique1Id?: InputMaybe<LongOperationFilterInput>;
  personnePhysique2?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique2Id?: InputMaybe<LongOperationFilterInput>;
  typeRelationCoupleCode?: InputMaybe<StringOperationFilterInput>;
  typeRelationCoupleCodeNavigation?: InputMaybe<TypeRelationCoupleFilterInput>;
};

export type RelationFamille = {
  __typename?: 'RelationFamille';
  arianeId?: Maybe<Scalars['Long']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  enfantACharge?: Maybe<Scalars['Boolean']['output']>;
  enfantAdopte?: Maybe<Scalars['Boolean']['output']>;
  gardeAlternee?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  niveauEtudeCode?: Maybe<Scalars['String']['output']>;
  niveauEtudeCodeNavigation?: Maybe<NiveauEtude>;
  personnePhysique1: PersonnePhysique;
  personnePhysique1Id: Scalars['Long']['output'];
  personnePhysique2: PersonnePhysique;
  personnePhysique2Id: Scalars['Long']['output'];
  typeAdoptionCode?: Maybe<Scalars['String']['output']>;
  typeAdoptionCodeNavigation?: Maybe<TypeAdoption>;
  typeRelationFamilleCode: Scalars['String']['output'];
  typeRelationFamilleCodeNavigation: TypeRelationFamille;
  typeRelationFamilleCodeRev: Scalars['String']['output'];
  typeRelationFamilleCodeRevNavigation: TypeRelationFamille;
};

export type RelationFamilleFilterInput = {
  and?: InputMaybe<Array<RelationFamilleFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  enfantACharge?: InputMaybe<BooleanOperationFilterInput>;
  enfantAdopte?: InputMaybe<BooleanOperationFilterInput>;
  gardeAlternee?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  niveauEtudeCode?: InputMaybe<StringOperationFilterInput>;
  niveauEtudeCodeNavigation?: InputMaybe<NiveauEtudeFilterInput>;
  or?: InputMaybe<Array<RelationFamilleFilterInput>>;
  personnePhysique1?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique1Id?: InputMaybe<LongOperationFilterInput>;
  personnePhysique2?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysique2Id?: InputMaybe<LongOperationFilterInput>;
  typeAdoptionCode?: InputMaybe<StringOperationFilterInput>;
  typeAdoptionCodeNavigation?: InputMaybe<TypeAdoptionFilterInput>;
  typeRelationFamilleCode?: InputMaybe<StringOperationFilterInput>;
  typeRelationFamilleCodeNavigation?: InputMaybe<TypeRelationFamilleFilterInput>;
  typeRelationFamilleCodeRev?: InputMaybe<StringOperationFilterInput>;
  typeRelationFamilleCodeRevNavigation?: InputMaybe<TypeRelationFamilleFilterInput>;
};

export type ReponseReadhesion = {
  __typename?: 'ReponseReadhesion';
  adhesionConsultant: Array<AdhesionConsultant>;
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type ReponseReadhesionAdhesionConsultantArgs = {
  where?: InputMaybe<AdhesionConsultantFilterInput>;
};

export type ReponseReadhesionFilterInput = {
  adhesionConsultant?: InputMaybe<ListFilterInputTypeOfAdhesionConsultantFilterInput>;
  and?: InputMaybe<Array<ReponseReadhesionFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ReponseReadhesionFilterInput>>;
};

export type ResiliationPaiementB2S = {
  __typename?: 'ResiliationPaiementB2S';
  creationDate: Scalars['DateTime']['output'];
  dateProchainPaiement?: Maybe<Scalars['DateTime']['output']>;
  editionDate: Scalars['DateTime']['output'];
  etat: Scalars['String']['output'];
  etatNavigation: EtatResiliationB2S;
  id: Scalars['Long']['output'];
  idConsultant: Scalars['Long']['output'];
  idConsultantNavigation: Consultant;
  messageRetour?: Maybe<Scalars['String']['output']>;
};

export type ResiliationPaiementB2SFilterInput = {
  and?: InputMaybe<Array<ResiliationPaiementB2SFilterInput>>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateProchainPaiement?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  etat?: InputMaybe<StringOperationFilterInput>;
  etatNavigation?: InputMaybe<EtatResiliationB2SFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idConsultant?: InputMaybe<LongOperationFilterInput>;
  idConsultantNavigation?: InputMaybe<ConsultantFilterInput>;
  messageRetour?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ResiliationPaiementB2SFilterInput>>;
};

export type SeanceSession = {
  __typename?: 'SeanceSession';
  codeEtat?: Maybe<Scalars['Byte']['output']>;
  codeEtatNavigation?: Maybe<SeanceSessionEtat>;
  creationDate: Scalars['DateTime']['output'];
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  nombreHeures?: Maybe<Scalars['Decimal']['output']>;
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['Long']['output']>;
};

export type SeanceSessionEtat = {
  __typename?: 'SeanceSessionEtat';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  seanceSession: Array<SeanceSession>;
};


export type SeanceSessionEtatSeanceSessionArgs = {
  where?: InputMaybe<SeanceSessionFilterInput>;
};

export type SeanceSessionEtatFilterInput = {
  and?: InputMaybe<Array<SeanceSessionEtatFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SeanceSessionEtatFilterInput>>;
  seanceSession?: InputMaybe<ListFilterInputTypeOfSeanceSessionFilterInput>;
};

export type SeanceSessionFilterInput = {
  and?: InputMaybe<Array<SeanceSessionFilterInput>>;
  codeEtat?: InputMaybe<ByteOperationFilterInput>;
  codeEtatNavigation?: InputMaybe<SeanceSessionEtatFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  nombreHeures?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<SeanceSessionFilterInput>>;
  session?: InputMaybe<SessionFilterInput>;
  sessionId?: InputMaybe<LongOperationFilterInput>;
};

export type ServiceByEnveloppeCode = {
  __typename?: 'ServiceByEnveloppeCode';
  enveloppeCode: Scalars['Byte']['output'];
  service: BackOfficeService;
};

export type ServiceByEnveloppeCodeFilterInput = {
  and?: InputMaybe<Array<ServiceByEnveloppeCodeFilterInput>>;
  enveloppeCode?: InputMaybe<ByteOperationFilterInput>;
  or?: InputMaybe<Array<ServiceByEnveloppeCodeFilterInput>>;
  service?: InputMaybe<BackOfficeServiceOperationFilterInput>;
};

export type Session = {
  __typename?: 'Session';
  actionDeFormationSession: Array<ActionDeFormationSession>;
  codeEtat?: Maybe<Scalars['Byte']['output']>;
  codeEtatNavigation?: Maybe<SessionEtat>;
  codeGenre?: Maybe<Scalars['Byte']['output']>;
  codeGenreNavigation?: Maybe<SessionGenre>;
  creationDate: Scalars['DateTime']['output'];
  dateDebut?: Maybe<Scalars['DateTime']['output']>;
  dateFin?: Maybe<Scalars['DateTime']['output']>;
  dureeHeure?: Maybe<Scalars['Decimal']['output']>;
  dureeJour?: Maybe<Scalars['Decimal']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['Long']['output']>;
  inscriptionSession: Array<InscriptionSession>;
  nom: Scalars['String']['output'];
  seanceSession: Array<SeanceSession>;
};


export type SessionActionDeFormationSessionArgs = {
  where?: InputMaybe<ActionDeFormationSessionFilterInput>;
};


export type SessionInscriptionSessionArgs = {
  where?: InputMaybe<InscriptionSessionFilterInput>;
};


export type SessionSeanceSessionArgs = {
  where?: InputMaybe<SeanceSessionFilterInput>;
};

export type SessionEtat = {
  __typename?: 'SessionEtat';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  cycleCourt: Array<CycleCourt>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  session: Array<Session>;
};


export type SessionEtatCycleCourtArgs = {
  where?: InputMaybe<CycleCourtFilterInput>;
};


export type SessionEtatSessionArgs = {
  where?: InputMaybe<SessionFilterInput>;
};

export type SessionEtatFilterInput = {
  and?: InputMaybe<Array<SessionEtatFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cycleCourt?: InputMaybe<ListFilterInputTypeOfCycleCourtFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SessionEtatFilterInput>>;
  session?: InputMaybe<ListFilterInputTypeOfSessionFilterInput>;
};

export type SessionFilterInput = {
  actionDeFormationSession?: InputMaybe<ListFilterInputTypeOfActionDeFormationSessionFilterInput>;
  and?: InputMaybe<Array<SessionFilterInput>>;
  codeEtat?: InputMaybe<ByteOperationFilterInput>;
  codeEtatNavigation?: InputMaybe<SessionEtatFilterInput>;
  codeGenre?: InputMaybe<ByteOperationFilterInput>;
  codeGenreNavigation?: InputMaybe<SessionGenreFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  dateDebut?: InputMaybe<DateTimeOperationFilterInput>;
  dateFin?: InputMaybe<DateTimeOperationFilterInput>;
  dureeHeure?: InputMaybe<DecimalOperationFilterInput>;
  dureeJour?: InputMaybe<DecimalOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<LongOperationFilterInput>;
  inscriptionSession?: InputMaybe<ListFilterInputTypeOfInscriptionSessionFilterInput>;
  nom?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SessionFilterInput>>;
  seanceSession?: InputMaybe<ListFilterInputTypeOfSeanceSessionFilterInput>;
};

export type SessionGenre = {
  __typename?: 'SessionGenre';
  code: Scalars['Byte']['output'];
  codeSource: Scalars['String']['output'];
  creationDate: Scalars['DateTime']['output'];
  cycleCourt: Array<CycleCourt>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  session: Array<Session>;
};


export type SessionGenreCycleCourtArgs = {
  where?: InputMaybe<CycleCourtFilterInput>;
};


export type SessionGenreSessionArgs = {
  where?: InputMaybe<SessionFilterInput>;
};

export type SessionGenreFilterInput = {
  and?: InputMaybe<Array<SessionGenreFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  codeSource?: InputMaybe<StringOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  cycleCourt?: InputMaybe<ListFilterInputTypeOfCycleCourtFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SessionGenreFilterInput>>;
  session?: InputMaybe<ListFilterInputTypeOfSessionFilterInput>;
};

export enum SignatureState {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Ready = 'READY',
  ValeurNonSupportee = 'VALEUR_NON_SUPPORTEE'
}

export type SituationProfessionnelle = {
  __typename?: 'SituationProfessionnelle';
  anciennete?: Maybe<Scalars['DateTime']['output']>;
  categorieSocioProfessionnelleCode?: Maybe<Scalars['String']['output']>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  idSource?: Maybe<Scalars['String']['output']>;
  nomSociete?: Maybe<Scalars['String']['output']>;
  note: Array<Note>;
  origineRevenus?: Maybe<Scalars['String']['output']>;
  personnePhysique: PersonnePhysique;
  personnePhysiqueId: Scalars['Long']['output'];
  profession?: Maybe<Scalars['String']['output']>;
};


export type SituationProfessionnelleNoteArgs = {
  where?: InputMaybe<NoteFilterInput>;
};

export type SituationProfessionnelleFilterInput = {
  anciennete?: InputMaybe<DateTimeOperationFilterInput>;
  and?: InputMaybe<Array<SituationProfessionnelleFilterInput>>;
  categorieSocioProfessionnelleCode?: InputMaybe<StringOperationFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  nomSociete?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<ListFilterInputTypeOfNoteFilterInput>;
  or?: InputMaybe<Array<SituationProfessionnelleFilterInput>>;
  origineRevenus?: InputMaybe<StringOperationFilterInput>;
  personnePhysique?: InputMaybe<PersonnePhysiqueFilterInput>;
  personnePhysiqueId?: InputMaybe<LongOperationFilterInput>;
  profession?: InputMaybe<StringOperationFilterInput>;
};

export type SituationProfessionnelleSortInput = {
  anciennete?: InputMaybe<SortEnumType>;
  categorieSocioProfessionnelleCode?: InputMaybe<SortEnumType>;
  createurId?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editeurId?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  idSource?: InputMaybe<SortEnumType>;
  nomSociete?: InputMaybe<SortEnumType>;
  origineRevenus?: InputMaybe<SortEnumType>;
  personnePhysique?: InputMaybe<PersonnePhysiqueSortInput>;
  personnePhysiqueId?: InputMaybe<SortEnumType>;
  profession?: InputMaybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Statut = {
  __typename?: 'Statut';
  backOfficeLibelle?: Maybe<Scalars['String']['output']>;
  consultantLibelle?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  operations: Array<Operation>;
};


export type StatutOperationsArgs = {
  where?: InputMaybe<OperationFilterInput>;
};

export type StatutCommercial = {
  __typename?: 'StatutCommercial';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantStatutCommercial: Array<ConsultantStatutCommercial>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type StatutCommercialConsultantStatutCommercialArgs = {
  where?: InputMaybe<ConsultantStatutCommercialFilterInput>;
};

export type StatutCommercialFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<StatutCommercialFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantStatutCommercial?: InputMaybe<ListFilterInputTypeOfConsultantStatutCommercialFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StatutCommercialFilterInput>>;
};

export type StatutConsultant = {
  __typename?: 'StatutConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultant: Array<Consultant>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type StatutConsultantConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};

export type StatutConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<StatutConsultantFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StatutConsultantFilterInput>>;
};

export type StatutConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type StatutDistinction = {
  __typename?: 'StatutDistinction';
  code: Scalars['Byte']['output'];
  consultantDistinction: Array<ConsultantDistinction>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type StatutDistinctionConsultantDistinctionArgs = {
  where?: InputMaybe<ConsultantDistinctionFilterInput>;
};

export type StatutDistinctionFilterInput = {
  and?: InputMaybe<Array<StatutDistinctionFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  consultantDistinction?: InputMaybe<ListFilterInputTypeOfConsultantDistinctionFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StatutDistinctionFilterInput>>;
};

export type StatutFilterInput = {
  and?: InputMaybe<Array<StatutFilterInput>>;
  backOfficeLibelle?: InputMaybe<StringOperationFilterInput>;
  consultantLibelle?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  operations?: InputMaybe<ListFilterInputTypeOfOperationFilterInput>;
  or?: InputMaybe<Array<StatutFilterInput>>;
};

export type StatutJuridique = {
  __typename?: 'StatutJuridique';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type StatutJuridiqueConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};

export type StatutJuridiqueFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<StatutJuridiqueFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<StatutJuridiqueFilterInput>>;
};

export type StatutSortInput = {
  backOfficeLibelle?: InputMaybe<SortEnumType>;
  consultantLibelle?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TauxRemunerationConsultant = {
  __typename?: 'TauxRemunerationConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  consultantOrganisation: Array<ConsultantOrganisation>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  libelle: Scalars['String']['output'];
};


export type TauxRemunerationConsultantConsultantOrganisationArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};

export type TauxRemunerationConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TauxRemunerationConsultantFilterInput>>;
  consultantOrganisation?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TauxRemunerationConsultantFilterInput>>;
};

export type TauxRemunerationConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type Telephone = {
  __typename?: 'Telephone';
  arianeId?: Maybe<Scalars['Long']['output']>;
  consultant: Array<Consultant>;
  contact: Array<Contact>;
  createurId?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  editeurId?: Maybe<Scalars['Long']['output']>;
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  estNumeroCorrespondance?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Long']['output'];
  idSource?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Note>;
  noteId?: Maybe<Scalars['Long']['output']>;
  numero: Scalars['String']['output'];
  personneMoraleTelephone: Array<PersonneMoraleTelephone>;
  personnePhysique: Array<PersonnePhysique>;
  prefixe?: Maybe<Scalars['String']['output']>;
  typeTelephoneCode: Scalars['String']['output'];
  typeTelephoneCodeNavigation: TypeTelephone;
};


export type TelephoneConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type TelephoneContactArgs = {
  where?: InputMaybe<ContactFilterInput>;
};


export type TelephonePersonneMoraleTelephoneArgs = {
  where?: InputMaybe<PersonneMoraleTelephoneFilterInput>;
};


export type TelephonePersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type TelephoneFilterInput = {
  and?: InputMaybe<Array<TelephoneFilterInput>>;
  arianeId?: InputMaybe<LongOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  contact?: InputMaybe<ListFilterInputTypeOfContactFilterInput>;
  createurId?: InputMaybe<LongOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editeurId?: InputMaybe<LongOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  estNumeroCorrespondance?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<LongOperationFilterInput>;
  idSource?: InputMaybe<StringOperationFilterInput>;
  note?: InputMaybe<NoteFilterInput>;
  noteId?: InputMaybe<LongOperationFilterInput>;
  numero?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TelephoneFilterInput>>;
  personneMoraleTelephone?: InputMaybe<ListFilterInputTypeOfPersonneMoraleTelephoneFilterInput>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
  prefixe?: InputMaybe<StringOperationFilterInput>;
  typeTelephoneCode?: InputMaybe<StringOperationFilterInput>;
  typeTelephoneCodeNavigation?: InputMaybe<TypeTelephoneFilterInput>;
};

export type TitreConsultant = {
  __typename?: 'TitreConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultant: Array<Consultant>;
  consultantOrganisation: Array<ConsultantOrganisation>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type TitreConsultantConsultantArgs = {
  where?: InputMaybe<ConsultantFilterInput>;
};


export type TitreConsultantConsultantOrganisationArgs = {
  where?: InputMaybe<ConsultantOrganisationFilterInput>;
};

export type TitreConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TitreConsultantFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultant?: InputMaybe<ListFilterInputTypeOfConsultantFilterInput>;
  consultantOrganisation?: InputMaybe<ListFilterInputTypeOfConsultantOrganisationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TitreConsultantFilterInput>>;
};

export type TitreConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export enum TransactionPersonnelleEnum {
  AutoSouscription = 'AUTO_SOUSCRIPTION',
  Non = 'NON',
  PourProches = 'POUR_PROCHES',
  PourSalaries = 'POUR_SALARIES'
}

export type Type2Produit = {
  __typename?: 'Type2Produit';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  enveloppeProduit: Array<EnveloppeProduit>;
  gamme: Scalars['Byte']['output'];
  gammeNavigation: GammeProduit;
  libelle: Scalars['String']['output'];
};


export type Type2ProduitEnveloppeProduitArgs = {
  where?: InputMaybe<EnveloppeProduitFilterInput>;
};

export type Type2ProduitFilterInput = {
  and?: InputMaybe<Array<Type2ProduitFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  enveloppeProduit?: InputMaybe<ListFilterInputTypeOfEnveloppeProduitFilterInput>;
  gamme?: InputMaybe<ByteOperationFilterInput>;
  gammeNavigation?: InputMaybe<GammeProduitFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<Type2ProduitFilterInput>>;
};

export type Type2ProduitSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  gamme?: InputMaybe<SortEnumType>;
  gammeNavigation?: InputMaybe<GammeProduitSortInput>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeAdoption = {
  __typename?: 'TypeAdoption';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  relationFamille: Array<RelationFamille>;
};


export type TypeAdoptionRelationFamilleArgs = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};

export type TypeAdoptionFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeAdoptionFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeAdoptionFilterInput>>;
  relationFamille?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
};

export type TypeAdresse = {
  __typename?: 'TypeAdresse';
  actif?: Maybe<Scalars['Boolean']['output']>;
  adresse: Array<Adresse>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type TypeAdresseAdresseArgs = {
  where?: InputMaybe<AdresseFilterInput>;
};

export type TypeAdresseFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  adresse?: InputMaybe<ListFilterInputTypeOfAdresseFilterInput>;
  and?: InputMaybe<Array<TypeAdresseFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeAdresseFilterInput>>;
};

export type TypeAvantageMatrimonial = {
  __typename?: 'TypeAvantageMatrimonial';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  etatCivil: Array<EtatCivil>;
  libelle: Scalars['String']['output'];
};


export type TypeAvantageMatrimonialEtatCivilArgs = {
  where?: InputMaybe<EtatCivilFilterInput>;
};

export type TypeAvantageMatrimonialFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeAvantageMatrimonialFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  etatCivil?: InputMaybe<ListFilterInputTypeOfEtatCivilFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeAvantageMatrimonialFilterInput>>;
};

export type TypeAvantageMatrimonialSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeCategorieObjectifInvestisseur = {
  __typename?: 'TypeCategorieObjectifInvestisseur';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  typeObjectifInvestisseur: Array<TypeObjectifInvestisseur>;
};


export type TypeCategorieObjectifInvestisseurTypeObjectifInvestisseurArgs = {
  where?: InputMaybe<TypeObjectifInvestisseurFilterInput>;
};

export type TypeCategorieObjectifInvestisseurFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeCategorieObjectifInvestisseurFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeCategorieObjectifInvestisseurFilterInput>>;
  typeObjectifInvestisseur?: InputMaybe<ListFilterInputTypeOfTypeObjectifInvestisseurFilterInput>;
};

export type TypeCategorieObjectifInvestisseurSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeCommande = {
  __typename?: 'TypeCommande';
  code: Scalars['Byte']['output'];
  commande: Array<Commande>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type TypeCommandeCommandeArgs = {
  where?: InputMaybe<CommandeFilterInput>;
};

export type TypeCommandeFilterInput = {
  and?: InputMaybe<Array<TypeCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  commande?: InputMaybe<ListFilterInputTypeOfCommandeFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeCommandeFilterInput>>;
};

export type TypeCommandeSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeConseillerCommande = {
  __typename?: 'TypeConseillerCommande';
  code: Scalars['Byte']['output'];
  conseillerCommande: Array<ConseillerCommande>;
  consultantMouvement: Array<ConsultantMouvement>;
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type TypeConseillerCommandeConseillerCommandeArgs = {
  where?: InputMaybe<ConseillerCommandeFilterInput>;
};


export type TypeConseillerCommandeConsultantMouvementArgs = {
  where?: InputMaybe<ConsultantMouvementFilterInput>;
};

export type TypeConseillerCommandeFilterInput = {
  and?: InputMaybe<Array<TypeConseillerCommandeFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  conseillerCommande?: InputMaybe<ListFilterInputTypeOfConseillerCommandeFilterInput>;
  consultantMouvement?: InputMaybe<ListFilterInputTypeOfConsultantMouvementFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeConseillerCommandeFilterInput>>;
};

export type TypeContrat = {
  __typename?: 'TypeContrat';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantContrat: Array<ConsultantContrat>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
};


export type TypeContratConsultantContratArgs = {
  where?: InputMaybe<ConsultantContratFilterInput>;
};

export type TypeContratFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeContratFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantContrat?: InputMaybe<ListFilterInputTypeOfConsultantContratFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeContratFilterInput>>;
};

export type TypeCredit = {
  __typename?: 'TypeCredit';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  patrimoinePassif: Array<PatrimoinePassif>;
};


export type TypeCreditPatrimoinePassifArgs = {
  where?: InputMaybe<PatrimoinePassifFilterInput>;
};

export type TypeCreditFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeCreditFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeCreditFilterInput>>;
  patrimoinePassif?: InputMaybe<ListFilterInputTypeOfPatrimoinePassifFilterInput>;
};

export type TypeEdition = {
  __typename?: 'TypeEdition';
  actif: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  commune: Scalars['Boolean']['output'];
  dateMaj: Scalars['DateTime']['output'];
  domaine: Scalars['String']['output'];
  domaineNavigation: TypeEditionDomaine;
  edition: Array<Edition>;
  frequenceMaj?: Maybe<Scalars['Int']['output']>;
  libelle: Scalars['String']['output'];
  libelleFichier: Scalars['String']['output'];
  ordre?: Maybe<Scalars['Int']['output']>;
};


export type TypeEditionEditionArgs = {
  where?: InputMaybe<EditionFilterInput>;
};

export type TypeEditionDomaine = {
  __typename?: 'TypeEditionDomaine';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  typeEdition: Array<TypeEdition>;
};


export type TypeEditionDomaineTypeEditionArgs = {
  where?: InputMaybe<TypeEditionFilterInput>;
};

export type TypeEditionDomaineFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeEditionDomaineFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeEditionDomaineFilterInput>>;
  typeEdition?: InputMaybe<ListFilterInputTypeOfTypeEditionFilterInput>;
};

export type TypeEditionFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeEditionFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  commune?: InputMaybe<BooleanOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  domaine?: InputMaybe<StringOperationFilterInput>;
  domaineNavigation?: InputMaybe<TypeEditionDomaineFilterInput>;
  edition?: InputMaybe<ListFilterInputTypeOfEditionFilterInput>;
  frequenceMaj?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  libelleFichier?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeEditionFilterInput>>;
  ordre?: InputMaybe<IntOperationFilterInput>;
};

export type TypeEmail = {
  __typename?: 'TypeEmail';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  email: Array<Email>;
  libelle: Scalars['String']['output'];
};


export type TypeEmailEmailArgs = {
  where?: InputMaybe<EmailFilterInput>;
};

export type TypeEmailFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeEmailFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  email?: InputMaybe<ListFilterInputTypeOfEmailFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeEmailFilterInput>>;
};

export type TypeFormeJuridique = {
  __typename?: 'TypeFormeJuridique';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  formeJuridique: Array<FormeJuridique>;
  libelle: Scalars['String']['output'];
};


export type TypeFormeJuridiqueFormeJuridiqueArgs = {
  where?: InputMaybe<FormeJuridiqueFilterInput>;
};

export type TypeFormeJuridiqueFilterInput = {
  and?: InputMaybe<Array<TypeFormeJuridiqueFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  formeJuridique?: InputMaybe<ListFilterInputTypeOfFormeJuridiqueFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeFormeJuridiqueFilterInput>>;
};

export type TypeJustificationFormation = {
  __typename?: 'TypeJustificationFormation';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  equivalenceDeFormation: Array<EquivalenceDeFormation>;
  libelle: Scalars['String']['output'];
};


export type TypeJustificationFormationEquivalenceDeFormationArgs = {
  where?: InputMaybe<EquivalenceDeFormationFilterInput>;
};

export type TypeJustificationFormationFilterInput = {
  and?: InputMaybe<Array<TypeJustificationFormationFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  equivalenceDeFormation?: InputMaybe<ListFilterInputTypeOfEquivalenceDeFormationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeJustificationFormationFilterInput>>;
};

export type TypeMesureProtection = {
  __typename?: 'TypeMesureProtection';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  personnePhysique: Array<PersonnePhysique>;
};


export type TypeMesureProtectionPersonnePhysiqueArgs = {
  where?: InputMaybe<PersonnePhysiqueFilterInput>;
};

export type TypeMesureProtectionFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeMesureProtectionFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeMesureProtectionFilterInput>>;
  personnePhysique?: InputMaybe<ListFilterInputTypeOfPersonnePhysiqueFilterInput>;
};

export type TypeMesureProtectionSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeMotifAnnulationMouvement = {
  __typename?: 'TypeMotifAnnulationMouvement';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  motifAnnulationCommande: Array<MotifAnnulationCommande>;
};


export type TypeMotifAnnulationMouvementMotifAnnulationCommandeArgs = {
  where?: InputMaybe<MotifAnnulationCommandeFilterInput>;
};

export type TypeMotifAnnulationMouvementFilterInput = {
  and?: InputMaybe<Array<TypeMotifAnnulationMouvementFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  motifAnnulationCommande?: InputMaybe<ListFilterInputTypeOfMotifAnnulationCommandeFilterInput>;
  or?: InputMaybe<Array<TypeMotifAnnulationMouvementFilterInput>>;
};

export type TypeMotifAnnulationMouvementSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeMotifCession = {
  __typename?: 'TypeMotifCession';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  motifCession: Array<MotifCession>;
};


export type TypeMotifCessionMotifCessionArgs = {
  where?: InputMaybe<MotifCessionFilterInput>;
};

export type TypeMotifCessionFilterInput = {
  and?: InputMaybe<Array<TypeMotifCessionFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  motifCession?: InputMaybe<ListFilterInputTypeOfMotifCessionFilterInput>;
  or?: InputMaybe<Array<TypeMotifCessionFilterInput>>;
};

export type TypeMotifCessionSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeObjectifInvestisseur = {
  __typename?: 'TypeObjectifInvestisseur';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  typeCategorieObjectifInvestisseurCode: Scalars['String']['output'];
  typeCategorieObjectifInvestisseurCodeNavigation: TypeCategorieObjectifInvestisseur;
};

export type TypeObjectifInvestisseurFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeObjectifInvestisseurFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseurObjectif?: InputMaybe<ListFilterInputTypeOfInvestisseurObjectifFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeObjectifInvestisseurFilterInput>>;
  typeCategorieObjectifInvestisseurCode?: InputMaybe<StringOperationFilterInput>;
  typeCategorieObjectifInvestisseurCodeNavigation?: InputMaybe<TypeCategorieObjectifInvestisseurFilterInput>;
};

export type TypeObjectifInvestisseurSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  typeCategorieObjectifInvestisseurCode?: InputMaybe<SortEnumType>;
  typeCategorieObjectifInvestisseurCodeNavigation?: InputMaybe<TypeCategorieObjectifInvestisseurSortInput>;
};

export type TypePieceJointe = {
  __typename?: 'TypePieceJointe';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  expirationCode: Scalars['String']['output'];
  expirationCodeNavigation: Expiration;
  libelle: Scalars['String']['output'];
  pieceJointeConsultant: Array<PieceJointeConsultant>;
  type: Scalars['Int']['output'];
};


export type TypePieceJointePieceJointeConsultantArgs = {
  where?: InputMaybe<PieceJointeConsultantFilterInput>;
};

export type TypePieceJointeFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypePieceJointeFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  expirationCode?: InputMaybe<StringOperationFilterInput>;
  expirationCodeNavigation?: InputMaybe<ExpirationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypePieceJointeFilterInput>>;
  pieceJointeConsultant?: InputMaybe<ListFilterInputTypeOfPieceJointeConsultantFilterInput>;
  type?: InputMaybe<IntOperationFilterInput>;
};

export type TypePpeMotif = {
  __typename?: 'TypePpeMotif';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  reglementaire: Array<Reglementaire>;
};


export type TypePpeMotifReglementaireArgs = {
  where?: InputMaybe<ReglementaireFilterInput>;
};

export type TypePpeMotifFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypePpeMotifFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypePpeMotifFilterInput>>;
  reglementaire?: InputMaybe<ListFilterInputTypeOfReglementaireFilterInput>;
};

export type TypePpeMotifSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypePpeRelation = {
  __typename?: 'TypePpeRelation';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  reglementaire: Array<Reglementaire>;
};


export type TypePpeRelationReglementaireArgs = {
  where?: InputMaybe<ReglementaireFilterInput>;
};

export type TypePpeRelationFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypePpeRelationFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypePpeRelationFilterInput>>;
  reglementaire?: InputMaybe<ListFilterInputTypeOfReglementaireFilterInput>;
};

export type TypePpeRelationSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeProduit = {
  __typename?: 'TypeProduit';
  code: Scalars['Byte']['output'];
  creationDate: Scalars['DateTime']['output'];
  editionDate: Scalars['DateTime']['output'];
  libelle: Scalars['String']['output'];
  produit: Array<Produit>;
};


export type TypeProduitProduitArgs = {
  where?: InputMaybe<ProduitFilterInput>;
};

export type TypeProduitFilterInput = {
  and?: InputMaybe<Array<TypeProduitFilterInput>>;
  code?: InputMaybe<ByteOperationFilterInput>;
  creationDate?: InputMaybe<DateTimeOperationFilterInput>;
  editionDate?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeProduitFilterInput>>;
  produit?: InputMaybe<ListFilterInputTypeOfProduitFilterInput>;
};

export type TypeProduitSortInput = {
  code?: InputMaybe<SortEnumType>;
  creationDate?: InputMaybe<SortEnumType>;
  editionDate?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeRcp = {
  __typename?: 'TypeRcp';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantRcp: Array<ConsultantRcp>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  habilitationRcp: Array<HabilitationRcp>;
  libelle?: Maybe<Scalars['String']['output']>;
};


export type TypeRcpConsultantRcpArgs = {
  where?: InputMaybe<ConsultantRcpFilterInput>;
};


export type TypeRcpHabilitationRcpArgs = {
  where?: InputMaybe<HabilitationRcpFilterInput>;
};

export type TypeRcpFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRcpFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantRcp?: InputMaybe<ListFilterInputTypeOfConsultantRcpFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  habilitationRcp?: InputMaybe<ListFilterInputTypeOfHabilitationRcpFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeRcpFilterInput>>;
};

export type TypeRegimeSituationEtatCivil = {
  __typename?: 'TypeRegimeSituationEtatCivil';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  etatCivil: Array<EtatCivil>;
  libelle: Scalars['String']['output'];
  type: Scalars['String']['output'];
};


export type TypeRegimeSituationEtatCivilEtatCivilArgs = {
  where?: InputMaybe<EtatCivilFilterInput>;
};

export type TypeRegimeSituationEtatCivilFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRegimeSituationEtatCivilFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  etatCivil?: InputMaybe<ListFilterInputTypeOfEtatCivilFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeRegimeSituationEtatCivilFilterInput>>;
  type?: InputMaybe<StringOperationFilterInput>;
};

export type TypeRegimeSituationEtatCivilSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
  type?: InputMaybe<SortEnumType>;
};

export type TypeRelationAutre = {
  __typename?: 'TypeRelationAutre';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  codeRev: Scalars['String']['output'];
  codeRevNavigation: TypeRelationAutre;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  inverseCodeRevNavigation: Array<TypeRelationAutre>;
  libelle: Scalars['String']['output'];
  natureRelationAutreCode: Scalars['String']['output'];
  natureRelationAutreCodeNavigation: NatureRelationAutre;
  relationAutreTypeRelationAutreCodeNavigation: Array<RelationAutre>;
  relationAutreTypeRelationAutreCodeRevNavigation: Array<RelationAutre>;
};


export type TypeRelationAutreInverseCodeRevNavigationArgs = {
  where?: InputMaybe<TypeRelationAutreFilterInput>;
};


export type TypeRelationAutreRelationAutreTypeRelationAutreCodeNavigationArgs = {
  where?: InputMaybe<RelationAutreFilterInput>;
};


export type TypeRelationAutreRelationAutreTypeRelationAutreCodeRevNavigationArgs = {
  where?: InputMaybe<RelationAutreFilterInput>;
};

export type TypeRelationAutreFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRelationAutreFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  codeRev?: InputMaybe<StringOperationFilterInput>;
  codeRevNavigation?: InputMaybe<TypeRelationAutreFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  inverseCodeRevNavigation?: InputMaybe<ListFilterInputTypeOfTypeRelationAutreFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  natureRelationAutreCode?: InputMaybe<StringOperationFilterInput>;
  natureRelationAutreCodeNavigation?: InputMaybe<NatureRelationAutreFilterInput>;
  or?: InputMaybe<Array<TypeRelationAutreFilterInput>>;
  relationAutreTypeRelationAutreCodeNavigation?: InputMaybe<ListFilterInputTypeOfRelationAutreFilterInput>;
  relationAutreTypeRelationAutreCodeRevNavigation?: InputMaybe<ListFilterInputTypeOfRelationAutreFilterInput>;
};

export type TypeRelationCouple = {
  __typename?: 'TypeRelationCouple';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  relationCouple: Array<RelationCouple>;
};


export type TypeRelationCoupleRelationCoupleArgs = {
  where?: InputMaybe<RelationCoupleFilterInput>;
};

export type TypeRelationCoupleFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRelationCoupleFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeRelationCoupleFilterInput>>;
  relationCouple?: InputMaybe<ListFilterInputTypeOfRelationCoupleFilterInput>;
};

export type TypeRelationFamille = {
  __typename?: 'TypeRelationFamille';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  codeRev: Scalars['String']['output'];
  codeRevNavigation: TypeRelationFamille;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  inverseCodeRevNavigation: Array<TypeRelationFamille>;
  libelle: Scalars['String']['output'];
  relationEnfant: Scalars['Boolean']['output'];
  relationFamilleTypeRelationFamilleCodeNavigation: Array<RelationFamille>;
  relationFamilleTypeRelationFamilleCodeRevNavigation: Array<RelationFamille>;
};


export type TypeRelationFamilleInverseCodeRevNavigationArgs = {
  where?: InputMaybe<TypeRelationFamilleFilterInput>;
};


export type TypeRelationFamilleRelationFamilleTypeRelationFamilleCodeNavigationArgs = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};


export type TypeRelationFamilleRelationFamilleTypeRelationFamilleCodeRevNavigationArgs = {
  where?: InputMaybe<RelationFamilleFilterInput>;
};

export type TypeRelationFamilleFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRelationFamilleFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  codeRev?: InputMaybe<StringOperationFilterInput>;
  codeRevNavigation?: InputMaybe<TypeRelationFamilleFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  inverseCodeRevNavigation?: InputMaybe<ListFilterInputTypeOfTypeRelationFamilleFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeRelationFamilleFilterInput>>;
  relationEnfant?: InputMaybe<BooleanOperationFilterInput>;
  relationFamilleTypeRelationFamilleCodeNavigation?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
  relationFamilleTypeRelationFamilleCodeRevNavigation?: InputMaybe<ListFilterInputTypeOfRelationFamilleFilterInput>;
};

export type TypeRelationInvestisseurConsultant = {
  __typename?: 'TypeRelationInvestisseurConsultant';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  investisseurConsultant: Array<InvestisseurConsultant>;
  libelle: Scalars['String']['output'];
};


export type TypeRelationInvestisseurConsultantInvestisseurConsultantArgs = {
  where?: InputMaybe<InvestisseurConsultantFilterInput>;
};

export type TypeRelationInvestisseurConsultantFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeRelationInvestisseurConsultantFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseurConsultant?: InputMaybe<ListFilterInputTypeOfInvestisseurConsultantFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeRelationInvestisseurConsultantFilterInput>>;
};

export type TypeRelationInvestisseurConsultantSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export enum TypeSignaturePartenaire {
  ElectroniqueArianeUniversign = 'ELECTRONIQUE_ARIANE_UNIVERSIGN',
  ElectroniqueExtranetPartenaire = 'ELECTRONIQUE_EXTRANET_PARTENAIRE',
  Manuscrite = 'MANUSCRITE'
}

export type TypeSituationEtatCivil = {
  __typename?: 'TypeSituationEtatCivil';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  etatCivil: Array<EtatCivil>;
  libelle: Scalars['String']['output'];
};


export type TypeSituationEtatCivilEtatCivilArgs = {
  where?: InputMaybe<EtatCivilFilterInput>;
};

export type TypeSituationEtatCivilFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeSituationEtatCivilFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  etatCivil?: InputMaybe<ListFilterInputTypeOfEtatCivilFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeSituationEtatCivilFilterInput>>;
};

export type TypeSituationEtatCivilSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeSociete = {
  __typename?: 'TypeSociete';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  consultantStatutJuridique: Array<ConsultantStatutJuridique>;
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle?: Maybe<Scalars['String']['output']>;
};


export type TypeSocieteConsultantStatutJuridiqueArgs = {
  where?: InputMaybe<ConsultantStatutJuridiqueFilterInput>;
};

export type TypeSocieteFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeSocieteFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  consultantStatutJuridique?: InputMaybe<ListFilterInputTypeOfConsultantStatutJuridiqueFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeSocieteFilterInput>>;
};

export type TypeStatutInvestisseur = {
  __typename?: 'TypeStatutInvestisseur';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  investisseur: Array<Investisseur>;
  libelle: Scalars['String']['output'];
};


export type TypeStatutInvestisseurInvestisseurArgs = {
  where?: InputMaybe<InvestisseurFilterInput>;
};

export type TypeStatutInvestisseurFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeStatutInvestisseurFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  investisseur?: InputMaybe<ListFilterInputTypeOfInvestisseurFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeStatutInvestisseurFilterInput>>;
};

export type TypeStatutInvestisseurSortInput = {
  actif?: InputMaybe<SortEnumType>;
  code?: InputMaybe<SortEnumType>;
  dateMaj?: InputMaybe<SortEnumType>;
  libelle?: InputMaybe<SortEnumType>;
};

export type TypeTelephone = {
  __typename?: 'TypeTelephone';
  actif?: Maybe<Scalars['Boolean']['output']>;
  code: Scalars['String']['output'];
  dateMaj?: Maybe<Scalars['DateTime']['output']>;
  libelle: Scalars['String']['output'];
  telephone: Array<Telephone>;
};


export type TypeTelephoneTelephoneArgs = {
  where?: InputMaybe<TelephoneFilterInput>;
};

export type TypeTelephoneFilterInput = {
  actif?: InputMaybe<BooleanOperationFilterInput>;
  and?: InputMaybe<Array<TypeTelephoneFilterInput>>;
  code?: InputMaybe<StringOperationFilterInput>;
  dateMaj?: InputMaybe<DateTimeOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<TypeTelephoneFilterInput>>;
  telephone?: InputMaybe<ListFilterInputTypeOfTelephoneFilterInput>;
};

export type UpdateOperationInput = {
  coInvestisseurId?: InputMaybe<Scalars['Long']['input']>;
  contratId?: InputMaybe<Scalars['Long']['input']>;
  donneesSpecifiques?: InputMaybe<Scalars['String']['input']>;
  investisseurId?: InputMaybe<Scalars['Long']['input']>;
  operationConfigId?: InputMaybe<Scalars['Int']['input']>;
  operationGroupId: Scalars['Int']['input'];
  operationId: Scalars['Int']['input'];
  produitId?: InputMaybe<Scalars['Long']['input']>;
  transactionPersonnelleStatut?: InputMaybe<TransactionPersonnelleEnum>;
  typeSignaturePartenaire?: InputMaybe<TypeSignaturePartenaire>;
};

export type UserAccessRight = {
  __typename?: 'UserAccessRight';
  authorizedConsultantIds: Array<Scalars['Long']['output']>;
  authorizedInvestisseurIds: Array<Scalars['Long']['output']>;
  consultantHabilitationIds: Array<Scalars['Long']['output']>;
  consultantHabilitations: Array<Maybe<Scalars['String']['output']>>;
  consultantId?: Maybe<Scalars['Long']['output']>;
  hasFullAccess: Scalars['Boolean']['output'];
};

export type UserInformations = {
  __typename?: 'UserInformations';
  backOfficeMember?: Maybe<BackOfficeMember>;
  notifications: Array<Notification>;
};

export type UuidOperationFilterInput = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  ngt?: InputMaybe<Scalars['UUID']['input']>;
  ngte?: InputMaybe<Scalars['UUID']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  nlt?: InputMaybe<Scalars['UUID']['input']>;
  nlte?: InputMaybe<Scalars['UUID']['input']>;
};

export type RemoteAtlasCoreApi_NatureOperation = {
  __typename?: 'remoteAtlasCoreApi_NatureOperation';
  id: Scalars['Int']['output'];
  libelle: Scalars['String']['output'];
};

export type RemoteAtlasCoreApi_NatureOperationFilterInput = {
  and?: InputMaybe<Array<RemoteAtlasCoreApi_NatureOperationFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  libelle?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RemoteAtlasCoreApi_NatureOperationFilterInput>>;
};

import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  EnvoiPartenaire,
  Operation,
  OperationFilterInput,
  OperationSortInput,
  OperationsPaginatedCollectionSegment,
  SortEnumType,
} from '@lib//models/generated/graphql';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import { gql } from 'apollo-angular';

import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  EnveloppeDashboardColumn,
  InvestisseurDashboardColumn,
  MontantDashboardColumn,
  NatureDashboardColumn,
  OperationDashboardColumn,
  OperationIDDashboardColumn,
  ProduitDashboardColumn,
  StatutBackOfficeDashboardColumn,
} from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { OperationsTableComponent } from '@lib/components/operations-dashboard/operations-table/operations-table.component';
import { operationTableBordereauInfoFragment } from '@lib/models/graphqlFragments';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { firstValueFrom } from 'rxjs';

const ALL_BORDEREAUX_OPERATIONS = gql`
  query allOperations($filter: OperationFilterInput, $order: [OperationSortInput!]) {
    allOperationsPaginated(skip: 0, take: 100, where: $filter, order: $order) {
      items {
        ...operationTableInfo
      }
    }
  }
  ${operationTableBordereauInfoFragment}
`;

export enum OperationDashboardFilterEnum {
  Statut,
  Enveloppe,
  Nature,
  Produit,
  Montant,
  Gestionnaire,
  FondEvnt,
  Investisseur,
}

type FilterChipsValue = {
  type: OperationDashboardFilterEnum;
  filter: OperationFilterInput;
};

const CREATE_BORDEREAU = gql`
  mutation createBordereau($operationsIds: [Int!]!, $partenaireId: Long!) {
    createBordereau(operationsIds: $operationsIds, partenaireId: $partenaireId) {
      id
      partenaireId
      statutEnvoi
      sendingDate
      receiveDate
      updateDate
      bordereauFileId
      bordereauFile {
        id
        fileId
        fileName
        createdDate
        lastModificationDate
      }
    }
  }
`;

interface PartenaireData {
  id: number;
  nom: string;
}

@Component({
  selector: 'app-bordereaux-operation-dashboard',
  templateUrl: './bordereaux-operations-dashboard.component.html',
  styleUrls: ['./bordereaux-operations-dashboard.component.scss'],
})
export class BordereauxOperationsDashboardComponent {
  columnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new NatureDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new StatutBackOfficeDashboardColumn(),
  ];

  // @Input()
  // filterSelection: OperationDashboardFilterEnum[] = [];
  // @Input()
  // defaultQueryFilter: OperationFilterInput = {};
  // @Input() withSelects: boolean = false;
  // @Input() isDispatcher: boolean = false;

  // @Input() allProduits: Produit[];
  // @Input() allNatureOperations: NatureOperation[];
  // @Input() allGestionnaires: BackOfficeMember[];
  // @Input() allEnveloppes: EnveloppeProduit[] = [];

  loading: boolean = false;

  // used only to acces to enumns values and handle the filters
  OperationDashboardFilterEnum = OperationDashboardFilterEnum;

  // operationsPerPatenaire: { [key: string]: Operation[] } = {};
  operationsPerPatenaireList: { key: number; value: { operations: Operation[]; loading: boolean } }[] = [];
  selectedOperationsPerPartenaireList: { key: number; value: { operations: Operation[]; loading: boolean } }[] = [];

  partenairesList: PartenaireData[] = [];
  partenaireOptions: OuiSelectOption<number>[] = [];
  partenaireControl = new FormControl<number | null>(null);

  selectedPartenaireId: number | null | undefined;

  dataSource = new MatTableDataSource<Operation>([]);
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { data: OperationsPaginatedCollectionSegment; loading: boolean } | undefined;

  @ViewChild(OperationsTableComponent) private operationTable: OperationsTableComponent;

  constructor(private queryManager: QueryManagerService, private dialogModal: MatDialog, private router: Router) {}

  ngOnInit(): void {
    this.getAllOperations();
    const sub = this.partenaireControl.valueChanges.subscribe(value => {
      if (value) {
        this.selectedOperationsPerPartenaireList = this.operationsPerPatenaireList.filter(
          partenaire => partenaire.key === value
        );
        return;
      }
      this.selectedOperationsPerPartenaireList = this.operationsPerPatenaireList;
    });
  }

  async onCreateBordereau(
    operations: Operation[],
    partenaireId: number,
    data: { operations: Operation[]; loading: boolean }
  ) {
    data.loading = true;
    let operationIds = operations.map(operation => operation.id);
    const result = await firstValueFrom(
      this.queryManager.mutate<{ createBordereau: EnvoiPartenaire }>({
        mutation: CREATE_BORDEREAU,
        variables: {
          operationsIds: operationIds,
          partenaireId: partenaireId,
        },
      })
    );
    if (result.data?.createBordereau && result.data?.createBordereau.id) {
      this.router.navigate(['bordereau-details', result.data?.createBordereau.id]);
    }
    data.loading = false;
  }

  onRowClick(operation: Operation) {}

  resetPartenaireSelection() {
    this.partenaireControl.setValue(null);
  }
  getAllOperations(): void {
    // get only operation with statut = 250 (stand for attente bordereau)
    let requestFilter = { and: [{ statutId: { eq: 250 } }, { envoiPartenaireId: { eq: null } }] };
    const lastModifiedFirst: OperationSortInput = { dateModification: SortEnumType.Desc };
    const requestVariables = { ...this.paginationParams, filter: requestFilter, order: [lastModifiedFirst] };

    this.loading = true;
    this.queryManager
      .query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
        query: ALL_BORDEREAUX_OPERATIONS,
        variables: requestVariables,
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allOperationsPaginated };
        let newPartenaireList: PartenaireData[] = [];
        const items = data.allOperationsPaginated.items ?? [];

        items?.forEach((operation: Operation) => {
          let partenaireId = operation.produitPreset?.partenaireId;
          if (partenaireId) {
            let entry = this.operationsPerPatenaireList.find(value => value.key === partenaireId);
            if (!entry) {
              this.operationsPerPatenaireList.push({
                key: partenaireId,
                value: { operations: [operation], loading: false },
              });
              const nouveauPartenaire: PartenaireData = {
                id: partenaireId,
                nom: operation.produitPreset?.partenaireLibelle ?? '',
              };
              newPartenaireList.push(nouveauPartenaire);
            } else {
              entry.value.operations.push(operation);
            }
          }
        });
        this.partenairesList = newPartenaireList.sort((a, b) => a.nom.localeCompare(b.nom));
        this.partenaireOptions = this.partenairesList
          .map(p => {
            return { value: p.id, label: p.nom };
          })
          .sort((a, b) => a.label.localeCompare(b.label));
        this.partenaireControl.setValue(null);
        this.loading = false;
      });
  }

  getPartenaireTitleById(partenaireId: number, count: number): string {
    const partenaireName = this.getPartenaireNameById(partenaireId);
    const operationcount = count > 1 ? ` (${count} opérations)` : ` (${count} opération)`;
    return partenaireName + operationcount;
  }

  getPartenaireNameById(partenaireId: number): string {
    if (this.partenairesList) {
      const partenaire = this.partenairesList.find(partenaire => partenaire.id === partenaireId);
      if (partenaire) {
        return partenaire.nom ?? 'Autre';
      }
    }
    return 'Autre';
  }
}

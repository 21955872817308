import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiTextarea } from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiSnackbarModule, OuiSnackbarService } from 'omnium-ui/snackbar';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { Operation } from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';

const CREATE_FICHE = gql`
  mutation createFicheLiaison($operationId: Int!, $commentaire: String) {
    createFicheLiaison(operationId: $operationId, commentaire: $commentaire)
  }
`;

@Component({
  selector: 'lib-fiche-liaison-dialog',
  standalone: true,
  imports: [CommonModule, OuiDialogModule, OuiButtonModule, OuiIconModule, OuiTextarea, OuiSnackbarModule],
  templateUrl: './fiche-liaison-dialog.component.html',
  styleUrls: ['./fiche-liaison-dialog.component.scss'],
})
export class FicheLiaisonDialogComponent {
  commentaire?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operation: Operation },
    private dialogRef: MatDialogRef<FicheLiaisonDialogComponent>,
    private queryManager: QueryManagerService,
    private documentService: DocumentsService,
    private notificationService: OuiSnackbarService
  ) {}

  async createFicheLiaison() {
    try {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ createFicheLiaison: string }>({
          mutation: CREATE_FICHE,
          variables: {
            operationId: this.data.operation.id,
            commentaire: this.commentaire,
          },
        })
      );

      const base64File = result.data!.createFicheLiaison;

      const base64Response = await fetch(`data:application/pdf;base64,${base64File}`);
      const blob = await base64Response.blob();
      const fileName = `fiche-liaison-${this.data.operation.investisseur?.investisseurEntite?.displayName}.pdf`;

      this.documentService.downloadBlob(blob, fileName);

      this.dialogRef.close();
    } catch (error) {
      this.notificationService.open("Une erreur s'est produite durant la génération de la fiche de liaison", 'error');
    }
  }
}

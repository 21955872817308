import { Component } from '@angular/core';
import { AbstractBoxFileListComponent } from '../../shared/abstract-box-files-list.component';
type DenominationChange = { newDenomination: string; fileId: number };

@Component({
  selector: 'app-box-file-list',
  templateUrl: './box-file-list.component.html',
  styleUrls: ['./box-file-list.component.scss'],
})
export class BoxFileListComponent extends AbstractBoxFileListComponent {}

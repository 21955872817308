<div class="operation-file" *ngFor="let file of files" (click)="onfileClick(file)">
  <div *ngIf="checkbox" class="checkbox-container">
    <oui-checkbox
      *ngIf="file.fileId"
      class="file-checkbox"
      [checked]="isIncluded(file.id)"
      (checkedChange)="toggleFileSelection(file.id)"
      (click)="$event.stopPropagation()"></oui-checkbox>
  </div>
  <div class="denomination">
    <div class="file-libelle body-2">
      {{
        getFileLabel(file)
      }}
    </div>
    <div class="file-nature caption">
      {{ getSubLabel(file) }}
    </div>
  </div>
  <div class="file-name caption">
    {{ file.metadata?.fileName }}
  </div>
  <div class="status-container" *ngIf="isStatusDisplayed(file)">
    <app-file-statut-tag class="file-status" [file]="file"></app-file-statut-tag>
  </div>
</div>

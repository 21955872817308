import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NonConformiteFichier } from '../../models/generated/graphql';
import { CommonModule } from '@angular/common';
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { NonConformiteFichierCardComponent } from '../non-conformite-fichier-card/non-conformite-fichier-card.component';

@Component({
  selector: 'app-non-conformite-accordion',
  standalone: true,
  imports: [CommonModule, OuiAccordionModule, NonConformiteFichierCardComponent],
  templateUrl: './non-conformite-accordion.component.html',
  styleUrls: ['./non-conformite-accordion.component.scss'],
})
export class NonConformiteAccordionComponent {
  @Input() NCs: NonConformiteFichier[];
  @Input() isShowMenu: boolean = false;
  @Input() title: string = 'Non-conformités ouvertes';
  @Input() titleClass: string = 'color-error subtitle-1';

  @Output() onCloseNC = new EventEmitter<NonConformiteFichier>();
  @Output() onEditNC = new EventEmitter<NonConformiteFichier>();
  @Output() onreOpenNC = new EventEmitter<NonConformiteFichier>();

  closeNC(nc: NonConformiteFichier) {
    this.onCloseNC.emit(nc);
  }
  editNC(nc: NonConformiteFichier) {
    this.onEditNC.emit(nc);
  }
  reOpenNC(nc: NonConformiteFichier) {
    this.onreOpenNC.emit(nc);
  }
}

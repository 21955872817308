import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoxFile, FileMetadata } from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';
import { OuiIconModule } from 'omnium-ui/icon';
import { CommonModule, DatePipe } from '@angular/common';
import { OuiDocumentViewer } from 'omnium-ui/document-viewer';
import { OuiButtonModule } from 'omnium-ui/button';
import { DocumentViewerHeaderComponent } from '../_common/document-viewer-header/document-viewer-header.component';

@Component({
  templateUrl: './simple-document-viewer.component.html',
  selector: 'app-simple-document-viewer',
  standalone: true,
  styleUrls: ['./simple-document-viewer.component.scss'],
  imports: [CommonModule, OuiDocumentViewer, OuiIconModule, OuiButtonModule, DatePipe, DocumentViewerHeaderComponent],
})
export class SimpleDocumentViewerComponent {
  /** file can be GED file (data provided in fileMetadata) OR Box File (data provided in boxFile) */

  private _fileMetadata?: FileMetadata | undefined;
  public get fileMetadata(): FileMetadata | undefined {
    return this._fileMetadata;
  }
  @Input()
  public set fileMetadata(value: FileMetadata | undefined) {
    this._fileMetadata = value;
    this.initDocument();
  }

  private _boxFile?: BoxFile | undefined;
  public get boxFile(): BoxFile | undefined {
    return this._boxFile;
  }
  @Input()
  public set boxFile(value: BoxFile | undefined) {
    this._boxFile = value;
    this.initDocument();
  }

  @Input()
  commentaire?: string;

  @Input()
  documentUrl?: string;

  @Output()
  close = new EventEmitter();

  protected get headerDocumentName(): string {
    const hasTypeLibelle = this.fileMetadata?.typeLibelle && this.fileMetadata.typeLibelle.toUpperCase() !== 'AUTRE';
    return hasTypeLibelle
      ? this.fileMetadata?.typeLibelle ?? 'Autre'
      : this.fileMetadata?.denomination ?? this.boxFile?.denomination ?? 'Autre';
  }

  constructor(private documentService: DocumentsService) {}

  async initDocument() {
    this.documentUrl = undefined;

    let fileCacheUrl;
    if (this.fileMetadata) {
      fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(this.fileMetadata);
    } else if (this.boxFile?.fileConnectionInfo?.sasUrl) {
      fileCacheUrl = await this.documentService.fetchAndCacheBlobDocument(
        this.boxFile.fileConnectionInfo?.sasUrl,
        this.boxFile.fileExtension
      );
    }

    if (fileCacheUrl) {
      this.documentUrl = fileCacheUrl;
    }
  }
}

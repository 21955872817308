import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isArray } from '@apollo/client/utilities';
import { gql } from 'apollo-angular';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { OuiStep, OuiStepper } from 'omnium-ui/stepper';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { firstValueFrom } from 'rxjs';
import { operationNotifyFragment } from '../../models/graphqlFragments';
import {
  BoxFile,
  EnvoiEmail,
  EnvoiPartenaire,
  FichierOperation,
  Operation,
  OperationsPaginatedCollectionSegment,
} from '../../models/generated/graphql';
import { DocumentsService } from '../../services/documents.service';
import { deepCopy } from '../../utils/deepCopy';
import { fileLabel } from '../../utils/fichierOperationLabel';

interface NotifyMailFile {
  identifier: FileIdentifier;
  filename: string | undefined;
  denomination: string | undefined;
  sizeInMB: string;
}
interface FileIdentifier {
  id: number;
  type: MailFileType;
}
enum MailFileType {
  box = 'box',
  fichierOperation = 'fichierOperation',
}
const senderEmail = gql`
  query senderEmail($operationId: Int, $envoiPartenaireId: Int) {
    senderEmail(operationId: $operationId, envoiPartenaireId: $envoiPartenaireId)
  }
`;

const operationPartenaireEmail = gql`
  query operationPartenaireEmail($operationId: Int!) {
    allOperationsPaginated(skip: 0, take: 1, where: { id: { eq: $operationId } }) {
      items {
        id
        produit {
          idPartenaireNavigation {
            contact {
              email {
                adresse
              }
            }
          }
        }
      }
    }
  }
`;

const envoiPartenaireEmail = gql`
  query envoiPartenaireEmail($envoiId: Int!) {
    envoiPartenaireById(id: $envoiId) {
      id
      partenaire {
        contact {
          email {
            adresse
          }
        }
      }
    }
  }
`;

const OPERATIONNOTIFYDETAILS = gql`
  query allOperations($where: OperationFilterInput) {
    allOperationsPaginated(skip: 0, take: 1, where: $where) {
      items {
        ...operationNotify
      }
    }
  }
  ${operationNotifyFragment}
`;
const sendPartenaireNotificationEmail = gql`
  mutation sendPartenaireNotificationEmail(
    $bordereauId: Int!
    $toList: [String!]!
    $ccList: [String!]!
    $subject: String!
    $textContent: String!
    $attachBoxFileIds: [Int!]!
  ) {
    sendPartenaireNotificationEmail(
      bordereauId: $bordereauId
      toList: $toList
      ccList: $ccList
      subject: $subject
      textContent: $textContent
      attachBoxFileIds: $attachBoxFileIds
    ) {
      id
    }
  }
`;

const sendOperationTransmissionEmailToPartenaire = gql`
  mutation sendOperationTransmissionEmailToPartenaire(
    $operationId: Int!
    $toList: [String!]!
    $ccList: [String!]!
    $subject: String!
    $textContent: String!
    $attachBoxFileIds: [Int!]!
    $fichierOperationIds: [Int!]!
  ) {
    sendOperationTransmissionEmailToPartenaire(
      operationId: $operationId
      toList: $toList
      ccList: $ccList
      subject: $subject
      textContent: $textContent
      attachBoxFileIds: $attachBoxFileIds
      fichierOperationIds: $fichierOperationIds
    ) {
      id
    }
  }
`;

@Component({
  selector: 'lib-notify-mail-partenaire',
  templateUrl: './notify-mail-partenaire.component.html',
  styleUrls: ['./notify-mail-partenaire.component.scss'],
})
export class NotifyMailPartenaireComponent {
  @Input() dialogTitle: string = 'Notifier le partenaire';
  @Input() operationId: number | undefined;
  @Input() isChild: boolean = false;
  @Output() onMailSent = new EventEmitter<boolean>();
  @ViewChild('stepper') stepper?: OuiStepper;

  readonly DOCUMENT_STEP_TITLE: string = 'Sélectionner les documents';
  readonly MAIL_STEP_TITLE: string = 'E-mail partenaire';
  readonly OBJECT_DEFAULT_VALUE: string = 'Stellium / ';
  readonly EMAIL_SEPARATOR: string = ';';

  readonly EMAIL_MESSAGE: string =
    "Bonjour,\nJe vous prie de trouver ci-joint les éléments pour l'opération référencée en objet.\nNos sincères salutations";

  private boxFileSizeMap = new Map<number, string>();
  private gedFileSizeMap = new Map<number, string>();

  steps: OuiStep[] = [
    {
      title: this.DOCUMENT_STEP_TITLE,
      state: 'todo',
      iconColor: 'primary',
    },
    {
      title: this.MAIL_STEP_TITLE,
      state: 'todo',
    },
  ];

  mailFromControl: FormControl;
  mailToControl: FormControl;
  mailCcControl: FormControl;
  mailObjectControl: FormControl;
  mailContentControl: FormControl;
  senderEMail: string;
  objectDefaultValue: string;

  operation: Operation;
  operationFiles: FichierOperation[];
  envoiPartenaire: EnvoiPartenaire;
  // ids of selected files with type (box or ged)
  selection: FileIdentifier[] = [];

  boxFiles: BoxFile[] = [];
  files: NotifyMailFile[] = [];

  destList: string[] = [];

  isCloseItself = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { envoiPartenaire: EnvoiPartenaire | undefined; operationId: number | undefined },
    private queryManager: QueryManagerService,
    private dialogRef: MatDialogRef<NotifyMailPartenaireComponent>,
    private documentService: DocumentsService,
    private snackbarService: OuiSnackbarService
  ) {
    if (data && data.envoiPartenaire) {
      this.envoiPartenaire = this.data.envoiPartenaire!;
      if (this.envoiPartenaire.bordereauFile) {
        this.boxFiles = [deepCopy(this.envoiPartenaire.bordereauFile)]; //deepCopy to mutate the object (denomination)
        this.boxFiles[0].denomination = 'Bordereau partenaire';
      }
      if (this.envoiPartenaire.attachedFiles?.length) {
        this.boxFiles.push(...this.data.envoiPartenaire!.attachedFiles);
      }

      this.queryManager
        .query<{ envoiPartenaireById: EnvoiPartenaire }>({
          query: envoiPartenaireEmail,
          variables: { envoiId: this.envoiPartenaire.id },
        })
        .subscribe(res => {
          res.data.envoiPartenaireById?.partenaire?.contact?.forEach(contact => {
            contact.email?.forEach(email => {
              if (email?.adresse) {
                this.destList.push(email.adresse);
              }
            });
          });
        });
    }
    if (data && data.operationId) {
      this.operationId = data.operationId;
      this.queryManager
        .query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
          query: operationPartenaireEmail,
          variables: { operationId: this.operationId },
        })
        .subscribe(res => {
          res.data.allOperationsPaginated?.items?.forEach(operation =>
            operation?.produit?.idPartenaireNavigation?.contact?.forEach(contact => {
              contact.email?.forEach(email => {
                if (email?.adresse) {
                  this.destList.push(email.adresse);
                }
              });
            })
          );
        });
    }
  }

  ngOnInit() {
    this.queryManager
      .query<{
        senderEmail: string;
      }>({
        query: senderEmail,
        variables: { operationId: this.operationId, envoiPartenaireId: this.envoiPartenaire?.id },
      })
      .subscribe(res => {
        this.senderEMail = res.data.senderEmail;
        this.mailFromControl = new FormControl(this.senderEMail);
        this.mailFromControl.disable();
        this.populateData();
      });
  }

  async populateData() {
    if (!this.operationId) {
      this.setControls();
      return await this.setBoxFiles();
    }
    await this.getOperationAndFilesByOperationId(this.operationId);
    this.setControls();
    this.setBoxFiles();
    this.setGedFiles();
  }

  toggleFileSelection(identifier: FileIdentifier) {
    if (this.selection.includes(identifier)) {
      this.selection = this.selection.filter(id => id !== identifier);
    } else {
      this.selection.push(identifier);
    }
  }
  toggleAllRows() {
    this.isAllSelected() ? (this.selection = []) : (this.selection = this.files.map(f => f.identifier));
  }
  isAllSelected(): boolean {
    const identifiers = this.files.map(f => f.identifier);
    if (JSON.stringify(identifiers) == JSON.stringify(this.selection)) {
      return true;
    }
    return false;
  }

  onStepChange(step: OuiStep) {}

  onStepStateChange(step: OuiStep) {}

  onPreviousStepClick() {
    this.stepper?.prevStep();
  }
  onCancel() {
    this.dialogRef.close();
  }
  onNextStepClick() {
    this.stepper?.completeCurrentStep();
    this.stepper?.nextStep();
    this.steps[0].icon = 'done';
  }

  setControls() {
    var mailTo = this.destList.join('; ');
    this.mailToControl = new FormControl(mailTo, [Validators.required]);
    this.mailCcControl = new FormControl('');
    this.objectDefaultValue = this.operationId ? this.objectOperation() : this.objetBordereau();
    this.mailObjectControl = new FormControl(this.objectDefaultValue, [Validators.required]);
    this.mailContentControl = new FormControl(this.EMAIL_MESSAGE, [Validators.required]);
  }

  objetBordereau() {
    return this.OBJECT_DEFAULT_VALUE + `Bordereau d’envoi N°${this.envoiPartenaire.id}`;
  }

  objectOperation() {
    return (
      this.OBJECT_DEFAULT_VALUE +
      `${this.operation.operationConfig?.natureOperation?.libelle} / ${
        this.operation.contrat?.numeroContrat ?? this.operation.produit?.nom
      } / ${this.operation.investisseur?.investisseurEntite?.displayName}`
    );
  }
  async onSendEmailClick() {
    var toList = this.mailToControl.value
      .split(';')
      .map((e: string) => e.trim())
      .filter((e: string) => e.length > 0);
    var ccList = this.mailCcControl.value
      .split(';')
      .map((e: string) => e.trim())
      .filter((e: string) => e.length > 0);
    if (!this.operationId && this.envoiPartenaire.bordereauFile) {
      const mailVariables = {
        bordereauId: this.envoiPartenaire.id,
        toList,
        ccList,
        subject: this.mailObjectControl.value,
        textContent: this.mailContentControl.value,
        attachBoxFileIds: this.selection.map(s => s.id),
      };

      const result = await firstValueFrom(
        this.queryManager.mutate<{ sendPartenaireNotificationEmail: [EnvoiEmail] }>({
          mutation: sendPartenaireNotificationEmail,
          variables: mailVariables,
        })
      );
      this.dialogRef.close(result.data?.sendPartenaireNotificationEmail);
    }
    if (this.operationId) {
      const variables = {
        operationId: this.operationId,
        toList,
        ccList,
        subject: this.mailObjectControl.value,
        textContent: this.mailContentControl.value,
        attachBoxFileIds: this.selection.filter(s => s.type === MailFileType.box).map(s => s.id),
        fichierOperationIds: this.selection.filter(s => s.type === MailFileType.fichierOperation).map(s => s.id),
      };

      const result = await firstValueFrom(
        this.queryManager.mutate<{ sendOperationTransmissionEmailToPartenaire: [EnvoiEmail] }>({
          mutation: sendOperationTransmissionEmailToPartenaire,
          variables,
        })
      );

      if (result.data?.sendOperationTransmissionEmailToPartenaire[0]?.id) {
        this.snackbarService.open('E-mail envoyé au partenaire', 'success', 5000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
        this.isChild
          ? this.onMailSent.emit(true)
          : this.dialogRef.close(result.data?.sendOperationTransmissionEmailToPartenaire[0]?.id);
      }
    }
  }

  async setBoxFiles() {
    const boxPromises = this.boxFiles.map(async file => {
      const boxFileSizeInBytes = await this.documentService.getFileSizeInBytes(file.fileConnectionInfo?.sasUrl!);
      if (boxFileSizeInBytes) {
        const fileSizeInMB = (boxFileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB and round to 2 decimal places
        this.boxFileSizeMap.set(file.id, fileSizeInMB);
      }
    });

    await Promise.all(boxPromises);
    const boxFiles = this.boxFiles.map(file => ({
      identifier: { id: file.id, type: MailFileType.box },
      filename: file.fileNameWithExtension || undefined,
      denomination: file.denomination || undefined,
      sizeInMB: this.boxFileSizeMap.get(file.id) ?? '0',
    }));
    this.files = [...this.files, ...boxFiles];
  }

  async setGedFiles() {
    const gedPromises = this.operationFiles.map(async file => {
      const gedFileSizeInBytes = await this.documentService.getFileSizeInBytes(file?.metadata?.url!);
      if (gedFileSizeInBytes) {
        const fileSizeInMB = (gedFileSizeInBytes / (1024 * 1024)).toFixed(2); // Convert to MB and round to 2 decimal places
        this.gedFileSizeMap.set(file.id, fileSizeInMB);
      }
    });
    await Promise.all(gedPromises);
    const gedFiles = this.operationFiles.map(file => ({
      identifier: { id: file.id, type: MailFileType.fichierOperation },
      filename: file.metadata?.fileName || undefined,
      denomination: this.getFileLabel(file) ?? undefined,
      sizeInMB: this.gedFileSizeMap.get(file.id) ?? '0',
    }));
    this.files = [...this.files, ...gedFiles];
  }

  async getOperationAndFilesByOperationId(id: number) {
    const result = await firstValueFrom(
      this.queryManager.query<{
        allOperationsPaginated: OperationsPaginatedCollectionSegment;
      }>({
        query: OPERATIONNOTIFYDETAILS,
        variables: {
          where: { id: { eq: id } },
        },
        fetchPolicy: 'network-only',
      })
    );

    if (
      result.data &&
      isArray(result.data?.allOperationsPaginated?.items) &&
      result.data.allOperationsPaginated.items[0]
    ) {
      this.operation = result.data.allOperationsPaginated.items[0];
      this.operationFiles = this.operation.fichierOperations;
      this.boxFiles = this.operation.attachedFiles;
    }
  }

  getFileLabel(file: FichierOperation): string {
    return fileLabel(file);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import {
  EnvoiPartenaire,
  EnvoiPartenaireFilterInput,
  EnvoiPartenairePaginatedCollectionSegment,
  EnvoiPartenaireSortInput,
  EnvoiPartenaireStatut,
  SortEnumType,
} from '@lib/models/generated/graphql';
import { emailCheckFragment } from '@lib/models/graphqlFragments';
import { OptionsService } from '@lib/services/options.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiSelectOption } from 'omnium-ui/form-field/select/select-option.model';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { BordereauxTableComponent } from 'src/app/components/bordereaux-desk/bordereaux-table/bordereaux-table.component';

const PAGEALLBORDEREAUX = gql`
  query allBordereauxPaginated(
    $skip: Int
    $take: Int
    $filter: EnvoiPartenaireFilterInput
    $order: [EnvoiPartenaireSortInput!]
  ) {
    allEnvoiPartenairePaginated(skip: $skip, take: $take, where: $filter, order: $order) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        statutEnvoi
        sendingDate
        receiveDate
        updateDate
        partenaire {
          nom
        }
        emails {
          ...emailCheck
        }
      }
      totalCount
    }
  }
  ${emailCheckFragment}
`;

@Component({
  selector: 'app-bordereaux-suivi',
  templateUrl: './bordereaux-suivi.component.html',
  styleUrls: ['./bordereaux-suivi.component.scss'],
})
export class BordereauxSuiviComponent implements OnInit {
  statutOptions: OuiSelectOption<EnvoiPartenaireStatut>[];
  statutControl = new FormControl<EnvoiPartenaireStatut | null>(null);

  filterSelection: DashboardFilterEnum[] = [
    DashboardFilterEnum.BordereauDateEnvoi,
    DashboardFilterEnum.Partenaire,
    DashboardFilterEnum.BordereauStatut,
  ];

  selectedPartenaireId: number | null = null;

  dataSource = new MatTableDataSource<EnvoiPartenaire>([]);

  displayProduitDetails = false;
  natureListOptions: OuiSelectOption[] = [];
  NatureFilters: any = [];

  bordereauStatutControl = new FormControl<EnvoiPartenaireStatut[]>([]);
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { data: EnvoiPartenairePaginatedCollectionSegment; loading: boolean } | undefined;
  statutSub: Subscription;

  sortInput: EnvoiPartenaireSortInput | undefined;

  requestFilter: EnvoiPartenaireFilterInput;
  @ViewChild(BordereauxTableComponent) bordereauxTable: BordereauxTableComponent;

  constructor(
    private queryManager: QueryManagerService,
    private optionsService: OptionsService
  ) {}

  ngOnInit() {
    this.statutOptions = this.optionsService.getEnvoiPartenaireStatutOptions();
    this.statutSub = this.bordereauStatutControl.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      this.paginationParams = DEFAULT_PAGINATION_PARAMS;
      this.getAllBordereaux();
    });
  }
  ngOnDestroy(): void {
    this.statutSub.unsubscribe();
  }
  setSelectedPartenaireId(event: number | null) {
    this.selectedPartenaireId = event;
    this.getAllBordereaux();
  }

  getAllBordereaux(): void {
    const requestVariables = {
      ...this.paginationParams,
      filter: this.requestFilter,
      order: this.buildRequestSortCriteria(),
    };
    this.queryManager
      .query<{ allEnvoiPartenairePaginated: EnvoiPartenairePaginatedCollectionSegment }>({
        query: PAGEALLBORDEREAUX,
        variables: requestVariables,
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allEnvoiPartenairePaginated };
      });
  }

  onFilterChanged(event: EnvoiPartenaireFilterInput) {
    this.requestFilter = event;
    this.getAllBordereaux();
  }
  onSortClicked(event: EnvoiPartenaireSortInput | undefined) {
    this.sortInput = event;
    this.getAllBordereaux();
  }

  buildRequestSortCriteria(): EnvoiPartenaireSortInput[] {
    const sortCriteria: EnvoiPartenaireSortInput[] = [];
    let sorter: EnvoiPartenaireSortInput = { updateDate: SortEnumType.Desc }; //by default sort by last update date

    if (this.sortInput) {
      sorter = this.sortInput;
    }
    sortCriteria.push(sorter);

    // add sort by id as second sort criteria to ensure determinism
    if (!this.sortInput?.id) {
      sortCriteria.push({ id: SortEnumType.Desc });
    }

    return sortCriteria;
  }
}

import { Component, Input } from '@angular/core';
import { Operation } from 'projects/box-lib/src/lib/models/generated/graphql';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';

@Component({
  selector: 'lib-nature-cell',
  templateUrl: './nature-cell.component.html',
  styleUrls: ['./nature-cell.component.scss'],
})
export class NatureCellComponent {
  @Input() operation: Operation;
  @Input() displayName: string | undefined | null;
  isBackOffice: boolean;
  constructor(authService: AuthService) {
    this.isBackOffice = authService.isBackOffice();
  }
}

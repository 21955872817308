import { Component, Input } from '@angular/core';
import { EnvoiEmailStatut, EnvoiPartenaire } from '@lib/models/generated/graphql';

@Component({
  selector: 'app-bordereau-warning-icon',
  templateUrl: './bordereau-warning-icon.component.html',
  styleUrls: ['./bordereau-warning-icon.component.scss'],
})
export class BordereauWarningIconComponent {
  @Input() bordereau: EnvoiPartenaire;

  isWarning = false;
  ngOnChanges() {
    this.checkIsWarning();
  }

  ngOnInit() {
    this.checkIsWarning();
  }

  isErrorInEmail() {
    if (this.bordereau) {
      return this.bordereau.emails.some(
        email => email.statutEnvoi === EnvoiEmailStatut.Error && !email.isErrorAcknowledged
      );
    }
    return false;
  }
  checkIsWarning() {
    this.isWarning = this.isErrorInEmail();
  }
}

import { Component, Input } from '@angular/core';
import { OuiTagBrand, OuiTagSize, OuiTagStatus } from 'omnium-ui/tag';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';
import { Statut } from '../../models/generated/graphql';
type TagType = 'status' | 'brand';
type Declination = 'primary' | 'secondary';
type OperationStatutTagConfig = {
  statutId: number;
  tagType: TagType;
  value: OuiTagBrand | OuiTagStatus;
  declination: Declination;
};
@Component({
  selector: 'app-operation-statut-tag',
  templateUrl: './operation-statut-tag.component.html',
  styleUrls: ['./operation-statut-tag.component.scss'],
})
export class OperationStatutTagComponent {
  config: OperationStatutTagConfig[];

  consultantTagConfig: OperationStatutTagConfig[] = [
    {
      statutId: 1,
      tagType: 'status',
      value: 'todo',
      declination: 'primary',
    },
    {
      statutId: 5,
      tagType: 'status',
      value: 'todo',
      declination: 'primary',
    },
    {
      statutId: 50,
      tagType: 'status',
      value: 'error',
      declination: 'primary',
    },
    {
      statutId: 51,
      tagType: 'status',
      value: 'error',
      declination: 'primary',
    },
    {
      statutId: 100,
      tagType: 'status',
      value: 'transition',
      declination: 'primary',
    },
    {
      statutId: 105,
      tagType: 'brand',
      value: 'placement',
      declination: 'primary',
    },
    {
      statutId: 200,
      tagType: 'brand',
      value: 'placement',
      declination: 'primary',
    },
    {
      statutId: 205,
      tagType: 'brand',
      value: 'placement',
      declination: 'primary',
    },
    {
      statutId: 206,
      tagType: 'brand',
      value: 'placement',
      declination: 'primary',
    },
    {
      statutId: 250,
      tagType: 'status',
      value: 'transition',
      declination: 'secondary',
    },
    {
      statutId: 300,
      tagType: 'status',
      value: 'transition',
      declination: 'secondary',
    },
    {
      statutId: 310,
      tagType: 'status',
      value: 'transition',
      declination: 'secondary',
    },
    {
      statutId: 400,
      tagType: 'status',
      value: 'completed',
      declination: 'primary',
    },
    {
      statutId: 405,
      tagType: 'status',
      value: 'pending',
      declination: 'primary',
    },
    {
      statutId: 410,
      tagType: 'status',
      value: 'pending',
      declination: 'primary',
    },
  ];

  backOfficeTagConfig: OperationStatutTagConfig[] = [
    {
      statutId: 1,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 5,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 50,
      tagType: 'status',
      value: 'transition',
      declination: 'primary',
    },
    {
      statutId: 51,
      tagType: 'status',
      value: 'transition',
      declination: 'primary',
    },
    {
      statutId: 100,
      tagType: 'status',
      value: 'transition',
      declination: 'primary',
    },
    {
      statutId: 105,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 200,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 205,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 206,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 250,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 300,
      tagType: 'status',
      value: 'transition',
      declination: 'primary',
    },
    {
      statutId: 310,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 400,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 405,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
    {
      statutId: 410,
      tagType: 'status',
      value: 'in-progress',
      declination: 'primary',
    },
  ];
  selectedConfig: OperationStatutTagConfig;
  statutText: string;
  brandValue: OuiTagBrand | undefined;
  statusValue: OuiTagStatus | undefined;

  @Input({ required: true }) set statut(statut: Statut) {
    this.config = this.authService.isBackOffice() ? this.backOfficeTagConfig : this.consultantTagConfig;
    this.selectedConfig = this.config.find(c => c.statutId === statut.id) ?? this.config[0];
    this.statutText = this.authService.isBackOffice()
      ? (statut.backOfficeLibelle ?? 'statut inconnu')
      : (statut.consultantLibelle ?? 'statut inconnu');

    if (this.selectedConfig.tagType === 'brand') {
      this.brandValue = this.selectedConfig.value as OuiTagBrand;
    } else if (this.selectedConfig.tagType === 'status') {
      this.statusValue = this.selectedConfig.value as OuiTagStatus;
    }
  }
  @Input() size: OuiTagSize = 'big';
  constructor(private authService: AuthService) {}
}

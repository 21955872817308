import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { BackOfficeMember, BackOfficeMemberSortInput, SortEnumType } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { ConfirmGestionnaireDeleteComponent } from 'src/app/components/parameters/param-utilisateurs/confirm-gestionnaire-delete/confirm-gestionnaire-delete.component';

const toggleBackOfficeMemberExtendedRIghts = gql`
  mutation toggleBackOfficeMemberExtendedRIghts($backOfficerMemberId: Int!) {
    toggleBackOfficeMemberExtendedRIghts(backOfficerMemberId: $backOfficerMemberId) {
      id
      tokenId
      displayName
      groupeGestionnaire
      isAdmin
      withGroupeAccessExtended
    }
  }
`;

const deactivateBackOfficeMember = gql`
  mutation deactivateBackOfficeMember($backOfficerMemberId: Int!) {
    deactivateBackOfficeMember(backOfficerMemberId: $backOfficerMemberId) {
      id
    }
  }
`;

@Component({
  selector: 'app-utilisateurs-table',
  templateUrl: './utilisateurs-table.component.html',
  styleUrls: ['./utilisateurs-table.component.scss'],
})
export class UtilisateursTableComponent extends AbstractPaginatedTableComponent<BackOfficeMember> {
  @ViewChild('usersSort') usersSort!: MatSort;

  @Output()
  onSortCriteriaUpdate = new EventEmitter<BackOfficeMemberSortInput | undefined>();

  displayedColumns = ['nom', 'role', 'group', 'toggleExtendedAccess', 'deactivateButton'];

  constructor(private queryManager: QueryManagerService, private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    this.usersSort.sortChange.subscribe(() => {
      this.onSortCriteriaUpdate.emit(this.getSortInputBySort(this.usersSort));
    });
  }

  getSortInputBySort(sort: MatSort): BackOfficeMemberSortInput | undefined {
    {
      if (sort.direction == '') {
        return undefined;
      }
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'nom':
          return { displayName: sort.direction === 'asc' ? SortEnumType.Asc : SortEnumType.Desc };
        case 'group':
          return { groupeGestionnaire: sort.direction === 'asc' ? SortEnumType.Asc : SortEnumType.Desc };
        default:
          return undefined;
      }
    }
  }

  onUserRightsToggle(user: BackOfficeMember) {
    //On fait une requete de mutation pour changer l'état
    this.queryManager
      .mutate<{ toggleBackOfficeMemberExtendedRIghts: BackOfficeMember }>({
        mutation: toggleBackOfficeMemberExtendedRIghts,
        variables: { backOfficerMemberId: user.id },
      })
      .subscribe(result => {
        // on retouve le user associé dans le datasource
        const resultUser = result.data?.toggleBackOfficeMemberExtendedRIghts;
        const dataSourceUser = this.dataSource.data.find(dUser => dUser?.id === user.id);
        if (dataSourceUser && resultUser) {
          // on update le user dans le datasource
          dataSourceUser.withGroupeAccessExtended = resultUser.withGroupeAccessExtended;
        }
        // on force le rendering du tableau car on a changer un sous-element du tableau qui n'est pas detecté
        this.table.renderRows();
      });
  }

  onDeactivateGestionnaire(user: BackOfficeMember) {
    const modalRef = this.dialog.open(ConfirmGestionnaireDeleteComponent, {
      height: 'auto',
      width: '402px',
    });
    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        //On fait une requete de mutation pour changer l'état
        this.queryManager
          .mutate<{ deactivateBackOfficeMember: BackOfficeMember }>({
            mutation: deactivateBackOfficeMember,
            variables: { backOfficerMemberId: user.id },
          })
          .subscribe(result => {
            // on retouve le user associé dans le datasource
            const usersDataSource = this.dataSource.data.filter(
              dUser => dUser?.id !== result.data?.deactivateBackOfficeMember?.id
            );
            if (usersDataSource) {
              // on update le datasource
              this.setTableData(usersDataSource);
            }

            // on met a jour manuellement les données de pagination
            this.pageSize = usersDataSource.length;
            this.totalCount = this.totalCount - 1;

            // on force le rendering du tableau car on a changer un sous-element du tableau qui n'est pas detecté
            this.table.renderRows();
          });
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from '@lib/components/unauthorized/unauthorized.component';

import { LandingPageComponent } from '@lib/components/landing-page/landing-page.component';
import { BordereauxDeskComponent } from 'src/app/components/bordereaux-desk/bordereaux-desk.component';
import { BordereauxDetailsComponent } from 'src/app/components/bordereaux-details/bordereaux-details.component';
import { ParamTypesDocComponent } from 'src/app/components/parameters/param-doc-types/param-doc-types.component';
import { ParamProductsComponent } from 'src/app/components/parameters/param-products/param-products.component';
import { ParamTransferOperationsComponent } from 'src/app/components/parameters/param-transfer-operations/param-transfer-operations.component';
import { ParamUtilisateursComponent } from 'src/app/components/parameters/param-utilisateurs/param-utilisateurs.component';
import { ParametersComponent } from 'src/app/components/parameters/parameters.component';
import { InitGuard, authAdminGuard, authBackOfficeGuard } from 'src/app/guards/auth.guard';
import { BordereauxOperationsDashboardComponent } from './components/bordereaux-desk/bordereaux-operations-dashboard/bordereaux-operations-dashboard.component';
import { BordereauxSuiviComponent } from './components/bordereaux-desk/bordereaux-suivi/bordereaux-suivi.component';
import { OperationDetailsGestionnaireComponent } from './components/operation-details-gestionnaire/operation-details-gestionnaire.component';
import { OperationsDeskComponent } from './components/operations-desk/operations-desk.component';
import { ParamFondEvenementielsComponent } from './components/parameters/param-fond-evenementiels/param-fond-evenementiels.component';

const routes: Routes = [
  {
    path: 'backoffice-tab/:tabId',
    title: 'Backoffice opérations - Box Placement',
    component: OperationsDeskComponent,
    canActivate: [authBackOfficeGuard],
  },
  {
    path: 'backoffice/:operationId',
    title: (route: ActivatedRouteSnapshot) => 'Opération ' + (route.params['operationId'] ?? '') + ' - Box Placement',
    component: OperationDetailsGestionnaireComponent,
    canActivate: [authBackOfficeGuard],
  },
  {
    path: 'bordereaux',
    title: 'Bordereaux partenaires - Box Placement',
    component: BordereauxDeskComponent,
    canActivate: [authBackOfficeGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: BordereauxOperationsDashboardComponent, canActivate: [authBackOfficeGuard] },
      { path: 'suivi', component: BordereauxSuiviComponent, canActivate: [authBackOfficeGuard] },
    ],
  },
  {
    path: 'bordereau-details/:bordereauId',
    title: (route: ActivatedRouteSnapshot) =>
      'Suivi bordereau ' + (route.params['bordereauId'] ?? '') + ' - Box Placement',
    component: BordereauxDetailsComponent,
    canActivate: [authBackOfficeGuard],
  },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'init', component: LandingPageComponent, canActivate: [InitGuard] },
  {
    path: 'parametres',
    title: 'Paramètres',
    component: ParametersComponent,
    canActivate: [authAdminGuard],
    children: [
      {
        path: 'produits',
        title: 'Produits - Box Placement',
        component: ParamProductsComponent,
        canActivate: [authAdminGuard],
      },
      {
        path: 'types-doc',
        title: 'types documents - Box Placement',
        component: ParamTypesDocComponent,
        canActivate: [authAdminGuard],
      },
      {
        path: 'produits/:produitId',
        title: 'Produit - Box Placement',
        component: ParamProductsComponent,
        canActivate: [authAdminGuard],
      },
      {
        path: 'fonds-evnt',
        title: 'Fonds événementiels - Box Placement',
        component: ParamFondEvenementielsComponent,
        canActivate: [authAdminGuard],
      },
      {
        path: 'utilisateurs',
        title: 'Utilisateurs - Box Placement',
        component: ParamUtilisateursComponent,
        canActivate: [authAdminGuard],
      },
      {
        path: 'transfer-operations',
        title: "Transfert d'opérations - Box Placement",
        component: ParamTransferOperationsComponent,
        canActivate: [authAdminGuard],
      },
      { path: '**', redirectTo: 'produits' },
    ],
  },
  { path: '**', redirectTo: 'backoffice-tab/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<ng-container *ngIf="displaymode === 'full'">
  <div class="bold">Transaction personnelle</div>
  <oui-radio-button-group [control]="radioControl" direction="vertical">
    <oui-radio-button [value]="transactionOption.Non">Non</oui-radio-button>
    <oui-radio-button [value]="transactionOption.AutoSouscription"
      >Auto-souscription
      <oui-infos-bulle oui-radio-suffix bulleTitle="Auto-souscription :">
        <ul>
          <li>Fait pour vous même en nom propre</li>
          <li>Pour une personne morale dont vous êtes bénéficiaire effectif ou représentant légal</li>
        </ul>
      </oui-infos-bulle>
    </oui-radio-button>
    <oui-radio-button [value]="transactionOption.PourProches">
      Personne(s) avec un lien familial ou étroit
      <oui-infos-bulle oui-radio-suffix bulleTitle="Personnes avec un lien familial ou étroit :">
        Les personnes avec un lien familial ou étroit qui entrent dans le champ d'application sont :
        <ul>
          <li>Votre conjoint non séparé de corps ou votre partenaire de PACS</li>
          <li>
            Les enfants mineurs sur lesquels vous avez l'autorité parentale, ou majeurs s'ils résident chez vous
            habituellement ou en alternance, et que vous avez la charge effective et permanente
          </li>
          <li>Tout autre parent ou allié si il réside à votre domicile depuis au moins un an</li>
        </ul>
      </oui-infos-bulle>
    </oui-radio-button>
    <oui-radio-button [value]="transactionOption.PourSalaries">Un de mes salariés</oui-radio-button>
  </oui-radio-button-group>
</ng-container>

<ng-container *ngIf="displaymode === 'partial'">
  <div class="flex-horizontal width-100pc flex-center gap-8">
    <oui-slide-toggle label="Contrat en auto-souscription" [control]="toggleControl"></oui-slide-toggle>
    <oui-infos-bulle bulleTitle="Auto-souscription">
      <ul>
        <li>fait pour vous même en nom propre</li>
        <li>pour une personne morale dont vous êtes bénéficiaire effectif ou représentant légal</li>
      </ul>
    </oui-infos-bulle>
  </div>
</ng-container>

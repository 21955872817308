<div class="flex-vertical gap-16 width-100pc">
  <div>
    {{ totalCount }} <span *ngIf="totalCount > 1">bordereaux</span><span *ngIf="totalCount < 2">bordereau</span>
  </div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource" matSort #envoiPartenaireSort="matSort">
        <ng-container matColumnDef="identifiant">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifiant</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>
        <ng-container matColumnDef="Partenaire">
          <th mat-header-cell *matHeaderCellDef>Partenaire</th>
          <td mat-cell *matCellDef="let element">{{ element?.partenaire?.nom }}</td>
        </ng-container>

        <ng-container matColumnDef="sendDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date d'envoi</th>
          <td mat-cell *matCellDef="let element">{{ element?.sendingDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="receiveDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de réception</th>
          <td mat-cell *matCellDef="let element">{{ element?.receiveDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="updateDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mise à jour</th>
          <td mat-cell *matCellDef="let element">{{ element?.updateDate | date: 'dd/MM/yyyy' }}</td>
        </ng-container>

        <ng-container matColumnDef="statut">
          <th mat-header-cell *matHeaderCellDef>Statut</th>
          <td mat-cell *matCellDef="let element">
            <app-bordereau-statut-tag [statut]="element?.statutEnvoi"></app-bordereau-statut-tag>
          </td>
        </ng-container>
        <ng-container matColumnDef="warning">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <app-bordereau-warning-icon [bordereau]="element"></app-bordereau-warning-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr
            mat-row
            [class.interactive]="true"
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>

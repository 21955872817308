import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { DocumentsService } from 'projects/box-lib/src/lib/services/documents.service';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { CreateNonConformiteFichierManquantInput, NonConformiteFichier } from '../../../models/generated/graphql';
import { fichierOperationControlFragment } from '../../../models/graphqlFragments';

const MISSINGFILENC = gql`
  mutation createNonConformiteFichierManquant($input: CreateNonConformiteFichierManquantInput!) {
    createNonConformiteFichierManquant(input: $input) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

const MISSINGFILEINSTANCE = gql`
  mutation createInstanceFichierManquant($input: CreateNonConformiteFichierManquantInput!) {
    createInstanceFichierManquant(input: $input) {
      id
      fichierOperation {
        ...fichierOperationControl
      }
    }
  }
  ${fichierOperationControlFragment}
`;

@Component({
  selector: 'app-declare-missing-file',
  templateUrl: './declare-missing-file.component.html',
  styleUrls: ['./declare-missing-file.component.scss'],
})
export class DeclareMissingFileComponent {
  commentaireControl = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required],
  });

  requestInput: CreateNonConformiteFichierManquantInput = {} as CreateNonConformiteFichierManquantInput;

  bodyText: string = 'Le fichier sera ajouté dans les non-conformités en tant que fichier manquant';

  boxDocumentTypeControl = new FormControl<number | undefined>(undefined, [Validators.required]);
  boxDocumentTypeListOptions: OuiAutocompleteOption[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operationId: number; isInstanceMode: boolean },
    private dialogRef: MatDialogRef<DeclareMissingFileComponent>,
    private queryManager: QueryManagerService,
    private documentService: DocumentsService
  ) {
    this.documentService.getBoxDocumentTypeList().then(boxDocumentTypes => {
      this.boxDocumentTypeListOptions = boxDocumentTypes
        .map(boxDocumentType => ({
          label: boxDocumentType.key,
          value: boxDocumentType.id,
        }))
        .filter(boxDocumentType => boxDocumentType.value != DEFAULT_BOX_DOCUMENT_TYPE.id); // Autre is not allowed to be selected
    });
  }

  ngOnInit() {
    if (this.data.isInstanceMode) {
      this.bodyText = 'Le fichier sera ajouté dans les instances partenaires en tant que fichier manquant';
    }
  }
  isValid(): boolean {
    return this.boxDocumentTypeControl.valid && this.commentaireControl.valid;
  }
  cancel() {
    this.dialogRef.close();
  }
  validate() {
    if (this.boxDocumentTypeControl.value) {
      this.requestInput.commentaire = this.commentaireControl.value;
      this.requestInput.boxDocumentTypeId = this.boxDocumentTypeControl.value;
      this.requestInput.operationId = this.data.operationId;
      this.declareMissingFile(this.requestInput);
    }
  }

  declareMissingFile(input: CreateNonConformiteFichierManquantInput | null) {
    if (!input) {
      return;
    }
    if (!this.data.isInstanceMode) {
      this.declareNCmissingFile(input);
      return;
    }
    this.declareInstanceMissingFile(input);
  }
  declareNCmissingFile(input: CreateNonConformiteFichierManquantInput) {
    this.queryManager
      .mutate<{ createNonConformiteFichierManquant: NonConformiteFichier }>({
        mutation: MISSINGFILENC,
        variables: {
          input: input,
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.createNonConformiteFichierManquant.fichierOperation;
        this.dialogRef.close(updatedFile);
      });
  }

  declareInstanceMissingFile(input: CreateNonConformiteFichierManquantInput) {
    this.queryManager
      .mutate<{ createInstanceFichierManquant: NonConformiteFichier }>({
        mutation: MISSINGFILEINSTANCE,
        variables: {
          input: input,
        },
      })
      .subscribe(result => {
        const updatedFile = result.data?.createInstanceFichierManquant.fichierOperation;
        this.dialogRef.close(updatedFile);
      });
  }
}

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FichierOperation } from '@lib/models/generated/graphql';
import { DocumentsService } from '@lib/services/documents.service';

@Injectable({
  providedIn: 'root',
})
export class FichierOperationsDownloadService {
  private selection: number[] = [];
  private files: FichierOperation[] = [];
  private operationLabel: string;
  constructor(
    private documentService: DocumentsService,
    private datepipe: DatePipe
  ) {}
  setFiles(files: FichierOperation[], operationLabel: string) {
    this.files = files.filter(f => f.fileId);
    this.operationLabel = operationLabel;
    // reset selection when set new files
    this.selection = [];
  }
  getFilesCount() {
    return this.files.length;
  }
  getSelectionCount() {
    return this.selection.length;
  }
  toggleFileSelection(arg0: number) {
    if (this.selection.includes(arg0)) {
      this.selection = this.selection.filter(id => id !== arg0);
    } else {
      this.selection.push(arg0);
    }
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection = [];
    } else {
      const ids = this.files?.map(f => f.id);
      if (ids) {
        this.selection = [...ids];
      }
    }
  }
  isAllSelected(): boolean {
    const ids = this.files?.map(f => f.id);
    if (JSON.stringify(ids) == JSON.stringify(this.selection)) {
      return true;
    }
    return false;
  }
  hasSelected(): boolean {
    return this.selection.length > 0;
  }

  isIncluded(fileId: number): boolean {
    return this.selection.includes(fileId);
  }

  async onDownloadSelectedFiles() {
    if (this.hasSelected()) {
      let zipName = this.operationLabel ?? 'operation';
      zipName += '_' + this.datepipe.transform(new Date(), 'MM-dd-HH-mm');
      await this.documentService.downloadMultipleFichierOperations(
        this.files.filter(f => this.selection.includes(f.id)),
        zipName
      );
    }
  }
}

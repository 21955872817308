import { Component, Input } from '@angular/core';
import { OperationSpecificData } from 'projects/box-lib/src/lib/models/donees-specifiques.model';
import { Operation } from 'projects/box-lib/src/lib/models/generated/graphql';
import { ApplicationInsightsService } from 'projects/box-lib/src/lib/services/ApplicationInsightService';

@Component({
  selector: 'lib-commentaire-cell',
  templateUrl: './commentaire-cell.component.html',
  styleUrls: ['./commentaire-cell.component.scss'],
})
export class CommentaireCellComponent {
  @Input({ required: true }) operation: Operation;
  commentaire: string | undefined;

  ngOnInit(): void {
    this.getCommentaire();
  }

  constructor(private loggerService: ApplicationInsightsService) {}
  getCommentaire() {
    try {
      const specificData: OperationSpecificData = JSON.parse(this.operation?.donneesSpecifiques);
      this.commentaire = specificData.commentaire;
    } catch (error: any) {
      const newError = new Error(`Données Spécifiques : JSON Parsing Error for operation ${this.operation?.id}`, error);
      this.loggerService.logException(newError);
    }
  }
}

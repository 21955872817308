<app-root-document-viewer
  [documentUrl]="documentUrl"
  (onValidate)="validate()"
  (onCancel)="close()"
  [isValidateDisabled]="isValidateDisabled()"
  [validateButtonLabel]="validateButtonLabel">
  <div class="flex-vertical gap-24" left-panel>
    <h5 class="padding-24">Ajouter des documents depuis mon ordinateur</h5>
    <div>
      <app-file-item
        *ngFor="let fileInfo of filesInfo"
        [fileName]="fileInfo.file.name"
        [label]="fileInfo?.denomination ?? fileInfo?.boxDocumentType?.key"
        [selected]="selectedFileInfo?.url === fileInfo.url"
        [status]="getStatus(fileInfo)"
        (click)="selectFile(fileInfo)"></app-file-item>
    </div>
  </div>

  <ng-container right-panel>
    <lib-document-viewer-header
      [documentName]="!typeSelectable ? typeLabel ?? '' : 'Qualifier un document'"
      [fileName]="selectedFileInfo?.file?.name"
      (close)="close()">
    </lib-document-viewer-header>

    <oui-autocomplete
      #typeDocumentUpload
      label="Type de document"
      [control]="boxDocumentTypeControl"
      [options]="boxDocumentTypeListOptions"
      class="width-100pc"></oui-autocomplete>

    <oui-textfield
      label="Saisir un nouveau type de document"
      [control]="denominationControl"
      *ngIf="denominationSelectable"></oui-textfield>

    <oui-select
      label="Enregistrer dans les documents de"
      [control]="investisseurControl"
      [options]="investisseurListOptions"
      class="width-100pc"
      *ngIf="!!investisseurs && investisseurs.length > 1"></oui-select>

    <oui-textarea label="Commentaire (facultatif)" [control]="commentControl"></oui-textarea>
    <oui-checkbox
      *ngIf="showAllOperationsOption"
      label="Ajouter dans toutes les opérations"
      [(checked)]="forAllOperations"></oui-checkbox>
  </ng-container>
</app-root-document-viewer>

export function openFilePicker(
  targetDivId: string,
  onFilesSelected: (files: File[]) => void,
  multiple: boolean = false,
  isConsultantFile: boolean = false
) {
  var input = document.createElement('input');
  input.type = 'file';
  if (isConsultantFile) {
    input.accept = 'image/jpeg,image/png,application/pdf';
  }
  if (multiple) {
    input.multiple = true;
  }

  input.onchange = (e: any) => {
    // convert FileList to array
    const files: File[] = new Array<File>();
    for (let i = 0; i < e.target.files.length; i++) {
      const file = e.target.files.item(i);
      if (file) {
        files.push(file);
      }
    }

    // execute process
    onFilesSelected(files);
  };
  document.getElementById(targetDivId)?.appendChild(input);

  input.click();
}

import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CreateBoxDocTypeDialogComponent } from '@lib/components/create-box-doc-type-dialog/create-box-doc-type-dialog.component';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import { BoxDocumentType, BoxDocumentTypesPaginatedCollectionSegment } from '@lib/models/generated/graphql';
import { DocumentsService } from '@lib/services/documents.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

const PAGE_ALL_DOC_TYPES = gql`
  query allBoxDocumentTypesPaginated($skip: Int, $take: Int, $search: String) {
    allBoxDocumentTypesPaginated(
      skip: $skip
      take: $take
      where: { key: { contains: $search } }
      order: [{ key: ASC }]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        gedParentTypeCode
        key
        parentGEDType {
          libelle
        }
      }
      totalCount
    }
  }
`;

@Component({
  selector: 'app-param-doc-types',
  templateUrl: './param-doc-types.component.html',
  styleUrls: ['./param-doc-types.component.scss'],
})
export class ParamTypesDocComponent {
  requestResult: { data: BoxDocumentTypesPaginatedCollectionSegment; loading: boolean } | undefined;
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  searchControl = new FormControl<any>('');

  searchSub: Subscription | undefined;

  constructor(
    private queryManager: QueryManagerService,
    private dialog: MatDialog,
    private dialogService: OuiDialogService,
    private documentService: DocumentsService
  ) {}
  ngOnInit(): void {
    this.searchSub = this.searchControl.valueChanges
      .pipe(debounceTime(350), distinctUntilChanged())
      .subscribe(search => {
        this.paginationParams = DEFAULT_PAGINATION_PARAMS;
        this.getAllDocTypes();
      });
  }

  ngOnDestroy(): void {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }
  getAllDocTypes() {
    const requestVariables = { ...this.paginationParams, search: this.searchControl.value };

    this.queryManager
      .query<{ allBoxDocumentTypesPaginated: BoxDocumentTypesPaginatedCollectionSegment }>({
        query: PAGE_ALL_DOC_TYPES,
        variables: requestVariables,
        fetchPolicy: 'network-only',
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allBoxDocumentTypesPaginated };
      });
  }

  onCreateNewBoxDocumentType() {
    let dialogRef = this.dialogService.openDialog(CreateBoxDocTypeDialogComponent, {}, 'auto', '400 px');
    dialogRef.afterClosed().subscribe((resultData: BoxDocumentType) => {
      if (resultData) {
        this.getAllDocTypes();
      }
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FichierOperation, Investisseur } from '../../models/generated/graphql';

// TODO LIB
import { FichierOperationsDownloadService } from 'projects/box-backoffice/src/services/fichier-operations-download.service';
import { FichierOperationStatutEnum } from '../../models/FichierOperationStatutEnum';
import { fileLabel } from '../../utils/fichierOperationLabel';
import { CONNAISSANCE_CLIENT_CATEGORIE_ID } from '../../models/graphqlData';
import { FichierOperationInvestisseurService } from 'projects/box-backoffice/src/services/fichier-operation-investisseur.service';

@Component({
  selector: 'lib-operation-files-list',
  templateUrl: './operation-files-list.component.html',
  styleUrls: ['./operation-files-list.component.scss'],
})
export class OperationFilesListComponent {
  @Input()
  files: FichierOperation[];

  @Input()
  checkbox: boolean = true;

  @Input({ required: true })
  audience: 'consultant' | 'gestionnaire' = 'consultant';

  @Input()
  displayStatus: boolean = true;

  @Output()
  fileClicked = new EventEmitter<FichierOperation>();

  constructor(
    protected fileService: FichierOperationsDownloadService,
    private investisseurService: FichierOperationInvestisseurService
  ) {}

  ngOnInit() {
    if (this.files) {
      this.files.sort((a, b) => (a.natureDocumentRequisId ?? Infinity) - (b.natureDocumentRequisId ?? Infinity));
    }
  }

  isIncluded(fileId: number): boolean {
    return this.fileService.isIncluded(fileId);
  }

  onfileClick(file: FichierOperation) {
    this.fileClicked.emit(file);
  }

  toggleFileSelection(fileId: number) {
    this.fileService.toggleFileSelection(fileId);
  }

  isStatusDisplayed(file: FichierOperation) {
    if (this.audience === 'consultant' && file.fichierOperationStatut.statut === FichierOperationStatutEnum.created) {
      return false;
    }

    return this.displayStatus;
  }

  getFileLabel(file: FichierOperation) {
    return fileLabel(file);
  }

  getSubLabel(file: FichierOperation) {
    const subLabel = file.natureDocumentRequis?.natureDocument?.categorie?.libelle ?? 'Document opération';
    if (file.natureDocumentRequis?.natureDocument.categorieId === CONNAISSANCE_CLIENT_CATEGORIE_ID) {
      // compare investisseurId from metadata to investisseurId from operation (in investisseurService)
      if (file.metadata?.investisseurId) {
        const investisseurs = this.investisseurService.getAllInvestisseurs();
        const investisseur = investisseurs.find(investisseur => investisseur.id === file.metadata?.investisseurId);
        if (investisseur) {
          const displayName = `${investisseur.investisseurEntite?.displayName ?? ''}`;
          return `${subLabel} - ${displayName}`;
        }
      }
    }
    return subLabel;
  }
}

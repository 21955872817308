import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { DocumentsService } from 'projects/box-lib/src/lib/services/documents.service';
import { BoxDocumentType, FileMetadata } from '../../../models/generated/graphql';
import { deepCopy } from '../../../utils/deepCopy';

@Component({
  selector: 'lib-update-doc-type',
  templateUrl: './update-doc-type.component.html',
  styleUrls: ['./update-doc-type.component.scss'],
})
export class UpdateDocumentTypeComponent {
  boxDocumentTypeControl = new FormControl<number | undefined>(undefined, [Validators.required]);
  boxDocumentTypeListOptions: OuiAutocompleteOption[] = [];

  file: FileMetadata;
  isLoading: boolean = false;
  selectedBoxType: BoxDocumentType | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { file: FileMetadata },
    private dialogRef: MatDialogRef<UpdateDocumentTypeComponent>,
    private documentService: DocumentsService
  ) {
    this.file = deepCopy(this.data.file);
    this.documentService.getBoxDocumentTypeList().then(boxDocumentTypes => {
      this.boxDocumentTypeListOptions = boxDocumentTypes
        .map(boxDocumentType => ({
          label: boxDocumentType.key,
          value: boxDocumentType.id,
        }))
        .filter(boxDocumentType => boxDocumentType.value != DEFAULT_BOX_DOCUMENT_TYPE.id); // Autre is not allowed to be selected

      this.boxDocumentTypeControl.valueChanges.subscribe(async value => {
        if (value) {
          this.selectedBoxType = await this.documentService.getBoxDocumentTypeById(value);
        }
      });
    });
  }

  onValidate() {
    if (this.selectedBoxType?.gedParentTypeCode && this.file.fileId) {
      this.isLoading = true;

      this.documentService
        .updateGedFileMetadata(this.file.fileId, {
          typeDocumentCode: this.selectedBoxType?.gedParentTypeCode,
          denomination: this.selectedBoxType?.key,
        })
        .then(result => {
          this.isLoading = false;
          this.dialogRef.close(result);
        });
    }
  }
}

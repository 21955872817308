import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationSimpleComponent } from '@lib/components/confirmation-simple/confirmation-simple.component';
import { AbstractPaginatedTableComponent } from '@lib/components/paginated-table/paginated-table.component';
import { fondEvenementielFragment } from '@lib/models/graphqlFragments';
import { FondEvenementiel } from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { FondEvenementielModalComponent } from '../fond-evenementiel-modal/fond-evenementiel-modal.component';
import { OuiDialogService } from 'omnium-ui/dialog';

const DEACTIVATE_FOND = gql`
  mutation deactivateFondEvenementiel($fondEvenementielId: Int!) {
    deactivateFondEvenementiel(fondEvenementielId: $fondEvenementielId) {
      id
    }
  }
`;
const UpdateFondEvenementiel = gql`
  mutation updateFondEvenementiel($fondEvenementielId: Int!, $libelle: String, $produitIds: [Int!]) {
    updateFondEvenementiel(fondEvenementielId: $fondEvenementielId, libelle: $libelle, produitIds: $produitIds) {
      ...fondEvenementielSimple
    }
  }
  ${fondEvenementielFragment}
`;
@Component({
  selector: 'app-fond-evenementiels-table',
  templateUrl: './fond-evenementiels-table.component.html',
  styleUrls: ['./fond-evenementiels-table.component.scss'],
})
export class FondEvenementielsTableComponent extends AbstractPaginatedTableComponent<FondEvenementiel> {
  displayedColumns = ['fondEvenementiel', 'produitFondEvenementiel', 'actionButton'];

  constructor(
    private dialog: MatDialog,
    private queryManager: QueryManagerService,
    private dialogService: OuiDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.fetchData(true);
  }

  onDeactivateFondEvenementiel(fond: FondEvenementiel) {
    const modalRef = this.dialogService.openDialog(
      ConfirmationSimpleComponent,
      {
        title: 'Supprimer un fond événementiel',
        message: 'Vous êtes sur le point de supprimer un fond événementiel. Toute suppression est définitive.',
        validateButtonLabel: 'Supprimer',
        cancelButtonLabel: 'Annuler',
      },
      'auto',
      '470px'
    );

    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        //On fait une requete de mutation pour changer l'état
        this.queryManager
          .mutate<{ deactivateFondEvenementiel: FondEvenementiel }>({
            mutation: DEACTIVATE_FOND,
            variables: { fondEvenementielId: fond.id },
          })
          .subscribe(result => {
            if (result.data?.deactivateFondEvenementiel?.id) {
              // on filtre le fond associé dans le datasource
              const fondsDataSource = this.dataSource.data.filter(
                dUser => dUser?.id !== result.data?.deactivateFondEvenementiel?.id
              );
              if (fondsDataSource) {
                // on update le datasource
                this.setTableData(fondsDataSource);
              }
              // on met a jour manuellement les données de pagination
              this.pageSize = fondsDataSource.length;
              this.totalCount = this.totalCount - 1;
              // on force le rendering du tableau car on a changer un sous-element du tableau qui n'est pas detecté
              this.table.renderRows();
            }
          });
      }
    });
  }

  onUpdateFond(fond: FondEvenementiel) {
    const modalRef = this.dialog.open(FondEvenementielModalComponent, {
      width: '660px',
      height: 'auto',
      data: {
        fondEvenementiel: fond,
        title: 'Modifier un fond événementiel',
        validateButtonLabel: 'Valider',
      },
    });
    modalRef.afterClosed().subscribe(result => {
      if (result && result.id) {
        this.fetchData(false);
      }
    });
  }
}

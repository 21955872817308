import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiChipModule } from 'omnium-ui/chip';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiSelect, OuiSelectOption } from 'omnium-ui/form-field';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiSelectFilter } from 'omnium-ui/select-filter';
import { OuiSnackbarModule, OuiSnackbarService } from 'omnium-ui/snackbar';
import { Subscription } from 'rxjs';
import { FileMetadata } from '../../../models/generated/graphql';
import { AuthService } from '../../../services/auth-service.service';
import { HistoryDisplayedRecord, HistoryService } from '../../../services/historyService';
import { HistoryComponent } from '../../history/history';

@Component({
  selector: 'lib-operation-historique-dialog',
  standalone: true,
  imports: [
    CommonModule,
    OuiDialogModule,
    OuiButtonModule,
    OuiIconModule,
    OuiSnackbarModule,
    OuiLoadingModule,
    HistoryComponent,
    OuiSelect,
    OuiSelectFilter,
    OuiChipModule,
  ],
  templateUrl: './operation-historique-dialog.component.html',
  styleUrls: ['./operation-historique-dialog.component.scss'],
})
export class OperationHistoriqueDialogComponent {
  @Output() documentOpen = new EventEmitter<FileMetadata>();

  historyDisplayedEntries: HistoryDisplayedRecord[] = [];
  filteredEntries: HistoryDisplayedRecord[] = [];
  title: string = "Historique de l'opération";

  isloading: boolean = true;
  isBackoffice: boolean = false;

  options: OuiSelectOption<string>[] = [];
  control = new FormControl<string[]>([]);

  filterSub: Subscription | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operationId: number },
    private dialogRef: MatDialogRef<OperationHistoriqueDialogComponent>,
    private historyService: HistoryService,
    private authService: AuthService,
    private snackbarService: OuiSnackbarService,
    private router: Router
  ) {}

  ngOnDestroy() {
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }
  ngOnInit() {
    this.isBackoffice = this.authService.isBackOffice();
    this.getOperationHistory().then(() => {
      this.filteredEntries = this.historyDisplayedEntries;
      const actiontexts = new Set(this.historyDisplayedEntries.map(h => h.actionText));
      this.options = [...actiontexts].map(a => ({ value: a, label: a })).sort((a, b) => a.label.localeCompare(b.label));
      this.filterSub = this.control.valueChanges.subscribe(value => {
        if (value?.length == 0) {
          this.filteredEntries = this.historyDisplayedEntries;
          return;
        }
        this.filteredEntries = this.historyDisplayedEntries.filter(h => value?.includes(h.actionText));
      });
    });
  }

  private async getOperationHistory() {
    try {
      this.historyDisplayedEntries = await this.historyService.fetchHistory(
        this.data.operationId,
        undefined,
        !this.isBackoffice
      );
      this.isloading = false;
    } catch (error) {
      this.snackbarService.open("Une erreur est survenue durant la récupération de l'historique", 'error');
      this.dialogRef.close();
    }
  }

  onRemoveChip(event: string) {
    if (!this.control.value) return;
    this.control.setValue(this.control.value.filter(v => v != event));
  }

  onBordereauLink(event: number) {
    const url = this.router.serializeUrl(this.router.createUrlTree([`/bordereau-details/${event}`]));
    window.open(url, '_blank');
  }
}

<div class="flex-vertical gap-16">
  <div class="filters-container">
    <div class="flex-horizontal gap-16">
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Partenaire)"
        label="Partenaire"
        type="list"
        panelDirection="right"
        [listOptions]="partenaireOptions"
        [control]="partenaireControl"></oui-select-filter>
      <oui-date-picker
        *ngIf="isFilterActive(operationDashboardFilterEnum.BordereauDateEnvoi)"
        [control]="dateEnvoiControl"
        label="Date d'envoi"></oui-date-picker>

      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.BordereauStatut)"
        label="Statut"
        type="list"
        panelDirection="right"
        [listOptions]="bordereauStatutOptions"
        [control]="bordereauStatutControl"></oui-select-filter>

      <!-- <oui-select-filter *ngIf="isFilterActive(operationDashboardFilterEnum.Partenaire)" label="Partenaires"
                type="list" panelDirection="left" listPlaceholder="Rechercher" [listOptions]="partenaireOptions"
                [control]="partenaireControl"></oui-select-filter> -->
    </div>
  </div>
  <div class="flex-horizontal gap-16">
    <oui-chip-list type="selection">
      <ng-container *ngFor="let filter of filterStateMap | keyvalue">
        <oui-chip *ngFor="let chip of filter.value.chips" (remove)="onRemove(chip)">{{ chip.label }}</oui-chip>
      </ng-container>
    </oui-chip-list>
    <oui-button *ngIf="showClearFiltersButton" type="text" color="primary" (click)="onclearAllFilter()"
      >Effacer les filtres
    </oui-button>
  </div>
</div>

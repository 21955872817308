import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import Papa from 'papaparse';
import { DocumentsService } from 'projects/box-lib/src/lib/services/documents.service';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  InvestisseurType,
  Operation,
  OperationFilterInput,
  OperationHistoryRecordTypes,
  OperationsPaginatedCollectionSegment,
} from '../models/generated/graphql';
import { OperationsService } from './operations.service';
import { OptionsService } from './options.service';

import { OuiSnackbarService } from 'omnium-ui/snackbar';

const fetchOperations = gql`
  query fetchOperations($skip: Int, $take: Int, $filter: OperationFilterInput) {
    allOperationsPaginated(skip: $skip, take: $take, where: $filter) {
      items {
        id
        dateCreation
        dateModification
        dateDeclaration
        montant
        typeSignaturePartenaire
        donneesSpecifiques
        commentaireGestionnaire
        motifAnnulation
        motifRejet
        motifRejetCancelCommentaire
        transactionPersonnelleStatut
        fichierOperations {
          commentaire
          reponseConsultant
          fichierOperationStatut {
            statut
          }
          metadata {
            fileName
            denomination
          }
        }
        operationConfig {
          enveloppeProduit {
            libelle
          }
          natureOperation {
            libelle
          }
        }
        statut {
          backOfficeLibelle
        }
        gestionnaire {
          displayName
          groupeGestionnaire
        }
        operationType
        operationGroupId
        operationGroup {
          operations {
            id
          }
        }
        notes {
          contenu
        }
        envoiPartenaire {
          statutEnvoi
          sendingDate
          receiveDate
          emails {
            statutEnvoi
            statutMessage
            sendingDate
          }
        }
        emails {
          statutEnvoi
          statutMessage
          sendingDate
        }
        attachedFiles {
          fileName
          denomination
        }
        fondEvenementiels {
          libelle
        }
        activeOperationStateTransitionTriggers
        operationActionRights
        investisseur {
          investisseurEntite {
            displayName
            type
          }
        }
        coInvestisseur {
          investisseurEntite {
            displayName
          }
        }
        consultant {
          personnePhysique {
            nom
            prenom
          }
        }
        produit {
          nom
        }
        contrat {
          numCommande
          numeroContrat
        }
        tags {
          libelle
        }
        historyEntry {
          type
          timestamp
          newStatutId
          previousStatutId
        }
      }
    }
  }
`;

const fetchOperationsNumber = gql`
  query fetchOperations($skip: Int, $take: Int, $filter: OperationFilterInput) {
    allOperationsPaginated(skip: $skip, take: $take, where: $filter) {
      totalCount
    }
  }
`;

const NUMBER_OF_OPERATIONS_BY_REQUEST = 20;

@Injectable({
  providedIn: 'root',
})
export class ExportCSVService {
  datepipe: DatePipe = new DatePipe('fr-FR');
  workInProgress = false;
  loadedOperations: any[] = [];
  totalNumberOfOperationsToLoad = 0;
  public readonly progressSubject = new BehaviorSubject<number | undefined>(undefined);
  columnKeysSet = new Set<string>();

  constructor(
    private queryManager: QueryManagerService,
    private documentsService: DocumentsService,
    private operationsService: OperationsService,
    private optionsService: OptionsService,
    private snackbarService: OuiSnackbarService
  ) {}

  public async getNumberOfOperations(filters: OperationFilterInput): Promise<number | undefined> {
    const result = await firstValueFrom(
      this.queryManager.query<{
        allOperationsPaginated: OperationsPaginatedCollectionSegment;
      }>({
        query: fetchOperationsNumber,
        variables: {
          filter: filters,
        },
        fetchPolicy: 'network-only',
      })
    );
    const totalNumberOfOperations = result.data.allOperationsPaginated.totalCount;

    if (!totalNumberOfOperations) {
      this.snackbarService.open(
        "Aucune opération correspondante à vos critères n'a été trouvée. L'exportation a été annulée.",
        'error',
        5000,
        { horizontal: 'left', vertical: 'bottom' }
      );
      return;
    }
    return totalNumberOfOperations;
  }

  public async startExportOperations(filters: OperationFilterInput) {
    if (this.workInProgress) {
      this.snackbarService.open('Exportation en cours. Veuillez patienter.', 'error', 5000, {
        horizontal: 'left',
        vertical: 'bottom',
      });
      return;
    }

    // init variables
    this.workInProgress = true;
    this.loadedOperations = [];
    this.progressSubject.next(0);
    const totalNumberOfOperations = await this.getNumberOfOperations(filters);
    if (!totalNumberOfOperations) {
      this.snackbarService.open(
        "Aucune opération correspondante à vos critères n'a été trouvée. L'exportation a été annulée.",
        'error',
        5000,
        { horizontal: 'left', vertical: 'bottom' }
      );
      return;
    }
    this.totalNumberOfOperationsToLoad = totalNumberOfOperations;

    // then fetch operations in sequence of batch of 20 operations
    for (let i = 0; i < this.totalNumberOfOperationsToLoad; i += NUMBER_OF_OPERATIONS_BY_REQUEST) {
      const result = await firstValueFrom(
        this.queryManager.query<{
          allOperationsPaginated: OperationsPaginatedCollectionSegment;
        }>({
          query: fetchOperations,
          variables: {
            filter: filters,
            skip: i,
            take: NUMBER_OF_OPERATIONS_BY_REQUEST,
          },
          fetchPolicy: 'network-only',
        })
      );

      if (result.data.allOperationsPaginated?.items?.length) {
        const formattedOpe = result.data.allOperationsPaginated.items.map(x => this.flattenOperation(x));
        this.loadedOperations.push(...formattedOpe);
        let progress = (i + result.data.allOperationsPaginated?.items?.length) / this.totalNumberOfOperationsToLoad;
        this.progressSubject.next(progress);
      }
    }

    const csvContent = Papa.unparse(this.loadedOperations, { delimiter: ';' });

    const date = formatDate(Date.now(), 'dd/MM/YYYYhh:mm', 'fr-FR');
    this.documentsService.downloadCSV('OperationsBox-' + date, csvContent);

    // reset persistent data at the end of the export
    this.workInProgress = false;
    this.progressSubject.next(undefined);
    this.totalNumberOfOperationsToLoad = 0;
    this.loadedOperations = [];
  }

  flattenOperation(operation: Operation) {
    let allHistoTransmissionPartenaire = operation.historyEntry
      .filter(
        e =>
          e.type === OperationHistoryRecordTypes.OperationStatutChange &&
          e.previousStatutId &&
          e.previousStatutId < 250 &&
          e.newStatutId &&
          e.newStatutId >= 250 // select operation tranfered to partenaire
      )
      .map(e => new Date(e.timestamp))
      .sort((a, b) => a.getTime() - b.getTime());

    let allHistoNC = operation.historyEntry
      .filter(
        e => e.type === OperationHistoryRecordTypes.OperationStatutChange && e.previousStatutId && e.newStatutId === 50 // select operation history new NC
      )
      .map(e => new Date(e.timestamp))
      .sort((a, b) => a.getTime() - b.getTime());

    let operationData = {
      Identifiant: operation.id ?? '',
      Investisseur: operation.investisseur?.investisseurEntite?.displayName ?? '',
      RaisonSociale:
        operation.investisseur?.investisseurEntite?.type === InvestisseurType.PersonneMorale
          ? operation.investisseur?.investisseurEntite?.displayName
          : '',
      CoInvestisseur: operation.coInvestisseur?.investisseurEntite?.displayName ?? '',
      Consultant:
        (operation.consultant?.personnePhysique[0]?.nom ?? '') +
        ' ' +
        (operation.consultant?.personnePhysique[0]?.prenom ?? ''),
      Produit: operation.produit?.nom ?? '',
      NumeroCommandeAramis: operation.contrat?.numCommande ?? '',
      NumeroContratAramis: operation.contrat?.numeroContrat ?? '',
      Montant: operation.montant ?? '',
      Statut: operation.statut.backOfficeLibelle ?? '',
      Tag: operation.tags.map(x => x.libelle).join(', '),
      DateCreation: this.datepipe.transform(operation.dateCreation) ?? '',
      DateDéclaration: this.datepipe.transform(operation.dateDeclaration) ?? '',
      DateModification: this.datepipe.transform(operation.dateModification) ?? '',
      type: operation.operationType ?? '',
      Nature: operation.operationConfig?.natureOperation.libelle ?? '',
      Enveloppe: operation.operationConfig?.enveloppeProduit?.libelle ?? '',
      Gestionnaire: operation.gestionnaire?.displayName ?? '',
      ServiceGestionnaire: operation.gestionnaire?.groupeGestionnaire ?? '',
      FondEvenementiel: operation.fondEvenementiels
        ?.map(x => x.libelle)
        .reduce((previous, current) => previous + '/' + current, ''),
      Motif_Annulation_Refus: operation.motifAnnulation
        ? this.optionsService.getLabelfromOptionValue(
            operation.motifAnnulation,
            this.optionsService.getMotifAnnulationOptions()
          ) ?? ''
        : this.optionsService.getLabelfromOptionValue(
            operation.motifRejet,
            this.optionsService.getMotifRejetOptions()
          ) ?? '',
      Commentaire_Annulation_Refus: operation.motifRejetCancelCommentaire ?? '',
      Notes: operation.notes?.map(x => x.contenu).reduce((previous, current) => previous + '/' + current, ''),
      StatutEnvoiBordereau: operation.envoiPartenaire?.statutEnvoi,
      DateEnvoiBordereau: this.datepipe.transform(operation.envoiPartenaire?.sendingDate) ?? '',
      DateReceptionBordereau: this.datepipe.transform(operation.envoiPartenaire?.receiveDate) ?? '',
      StatutEnvoiEmail: operation.emails
        ?.map(x => x.statutEnvoi.toString())
        .reduce((previous, current) => previous + '/' + current, ''),
      DateEnvoiEmail: operation.emails
        ?.map(x => this.datepipe.transform(x.sendingDate))
        .reduce((previous, current) => previous + '/' + current, ''),
      FichierOperation_Statuts: operation.fichierOperations
        ?.map(x => x.fichierOperationStatut?.statut)
        .reduce((previous, current) => previous + '/' + current, ''),
      FichierOperation_NomFichiers: operation.fichierOperations
        ?.map(x => x.metadata?.fileName)
        .reduce((previous, current) => previous + '/' + current, ''),
      FichierOperation_DenominationFichiers: operation.fichierOperations
        ?.map(x => x.metadata?.denomination)
        .reduce((previous, current) => previous + '/' + current, ''),
      typeSignaturePartenaire: operation.typeSignaturePartenaire?.toString() ?? '',
      transactionPersonnelle: operation.transactionPersonnelleStatut?.toString() ?? '',
      datePremiereTransmissionPartenaire:
        allHistoTransmissionPartenaire.length > 0
          ? this.datepipe.transform(allHistoTransmissionPartenaire[0], 'dd/MM/YYYY HH:mm') ?? ''
          : '',
      datePremierRetourNC:
        allHistoNC.length > 0 ? this.datepipe.transform(allHistoNC[0], 'dd/MM/YYYY HH:mm') ?? '' : '',
    };

    if (operation.donneesSpecifiques) {
      const donneesSpecifiques = this.operationsService.parseSpecificData(operation.donneesSpecifiques);
      operationData = { ...operationData, ...donneesSpecifiques };
    }

    for (const key in operationData) {
      this.columnKeysSet.add(key);
    }

    return operationData;
  }
}

import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { deepCopy } from 'projects/box-lib/src/lib/utils/deepCopy';
import { BoxDocumentType, FileMetadata, Investisseur, NatureDocumentRequi } from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';
import { FilesSelectorService } from '../../../services/files-selector.service';
import { getTime } from '../../../utils/codeutils';
import { ConfirmationSimpleComponent } from '../../confirmation-simple/confirmation-simple.component';
export type AddArianeResult = {
  filesInfo: FileInfo[];
  forAllOperations: boolean;
};
interface FileInfo {
  metadata: FileMetadata;
  boxDocumentType?: BoxDocumentType;
  denomination?: string;
  comment?: string;
}

@Component({
  templateUrl: './add-ariane-document-viewer.component.html',
  selector: 'app-add-ariane-document-viewer',
  styleUrls: ['./add-ariane-document-viewer.component.scss'],
})
export class AddArianeDocumentViewerComponent {
  investisseurListOptions: OuiSelectOption<number>[];
  investisseurControl: FormControl<number | null>;

  searchControl = new FormControl<string | null>('', { nonNullable: true });
  commentControl = new FormControl<string>('');

  statutSignatureControl = new FormControl<number>(0, { nonNullable: true });
  statutSignatureListOptions: OuiSelectOption<number>[] = [
    { value: 1, label: 'Signés' },
    { value: -1, label: 'Non signés' },
    { value: 0, label: 'Tous' },
  ];

  investisseurs: Investisseur[];
  fileInfoList: FileInfo[];

  filteredFileInfoList: FileInfo[] = [];

  selectedFileInfo?: FileInfo;

  //FIXME : FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
  // boxDocumentTypeControl = new FormControl<number | undefined>(undefined, [Validators.required]);
  // boxDocumentTypeListOptions: OuiAutocompleteOption[] = [];

  // denominationControl = new FormControl<string | undefined>(undefined, [Validators.required]);

  documentUrl: string = '';
  safeBlobUrl: SafeResourceUrl;
  trusteddocumentUrl: SafeResourceUrl;
  hideComment: boolean | undefined;
  isMultiple: boolean;

  forAllOperations: boolean = false;
  showAllOperationsOption: boolean = false;
  denominationSelectable: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      investisseurs: Investisseur[];
      natureDocumentRequis: NatureDocumentRequi;
      hideComment?: boolean;
      multiple?: boolean;
      showAllOperationsOption?: boolean;
    },
    private dialogRef: MatDialogRef<AddArianeDocumentViewerComponent>,
    private documentService: DocumentsService,
    private datepipe: DatePipe,
    protected selectorService: FilesSelectorService,
    private dialogService: OuiDialogService
  ) {
    this.investisseurs = data.investisseurs;
    this.showAllOperationsOption = data.showAllOperationsOption ?? false;
    this.hideComment = data.hideComment;
    this.isMultiple = !!data.multiple;
    this.investisseurListOptions = this.investisseurs.map(investissor => ({
      label: investissor.investisseurEntite?.displayName ?? ' - ',
      value: investissor.id,
    }));

    //FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
    // this.documentService.getBoxDocumentTypeList().then(boxDocumentTypes => {
    //   this.boxDocumentTypeListOptions = boxDocumentTypes.map(boxDocumentType => ({
    //     label: boxDocumentType.key,
    //     value: boxDocumentType.id,
    //   }));
    // });

    this.investisseurControl = new FormControl<number | null>(null, { nonNullable: true });
  }

  ngOnInit() {
    this.investisseurControl.setValue(this.investisseurs[0].id);
    this.setFilesInfoList(this.investisseurs[0].id).then(() => {
      this.investisseurControl.valueChanges.subscribe(investisseurId => {
        if (investisseurId == null) return;
        this.setFilesInfoList(investisseurId);
        this.filterFilesOnValueChange();
        if (this.filteredFileInfoList.length > 0) {
          //select first file
          this.selectFile(this.filteredFileInfoList[0]);
        } else {
          this.selectedFileInfo = undefined;
          this.documentUrl = '';
        }
      });
    });

    this.searchControl.valueChanges.subscribe(searchValue => {
      this.filterFilesOnValueChange();
    });

    //FIXME : waiting futur dev to be able to filter on etatSignature (currently not reliable data)
    // this.statutSignatureControl.valueChanges.subscribe(statutSignature => {
    //   this.filterFilesOnValueChange();
    // });

    this.commentControl.valueChanges.subscribe(comment => {
      if (this.selectedFileInfo) {
        this.selectedFileInfo.comment = comment ?? undefined;
      }
    });
    //FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
    // this.boxDocumentTypeControl.valueChanges.subscribe(async newBoxDocumentTypeId => {
    //   // on file type defined save it to the selected file
    //   let newBoxDocumentType: BoxDocumentType | undefined;
    //   if (newBoxDocumentTypeId) {
    //     newBoxDocumentType = await this.documentService.getBoxDocumentTypeById(newBoxDocumentTypeId);
    //   } else {
    //     newBoxDocumentType = undefined;
    //   }
    //   if (this.selectedFileInfo) {
    //     this.selectedFileInfo.boxDocumentType = deepCopy(newBoxDocumentType);

    //     if (newBoxDocumentType?.id === DEFAULT_BOX_DOCUMENT_TYPE.id) {
    //       // if type is "Autre" should define denomination
    //       this.denominationSelectable = true;
    //     } else {
    //       this.denominationSelectable = false;
    //     }
    //   }
    // });
    // this.denominationControl.valueChanges.subscribe(newDenomination => {
    //   if (newDenomination && this.selectedFileInfo?.boxDocumentType?.key) {
    //     this.documentService.getBoxDocumentTypeByDenomination(newDenomination).then(boxDocumentType => {
    //       if (this.selectedFileInfo?.boxDocumentType?.key) {
    //         if (boxDocumentType) {
    //           this.selectedFileInfo.denomination = newDenomination + ' '; // add space to avoid conflict with box types keys
    //         } else {
    //           this.selectedFileInfo.denomination = newDenomination;
    //         }
    //       }
    //     });
    //   } else {
    //     if (this.selectedFileInfo) {
    //       this.selectedFileInfo.denomination = newDenomination ?? undefined;
    //     }
    //   }
    // });
  }

  filterFilesOnSearch(filesList: FileInfo[]): FileInfo[] {
    const searchValue = this.searchControl.value?.toLocaleLowerCase() ?? null;
    if (!searchValue) {
      return filesList;
    }
    return (
      filesList.filter(
        file =>
          file.metadata.fileName?.toLocaleLowerCase().includes(searchValue!) ||
          this.generateFileLabel(file)?.toLocaleLowerCase().includes(searchValue!)
      ) ?? []
    );
  }

  filterFilesOnStatutSignature(filesList: FileInfo[]): FileInfo[] {
    //FIXME : waiting futur dev to be able to filter on etatSignature (currently not reliable data)

    // const filterLogic = this.statutSignatureControl.value;
    // switch (filterLogic) {
    //   case 1: //signes
    //     return filesList.filter(file => file.metadata.etatSignature === SignatureState.Completed);
    //   case -1: //non signes
    //     return filesList.filter(file => file.metadata.etatSignature !== SignatureState.Completed);
    //   default:
    //     return filesList;
    // }
    return filesList;
  }
  filterFilesOnValueChange() {
    this.filteredFileInfoList = this.filterFilesOnSearch(this.filterFilesOnStatutSignature(this.fileInfoList));
  }
  close(): void {
    this.dialogRef.close();
  }

  canValidate(): boolean {
    if (this.isMultiple) {
      if (this.selectorService.getSelectionCount() == 0) {
        return false;
      }
      const selection = this.selectorService.getSelection();
      const selectionFileInfo = selection?.map(metadata =>
        this.fileInfoList?.find(file => file.metadata.fileId === metadata.fileId)
      );
      if (selection) {
        //ensure denomination has been defined and is not default value for all selected files
        let result = selectionFileInfo.some(
          fileInfo =>
            fileInfo?.boxDocumentType &&
            DEFAULT_BOX_DOCUMENT_TYPE.key !== fileInfo.metadata.denomination &&
            fileInfo.denomination
        );
        return result;
      }
      return false;
    } else {
      //ensure denomination has been defined and is not default value
      return this.selectedFileInfo?.denomination !== DEFAULT_BOX_DOCUMENT_TYPE.key;
    }
  }

  validate(): void {
    // close dialog and return file metadata
    const result = this.generateFilesMetaDataResult();
    if (!(this.forAllOperations && this.data.natureDocumentRequis)) {
      this.dialogRef.close(result);
      return;
    }
    const modalRef = this.dialogService.openDialog(
      ConfirmationSimpleComponent,
      {
        title: 'Le document sera ajouté partout',
        message:
          'En cliquant sur “Valider”, ce document sera ajouté à toutes les opérations. Si un document de ce type est déjà présent dans une opération, il sera remplacé.',
        validateButtonLabel: 'Valider',
      },
      'auto',
      '414px'
    );

    modalRef.afterClosed().subscribe((isValidated: boolean) => {
      if (isValidated) {
        // this.updateMetadataAndValidate();
        this.dialogRef.close(result);
      }
    });
  }

  generateFilesMetaDataResult(): AddArianeResult | undefined {
    if (this.isMultiple) {
      const selection = this.selectorService.getSelectionFileIdList();
      if (selection) {
        const selectedOnes = this.fileInfoList.filter(
          file => file.metadata.fileId && selection.includes(file.metadata.fileId)
        );
        const output: AddArianeResult = { filesInfo: [], forAllOperations: this.forAllOperations };
        const metadataToUpdate: FileMetadata[] = [];
        selectedOnes.forEach(file => {
          output.filesInfo.push({
            metadata: { ...file.metadata, denomination: file.denomination ?? file.metadata.denomination },
            comment: file.comment,
          });
          if (file.metadata.denomination !== file.denomination) {
            metadataToUpdate.push({ ...file.metadata, denomination: file.denomination });
          }
        });
        metadataToUpdate.forEach(metadata => {
          if (metadata.fileId) {
            this.documentService.updateGedFileMetadata(metadata.fileId, metadata);
          }
        });
        return output;
      }
      return undefined;
    } else {
      const newMetadata = this.selectedFileInfo?.metadata;
      let newFileInfo = this.selectedFileInfo;
      if (newMetadata && newMetadata?.denomination !== this.selectedFileInfo?.denomination && newFileInfo) {
        // update denomination
        newMetadata.denomination = this.selectedFileInfo?.denomination;
        newFileInfo.metadata = newMetadata;
      }

      return {
        filesInfo: newFileInfo ? [newFileInfo] : [],
        forAllOperations: this.forAllOperations,
      };
    }
  }

  async selectFile(fileInfo: FileInfo) {
    const fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(fileInfo.metadata);
    if (fileCacheUrl) {
      this.documentUrl = fileCacheUrl;
    } else {
      this.documentUrl = '';
    }
    this.selectedFileInfo = fileInfo;
    //FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
    // if (fileInfo.boxDocumentType?.id === DEFAULT_BOX_DOCUMENT_TYPE.id) {
    //   this.denominationSelectable = true;
    //   this.denominationControl.setValue(fileInfo.metadata.denomination);
    // }
    // this.denominationControl.setValue(fileInfo.metadata.denomination);
    if (this.selectedFileInfo) {
      this.selectedFileInfo.denomination =
        this.selectedFileInfo?.metadata.denomination ?? fileInfo.boxDocumentType?.key;
    }

    this.commentControl.setValue(this.selectedFileInfo?.comment ?? null);
    //FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
    // this.boxDocumentTypeControl.setValue(fileInfo.boxDocumentType?.id);
  }

  generateFileLabel(fileInfo: FileInfo | undefined) {
    if (!fileInfo) {
      return 'Autre';
    }
    if (fileInfo.denomination?.trimEnd() !== DEFAULT_BOX_DOCUMENT_TYPE.key) {
      return (
        fileInfo.denomination ??
        fileInfo.metadata.denomination ??
        fileInfo.boxDocumentType?.key ??
        DEFAULT_BOX_DOCUMENT_TYPE.key
      );
    } else {
      return fileInfo.boxDocumentType?.key ?? fileInfo.metadata.denomination ?? DEFAULT_BOX_DOCUMENT_TYPE.key;
    }
  }

  async setFilesInfoList(investisseurId: Number) {
    if (investisseurId == null) return;
    const investor = this.investisseurs.find(i => i.id === investisseurId);
    let fileMetadataList = investor?.filesMetadata
      ? deepCopy(investor.filesMetadata)
          .filter(file => file.actif)
          .sort((a, b) => getTime(b.dateDeDerniereModification) - getTime(a.dateDeDerniereModification))
      : [];
    let fileInfoListTask = fileMetadataList.map(async metadata => ({
      metadata,
      boxDocumentType: await this.documentService.getBoxDocumentTypeByMetadata(metadata),
      denomination: metadata.denomination ?? undefined,
    }));

    this.fileInfoList = [];
    for (const task of fileInfoListTask) {
      let result = await task;
      this.fileInfoList.push(result);
    }
    this.selectorService.setFiles(fileMetadataList);
    if (this.fileInfoList.length > 0) {
      this.selectFile(this.fileInfoList[0]);
    }
  }
}

<div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-24 gap-36">

    <div class="flex-horizontal flex-center gap-24">
        <oui-textfield label="Recherche par raison sociale ou personne physique" [control]="searchControl"
            class="width-100pc"></oui-textfield>
        <oui-select label="Statut" [options]="statusOptions" [control]="statusControl"
            *ngIf="config =='consultant'"></oui-select>
    </div>
    <lib-creation-personne-morale-table [receivedData]="requestResult" (onRefresh)="getAllCreationPersonneMorales()"
        [columnSelection]="columnSelection"
        (onDeclareSouscription)="declareSouscription($event)"
        [withRefresh]="config === 'backoffice'"></lib-creation-personne-morale-table>
</div>
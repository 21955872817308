<div oui-dialog-title>
  Déclarer un document manquant
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>
<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <div class="body-1 color-on-surface-medium">{{ bodyText }}</div>
    <oui-autocomplete
      label="Type du document"
      [control]="boxDocumentTypeControl"
      [options]="boxDocumentTypeListOptions"
      class="width-100pc top-margin-8"></oui-autocomplete>
    <div class="min-height-250 width-100pc flex-horizontal">
      <oui-textarea
        class="width-100pc flex-none"
        label="Commentaire"
        [control]="commentaireControl"
        placeholder="Commentaire"></oui-textarea>
    </div>
  </div>
</oui-dialog-content>
<oui-dialog-actions>
  <oui-button type="text" class="flex-none" (click)="cancel()"> Annuler </oui-button>
  <oui-button type="contained" class="flex-none" (click)="validate()" [disabled]="!isValid()"> Valider </oui-button>
</oui-dialog-actions>

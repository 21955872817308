<div class="footer flex-none background-white">
  <div class="flex-horizontal height-100pc gap-16">
    <oui-button class="align-self-center flex-none" type="text" iconPrefix="arrow_back" (click)="onPreviousStepClick()">
      {{ previousStepButtonText }}
    </oui-button>
    <div class="flex-auto"></div>
    <oui-button *ngIf="isSaveDisplayed" class="align-self-center flex-none" type="text" (click)="onSaveAndExit()">
      Enregistrer et quitter
    </oui-button>
    <oui-button
      class="align-self-center flex-none"
      type="contained"
      [iconSuffix]="nextStepButtonIcon ? 'arrow_forward' : undefined"
      (click)="onNextStepClick()"
      [disabled]="isNextStepDisabled">
      {{ nextStepButtonText }}
    </oui-button>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { gql } from '@apollo/client/core';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { NatureOperation, NatureOperationFilterInput } from '../../models/generated/graphql';
const allNaturesQuery = gql`
  query allNatureOperations($filter: NatureOperationFilterInput) {
    allNatureOperations(where: $filter) {
      id
      libelle
    }
  }
`;

@Component({
  selector: 'app-search-nature-autocomplete',
  templateUrl: './search-nature-autocomplete.component.html',
  styleUrls: ['./search-nature-autocomplete.component.scss'],
})
export class SearchNatureAutocompleteComponent {
  @Input() currentNatureId: number | undefined;
  @Input() isRequired: boolean = false;
  @Input() isSouscriptionIncluded: boolean = false;
  @Input() isDisabled: boolean = true;
  @Input() enveloppeId: number | undefined;

  @Output() onNatureSelected = new EventEmitter<number | null>();

  allNatures: NatureOperation[];

  natureFilter: NatureOperationFilterInput;

  natureAutocompleteOptions: OuiAutocompleteOption[] = [];
  natureControl: FormControl;
  initNatureLibelle: string | undefined;

  isLoading: boolean = false;
  constructor(private queryManager: QueryManagerService) {
    this.natureFilter = { and: [] };
  }

  ngOnInit() {
    this.isLoading = true;
    // init the control
    this.natureControl = new FormControl<number | undefined>(this.currentNatureId);
    // build filter
    this.natureFilter.and = [];
    if (!this.isSouscriptionIncluded) {
      this.natureFilter.and.push({ id: { neq: 1 } }); // souscription has id = 1;
    }
    if (this.enveloppeId) {
      this.natureFilter.and.push({ operationConfigs: { some: { enveloppeId: { eq: this.enveloppeId } } } });
    }
    this.fetchAllNatures(this.natureFilter).then(() => {
      // set control value
      this.natureControl.setValue(this.currentNatureId);
      this.natureControl.updateValueAndValidity();
      // set control validators
      if (this.isRequired) {
        this.natureControl.setValidators(Validators.required);
      }

      this.isLoading = false;

      //subscribe to control
      this.natureControl.valueChanges.pipe(distinctUntilChanged()).subscribe(newNatureId => {
        if (newNatureId && Number.isInteger(newNatureId)) {
          if (this.allNatures) {
            const newNature = this.allNatures.find(nature => nature.id === newNatureId);
            if (newNature) {
              this.onNatureSelected.emit(newNatureId);
            }
          }
        } else {
          this.onNatureSelected.emit(null);
          if (this.allNatures && newNatureId != null) {
            const possibleNatures = this.allNatures.filter(nature =>
              nature.libelle.toUpperCase().includes(newNatureId.toString().toUpperCase())
            );
            this.setNaturesOptions(possibleNatures);
          }
        }
      });
    });
  }

  async fetchAllNatures(filter: NatureOperationFilterInput) {
    let result = await firstValueFrom(
      this.queryManager.query<{ allNatureOperations: NatureOperation[] }>({
        query: allNaturesQuery,
        variables: {
          filter: filter,
        },
      })
    );
    this.allNatures = result.data.allNatureOperations;
    this.setNaturesOptions(this.allNatures);
  }

  setNaturesOptions(natures: NatureOperation[]) {
    this.natureAutocompleteOptions = natures
      .map(nature => {
        return { label: nature.libelle, value: nature.id };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}

import { Interpolation } from '@angular/compiler';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['./stepper-footer.component.scss'],
})
export class StepperFooterComponent {
  @Input() isNextStepDisabled: boolean;
  @Input() previousStepButtonText: string;
  @Input() nextStepButtonText: string;
  @Input() nextStepButtonIcon: boolean = false;
  @Input() isSaveDisplayed: boolean = true;

  @Output() previousStepClicked = new EventEmitter();
  @Output() nextStepClicked = new EventEmitter();
  @Output() saveAndExitClicked = new EventEmitter();

  onPreviousStepClick() {
    this.previousStepClicked.emit();
  }
  onSaveAndExit() {
    this.saveAndExitClicked.emit();
  }

  onNextStepClick() {
    this.nextStepClicked.emit();
  }
}

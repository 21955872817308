<div class="document-name-container">
  <div class="document-name">
    <div class="headline-6">{{ documentName }}</div>
    <div *ngIf="fileName" class="file-name body-1">
      {{ fileName }}
    </div>
  </div>

  <oui-menu *ngIf="menu && menu.length > 0" [menuItems]="menu" horizontalPosition="right">
    <oui-button type="icon" color="secondary">
      <oui-icon>more_vert</oui-icon>
    </oui-button>
  </oui-menu>

  <oui-button *ngIf="showCloseButton" type="icon" color="secondary" (click)="close.emit()">
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<ng-content></ng-content>

<div *ngIf="dateAjout" class="document-date">
  Ajouté le
  {{ dateAjout | date: 'dd/MM/YYYY à HH:mm' }}
</div>

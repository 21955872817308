import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { FondEvenementiel } from '../models/generated/graphql';
import { firstValueFrom } from 'rxjs';
import { QueryManagerService } from './queryManagerService';

const fondEvenementielsQuery = gql`
  query fondEvenementielsByProductId($productId: Int!) {
    fondEvenementielsByProductId(productId: $productId) {
      id
      libelle
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class FondsEvenementielsService {
  constructor(private queryManager: QueryManagerService) {}

  async getfondEvenementielsByProductId(productId: number): Promise<FondEvenementiel[]> {
    const result = await firstValueFrom(
      this.queryManager.query<{ fondEvenementielsByProductId: FondEvenementiel[] }>({
        query: fondEvenementielsQuery,
        variables: {
          productId: productId,
        },
      })
    );

    return result.data.fondEvenementielsByProductId;
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { OuiTagStatus } from 'omnium-ui/tag';
import { Maybe } from '../../../models/generated/graphql';
import { FilesSelectorService } from '../../../services/files-selector.service';

export type FileItemStatus =
  | 'default'
  | 'a_qualifier'
  | 'a_controler'
  | 'a_corriger'
  | 'manquant'
  | 'conforme'
  | 'instance_partenaire';

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss'],
})
export class FileItemComponent {
  @Input()
  fileName?: Maybe<string> | undefined;

  @Input()
  label?: Maybe<string> | undefined;

  @Input()
  fileId?: Maybe<string> | undefined;

  @Input()
  withCheckBox: boolean = false;

  @Input()
  @HostBinding('class.selected')
  selected: boolean = false;

  private _status: FileItemStatus = 'default';
  public get status(): FileItemStatus {
    return this._status;
  }
  @Input()
  public set status(value: FileItemStatus) {
    this._status = value;
    this.initFileStatus();
  }

  checkboxControl = new FormControl<boolean>(false, { nonNullable: true });

  displayStatus: boolean = false;

  @HostBinding('attr.tagStatus')
  tagStatus: OuiTagStatus = 'pending';
  statusLabel: string = '';
  icon: string = 'draft';

  constructor(protected selectorService: FilesSelectorService) {}

  initFileStatus() {
    this.displayStatus = true;

    switch (this.status) {
      case 'a_controler':
        this.statusLabel = 'À contrôler';
        this.tagStatus = 'todo';
        this.icon = 'description';
        break;
      case 'a_qualifier':
        this.statusLabel = 'À qualifier';
        this.tagStatus = 'todo';
        this.icon = 'draft';
        break;
      case 'a_corriger':
        this.statusLabel = 'À corriger';
        this.tagStatus = 'to-correct';
        this.icon = 'scan_delete';
        break;
      case 'conforme':
        this.statusLabel = 'Conforme';
        this.tagStatus = 'completed';
        this.icon = 'task';
        break;
      case 'instance_partenaire':
        this.statusLabel = 'Instance partenaire';
        this.tagStatus = 'to-correct';
        this.icon = 'scan_delete';
        break;
      case 'manquant':
        this.statusLabel = 'Manquant';
        this.tagStatus = 'to-correct';
        this.icon = 'unknown_document';
        break;
      default:
        this.statusLabel = '';
        this.tagStatus = 'pending';
        this.icon = 'draft';
        this.displayStatus = false;
    }
  }

  isIncluded(fileId: string): boolean {
    return this.selectorService.isIncluded(fileId);
  }

  toggleFileSelection(fileId: string) {
    this.selectorService.toggleFileSelection(fileId);
  }
}

import { Component } from '@angular/core';
import { ConfirmationSimpleComponent } from '../confirmation-simple/confirmation-simple.component';

@Component({
  selector: 'lib-confirmation-checkbox',
  templateUrl: './confirmation-checkbox.component.html',
  styleUrls: ['./confirmation-checkbox.component.scss'],
})
export class ConfirmationCheckboxComponent extends ConfirmationSimpleComponent {
  checked: boolean = false;
}

import { Component, ViewChild } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import {
  ConsultantDashboardColumn,
  EnveloppeDashboardColumn,
  GestionnaireDashboardColumn,
  InvestisseurDashboardColumn,
  MAJDashboardColumn,
  MontantDashboardColumn,
  NatureDashboardColumn,
  OperationDashboardColumn,
  ProduitDashboardColumn,
  StatutBackOfficeDashboardColumn,
} from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import { OuiTabGroup } from 'omnium-ui/tabs';
import { delay } from 'rxjs';

@Component({
  selector: 'app-bordereaux-desk',
  templateUrl: './bordereaux-desk.component.html',
  styleUrls: ['./bordereaux-desk.component.scss'],
})
export class BordereauxDeskComponent {
  @ViewChild('tabGroup') tabGroup: OuiTabGroup;
  columnSelection: OperationDashboardColumn[] = [
    new NatureDashboardColumn(),
    new ConsultantDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new GestionnaireDashboardColumn(),
    new MAJDashboardColumn(),
    new StatutBackOfficeDashboardColumn(),
  ];

  filterSelection: DashboardFilterEnum[] = [];
  tabIndex: number;
  constructor(private route: ActivatedRoute) {}
}

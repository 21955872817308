<div class="flex-vertical gap-32">
  <app-file-status-accordion
    *ngIf="fichiersAControler && fichiersAControler.length"
    [audience]="mode"
    [files]="fichiersAControler"
    [title]="'À contrôler'"
    [titleColor]="'alert'"
    [checkbox]="checkbox"
    [withValidateAll]="true"
    (validateAllClicked)="onValidateAllToControlClick()"
    (fileClicked)="onfileClick($event)">
  </app-file-status-accordion>
  <app-file-status-accordion
    *ngIf="fichiersNonConformes && fichiersNonConformes.length"
    [audience]="mode"
    [files]="fichiersNonConformes"
    [title]="fichiersNonConformesTitle"
    [titleColor]="'error'"
    [checkbox]="checkbox"
    (fileClicked)="onfileClick($event)">
  </app-file-status-accordion>
  <app-file-status-accordion
    *ngIf="fichiersNonConformesTraitees && fichiersNonConformesTraitees.length"
    [audience]="mode"
    [files]="fichiersNonConformesTraitees"
    [title]="fichiersUpdatedTitle"
    [titleColor]="'transition'"
    [checkbox]="checkbox"
    (fileClicked)="onfileClick($event)">
  </app-file-status-accordion>
  <app-file-status-accordion
    *ngIf="fichiersConformes && fichiersConformes.length"
    [audience]="mode"
    [files]="fichiersConformes"
    [title]="'Conformes'"
    [titleColor]="'valid'"
    [checkbox]="checkbox"
    (fileClicked)="onfileClick($event)">
  </app-file-status-accordion>
</div>

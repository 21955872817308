import { Component, HostBinding, Input } from '@angular/core';
import { Operation } from 'projects/box-lib/src/lib/models/generated/graphql';
import { AuthService } from 'projects/box-lib/src/lib/services/auth-service.service';

@Component({
  selector: 'lib-investisseur-cell',
  templateUrl: './investisseur-cell.component.html',
  styleUrls: ['./investisseur-cell.component.scss'],
})
export class InvestisseurCellComponent {
  @Input() operation: Operation;
  @Input() displayName: string | undefined | null;

  @HostBinding('attr.statut')
  get statut(): 'default' | 'error' {
    const statutId = this.operation.statutId;

    if (!this.isBackOffice) {
      if (statutId >= 50 && statutId <= 51) {
        return 'error';
      }
    }

    return 'default';
  }

  protected isBackOffice: boolean;

  constructor(authService: AuthService) {
    this.isBackOffice = authService.isBackOffice();
  }
}

import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';

@Component({
  selector: 'lib-validate-all-operation-doc-dialog',
  templateUrl: './validate-all-operation-doc-dialog.component.html',
  styleUrls: ['./validate-all-operation-doc-dialog.component.scss'],
})
export class ValidateAllOperationDocDialogComponent {
  gedParentTypeCode: string | undefined;
  key: string | undefined;

  gedDocumentTypeControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  gedDocumentTypeListOptions: OuiAutocompleteOption[] = [];
  boxDocumentTypeControl: any;
  boxDocumentTypeListOptions: any;

  constructor(private dialogRef: MatDialogRef<ValidateAllOperationDocDialogComponent>) {}
  async onValidate() {
    this.dialogRef.close(true);
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { Investisseur, Operation } from 'projects/box-lib/src/public-api';

const GET_INVESTISSEUR_CONSULTANT = gql`
  query getInvestisseurConsultant($investisseurId: Long!) {
    investisseurById(id: $investisseurId) {
      id
      code
      investisseurConsultant(where: { actif: { eq: 1 } }) {
        consultant {
          id
          personnePhysique {
            nom
            prenom
          }
        }
      }
    }
  }
`;

const UPDATE_CONSULTANT = gql`
  mutation updateConsultantInOperation($operationId: Int!, $consultantId: Long!) {
    updateConsultantInOperation(operationId: $operationId, consultantId: $consultantId) {
      id
    }
  }
`;
type DisplayableConsultant = {
  id: number;
  displayName: string;
};
@Component({
  selector: 'lib-update-consultant-dialog',
  templateUrl: './update-consultant-dialog.component.html',
  styleUrls: ['./update-consultant-dialog.component.scss'],
})
export class UpdateConsultantDialogComponent {
  investisseurId: number;
  operationId: number;
  displayableConsultants: DisplayableConsultant[] = [];

  constructor(
    private queryManager: QueryManagerService,
    @Inject(MAT_DIALOG_DATA) data: { operationId: number; investisseurId: number },
    private dialogRef: MatDialogRef<UpdateConsultantDialogComponent>
  ) {
    this.investisseurId = data.investisseurId;
    this.operationId = data.operationId;
  }

  ngOnInit(): void {
    this.queryManager
      .query<{ investisseurById: Investisseur }>({
        query: GET_INVESTISSEUR_CONSULTANT,
        variables: {
          investisseurId: this.investisseurId,
        },
      })
      .subscribe(result => {
        this.displayableConsultants = result.data?.investisseurById.investisseurConsultant.map(c => ({
          id: c.consultant.id,
          displayName: `${c.consultant.personnePhysique[0]?.nom} ${c.consultant.personnePhysique[0]?.prenom}`,
        }));
      });
  }

  onSelectConsultant(consultantId: number) {
    this.queryManager
      .mutate<{
        updateConsultantInOperation: Operation;
      }>({ mutation: UPDATE_CONSULTANT, variables: { operationId: this.operationId, consultantId: consultantId } })
      .subscribe(result => {
        if (result.data?.updateConsultantInOperation?.id) {
          this.dialogRef.close(consultantId);
        }
      });
  }
}

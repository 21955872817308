import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { OuiIconModule } from 'omnium-ui/icon';
import { FileMetadata } from '../../models/generated/graphql';
import { HistoryDisplayedRecord } from '../../services/historyService';

@Component({
  selector: 'lib-history',
  standalone: true,
  imports: [CommonModule, MatDialogModule, OuiIconModule],
  templateUrl: './history.html',
  styleUrls: ['./history.scss'],
})
export class HistoryComponent {
  @Input({ required: true }) records: HistoryDisplayedRecord[];

  @Output() documentOpen = new EventEmitter<FileMetadata>();
  @Output() bordereauLink = new EventEmitter<number>();

  constructor() {}

  onFileLinkClick(fileMetadata?: FileMetadata) {
    if (fileMetadata) {
      this.documentOpen.emit(fileMetadata);
    }
  }
  onBordereauLink(bordereauId?: number) {
    if (bordereauId) {
      this.bordereauLink.emit(bordereauId);
    }
  }
}

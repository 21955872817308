import { Injectable } from '@angular/core';
import { gql, Mutation } from 'apollo-angular';
import { ProduitExtension } from '../models/generated/graphql';

export type ChangeProduitSouscriptionPossibleResponse = {
  updateProduitExtensionSouscriptionPossible: ProduitExtension;
};

@Injectable({
  providedIn: 'root',
})
export class ChangeProduitSouscriptionPossible extends Mutation<ChangeProduitSouscriptionPossibleResponse> {
  override document = gql`
    mutation updateProduitExtensionSouscriptionPossible($produitId: ID!, $newValue: Boolean!) {
      updateProduitExtensionSouscriptionPossible(produitId: $produitId, newSouscriptionPossibleValue: $newValue) {
        id
        libelle
        souscriptionPossible
      }
    }
  `;
}

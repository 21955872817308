<div oui-dialog-title>
  Exporter les opérations
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-vertical flex-start gap-16">
    <div style="white-space: pre-line" class="body-1 color-on-surface-emphasis">
      Exportez la liste des opérations dans un fichier Excel ({{ numberOfOperations }} opérations)
    </div>
  </div>
  <div *ngIf="isLoading" class="flex-horizontal">
    <div class="flex-1"></div>
    <div class="text-center flex-none top-margin-16">
      <oui-loading></oui-loading>
      <div class="top-margin-8">
        {{ exportService.progressSubject | async | percent }}
      </div>
    </div>
    <div class="flex-1"></div>
  </div>
</oui-dialog-content>

<oui-dialog-actions class="padding-right-24 padding-bottom-24">
  <div class="flex-1"></div>
  <oui-button type="text" class="flex-none" (click)="cancel()" [disabled]="exportService.workInProgress">
    Annuler
  </oui-button>
  <oui-button
    type="contained"
    class="flex-none padding-left-8"
    (click)="validate()"
    [disabled]="exportService.workInProgress">
    Exporter
  </oui-button>
</oui-dialog-actions>

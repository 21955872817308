import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import {
  BackOfficeMember,
  BackOfficeMemberSortInput,
  BackOfficeMembersPaginatedCollectionSegment,
} from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { UtilisateursTableComponent } from 'src/app/components/parameters/param-utilisateurs/products-utilisateurs/utilisateurs-table.component';

const PAGE_ALL_USERS = gql`
  query allBackOfficeMembersPaginated(
    $skip: Int
    $take: Int
    $where: BackOfficeMemberFilterInput
    $order: [BackOfficeMemberSortInput!]
  ) {
    allBackOfficeMembersPaginated(skip: $skip, take: $take, where: $where, order: $order) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        tokenId
        displayName
        groupeGestionnaire
        isAdmin
        withGroupeAccessExtended
      }
      totalCount
    }
  }
`;

@Component({
  selector: 'app-param-utilisateurs',
  templateUrl: './param-utilisateurs.component.html',
  styleUrls: ['./param-utilisateurs.component.scss'],
})
export class ParamUtilisateursComponent {
  dataSource = new MatTableDataSource<BackOfficeMember>([]);

  searchControl = new FormControl<any>('');
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { data: BackOfficeMembersPaginatedCollectionSegment; loading: boolean } | undefined;

  sortInput: BackOfficeMemberSortInput | undefined;

  @ViewChild(UtilisateursTableComponent) UtilisateurTable: UtilisateursTableComponent;

  constructor(private queryManager: QueryManagerService) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(search => {
      this.getAllUtilisateurs();
    });

    this.getAllUtilisateurs();
  }

  updateSortCriteria($event: BackOfficeMemberSortInput | undefined) {
    this.sortInput = $event;
    this.getAllUtilisateurs();
  }

  buildOrderCriteria(): BackOfficeMemberSortInput[] {
    if (this.sortInput) {
      return [this.sortInput];
    }
    return [];
  }

  getAllUtilisateurs(): void {
    const requestVariables = {
      ...this.paginationParams,
      where: { displayName: { contains: this.searchControl.value } },
      order: this.buildOrderCriteria(),
    };
    this.queryManager
      .query<{ allBackOfficeMembersPaginated: BackOfficeMembersPaginatedCollectionSegment }>({
        query: PAGE_ALL_USERS,
        variables: requestVariables,
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allBackOfficeMembersPaginated };
      });
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-gestionnaire-delete',
  templateUrl: './confirm-gestionnaire-delete.component.html',
  styleUrls: ['./confirm-gestionnaire-delete.component.scss'],
})
export class ConfirmGestionnaireDeleteComponent {
  constructor(private dialogRef: MatDialogRef<ConfirmGestionnaireDeleteComponent>) {}

  cancel() {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close(true);
  }
}

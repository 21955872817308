<div oui-dialog-title>
  {{ dialogTitle }}
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-stepper
  oui-dialog-stepper
  [steps]="steps"
  [displayNumbers]="true"
  direction="horizontal"
  class="padding-lateral-24"
  (currentStepChange)="onStepChange($event)"
  (stepStateChange)="onStepStateChange($event)"
  #stepper></oui-stepper>

<oui-dialog-content class="color-on-surface-emphasis">
  <ng-container *ngIf="this.stepper?.currentStep?.title == DOCUMENT_STEP_TITLE">
    <div class="flex-vertical">
      <div class="padding-bottom-8">Sélectionnez les documents à envoyer au partenaire</div>
      <div class="flex-horizontal align-self-center">
        <oui-loading size="small" *ngIf="!files?.length"></oui-loading>
      </div>
      <div class="flex-horizontal gap-16 body-2">
        <div class="padding-16 flex-horizontal gap-8" *ngIf="files && files.length > 0">
          <oui-checkbox
            (checkedChange)="toggleAllRows()"
            [checked]="selection.length > 0 && isAllSelected()"
            [indeterminate]="selection.length > 0 && !isAllSelected()">
          </oui-checkbox>
          {{ selection.length }}
          <span *ngIf="selection.length > 1">documents sélectionnés</span>
          <span *ngIf="selection.length < 2">document sélectionné</span>
        </div>
      </div>
      <div class="flex-vertical rounded with-border">
        <div
          *ngFor="let file of files; let eltIndex = index"
          class="flex-horizontal height-56"
          [ngClass]="eltIndex > 0 ? 'with-border-top' : ''">
          <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
            <oui-checkbox
              [checked]="selection.includes(file.identifier)"
              (checkedChange)="toggleFileSelection(file.identifier)"></oui-checkbox>
            {{ file.denomination }}
          </div>
          <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
            {{ file.filename }}
          </div>
          <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-horizontal gap-16">
            {{ file.sizeInMB }} Mo
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="this.stepper?.currentStep?.title == MAIL_STEP_TITLE">
    <div class="flex-vertical flex-start gap-24">
      <oui-textfield type="email" label="De" [control]="mailFromControl" class="width-100pc"> </oui-textfield>
      <oui-textfield type="email" label="A" [control]="mailToControl" class="width-100pc"> </oui-textfield>
      <oui-textfield type="email" label="Cc" [control]="mailCcControl" class="width-100pc"> </oui-textfield>
      <oui-textfield type="text" label="Objet" [control]="mailObjectControl" class="width-100pc"> </oui-textfield>
      <oui-textarea label="Message" [control]="mailContentControl" class="width-100pc"> </oui-textarea>
    </div>
  </ng-container>
</oui-dialog-content>
<oui-dialog-actions>
  <ng-container *ngIf="this.stepper?.currentStep?.title == DOCUMENT_STEP_TITLE">
    <oui-button type="text" (click)="onCancel()"> Annuler </oui-button>
    <oui-button
      type="contained"
      (click)="onNextStepClick()"
      [disabled]="selection.length == 0"
      iconSuffix="arrow_forward">
      Etape suivante
    </oui-button>
  </ng-container>

  <ng-container *ngIf="this.stepper?.currentStep?.title == MAIL_STEP_TITLE">
    <oui-button type="text" iconPrefix="arrow_back" color="primary" (click)="onPreviousStepClick()">
      Etape précédente
    </oui-button>
    <div class="flex-1"></div>
    <oui-button class="align-self-center" type="text" (click)="onCancel()"> Annuler </oui-button>
    <oui-button
      class="align-self-center"
      type="contained"
      (click)="onSendEmailClick()"
      [disabled]="!mailToControl.valid || !mailContentControl.valid || !mailObjectControl.valid">
      Envoyer l'e-mail
    </oui-button>
  </ng-container>
</oui-dialog-actions>

<div class="flex-vertical gap-16 width-100pc">
  <div>
    {{ totalCount }} <span *ngIf="totalCount > 1">types documents</span
    ><span *ngIf="totalCount < 2">type document</span>
  </div>
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="typesDocument">
          <th mat-header-cell *matHeaderCellDef>Type document Box</th>
          <td mat-cell *matCellDef="let element">{{ element?.key }}</td>
        </ng-container>
        <ng-container matColumnDef="parentGedLibelle">
          <th mat-header-cell *matHeaderCellDef>Type GED associé</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.parentGEDType?.libelle }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>

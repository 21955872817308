import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ApplicationInsightsService } from 'projects/box-lib/src/lib/services/ApplicationInsightService';

/**
 * Error handler for transfering errors to ApplicationInsights
 */
@Injectable()
export class ApplicationInsightsErrorHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  override handleError(error: any): void {
    // injecting ApplicationInsightService via injector to avoid infinite loop in case
    // error is with service itself
    this.injector.get<ApplicationInsightsService>(ApplicationInsightsService).logException(error);
    super.handleError(error);
    //FIXME: add any customer data here?
  }
}

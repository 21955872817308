export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function getTime(date?: Date | string | number): number {
  if (date === null || date === undefined) {
    return 0;
  }
  if (date instanceof Date) {
    return date.getTime();
  } else {
    const newDate = new Date(date);
    if (isNaN(newDate.getTime())) {
      return 0;
    } else {
      return newDate.getTime();
    }
  }
}

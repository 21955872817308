<div oui-dialog-title>
  <div>
    Affecter à un gestionnaire
    <div class="body-1 color-on-surface-medium">
      {{ subtitle }}
    </div>
  </div>
  <oui-button type="icon" [oui-dialog-close]>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <oui-searchbar class="padding-24" placeholder="Rechercher un gestionnaire" [control]="searchControl"></oui-searchbar>

  <lib-gestionnaire-activity-table
    class="width-100pc height-100pc"
    [receivedData]="requestResult"
    (requestNewPage)="getAllGestionnaireWorkloads()"
    [(paginationParams)]="paginationParams"
    (onSelectedGestionnaire)="onSelectedGestionnaire($event)">
  </lib-gestionnaire-activity-table
></oui-dialog-content>

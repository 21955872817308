import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { OuiSelectOption } from 'omnium-ui/form-field';
import { MenuNode } from 'omnium-ui/shared';
import { NonConformiteFichier } from '../../models/generated/graphql';
import { OptionsService } from '../../services/options.service';
import { CommonModule } from '@angular/common';
import { OuiAccordionModule } from 'omnium-ui/accordion';
import { OuiMenuModule } from 'omnium-ui/menu';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiTagModule } from 'omnium-ui/tag';
import { OuiNumber } from 'omnium-ui/number';
import { OptionLabelPipe } from '../../pipes/optionLabel';

@Component({
  selector: 'app-non-conformite-fichier-card',
  standalone: true,
  imports: [
    CommonModule,
    OuiAccordionModule,
    OuiMenuModule,
    OuiButtonModule,
    OuiIconModule,
    OuiTagModule,
    OuiNumber,
    OptionLabelPipe,
  ],
  templateUrl: './non-conformite-fichier-card.component.html',
  styleUrls: ['./non-conformite-fichier-card.component.scss'],
})
export class NonConformiteFichierCardComponent {
  @Input()
  nonConformite: NonConformiteFichier;

  @Input()
  position: number;

  @Input() isShowMenu: boolean;

  @Output()
  closeNC = new EventEmitter<NonConformiteFichier>();

  @Output()
  editNC = new EventEmitter<NonConformiteFichier>();

  @Output()
  reOpenNC = new EventEmitter<NonConformiteFichier>();

  errorColor = '#E00000';
  secondaryColor = '#D5DBE5';
  categories: OuiSelectOption[];
  motifs: OuiSelectOption[];

  NcType = 'la NC';
  cardClass: string;
  textClass: string;
  iconColor: string;
  menuItems: MenuNode[] = [];
  constructor(private optionService: OptionsService) {}

  ngOnInit() {
    this.categories = this.optionService.getNonConformiteCategories();
    this.motifs = this.optionService.getNonConformiteMotifs();
    this.setClassAndItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['nonConformite'] && changes['nonConformite'].currentValue) {
      this.setClassAndItems();
    }
  }

  // menuItemsActive: MenuNode[] = [
  //   { name: `Maintenir ${this.NcType}`, icon: 'arrow_upward', disabled: true, },
  //   { name: 'Modifier', icon: 'edit', action: () => this.onEditNC() },
  //   { name: `Fermer ${this.NcType}`, icon: 'lock', action: () => this.onCloseNC() },
  // ];

  // menuItemsInactive: MenuNode[] = [
  //   { name: `Maintenir ${this.NcType}`, icon: 'arrow_upward', action: () => this.onReOpenNC() },
  //   { name: 'Modifier', icon: 'edit', disabled: true },
  //   { name: `Fermer ${this.NcType}`, icon: 'lock', disabled: true },
  // ];

  onEditNC() {
    this.editNC.emit(this.nonConformite);
  }
  onCloseNC() {
    this.nonConformite.isActive = false;
    this.closeNC.emit(this.nonConformite);
  }

  onReOpenNC() {
    this.nonConformite.isActive = true;
    this.reOpenNC.emit(this.nonConformite);
  }

  setClassAndItems() {
    this.NcType = this.nonConformite.isInstancePartenaire ? "l'instance" : 'la NC';
    const menuItemsActive: MenuNode[] = [
      { name: `Maintenir ${this.NcType}`, icon: 'arrow_upward', disabled: true },
      { name: 'Modifier', icon: 'edit', action: () => this.onEditNC() },
      { name: `Fermer ${this.NcType}`, icon: 'lock', action: () => this.onCloseNC() },
    ];

    const menuItemsInactive: MenuNode[] = [
      { name: `Maintenir ${this.NcType}`, icon: 'arrow_upward', action: () => this.onReOpenNC() },
      { name: 'Modifier', icon: 'edit', disabled: true },
      { name: `Fermer ${this.NcType}`, icon: 'lock', disabled: true },
    ];
    this.cardClass = this.nonConformite.isActive ? 'with-border-error rounded' : 'with-border-secondary rounded';
    this.textClass = this.nonConformite.isActive ? 'subtitle-2 color-error' : 'subtitle-2 color-secondary';
    this.iconColor = this.nonConformite.isActive ? this.errorColor : this.secondaryColor;
    this.menuItems = this.nonConformite.isActive ? menuItemsActive : menuItemsInactive;
  }
}

import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { OuiAutocompleteOption } from 'omnium-ui/form-field';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { DocumentsService } from 'projects/box-lib/src/lib/services/documents.service';

@Component({
  selector: 'lib-create-box-doc-type-dialog',
  templateUrl: './create-box-doc-type-dialog.component.html',
  styleUrls: ['./create-box-doc-type-dialog.component.scss'],
})
export class CreateBoxDocTypeDialogComponent {
  gedParentTypeCode: string | undefined;
  key: string | undefined;

  gedDocumentTypeControl = new FormControl<string | undefined>(undefined, [Validators.required]);
  gedDocumentTypeListOptions: OuiAutocompleteOption[] = [];
  boxDocumentTypeControl: any;
  boxDocumentTypeListOptions: any;

  constructor(
    private dialogRef: MatDialogRef<CreateBoxDocTypeDialogComponent>,
    private documentService: DocumentsService,
    private snackbarService: OuiSnackbarService
  ) {
    this.documentService.getGEDDocumentTypeList().then(gedTypes => {
      this.gedDocumentTypeListOptions = gedTypes.map(gedType => ({
        label: gedType.libelle,
        value: gedType.code,
      }));
    });
    this.gedDocumentTypeControl.valueChanges.subscribe(value => {
      this.gedParentTypeCode = value ?? undefined;
    });
  }
  async onValidate() {
    if (this.gedParentTypeCode && this.key) {
      let typeToCreate = {
        gedParentTypeCode: this.gedParentTypeCode,
        key: this.key,
      };
      let result = await this.documentService.createBoxDocumentType(typeToCreate);
      if (result) {
        this.snackbarService.open('Nouveau type de document "' + typeToCreate.key + '" créé', 'success', 7000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
        this.dialogRef.close(result);
      }
    }
  }
}

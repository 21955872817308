import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDialogModule } from 'omnium-ui/dialog';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiLoadingModule } from 'omnium-ui/loading';
import { OuiSnackbarModule, OuiSnackbarService } from 'omnium-ui/snackbar';
import { FileMetadata } from '../../../models/generated/graphql';
import { AuthService } from '../../../services/auth-service.service';
import { HistoryDisplayedRecord, HistoryService } from '../../../services/historyService';
import { HistoryComponent } from '../../history/history';

@Component({
  selector: 'lib-file-historique-dialog',
  standalone: true,
  imports: [
    CommonModule,
    OuiDialogModule,
    OuiButtonModule,
    OuiIconModule,
    OuiSnackbarModule,
    OuiLoadingModule,
    HistoryComponent,
  ],
  templateUrl: './file-historique-dialog.component.html',
  styleUrls: ['./file-historique-dialog.component.scss'],
})
export class FileHistoriqueDialogComponent {
  historyDisplayedEntries: HistoryDisplayedRecord[] = [];

  isloading: boolean = true;
  isBackoffice: boolean = false;

  @Output() documentOpen = new EventEmitter<FileMetadata>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { operationId: number; fichierOperationId: number },
    private dialogRef: MatDialogRef<FileHistoriqueDialogComponent>,
    private historyService: HistoryService,
    private authService: AuthService,
    private snackbarService: OuiSnackbarService
  ) {}

  ngOnInit() {
    this.isBackoffice = this.authService.isBackOffice();
    this.getOperationHistory();
  }

  private async getOperationHistory() {
    try {
      this.historyDisplayedEntries = await this.historyService.fetchHistory(
        this.data.operationId,
        this.data.fichierOperationId,
        !this.authService.isBackOffice()
      );
      this.isloading = false;
    } catch (error) {
      this.snackbarService.open("Une erreur est survenue durant la récupération de l'historique", 'error');
      this.dialogRef.close();
    }
  }
}

import { Injectable } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { OuiSlidePanelService } from 'omnium-ui/layout';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';
import { BoxFile, FichierOperation, FileMetadata } from '../../../models/generated/graphql';
import {
  AppendedDocumentViewerRemoveEvent,
  AppendedDocumentViewerResult,
} from '../appended-document-viewer/appended-document-viewer.component';

export type DocumentViewerInfo = {
  viewerType: 'custom' | 'simple' | 'appended-file' | 'operation';
  fileMetadata?: FileMetadata;
  boxFile?: BoxFile;
  commentaire?: string;
  documentUrl?: string;
  fichierOperation?: FichierOperation;
  customPortal?: Portal<any>;
  onCloseAppendedFileViewer?: (result: AppendedDocumentViewerResult | undefined) => void;
  onRemoveFile?: (event: AppendedDocumentViewerRemoveEvent) => void;
};

export type SimpleDocumentViewerInfo = {
  fileMetadata?: FileMetadata;
  boxFile?: BoxFile;
  commentaire?: string;
  documentUrl?: string;
};

@Injectable({
  providedIn: 'root',
})
export class BoxDocumentViewerService {
  private _fileViewerInfoSubject = new BehaviorSubject<DocumentViewerInfo | null>(null);
  public readonly fileViewerInfo$ = this._fileViewerInfoSubject.asObservable();

  public readonly viewerClosed$: Observable<void>;

  private opened: boolean = false;

  slidePanelContent?: Portal<any>;
  customSlidePanelContent?: Portal<any>;

  constructor(private slidePanelService: OuiSlidePanelService) {
    this.viewerClosed$ = this.slidePanelService.slidePanelState$.pipe(
      filter(state => state === 'closed' && this.opened),
      tap(() => this.onPanelClose()),
      map(() => {})
    );
  }

  public setDocumentViewerPortal(portal: Portal<any>) {
    this.slidePanelContent = portal;
  }

  private openDocumentViewer() {
    if (!this.slidePanelContent) {
      throw "Impossible d'ouvrir le document viewer : portal non défini. Définissez le à l'aide de la méthode setDocumentViewerPortal.";
    }

    this.slidePanelService.setSlidePanelContent(this.slidePanelContent);
    this.slidePanelService.openSlidePanel({ position: 'right', autoFocus: false, disableClose: true });

    this.opened = true;
  }

  public openCustomDocumentViewer(portal: Portal<any>) {
    this._fileViewerInfoSubject.next({
      viewerType: 'custom',
      customPortal: portal,
    });

    this.openDocumentViewer();
  }

  public closeDocumentViewer() {
    this.slidePanelService.setSlidePanelContent(null);
    this.slidePanelService.closeSlidePanel();
  }

  private onPanelClose() {
    this.opened = false;
  }

  public openSimpleDocumentViewer(fileInfo: SimpleDocumentViewerInfo): void {
    this._fileViewerInfoSubject.next({
      viewerType: 'simple',
      boxFile: fileInfo.boxFile,
      commentaire: fileInfo.commentaire,
      documentUrl: fileInfo.documentUrl,
      fileMetadata: fileInfo.fileMetadata,
    });

    this.openDocumentViewer();
  }

  public openAppendedDocumentViewer(
    fichierOperation: FichierOperation,
    onClose: (result: AppendedDocumentViewerResult | undefined) => void,
    onRemoveFile: (event: AppendedDocumentViewerRemoveEvent) => void
  ) {
    this._fileViewerInfoSubject.next({
      viewerType: 'appended-file',
      fichierOperation: fichierOperation,
      onCloseAppendedFileViewer: onClose,
      onRemoveFile: onRemoveFile,
    });

    this.openDocumentViewer();
  }

  public closeAppendedDocumentViewer(result: AppendedDocumentViewerResult | undefined) {
    this._fileViewerInfoSubject.value?.onCloseAppendedFileViewer?.(result);
    this.closeDocumentViewer();
  }

  public openOperationDocumentViewer(fichierOperation: FichierOperation) {
    this._fileViewerInfoSubject.next({
      viewerType: 'operation',
      fichierOperation: fichierOperation,
    });

    this.openDocumentViewer();
  }

  public removeFichierOperation(event: AppendedDocumentViewerRemoveEvent) {
    this._fileViewerInfoSubject.value?.onRemoveFile?.(event);
    this.closeDocumentViewer();
  }
}

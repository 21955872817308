import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OuiPaginator } from 'omnium-ui/paginator';
import { Subscription } from 'rxjs';

export interface PaginationParams {
  skip: number;
  take: number;
}

export const DEFAULT_PAGINATION_PARAMS: PaginationParams = {
  skip: 0,
  take: 20,
};

@Component({
  template: '',
})
export abstract class AbstractPaginatedTableComponent<T> implements OnChanges {
  @Input()
  receivedData: { data: any; loading: boolean } | undefined;

  @Input()
  paginationParams: PaginationParams;
  @Output()
  paginationParamsChange = new EventEmitter<PaginationParams>();

  @Output()
  requestNewPage = new EventEmitter<PaginationParams>();

  @Input()
  withLoadingOnPageChange: boolean = false;

  @ViewChild(MatTable) table: MatTable<T>;
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);

  pendingPageChange: boolean = false;

  @ViewChildren(OuiPaginator) paginators: QueryList<OuiPaginator>;
  pageSizeOptions = [20, 50, 100];
  totalCount = 0;
  pageIndex = 0;
  pageSize = DEFAULT_PAGINATION_PARAMS.take;

  noFetchOnPageChange = false;
  isLoading = false;

  paginationSubscription: Subscription;
  paginatorViewSubscription: Subscription;
  ngAfterViewInit(): void {
    if (this.paginators?.first) {
      this.susbcribeToPaginationChange(this.paginators.first);
    } else {
      this.paginatorViewSubscription = this.paginators.changes.subscribe(value => {
        if (value.first && value.first instanceof OuiPaginator) {
          this.susbcribeToPaginationChange(value.first);
        }
      });
    }
  }

  susbcribeToPaginationChange(paginator: OuiPaginator) {
    if (this.paginationSubscription) {
      this.paginationSubscription.unsubscribe();
    }
    this.paginationSubscription = paginator?.page.subscribe(value => {
      this.paginationParams = {
        skip: value.pageSize * value.pageIndex,
        take: value.pageSize,
      };

      this.paginationParamsChange.emit(this.paginationParams);

      if (!this.noFetchOnPageChange) {
        this.fetchData(this.withLoadingOnPageChange);
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['receivedData']) {
      this.receivedData = changes['receivedData'].currentValue;
      this.updateTableFromReceivedData();
    }
    if (changes['paginationParams']) {
      this.pageSize = changes['paginationParams'].currentValue.take;
      if (this.pageSize && changes['paginationParams'].currentValue.skip) {
        this.pageIndex = Math.ceil(changes['paginationParams'].currentValue.skip / this.pageSize);
      } else {
        this.pageIndex = 0;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.paginationSubscription) {
      this.paginationSubscription.unsubscribe();
    }
    if (this.paginatorViewSubscription) {
      this.paginatorViewSubscription.unsubscribe();
    }
  }
  /**
   * Fetches data from the server.
   *
   * @param {boolean} withLoading - Indicates whether to show loading indicator.
   * @return {void} This function does not return anything.
   */
  fetchData(withLoading: boolean): void {
    this.isLoading = withLoading;
    this.pendingPageChange = true;
    this.requestNewPage.emit();
  }

  protected setTableData(newData: T[]) {
    if (Array.isArray(newData)) {
      this.dataSource.data = newData.map((dataItem: any) => {
        return JSON.parse(JSON.stringify(dataItem)); // on fait un deep clone du produit pour le rendre mutable. on pourra ensuite le modifilier en delta à chaque mutation
      });
    }
  }

  updateTableFromReceivedData() {
    if (this.receivedData) {
      this.isLoading = this.receivedData.loading;
      this.pendingPageChange = false;
      if (this.receivedData.data) {
        this.totalCount = this.receivedData.data.totalCount;
        this.setTableData(this.receivedData.data.items);
      }
    }
  }
}

import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionBackOfficeCreationPMColumn,
  ConsultantCreationPMColumn,
  CreationPersonneMoraleDashboardColumn,
  DateDemandeCreationPMColumn,
  FormeSocialeCreationPMColumn,
  InvestisseurPhysiqueCreationPMColumn,
  RaisonSocialeCreationPMColumn,
} from '@lib/components/creation-personne-morale-dashboard/creation-personne-morale-dashboard-column';
import {
  ConsultantDashboardColumn,
  DeclarationDashboardColumn,
  EnveloppeDashboardColumn,
  GestionnaireDashboardColumn,
  InvestisseurDashboardColumn,
  MAJDashboardColumn,
  MontantDashboardColumn,
  NatureDashboardColumn,
  OperationDashboardColumn,
  OperationIDDashboardColumn,
  ProduitDashboardColumn,
  StatutBackOfficeDashboardColumn,
  WarningBackOfficeDashboardColumn,
} from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { OperationsDashboardComponent } from '@lib/components/operations-dashboard/operations-dashboard.component';
import { DashboardFilterEnum } from '@lib/models/dashboardFilterEnum';
import { BackOfficeMember, GroupeGestionnaire, OperationFilterInput } from '@lib/models/generated/graphql';
import { AuthService } from '@lib/services/auth-service.service';
import { UserParametersService } from '@lib/services/user-parameters.service';
import { OuiButtonToggleOption } from 'omnium-ui/button-toggle';
import {
  ALL_BACKOFFICE_FILTER,
  HISTORY_FILTER,
  TO_CONTROL_FILTER,
  TO_DISPATCH_FILTER,
  WAITING_DOCUMENTS_FILTER,
  WAITING_FOR_REPLY_FILTER,
} from '../../../utils/operation-desk-tab-config';
const SERVICE_PARAM_KEY: string = 'service-toggle';
const OPERATION_DESK_KEY: string = 'operation-desk';

@Component({
  selector: 'app-operations-desk',
  templateUrl: './operations-desk.component.html',
  styleUrls: ['./operations-desk.component.scss'],
})
export class OperationsDeskComponent {
  @ViewChild('toControlBoard', { static: false }) toControlBoard: OperationsDashboardComponent;

  serviceToggleOptions: OuiButtonToggleOption<string>[] = [
    { value: 'PSI', label: 'PSI' },
    { value: 'SVP', label: 'SVP' },
  ];

  serviceToggleControl = new FormControl<string | null>(null);
  queryFilter: OperationFilterInput = {};

  displayOperationDetails = false;
  userBackOfficeInfo: BackOfficeMember | undefined;

  columnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new NatureDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new ConsultantDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new GestionnaireDashboardColumn(),
    new MAJDashboardColumn(),
    new DeclarationDashboardColumn(),
    new StatutBackOfficeDashboardColumn(),
    new WarningBackOfficeDashboardColumn(),
  ];

  creationPMColumnSelection: CreationPersonneMoraleDashboardColumn[] = [
    new ConsultantCreationPMColumn(),
    new RaisonSocialeCreationPMColumn(),
    new FormeSocialeCreationPMColumn(),
    new InvestisseurPhysiqueCreationPMColumn(),
    new DateDemandeCreationPMColumn(),
    new ActionBackOfficeCreationPMColumn(),
  ];

  filterSelection: DashboardFilterEnum[] = [
    DashboardFilterEnum.GoToById,
    DashboardFilterEnum.Statut,
    DashboardFilterEnum.Enveloppe,
    DashboardFilterEnum.Nature,
    DashboardFilterEnum.Produit,
    DashboardFilterEnum.Montant,
    DashboardFilterEnum.Gestionnaire,
    DashboardFilterEnum.FondEvnt,
    DashboardFilterEnum.Tag,
    DashboardFilterEnum.Signature,
    DashboardFilterEnum.Investisseur,
    DashboardFilterEnum.ErrorPartenaireEmail,
    DashboardFilterEnum.CommandeAramis,
    DashboardFilterEnum.IsInvestisseurPersonneMorale,
  ];

  allDefaultQueryFilter: OperationFilterInput = {};
  waitingDocumentsDefaultQueryFilter: OperationFilterInput = {};
  toDispatchDefaultQueryFilter: OperationFilterInput = {};
  toControlDefaultQueryFilter: OperationFilterInput = {};
  waitingReturnDefaultQueryFilter: OperationFilterInput = {};
  historiqueDefaultQueryFilter: OperationFilterInput = {};

  allItemNumber: string = '';
  waitingDocumentsItemNumber: string = '';
  toDispatchItemNumber: string = '';
  toControlItemNumber: string = '';
  waitingReturnItemNumber: string = '';
  historyItemNumber: string = '';
  demandesPmItemNumber: string = '';

  enveloppesIdsFilter: number[] | undefined;

  noFetch: boolean = true;

  operationId?: number;

  tabIndex: number = 3;

  /**
   * The TABS index and filters are used in utils/operation-desk-tab-config.ts
   * If you change the tab order and/or filters, please update utils/operation-desk-tab-config.ts
   **/

  constructor(
    private router: Router,
    protected authService: AuthService,
    protected userParametersService: UserParametersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const tabIdParam = this.route.snapshot.paramMap.get('tabId');
    if (tabIdParam) {
      this.tabIndex = Number.parseInt(tabIdParam);
    }

    // get gestionnaire info
    this.authService.userBackOfficeSubject?.subscribe(user => {
      // ingnore if user is undefined (i.e. response from server not received yet)
      if (user) {
        this.userBackOfficeInfo = user;
        if (this.userBackOfficeInfo.withGroupeAccessExtended) {
          // if user is a gestionnaire with extended rights
          // nota if user do not have extended rights backend handle service filtering so nothing to do in front
          this.serviceToggleControl = new FormControl<string | null>(null);
          this.enveloppesIdsFilter = this.userBackOfficeInfo.withGroupeAccessExtended
            ? this.userBackOfficeInfo.serviceAllowedEnvelopeIds ?? undefined
            : undefined;

          this.serviceToggleControl.valueChanges.subscribe(value => {
            if (this.enveloppesIdsFilter) {
              if (
                (value == 'SVP' && this.userBackOfficeInfo?.groupeGestionnaire == GroupeGestionnaire.Svp) ||
                (value == 'PSI' && this.userBackOfficeInfo?.groupeGestionnaire == GroupeGestionnaire.Psi)
              ) {
                this.queryFilter = {
                  operationConfig: {
                    enveloppeId: {
                      in: this.enveloppesIdsFilter,
                    },
                  },
                };
              } else {
                this.queryFilter = {
                  operationConfig: {
                    enveloppeId: {
                      nin: this.enveloppesIdsFilter,
                    },
                  },
                };
              }
            }
            this.computeFilters();

            //save new value in parameters
            this.userParametersService.saveUserParam(OPERATION_DESK_KEY, SERVICE_PARAM_KEY, value);
          });

          let serviceToggleDefaultValue =
            this.userParametersService.getUserParam(OPERATION_DESK_KEY, SERVICE_PARAM_KEY) ??
            this.userBackOfficeInfo.groupeGestionnaire;
          this.serviceToggleControl.setValue(serviceToggleDefaultValue);
        } else {
          this.queryFilter = {};
          this.computeFilters();
        }
      }
    });
  }

  updateDemandesPmItemNumber(ops: number) {
    this.demandesPmItemNumber = this.formatItemsNumber(ops);
  }
  updateWaitingDocumentsItemNumber(ops: number) {
    this.waitingDocumentsItemNumber = this.formatItemsNumber(ops);
  }
  updateAllItemNumber(ops: number) {
    this.allItemNumber = this.formatItemsNumber(ops);
  }
  updateToDispatchItemNumber(ops: number) {
    this.toDispatchItemNumber = this.formatItemsNumber(ops);
  }
  updateToControlItemNumber(ops: number) {
    this.toControlItemNumber = this.formatItemsNumber(ops);
  }
  updateWaitingReturnItemNumber(ops: number) {
    this.waitingReturnItemNumber = this.formatItemsNumber(ops);
  }

  updateHistoryItemNumber(ops: number) {
    this.historyItemNumber = this.formatItemsNumber(ops);
  }
  formatItemsNumber(number: number | undefined): string {
    const itemsNumberFormat = Number.isNaN(number) ? `(0)` : `(${number})`;
    return itemsNumberFormat;
  }
  // elaborate filter to apply for each dashboard
  computeFilters(): void {
    // no id filter
    this.allDefaultQueryFilter = {
      and: [ALL_BACKOFFICE_FILTER, this.queryFilter],
    };

    // statut id == 100 match to status 'waiting document'
    this.waitingDocumentsDefaultQueryFilter = {
      and: [WAITING_DOCUMENTS_FILTER, this.queryFilter],
    };
    // statut id == 105 match to status 'to dispatch'
    this.toDispatchDefaultQueryFilter = {
      and: [TO_DISPATCH_FILTER, this.queryFilter],
    };
    // statut ids between 200 and 250 are for control
    this.toControlDefaultQueryFilter = {
      and: [TO_CONTROL_FILTER, this.queryFilter],
    };

    // statut ids between 250 and 300 are for correction for consultant
    this.waitingReturnDefaultQueryFilter = {
      and: [WAITING_FOR_REPLY_FILTER, this.queryFilter],
    };

    // statut over 400 are for operation done, cancelled, or refused
    this.historiqueDefaultQueryFilter = {
      and: [HISTORY_FILTER, this.queryFilter],
    };
    // once filters are setted authorize fetching
    if (this.noFetch) {
      this.noFetch = false;
    }
  }

  onOperationClick(operation: any) {
    this.router.navigate(['backoffice', operation.id]);
  }

  refreshToControlOperationsBoard(): void {
    this.toControlBoard.getAllOperations();
  }
}

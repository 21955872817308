import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { gql } from 'apollo-angular';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { ExcelExportComponent } from 'projects/box-lib/src/lib/components/excel-export/excel-export.component';
import { OperationsDispatcherComponent } from 'projects/box-lib/src/lib/components/operations-dashboard/operations-dispatcher/operations-dispatcher.component';
import { OperationsTableComponent } from 'projects/box-lib/src/lib/components/operations-dashboard/operations-table/operations-table.component';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { deepCopy } from 'projects/box-lib/src/lib/utils/deepCopy';
import { forkJoin } from 'rxjs';
import { DashboardFilterEnum } from '../../models/dashboardFilterEnum';
import { emailCheckFragment } from '../../models/graphqlFragments';
import {
  BackOfficeMember,
  EnveloppeProduit,
  NatureOperation,
  Operation,
  OperationFilterInput,
  OperationSortInput,
  OperationsPaginatedCollectionSegment,
  Produit,
  SortEnumType,
} from '../../models/generated/graphql';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '../paginated-table/paginated-table.component';
import { DeleteDraftConfirmComponent } from './delete-draft-confirm/delete-draft-confirm.component';
import { OperationDashboardColumn } from './operations-dashboard-columns';

const ALLOPERATIONTABLE = gql`
  query allOperationsPaginated($skip: Int, $take: Int, $filter: OperationFilterInput, $order: [OperationSortInput!]) {
    allOperationsPaginated(skip: $skip, take: $take, where: $filter, order: $order) {
      totalCount
      pageInfo {
        hasNextPage
      }
      items {
        id
        statutId
        operationType
        gestionnaireId
        montant
        operationActionRights
        commentaireGestionnaire
        gestionnaire {
          displayName
        }
        statut {
          id
          consultantLibelle
          backOfficeLibelle
        }
        produitPreset {
          id
          nom
          nomTechniqueProduitBox
          habilitationCode
          habilitationLibelle
          partenaireLibelle
          partenaireId
          enveloppeCode
          enveloppeLibelle
        }
        dateCreation
        dateModification
        dateDeclaration
        consultantId
        consultantPreset {
          id
          nom
          prenom
          habilitationCodes
        }
        donneesSpecifiques
        operationConfig {
          enveloppeId
          natureOperationId
          natureOperation {
            libelle
          }
        }
        coInvestisseurPreset {
          id
          displayName
        }
        investisseurPreset {
          id
          displayName
          type
        }
        emails {
          ...emailCheck
        }
        operationGroup {
          id
          operations {
            id
          }
        }
        tags {
          id
          libelle
        }
      }
    }
  }
  ${emailCheckFragment}
`;

@Component({
  selector: 'app-operation-dashboard',
  templateUrl: './operations-dashboard.component.html',
  styleUrls: ['./operations-dashboard.component.scss'],
})
export class OperationsDashboardComponent {
  @ViewChild(OperationsTableComponent)
  private operationTable: OperationsTableComponent;

  @Input()
  columnSelection: OperationDashboardColumn[] = [];

  @Input()
  filterSelection: DashboardFilterEnum[] = [];
  @Input()
  defaultQueryFilter: OperationFilterInput = {};

  @Input() preSelectedGestionnaireId: string | undefined;

  @Input() allProduits: Produit[];
  @Input() allNatureOperations: NatureOperation[];
  @Input() allGestionnaires: BackOfficeMember[];
  @Input() allEnveloppes: EnveloppeProduit[] = [];

  @Input() withSelects: boolean = false;
  @Input() withCSVExport: boolean = false;
  @Input() isDispatcher: boolean = false;
  @Input() dashboardKey: string;

  // input can be used to disable automatic fetch at loading
  @Input() noFetch: boolean = false;

  // used only to acces to enumns values and handle the filters
  operationDashboardFilterEnum = DashboardFilterEnum;

  @Output()
  onSelectedOperation = new EventEmitter<Operation>();
  @Output()
  onDispatchedOperationsEvent = new EventEmitter();
  @Output()
  onTotalCount = new EventEmitter<number>();

  operations: Operation[] = [];

  dataSource = new MatTableDataSource<Operation>([]);
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { data: OperationsPaginatedCollectionSegment; loading: boolean } | undefined;

  tableSortCriteria: OperationSortInput | undefined;
  requestFilter: OperationFilterInput | undefined;

  constructor(
    private queryManager: QueryManagerService,
    private dialogService: OuiDialogService,
    private snackbarService: OuiSnackbarService,
    private ouiDialog: OuiDialogService
  ) {}

  ngOnInit(): void {}

  onRowClick(operation: Operation) {
    this.onSelectedOperation.emit(operation);
  }

  onExportClick() {
    const filter = this.requestFilter;
    const modalRef = this.ouiDialog.openDialog(ExcelExportComponent, { queryFilter: filter }, 'auto', 'auto');
  }

  onTableSortCriteriaChange(newCriteria: OperationSortInput | undefined) {
    this.tableSortCriteria = newCriteria;
    this.getAllOperations();
  }

  getAllOperations() {
    if (!this.noFetch && this.requestFilter) {
      let requestSortCriteria = this.buildRequestSortCriteria();
      const requestVariables = { ...this.paginationParams, filter: this.requestFilter, order: requestSortCriteria };

      this.requestResult = {
        loading: true,
        data: { items: [], totalCount: 0, pageInfo: { hasNextPage: false, hasPreviousPage: false } },
      };
      // FIXME : comme la gateway n'a pas de batch dataloader elle subit le probleme N+1. Les requetes Atlas sont donc trop grosse si on demande beaucoup d'opérations
      // pour corriger i faut migrer sur fusion mais il n'est pas dispo en release. en attendant on limite le nombre d'opérations par requête à 50 et dans ce cas on fait
      // deux requêtes consécutives
      const maxOperaPerRequest = 50;
      if (requestVariables.take <= maxOperaPerRequest) {
        this.queryManager
          .query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
            query: ALLOPERATIONTABLE,
            variables: requestVariables,
            fetchPolicy: 'network-only',
          })
          .subscribe(({ loading, data }) => {
            this.requestResult = { loading, data: data.allOperationsPaginated };
            if (data.allOperationsPaginated.items && data.allOperationsPaginated.totalCount) {
              this.onTotalCount.emit(data.allOperationsPaginated.totalCount);
            } else {
              this.onTotalCount.emit(0);
            }
          });
      } else {
        // TODO : a supprimer dès qu'on a passé la gateway sous fusion. en attendant on fait deux requetes de 50 opérations
        var param1 = { ...requestVariables, take: maxOperaPerRequest };
        const query1 = this.queryManager.query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
          query: ALLOPERATIONTABLE,
          variables: param1,
          fetchPolicy: 'network-only',
        });
        const param2 = {
          ...requestVariables,
          skip: requestVariables.skip + maxOperaPerRequest,
          take: maxOperaPerRequest,
        };
        const query2 = this.queryManager.query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
          query: ALLOPERATIONTABLE,
          variables: param2,
          fetchPolicy: 'network-only',
        });

        // on fusionne les 2 resultats pour simuler une requete unique
        forkJoin([query1, query2]).subscribe(([data1, data2]: any[]) => {
          const allOperationsResult = deepCopy(data1.data.allOperationsPaginated);
          allOperationsResult.items = allOperationsResult.items.concat(data2.data.allOperationsPaginated.items);
          allOperationsResult.pageInfo.hasNextPage = data2.data.allOperationsPaginated.pageInfo.hasNextPage;
          this.requestResult = { loading: false, data: allOperationsResult };
          if (allOperationsResult.items && allOperationsResult.totalCount) {
            this.onTotalCount.emit(allOperationsResult.totalCount);
          } else {
            this.onTotalCount.emit(0);
          }
        });
      }
    }
  }

  onDispatchedOperations(dispatchedOperations: Operation[]) {
    if (dispatchedOperations && dispatchedOperations.length > 0) {
      const modalRef = this.dialogService.openDialog(
        OperationsDispatcherComponent,
        {
          operations: dispatchedOperations,
        },
        'auto',
        '1280px'
      );

      modalRef.afterClosed().subscribe((result: Operation[]) => {
        if (!result || result.length === 0) {
          return;
        }

        this.snackbarService.open(this.getDispatchedOperationsMessage(dispatchedOperations), 'success', 5000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
        this.operationTable.unselectAll();
        this.requestResult = undefined;
        this.onDispatchedOperationsEvent.emit();
        this.getAllOperations();
      });
    }
  }

  private getDispatchedOperationsMessage(dispatchedOperations: Operation[]) {
    if (dispatchedOperations.length === 1) {
      return "L'opération a été affectée";
    }
    return `Les ${dispatchedOperations.length} opérations ont été affectées`;
  }

  buildRequestSortCriteria(): OperationSortInput[] {
    var result = [];
    if (this.tableSortCriteria) {
      result.push(this.tableSortCriteria);
    } else {
      // default sort by date modification if no sort criteria is set
      result.push({ dateModification: SortEnumType.Desc });
    }

    // add sort by id as second sort criteria to ensure determinism
    result.push({ id: SortEnumType.Desc });

    return result;
  }

  async onDeleteDraftClick(operation: Operation) {
    const dialogRef = this.dialogService.openDialog(DeleteDraftConfirmComponent, { operation }, 'auto', '448px');
    dialogRef.afterClosed().subscribe(result => {
      if (result > 0) {
        this.getAllOperations();
        this.snackbarService.open('Operation supprimée', 'success', 5000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
      }
    });
  }

  onFilterChanged(event: OperationFilterInput) {
    this.requestFilter = event;
    this.getAllOperations();
  }
}

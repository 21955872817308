import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import { CONNAISSANCE_CLIENT_CATEGORIE_ID, CONSEIL_CATEGORIE_ID } from '@lib/models/graphqlData';
import {
  OperationConfig,
  Produit,
  ProduitSortInput,
  ProduitsPaginatedCollectionSegment,
  SortEnumType,
} from '@lib/models/generated/graphql';
import { ProduitDetails } from '@lib/services/produitDetails.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiSelectOption } from 'omnium-ui/form-field/select/select-option.model';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ProductsTableComponent } from './products-table/products-table.component';
import { deepCopy } from '@lib/utils/deepCopy';

const PAGEALLPRODUITS = gql`
  query allProduitsPaginated($skip: Int, $take: Int, $filter: String, $order: [ProduitSortInput!]) {
    allProduitsPaginated(
      skip: $skip
      take: $take
      where: {
        and: [
          { etat: { eq: 2 } } #product with etat "ouvert à la souscription"
          {
            or: [
              { nom: { contains: $filter } }
              { idPartenaireNavigation: { nom: { contains: $filter } } }
              { enveloppeNavigation: { libelle: { contains: $filter } } }
              { nomTechniqueProduitBox: { contains: $filter } }
            ]
          }
        ]
      }
      order: $order
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        nom
        nomTechniqueProduitBox
        enveloppeNavigation {
          libelle
        }
        idPartenaireNavigation {
          nom
        }
        produitExtension {
          id
          libelle
          souscriptionPossible
        }
      }
      totalCount
    }
  }
`;

@Component({
  selector: 'app-param-products',
  templateUrl: './param-products.component.html',
  styleUrls: ['./param-products.component.scss'],
})
export class ParamProductsComponent implements OnInit {
  dataSource = new MatTableDataSource<Produit>([]);

  selectedProduit?: Produit;
  displayProduitDetails = false;
  natureListOptions: OuiSelectOption[] = [];
  NatureFilters: any = [];

  searchControl = new FormControl<any>('');
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { data: ProduitsPaginatedCollectionSegment; loading: boolean } | undefined;

  @ViewChild(ProductsTableComponent) produitTable: ProductsTableComponent;
  sortInput: ProduitSortInput | undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private queryManager: QueryManagerService,
    private produitDetailsProvider: ProduitDetails
  ) {}

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(search => {
      this.paginationParams = DEFAULT_PAGINATION_PARAMS;
      this.getAllProduits();
    });

    let routeId = this.route.snapshot.paramMap.get('produitId');
    if (routeId) {
      // si l'url contient produitId en paramètre on affiche directement le détail du produit
      this.displayProduitDetails = true;
      let fetchedProduit$ = this.produitDetailsProvider.fetch({ produitID: Number.parseInt(routeId) });
      fetchedProduit$.subscribe(({ data, loading }) => {
        this.selectedProduit = deepCopy(data?.produitById); // on fait un deep clone du produit pour le rendre mutable. on pourra ensuite le modifilier en delta à chaque mutation
      });
    }
  }

  onBackArrowClick() {
    this.router.navigate(['parametres', 'produits']);
  }

  filterConnaissanceClientDoc(opeConfig: OperationConfig) {
    return opeConfig?.natureDocumentRequis?.filter(d => d.natureDocument?.categorieId === CONNAISSANCE_CLIENT_CATEGORIE_ID);
  }

  filterConseilDoc(opeConfig: OperationConfig) {
    return opeConfig?.natureDocumentRequis?.filter(d => d.natureDocument?.categorieId === CONSEIL_CATEGORIE_ID);
  }

  getAllProduits(): void {
    const requestVariables = {
      ...this.paginationParams,
      filter: this.searchControl.value,
      order: this.buildRequestSortCriteria(),
    };

    this.queryManager
      .query<{ allProduitsPaginated: ProduitsPaginatedCollectionSegment }>({
        query: PAGEALLPRODUITS,
        variables: requestVariables,
      })
      .subscribe(({ loading, data }) => {
        this.requestResult = { loading, data: data.allProduitsPaginated };
      });
  }
  onSortUpdate(sortInput: ProduitSortInput | undefined) {
    this.sortInput = sortInput;
    this.getAllProduits();
  }

  buildRequestSortCriteria(): ProduitSortInput[] {
    const sortCriteria: ProduitSortInput[] = [];
    if (this.sortInput) {
      sortCriteria.push(this.sortInput);
    }
    // add sort by id as second sort criteria to ensure determinism
    sortCriteria.push({ id: SortEnumType.Desc });
    return sortCriteria;
  }
}

<div class="flex-vertical gap-16">
  <div class="filters-container">
    <div class="flex-horizontal gap-16 width-100pc">
      <app-search-investisseur-autocomplete
        class="searchbar"
        *ngIf="isFilterActive(operationDashboardFilterEnum.Investisseur)"
        (onInvestisseurPersonneSelected)="onInvestisseurSelected($event)"
        [fullDisplay]="isBackOffice"
        [label]="'Rechercher un investisseur'"></app-search-investisseur-autocomplete>
      <div
        class="flex-1 flex-horizontal gap-8 width-100pc"
        *ngIf="isFilterActive(operationDashboardFilterEnum.GoToById)">
        <div class="flex-none justify-center flex-vertical align-self-center">ou</div>
        <oui-textfield
          label="Rechercher par ID d'opération"
          [control]="goToOperationIdControl"
          class="flex-1"></oui-textfield>
        <oui-button
          type="contained"
          (click)="goToOperation()"
          [disabled]="!goToOperationId"
          class="flex-none"
          size="large"
          >Voir</oui-button
        >
      </div>
    </div>

    <div class="flex-horizontal gap-16 flex-wrap">
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Statut)"
        label="Statut"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="statutOptions"
        [control]="statutControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Enveloppe)"
        label="Enveloppe"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="enveloppeOptions"
        [control]="enveloppeControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Nature)"
        label="Nature"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="natureOperationOptions"
        [control]="natureOperationControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Produit)"
        label="Produit"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="produitOptions"
        [control]="produitControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Montant)"
        label="Montant"
        type="range"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [rangeUnit]="'€'"
        [control]="montantControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Gestionnaire)"
        label="Gestionnaires"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="gestionnaireOptions"
        [control]="gestionnaireControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.FondEvnt)"
        label="Fonds évenementiels"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="fondsEvntOptions"
        [control]="fondsEvntControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Tag)"
        label="Tag"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="tagOptions"
        [control]="tagControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.Signature)"
        label="Signature"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="signatureOptions"
        [control]="signatureControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.ErrorPartenaireEmail)"
        label="Problème e-mail partenaire"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="errorEmailOptions"
        [control]="errorEmailControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.CommandeAramis)"
        label="Commande Aramis"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="commandeAramisOptions"
        [control]="commandeAramisControl"></oui-select-filter>
      <oui-select-filter
        *ngIf="isFilterActive(operationDashboardFilterEnum.IsInvestisseurPersonneMorale)"
        label=" Type d'investisseur"
        type="list"
        panelDirection="left"
        listPlaceholder="Rechercher"
        [listOptions]="isInvestisseurPersonneMoraleOptions"
        [control]="isInvestisseurPersonneMoraleControl"></oui-select-filter>
    </div>
  </div>
  <div class="flex-horizontal gap-16">
    <oui-chip-list type="selection">
      <ng-container *ngFor="let filter of filterStateMap | keyvalue">
        <oui-chip *ngFor="let chip of filter.value.chips" (remove)="onRemove(chip)">{{ chip.label }}</oui-chip>
      </ng-container>
    </oui-chip-list>
    <oui-button *ngIf="showClearFiltersButton" type="text" color="primary" (click)="onclearAllFilter()"
      >Effacer les filtres
    </oui-button>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { gql } from 'apollo-angular';
import { QueryManagerService } from 'projects/box-lib/src/lib/services/queryManagerService';
import { Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { operationDispatchedFragment } from '../../../models/graphqlFragments';
import {
  EnveloppeProduit,
  GestionnaireWorkLoadFilterInput,
  GestionnaireWorkLoadsCollectionSegment,
  GroupeGestionnaire,
  Operation,
  OperationStateTransitionTrigger,
  SortEnumType,
} from '../../../models/generated/graphql';
import { AuthService } from '../../../services/auth-service.service';
import { DEFAULT_PAGINATION_PARAMS, PaginationParams } from '../../paginated-table/paginated-table.component';

const PAGEWORKERS = gql`
  query allGestionnaireWorkLoadPaginated(
    $skip: Int
    $take: Int
    $filter: GestionnaireWorkLoadFilterInput
    $order: [GestionnaireWorkLoadSortInput!]
  ) {
    allGestionnaireWorkLoadPaginated(skip: $skip, take: $take, where: $filter, order: $order) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        displayName
        nouveauSouscription
        nouveauActeDeGestion
        retourNC
        retourInstance
      }
      totalCount
    }
  }
`;

const DISPATCH = gql`
  mutation dispatchOperationsTo($operationIds: [Int!]!, $gestionnaireId: Int!) {
    dispatchOperationsTo(operationIds: $operationIds, gestionnaireId: $gestionnaireId) {
      ...operationDispatched
    }
  }
  ${operationDispatchedFragment}
`;

const fireOperationStateTransitionTriggerForOperationsList = gql`
  mutation fireOperationStateTransitionTriggerForOperationsList(
    $operationIds: [Int!]!
    $trigger: OperationStateTransitionTrigger!
  ) {
    fireOperationStateTransitionTriggerForOperationsList(operationIds: $operationIds, trigger: $trigger) {
      ...operationDispatched
    }
  }
  ${operationDispatchedFragment}
`;

const enveloppeBackOffice = gql`
  query allEnveloppeProduits($where: EnveloppeProduitFilterInput) {
    allEnveloppeProduits(where: $where) {
      code
      backOfficeService
    }
  }
`;

@Component({
  selector: 'lib-operations-dispatcher',
  templateUrl: './operations-dispatcher.component.html',
  styleUrls: ['./operations-dispatcher.component.scss'],
})
export class OperationsDispatcherComponent {
  searchControl = new FormControl<any>('');
  paginationParams: PaginationParams = DEFAULT_PAGINATION_PARAMS;
  requestResult: { loading: boolean; data: GestionnaireWorkLoadsCollectionSegment };

  subtitle: string = '';

  operationGroupGestionnaire: GroupeGestionnaire;
  nameSearchSubscription: Subscription;
  constructor(
    private queryManager: QueryManagerService,
    @Inject(MAT_DIALOG_DATA) private data: { operations?: Operation[] },
    private dialogRef: MatDialogRef<OperationsDispatcherComponent>,
    private authService: AuthService
  ) {}

  ngOnDestroy() {
    if (this.nameSearchSubscription) {
      this.nameSearchSubscription.unsubscribe();
    }
  }
  ngOnInit() {
    this.setSubtitle();
    //Get All EnveloppeProduits to use Apollo query cache
    this.queryManager
      .query<{ allEnveloppeProduits: EnveloppeProduit[] }>({ query: enveloppeBackOffice })
      .subscribe(res => {
        if (res.data.allEnveloppeProduits && this.data.operations && this.data.operations[0]) {
          //set operationGroupGestionnaire
          const enveloppes = res.data.allEnveloppeProduits;
          const produitEnveloppeCode =
            this.data.operations[0].produitPreset?.enveloppeCode ?? this.data.operations[0].produit?.enveloppe;
          const service = enveloppes.find(env => env?.code === produitEnveloppeCode)?.backOfficeService;
          this.operationGroupGestionnaire =
            service?.toString().toUpperCase() === GroupeGestionnaire.Svp.toString().toUpperCase()
              ? GroupeGestionnaire.Svp
              : GroupeGestionnaire.Psi;
          // init gestionnaire worloads fetch
          this.getAllGestionnaireWorkloads();
          //subscribe to control
          this.nameSearchSubscription = this.searchControl.valueChanges
            .pipe(debounceTime(350), distinctUntilChanged())
            .subscribe(search => {
              this.paginationParams = DEFAULT_PAGINATION_PARAMS;
              this.getAllGestionnaireWorkloads();
            });
        }
      });
  }

  setSubtitle() {
    if (this.data.operations && this.data.operations.length > 1) {
      this.subtitle = `Sélectionnez le gestionnaire pour lui affecter les ${this.data.operations.length} opérations.`;
    } else {
      this.subtitle = `Sélectionnez le gestionnaire pour lui affecter l'opération.`;
    }
  }

  getAllGestionnaireWorkloads(): void {
    if (this.operationGroupGestionnaire) {
      const requestVariables = {
        ...this.paginationParams,
        filter: this.buildInputFilter(),
        order: [{ displayName: SortEnumType.Asc }],
      };
      this.queryManager
        .query<{ allGestionnaireWorkLoadPaginated: GestionnaireWorkLoadsCollectionSegment }>({
          query: PAGEWORKERS,
          variables: requestVariables,
          fetchPolicy: 'network-only',
        })
        .subscribe(({ loading, data }) => {
          this.requestResult = { loading, data: data.allGestionnaireWorkLoadPaginated };
        });
    }
  }

  async fireOperationStateTransitionTriggerForOperationsList(
    trigger: OperationStateTransitionTrigger,
    operations: Operation[]
  ): Promise<Operation[]> {
    if (operations.some(operation => operation?.activeOperationStateTransitionTriggers.includes(trigger))) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ fireOperationStateTransitionTriggerForOperationsList: Operation[] }>({
          mutation: fireOperationStateTransitionTriggerForOperationsList,
          variables: {
            operationIds: operations.map(op => op.id),
            trigger: trigger,
          },
        })
      );
      if (result.data?.fireOperationStateTransitionTriggerForOperationsList) {
        return result.data?.fireOperationStateTransitionTriggerForOperationsList;
      }
    }
    return operations;
  }

  async onSelectedGestionnaire(gestionnaireId: number) {
    if (this.data.operations && gestionnaireId) {
      const dispatchedOperations = await this.dispatchOperationsTo(gestionnaireId);
      if (dispatchedOperations && dispatchedOperations[0]) {
        const dispatchedOperationList = await this.fireOperationStateTransitionTriggerForOperationsList(
          OperationStateTransitionTrigger.ManagerDispatchsOperation,
          dispatchedOperations
        );
        this.dialogRef.close(dispatchedOperationList);
      }
    }
  }

  async dispatchOperationsTo(gestionnaireId: number): Promise<Operation[] | undefined> {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ dispatchOperationsTo: Operation[] }>({
        mutation: DISPATCH,
        variables: { operationIds: this.data.operations?.map(op => op.id), gestionnaireId },
      })
    );
    return result?.data?.dispatchOperationsTo;
  }
  close(data: Operation | null = null) {
    this.dialogRef.close(data);
  }

  buildInputFilter(): GestionnaireWorkLoadFilterInput {
    const nameFilter: GestionnaireWorkLoadFilterInput = { displayName: { contains: this.searchControl.value } };
    const groupFilter: GestionnaireWorkLoadFilterInput = {
      or: [{ withGroupeAccessExtended: { eq: true } }, { groupeGestionnaire: { eq: this.operationGroupGestionnaire } }],
    };
    const filter: GestionnaireWorkLoadFilterInput = {
      and: [nameFilter, groupFilter],
    };
    return filter;
  }
}

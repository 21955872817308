import { formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE } from '@lib/models/graphqlData';
import { Investisseur } from '@lib/models/generated/graphql';

@Component({
  selector: 'transfer-operation-modal',
  templateUrl: './transfer-operation-modal.component.html',
  styleUrls: ['./transfer-operation-modal.component.scss'],
})
export class TransferOperationModalComponent {
  constructor(
    private dialogRef: MatDialogRef<TransferOperationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      sourceInvestisseurData: Investisseur;
      targetInvestisseurData: Investisseur;
      operationNumber: number;
    }
  ) {}
  formatName(investisseur: Investisseur): string {
    let label = '';

    label += investisseur?.investisseurEntite?.displayName;

    if (investisseur?.investisseurEntite?.personnePhysique?.dateNaissance) {
      if (investisseur?.investisseurEntite?.personnePhysique?.genre == PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE) {
        label += ' (née le ';
      } else {
        label += ' (né le ';
      }
      label +=
        formatDate(investisseur.investisseurEntite?.personnePhysique?.dateNaissance, 'dd/MM/yyyy', 'fr_FR') + ')';
    }
    return label;
  }
  formatIds(investisseur: Investisseur): string {
    let label = '';
    label += investisseur?.code;
    label += '   -   ID Ariane : ' + investisseur?.arianeId;

    return label;
  }

  cancel() {
    this.dialogRef.close();
  }

  validate() {
    this.dialogRef.close(true);
  }
}

import { Component, Inject, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiAutocomplete, OuiAutocompleteOption, OuiSelectOption } from 'omnium-ui/form-field';
import { DEFAULT_BOX_DOCUMENT_TYPE } from 'projects/box-lib/src/lib/models/types';
import { deepCopy } from 'projects/box-lib/src/lib/utils/deepCopy';
import { LocalFileInfo } from '../../../models/LocalFileInfo';
import { BoxDocumentType, Investisseur, NatureDocumentRequi } from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';
import { ConfirmationSimpleComponent } from '../../confirmation-simple/confirmation-simple.component';

export type AddUploadResult = {
  filesInfo: LocalFileInfo[];
  forAllOperations?: boolean;
};
@Component({
  templateUrl: './add-upload-document-viewer.component.html',
  selector: 'app-add-upload-document-viewer',
  styleUrls: ['./add-upload-document-viewer.component.scss'],
})
export class AddUploadDocumentViewerComponent {
  @ViewChild('typeDocumentUpload') typeDocAutoComplete: OuiAutocomplete<any>;

  commentControl = new FormControl<string>('');

  filesInfo: LocalFileInfo[] = [];

  selectedFileInfo?: LocalFileInfo;
  typeLabel?: string;

  documentUrl: string;

  typeSelectable = false;

  investisseurListOptions: OuiSelectOption<number>[];
  investisseurControl = new FormControl();

  denominationControl = new FormControl<string | undefined>(undefined, [Validators.required]);

  boxDocumentTypeControl = new FormControl<number | undefined>(undefined, [Validators.required]);
  boxDocumentTypeListOptions: OuiAutocompleteOption[] = [];

  subcriptionOperationConfigs: any;

  validateButtonLabel: string;
  investisseurs: Investisseur[] | undefined;

  forAllOperations: boolean = false;
  showAllOperationsOption: boolean = false;
  DEFAULT_BOX_DOCUMENT_TYPE_KEY = DEFAULT_BOX_DOCUMENT_TYPE.key;
  denominationSelectable: any;
  natureDocumentRequi: NatureDocumentRequi | undefined;
  defaultBoxDocumentType: BoxDocumentType | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      files: File[];
      typeSelectable: boolean;
      natureDocumentRequi?: NatureDocumentRequi;
      typeLabel: string;
      investisseurs: Investisseur[];
      showAllOperationsOption?: boolean;
    },
    private dialogRef: MatDialogRef<AddUploadDocumentViewerComponent>,
    private documentService: DocumentsService,
    private dialogService: OuiDialogService
  ) {
    this.typeSelectable = !!data.typeSelectable;
    this.natureDocumentRequi = data.natureDocumentRequi;
    this.typeLabel = data.typeLabel;
    this.showAllOperationsOption = data.showAllOperationsOption ?? false;
    this.investisseurs = data.investisseurs;

    if (this.investisseurs) {
      this.investisseurListOptions = this.investisseurs.map(investisseur => ({
        label: investisseur.investisseurEntite?.displayName ?? '-',
        value: investisseur.id,
      }));
      if (this.investisseurListOptions.length > 0) {
        this.investisseurControl.setValue(this.investisseurListOptions[0].value);
      }
    }

    this.defaultBoxDocumentType = this.natureDocumentRequi?.natureDocument?.boxDocumentType;
    this.filesInfo = data.files.map(
      file =>
        <LocalFileInfo>{
          url: this.documentService.cacheLocalFile(file),
          file: file,
          denomination: this.typeSelectable ? undefined : this.typeLabel,
          investisseurId: this.investisseurControl.value,
          boxDocumentType: this.defaultBoxDocumentType,
        }
    );
  }

  protected getStatus(fileInfo: LocalFileInfo) {
    let typeToDefine = !fileInfo?.denomination;
    return !typeToDefine ? 'default' : 'a_qualifier';
  }

  ngOnInit() {
    this.validateButtonLabel = this.getValidateButtonLabel();

    this.boxDocumentTypeControl.valueChanges.subscribe(newBoxDocumentTypeId => {
      if (newBoxDocumentTypeId) {
        this.documentService.getBoxDocumentTypeById(newBoxDocumentTypeId).then(boxDocumentType => {
          if (this.selectedFileInfo) {
            this.selectedFileInfo.boxDocumentType = deepCopy(boxDocumentType); // deep copy to all overwrite attributes

            if (boxDocumentType?.id === DEFAULT_BOX_DOCUMENT_TYPE.id) {
              // if type is "Autre" should define denomination
              this.denominationSelectable = true;
              this.selectedFileInfo.denomination = undefined;
            } else {
              this.denominationSelectable = false;
              this.selectedFileInfo.denomination = this.selectedFileInfo?.boxDocumentType?.key;
            }
          }
        });
      }
    });

    this.denominationControl.valueChanges.subscribe(newDenomination => {
      if (newDenomination && this.selectedFileInfo?.boxDocumentType?.key) {
        this.documentService.getBoxDocumentTypeByDenomination(newDenomination).then(boxDocumentType => {
          if (this.selectedFileInfo?.boxDocumentType?.key) {
            if (boxDocumentType) {
              this.selectedFileInfo.denomination = newDenomination + ' '; // add space to avoid conflict with box types keys
            } else {
              this.selectedFileInfo.denomination = newDenomination;
            }
          }
        });
      }
    });

    this.commentControl.valueChanges.subscribe(newComment => {
      if (this.selectedFileInfo) {
        this.selectedFileInfo.comment = newComment ?? undefined;
      }
    });

    this.documentService.getBoxDocumentTypeList().then(boxDocumentTypes => {
      // FIXME : probleme maj du autocomplete. on est obligé de mettre definir les option avant de setter la valueur dans l'autocomplete sinon rien ne s'affiche
      this.boxDocumentTypeListOptions = boxDocumentTypes.map(boxDocumentType => ({
        label: boxDocumentType.key,
        value: boxDocumentType.id,
      }));
      if (this.filesInfo && this.filesInfo.length > 0) {
        // FIXME  : probleme maj du autocomplete. si on ne met pas le timeout l'autocomple n'affiche pas la valeur alors que la donnee est settée
        setTimeout(() => {
          this.selectFile(this.filesInfo[0]);
        }, 0);
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  validate(): void {
    if (this.filesInfo) {
      this.filesInfo.forEach(file => {
        file.investisseurId = this.investisseurControl.value;
      });
      const result: AddUploadResult = { filesInfo: this.filesInfo, forAllOperations: this.forAllOperations };

      if (!(this.forAllOperations && this.filesInfo?.length === 1 && this.natureDocumentRequi)) {
        this.dialogRef.close(result);
        return;
      }

      const modalRef = this.dialogService.openDialog(
        ConfirmationSimpleComponent,
        {
          title: 'Le document sera ajouté partout',
          message:
            'En cliquant sur “Valider”, ce document sera ajouté à toutes les opérations. Si un document de ce type est déjà présent dans une opération, il sera remplacé.',
          validateButtonLabel: 'Valider',
        },
        'auto',
        '414px'
      );

      modalRef.afterClosed().subscribe((isValidated: boolean) => {
        if (isValidated) {
          result.forAllOperations = true;
          this.dialogRef.close(result);
        }
      });

      // close dialog and return file info
    }
  }

  selectFile(fileInfo: LocalFileInfo) {
    if (fileInfo?.url) {
      this.documentUrl = fileInfo.url;
    }
    this.selectedFileInfo = fileInfo;

    //clear autocompletes for type document // FIXME  : only use of this.boxDocumentTypeControl.setValue is not possible because autocomplete keep a memory
    // of the last selected value in visible option list
    const newDocTypeId = fileInfo?.boxDocumentType?.id ?? this.defaultBoxDocumentType?.id;
    if (!newDocTypeId) {
      this.boxDocumentTypeControl.setValue(undefined);
      this.boxDocumentTypeControl.reset();
      this.typeDocAutoComplete.empty();
    } else {
      this.boxDocumentTypeControl.setValue(fileInfo?.boxDocumentType?.id ?? this.defaultBoxDocumentType?.id);
    }

    this.denominationControl.setValue(fileInfo?.denomination);
  }
  isValidateDisabled() {
    const isValid = this.filesInfo.every(
      file =>
        (file.boxDocumentType && file.boxDocumentType?.id !== DEFAULT_BOX_DOCUMENT_TYPE.id) ||
        (file.boxDocumentType?.key === DEFAULT_BOX_DOCUMENT_TYPE.key && file.denomination) // check if denomination has been defined
    );
    return !isValid;
  }

  getValidateButtonLabel() {
    let title = 'Ajouter le document';
    if (this.filesInfo && this.filesInfo.length > 1) {
      title = `Ajouter les ${this.filesInfo.length} documents`;
    }
    return title;
  }
}

<oui-document-viewer [documentUrl]="documentUrl" [showControls]="true">
  <ng-container oui-document-controls>
    <lib-document-viewer-header
      [documentName]="headerDocumentName"
      [fileName]="fichierOperation.metadata?.fileName ?? ''"
      [dateAjout]="fichierOperation.metadata?.dateDeDerniereModification"
      (close)="closeDocumentViewer()">
    </lib-document-viewer-header>

    <oui-textarea class="field-commentaire" label="Commentaire (facultatif)" [control]="commentControl"></oui-textarea>

    <oui-button [iconPrefix]="'delete'" type="text" (click)="removeFicherOperation()">Supprimer le document</oui-button>
  </ng-container>
</oui-document-viewer>

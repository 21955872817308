<div class="page-layout flex-vertical align-self-stretch">
  <div class="flex-horizontal width-100pc">
    <h4 class="flex-none">Bordereaux partenaire</h4>
  </div>

  <oui-tab-group class="tabs" [navigation]="true">
    <oui-tab label="A générer" link="dashboard"> </oui-tab>
    <oui-tab label="Suivi" link="suivi"> </oui-tab>
  </oui-tab-group>
  <router-outlet></router-outlet>
</div>

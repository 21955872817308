import { Pipe, PipeTransform } from '@angular/core';

interface Option {
  label?: string;
  value?: string;
}

@Pipe({
  name: 'optionLabel',
  standalone: true,
})
export class OptionLabelPipe implements PipeTransform {
  transform(value: string, options: Option[] | null): string {
    if (!options) {
      return value;
    }
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label ?? value : value;
  }
}

import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { OuiDialogService } from 'omnium-ui/dialog';
import { MenuNode } from 'omnium-ui/shared';
import { FichierOperationStatutEnum } from '../../../models/FichierOperationStatutEnum';
import {
  FileMetadata,
  FichierOperationHistoryRecord,
  NonConformiteFichier,
  FichierOperationStatut,
  FichierOperation,
} from '../../../models/generated/graphql';
import { DocumentsService } from '../../../services/documents.service';
import { FileHistoriqueDialogComponent } from '../../control-file-pdf-viewer/file-historique-dialog/file-historique-dialog.component';
import { CommonModule } from '@angular/common';
import { OuiIconModule } from 'omnium-ui/icon';
import { OuiButtonModule } from 'omnium-ui/button';
import { OuiDocumentViewer } from 'omnium-ui/document-viewer';
import { DocumentViewerHeaderComponent } from '../_common/document-viewer-header/document-viewer-header.component';
import { BoxDocumentViewerService } from '../document-viewer-panel/document-viewer-panel.service';
import { firstValueFrom } from 'rxjs';
import { FileConformStatusStampComponent } from '../../file-conform-status-stamp/file-conform-status-stamp.component';
import { NonConformiteAccordionComponent } from '../../non-conformite-accordion/non-conformite-accordion.component';

@Component({
  selector: 'lib-operation-document-viewer',
  standalone: true,
  imports: [
    CommonModule,
    OuiIconModule,
    OuiButtonModule,
    OuiDocumentViewer,
    DocumentViewerHeaderComponent,
    FileConformStatusStampComponent,
    NonConformiteAccordionComponent,
  ],
  templateUrl: './operation-document-viewer.component.html',
  styleUrls: ['./operation-document-viewer.component.scss'],
})
export class OperationDocumentViewerComponent {
  private _fichierOperation: FichierOperation;
  public get fichierOperation(): FichierOperation {
    return this._fichierOperation;
  }
  @Input({ required: true })
  public set fichierOperation(value: FichierOperation) {
    this._fichierOperation = value;
    this.initDocument();
  }

  @Output()
  close = new EventEmitter();

  documentUrl: string;

  fileMetadata?: FileMetadata;
  commentaire?: string;

  headerDocumentName: string;

  historyEntries: FichierOperationHistoryRecord[];
  allNonConformiteFichiers: NonConformiteFichier[];
  fichierOperationStatut: FichierOperationStatut | undefined;

  compliant = FichierOperationStatutEnum.compliant;

  nCtoDisplayList: NonConformiteFichier[] = [];
  ncTitle: string = 'Non-conformités ouvertes';

  fileMenu: MenuNode[] = [];

  protected reopenHistory: boolean = false;

  constructor(
    private ouiDialog: OuiDialogService,
    private documentService: DocumentsService,
    private documentViewerPanelService: BoxDocumentViewerService
  ) {}

  private initDocument() {
    if (this.fichierOperation) {
      this.fichierOperationStatut = this.fichierOperation.fichierOperationStatut;
      this.historyEntries = this.fichierOperation.historyEntry;
      this.allNonConformiteFichiers = this.fichierOperation.nonConformiteFichiers;
      if (this.fichierOperation.metadata) {
        this.fileMetadata = this.fichierOperation.metadata;
      }
      if (this.fichierOperation.commentaire) {
        this.commentaire = this.fichierOperation.commentaire;
      }
    }
    const hasTypeLibelle = this.fileMetadata?.typeLibelle && this.fileMetadata.typeLibelle.toUpperCase() !== 'AUTRE';
    const denomination = this.fileMetadata?.denomination ?? 'Autre';
    this.headerDocumentName = hasTypeLibelle ? this.fileMetadata?.typeLibelle! : denomination;

    this.fetchDocumentUrl();
    this.setNCtoDisplay();

    if (this.fichierOperation) {
      this.fileMenu = this.createFileMenu();
    }
  }

  async fetchDocumentUrl() {
    let fileCacheUrl;
    if (this.fileMetadata) {
      fileCacheUrl = await this.documentService.fetchAndCacheGedDocument(this.fileMetadata);
    }

    if (fileCacheUrl) {
      this.documentUrl = fileCacheUrl;
    }
  }

  setNCtoDisplay() {
    if (this.allNonConformiteFichiers) {
      this.nCtoDisplayList = this.allNonConformiteFichiers.filter(ncf => ncf.isActive);
      if (this.nCtoDisplayList.some(ncf => ncf.isInstancePartenaire)) {
        this.nCtoDisplayList = this.nCtoDisplayList.filter(ncf => ncf.isInstancePartenaire);
        this.ncTitle = 'Instances partenaire ouvertes';
      }
    }
  }

  createFileMenu() {
    const menuItems = [
      {
        name: 'Historique',
        action: () => {
          this.openSelectedFileHistory();
        },
      },
    ];

    return menuItems;
  }

  openSelectedFileHistory() {
    const operationId = this.fichierOperation?.operationId;
    if (operationId) {
      const fichierOperationId = this.fichierOperation?.id;
      const dialogRef = this.ouiDialog.openDialog(
        FileHistoriqueDialogComponent,
        {
          operationId: operationId,
          fichierOperationId: fichierOperationId,
        },
        '720px',
        '700px'
      );

      const dialogSub = dialogRef.componentInstance.documentOpen.subscribe((file: FileMetadata) => {
        this.reopenHistory = true;
        this.openDocumentViewer(file);

        dialogRef.close();
      });

      dialogRef.afterClosed().subscribe(() => {
        dialogSub.unsubscribe();
      });
    }
  }

  openDocumentViewer(fileMetadata?: FileMetadata) {
    this.documentViewerPanelService.openSimpleDocumentViewer({ fileMetadata: fileMetadata });

    if (this.reopenHistory) {
      firstValueFrom(this.documentViewerPanelService.viewerClosed$).then(() => {
        this.documentViewerPanelService.openOperationDocumentViewer(this.fichierOperation);
        this.openSelectedFileHistory();
        this.reopenHistory = false;
      });
    }
  }
}

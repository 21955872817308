<div class="flex-vertical gap-16 width-100pc">
  <!-- <div>
        {{totalCount}} <span *ngIf="totalCount>1">produits</span><span *ngIf="totalCount<2">produit</span>
    </div> -->
  <div class="width-100pc">
    <oui-table>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="gestionnaire">
          <th mat-header-cell *matHeaderCellDef>Gestionnaire</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.displayName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="souscriptions">
          <th mat-header-cell *matHeaderCellDef>Souscriptions</th>
          <td mat-cell *matCellDef="let element">{{ element?.nouveauSouscription }}</td>
        </ng-container>

        <ng-container matColumnDef="actes">
          <th mat-header-cell *matHeaderCellDef>Actes de gestion</th>
          <td mat-cell *matCellDef="let element">{{ element?.nouveauActeDeGestion }}</td>
        </ng-container>

        <ng-container matColumnDef="retourNCs">
          <th mat-header-cell *matHeaderCellDef>Retour NC</th>
          <td mat-cell *matCellDef="let element">{{ element?.retourNC }}</td>
        </ng-container>

        <ng-container matColumnDef="RetourInstances">
          <th mat-header-cell *matHeaderCellDef>Retour Instance</th>
          <td mat-cell *matCellDef="let element">{{ element?.retourInstance }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container *ngIf="!isLoading">
          <tr
            mat-row
            [class.interactive]="true"
            *matRowDef="let row; columns: displayedColumns"
            (click)="onRowClick(row)"></tr>
        </ng-container>
      </table>
      <oui-loading class="flex-vertical flex-center padding-vertical-16" *ngIf="isLoading"></oui-loading>
      <oui-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="totalCount"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize">
      </oui-paginator>
    </oui-table>
  </div>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OperationFilterInput } from 'projects/box-lib/src/lib/models/generated/graphql';
import { ExportCSVService } from 'projects/box-lib/src/lib/services/exportCSVService';

@Component({
  selector: 'excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss'],
})
export class ExcelExportComponent {
  isLoading = false;
  numberOfOperations?: number;

  constructor(
    private dialogRef: MatDialogRef<ExcelExportComponent>,
    protected exportService: ExportCSVService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      queryFilter: OperationFilterInput;
    }
  ) {
    exportService.getNumberOfOperations(this.data.queryFilter).then(res => {
      this.numberOfOperations = res;
    });
  }
  cancel() {
    this.dialogRef.close();
  }

  async validate() {
    let filter = this.data.queryFilter;
    this.isLoading = true;
    await this.exportService.startExportOperations(filter);
    this.isLoading = false;
    this.dialogRef.close(true);
  }
}

<oui-accordion [ngClass]="cardClass" [expanded]="!!nonConformite.isActive">
  <oui-accordion-header class="padding-vertical-8 padding-lateral-24">
    <oui-accordion-title>
      <div class="flex-horizontal flex-center width-100pc">
        <oui-number [color]="iconColor" [value]="position"> </oui-number>
        <div class="nc-title" [ngClass]="textClass">
          {{ nonConformite.categorie | optionLabel: categories }} {{ nonConformite.motif | optionLabel: motifs }}
        </div>
      </div>
      <oui-tag [size]="'small'" type="status" status="pending" *ngIf="nonConformite.isInternal">interne</oui-tag>
    </oui-accordion-title>
    <oui-accordion-actions>
      <oui-menu [menuItems]="menuItems" *ngIf="isShowMenu">
        <oui-button type="icon" color="secondary"><oui-icon>more_vert</oui-icon></oui-button>
      </oui-menu>
    </oui-accordion-actions>
  </oui-accordion-header>
  <div class="flex-vertical padding-lateral-24 padding-bottom-16" [innerText]="nonConformite.commentaire"></div>
</oui-accordion>

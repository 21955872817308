<div class="param-layout">
  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
    <div class="flex-horizontal gap-72">
      <app-bordereaux-dashboard-filters
        (onFilterChanged)="onFilterChanged($event)"
        [filterSelection]="filterSelection"></app-bordereaux-dashboard-filters>
    </div>
    <app-bordereaux-table
      [receivedData]="requestResult"
      (requestNewPage)="getAllBordereaux()"
      [(paginationParams)]="paginationParams"
      (onSortClick)="onSortClicked($event)">
    </app-bordereaux-table>
  </div>
</div>

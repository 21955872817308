import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationSimpleComponent } from '@lib/components/confirmation-simple/confirmation-simple.component';
import { NotifyMailPartenaireComponent } from '@lib/components/notify-mail-partenaire/notify-mail-partenaire.component';
import {
  EnveloppeDashboardColumn,
  InvestisseurDashboardColumn,
  MontantDashboardColumn,
  NatureDashboardColumn,
  OperationDashboardColumn,
  OperationIDDashboardColumn,
  ProduitDashboardColumn,
  StatutBackOfficeDashboardColumn,
} from '@lib/components/operations-dashboard/operations-dashboard-columns';
import { PaginationParams } from '@lib/components/paginated-table/paginated-table.component';
import {
  EnvoiEmail,
  EnvoiEmailStatut,
  EnvoiPartenaire,
  EnvoiPartenaireHistoryRecordTypes,
  EnvoiPartenaireStatut,
  Operation,
  OperationSortInput,
  OperationStateTransitionTrigger,
  OperationsPaginatedCollectionSegment,
  SortEnumType,
} from '@lib/models/generated/graphql';
import { emailCheckFragment, operationTableBordereauInfoFragment } from '@lib/models/graphqlFragments';
import { DocumentsService } from '@lib/services/documents.service';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { getTime } from '@lib/utils/codeutils';
import { deepCopy } from '@lib/utils/deepCopy';
import { gql } from 'apollo-angular';
import { OuiBannerAction } from 'omnium-ui/banner';
import { OuiDialogService } from 'omnium-ui/dialog';
import { OuiSnackbarService } from 'omnium-ui/snackbar';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';

const fireOperationStateTransitionTriggerForOperationsList = gql`
  mutation fireOperationStateTransitionTriggerForOperationsList(
    $operationIds: [Int!]!
    $trigger: OperationStateTransitionTrigger!
  ) {
    fireOperationStateTransitionTriggerForOperationsList(operationIds: $operationIds, trigger: $trigger) {
      id
      statut {
        id
        consultantLibelle
        backOfficeLibelle
      }
      activeOperationStateTransitionTriggers
    }
  }
`;

const bordereauBaseInfoFragment = gql`
  fragment bordereauBaseInfo on EnvoiPartenaire {
    id
    statutEnvoi
    historyRecords {
      type
      declaredDate
      timestamp
      newStatut
      previousStatut
    }
  }
`;

const BORDEREAU_DETAILS = gql`
  query envoiPartenaireById($id: Int!) {
    envoiPartenaireById(id: $id) {
      ...bordereauBaseInfo
      partenaireId
      sendingDate
      receiveDate
      updateDate
      bordereauFileId
      note
      operations {
        id
        statut {
          id
        }
      }
      bordereauFile {
        id
        fileId
        fileNameWithExtension
        fileExtension
        createdDate
        lastModificationDate
        fileConnectionInfo {
          sasUrl
          expiration
        }
      }
      attachedFiles {
        id
        fileId
        fileNameWithExtension
        fileExtension
        denomination
        createdDate
        lastModificationDate
        fileConnectionInfo {
          sasUrl
          expiration
        }
      }
      partenaire {
        nom
      }
      emails {
        ...emailCheck
      }
    }
  }
  ${bordereauBaseInfoFragment}
  ${emailCheckFragment}
`;

const ALL_ENVOI_PARTENAIRE_OPERATIONS = gql`
  query allOperations($filter: OperationFilterInput, $order: [OperationSortInput!]) {
    allOperationsPaginated(skip: 0, take: 100, where: $filter, order: $order) {
      items {
        ...operationTableInfo
        activeOperationStateTransitionTriggers
      }
    }
  }
  ${operationTableBordereauInfoFragment}
`;

const DECLARE_SEND_DATE_MUTATION = gql`
  mutation declareBordereauSendDate($id: Int!, $sendDate: DateTime!) {
    declareBordereauSendDate(bordereauId: $id, sendDate: $sendDate) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const DECLARE_RECEIVE_DATE_MUTATION = gql`
  mutation declareBordereauReceiveDate($id: Int!, $receiveDate: DateTime!) {
    declareBordereauReceiveDate(bordereauId: $id, receiveDate: $receiveDate) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const DELETE_BORDEREAU_MUTATION = gql`
  mutation deleteBordereau($bordereauId: Int!) {
    deleteBordereau(bordereauId: $bordereauId)
  }
`;

const DECLARE_SEARCH_MUTATION = gql`
  mutation declareBordereauSearch($id: Int!) {
    declareBordereauSearch(bordereauId: $id) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const CANCEL_SEARCH_MUTATION = gql`
  mutation cancelBordereauSearch($id: Int!) {
    cancelBordereauSearch(bordereauId: $id) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const CANCEL_STATUT_MUTATION = gql`
  mutation cancelBordereauStatut($id: Int!) {
    cancelBordereauStatut(bordereauId: $id) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const DECLARE_LOST_MUTATION = gql`
  mutation declareBordereauLost($id: Int!) {
    declareBordereauLost(bordereauId: $id) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

const UPDATE_NOTE_MUTATION = gql`
  mutation bordereauNoteUpdate($id: Int!, $newNote: String!) {
    bordereauNoteUpdate(bordereauId: $id, newNote: $newNote) {
      ...bordereauBaseInfo
    }
  }
  ${bordereauBaseInfoFragment}
`;

// const sendPartenaireNotificationEmail = gql`
//   mutation sendPartenaireNotificationEmail(
//     $bordereauId: Int!
//     $toList: [String!]!
//     $subject: String!
//     $textContent: String!
//     $attachBoxFileIds: [Int!]!
//   ) {
//     sendPartenaireNotificationEmail(
//       bordereauId: $bordereauId
//       toList: $toList
//       subject: $subject
//       textContent: $textContent
//       attachBoxFileIds: $attachBoxFileIds
//     ) {
//       id
//     }
//   }
// `;

const ATTENTE_BORDERAUX_STATUT_ID = 250;
@Component({
  selector: 'app-bordereaux-details',
  templateUrl: './bordereaux-details.component.html',
  styleUrls: ['./bordereaux-details.component.scss'],
})
export class BordereauxDetailsComponent {
  bordereauId: number;
  envoiPartenaire?: EnvoiPartenaire;
  createDateControl: FormControl<Date | null>;
  sendingDateControl: FormControl<Date | null>;
  receivingDateControl: FormControl<Date | null>;
  noteControl: FormControl<String | null>;
  isSearchButtonDisabled: boolean = false;
  isLostButtonDisabled: boolean = false;
  isCancelSearchButtonDisabled: boolean = false;
  isDeleteDisabled: boolean = false;
  operationPaginationParams: PaginationParams = { skip: 0, take: 100 };
  operationCollectionSegment: { loading: boolean; data: OperationsPaginatedCollectionSegment } = {
    loading: true,
    data: { items: [], totalCount: 0, pageInfo: { hasNextPage: false, hasPreviousPage: false } },
  };
  columnSelection: OperationDashboardColumn[] = [
    new OperationIDDashboardColumn(),
    new InvestisseurDashboardColumn(),
    new NatureDashboardColumn(),
    new ProduitDashboardColumn(),
    new EnveloppeDashboardColumn(),
    new MontantDashboardColumn(),
    new StatutBackOfficeDashboardColumn(),
  ];

  bannerEmailAction: OuiBannerAction = {
    label: 'E-mail partenaire',
    action: () => {
      this.onNotifyPartenaireClick();
    },
  };
  emailInErrors: EnvoiEmail[];
  emailBannerTitle: string = '';
  emailLastSendDate: Date | null = null;

  constructor(
    private route: ActivatedRoute,
    private queryManager: QueryManagerService,
    private router: Router,
    private datepipe: DatePipe,
    private cdref: ChangeDetectorRef,
    private documentsService: DocumentsService,
    private dialogService: OuiDialogService,
    private snackbarService: OuiSnackbarService
  ) {
    this.createDateControl = new FormControl<Date | null>(null);
    this.createDateControl.disable();

    this.sendingDateControl = new FormControl<Date | null>(null);
    //FIXME : le date picker notify plusieures fois du changement de date. pour éviter les problème de notification, on utilise distinctUntilChanged
    this.sendingDateControl.valueChanges.pipe(distinctUntilChanged()).subscribe(date => {
      if (date && this.envoiPartenaire?.sendingDate != date) {
        this.onSendDateDeclaredClick(date);
      }
    });

    this.receivingDateControl = new FormControl<Date | null>(null);
    //FIXME : le date picker notify plusieures fois du changement de date. pour éviter les problème de notification, on utilise distinctUntilChanged
    this.receivingDateControl.valueChanges.pipe(distinctUntilChanged()).subscribe(date => {
      if (date && this.envoiPartenaire?.receiveDate != date) {
        this.onReceiveDateDeclared(date);
      }
    });

    this.noteControl = new FormControl<String | null>(null);
    this.noteControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(note => {
      if ((note != null || note != undefined) && note !== this.envoiPartenaire?.note) {
        this.onNoteUpdate(note);
      }
    });
  }

  ngOnInit() {
    let routeId = this.route.snapshot.paramMap.get('bordereauId');

    if (routeId) {
      this.bordereauId = Number.parseInt(routeId);

      this.fetchBordereau().then(() => {
        this.fetchOperations();
      });
    }
  }

  fetchOperations(): void {
    if (this.envoiPartenaire?.id) {
      // get only operations of envoi Partenaire
      let requestFilter = { envoiPartenaireId: { eq: this.envoiPartenaire?.id } };
      const lastModifiedFirst: OperationSortInput = { dateModification: SortEnumType.Desc };
      const requestVariables = { filter: requestFilter, order: [lastModifiedFirst] };

      this.queryManager
        .query<{ allOperationsPaginated: OperationsPaginatedCollectionSegment }>({
          query: ALL_ENVOI_PARTENAIRE_OPERATIONS,
          variables: requestVariables,
          fetchPolicy: 'network-only',
        })
        .subscribe(({ loading, data }) => {
          this.operationCollectionSegment = { loading, data: deepCopy(data.allOperationsPaginated) };
        });
    }
  }

  async fireOperationStateTransitionTriggerForOperationsList(trigger: OperationStateTransitionTrigger): Promise<void> {
    if (
      this.operationCollectionSegment?.data?.items?.some(operation =>
        operation?.activeOperationStateTransitionTriggers.includes(trigger)
      )
    ) {
      const result = await firstValueFrom(
        this.queryManager.mutate<{ fireOperationStateTransitionTriggerForOperationsList: Operation[] }>({
          mutation: fireOperationStateTransitionTriggerForOperationsList,
          variables: {
            operationIds: this.operationCollectionSegment.data.items.map(op => op.id),
            trigger: trigger,
          },
        })
      );
      if (result.data?.fireOperationStateTransitionTriggerForOperationsList) {
        var hasChanged = false;
        result.data?.fireOperationStateTransitionTriggerForOperationsList.forEach(operation => {
          const ope = this.operationCollectionSegment?.data?.items?.find(op => op.id === operation.id);
          if (ope) {
            ope.activeOperationStateTransitionTriggers = operation.activeOperationStateTransitionTriggers;
            ope.statut = operation.statut;
            hasChanged = true;
          }
        });

        // as we update nested data of operationCollectionSegment, angular is not able to detect changes automatically
        // so we need to force it. code like this.cdref.detectChanges() does not work because angular do a shallow check of data
        // simplest way i found is to deep copy the data and reallocate it to itself. Its weird and ugly, but it works
        if (hasChanged) {
          this.operationCollectionSegment = deepCopy(this.operationCollectionSegment);
        }
      }
    }
  }

  async onDeleteBordereauClick() {
    const modalRef = this.dialogService.openDialog(
      ConfirmationSimpleComponent,
      {
        title: 'Supprimer le bordereau',
        message:
          'Vous êtes sur le point de supprimer un bordereau.\n Les opérations liées seront de nouveau accessible pour un autre bordereau.\n Toute suppression est définitive.',
        validateButtonLabel: 'Supprimer',
        cancelButtonLabel: 'Annuler',
      },
      'auto',
      '560px'
    );
    modalRef.afterClosed().subscribe(async (isValidated: boolean) => {
      if (isValidated) {
        const result = await firstValueFrom(
          this.queryManager.mutate<{ deleteBordereau: boolean }>({
            mutation: DELETE_BORDEREAU_MUTATION,
            variables: {
              bordereauId: this.bordereauId,
            },
          })
        );
        if (result?.data?.deleteBordereau) {
          this.router.navigate(['bordereaux']);
        }
      }
    });
  }

  getOperationTableTitle() {
    const title =
      (this.envoiPartenaire?.partenaire?.nom ?? 'Partenaire') +
      ' (' +
      this.envoiPartenaire?.operations?.length +
      ' opération' +
      (this.envoiPartenaire?.operations?.length === 1 ? '' : 's') +
      ' papier)';
    return title;
  }

  onBackArrowClick() {
    this.router.navigate(['bordereaux/suivi']);
  }

  onAddAttachedFile() {
    // fetch the bordereau data to refresh the attached files list
    this.fetchBordereau();
  }

  onDownloadBordereauClick() {
    if (this.envoiPartenaire?.bordereauFile) {
      this.documentsService.downloadBoxFile(this.envoiPartenaire?.bordereauFile);
    }
  }

  onNotifyPartenaireClick() {
    const modalRef = this.dialogService.openDialog(
      NotifyMailPartenaireComponent,
      {
        envoiPartenaire: this.envoiPartenaire,
      },
      'auto',
      '842px'
    );
    modalRef.afterClosed().subscribe((mailId: number) => {
      if (mailId) {
        this.emailInErrors = [];
        this.fetchBordereau();
        this.snackbarService.open('Bordereau envoyé', 'success', 7000, {
          horizontal: 'left',
          vertical: 'bottom',
        });
      }
    });
  }

  // fix for Expression has changed after it was checked error
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  getEmailErrors() {
    if (this.envoiPartenaire) {
      const errors = this.envoiPartenaire.emails.filter(
        email => email.statutEnvoi === EnvoiEmailStatut.Error && !email.isErrorAcknowledged
      );
      this.emailBannerTitle =
        'L’e-mail partenaire n’a pas été reçu: ' + (errors[0]?.statutMessage ? errors[0].statutMessage! : '');
      this.emailInErrors = errors ?? [];
    }
  }

  updateButonsOnStateChange() {
    this.isDeleteDisabled =
      this.envoiPartenaire?.operations.some(op => op.statut.id != ATTENTE_BORDERAUX_STATUT_ID) ?? false;
    switch (this.envoiPartenaire?.statutEnvoi) {
      case EnvoiPartenaireStatut.CourrierBordereauCreated:
      case EnvoiPartenaireStatut.CourrierLost:
        this.receivingDateControl.disable();
        this.isCancelSearchButtonDisabled = true;
        this.isLostButtonDisabled = true;
        this.isSearchButtonDisabled = true;
        return;
      case EnvoiPartenaireStatut.CourrierSent:
        this.receivingDateControl.enable();
        this.isCancelSearchButtonDisabled = true;
        this.isLostButtonDisabled = false;
        this.isSearchButtonDisabled = false;
        return;
      case EnvoiPartenaireStatut.CourrierSearching:
        this.receivingDateControl.enable();
        this.isCancelSearchButtonDisabled = false;
        this.isLostButtonDisabled = false;
        this.isSearchButtonDisabled = true;
        return;
      case EnvoiPartenaireStatut.CourrierReceived:
        this.receivingDateControl.enable();
        this.isCancelSearchButtonDisabled = true;
        this.isLostButtonDisabled = true;
        this.isSearchButtonDisabled = true;
        return;
    }
  }
  async onDeclareLost() {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ declareBordereauLost: EnvoiPartenaire }>({
        mutation: DECLARE_LOST_MUTATION,
        variables: {
          id: this.bordereauId,
        },
      })
    );
    if (result?.data?.declareBordereauLost) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.declareBordereauLost };
      this.updateButonsOnStateChange();
    }
  }

  async onDeclareSearch() {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ declareBordereauSearch: EnvoiPartenaire }>({
        mutation: DECLARE_SEARCH_MUTATION,
        variables: {
          id: this.bordereauId,
        },
      })
    );
    if (result?.data?.declareBordereauSearch) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.declareBordereauSearch };
      this.updateButonsOnStateChange();
    }
  }

  async onCancelStatut() {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ cancelBordereauStatut: EnvoiPartenaire }>({
        mutation: CANCEL_STATUT_MUTATION,
        variables: {
          id: this.bordereauId,
        },
      })
    );
    if (result?.data?.cancelBordereauStatut) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.cancelBordereauStatut };
      this.updateButonsOnStateChange();
    }
  }

  async onCancelSearch() {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ cancelBordereauSearch: EnvoiPartenaire }>({
        mutation: CANCEL_SEARCH_MUTATION,
        variables: {
          id: this.bordereauId,
        },
      })
    );
    if (result?.data?.cancelBordereauSearch) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.cancelBordereauSearch };
      this.updateButonsOnStateChange();
    }
  }
  async onNoteUpdate(note: String | null) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ bordereauNoteUpdate: EnvoiPartenaire }>({
        mutation: UPDATE_NOTE_MUTATION,
        variables: {
          id: this.bordereauId,
          newNote: note,
        },
      })
    );
    if (result?.data?.bordereauNoteUpdate) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.bordereauNoteUpdate };
      this.updateButonsOnStateChange();
    }
  }

  async onSendDateDeclaredClick(sendDate: Date) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ declareBordereauSendDate: EnvoiPartenaire }>({
        mutation: DECLARE_SEND_DATE_MUTATION,
        variables: {
          id: this.bordereauId,
          sendDate: sendDate,
        },
      })
    );
    if (result?.data?.declareBordereauSendDate) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.declareBordereauSendDate };
      this.updateButonsOnStateChange();
      this.fireOperationStateTransitionTriggerForOperationsList(
        OperationStateTransitionTrigger.GestionnaireSendsOperationToPartenaireByCourrier
      );
    }
  }

  async onReceiveDateDeclared(receiveDate: Date) {
    const result = await firstValueFrom(
      this.queryManager.mutate<{ declareBordereauReceiveDate: EnvoiPartenaire }>({
        mutation: DECLARE_RECEIVE_DATE_MUTATION,
        variables: {
          id: this.bordereauId,
          receiveDate: receiveDate,
        },
      })
    );
    if (result?.data?.declareBordereauReceiveDate) {
      this.envoiPartenaire = { ...this.envoiPartenaire, ...result?.data?.declareBordereauReceiveDate };
      this.updateButonsOnStateChange();
    }
  }

  getSearchDate() {
    return this.getStatuDate(EnvoiPartenaireStatut.CourrierSearching);
  }
  getLostDate() {
    return this.getStatuDate(EnvoiPartenaireStatut.CourrierLost);
  }

  getStatuDate(statut: EnvoiPartenaireStatut) {
    let historyEntries = this.envoiPartenaire?.historyRecords?.filter(
      record =>
        record.type === EnvoiPartenaireHistoryRecordTypes.EnvoiPartenaireStatutChanged && record.newStatut === statut
    );
    if (historyEntries?.length == 0) {
      return null;
    } else if (historyEntries?.length == 1) {
      return this.datepipe.transform(historyEntries[0].timestamp, 'dd/MM/YYYY');
    } else {
      let date = historyEntries?.reduce((previousValue, currentValue) => {
        if (currentValue && currentValue.timestamp > previousValue.timestamp) {
          return currentValue;
        }
        return previousValue;
      }, historyEntries[0]);
      if (date) {
        return this.datepipe.transform(date.timestamp, 'dd/MM/YYYY');
      }
      return null;
    }
  }
  isStatutRecherche(): boolean {
    return this.envoiPartenaire?.statutEnvoi === EnvoiPartenaireStatut.CourrierSearching;
  }

  isStatutPerdu(): boolean {
    return this.envoiPartenaire?.statutEnvoi === EnvoiPartenaireStatut.CourrierLost;
  }
  async fetchBordereau() {
    const result = await firstValueFrom(
      this.queryManager.query<{ envoiPartenaireById: EnvoiPartenaire }>({
        query: BORDEREAU_DETAILS,
        variables: {
          id: this.bordereauId,
        },
        fetchPolicy: 'network-only',
      })
    );

    this.envoiPartenaire = deepCopy(result.data?.envoiPartenaireById);

    let createdDate = this.envoiPartenaire?.historyRecords?.find(
      record => record.type === EnvoiPartenaireHistoryRecordTypes.EnvoiPartenaireCreate
    )?.timestamp;
    this.createDateControl.setValue(createdDate);

    let sendingDate = this.envoiPartenaire?.sendingDate;
    if (sendingDate) {
      this.sendingDateControl.setValue(sendingDate);
    }

    let receivingDate = this.envoiPartenaire?.receiveDate;
    if (receivingDate) {
      this.receivingDateControl.setValue(receivingDate);
    }

    let note = this.envoiPartenaire?.note;
    if (note) {
      this.noteControl.setValue(note);
    }

    this.envoiPartenaire.emails?.sort((a, b) => {
      return getTime(b.sendingDate) - getTime(a.sendingDate);
    });
    this.emailLastSendDate = this.envoiPartenaire.emails?.[0]?.sendingDate;

    this.getEmailErrors();
    this.updateButonsOnStateChange();
  }
}

import { formatDate } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE } from '@lib/models/graphqlData';
import {
  Investisseur,
  InvestisseurFilterInput,
  InvestisseursPaginatedCollectionSegment,
} from '@lib/models/generated/graphql';
import { QueryManagerService } from '@lib/services/queryManagerService';
import { gql } from 'apollo-angular';
import { OuiAutocomplete, OuiAutocompleteOption } from 'omnium-ui/form-field';
import { debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';

const investisseurInfoFragment = gql`
  fragment investisseurInfo on Investisseur {
    id
    code
    arianeId
    investisseurEntite {
    type
    displayName
    personnePhysique {
      dateNaissance
      prenom
      nom
      genre
    }
    personneMorale {
      denomination
    }
  }  }
`;

const allTransferInvestisseursByText = gql`
  query allInvestisseurs($text: String, $optionalFilter: InvestisseurFilterInput!) {
    byFirstName: allInvestisseursPaginated(
      skip: 0
      take: 10
      where: { and: [$optionalFilter, { or: [{ personnePhysique: { some: { prenom: { startsWith: $text } } } }] }] }
    ) {
      items {
        ...investisseurInfo
      }
    }
    byLastName: allInvestisseursPaginated(
      skip: 0
      take: 30
      where: { and: [$optionalFilter, { or: [{ personnePhysique: { some: { nom: { startsWith: $text } } } }] }] }
    ) {
      items {
        ...investisseurInfo
      }
    }
    byCode: allInvestisseursPaginated(
      skip: 0
      take: 10
      where: { and: [$optionalFilter, { code: { startsWith: $text } }] }
    ) {
      items {
        ...investisseurInfo
      }
    }
  }
  ${investisseurInfoFragment}
`;

const allTransferInvestisseursByLong = gql`
  query allInvestisseurs($long: Long, $optionalFilter: InvestisseurFilterInput!) {
    byArianeId: allInvestisseursPaginated(
      skip: 0
      take: 10
      where: { and: [$optionalFilter, { or: [{ arianeId: { eq: $long } }] }] }
    ) {
      items {
        ...investisseurInfo
      }
    }
  }
  ${investisseurInfoFragment}
`;

@Component({
  selector: 'app-search-investisseur-transfer-autocomplete',
  templateUrl: './search-investisseur-transfer-autocomplete.component.html',
  styleUrls: ['./search-investisseur-transfer-autocomplete.component.scss'],
})
export class SearchInvestisseurTransferAutocompleteComponent implements AfterViewInit {
  @ViewChild('investisseurAutocomplete') investisseurAutocomplete: OuiAutocomplete<any>;

  label: string = 'Nom, Prénom, numéro CLT ou ID Ariane';
  @Input() consultantIdFilter: number[] = [];
  @Input() excludedInvestisseurIds: number[] = [];
  @Input() isTarget: boolean = false;

  @Output() onInvestisseurIdSelected = new EventEmitter<number | null | undefined>();
  @Output() investisseurData = new EventEmitter<Investisseur | null | undefined>();

  investisseurs: Investisseur[] = [];

  investisseurAutocompleteOptions: OuiAutocompleteOption<number>[] = [];
  investisseurControl = new FormControl<number | undefined>(undefined);

  constructor(private queryManager: QueryManagerService) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.initSearchInvestisseurs();
    });
  }

  async initSearchInvestisseurs() {
    this.listenForInvestisseursChanges();
  }

  public clear() {
    this.investisseurControl.setValue(undefined);
    this.investisseurAutocompleteOptions = [];
    this.investisseurs = [];
  }

  setOptions() {
    this.investisseurAutocompleteOptions = this.investisseurs.map(investisseur => ({
      label: this.formatInvestisseurLabel(investisseur),
      value: investisseur.id,
    }));
  }

  listenForInvestisseursChanges() {
    this.investisseurControl.valueChanges.pipe(debounceTime(350), distinctUntilChanged()).subscribe(investisseurId => {
      if (Number.isInteger(investisseurId)) {
        const investisseurFound = this.investisseurs.find(investisseur => investisseur.id == investisseurId);
        if (investisseurFound) {
          this.onInvestisseurIdSelected.emit(investisseurFound.id);
          this.investisseurData.emit(investisseurFound);
        }
      } else if (investisseurId) {
        this.fetchInvestisseurs(investisseurId.toString());
        this.onInvestisseurIdSelected.emit(null);
      }
    });
  }

  async fetchInvestisseurs(search: string = '') {
    const searchAsNumber = Number(search);
    const filter = this.buildFilter();
    if (!Number.isNaN(searchAsNumber) && Number.isInteger(searchAsNumber)) {
      let investisseurResult = await firstValueFrom(
        this.queryManager.query<{ byArianeId: InvestisseursPaginatedCollectionSegment }>({
          query: allTransferInvestisseursByLong,
          variables: {
            long: searchAsNumber,
            optionalFilter: filter,
          },
        })
      );
      this.investisseurs = investisseurResult.data.byArianeId.items ?? [];
    } else {
      let investisseurResult = await firstValueFrom(
        this.queryManager.query<{
          byFirstName: InvestisseursPaginatedCollectionSegment;
          byLastName: InvestisseursPaginatedCollectionSegment;
          byCode: InvestisseursPaginatedCollectionSegment;
        }>({
          query: allTransferInvestisseursByText,
          variables: {
            text: search,
            optionalFilter: filter,
          },
        })
      );
      this.investisseurs = [];

      this.investisseurs = this.investisseurs.concat(investisseurResult.data.byFirstName.items ?? []);
      this.investisseurs = this.investisseurs.concat(investisseurResult.data.byLastName.items ?? []);
      this.investisseurs = this.investisseurs.concat(investisseurResult.data.byCode.items ?? []);
    }

    this.setOptions();
  }

  buildFilter(): InvestisseurFilterInput {
    let investFilter: InvestisseurFilterInput = {};
    if (this.consultantIdFilter?.length) {
      investFilter.investisseurConsultant = {
        some: { and: [{ consultantId: { in: this.consultantIdFilter } }, { actif: { eq: 1 } }] },
      };
    }
    if (this.excludedInvestisseurIds?.length) {
      investFilter.id = { nin: this.excludedInvestisseurIds };
    }
    return investFilter;
  }

  formatInvestisseurLabel(investisseur: Investisseur) {
    let label = "";

    label += investisseur?.investisseurEntite?.displayName 

    if (investisseur?.investisseurEntite?.personnePhysique?.dateNaissance) {
      if (investisseur?.investisseurEntite?.personnePhysique?.genre == PERSONNE_PHYSIQUE_FEMALE_GENDER_VALUE) {
        label += " (née le ";
      }
      else {
        label += " (né le ";
      }
      label += formatDate(investisseur.investisseurEntite?.personnePhysique?.dateNaissance, 'dd/MM/yyyy', 'fr_FR') + ")";

      label += '   -   ' + investisseur?.code;
      label += '   -   ID Ariane : ' + investisseur?.arianeId;
    }
    return label;
  }

  setControlValue(investisseurId: number | null | undefined) {
    if (investisseurId) {
      this.investisseurControl.setValue(investisseurId);
    } else {
      this.investisseurControl.setValue(null);
      this.investisseurControl.reset();
      this.investisseurAutocomplete.empty();
    }
    this.investisseurControl.updateValueAndValidity();
  }
}

import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';
import {
  Partenaire,
  Produit,
  NatureOperation,
  BackOfficeMember,
  StatutFilterInput,
  Statut,
  FondEvenementiel,
} from '../models/generated/graphql';
import { QueryManagerService } from './queryManagerService';
import { AuthService } from './auth-service.service';

const allPartenairesQuery = gql`
  query allPartenaires($filter: PartenaireFilterInput) {
    allPartenaires(where: $filter) {
      id
      nom
    }
  }
`;

const allBackOfficeMembers = gql`
  query allBackOfficeMembers($where: BackOfficeMemberFilterInput) {
    allBackOfficeMembers(where: $where) {
      id
      tokenId
      displayName
    }
  }
`;

const allProduits = gql`
  query allProduits($where: ProduitFilterInput) {
    allProduits(where: $where) {
      id
      nom
      nomTechniqueProduitBox
      enveloppeNavigation {
        code
        libelle
      }
    }
  }
`;

const allNatureOperations = gql`
  query allNatureOperations($where: NatureOperationFilterInput) {
    allNatureOperations(where: $where) {
      id
      libelle
    }
  }
`;

const allStatuts = gql`
  query allStatuts($where: StatutFilterInput) {
    allStatuts(where: $where) {
      id
      backOfficeLibelle
      consultantLibelle
    }
  }
`;

const PAGEALLACTIVEFONDS = gql`
  query allFondEvenementielsPaginated($skip: Int, $take: Int) {
    allFondEvenementielsPaginated(skip: $skip, take: $take) {
      pageInfo {
        hasNextPage
      }
      items {
        id
        libelle
      }
      totalCount
    }
  }
`;
const NUMBER_OF_FONDSEVENEMENTIELS_BY_REQUEST = 50;
@Injectable({
  providedIn: 'root',
})
export class DashboardFiltersService {
  private allPartenaires: Partenaire[] = [];
  private allProduits: Produit[] = [];
  private allNatureOperations: NatureOperation[] = [];
  private allGestionnaires: BackOfficeMember[] = [];

  private allStatutsMap: Map<StatutFilterInput, Statut[]> = new Map();
  private allFondsEvenementiels: FondEvenementiel[] = [];
  constructor(private queryManager: QueryManagerService) {}

  async getAllPartenaires(): Promise<Partenaire[]> {
    if (this.allPartenaires.length === 0) {
      const activiteFilter = { activiteRattachement: { eq: 0 } }; //  activite investissement only
      const result = await firstValueFrom(
        this.queryManager.query<{ allPartenaires: Partenaire[] }>(
          {
            query: allPartenairesQuery,
            variables: {
              filter: activiteFilter,
            },
          },
          false,
          true
        )
      );
      this.allPartenaires = result.data.allPartenaires;
    }
    return this.allPartenaires;
  }

  async getAllProduits(): Promise<Produit[]> {
    if (this.allProduits.length === 0) {
      const result = await firstValueFrom(
        this.queryManager.query<{ allProduits: Produit[] }>(
          {
            query: allProduits,
          },
          false,
          true
        )
      );
      this.allProduits = result.data.allProduits;
    }
    return this.allProduits;
  }

  async getAllNatures(): Promise<NatureOperation[]> {
    if (this.allNatureOperations.length === 0) {
      const result = await firstValueFrom(
        this.queryManager.query<{ allNatureOperations: NatureOperation[] }>(
          {
            query: allNatureOperations,
          },
          false,
          true
        )
      );
      this.allNatureOperations = result.data.allNatureOperations;
    }
    return this.allNatureOperations;
  }

  async getAllGestionnaires(): Promise<BackOfficeMember[]> {
    if (this.allGestionnaires.length === 0) {
      const result = await firstValueFrom(
        this.queryManager.query<{ allBackOfficeMembers: BackOfficeMember[] }>(
          {
            query: allBackOfficeMembers,
          },
          false,
          true
        )
      );
      this.allGestionnaires = result.data.allBackOfficeMembers;
    }
    return this.allGestionnaires;
  }

  async getAllStatuts(where: StatutFilterInput) {
    if (!this.allStatutsMap.has(where)) {
      const result = await firstValueFrom(
        this.queryManager.query<{ allStatuts: Statut[] }>(
          {
            query: allStatuts,
            variables: {
              where,
            },
          },
          false,
          true
        )
      );
      this.allStatutsMap.set(where, result.data.allStatuts);
    }
    return this.allStatutsMap.get(where);
  }

  private async getPartOfFondsEvenementiels(round: number = 0): Promise<{
    pageInfo: {
      hasNextPage: boolean;
    };
    items: any[];
    totalCount: number;
  }> {
    const result = await firstValueFrom(
      this.queryManager.query<{
        allFondEvenementielsPaginated: { pageInfo: { hasNextPage: boolean }; items: any[]; totalCount: number };
      }>({
        query: PAGEALLACTIVEFONDS,
        variables: {
          skip: 0 + NUMBER_OF_FONDSEVENEMENTIELS_BY_REQUEST * round,
          take: NUMBER_OF_FONDSEVENEMENTIELS_BY_REQUEST + NUMBER_OF_FONDSEVENEMENTIELS_BY_REQUEST * round,
        },
      })
    );

    return result.data.allFondEvenementielsPaginated;
  }
  private async getAllIterativeLoadedFondsEvenementiels(): Promise<FondEvenementiel[]> {
    let hasNextPage: boolean = false;
    let round = 0;
    let allFondsEvenementiels: any[] = [];
    do {
      const result = await this.getPartOfFondsEvenementiels(round);
      allFondsEvenementiels = allFondsEvenementiels.concat(result.items);
      hasNextPage = result.pageInfo.hasNextPage;
      round++;
    } while (hasNextPage);
    return allFondsEvenementiels;
  }
  //FIXME: this use mulitple paginated requests.this is a temporary solution, not a future proof solution
  async getAllFondsEvenementiels(): Promise<FondEvenementiel[]> {
    if (this.allFondsEvenementiels.length > 0) {
      return this.allFondsEvenementiels;
    }
    this.allFondsEvenementiels = await this.getAllIterativeLoadedFondsEvenementiels();
    return this.allFondsEvenementiels;
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpleDocumentViewerComponent } from '../simple-document-viewer/simple-document-viewer.component';
import { BoxDocumentViewerService, DocumentViewerInfo } from './document-viewer-panel.service';
import {
  AppendedDocumentViewerComponent,
  AppendedDocumentViewerRemoveEvent,
  AppendedDocumentViewerResult,
} from '../appended-document-viewer/appended-document-viewer.component';
import { OperationDocumentViewerComponent } from '../operation-document-viewer/operation-document-viewer.component';
import { PortalModule } from '@angular/cdk/portal';

@Component({
  selector: 'lib-document-viewer-panel',
  standalone: true,
  imports: [
    CommonModule,
    SimpleDocumentViewerComponent,
    AppendedDocumentViewerComponent,
    OperationDocumentViewerComponent,
    PortalModule,
  ],
  templateUrl: './document-viewer-panel.component.html',
  styleUrls: ['./document-viewer-panel.component.scss'],
})
export class DocumentViewerPanelComponent {
  fileInfo: DocumentViewerInfo | null = null;

  constructor(private documentViewerService: BoxDocumentViewerService) {
    this.documentViewerService.fileViewerInfo$.subscribe(value => {
      this.fileInfo = value;
    });
  }

  closeDocumentViewer() {
    this.documentViewerService.closeDocumentViewer();
  }

  closeAppendedDocumentViewer(result: AppendedDocumentViewerResult | undefined) {
    this.documentViewerService.closeAppendedDocumentViewer(result);
  }

  removeFichierOperation(event: AppendedDocumentViewerRemoveEvent) {
    this.documentViewerService.removeFichierOperation(event);
  }
}

<div class="param-layout">
  <div class="flex-horizontal">
    <h4>Fonds événementiels</h4>
    <div class="flex-1"></div>
    <oui-button class="contained" (click)="addFondEvenementiel()">Ajouter un fond événementiel</oui-button>
  </div>

  <div class="flex-vertical oui-elevation-04 background-white rounded width-100pc padding-32 gap-40">
    <oui-searchbar
      class="width-100pc"
      placeholder="Rechercher un fond événementiel"
      [control]="searchControl"></oui-searchbar>
    <app-fond-evenementiels-table
      [receivedData]="requestResult"
      (requestNewPage)="getAllFondEvenementiels()"
      [(paginationParams)]="paginationParams">
    </app-fond-evenementiels-table>
  </div>
</div>

<div oui-dialog-title>
  Fiche de liaison
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  Si nécessaire, saisissez un commentaire pour le partenaire qui sera ajouté à la fiche de liaison.

  <oui-textarea label="Commentaire (facultatif)" [(value)]="commentaire"></oui-textarea>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="createFicheLiaison()">Télécharger la fiche de liaison</oui-button>
</oui-dialog-actions>

<oui-document-viewer [documentUrl]="documentUrl" [showControls]="true">
  <ng-container oui-document-controls>
    <lib-document-viewer-header
      [documentName]="headerDocumentName"
      [fileName]="fileMetadata?.fileName ?? boxFile?.fileNameWithExtension ?? ''"
      [dateAjout]="fileMetadata?.dateDeDerniereModification ?? boxFile?.lastModificationDate"
      (close)="close.emit()">
    </lib-document-viewer-header>

    <div class="flex-vertical" *ngIf="commentaire">
      <div class="body-2 color-on-surface-medium">Commentaire</div>
      <div class="body-2 color-on-surface-emphasis" [innerText]="commentaire"></div>
    </div>
  </ng-container>
</oui-document-viewer>

<div oui-dialog-title>
  Commande Aramis
  <oui-button type="icon" oui-dialog-close>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div class="flex-horizontal flex-center gap-8">
    <oui-autocomplete #commandeAutocomplete label="Commande Aramis" [options]="commandesAutocompleteOptions"
      [control]="commandesControl" class="width-100pc"></oui-autocomplete>
    <oui-button type="icon" color="secondary" (click)="removeLink()"><oui-icon>delete</oui-icon></oui-button>
  </div>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" oui-dialog-close>Annuler</oui-button>
  <oui-button type="contained" (click)="validate()">Valider</oui-button>
</oui-dialog-actions>
<app-root-document-viewer
  [documentUrl]="documentUrl"
  (onValidate)="validate()"
  (onCancel)="close()"
  [isValidateDisabled]="!canValidate()">
  <div class="flex-vertical" left-panel>
    <div class="flex-vertical padding-24 gap-16">
      <h5>Ajouter depuis mes documents Ariane</h5>
      <oui-select
        label="Investisseur"
        *ngIf="investisseurs.length > 1"
        [control]="investisseurControl"
        [options]="investisseurListOptions"
        class="width-100pc"></oui-select>

      <!-- FIXME : waiting futur dev to be able to filter on etatSignature (currently not reliable data) -->
      <!-- <oui-select
        label="Statut signature"
        [control]="statutSignatureControl"
        [options]="statutSignatureListOptions"
        class="width-100pc"></oui-select> -->

      <oui-searchbar
        class="demo-component"
        [control]="searchControl"
        type="filter"
        placeholder="Rechercher un document"></oui-searchbar>
    </div>
    <div>
      <app-file-item
        *ngFor="let fileInfo of filteredFileInfoList"
        [fileName]="fileInfo?.metadata?.fileName ?? undefined"
        [withCheckBox]="isMultiple"
        [fileId]="fileInfo?.metadata?.fileId"
        [label]="generateFileLabel(fileInfo)"
        [selected]="selectedFileInfo?.metadata?.fileId === fileInfo?.metadata?.fileId"
        (click)="selectFile(fileInfo)"></app-file-item>
    </div>
  </div>

  <ng-container right-panel>
    <lib-document-viewer-header
      [documentName]="generateFileLabel(selectedFileInfo)"
      [fileName]="selectedFileInfo?.metadata?.fileName ?? undefined"
      [dateAjout]="selectedFileInfo?.metadata?.dateDeDerniereModification"
      (close)="close()">
    </lib-document-viewer-header>

    <!-- FIXME : should user be able to change type of document in ariane. For now, it's not possible but leave code available if required
    <oui-autocomplete
      label="Type de document"
      [control]="boxDocumentTypeControl"
      [options]="boxDocumentTypeListOptions"
      class="width-100pc"></oui-autocomplete>
    <oui-textfield
      label="Nom du document"
      [control]="denominationControl"
      *ngIf="denominationSelectable"></oui-textfield> -->

    <oui-textarea label="Commentaire (facultatif)" [control]="commentControl" *ngIf="!hideComment"></oui-textarea>
    <oui-checkbox
      *ngIf="showAllOperationsOption"
      label="Ajouter dans toutes les opérations"
      [(checked)]="forAllOperations"></oui-checkbox>
  </ng-container>
</app-root-document-viewer>

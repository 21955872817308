<div oui-dialog-title>
  Valider tous les documents de l'opération
  <oui-button type="icon" [oui-dialog-close]>
    <oui-icon>close</oui-icon>
  </oui-button>
</div>

<oui-dialog-content>
  <div>Vous allez déclarer conforme tous les documents de l'opération qui sont à contrôler.</div>
  <div>Voulez-vous continuer ?</div>
</oui-dialog-content>

<oui-dialog-actions>
  <oui-button type="text" [oui-dialog-close]>Annuler</oui-button>
  <oui-button type="contained" (click)="onValidate()">Valider</oui-button>
</oui-dialog-actions>

<oui-accordion [expanded]="files && files.length > 0" buttonPosition="title-end">
  <oui-accordion-header class="padding-vertical-8">
    <oui-accordion-title class="subtitle-1">Pièces jointes</oui-accordion-title>
    <oui-accordion-description>
      <oui-button type="outlined" (click)="onAddAttachedFile($event)">Ajouter des documents</oui-button>
    </oui-accordion-description>
  </oui-accordion-header>
  <div class="flex-horizontal gap-16" *ngIf="files && files.length > 0">
    <div class="padding-16 flex-horizontal gap-8">
      <oui-checkbox
        (checkedChange)="toggleAllRows()"
        [checked]="selection.length > 0 && isAllSelected()"
        [indeterminate]="selection.length > 0 && !isAllSelected()">
      </oui-checkbox>
      {{ files.length }} documents
    </div>

    <oui-button
      *ngIf="!isFileDownloading"
      class="align-self-center"
      [type]="'text'"
      (click)="onDownloadSelectedFiles()"
      iconPrefix="download"
      [disabled]="!selection || selection.length === 0"
      >Télécharger</oui-button
    ><oui-loading *ngIf="isFileDownloading" size="small"></oui-loading>
  </div>

  <div class="flex-vertical rounded with-border" *ngIf="files && files.length > 0">
    <div
      *ngFor="let file of files; let eltIndex = index"
      class="flex-horizontal height-56"
      [ngClass]="eltIndex > 0 ? 'with-border-top' : ''">
      <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
        <oui-checkbox
          [checked]="selection.includes(file.id)"
          (checkedChange)="toggleFileSelection(file.id)"></oui-checkbox>
        <div *ngIf="isFileViewerCompatible(file)" class="cursor-pointer flex-none" (click)="openFileViewer(file)">
          <u class="color-primary-500">{{ file.fileNameWithExtension }}</u>
        </div>
        <span *ngIf="!isFileViewerCompatible(file)">{{ file.fileNameWithExtension }}</span>
      </div>
      <div class="align-self-center padding-lateral-16 padding-vertical-4 flex-1 flex-horizontal gap-16">
        <oui-textfield
          class="flex-1"
          [placeholder]="'Nom du document'"
          [value]="file.denomination"
          (change)="onFileDenominationChange(file.id, $event)"></oui-textfield>
        <oui-icon class="flex-none align-self-center cursor-pointer" (click)="onDeleteFile(file.id)">delete</oui-icon>
      </div>
    </div>
  </div>
  <div id="target_div" class="display-none"></div>
</oui-accordion>

import { Component } from '@angular/core';
import { BoxLayoutService } from 'projects/box-lib/src/lib/services/layout.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  constructor(private layoutService: BoxLayoutService) {}

  ngOnInit() {
    this.layoutService.hideHeader();
  }

  ngOnDestroy(): void {
    this.layoutService.showHeader();
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { OuiButtonColor } from 'omnium-ui/button';

@Component({
  selector: 'app-root-document-viewer',
  templateUrl: './root-document-viewer.component.html',
  styleUrls: ['./root-document-viewer.component.scss'],
})
export class RootDocumentViewerComponent {
  @Input()
  documentUrl: string;

  @Input()
  isMissingFile: boolean = false;

  @Input()
  withFooter: boolean = true;

  @Input()
  isValidateDisabled: boolean = false;

  @Input()
  isOptionnalButton1Disabled: boolean = false;

  @Input()
  validateButtonLabel: string = ' Ajouter le(s) document(s)';
  @Input()
  cancelButtonLabel: string = ' Annuler';
  @Input()
  optionnalButton1Label?: string = undefined;

  @Input()
  optionnalButtonColor: OuiButtonColor = 'primary';

  @Output()
  onValidate = new EventEmitter<void>();

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onOptionnalButton1Click = new EventEmitter<void>();

  // if you need another template for button in the footer: declare it here and complete the getter getTemplate
  @ViewChild('twoButtons') twoButtons: TemplateRef<any>;
  @ViewChild('controlFile') controlFile: TemplateRef<any>;

  constructor(private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  cancel(): void {
    this.onCancel.emit();
  }

  validate(): void {
    this.onValidate.emit();
  }

  optionnalButton1Click(): void {
    this.onOptionnalButton1Click.emit();
  }
}
